import React from 'react';

export function SeveralItemsColumnFormatter(cellContent) {
  let cellDataArray = [];
  if (Boolean(cellContent) && typeof cellContent === 'string') {
    cellDataArray = cellContent.split(',');
  } else if (Boolean(cellContent) && (cellContent instanceof Array)) {
    cellDataArray = cellContent;
  }

  const mappedCellContent = cellDataArray.map(item => {
    return (
      <div
        data-text={item}
        className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap"
      >
        {item}
      </div>
    )
  });

  return (
    <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
      {mappedCellContent}
    </div>
  );
}

import {v4 as uuidv4} from 'uuid';
import conf from '../config/common';
import {showGlobalMessage} from '../app/modules/_common/components/GlobalMessage/actions/showGlobalMessage';
import {messages as M} from '../_metronic/i18n/messages';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: {accessToken}
      } = store.getState();

      if (accessToken) {
        config.headers.accessToken = `${accessToken}`;
      }

      config.baseURL =
        (process.env.NODE_ENV === 'development')
          ? conf.dev_base_URL
          : conf.base_URL;
      config.headers['Content-Type'] = 'application/json';
      config.headers['requestid'] = `${uuidv4()}`;

      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    response => {
      const {errorCode, body} = response.data;
      if (!(response.data instanceof Blob)) {
        const {errorMessage} = body;
        const isGeneralError = errorCode !== 0;
        const isInWhitelist = checkUrlIsInWhitelist(response);
        if (isGeneralError && !isInWhitelist) {
          console.log('Ooops!', `error code ${errorCode}`, M[errorCode]);
          Boolean(errorMessage)
            ? showGlobalMessage(errorMessage)(store.dispatch)
            : showGlobalMessage(M[errorCode])(store.dispatch);
        }
      }
      return response;
    },
    error => {
      const isAuthError = error.response.status >= 400;
      const isNotLogout = error.request.responseURL !== `${conf.base_URL}/auth/logout`;
      if (process.env.NODE_ENV !== 'development') {
        if (isAuthError && isNotLogout) window.location.href = '/logout';
      }

      return Promise.reject(error);
    });

}

const checkUrlIsInWhitelist = response => {
  return urlWhiteList.indexOf(response.config.url) >= 0;
};

const urlWhiteList = [
  '/files/generate6101Close',
];



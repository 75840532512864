import React from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import styles from './styles.module.scss';

const CommonPopup = ({
                       isOpen,
                       closeModal,
                       content,
                       commonButton = true,
                       customContainerStyles,
                       size = 'xl'
                     }) => {

  return (
    <Modal show={isOpen} onHide={closeModal} size={size} backdrop={'static'}>
      <Modal.Body>
        <div className={`${styles.modalContainer} ${customContainerStyles}`}>
          <div className={styles.content}>
            {content}
          </div>
          {commonButton &&
          <div className={styles.buttonsContainer}>
            <Button
              onClick={closeModal}
            >
              {'ביטול'}
            </Button>
          </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

CommonPopup.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  content: PropTypes.node,
  commonButton: PropTypes.bool,
  customContainerStyles: PropTypes.string,
  size: PropTypes.string,
};

export default CommonPopup;

import React from "react";
import {Button, Dropdown} from "react-bootstrap";


export const DropdownButton = ({
                                 icon,
                                 buttonLabel,
                                 disabled = false,
                                 items,
                               }) => {

  const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <Button
      variant={'warning'}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className='d-flex align-items-center w-175px'
      disabled={disabled}
    >
      {icon && (
        <img
          src={icon}
          alt="upload"
          className="w-20px h-20px"
        />
      )}
      <span className='w-100 text-dark'>{buttonLabel}</span>
    </Button>
  ));

  const CustomDDItem = React.forwardRef(
    ({children, style, className, 'aria-labelledby': labeledBy, onClick}, ref) => (
      <label
        ref={ref}
        className='dropdown-item'
      >
        {children}
      </label>
    )
  );

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          as={CustomToggle}
          variant="light"
          id="dropdown-basic"
        />
        <Dropdown.Menu style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '300px',
        }}
        >
          {items.map(item => {
            return (
              <Dropdown.Item as={CustomDDItem}>
                {item}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

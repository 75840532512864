import {value} from "lodash/seq";

export const propertyTypeIDList = [
  {label: 'בנייה רוויה', value: 1}, //construction
  {label: 'צמוד קרקע', value: 2}, //floor
  {label: 'שטחים חקלאיים', value: 3}, //areas
];
export const propertyTypeIDListRightsEstimation = [
  {label: 'בנייה רוויה', value: 1}, //construction
];
export const propertyTypeIDListBankSecure = [
  {label: 'בנייה רוויה', value: 1}, //construction
];
export const propertyTypeIDListEnabled = [
  {label: 'בנייה רוויה', value: 1}, //construction
  {label: 'צמוד קרקע', value: 2}, //floor
];
export const bankIDList = [
  {label: 'בנק לאומי', value: 1}, //national
  {label: 'בנק הפועלים', value: 2}, //hapoalim
  {label: 'בנק מזרחי', value: 3}, //mizrahi
  {label: 'בנק דיסקונט', value: 4}, //discount
  {label: 'הבנק הבינלאומי', value: 5}, //international
];
export const shumaTypeIDList = [
  {label: 'תקן 19 למגורים', value: 1}, //standard
  {label: 'שמאות מקוצרת', value: 2}, //abbreviated
  {label: 'שמאות מורחבת*', value: 3}, //extended
];
export const shumaTypeIDListEnabled = [
  {label: 'תקן 19 למגורים', value: 1}, //standard
  {label: 'שמאות מקוצרת', value: 2}, //abbreviated
];
export const loanTypeIDList = [
  {label: 'רכישת מקרקעין שבנידון', value: 1}, //inQuestion
  {label: 'רכישת מקרקעין אחר', value: 2}, //other
  {label: 'מימון בנקאי', value: 3}, //bankFinancing
  {label: 'נכס קיים', value: 4}, //existingProperty
  {label: 'שיפוצים', value: 5}, //renovations
  {label: 'בניה עצמית', value: 6}, //selfConstruction
  {label: 'אחר', value: 7}, //another
];
export const otherPropertyTypeIDList = [
  {label: 'בנייה רוויה', value: 1}, //construction
  {label: 'צמוד קרקע', value: 2}, //floor
  {label: 'חניון', value: 3}, //parkingLot
  {label: 'משרד', value: 4}, //office
  {label: 'חנות', value: 5}, //shop
  {label: 'קרקע', value: 6}, //land
];
export const genders = [
  {label: 'זכר', value: 1},
  {label: 'נקבה', value: 2},
];
export const customerTypes = [
  {label: 'חברה', value: 1},
  {label: 'עירייה', value: 2},
  {label: 'מוסד', value: 3},
  {label: 'פרטי', value: 4},
  {label: 'קבוצה', value: 5},
];
export const customerStatus = [
  {label: 'פעיל', value: 1},
  {label: 'מוקפא', value: 2},
];
export const priceQuoteStatus = [
  {label: 'פתוחה', value: 1},
  {label: 'מוקפאת', value: 2},
  {label: 'מאושרת', value: 3},
];
export const taskStatuses = [
  {label: 'פתוחה', value: 1},
  {label: 'סגורה', value: 2},
];
export const taskTypes = [
  {label: 'ביקור בנכס', value: 1},
  {label: 'כללי', value: 2},
];
export const maxPayments = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
  {label: '13', value: 13},
  {label: '14', value: 14},
  {label: '15', value: 15},
  {label: '16', value: 16},
  {label: '17', value: 17},
  {label: '18', value: 18},
  {label: '19', value: 19},
  {label: '20', value: 20},
  {label: '21', value: 21},
  {label: '22', value: 22},
  {label: '23', value: 23},
  {label: '24', value: 24},
  {label: '25', value: 25},
  {label: '26', value: 26},
  {label: '27', value: 27},
  {label: '28', value: 28},
  {label: '29', value: 29},
  {label: '30', value: 30},
  {label: '31', value: 31},
  {label: '32', value: 32},
  {label: '33', value: 33},
  {label: '34', value: 34},
  {label: '35', value: 35},
  {label: '36', value: 36},
];
export const docTypes = [
  {label: 'חשבונית מס קבלה', value: 320},
  {label: 'קבלה', value: 400},
];


import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import DocumentPreview from '../../../_common/components/DocumentPreview';
import {can} from '../../../_common/components/Permissions';
import {permissionTypes as T, subjects as S} from '../../../_common/constants/permissionSubjects';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import styles from '../../styles/styles.module.scss';
import {FormattedMessage} from "react-intl";

const PopupPreviewDocument = ({content}) => {
  const {adminPermissionsLevel} = useSelector(
    (state) => ({
      adminPermissionsLevel: state.auth.adminPermissionsLevel,
    }),
    shallowEqual
  );

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const disabledCondition = !can(T.FULL, S.INSPECTIONS_PAGE)
    || !content.finalPDFFileID
    || adminPermissionsLevel === 3
    || Boolean(!adminPermissionsLevel);

  return (
    <>
      <Button
        className={'w-100 bg-white border-0 h-40px d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg'}
        onClick={() => handleShowModal()}
        disabled={disabledCondition}
      >
        <img
          src={toAbsoluteUrl('media/common/ZoomPlus.svg')}
          alt={'action'}
          className={'w-20px mr-4'}
        />
        <FormattedMessage
          id="inspections-column-preview"
          defaultMessage="Preview"
        />
      </Button>
      <Modal
        show={show}
        onHide={handleCloseModal}
        centered
        overlayClassName={styles.customOverlay}
      >
        <Modal.Body className={styles.customModalPreview}>
          <div className={styles.modalContentContainer}>
            <div className={styles.documentViewPort}>
              <div className={styles.iframeContainerWrapper}>
                <div className={styles.iframeContainer}>
                  <DocumentPreview
                    shumaID={content.shumaID}
                    fileID={content.finalPDFFileID}
                    close={handleCloseModal}
                  />
                </div>
              </div>
            </div>
            <div className={styles.rejectMenu}>
              <div className={classNames(styles.modalButtons, `mt-6 mb-4`)}>
                <button
                  type="button"
                  className={styles.cancelButton}
                  onClick={handleCloseModal}
                >
                  <span>סגירה</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PopupPreviewDocument;
import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {checkIfTextEndsWithDot} from "../../../utils/texts";
import styles from './styles.module.scss';

const Head = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        taxAndCostDesc
      }
    },
    sectionNumbersShumaType3
  } = useCompleteInspectionContext();
  const showHeader12 = Boolean(taxAndCostDesc)

  return (
    <div ref={ref}>
      {showHeader12 && (
        <div className={classnames(styles.textRegular, styles.sectionContainer)}>
          <span className={classnames(styles.textUnderline, styles.bold)}>
            <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section12}. `}</span>
            <span>מיסוי והיטלים (במידה וידועים)</span>
          </span>
          <div className={classnames(styles.innerPadding)}>
            {taxAndCostDesc}{checkIfTextEndsWithDot(taxAndCostDesc)}
          </div>
        </div>
      )}
    </div>
  );
});

export default {
  Head
};

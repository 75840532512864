import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import ProgressBar from "../ProgressBar";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');


const UploadingFilePopUp = ({
                              showUploadingFilePopUp,
                              hideUploadingFilePopUp,
                              progress,
                              message
                            }) => {

  return (
    <Modal
      isOpen={showUploadingFilePopUp}
      onRequestClose={hideUploadingFilePopUp}
      className={`${styles.modal} ${styles.rtl}`}
      overlayClassName={styles.overlay}
    >
      <div dir='rtl' className={styles.modalContainer}>
        <h3 className={styles.header}>{message}</h3>
        <div className={styles.progressBar}>
          <ProgressBar progress={progress}/>
        </div>
      </div>
    </Modal>
  )
};

UploadingFilePopUp.propTypes = {
  showUploadingFilePopUp: PropTypes.bool,
  hideUploadingFilePopUp: PropTypes.func,
  progress: PropTypes.number,
  message: PropTypes.string,
};

export default UploadingFilePopUp;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

const FilterTextBox = ({
                         customContainerClassName = '',
                         customInputClassName = 'form-control form-control-lg form-control-solid h-35px pl-4',
                         name,
                         handleBlur,
                         onChangeValue,
                         placeholder,
                         value,
                         onClearValue,
                         ...rest
                       }) => {

  return (
    <div className={customContainerClassName}>
      <input
        type="text"
        className={customInputClassName}
        name={name}
        onBlur={handleBlur}
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        {...rest}
      />
      <img
        src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
        alt="clear-filter"
        className={styles.clearFilterIcon}
        onClick={onClearValue}
      />
    </div>
  );
};

FilterTextBox.propTypes = {
  customContainerClassName: PropTypes.string,
  customInputClassName: PropTypes.string,
  name: PropTypes.string,
  handleBlur: PropTypes.func,
  onChangeValue: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onClearValue: PropTypes.func
};

export default FilterTextBox;

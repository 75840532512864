import React from 'react';
import Select from "react-select";
import {useIntl} from "react-intl";
import PropTypes from 'prop-types';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {filtersBar} from "../../selectStyles/customSelectStyles";
import styles from './styles.module.scss';


const FilterDropdown = ({
                          name,
                          onChangeValue,
                          options,
                          placeholder,
                          value,
                          onClearValue,
                          customContainerClassname,
                          disabled
                        }) => {

  const intl = useIntl();
  const noOptionsMessage = intl.formatMessage({
    id: 'label-no-options',
    defaultMessage: 'No options',
  });

  return (
    <div className={customContainerClassname}>
      <Select
        name={name}
        value={value}
        onChange={onChangeValue}
        options={options}
        styles={filtersBar}
        placeholder={placeholder}
        isSearchable={true}
        isAsync={true}
        defaultOptions={true}
        noOptionsMessage={() => noOptionsMessage}
        isDisabled={disabled}
      />
      <img
        src={toAbsoluteUrl(!disabled
          ? '/media/common/Cross_BC.svg'
          : '/media/common/Cross_disabled.svg'
        )}
        alt="clear-filter"
        className={styles.clearFilterIcon}
        onClick={() => {
          if(!disabled) {
            onClearValue();
          }
        }}
      />
    </div>
  );
};

FilterDropdown.propTypes = {
  customContainerClassname: PropTypes.string,
  name: PropTypes.string,
  handleBlur: PropTypes.func,
  onChangeValue: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  onClearValue: PropTypes.func,
  disabled: PropTypes.bool
};

export default FilterDropdown;

import {documentTypes as T} from "../../CustomerDetails/constants/documentTypes";

export const descriptionTextSuggestions = (type) => {
  switch (type) {
    case T.PRICE_QUOTE:
      return [
        {
          label: 'חלופה 1',
          value: 'הריני להגיש הצעת המחיר (1) לכב\' ____  בעניין הכנת חוות דעת למקרקעין כמפורט להלן:'
        },
        {
          label: 'חלופה 2',
          value: 'עבור נכס בגוש-  חלקה-  תת חלקה -  , כתובת:    , מהות הנכס:'
        },
      ]
    case T.WORK_ORDER:
      return [
        {
          label: 'חלופה 1',
          value: 'הריני להגיש הזמנת העבודה (1) לכב\' ____  בעניין הכנת חוות דעת למקרקעין כמפורט להלן:'
        },
        {
          label: 'חלופה 2',
          value: 'עבור נכס בגוש-  חלקה-  תת חלקה -  , כתובת:    , מהות הנכס:'
        },
      ]
    case T.RETURN_CERTIFICATE:
    case T.TRANSACTION_ACCOUNT:
    case T.INVOICE:
    case T.INVOICE_RECEIPT:
    case T.CREDIT_INVOICE:
    case T.RECEIPT:
    case T.DONATION_RECEIPT:
    case T.PURCHASE_ORDER:
    case T.RECEIVING_DEPOSIT:
    case T.WITHDRAWAL_DEPOSIT:
    default:
      return []
  }
};

export const remarksTextSuggestions = (type) => {
  switch (type) {
    case T.PRICE_QUOTE:
      return [
        {
          label: 'חלופה 1',
          value: '1.חוות הדעת תכלול:\n' +
            'א. אומדן שווי שוק.\n' +
            'ב. אומדן היטל השבחה בעת מימוש במכר.\n' +
            'ג. אומדן זכויות עודפות לעניין סעיף 49ז לחוק מסמ"ק, ככל שקיימות.\n' +
            'ד. התאמת שטח ברישוי לעומת שטח קיים.\n' +
            '2. במידה וקיימים מסמכים הרלוונטיים לחוות הדעת יש להעבירם עם אישור הצעת המחיר (במידה ויוגשו מסמכים לאחר חתימה על חוות הדעת יידרש תשלום נוסף לעדכון).\n' +
            '3. תנאי תשלום: חוות הדעת לרבות טיוטה/ות תימסר/נה לאחר הסדרת תשלום במלואו (2).  ניתן לשלם בהעברה בנקאית, המחאות, מזומן (לא ניתן לשלם באשראי).\n' +
            '4. לעדכונך/כם בהתאם, אודה.\n' +
            '5. בכל עניין נוסף הריני לרשותך/כם.'
        },
        {
          label: 'חלופה 2',
          value: 'מסמכים נדרשים:\n' +
            '-          אישור זכויות מעודכן לחודשיים האחרונים.\n' +
            '-          הסכם שיתוף.\n' +
            '-          הסכם שכירות + העתק 3 חשבוניות אחרונות.\n' +
            '-          חוזה חכירה.\n' +
            '-          הסכם רכישה.\n' +
            ' תנאי תשלום:\n' +
            'א. במעמד החתימה על הזמנת העבודה - מקדמה 50%.\n' +
            'ב. יתרת התשלום טרם שליחת טיוטה.\n' +
            ' התשלום אינו כולל מתן עדות. במידה ואדרש למתן עדות או דיון בפני מגשר/בורר יש להוסיף סך של ____\n' +
            'התשלום יתבצע באמצעות הפקדה לחשבון:\n' +
            'בנק דיסקונט, סניף גדרה 112 חשבון 20900 ע"ש סוויד שמאות מקרקעין בע"מ.\n' +
            'לוח זמנים: זמן ביצוע העבודה הינו עד 7/14/21 ימי עסקים.\n' +
            'מוסכם כי:\n' +
            '-          בעצם אישור הזמנת עבודה זו מוסכם כי אומדן השווי הינו למטרה זו בלבד ואין להשתמש בה לכל מטרה אחרת ללא הסכמה בכתב מצד השמאי.\n' +
            '-          המזמין כמו גם חותם ההזמנה ערב באופן אישי לתשלום.\n' +
            '-          תוקף ההצעה הינו ל-7 ימים.\n' +
            ' \t\t\t\t\t\tחתימת המזמין : ------------'
        },
      ]
    case T.WORK_ORDER:
      return [
        {
          label: 'חלופה 1',
          value: '1.חוות הדעת תכלול:\n' +
            'א. אומדן שווי שוק.\n' +
            'ב. אומדן היטל השבחה בעת מימוש במכר.\n' +
            'ג. אומדן זכויות עודפות לעניין סעיף 49ז לחוק מסמ"ק, ככל שקיימות.\n' +
            'ד. התאמת שטח ברישוי לעומת שטח קיים.\n' +
            '2. במידה וקיימים מסמכים הרלוונטיים לחוות הדעת יש להעבירם עם אישור הזמנת העבודה (במידה ויוגשו מסמכים לאחר חתימה על חוות הדעת יידרש תשלום נוסף לעדכון).\n' +
            '3. תנאי תשלום: חוות הדעת לרבות טיוטה/ות תימסר/נה לאחר הסדרת תשלום במלואו (2).  ניתן לשלם בהעברה בנקאית, המחאות, מזומן (לא ניתן לשלם באשראי).\n' +
            '4. לעדכונך/כם בהתאם, אודה.\n' +
            '5. בכל עניין נוסף הריני לרשותך/כם.'
        },
        {
          label: 'חלופה 2',
          value: 'מסמכים נדרשים:\n' +
            '-          אישור זכויות מעודכן לחודשיים האחרונים.\n' +
            '-          הסכם שיתוף.\n' +
            '-          הסכם שכירות + העתק 3 חשבוניות אחרונות.\n' +
            '-          חוזה חכירה.\n' +
            '-          הסכם רכישה.\n' +
            ' תנאי תשלום:\n' +
            'א. במעמד החתימה על הזמנת העבודה - מקדמה 50%.\n' +
            'ב. יתרת התשלום טרם שליחת טיוטה.\n' +
            ' התשלום אינו כולל מתן עדות. במידה ואדרש למתן עדות או דיון בפני מגשר/בורר יש להוסיף סך של ____\n' +
            'התשלום יתבצע באמצעות הפקדה לחשבון:\n' +
            'בנק דיסקונט, סניף גדרה 112 חשבון 20900 ע"ש סוויד שמאות מקרקעין בע"מ.\n' +
            'לוח זמנים: זמן ביצוע העבודה הינו עד 7/14/21 ימי עסקים.\n' +
            'מוסכם כי:\n' +
            '-          בעצם אישור הזמנת עבודה זו מוסכם כי אומדן השווי הינו למטרה זו בלבד ואין להשתמש בה לכל מטרה אחרת ללא הסכמה בכתב מצד השמאי.\n' +
            '-          המזמין כמו גם חותם ההזמנה ערב באופן אישי לתשלום.\n' +
            '-          תוקף ההצעה הינו ל-7 ימים.\n' +
            ' \t\t\t\t\t\tחתימת המזמין : ------------'
        },
      ]
    case T.RETURN_CERTIFICATE:
    case T.TRANSACTION_ACCOUNT:
    case T.INVOICE:
    case T.INVOICE_RECEIPT:
    case T.CREDIT_INVOICE:
    case T.RECEIPT:
    case T.DONATION_RECEIPT:
    case T.PURCHASE_ORDER:
    case T.RECEIVING_DEPOSIT:
    case T.WITHDRAWAL_DEPOSIT:
    default:
      return []
  }
};

export const footerTextSuggestions = (type) => {
  switch (type) {
    case T.PRICE_QUOTE:
      return [
        {
          label: 'חלופה 1',
          value: '[1] הצעת מחיר זו אינה כוללת המרת הדו"ח לצורך הגשה לערכאות ו/או לרשות המיסים ו/או לבטוחה (משכנתא). הדו"ח אינו מהווה שומה אחרת כהגדרתה לפי התוספת השלישית לחוק התו"ב לצורך הליך השגה במסגרת תיקון 84.\n' +
            '[2] חוות הדעת תשלח בדוא"ל בקובץ PDF במידה ויידרש עותק פיזי הוא יישלח בדואר.'
        },
        {
          label: 'חלופה 2',
          value: 'בשל מורכבות הנכס ייתכן ונדרש למסמכים נוספים, דבר שיתברר תוך כדי עבודה ובתאום עם מזמין העבודה.'
        },
      ]
    case T.WORK_ORDER:
      return [
        {
          label: 'חלופה 1',
          value: '[1] הזמנת עבודה זו אינה כוללת המרת הדו"ח לצורך הגשה לערכאות ו/או לרשות המיסים ו/או לבטוחה (משכנתא). הדו"ח אינו מהווה שומה אחרת כהגדרתה לפי התוספת השלישית לחוק התו"ב לצורך הליך השגה במסגרת תיקון 84.\n' +
            '[2] חוות הדעת תשלח בדוא"ל בקובץ PDF במידה ויידרש עותק פיזי הוא יישלח בדואר.'
        },
        {
          label: 'חלופה 2',
          value: 'בשל מורכבות הנכס ייתכן ונדרש למסמכים נוספים, דבר שיתברר תוך כדי עבודה ובתאום עם מזמין העבודה.'
        },
      ]
    case T.RETURN_CERTIFICATE:
    case T.TRANSACTION_ACCOUNT:
    case T.INVOICE:
    case T.INVOICE_RECEIPT:
    case T.CREDIT_INVOICE:
    case T.RECEIPT:
    case T.DONATION_RECEIPT:
    case T.PURCHASE_ORDER:
    case T.RECEIVING_DEPOSIT:
    case T.WITHDRAWAL_DEPOSIT:
    default:
      return []
  }
};


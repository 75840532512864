import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import {useExtendedDateRangeContext} from "../../../ExtendedDateRangeContext";
import {useCitiesContext, useStreetsContext} from "../../../../_common/components/CitiesAndStreets";
import FilterDropdown from "../../../../_common/components/FilterDropdown";
import {filterOptions} from '../../../../../../utils/filterOptions';
import {applyFilter} from "../../../../../../utils/prepareApplyFilter";
import FieldRadiobutton from "../../../../_common/components/FieldRadiobutton";
import FiltersLabel from "../../../../_common/components/FiltersLabel";
import styles from './styles.module.scss';

export function ExtendedDateRangeFilters({}) {
  const intl = useIntl();
  const searchByScale = intl.formatMessage({
    id: 'dashboard-page-extendedDateRange-filters-scale-label',
    defaultMessage: 'Scale:',
  });
  const oneWeek = intl.formatMessage({
    id: 'dashboard-page-extendedDateRange-oneWeek',
    defaultMessage: 'One week',
  });
  const twoWeeks = intl.formatMessage({
    id: 'dashboard-page-extendedDateRange-twoWeeks',
    defaultMessage: 'Two weeks',
  });
  const oneMonth = intl.formatMessage({
    id: 'dashboard-page-extendedDateRange-oneMonth',
    defaultMessage: 'One month',
  });
  const searchByDateRange = intl.formatMessage({
    id: 'dashboard-page-extendedDateRange-filters-extendedDateRange-label',
    defaultMessage: 'Date range',
  });
  const searchByCity = intl.formatMessage({
    id: 'filter-placeholder-city',
    defaultMessage: 'City',
  });
  const searchByStreet = intl.formatMessage({
    id: 'filter-placeholder-street',
    defaultMessage: 'Street',
  });

  const extendedDateRangeContext = useExtendedDateRangeContext();
  const extendedDateRangeProps = useMemo(() => {
    return {
      queryParams: extendedDateRangeContext.extendedQueryParams,
      setQueryParams: extendedDateRangeContext.setExtendedQueryParams,
    };
  }, [extendedDateRangeContext]);
  useEffect(() => {
    extendedDateRangeProps.setQueryParams({extendedDateRange, scale, cityID, streetID})
  }, []);

  const initialDateRange = window.localStorage.getItem('extendedDateRange') || 'last 6 periods';
  const initialScale = window.localStorage.getItem('scale') || 'one week';
  const initialCityID = window.localStorage.getItem('cityID') || -1;
  const initialStreetID = window.localStorage.getItem('streetID') || -1;
  const [extendedDateRange, setExtendedDateRange] = useState(initialDateRange);
  const [scale, setScale] = useState(initialScale);
  const [cityID, setCityID] = useState(initialCityID);
  const [streetID, setStreetID] = useState(initialStreetID);

  const {cities} = useCitiesContext();
  const {streets, updateStreets} = useStreetsContext();

  const onChangeCityStreet = (fieldID, value, handleSubmit, setFieldValue, setState) => {
    handleSubmit();
    setFieldValue(fieldID, value);
    setState(value);
    if (fieldID === 'cityID') {
      updateStreets(value);
      if (value === -1) {
        setFieldValue('streetID', -1);
        setStreetID(-1);
      }
    }
    window.localStorage.setItem(fieldID, value);
  };

  useEffect(() => {
    if (Boolean(cityID) && cityID !== -1) {
      updateStreets(cityID)
    }
  }, []);

  const getCommonProps = (handleSubmit, setFieldValue, setState, fieldID) => {

    return {
      onChange: ({value}) => {
        handleSubmit();
        setFieldValue(fieldID, value);
        setState(value);
        window.localStorage.setItem(fieldID, value);
      },
      customContainerStyles: 'mx-4',
      customClassNames: {container: 'w-120px h-40px'}
    }
  };

  return (
    <Formik
      initialValues={{
        extendedDateRange,
        scale,
        cityID,
        streetID,
      }}
      onSubmit={(values) => {
        applyFilter(values, extendedDateRangeProps);
      }}
      enableReinitialize={false}
    >
      {({
          values,
          handleSubmit,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit}>
          <div className={'w-75 d-flex mx-auto pt-8 px-12 position-relative'}>
            <FilterDropdown
              name="cityID"
              value={filterOptions(`${values.cityID}`)(cities)}
              onChangeValue={({value}) => onChangeCityStreet('cityID', value, handleSubmit, setFieldValue, setCityID)}
              options={cities}
              placeholder={searchByCity}
              onClearValue={() => onChangeCityStreet('cityID', -1, handleSubmit, setFieldValue, setCityID)}
              customContainerClassname={`${styles.customCityFilter} col-md-2`}
            />
            <FilterDropdown
              name="streetID"
              value={filterOptions(`${values.streetID}`)(streets || [])}
              onChangeValue={({value}) => onChangeCityStreet('streetID', value, handleSubmit, setFieldValue, setStreetID)}
              options={streets}
              placeholder={searchByStreet}
              onClearValue={() => onChangeCityStreet('streetID', -1, handleSubmit, setFieldValue, setStreetID)}
              customContainerClassname={`${styles.customStreetFilter} col-md-2`}
              disabled={!Boolean(cityID) || cityID === -1}
            />
            <div className={'d-flex align-items-center'}>
              <FiltersLabel label={searchByScale}/>
              <FieldRadiobutton
                fieldId={'scale'}
                value={'one week'}
                label={oneWeek}
                {...getCommonProps(handleSubmit, setFieldValue, setScale, 'scale')}
              />
              <FieldRadiobutton
                fieldId={'scale'}
                value={'two weeks'}
                label={twoWeeks}
                {...getCommonProps(handleSubmit, setFieldValue, setScale, 'scale')}
              />
              <FieldRadiobutton
                fieldId={'scale'}
                value={'one month'}
                label={oneMonth}
                {...getCommonProps(handleSubmit, setFieldValue, setScale, 'scale')}
              />
            </div>
            <div className={'d-flex align-items-center ml-6'}>
              <FiltersLabel label={searchByDateRange}/>
              <FieldRadiobutton
                fieldId={'extendedDateRange'}
                value={'last 6 periods'}
                label={'6'}
                {...getCommonProps(handleSubmit, setFieldValue, setExtendedDateRange, 'extendedDateRange')}
              />
              <FieldRadiobutton
                fieldId={'extendedDateRange'}
                value={'last 10 periods'}
                label={'10'}
                {...getCommonProps(handleSubmit, setFieldValue, setExtendedDateRange, 'extendedDateRange')}
              />
              <FieldRadiobutton
                fieldId={'extendedDateRange'}
                value={'last 12 periods'}
                label={'12'}
                {...getCommonProps(handleSubmit, setFieldValue, setExtendedDateRange, 'extendedDateRange')}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default ExtendedDateRangeFilters;

export const subjects = {
  DASHBOARD_PAGE: '/dashboard-page',
  INSPECTIONS_PAGE: '/inspections-page',
  CUSTOMERS_ENTITY_PAGE: '/customers-entity',
  OLD_INSPECTIONS_PAGE: '/old-inspections-page',
  OLD_INSPECTION_DETAILS_PAGE: '/old-inspection-details',
  NEW_INSPECTION_DETAILS_PAGE: '/inspection-details',
  CUSTOMER_DETAILS_PAGE: '/customer-details',
  ORDERS_PAGE: '/orders-page',
  PRICE_QUOTES_PAGE: '/price-quotes-page',
  TASKS_PAGE: '/tasks-page',
}

export const permissionTypes = {
  FULL: 'full',
  VIEW: 'view',
  BLOCK: 'block'
}

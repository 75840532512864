import React from 'react';
import {FormattedMessage} from 'react-intl';
import styles from '../styles.module.scss';
import FieldInput from '../../../_common/components/FieldInput';
import {
  limitFloatValueLength,
  limitIntegerValueLength
} from '../../../_common/utils/changeInputValue';

export const SectionBuildingInfo = ({formik, fieldNames}) => {
  const {values} = formik;

  return (
    <div className={`${styles.sectionContainer} mr-6`}>
      <div className={styles.formContainer}>
        <>
          <h4>
            <FormattedMessage
              id="inspection-details-page-section-propertyInfo"
              defaultMessage="Property info"
            />
          </h4>
          <div className={styles.itemContainer}>
            <div className={styles.labelContainer}>
              <FormattedMessage
                id="inspection-details-page-lotArea-label"
                defaultMessage="Lot area"
              />
            </div>
            <FieldInput
              fieldId={fieldNames.landRegisteredLotArea}
              value={values.landRegisteredLotArea}
              onChange={limitFloatValueLength(7)}
            />
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.labelContainer}>
              <FormattedMessage
                id="inspection-details-page-registeredArea-label"
                defaultMessage="Registered area"
              />
            </div>
            <FieldInput
              fieldId={fieldNames.houseRegisteredArea}
              value={values.houseRegisteredArea}
              onChange={limitFloatValueLength(7)}
            />
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.labelContainer}>
              <FormattedMessage
                id="inspection-details-page-roomCount-label"
                defaultMessage="Room count"
              />
            </div>
            <FieldInput
              fieldId={fieldNames.houseRoomCount}
              value={values.houseRoomCount}
              onChange={limitIntegerValueLength(2)}
            />
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.labelContainer}>
              <FormattedMessage
                id="inspection-details-page-floor-label"
                defaultMessage="Floor"
              />
            </div>
            <FieldInput
              fieldId={fieldNames.floor}
              value={values.floor}
              onChange={limitIntegerValueLength(2)}
            />
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.labelContainer}>
              <FormattedMessage
                id="inspection-details-page-floorCount-label"
                defaultMessage="Floor count"
              />
            </div>
            <FieldInput
              fieldId={fieldNames.floorCount}
              value={values.floorCount}
              onChange={limitIntegerValueLength(2)}
            />
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.labelContainer}>
              <FormattedMessage
                id="inspection-details-page-buildYear-label"
                defaultMessage="Build year"
              />
            </div>
            <FieldInput
              fieldId={fieldNames.buildingAge}
              value={values.buildingAge}
              onChange={limitIntegerValueLength(4)}
            />
          </div>
        </>
      </div>
    </div>
  );
};

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {refreshToken} from '../_redux/authActions';
import config from '../../../../config/common';

function withRefreshToken(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      this.checkAndRefreshToken();
    }

    componentDidUpdate() {
      this.checkAndRefreshToken();
    }

    checkAndRefreshToken() {
      const isExpired = this.checkTokenExpire();
      if (isExpired) {
        this.refreshToken()
          .catch(err => {
            this.props.history.push("/logout");
          });
      }
    }

    checkTokenExpire() {
      const {expire} = this.props;
      if (!expire) return false;

      const expireMoment = moment.unix(expire).subtract(config.refreshGap);
      const currentMoment = moment();

      return currentMoment.isSameOrAfter(expireMoment);
    }

    refreshToken() {
      return this.props.refreshToken();
    }

    render() {
      const {
        expire,
        refreshToken,
        ...rest
      } = this.props;

      return <WrappedComponent {...rest}/>;
    }
  }
}

const mapStateToProps = ({
                           auth: {expire},
                         }) => {
  return {expire}
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {refreshToken}
  ),
  withRefreshToken
);

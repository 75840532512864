import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styles from './styles.module.scss';

const PDFDocument = ({className, file, onLoad}) => {
  const [loadedPages, setLoadedPages] = useState([]);

  useEffect(() => {
    if ((loadedPages.length === file.convertedPages.length) && onLoad) {
      onLoad(loadedPages);
    }
  }, [loadedPages]);

  const mapPages = () => {
    return file.convertedPages
      .map((src, index) => {
        const handleImageLoad = image => {
          setLoadedPages(prevState => {
            return [...prevState, {image, page: index + 1}];
          });
        };

        return (
          <Image
            src={src}
            alt={`page-${index}`}
            onImageLoad={handleImageLoad}
            key={index}
          />
        );
      });
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {mapPages()}
    </div>
  );
};

PDFDocument.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number,
  onLoad: PropTypes.func,
};

export default PDFDocument;

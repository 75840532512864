import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getBuildingOuterLayerValue} from '../../../utils/texts';
import styles from './styles.module.scss';

export const TextItem16 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingOuterLayer) &&
        (
          <div className={classnames(styles.itemText, styles.innerPadding, className)}>
            <span className={styles.bold}>חיפוי חיצוני: </span>
            {` ${getBuildingOuterLayerValue(context.data.pdfData.buildingOuterLayer, context.data.pdfData.buildingOuterLayerText)}.`}
          </div>
        )}
    </div>
  );
});

TextItem16.propTypes = {
  className: PropTypes.string
};

export default [
  TextItem16,
];

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Field} from 'formik';
import styles from './styles.module.scss';

export const StyledFieldInput = ({
                                   fieldId,
                                   type = 'text',
                                   label = null,
                                   customContainerStyles = null,
                                   customLabelStyles = null,
                                   customInputStyles = null,
                                   onChange,
                                   value,
                                   ...rest
                                 }) => {
  return (
    <label className={classnames(styles.inputContainer, customContainerStyles)}>
      <div className={classnames(styles.labelContainer, customLabelStyles)}>
        {label}
      </div>
      <input
        dir={type === 'email' ? 'ltr' : 'rtl'}
        className={`form-control form-control-lg form-control-solid ${customInputStyles} ${type === 'email' ? 'text-left' : ''}`}
        id={fieldId}
        name={fieldId}
        type={type}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </label>
  );
};

const FieldInput = ({
                      fieldId,
                      type = 'text',
                      label = null,
                      customContainerStyles = null,
                      customLabelStyles = null,
                      customInputStyles = null,
                      onChange,
                      ...rest
                    }) => {

  const handleInputChange = ({form, field}) => e => {
    const value = e.target.value;
    if (onChange) {
      const changedValue = onChange(e);
      form.setFieldValue(field.name, changedValue);
    } else {
      form.setFieldValue(field.name, value);
    }
  };

  return (
    <Field
      id={fieldId}
      name={fieldId}
    >
      {({form, field}) => (
        <label
          className={classnames(styles.inputContainer, customContainerStyles)}>
          <div className={classnames(styles.labelContainer, customLabelStyles)}>
            {label}
          </div>
          <input
            dir={type === 'email' ? 'ltr' : 'rtl'}
            className={`form-control form-control-lg form-control-solid ${customInputStyles} ${type === 'email' ? 'text-left' : ''}`}
            type={type}
            onChange={handleInputChange({form, field})}
            value={field.value}
            {...rest}
          />
        </label>
      )}
    </Field>
  );
};

FieldInput.propTypes = {
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel']),
  label: PropTypes.node,
  customContainerStyles: PropTypes.string,
  customLabelStyles: PropTypes.string,
  customInputStyles: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldInput;

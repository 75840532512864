import React from 'react';
import {useCustomerDetailsUIContext} from "../CustomerDetailsUIContext";
import UploadedFile from "../UploadedFile";
import Empty from "../../../_common/components/Empty";
import ButtonUploadFile from "../ButtonUploadFile";
import AnimatedUploadingFilePopUp from "../../../_common/components/AnimatedUploadingFilePopUp";

const TabCustomerFiles = () => {
  const {fileList, loadingFile} = useCustomerDetailsUIContext();
  return (
    <div>
      <AnimatedUploadingFilePopUp show={loadingFile}/>
      <div className='h-40px mb-2 d-flex justify-content-start'>
        <ButtonUploadFile/>
      </div>
      {fileList.length > 0
        ?
        (<div className='w-100 mt-4 align-content-start h-1000px overflow-auto d-flex flex-wrap justify-content-start px-2'>
          {fileList.map((item, ind) => (
            <div className='m-4 h-225px' key={ind}>
              <UploadedFile
                key={ind}
                {...item}
              />
            </div>
          ))}
        </div>)
        : (
          <div className='w-100 h-750px mt-4 d-flex align-items-center'>
            <Empty/>
          </div>
        )
      }
    </div>
  );
};

export default TabCustomerFiles;

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingDate} from '../../../utils/formattingHelper';
import styles from './styles.module.scss';

const ShumaType3GrayBox = React.forwardRef(({}, ref) => {
  const {
    data: {
      userData: {
        gender,
      },
      pdfData: {
        previousLastInspectionDate,
        bankName,
        warningNote,
        dangerousStructure,
        cityName,
      }
    },
  } = useCompleteInspectionContext();
  const genderSuffix1 = (gender === 2) ? 'ה' : '';
  const genderSuffix2 = (gender === 2) ? 'ת' : '';
  const genderPrefix = (gender === 2) ? 'ת' : 'י';
  const dangerousStructureValue = (dangerousStructure === 1)
    ? 'כמסוכן'
    : 'כלא מסוכן';

  return (
    <div ref={ref} className={styles.innerPadding}>
      <ul className={styles.grayBgArea}>
        <li className={styles.item}>
          הנני מצהיר{genderSuffix1} כי למיטב ידיעתי העובדות עליהן מתבססת חוו"ד
          נכונות וכי המידע המשפטי המוצג בחוו"ד זו הינו המידע המשפטי עליו מבוסס
          הדו"ח. חוו"ד זו אינה מהווה חוו"ד משפטי ו/או תחליף ליעוץ משפטי.
        </li>
        <li className={styles.item}>
          פרטי רישום הזכויות במקרקעין והמצב התכנוני אומתו ע"י הח"מ במקורות
          המידע הגלויים לציבור במסגרת חופש המידע.
        </li>
        <li className={styles.item}>
          אין בחוו"ד זו משום חוו"ד הנדסית ו/או אדריכלית ואין בה כדי להעיד על
          נתוני איכות הסביבה, לרבות קיומן של אנטנות סלולריות, זיהום קרקע
          וכיוצ"ב.
        </li>
        <li className={styles.item}>
          הנני מצהיר{genderSuffix1} כי חוו"ד זו נערכה עפ"י כללי האתיקה של לשכת
          שמאי המקרקעין וכי אין לי עניין או נגיעה אישית לנכס הנדון . חוו"ד זו
          נעשתה עפ"י מיטב הבנתי,ידיעותיי וניסיוני המקצועי.
        </li>
        <li className={styles.item}>
          כל הנתונים והערכים בחוו"ד נכונים ליום הקובע לחוו"ד. אין הח"מ מתחייבת
          כי אלה יהיו הערכים בעתיד.אם בכלל.
        </li>
        <li className={styles.item}>
          חוו"ד זו נערכה למטרה המנויה לעיל עבור מזמין הדו"ח. אחריותה של הח"מ
          מוגבלת למזמין הדו"ח ולמטרת הדו"ח בלבד. שימוש שלא בהתאם לאמור לעיל
          יעשה לאחר קבלת אישור מראש ובכתב מאת הח"מ בלבד. הח"מ
          לא {genderPrefix}היה אחראי{genderSuffix2} כלפי מאן דהו אשר יעשה
          שימוש בשומה זו ללא אישור כאמור לעיל.
        </li>
        {Boolean(previousLastInspectionDate) && (
          <li className={styles.item}>
            <span>נערכה חוו"ד קודמת לנכס שבנדון ע"י הח"מ בתאריך </span>
            {` ${formattingDate(previousLastInspectionDate)}.`}
          </li>
        )}
        <li className={styles.item}>
          מסמך זה נעשה בהתאם למבנה והנחיות הבנק.
        </li>
        <li className={styles.item}>
          חוו"ד זו אינה המלצה או אי המלצה לעשיית עסקה.
        </li>
        <li className={styles.item}>
          <span>שומה זו נועדה אך ורק ל</span>
          {`${bankName} `}
          <span>ואין לעשות בה כל שימוש אחר.</span>
        </li>
        {(warningNote !== 1) && (
          <li className={styles.item}>
            לא רשומה על הנכס הערת אזהרה כלשהי.
          </li>
        )}
        {(dangerousStructure === 1 || dangerousStructure === 2) &&
          <li className={styles.item}>
        <span>
          לתשומת לב הבנק, בהתאם לחוו"ד הנדסית מאת אגף מבנים מסוכנים
          בעיריית
        </span>
            {` ${cityName} `}
            <span>הוכרז המבנה</span>
            {` ${dangerousStructureValue}.`}
          </li>
        }
        <li className={styles.item}>
          הערכת הנכס בוצעה על ידי לפי פניית הלווים אליי.
        </li>
      </ul>
    </div>
  );
});

export default ShumaType3GrayBox;

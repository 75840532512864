import {pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const convertPdfDocumentToPngArray = async (url) => {
  const data = atob(url);
  const loadingTask = pdfjs.getDocument({data});
  const pdf = await loadingTask.promise;
  const totalPages = pdf.numPages;
  const unresolvedPngArray = new Array(totalPages)
    .fill('')
    .map(async (item, index) => {
      return await covertPdfPageToPng({data, pageNumber: index + 1})
    })
  const pngArray = await Promise.all(unresolvedPngArray);
  return pngArray.filter(Boolean);
}

export const covertPdfPageToPng = async ({data, pageNumber}) => {
  let result = null;
  const tempCanvas = document.createElement('canvas');
  tempCanvas.style.display = 'none';

  try {
    const scale = 1;
    /*const tempCanvas = document.createElement('canvas');
    tempCanvas.style.display = 'none';*/
    document.body.appendChild(tempCanvas);
    const context = tempCanvas.getContext('2d');
    const loadingTask = pdfjs.getDocument({data});
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({scale});
    tempCanvas.height = viewport.height;
    tempCanvas.width = viewport.width;
    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    const renderTask = page.render(renderContext);
    await renderTask.promise;
    result = tempCanvas.toDataURL('image/png');
  } catch (e) {
    console.error(e);
  } finally {
    document.body.removeChild(tempCanvas);
  }

  return result
};

import React, {useEffect} from 'react';
import PriceQuoteForm from "./PriceQuoteForm";
import {usePriceQuoteEditUIGlobalContext} from "./PriceQuoteEditUIContext";


const PriceQuoteEdit = () => {
  const {
    formik,
    id,
    getData,
    closeModal,
    editPriceQuote,
    loadingData,
    uploadFile,
    uploadingFile,
    handleDeleteFile,
    handleDownloadFile
  } = usePriceQuoteEditUIGlobalContext();

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  return (
    <PriceQuoteForm
      id={id}
      formik={formik}
      closeModal={closeModal}
      handleApprove={() => {
        editPriceQuote();
        closeModal();
      }}
      loadingData={loadingData}
      uploadFile={uploadFile}
      uploadingFile={uploadingFile}
      handleDeleteFile={handleDeleteFile}
      handleDownloadFile={handleDownloadFile}
    />
  );
};

export default PriceQuoteEdit;

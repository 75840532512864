import {actions} from "../reducers/OrdersPageTableReduser";
import axios from "axios";


export const GET_ORDERS_LIST = "orders/getList";

export const getOrdersList = action => ({
                                          orderNumber = -1,
                                          createdFromDate = "",
                                          createdToDate = "",
                                          dueFromDate = "",
                                          dueToDate = "",
                                          customerID = -1,
                                          status = -1,
                                          shumaID = -1,
                                          requestedPageNumber = 0,
                                        }) => dispatch => {

  const data = {
    orderNumber: parseInt(orderNumber, 10),
    createdFromDate,
    createdToDate,
    dueFromDate,
    dueToDate,
    customerID: parseInt(customerID, 10),
    status: parseInt(status, 10),
    shumaID: parseInt(shumaID, 10),
    requestedPageNumber: parseInt(requestedPageNumber, 10),
  };

  return axios.post(GET_ORDERS_LIST, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {totalPageCount, currentPageNumber, list, totalResultCount} = body;

      dispatch(action({
        totalPageCount,
        currentPageNumber,
        orders: list,
        totalResultCount,
      }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError(error));
    });
};

export const getOrders = getOrdersList(actions.ordersFetched);
export const getNextOrders = getOrdersList(actions.ordersAppended);

import {actionTypes} from "../reducers/FileListReducer";
import axios from "axios";
import {downloadFile} from "../../../../utils/downloadFile";

export const GET_FILE_LIST = "/files/getFileList";
export const UPLOAD_FILE = "/file/upload/upload_file";
export const DELETE_FILE = "/files/deleteFile";
export const DOWNLOAD_FILE = "/files/getFile";
export const COPY_ORDER_FILES = "files/copyOrderFilesToShuma";

export const getFileList = shumaID => dispatch => {
  const data = {shumaID: parseInt(shumaID, 10)};
  return axios.post(GET_FILE_LIST, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {fileList} = body;

      dispatch({
        type: actionTypes.fileListFetched,
        payload: {fileList: fileList},
      });
    })
    .catch((err) => {
      err.clientMessage = "Can't find data";
      dispatch({
        type: actionTypes.catchError,
        payload: err,
      });
    });
};

export const uploadFile = (fileToSave, shumaID, fileTypeID, fullFileName, payload = {}) => {

  return () => {
    const formData = new FormData();
    formData.append('fileToSave', fileToSave);
    formData.append('shumaID', shumaID);
    formData.append('fullFileName', fullFileName);
    formData.append('fileTypeID', fileTypeID);
    Object.keys(payload).forEach(key => {
      formData.append(`${key}`, payload[key]);
    });

    return axios.post(UPLOAD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .catch((err) => {
        console.error('uploadFile returns error:');
        console.error(err);
      });
  };
};

export const getFile = ({shumaID, fileID, fileName}) => () => {
  const data = {
    shumaID: parseInt(shumaID, 10),
    fileID: parseInt(fileID, 10),
  };

  return axios.post(DOWNLOAD_FILE, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      try {
        downloadFile(`data:;base64,${body.file}`, fileName);
      } catch (exc) {
        console.log("Save Blob method failed with the following exception.");
        console.log(exc);
      }

      return body.file;
    })
    .catch((err) => {
      console.error('getFile returns error:');
      console.error(err);
    });
};

export const deleteFile = ({shumaID, fileID}) => () => {
  const data = {
    shumaID: parseInt(shumaID, 10),
    fileID: parseInt(fileID, 10),
  };

  return axios.post(DELETE_FILE, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      return body;
    })
    .catch((err) => {
      console.error('deleteFile returns error:');
      console.error(err);
    });
};

export const copyOrderFileToShuma = ({
                                       orderID,
                                       shumaID,
                                       orderFileID,
                                       fileTypeID
                                     }) => () => {
  const data = {
    orderID: parseInt(orderID, 10),
    shumaID: parseInt(shumaID, 10),
    orderFileID: parseInt(orderFileID, 10),
    fileTypeID: parseInt(fileTypeID, 10),
  };

  return axios.post(COPY_ORDER_FILES, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch((err) => {
      console.error(err);
    });
};



import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useField} from 'formik';
import styles from './styles.module.scss';

const FieldCheckbox = ({
                         fieldId,
                         ...rest
                       }) => {
  const [field, meta] = useField(fieldId);
  return (
    <InputCheckbox
      meta={meta}
      {...field}
      {...rest}
    />
  );
};

export const InputCheckbox = ({
                                fieldId,
                                value,
                                label = null,
                                customContainerStyles = null,
                                disabled,
                                meta,
                                ...rest
                              }) => {
  return (
    <label className={classnames(
      styles.inputContainer,
      {[styles.disabled]: disabled},
      customContainerStyles)}
    >
      <div className={styles.checkboxContainer}>
        <input
          className={classnames(styles.input)}
          type="checkbox"
          checked={value}
          disabled={disabled}
          {...rest}
        />
        <div className={classnames(styles.checkbox, {
          [styles.checked]: value,
          [styles.errorBorder]: meta?.error && meta?.touched
        })}
        />
        <div className={styles.labelContainer}>
          {label}
        </div>
      </div>
    </label>
  );
};

FieldCheckbox.propTypes = {
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.oneOf(['text', 'checkbox', 'number', 'email', 'password', 'tel']),
  label: PropTypes.node,
  info: PropTypes.node,
  customContainerStyles: PropTypes.string,
};

export default FieldCheckbox;

import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import Select from 'react-select';
import FieldInput from '../../../_common/components/FieldInput';
import {StyledFieldDatepicker} from '../../../_common/components/FieldDatepicker';
import {
  divideThousandsInteger,
  formatStringToNumber,
  limitIntegerValueLength
} from '../../../_common/utils/changeInputValue';
import {inspectionDetails} from '../../../_common/selectStyles/customSelectStyles';
import {useCitiesContext, useStreetsContext} from '../../../_common/components/CitiesAndStreets';
import styles from '../styles.module.scss';

export function SectionGeneralInfo({formik, fieldNames}) {
  const {cities} = useCitiesContext();
  const {streets, updateStreets} = useStreetsContext();
  const {values, setFieldValue} = formik;
  const {cityID} = values;

  const filterOptions = (values) => (options) => {
    return options.filter(({value}) => value === values);
  };

  useEffect(() => {
    if (cityID) {
      updateStreets(cityID);
    }
  }, [cityID]);

  const onChangeCityID = (value) => {
    setFieldValue(fieldNames.cityID, value);
    setFieldValue(fieldNames.streetID, '');
  };
  const onChangeStreetID = (value) => {
    setFieldValue(fieldNames.streetID, value);
  };
  const onChangeDate = (date, setFieldValue) => {
    setFieldValue(fieldNames.officialDate, moment(date).format('YYYY-MM-DD'));
  };

  return (
    <div className={`${styles.sectionContainer} mr-6`}>
      <div className={styles.formContainer}>
        <h4>
          <FormattedMessage
            id="inspection-details-page-section-generalInfo"
            defaultMessage="General info"
          />
        </h4>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <span className="text-danger">*</span>
            <FormattedMessage
              id="inspection-details-page-gush-label"
              defaultMessage="Gush"
            />
          </div>
          <FieldInput
            fieldId={fieldNames.gush}
            value={values.gush}
            onChange={limitIntegerValueLength(6)}
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <span className="text-danger">*</span>
            <FormattedMessage
              id="inspection-details-page-helka-label"
              defaultMessage="Helka"
            />
          </div>
          <FieldInput
            fieldId={fieldNames.helka}
            value={values.helka}
            onChange={limitIntegerValueLength(6)}
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="inspection-details-page-ttHelka-label"
              defaultMessage="ttHelka"
            />
          </div>
          <FieldInput
            fieldId={fieldNames.ttHelka}
            value={values.ttHelka}
            onChange={limitIntegerValueLength(4)}
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="filter-placeholder-city"
              defaultMessage="City"
            />
          </div>
          <Select
            name={fieldNames.cityID}
            value={filterOptions(values.cityID)(cities)}
            onChange={({value}) => onChangeCityID(value)}
            options={cities}
            styles={inspectionDetails}
            isSearchable={true}
            isAsync={true}
            placeholder={
              <FormattedMessage
                id="select"
                defaultMessage="Select"
              />
            }
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="filter-placeholder-street"
              defaultMessage="Street"
            />
          </div>
          <Select
            name={fieldNames.streetID}
            value={streets ? filterOptions(values.streetID)(streets) : null}
            onChange={({value}) => onChangeStreetID(value)}
            options={streets}
            styles={inspectionDetails}
            isSearchable={true}
            isAsync={true}
            defaultOptions={true}
            isDisabled={!values.cityID}
            placeholder={
              <FormattedMessage
                id="select"
                defaultMessage="Select"
              />
            }
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="inspection-details-page-houseNumber-label"
              defaultMessage="houseNumber"
            />
          </div>
          <FieldInput
            fieldId={fieldNames.houseNumber}
            value={values.houseNumber}
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="inspection-details-page-date-label"
              defaultMessage="Date"
            />
          </div>
          <div className={styles.wrapperDatepicker}>
            <StyledFieldDatepicker
              className={styles.fieldInput}
              value={values[fieldNames.officialDate]}
              onChange={date => onChangeDate(date, setFieldValue)}
              placeholder={moment(values[fieldNames.officialDate]).format('DD-MM-YYYY')}
            />
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="inspection-details-page-pricePerSQM-label"
              defaultMessage="Price per SQM"
            />
          </div>
          <FieldInput
            fieldId="averageSqmPrice"
            value={values.averageSqmPrice}
            onChange={limitIntegerValueLength(8)}
          />
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.labelContainer}>
            <FormattedMessage
              id="inspection-details-page-price-label"
              defaultMessage="Price"
            />
          </div>
          <FieldInput
            fieldId={fieldNames.estimatePriceFinal}
            value={divideThousandsInteger(values.estimatePriceFinal)}
            onChange={formatStringToNumber}
          />
        </div>
      </div>
    </div>
  );
}

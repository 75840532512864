import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import classnames from 'classnames';
import styles from './styles.module.scss';

const CustomerProps = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfDataCustomers,
    },
  } = useCompleteInspectionContext();

  const textName = 'שמות הלווים: ';

  const mapCustomers = (customers) => customers.map((customer, index) => {
    const textID = 'ת.ז ';

    return (
      <div key={index} className={styles.customerPropsLine}>
        <span className={styles.customerPropsName}>
          {customer.name}
        </span>
        {Boolean(customer.idNumber)
          ? (
            <span className={styles.customerPropsID}>
          <span>{textID}</span>
          <span>{`${customer.idNumber}`}</span>
        </span>
        )
        : ''
        }
      </div>
    );
  });

  return (
    <div
      className={classnames(styles.innerPadding, styles.textRegular, styles.customerPropsContainer, styles.flex)}
      ref={ref}
    >
      <span className={classnames(styles.bold)}>{textName}</span>
      <div>{mapCustomers(pdfDataCustomers)}</div>
    </div>
  );
});

export default CustomerProps;

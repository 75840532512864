import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import InspectionsPage from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const inspectionsPageRoute = (
  <ProtectedRoute
    subject={S.INSPECTIONS_PAGE}
    path={P.INSPECTIONS_PAGE}
    component={InspectionsPage}
  />
);

import React from 'react';
import {FileItem} from "./FileItem";
import classnames from 'classnames';
import {useOrderDetailsUIGlobalContext} from '../OrderDetailsUIGlobalContext';
import {useOrderDetailsUIFilesContext} from '../OrderDetailsUIFilesContext';
import styles from "../styles.module.scss";

export function FileList() {
  const {textContent} = useOrderDetailsUIGlobalContext();
  const {fileList} = useOrderDetailsUIFilesContext();

  const {
    tabOrderDocumentsGridColumnUploadDate,
    tabOrderDocumentsGridColumnName,
    tabOrderDocumentsGridColumnType,
    tabOrderDocumentsGridColumnDocType,
    tabOrderDocumentsGridColumnDownload,
    tabOrderDocumentsGridColumnDelete,
  } = textContent;

  const mapFiles = file => {

    return (
      <FileItem {...file}/>
    )
  };

  const scrollPaddingStyle = fileList.length > 9 ? '13px' : '0px';
  const scrollPaddingClassName = fileList.length > 9 ? 'pr-11' : '';

  return (
    <div className='pt-4 pb-4'>
      <div className={classnames(styles.filesList, scrollPaddingClassName)}>
        <div className="font-weight-bolder">
          {tabOrderDocumentsGridColumnUploadDate}
        </div>
        <div className="font-weight-bolder">
          {tabOrderDocumentsGridColumnName}
        </div>
        <div className="font-weight-bolder">
          {tabOrderDocumentsGridColumnType}
        </div>
        <div className="font-weight-bolder">
          {tabOrderDocumentsGridColumnDocType}
        </div>
        <div className="font-weight-bolder">
          {tabOrderDocumentsGridColumnDelete}
        </div>
        <div className="font-weight-bolder">
        {tabOrderDocumentsGridColumnDownload}
      </div>
      </div>
      <div
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '495px',
          paddingLeft: scrollPaddingStyle
        }}
      >
        {fileList.map(mapFiles)}
      </div>
    </div>
  );
}

FileList.propTypes = {};

import axios from 'axios';
import {actions} from '../reducers/CustomerGreenInvoiceReducer';
import {actionsDocumentData} from '../reducers/CustomerDocumentDataReducer';
import {actionsPreviewDocument} from '../reducers/CustomerGreenInvoicePreviewDocumentReducer';

export const SEARCH_DOCUMENT = '/document/search';
export const GET_DOCUMENT_DATA = '/documents';
export const CREATE_PRICE_QUOTE = '/document/create/priceQuote';
export const CREATE_WORK_ORDER = '/document/create/workOrder';
export const CREATE_INVOICE = '/document/create/invoice';
export const CREATE_RECEIPT = '/document/create/receipt';
export const CREATE_TRANSACTION_ACCOUNT = '/document/create/transactionAccount';
export const CREATE_INVOICE_RECEIPT = '/document/create/receiptTaxInvoice';
export const PREVIEW_PRICE_QUOTE = '/document/preview/priceQuote';
export const PREVIEW_WORK_ORDER = '/document/preview/workOrder';
export const PREVIEW_INVOICE = '/document/preview/invoice';
export const PREVIEW_RECEIPT = '/document/preview/receipt';
export const PREVIEW_TRANSACTION_ACCOUNT = '/document/preview/transactionAccount';
export const PREVIEW_INVOICE_RECEIPT = '/document/preview/receiptTaxInvoice';
export const CREATE_PAYMENT_LINK = '/payments/form';

export const getDocuments = (clientId,
                             greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(SEARCH_DOCUMENT, {clientId}, {
    headers: {
      'gi-api-key': greenInvoiceApiKey,
      'gi-api-secret': greenInvoiceSecret
    }
  })
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.fetchDocuments(body))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
    });
};

export const getDocumentData = (documentId,
                             greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  const dynamicURL = `/documents/${documentId}`;
  return axios.get(dynamicURL, {
    headers: {
      'gi-api-key': greenInvoiceApiKey,
      'gi-api-secret': greenInvoiceSecret
    },
  })
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actionsDocumentData.fetchDocumentData(body))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
    });
};

// Create documents start

export const createPriceQuote = (data,
                                 greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_PRICE_QUOTE,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch(error => {

      error.clientMessage = 'Can\'t create document';
    });
};

export const createWorkOrder = (data,
                                greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_WORK_ORDER,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create document';
    });
};

export const createInvoice = (data,
                              greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_INVOICE,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create document';
    });
};

export const createReceipt = (data,
                              greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_RECEIPT,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create document';
    });
};

export const createTransactionAccount = (data,
                                         greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_TRANSACTION_ACCOUNT,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create document';
    });
};

export const createInvoiceReceipt = (data,
                                     greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_INVOICE_RECEIPT,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create document';
    });
};

// Create documents end


// Preview document start

export const previewPriceQuote = (data,
                                  greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    PREVIEW_PRICE_QUOTE,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode, body} = response.data;
      const {file} = body;
      dispatch(actionsPreviewDocument.fetchDocumentLink({file}));

      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t preview document';
    });
};

export const previewWorkOrder = (data,
                                 greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    PREVIEW_WORK_ORDER,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode, body} = response.data;
      const {file} = body;
      dispatch(actionsPreviewDocument.fetchDocumentLink({file}));

      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t preview document';
    });
};

export const previewInvoice = (data,
                               greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    PREVIEW_INVOICE,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode, body} = response.data;
      const {file} = body;
      dispatch(actionsPreviewDocument.fetchDocumentLink({file}));

      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t preview document';
    });
};

export const previewReceipt = (data,
                               greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    PREVIEW_RECEIPT,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode, body} = response.data;
      const {file} = body;
      dispatch(actionsPreviewDocument.fetchDocumentLink({file}));

      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t preview document';
    });
};

export const previewTransactionAccount = (data,
                                          greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    PREVIEW_TRANSACTION_ACCOUNT,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode, body} = response.data;
      const {file} = body;
      dispatch(actionsPreviewDocument.fetchDocumentLink({file}));

      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t preview document';
    });
};

export const previewInvoiceReceipt = (data,
                                      greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    PREVIEW_INVOICE_RECEIPT,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {
      const {errorCode, body} = response.data;
      const {file} = body;
      dispatch(actionsPreviewDocument.fetchDocumentLink({file}));

      if (errorCode < 0) throw new Error();
    })
    .catch(error => {
      error.clientMessage = 'Can\'t preview document';
    });
};

// Preview document end

export const createPaymentLink = (data,
                                 greenInvoiceApiKey, greenInvoiceSecret
) => dispatch => {
  return axios.post(
    CREATE_PAYMENT_LINK,
    data,
    {
      headers: {
        'gi-api-key': greenInvoiceApiKey,
        'gi-api-secret': greenInvoiceSecret
      }
    }
  )
    .then(response => {

      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create payment link';
    });
};

export const normalizeFormValues = values => {
  const result = {};
  Object.keys(values).forEach(key => {
    const value = values[key];
    if (!Boolean(value)) {
      result[key] = null;
    } else {
      result[key] = value;
    }
    if (value !== null && typeof value === 'string' && value.length === 0) {
      result[key] = null;
    }
    if (value !== null && typeof value === 'object' && 'value' in value) {
      result[key] = value.value;
    }
    if (value !== null && typeof value === 'object' && value instanceof Date) {
      const reducedDate = new Date(value);
      reducedDate.setHours(-value.getTimezoneOffset() / 60); //compensations of current timezone offset
      reducedDate.setMinutes(0);
      reducedDate.setSeconds(0);
      reducedDate.setMilliseconds(0);
      result[key] = reducedDate.getTime();
    }
  });

  return result;
};

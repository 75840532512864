import React from 'react';
import {useIntl} from "react-intl";
import moment from "moment";
import {useExtendedDateRangeContext} from "../../ExtendedDateRangeContext";
import {Chart} from "./Chart";
import {getOptionsBar, getOptionsLine} from "../../constants/chartsProps";


export const ExtendedDateRange = () => {
  const intl = useIntl();
  const headerPriceTime = intl.formatMessage({
    id: 'dashboard-page-priceTime-header',
    defaultMessage: 'Average price per square meter by month',
  });
  const headerTasksStatus = intl.formatMessage({
    id: 'dashboard-page-tasksStatus-header',
    defaultMessage: 'Tasks status',
  });
  const headerPriceQuotesStats = intl.formatMessage({
    id: 'dashboard-page-priceQuotesStats-header',
    defaultMessage: 'Price quotes status',
  });
  const headerOrdersStats = intl.formatMessage({
    id: 'dashboard-page-ordersStats-header',
    defaultMessage: 'Orders status',
  });
  const tooltipCount = intl.formatMessage({
    id: 'dashboard-page-graph-tooltip-count',
    defaultMessage: 'Count',
  });
  const tooltipAveragePrice = intl.formatMessage({
    id: 'dashboard-page-graph-tooltip-averagePrice',
    defaultMessage: 'Average price',
  });
  const tooltipMonthYear = intl.formatMessage({
    id: 'dashboard-page-graph-tooltip-monthYear',
    defaultMessage: 'Month/year',
  });
  const labelTasks = intl.formatMessage({
    id: 'dashboard-page-chart-bar-label-tasks',
    defaultMessage: 'Tasks',
  });
  const labelBacklogTasks = intl.formatMessage({
    id: 'dashboard-page-chart-bar-label-backlogTasks',
    defaultMessage: 'Backlog tasks',
  });
  const labelPriceQuotes = intl.formatMessage({
    id: 'dashboard-page-chart-bar-label-priceQuotes',
    defaultMessage: 'Price quotes',
  });
  const labelBacklogPriceQuotes = intl.formatMessage({
    id: 'dashboard-page-chart-bar-label-backlogPriceQuotes',
    defaultMessage: 'Backlog price quotes',
  });
  const labelOrders = intl.formatMessage({
    id: 'dashboard-page-chart-bar-label-orders',
    defaultMessage: 'Orders',
  });
  const labelBacklogOrders = intl.formatMessage({
    id: 'dashboard-page-chart-bar-label-backlogOrders',
    defaultMessage: 'Backlog orders',
  });


  const {dataBar, dataLine} = useExtendedDateRangeContext();

  const getXData = (data) => {
    const result = data ? data.map(item => {
      const trimmedStr = item.replace(/ /g, '');
      const array = trimmedStr.split('/');
      return array.length > 0
        ? array.map((item) => {
            return moment(item).format('DD.MM.YY')
          }
        )
        : [];
    }) : []

    return result.filter(item => item !== '')
  };
  const getBarsYData = (data, firstLabel, secondLabel) => {
    return data ? data.map(item => {
      const {name, data} = item;
      const hebrewName = name === 'total' ? firstLabel : secondLabel;
      return {data, name: hebrewName}
    }) : []
  };
  const getCustomTooltipBar = ({series, seriesIndex, dataPointIndex}) => {
    switch (seriesIndex) {
      case 1:
        const percentInTotal = Boolean(series[0][dataPointIndex])
          ? `${(series[1][dataPointIndex] / series[0][dataPointIndex] * 100).toFixed(2)}%`
          : ''
        ;
        return '<div class="bg-danger text-white p-2">'
          + percentInTotal
          + '</div>'
      default:
        return null
    }
  };

  const tasksStats = dataBar?.tasksStats ? dataBar?.tasksStats : [];
  const priceQuotesStats = dataBar?.priceQuotesStats ? dataBar?.priceQuotesStats : [];
  const ordersStats = dataBar?.ordersStats ? dataBar?.ordersStats : [];
  const inspectionsStats = dataLine?.inspectionsStats ? dataLine?.inspectionsStats : [];
  const xData = inspectionsStats.map(item => item.currentPeriod);
  const averageSqmPrice = inspectionsStats.map(item => {
    return item.averageSqmPrice || 0
  });
  const totalInspectionsCount = inspectionsStats.map(item => item.totalInspectionsCount);

  const getCustomTooltipPriceTime = ({series, seriesIndex, dataPointIndex}) => {

    return '<div>' +
      '<div class="bg-warning text-white p-2">' + tooltipMonthYear + getXData(xData)[dataPointIndex] + '</div>' +
      '<div class="bg-info text-white p-2">' + tooltipAveragePrice + series[seriesIndex][dataPointIndex] + '</div>' +
      '<div class="bg-gray-600 text-white p-2">' + tooltipCount + totalInspectionsCount[dataPointIndex] + '</div>' +
      '</div>'
  };

  const commonProps = {
    width: '100%',
    height: '280px',
  };

  return (
    <div className='mx-auto mt-4 mb-6 px-10 w-75'>
      <Chart
        header={headerTasksStatus}
        options={getOptionsBar(
          getXData(tasksStats?.xaxis),
          getBarsYData(tasksStats?.series, labelTasks, labelBacklogTasks),
          getCustomTooltipBar
        )}
        type={'bar'}
        {...commonProps}
      />
      <Chart
        header={headerPriceQuotesStats}
        options={getOptionsBar(
          getXData(priceQuotesStats?.xaxis),
          getBarsYData(priceQuotesStats?.series, labelPriceQuotes, labelBacklogPriceQuotes),
          getCustomTooltipBar
        )}
        type={'bar'}
        {...commonProps}
      />
      <Chart
        header={headerOrdersStats}
        options={getOptionsBar(
          getXData(ordersStats?.xaxis),
          getBarsYData(ordersStats?.series, labelOrders, labelBacklogOrders),
          getCustomTooltipBar
        )}
        type={'bar'}
        {...commonProps}
      />
      <Chart
        header={headerPriceTime}
        options={getOptionsLine(
          getXData(xData),
          averageSqmPrice,
          getCustomTooltipPriceTime
        )}
        type={'line'}
        {...commonProps}
      />
    </div>
  )
}


import XLSX from 'sheetjs-style';
import {excelHeaderCellsRange} from '../constants/excelHeaderCellsRange';

const set_right_to_left = wb => {
  if (!wb.Workbook) wb.Workbook = {};
  if (!wb.Workbook.Views) wb.Workbook.Views = [];
  if (!wb.Workbook.Views[0]) wb.Workbook.Views[0] = {};
  wb.Workbook.Views[0].RTL = true;
};

const COLUMN_WIDTH_OFFSET = 5;

const autofitColumns = (ws, json) => {
  const objectMaxLength = [];

  json.map(jsonData => {
    Object.entries(jsonData)
      .map(([, columnValue], idx) => {
        objectMaxLength[idx] = objectMaxLength[idx] >= columnValue.length ? objectMaxLength[idx] : columnValue.length + COLUMN_WIDTH_OFFSET;
      })
  });

  ws["!cols"] = objectMaxLength.map(w => ({width: w}))
};

const fit_to_column = (data) => {
  const columnWidths = [];
  for (const i in data[0]) {
    columnWidths.push({
      wch: Math.max(
        i ? i.toString().length : 5,
        ...data.map(obj => obj[i] ? obj[i].toString().length : 5),
      )
    });
  }
  return columnWidths;
};

const style_header_cells_centered_bold = (headersArray, ws) => {

  headersArray.map(item => {
    if (ws[item]) {
      return ws[item].s = {
        font: {
          bold: true,
        },
        alignment: {
          horizontal: 'center'
        }
      }
    } else return null
  });
};

const style_cells_content_center = (headersArray, ws) => {
  const arrayOfCells = Object.keys(ws);
  const arrayOfGridCells = arrayOfCells.filter(item => headersArray.indexOf(item) === -1);
  arrayOfGridCells.map(item => {
    if (item !== '!cols' && item !== '!ref') {
      return ws[item].s = {
        alignment: {
          horizontal: 'center'
        }
      };
    } else return null
  });
};

const style_title_header_bold = (ws) => {
  if (ws['A1']) {
    return ws['A1'].s = {
      font: {
        bold: true,
      }
    }
  }
};

export const generateExcelFile = (data, fileName, title, merge, customCell) => {
  /* create  new worksheet from data and static title */
  const ws = XLSX.utils.json_to_sheet([
    {title},
  ], {skipHeader: true, origin: "A1"});
  XLSX.utils.sheet_add_json(ws, data, {origin: "A3"});

  /* build new workbook */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws);
  set_right_to_left(wb);
  ws['!cols'] = fit_to_column(data);
  style_header_cells_centered_bold(excelHeaderCellsRange, ws);
  style_cells_content_center(excelHeaderCellsRange, ws);
  style_title_header_bold(ws);
  ws["!merges"] = merge;
  customCell(ws)

  /* write file */
  XLSX.writeFile(wb, fileName);
};

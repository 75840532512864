import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {useFormik, FormikProvider, FieldArray} from 'formik';
import moment from 'moment';
import * as tasksActions from '../../_common/actions/tasksActions';
import {taskTypeIDs} from '../../_common/constants/taskTypes';

const OrderDetailsTaskContext = createContext('');

export function useOrderDetailsTaskContext(shumaID) {
  return useContext(OrderDetailsTaskContext);
}

export function OrderDetailsTaskProvider({children, shumaID}) {
  const [loadingList, setLoadingList] = useState(false);
  const [tasksList, setTasksList] = useState([]);
  const [taskDetails, setTaskDetails] = useState(null);

  const updateTasksList = async (shumaID) => {
    try {
      if (shumaID) {
        const list = await tasksActions.getList(shumaID);
        setTasksList([...list]);
      }
    } catch (e) {
      console.error(e);
      setTasksList([]);
    }
  };

  useEffect(() => {
    setLoadingList(true);
    updateTasksList(shumaID)
      .finally(() => setLoadingList(false));
  }, [shumaID]);

  const getTaskDetails = async (taskID) => {
    if (taskID !== -1) {
      try {
        const data = await tasksActions.getTaskDetails(taskID);
        setTaskDetails(data);
      } catch (e) {
        console.error(e);
        setTaskDetails(null);
      }
    }
  };

  const resetTaskDetails = (taskID) => {
    if (taskID === -1) {
      formik.resetForm();
      setTaskDetails(null);
    }
  };

  const addEditTask = async () => {
    try {
      if (formik.values.taskTypeID === taskTypeIDs.visit) {
        await tasksActions.addEditVisitTask({
          taskID: formik.values.taskID,
          shumaID,
          responsibleUserID: formik.values.responsibleUserID,
          taskStatusID: formik.values.taskStatusID,
          dueDate: formik.values.taskDueDate.toString(),
          notes: formik.values.notes,
          visitContactName: formik.values.visitContactName,
          visitContactPhone: formik.values.visitContactPhone
        })
      } else {
        await tasksActions.addEditGeneralTask({
          taskID: formik.values.taskID,
          shumaID,
          responsibleUserID: formik.values.responsibleUserID,
          taskStatusID: formik.values.taskStatusID,
          dueDate: formik.values.taskDueDate.toString(),
          taskDescription: formik.values.taskDescription
        })
      }
    } catch (e) {
      console.error(e);
    }
  }

  const createTask = async (taskType) => {
    try {
      if (taskType === taskTypeIDs.visit) {
        await tasksActions.addEditVisitTask({
          taskID: -1,
          shumaID,
          responsibleUserID: formik.values.responsibleUserID,
          taskStatusID: formik.values.taskStatusID,
          dueDate: formik.values.taskDueDate.toString(),
          notes: formik.values.notes,
          visitContactName: formik.values.visitContactName,
          visitContactPhone: formik.values.visitContactPhone
        })
      } else {
        await tasksActions.addEditGeneralTask({
          taskID: -1,
          shumaID,
          responsibleUserID: formik.values.responsibleUserID,
          taskStatusID: formik.values.taskStatusID,
          dueDate: formik.values.taskDueDate.toString(),
          taskDescription: formik.values.taskDescription
        })
      }
    } catch (e) {
      console.error(e);
    }
  }

  const deleteTask = async (taskID) => {
    try {
      await tasksActions.deleteTask(taskID);
    } catch (e) {
      console.error(e);
    }
  }

  const fieldNames = {
    responsibleUserID: 'responsibleUserID',
    taskTypeID: 'taskTypeID',
    taskStatusID: 'taskStatusID',
    taskDueDate: 'taskDueDate',
    notes: 'notes',
    taskDescription: 'taskDescription',
    visitContactName: 'visitContactName',
    visitContactPhone: 'visitContactPhone',
  };

  const initialValues = {
    taskID: taskDetails?.taskID || -1,
    responsibleUserID: taskDetails?.responsibleUserID || '',
    taskTypeID: taskDetails?.taskTypeID || 1,
    taskStatusID: taskDetails?.taskStatusID || 1,
    taskDueDate: (taskDetails?.dueDate) ? moment(taskDetails.dueDate).toDate() : null,
    notes: taskDetails?.notes || '',
    taskDescription: taskDetails?.taskDescription || '',
    visitContactName: taskDetails?.visitContactName || '',
    visitContactPhone: taskDetails?.visitContactPhone || ''
  };

  const validate = (values) => {
    let errors = {};
    const {
      taskTypeID,
      responsibleUserID,
      taskStatusID,
      taskDescription,
      taskDueDate,
    } = values;

    const commonFields = {
      responsibleUserID,
      taskStatusID,
      taskDueDate,
    };

    const arrCommonFields = Object.keys(commonFields);

    arrCommonFields.forEach(function (item) {
      if (!values[item]) errors[item] = 'Required field';
    });

    if (taskTypeID === taskTypeIDs.general) {
      if (!taskDescription) errors[`taskDescription`] = 'Required field';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const value = {
    shumaID,
    formik,
    fieldNames,
    tasksList,
    loadingList,
    getTaskDetails,
    addEditTask,
    resetTaskDetails,
    updateTasksList: () => updateTasksList(shumaID),
    deleteTask,
    createTask,
  };

  return (
    <FormikProvider value={value.formik}>
      <FieldArray name={'tasks'}>
        {(arrayHelpers) => (
          <OrderDetailsTaskContext.Provider value={{...value, arrayHelpers}}>
            {children}
          </OrderDetailsTaskContext.Provider>
        )}
      </FieldArray>
    </FormikProvider>
  );
}

OrderDetailsTaskProvider.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {FormattedMessage} from "react-intl";
import CommonButton from "../../CommonButton";

const defaultMessage = 'Something went wrong. Try again later...';

const GlobalMessage = ({
                         showGlobalMessage,
                         hideGlobalMessage,
                         message = defaultMessage,
                       }) => {

  return (
    <Modal
      show={showGlobalMessage}
      onHide={hideGlobalMessage}
      className="mt-20"
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-around">
          <h2 className="font-weight-bold text-center mb-6">
            {message}
          </h2>
          <CommonButton
            onClick={hideGlobalMessage}
            label={
              <FormattedMessage
                id="button-close"
                defaultMessage="Close"
              />
            }
          />
        </div>
      </Modal.Body>
    </Modal>
  )
};

GlobalMessage.propTypes = {
  showGlobalMessage: PropTypes.bool,
  hideGlobalMessage: PropTypes.func,
  message: PropTypes.string,
};

export default GlobalMessage;

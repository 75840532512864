export const gridTypes = {
  CHEQUE: 'CHEQUE',
  BANK_TRANSFER: 'BANK_TRANSFER',
  CREDIT_CARD: 'CREDIT_CARD',
  PAYMENT_APP: 'PAYMENT_APP',
  PAY_PAL: 'PAY_PAL',
  CASH: 'CASH',
  OTHER: 'OTHER',
  WITHHOLDING_TAX: 'WITHHOLDING_TAX',
  COMMON_DOCS: 'COMMON_DOCS',
}

import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import {showGlobalMessage} from '../../../_common/components/GlobalMessage/actions/showGlobalMessage';
import {can} from '../../../_common/components/Permissions';
import {permissionTypes as T, subjects as S} from '../../../_common/constants/permissionSubjects';
import {deleteCustomer, restoreCustomer} from "../../actions/CustomersEntityTableActions";
import {tooltips as TTS} from "../../../_common/constants/tooltips";
import {messages as M} from "../../../../../_metronic/i18n/messages";
import CommonButton from "../../../_common/components/CommonButton";

export default function PopupDeleteRestoreCustomer({data}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const customerName = [data.firstName, data.lastName].filter(Boolean).join(' ');

  const handleAction = async () => {
    const result = data.deleted
      ? await dispatch(restoreCustomer(data.customerID))
      : await dispatch(deleteCustomer(data.customerID));
    if (result.errorCode < 0) {
      await dispatch(showGlobalMessage(M['-1']));
    }
    handleCloseModal();
  };

  const icon = data.deleted
    ? 'media/common/Restore.svg'
    : 'media/common/Delete.svg';
  const message = !data.deleted
    ? (
      <FormattedMessage
        id="customers-entity-popup-delete-header"
        defaultMessage="Are you sure you want to delete {name}?"
        values={{name: customerName}}
      />
    )
    : (
      <FormattedMessage
        id="customers-entity-popup-restore-header"
        defaultMessage="Are you sure you want to restore {name}?"
        values={{name: customerName}}
      />
    );
  const tooltip = !data.deleted
    ? TTS.DELETE_CUSTOMER
    : TTS.RESTORE_CUSTOMER;
  const buttonSubmit = !data.deleted
    ? (
      <FormattedMessage
        id="customers-entity-popup-delete-yes"
        defaultMessage="Delete"
      />
    )
    : (
      <FormattedMessage
        id="customers-entity-popup-restore-yes"
        defaultMessage="Restore"
      />
    );

  return (
    <>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={tooltip}
        disabled={!can(T.FULL, S.CUSTOMERS_ENTITY_PAGE)}
        onClick={handleShowModal}
      >
        <img
          src={toAbsoluteUrl(icon)}
          alt="action"
          className={'w-24px'}
        />
      </Button>
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <div className={'py-4'}>
            <div className={'h2 text-center'}>
              {message}
            </div>
            <div className="w-75 mx-auto d-flex justify-content-between pt-10">
              <CommonButton
                variant={'button'}
                onClick={handleCloseModal}
                label={
                  <FormattedMessage
                    id="customers-entity-popup-delete-no"
                    defaultMessage="No"
                  />
                }
              />
              <CommonButton
                variant={'submit'}
                onClick={() => handleAction()}
                label={buttonSubmit}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React from 'react';
import styles from './styles.module.scss';

export const InteractionsMenuItem = ({
                                       label,
                                       icon,
                                       onClick,
                                       customContainerStyles = '',
                                       ...itemProps
                                     }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`w-100 h-100 d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg ${customContainerStyles}`}
      {...itemProps}
      onClick={handleClick}
    >
      <img className='mr-3' src={icon} alt={label}/>
      <div>{label}</div>
    </div>
  );
};

import React from 'react';
import {FormattedMessage} from 'react-intl';
import OrderInspectionForm from '../../../_common/components/OrderInspectionForm';
import {useInspectionDetailsUIContext} from '../InspectionsDetailsUIContext';
import {useInspectionDetailsTasksContext} from '../InspectionsDetailsTasksContext';
import CommonButton from '../../../_common/components/CommonButton';
import Spinner from '../../../_common/components/Spinner';
import {GridTasksContextProvider} from "../../../_common/components/GridTasks/gridTasksContext";
import GridTasks from "../../../_common/components/GridTasks";

const InspectionDetails = () => {
  const {
    formik,
    users,
    loadingData,
    validate,
    decisionTreeProps,
    handleEditInspection,
    fieldNames
  } = useInspectionDetailsUIContext();
  const selectProps = {users};
  const {values, setFieldValue, dirty, handleChange} = formik;
  const isValid = Object.keys(validate(values)).length === 0;
  const tasksContext = useInspectionDetailsTasksContext();

  return (
    <>
      {loadingData
        ? <Spinner
          customContainerStyles={'w-100 h-100 d-flex justify-content-center align-items-center'}/>
        : (
          <div className={'w-100 px-10 my-20 mx-auto'}>
            <div className={'bg-white pt-8 px-8 pb-4 w-100'}>
              <OrderInspectionForm
                values={values}
                decisionTreeProps={decisionTreeProps}
                selectProps={selectProps}
                fieldNames={fieldNames}
                setFieldValue={setFieldValue}
                disabledFormCondition={!values.shumaID}
                handleChange={handleChange}
                tasksSection={(
                  <>
                    <CommonButton
                      customButtonClassNames={'w-250px btn btn-warning font-weight-bolder text-dark my-6'}
                      label={(
                        <FormattedMessage
                          id={'inspection-details-button-label-edit'}
                          defaultMessage={'Edit inspection'}
                        />
                      )}
                      onClick={() => handleEditInspection(values)}
                      disabled={!(isValid && dirty)}
                    />
                    <GridTasksContextProvider tasksContext={tasksContext}>
                      <GridTasks/>
                    </GridTasksContextProvider>
                  </>
                )}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default InspectionDetails;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

const SectionTitle = React.forwardRef(({title, className}, ref) => {
  return (
    <div ref={ref}>
      {Boolean(title) && (
        <div className={classnames(styles.itemTitle, className)}>
          {title}
        </div>
      )}
    </div>
  );
});

SectionTitle.propTypes = {
  title: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default SectionTitle;

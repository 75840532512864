import React from 'react';

export function AddressColumnFormatter(city, street, houseNumber) {
  const topRow = city || '';
  const bottomRow = [street, houseNumber].filter(Boolean).join(' ');

  return (
    <div>
      <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
        {topRow}
      </div>
      <div className="text-dark-75 d-block font-size-md text-nowrap">
        {bottomRow}
      </div>
    </div>
  );
}

import React from 'react';
import paymentLink from "../../assets/link.svg";
import {FormattedMessage} from "react-intl";
import {usePopupCreatePaymentLinkContext} from "../PopupCreatePaymentLinkContext";
import CommonPopupWithTrigger from "../../../_common/components/CommonPopupWithTrigger";
import {StyledFieldInput} from "../../../_common/components/FieldInput";
import {StyledFieldSelect} from "../../../_common/components/FieldSelect";
import {filterOptions} from "../../../_common/utils/options";
import {formatStringToFloatNumber} from "../../../_common/utils/changeInputValue";
import {maxPayments, docTypes} from '../../../_common/constants/options';

const PopupContent = () => {
  const {
    formik: {
      values,
      setFieldValue,
      errors
    }
  } = usePopupCreatePaymentLinkContext();
  const commonContainerStyles = 'py-3';

  return (
    <div>
      <h2 className='text-center font-weight-bolder mb-2'>
        <FormattedMessage
          id="customer-details-popup-payment-header"
          defaultMessage="Create a link for payment"
        />
      </h2>
      <div className={'my-4'}>
        <StyledFieldInput
          fieldId={'description'}
          value={values.description}
          onChange={(e) => {
            setFieldValue('description', e.target.value)
          }}
          label={
            <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <span className={'text-danger'}>*</span>
              <FormattedMessage
                id="customer-details-popup-payment-form-label-description"
                defaultMessage="Description"
              />
            </div>
          }
          customContainerStyles={commonContainerStyles}
        />
        <StyledFieldInput
          fieldId={'amount'}
          value={values.amount}
          onChange={(e) => {
            setFieldValue('amount', formatStringToFloatNumber(e))
          }}
          label={
            <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <span className={'text-danger'}>*</span>
              <FormattedMessage
                id="customer-details-popup-payment-form-label-amount"
                defaultMessage="Amount"
              />
            </div>
          }
          customContainerStyles={commonContainerStyles}
        />
        <StyledFieldInput
          fieldId={'remarks'}
          value={values.remarks}
          onChange={(e) => {
            setFieldValue('remarks', e.target.value)
          }}
          label={
            <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <FormattedMessage
                id="customer-details-popup-payment-form-label-remarks"
                defaultMessage="Remarks"
              />
            </div>
          }
          customContainerStyles={commonContainerStyles}
        />
        <StyledFieldSelect
          fieldId={'maxPayments'}
          onChange={(option) => setFieldValue('maxPayments', option.value)}
          value={filterOptions(values.maxPayments)(maxPayments)}
          options={maxPayments}
          label={
            <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <span className={'text-danger'}>*</span>
              <FormattedMessage
                id="customer-details-popup-payment-form-label-maxPayments"
                defaultMessage="Max payments"
              />
            </div>
          }
          customContainerStyles={commonContainerStyles}
        />
        <StyledFieldSelect
          fieldId={'type'}
          onChange={(option) => setFieldValue('type', option.value)}
          value={filterOptions(values.type)(docTypes)}
          options={docTypes}
          label={
            <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <span className={'text-danger'}>*</span>
              <FormattedMessage
                id="customer-details-popup-payment-form-label-docType"
                defaultMessage="Doc type"
              />
            </div>
          }
          customContainerStyles={commonContainerStyles}
        />
        <div className={'position-relative pb-2'}>
          <StyledFieldInput
            fieldId={'email'}
            type={'email'}
            value={values.email}
            onChange={(e) => {
              setFieldValue('email', e.target.value)
            }}
            label={
              <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                <span className={'text-danger'}>*</span>
                <FormattedMessage
                  id="customer-details-popup-payment-form-label-email"
                  defaultMessage="Email"
                />
              </div>
            }
            customContainerStyles={commonContainerStyles}
          />
          {errors.email === 'Invalid email address!' && (
            <div className={'text-danger font-weight-bold position-absolute bottom-0 pl-23'}>
              <FormattedMessage
                id="invalid-email-message"
                defaultMessage="Invalid email"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

const PopupCreatePaymentLink = ({}) => {
  const {
    formik: {
      values,
      isValid
    },
    handleSubmit,
    hasPluginID
  } = usePopupCreatePaymentLinkContext();

  return hasPluginID && (
    <CommonPopupWithTrigger
      size={'m'}
      triggerContent={
        <div className={`d-flex align-items-center px-3 py-3 font-weight-bold text-nowrap font-size-lg`}>
          <div className='mr-2'>
            <img
              style={{width: '18px'}}
              src={paymentLink}
              alt="paymentLink"
            />
          </div>
          <FormattedMessage
            id="customer-details-documentInfo-label-button-generate-payment-link"
            defaultMessage="Create a link for payment"
          />
        </div>
      }
      content={<PopupContent/>}
      buttonLabelApprove={
        <FormattedMessage
          id="customer-details-popup-payment-link-button-label-submit"
          defaultMessage="Creating a link and sending"
        />
      }
      buttonLabelCancel={
        <FormattedMessage
          id="customer-details-popup-payment-link-button-label-cancel"
          defaultMessage="Cancel"
        />
      }
      onApprove={() => {
        handleSubmit(values)
      }}
      customContainerStyles={'w-75 mx-auto'}
      isOnApproveDisabled={!(isValid && hasPluginID)}
    />
  );
};

export default PopupCreatePaymentLink;

import mime from 'mime-types';

export const findFileByTypeId = (files, fileTypeID) => {
  if (files) {
    const [file] = files.filter(file => file.file_type_id === fileTypeID);
    return file;
  }
};

export const getMediaType = ({file_extension}) => {
  switch (mime.lookup(file_extension)) {
    case 'application/pdf':
      return 'pdf';
    case 'image/gif':
    case 'image/jpeg':
    case 'image/tiff':
    case 'image/png':
      return 'image';
    default:
      return null;
  }
};

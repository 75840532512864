import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

export function CreateAndStatusFormatter(createTime, statusID, statusText) {
  return (
    <div className="w-80px">
      <div className="font-weight-bold">
        {moment(createTime).format('DD-MM-YYYY')}
      </div>
      <div
        className={classNames('badge badge-pill', {
          ['badge-secondary']: statusID === 1,
          ['badge-danger']: statusID === 3,
          ['badge-primary']: statusID === 4,
          ['badge-success']: statusID === 5,
          ['badge-warning']: statusID === 6,
          ['badge-info']: statusID === 7,
        })}
      >
        {statusText}
      </div>
    </div>
  );
}

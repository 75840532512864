import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const Section6Text2 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {buildingNearbyHazard} = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(buildingNearbyHazard) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, styles.lastSectionLine)}>
          <span>החלקה גובלת במפגעים הבאים: </span>
          <span>{`${buildingNearbyHazard}.`}</span>
        </div>
      )}
    </div>
  );
});

export default Section6Text2;

import React from 'react';
import classnames from 'classnames';
import Section from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {landLabels} from '../../../constants/landLabels';
import styles from './styles.module.scss';

const SelectedLandsTextLandShituf = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    landShituf,
    landShitufText,
    landOther,
    landZikatHanaa,
    landHazmada,
    landHafkaa,
    landHahira
  } = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(landShituf) && (
        <Section
          customClassNames={{
            container: classnames(styles.itemText, styles.noMarginAfter, {
              [styles.noMarginAfter]: Boolean(landOther) || Boolean(landZikatHanaa) || Boolean(landHazmada) || Boolean(landHafkaa) || Boolean(landHahira),
              [styles.lastSectionLine]: !Boolean(landOther) && !Boolean(landZikatHanaa) && !Boolean(landHazmada) && !Boolean(landHafkaa) && !Boolean(landHahira),
            })
          }}
        >
          <span className={styles.bold}> {landLabels['landShituf']} - </span>
          <span>{landShitufText}.</span>
        </Section>
      )}
    </div>
  );
});

const SelectedLandsTextLandHahira = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    landHahira,
    landHahiraText,
    landOther,
    landZikatHanaa,
    landHazmada,
    landHafkaa
  } = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(landHahira) && (
        <Section
          customClassNames={{
            container: classnames(styles.itemText, styles.noMarginAfter, {
              [styles.noMarginAfter]: Boolean(landOther) || Boolean(landZikatHanaa) || Boolean(landHazmada) || Boolean(landHafkaa),
              [styles.lastSectionLine]: !Boolean(landOther) && !Boolean(landZikatHanaa) && !Boolean(landHazmada) && !Boolean(landHafkaa),
            })
          }}
        >
          <span className={styles.bold}> {landLabels['landHahira']} - </span>
          <span>{landHahiraText}.</span>
        </Section>
      )}
    </div>
  );
});

const SelectedLandsTextLandHafkaa = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    landHafkaa,
    landHafkaaText,
    landOther,
    landZikatHanaa,
    landHazmada,
  } = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(landHafkaa) && (
        <Section
          customClassNames={{
            container: classnames(styles.itemText, styles.noMarginAfter, {
              [styles.noMarginAfter]: Boolean(landOther) || Boolean(landZikatHanaa) || Boolean(landHazmada),
              [styles.lastSectionLine]: !Boolean(landOther) && !Boolean(landZikatHanaa) && !Boolean(landHazmada),
            })
          }}
        >
          <span className={styles.bold}> {landLabels['landHafkaa']} - </span>
          <span>{landHafkaaText}.</span>
        </Section>
      )}
    </div>
  );
});

const SelectedLandsTextLandHazmada = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    landHazmada,
    landHazmadaText,
    landOther,
    landZikatHanaa
  } = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(landHazmada) && (
        <Section
          customClassNames={{
            container: classnames(styles.itemText, styles.noMarginAfter, {
              [styles.noMarginAfter]: Boolean(landOther) || Boolean(landZikatHanaa),
              [styles.lastSectionLine]: !Boolean(landOther) && !Boolean(landZikatHanaa),
            })
          }}
        >
          <span className={styles.bold}> {landLabels['landHazmada']} - </span>
          <span>{landHazmadaText}.</span>
        </Section>
      )}
    </div>
  );
});

const SelectedLandsTextLandZikatHanaa = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    landZikatHanaa,
    landZikatHanaaText,
    landOther
  } = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(landZikatHanaa) && (
        <Section
          customClassNames={{
            container: classnames(styles.itemText, styles.noMarginAfter, {
              [styles.noMarginAfter]: Boolean(landOther),
              [styles.lastSectionLine]: !Boolean(landOther),
            })
          }}
        >
          <span className={styles.bold}> {landLabels['landZikatHanaa']} - </span>
          <span>{landZikatHanaaText}.</span>
        </Section>
      )}
    </div>
  );
});

const SelectedLandsTextLandOther = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    landOther,
    landOtherText,
  } = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(landOther) && (
        <Section
          customClassNames={{container: classnames(styles.itemText, styles.lastSectionLine)}}
        >
          <span className={styles.bold}> {landLabels['landOther']} - </span>
          <span>{landOtherText}.</span>
        </Section>
      )}
    </div>
  );
});

export default [
  SelectedLandsTextLandShituf,
  SelectedLandsTextLandHahira,
  SelectedLandsTextLandHafkaa,
  SelectedLandsTextLandHazmada,
  SelectedLandsTextLandZikatHanaa,
  SelectedLandsTextLandOther
];

import React from 'react';
import classnames from 'classnames';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

export const getSection6Text714 = (context) => {
  const {houseFunctionalDivide} = context.data.pdfData;
  let houseFunctionalDivideValues = [];
  if (Boolean(houseFunctionalDivide)) {
    houseFunctionalDivideValues = getTextChunks(houseFunctionalDivide).map(mapTextChunks);
  }

  return [
    <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
      <span className={styles.bold}>חלוקה פונקציונלית: </span>
    </div>,
    ...houseFunctionalDivideValues.map((item, index) => (
      <div
        className={classnames(styles.itemText, styles.innerPadding)}
        key={index + 1}
      >
        {item}
      </div>
    ))
  ];
};

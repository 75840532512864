import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getStatusList} from '../actions/StatusListActions';

function withStatusList(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      const {statusList, getStatusList} = this.props;
      if (statusList.length < 1) {
        getStatusList();
      }
    }

    getOptions = () => {
      const {statusList} = this.props;
      return {
        statusList: statusList || [],
      }
    };

    render() {
      return <WrappedComponent {...this.props} {...this.getOptions()}/>;
    }
  };
}

const mapStateToProps = ({
                           statusListReducer: {statusList}
                         }) => {
  return {statusList};
};

export default compose(
  connect(
    mapStateToProps,
    {getStatusList}
  ),
  withStatusList
);

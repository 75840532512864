import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const FittedImage = ({className, src, alt, onLoad, style}) => {
  const imageRef = useRef(null);

  useEffect(() => {
    if (onLoad) {
      onLoad(imageRef.current);
    }
  }, []);

  return (
    <div
      className={classnames(styles.fittedImg, className)}
    >
      <img
        className={classnames(styles.img)}
        src={src}
        style={{
          ...style,
          filter: 'drop-shadow(3px 0 0 black) drop-shadow(0 3px 0 black) drop-shadow(-2px 0 0 black) drop-shadow(0 -2px 0 black)', // because the image is used as background not an element content and can occupy only a part of the container element, so if we use border styles instead of the filter style the border is displayed around the full element, not the background image
        }}
        ref={imageRef}
        alt={alt}
        data-testid={alt}
      />
    </div>
  );
};

FittedImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onLoad: PropTypes.func,
};

export default FittedImage;

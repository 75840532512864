import {actionTypes} from "../reducers/OrderFilesReducer";
import axios from "axios";
import {downloadFile} from "../../../../../utils/downloadFile";

export const GET_FILE_LIST = "/ordersFiles/getFileList";
export const UPLOAD_FILE = "/ordersFiles/uploadFile";
export const DOWNLOAD_FILE = "/ordersFiles/getFile";
export const DELETE_FILE = "/ordersFiles/deleteFile";


export const getFileList = orderID => dispatch => {
  const data = {orderID: parseInt(orderID, 10)};

  return axios.post(GET_FILE_LIST, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {fileList} = body;

      dispatch({
        type: actionTypes.orderFileListFetched,
        payload: {orderFileList: fileList},
      });
    })
    .catch((err) => {
      err.clientMessage = "Can't find data";
      dispatch({
        type: actionTypes.catchError,
        payload: err,
      });
    });
};

export const uploadFile = (fileToSave, orderID, orderFileTypeID, fileName, payload = {}) => {

  return (dispatch) => {
    const formData = new FormData();
    formData.append('fileToSave', fileToSave);
    formData.append('orderID', orderID);
    formData.append('fileName', fileName);
    formData.append('orderFileTypeID', orderFileTypeID);
    Object.keys(payload).forEach(key => {
      formData.append(`${key}`, payload[key]);
    });

    return axios.post(UPLOAD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .catch((err) => {
        console.error('uploadFile returns error:');
        console.error(err);
      });
  };
};

export const getFile = ({orderID, fileName}) => dispatch => {
  const data = {
    orderID: parseInt(orderID, 10),
    fileName: fileName
  };

  return axios.post(DOWNLOAD_FILE, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      try {
        downloadFile(`data:;base64,${body.file}`, fileName);
      } catch (exc) {
        console.log("Save Blob method failed with the following exception.");
        console.log(exc);
      }

      return body.file;
    })
    .catch((err) => {
      console.error('getFile returns error:');
      console.error(err);
    });
};

export const deleteFile = ({orderID, fileID, fileName}) => dispatch => {
  const data = {
    orderID: parseInt(orderID, 10),
    fileID: parseInt(fileID, 10),
    fileName,
  };

  return axios.post(DELETE_FILE, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      return body;
    })
    .catch((err) => {
      console.error('deleteFile returns error:');
      console.error(err);
    });
};


import React from 'react';
import PopupEditTask from "../../TasksPage/components/PopupEditTask/PopupEditTask";
import {PopupEditTasksContextProvider} from "../../TasksPage/components/PopupEditTask/PopupEditTasksContext";

export function EditTaskColumnFormatter(id, type) {

  return (
    <PopupEditTasksContextProvider>
      <PopupEditTask
        id={id}
        type={type}
      />
    </PopupEditTasksContextProvider>
  )
};

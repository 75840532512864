import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import {isEqual, isFunction} from 'lodash';
import moment from 'moment';
import * as actions from '../actions/CustomerDetailsActions';
import * as giActions from '../../_common/actions/greenInvoice';
import * as documentActions from '../actions/CustomerGreenInvoiceActions';
import * as filesActions from '../actions/CustomerFilesActions';
import {filterOptionsByLabel} from '../../_common/utils/options';
import {
  optionsCustomerStatus,
  optionsCustomerTypes,
  optionsDiscountType,
  optionsGenders,
  optionsVatType
} from '../constants/options';
import {pathnames as P} from '../../_common/constants/pathnames';
import {documentTypes as T} from '../constants/documentTypes';
import {checkEmailIsValid} from '../../_common/utils/checkEmailIsValid';
import {showGlobalMessage} from '../../_common/components/GlobalMessage/actions/showGlobalMessage';
import {messages as M} from '../../../../_metronic/i18n/messages';

const CustomerDetailsUIContext = createContext('');

export function useCustomerDetailsUIContext() {
  return useContext(CustomerDetailsUIContext);
}

const initialValues = {
  customerID: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  idNumber: '',
  address: '',
  customerType: {value: null},
  genderID: {value: null},
  status: {value: null},
};

export function CustomerDetailsUIProvider({children, customerID}) {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const {generalDetails} = useSelector(
    (state) => ({
      generalDetails: state.customerDetailsReducer.general,
    }),
    shallowEqual
  );
  const {greenInvoiceApiKey, greenInvoiceSecret} = useSelector(
    (state) => ({
      greenInvoiceApiKey: state.auth.greenInvoiceApiKey,
      greenInvoiceSecret: state.auth.greenInvoiceSecret
    }),
    shallowEqual
  );
  const {documentsData} = useSelector(
    (state) => ({
      documentsData: state.customerGreenInvoiceReducer.documents,
    }),
    shallowEqual
  );
  const {fileList} = useSelector(
    (state) => ({
      fileList: state.customerFileListReducer.fileList,
    }),
    shallowEqual
  );
  const {previewDocumentLink} = useSelector(
    (state) => ({
      previewDocumentLink: state.customerGreenInvoicePreviewDocumentReducer.previewDocumentLink,
    }),
    shallowEqual
  );
  const {copiedDocumentData} = useSelector(
    (state) => ({
      copiedDocumentData: state.customerDocumentDataReducer.documentData,
    }),
    shallowEqual
  );
  const {loadingCopiedDocumentData} = useSelector(
    (state) => ({
      loadingCopiedDocumentData: state.customerDocumentDataReducer.loadingDocumentData,
    }),
    shallowEqual
  );

  const {greenInvoiceClientID, email} = generalDetails;
  const greenInvoiceIsEnabled = Boolean(greenInvoiceApiKey)
    && Boolean(greenInvoiceSecret)
    && Boolean(greenInvoiceClientID);

  const {
    description,
    remarks,
    footer,
    linkedDocuments,
    client,
    discount,
    income
  } = copiedDocumentData;
  const clientExists = Boolean(client) && Object.keys(client).length > 0;
  const discountExists = Boolean(discount) && Object.keys(discount).length > 0;
  const filteredDiscountType = discountExists ?
    optionsDiscountType.filter(item => {
      if (item.value === discount.type) {
        return item;
      }
    })
    : [];
  const getInitialPrice = (vatTypeValue, price, amountTotal, quantity) => {
    if (vatTypeValue === 0) {
      return price.toFixed(2);
    }
    if (vatTypeValue === 1) {
      return (amountTotal / quantity).toFixed(2);
    } else return 0;
  };
  const mappedIncome = Boolean(income) && income.length > 0 && income.map(item => {
    const {vatType, price, amountTotal, quantity, ...restIncomeData} = item;

    const filteredVatType = optionsVatType.filter(item => {
      if (item.value === vatType) {
        return item;
      }
    });

    const filteredVatValue = filteredVatType.length > 0
      ? filteredVatType[0].value
      : 0;

    return {
      vatType: filteredVatType[0],
      price: getInitialPrice(filteredVatValue, price, amountTotal, quantity),
      amountTotal,
      quantity,
      ...restIncomeData
    };
  });

  const [createFromCopiedDataMark, setCreateFromCopiedDataMark] = useState(false);
  const getCopiedDocumentData = (documentId) => {
    if (documentId && greenInvoiceIsEnabled) {
      setCreateFromCopiedDataMark(true);
      dispatch(documentActions.getDocumentData(
        documentId,
        greenInvoiceApiKey,
        greenInvoiceSecret
      ));
    }
  };

  const createDocumentInitialValues = {
    clientId: '',
    emails: [email] || [],
    description: '',
    rowsCommonDocs: [
      {
        description: '',
        quantity: '',
        price: '',
        vatType: null
      }
    ],
    rowsCheque: [],
    rowsBankTransfer: [],
    rowsCreditCard: [],
    rowsPaymentApp: [],
    rowsPayPal: [],
    rowsCash: [],
    rowsOther: [],
    rowsWithholdingTax: [],
    amount: '0.00',
    type: null,
    remarks: '',
    footer: '',
    emailContent: '',
    linkedDocumentIds: [],
  };
  const createCopyFromDocumentInitialValues = {
    clientId: '',
    emails: clientExists ? client.emails : [email],
    description: description || '',
    rowsCommonDocs: mappedIncome.length > 0
      ? mappedIncome
      :
      [{
        description: '',
        quantity: '',
        price: '',
        vatType: null
      }],
    rowsCheque: [],
    rowsBankTransfer: [],
    rowsCreditCard: [],
    rowsPaymentApp: [],
    rowsPayPal: [],
    rowsCash: [],
    rowsOther: [],
    rowsWithholdingTax: [],
    amount: discountExists ? discount.amount : '0.00',
    type: discountExists ? filteredDiscountType[0] : null,
    remarks: remarks || '',
    footer: footer || '',
    emailContent: '',
    linkedDocumentIds: Boolean(linkedDocuments) && linkedDocuments.length > 0
      ? linkedDocuments
      : [],
  };

  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [creatingDoc, setCreatingDoc] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const validateGeneralDetails = (values) => {
    const {email} = values;
    let errors = {};

    if (!values.customerType) {
      errors.customerType = 'Required';
    }

    switch (values.customerType) {
      case 1:
      case 2:
      case 3:
        ['firstName'].forEach(function (item) {
          if (!values[item]) errors[item] = 'Required';
        });
        break;
      case 4:
        ['firstName', 'lastName'].forEach(function (item) {
          if (!values[item]) errors[item] = 'Required';
        });
        break;
      case 5:
        ['firstName', 'lastName'].forEach(function (item) {
          if (!values[item]) errors[item] = 'Required';
        });
        break;
    }

    setEmailError(false);
    if (email && !checkEmailIsValid(email)) {
      setEmailError(true);
      errors['email'] = 'Invalid email address';
    }

    return errors;
  };

  const getFormikValues = data => {
    const {
      customerTypeName,
      customerTypeID,
      genderID,
      genderName,
      customerStatusID,
      customerStatus,
      address,
      ...rest
    } = data;

    const [customerType] = filterOptionsByLabel(customerTypeName)(optionsCustomerTypes);
    const [gender] = filterOptionsByLabel(genderName)(optionsGenders);
    const [status] = filterOptionsByLabel(customerStatus)(optionsCustomerStatus);

    return {
      customerType: customerType || initialValues.customerType,
      genderID: gender || initialValues.genderID,
      status: status || initialValues.status,
      address: address || initialValues.address,
      ...rest
    };
  };
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...getFormikValues(generalDetails),
    },
    validate: validateGeneralDetails,
    validateOnMount: true,
    onSubmit: () => {
    },
    enableReinitialize: true,
  });

  const validateCreateDocument = (values) => {
    let errors = {};
    const {
      emails,
      rowsCommonDocs,
      rowsCheque,
      rowsBankTransfer,
      rowsCreditCard,
      rowsPaymentApp,
      rowsPayPal,
      rowsCash,
      rowsOther,
      rowsWithholdingTax,
    } = values;

    const arr = Object.keys({emails});
    arr.forEach(function (item) {
      if (!values[item]) errors[item] = 'Required';
    });

    if (
      rowsCommonDocs.length < 1 && (
        rowsCheque.length < 1 &&
        rowsBankTransfer.length < 1 &&
        rowsCreditCard.length < 1 &&
        rowsPaymentApp.length < 1 &&
        rowsPayPal.length < 1 &&
        rowsCash.length < 1 &&
        rowsOther.length < 1 &&
        rowsWithholdingTax.length < 1
      )) {
      errors.rows = 'Required at least one row';
    }

    if (rowsCommonDocs.length > 0 && (
      rowsCheque.length < 1 &&
      rowsBankTransfer.length < 1 &&
      rowsCreditCard.length < 1 &&
      rowsPaymentApp.length < 1 &&
      rowsPayPal.length < 1 &&
      rowsCash.length < 1 &&
      rowsOther.length < 1 &&
      rowsWithholdingTax.length < 1
    )) {
      let rowsData = (rowsCommonDocs && Array.isArray(rowsCommonDocs)) && rowsCommonDocs.map((item, index) => {

        return {
          description: rowsCommonDocs[index].description,
          vatType: rowsCommonDocs[index].vatType,
          quantity: rowsCommonDocs[index].quantity,
          price: rowsCommonDocs[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          description,
          vatType,
          quantity,
          price,
        } = item;

        if (!description) errors['rowsCommonDocs-description'] = 'Required line item';
        if (!vatType) errors['rowsCommonDocs-vatType'] = 'Required line item';
        if (Boolean(vatType) && typeof vatType === 'object' && !(vatType.value === 1 || vatType.value === 0)) {
          errors['rowsCommonDocs-vatType2'] = 'Required line item';
        }
        if (!quantity) errors['rowsCommonDocs-quantity'] = 'Required line item';
        if (!price) errors['rowsCommonDocs-price'] = 'Required line item';
      });
    }
    if (rowsCheque.length > 0) {
      let rowsData = (rowsCheque && Array.isArray(rowsCheque)) && rowsCheque.map((item, index) => {

        return {
          bankName: rowsCheque[index].bankName,
          bankBranch: rowsCheque[index].bankBranch,
          bankAccount: rowsCheque[index].bankAccount,
          chequeNum: rowsCheque[index].chequeNum,
          price: rowsCheque[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          bankName,
          bankBranch,
          bankAccount,
          chequeNum,
          price,
        } = item;

        if (!bankName) errors['rowsCheque-bankName'] = 'Required line item';
        if (!bankBranch) errors['rowsCheque-bankBranch'] = 'Required line item';
        if (!bankAccount) errors['rowsCheque-bankAccount'] = 'Required line item';
        if (!chequeNum) errors['rowsCheque-chequeNum'] = 'Required line item';
        if (!price) errors['rowsCheque-price'] = 'Required line item';
      });
    }
    if (rowsBankTransfer.length > 0) {
      let rowsData = (rowsBankTransfer && Array.isArray(rowsBankTransfer)) && rowsBankTransfer.map((item, index) => {

        return {
          bankName: rowsBankTransfer[index].bankName,
          bankBranch: rowsBankTransfer[index].bankBranch,
          bankAccount: rowsBankTransfer[index].bankAccount,
          price: rowsBankTransfer[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          bankName,
          bankBranch,
          price,
        } = item;
        if (!bankName) errors['rowsBankTransfer-bankName'] = 'Required line item';
        if (!bankBranch) errors['rowsBankTransfer-bankBranch'] = 'Required line item';
        if (!price) errors['rowsBankTransfer-price'] = 'Required line item';
      });
    }
    if (rowsCreditCard.length > 0) {
      let rowsData = (rowsCreditCard && Array.isArray(rowsCreditCard)) && rowsCreditCard.map((item, index) => {

        return {
          cardNum: rowsCreditCard[index].cardNum,
          cardType: rowsCreditCard[index].cardType,
          dealType: rowsCreditCard[index].dealType,
          numPayments: rowsCreditCard[index].numPayments,
          price: rowsCreditCard[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          cardNum,
          cardType,
          dealType,
          numPayments,
          price,
        } = item;

        if (!cardNum) errors['rowsCreditCard-cardNum'] = 'Required line item';
        if (typeof cardType === 'object') errors['rowsCreditCard-cardType'] = 'Required line item';
        if (typeof dealType === 'object') errors['rowsCreditCard-dealType'] = 'Required line item';
        if (!numPayments) errors['rowsCreditCard-numPayments'] = 'Required line item';
        if (!price) errors['rowsCreditCard-price'] = 'Required line item';
      });
    }
    if (rowsPaymentApp.length > 0) {
      let rowsData = (rowsPaymentApp && Array.isArray(rowsPaymentApp)) && rowsPaymentApp.map((item, index) => {

        return {
          appType: rowsPaymentApp[index].appType,
          accountId: rowsPaymentApp[index].accountId,
          transactionId: rowsPaymentApp[index].transactionId,
          price: rowsPaymentApp[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          appType,
          accountId,
          transactionId,
          price,
        } = item;

        if (typeof appType === 'object') errors['rowsPaymentApp-appType'] = 'Required line item';
        if (!accountId) errors['rowsPaymentApp-accountId'] = 'Required line item';
        if (!transactionId) errors['rowsPaymentApp-transactionId'] = 'Required line item';
        if (!price) errors['rowsPaymentApp-price'] = 'Required line item';
      });
    }
    if (rowsPayPal.length > 0) {
      let rowsData = (rowsPayPal && Array.isArray(rowsPayPal)) && rowsPayPal.map((item, index) => {

        return {
          accountId: rowsPayPal[index].accountId,
          transactionId: rowsPayPal[index].transactionId,
          price: rowsPayPal[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          accountId,
          transactionId,
          price,
        } = item;

        if (!accountId) errors['rowsPayPal-accountId'] = 'Required line item';
        if (!transactionId) errors['rowsPayPal-transactionId'] = 'Required line item';
        if (!price) errors['rowsPayPal-price'] = 'Required line item';
      });
    }
    if (rowsCash.length > 0) {
      let rowsData = (rowsCash && Array.isArray(rowsCash)) && rowsCash.map((item, index) => {

        return {
          price: rowsCash[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {price} = item;

        if (!price) errors['rowsCash-price'] = 'Required line item';
      });
    }
    if (rowsOther.length > 0) {
      let rowsData = (rowsOther && Array.isArray(rowsOther)) && rowsOther.map((item, index) => {

        return {
          subType: rowsOther[index].subType,
          transactionId: rowsOther[index].transactionId,
          price: rowsOther[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {
          subType,
          transactionId,
          price,
        } = item;
        if (typeof subType === 'object') errors['rowsOther-subType'] = 'Required line item';
        if (!transactionId) errors['rowsOther-transactionId'] = 'Required line item';
        if (!price) errors['rowsOther-price'] = 'Required line item';
      });
    }
    if (rowsWithholdingTax.length > 0 && (
      rowsCheque.length < 1 &&
      rowsBankTransfer.length < 1 &&
      rowsCreditCard.length < 1 &&
      rowsPaymentApp.length < 1 &&
      rowsPayPal.length < 1 &&
      rowsCash.length < 1 &&
      rowsOther.length < 1
    )) {

      errors['rowsWithholdingTax-price'] = 'Need to fill at least one other line';
    }

    if (rowsWithholdingTax.length > 0) {
      let rowsData = (rowsWithholdingTax && Array.isArray(rowsWithholdingTax)) && rowsWithholdingTax.map((item, index) => {

        return {
          price: rowsWithholdingTax[index].price,
        };
      });

      rowsData.forEach(function (item) {
        const {price} = item;

        if (!price) errors['rowsWithholdingTax-price'] = 'Required line item';
      });
    }

    return errors;
  };
  const formikCreateDocument = useFormik({
    initialValues: createFromCopiedDataMark
      ? {...createCopyFromDocumentInitialValues}
      : {...createDocumentInitialValues},
    validate: validateCreateDocument,
    validateOnMount: true,
    onSubmit: () => {
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getCustomerDetails(customerID))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (greenInvoiceIsEnabled) {
      dispatch(documentActions.getDocuments(
        greenInvoiceClientID,
        greenInvoiceApiKey,
        greenInvoiceSecret
      ));
    }
  }, [greenInvoiceClientID]);

  useEffect(() => {
    dispatch(filesActions.getFileList(customerID));
  }, []);

  const preparedCommonDocsData = () => {
    const {values} = formikCreateDocument;
    const {
      emails,
      amount,
      type,
      description,
      rowsCommonDocs,
      remarks,
      footer,
      emailContent
    } = values;

    const mappedRowsCommonDocs = (array) => {

      return array.map((item) => {
        const {vatType, ...rest} = item;
        return (
          {
            vatType: typeof vatType === 'object' ? vatType.value : vatType,
            ...rest
          }
        );
      });
    };

    return {
      clientId: greenInvoiceClientID,
      emails,
      discount: {amount, type: Boolean(type) && typeof type === 'object' ? type.value : type},
      description,
      rows: mappedRowsCommonDocs(rowsCommonDocs),
      remarks,
      footer,
      emailContent
    };
  };
  const preparedReceiptData = () => {
    const {values} = formikCreateDocument;
    const {
      emails,
      description,
      rowsCheque,
      rowsBankTransfer,
      rowsCreditCard,
      rowsPaymentApp,
      rowsPayPal,
      rowsCash,
      rowsOther,
      rowsWithholdingTax,
      remarks,
      footer,
      emailContent
    } = values;

    const mappedArrayWithDate = (array) => {
      return array.map(item => {
        const {date, price, ...rest} = item;
        return (
          {
            date: moment(date).format('YYYY-MM-DD'),
            price: Boolean(price) ? parseFloat(price) : 0,
            ...rest
          }
        );
      });
    };
    const mappedArrayWithholdingTaxWithDate = (array) => {
      return array.map(item => {
        const {price, ...rest} = item;
        return (
          {
            date: moment().format('YYYY-MM-DD'),
            price: Boolean(price) ? parseFloat(price) : 0,
            ...rest
          }
        );
      });
    };
    let arrayRows = [
      ...mappedArrayWithDate(rowsCheque),
      ...mappedArrayWithDate(rowsBankTransfer),
      ...mappedArrayWithDate(rowsCreditCard),
      ...mappedArrayWithDate(rowsPaymentApp),
      ...mappedArrayWithDate(rowsPayPal),
      ...mappedArrayWithDate(rowsCash),
      ...mappedArrayWithDate(rowsOther),
      ...mappedArrayWithholdingTaxWithDate(rowsWithholdingTax),
    ];
    return {
      clientId: greenInvoiceClientID,
      emails,
      description,
      rows: arrayRows,
      remarks,
      footer,
      emailContent,
    };
  };
  const preparedInvoiceReceiptData = () => {
    const {values} = formikCreateDocument;
    const {
      emails,
      description,
      rowsCommonDocs,
      rowsCheque,
      rowsBankTransfer,
      rowsCreditCard,
      rowsPaymentApp,
      rowsPayPal,
      rowsCash,
      rowsOther,
      rowsWithholdingTax,
      remarks,
      footer,
      emailContent
    } = values;

    const mappedArrayWithDate = (array) => {
      return array.map(item => {
        const {date, price, ...rest} = item;
        return (
          {
            date: moment(date).format('YYYY-MM-DD'),
            price: Boolean(price) ? parseFloat(price) : 0,
            ...rest
          }
        );
      });
    };
    const mappedArrayWithholdingTaxWithDate = (array) => {
      return array.map(item => {
        const {price, ...rest} = item;
        return (
          {
            date: moment().format('YYYY-MM-DD'),
            price: Boolean(price) ? parseFloat(price) : 0,
            ...rest
          }
        );
      });
    };
    let arrayRows = [
      ...mappedArrayWithDate(rowsCheque),
      ...mappedArrayWithDate(rowsBankTransfer),
      ...mappedArrayWithDate(rowsCreditCard),
      ...mappedArrayWithDate(rowsPaymentApp),
      ...mappedArrayWithDate(rowsPayPal),
      ...mappedArrayWithDate(rowsCash),
      ...mappedArrayWithDate(rowsOther),
      ...mappedArrayWithholdingTaxWithDate(rowsWithholdingTax),
    ];

    const mappedRowsCommonDocs = (array) => {

      return array.map((item) => {
        const {vatType, ...rest} = item;
        return (
          {
            vatType: vatType.value,
            ...rest
          }
        );
      });
    };

    return {
      clientId: greenInvoiceClientID,
      emails,
      description,
      rowsIncome: mappedRowsCommonDocs(rowsCommonDocs),
      rows: arrayRows,
      remarks,
      footer,
      emailContent,
    };
  };

  const getCopiedDocumentDataCondition = () => {
    return Object.keys(copiedDocumentData).length !== 0;
  };

  const textContent = () => {
    const alreadyAdded = intl.formatMessage({
      id: 'customer-details-popup-create-document-error-already-added-email',
      defaultMessage: 'Email has already added!',
    });
    const notValidEmail = intl.formatMessage({
      id: 'customer-details-popup-create-document-error-not-valid-email',
      defaultMessage: 'Email not valid!',
    });
    const noMoreEmailsRequired = intl.formatMessage({
      id: 'customer-details-popup-create-document-error-no-more-emails-required',
      defaultMessage: 'No more emails required!',
    });
    const subHeaderServicesDetails = intl.formatMessage({
      id: 'customer-details-popup-create-document-subheader-services-details',
      defaultMessage: 'Services details',
    });
    const subHeaderReceiptsDetails = intl.formatMessage({
      id: 'customer-details-popup-create-document-subheader-receipts-details',
      defaultMessage: 'Services details',
    });

    return {
      alreadyAdded,
      notValidEmail,
      noMoreEmailsRequired,
      subHeaderServicesDetails,
      subHeaderReceiptsDetails,
    };
  };

  const handleCreateDocument = (type, linkedDocumentsIds, createdFromDocType) => {
    if (greenInvoiceIsEnabled) {
      switch (type) {
        case T.PRICE_QUOTE:
          return (
            Promise.resolve()
              .then(() => {
                setCreatingDoc(true);
              })
              .then(() => {
                dispatch(documentActions.createPriceQuote(preparedCommonDocsData(), greenInvoiceApiKey, greenInvoiceSecret));
              })
              .then(() => {
                setTimeout(() => {
                  dispatch(documentActions.getDocuments(greenInvoiceClientID, greenInvoiceApiKey, greenInvoiceSecret));
                }, 5000)
              })
              .finally(() => setTimeout(() => {
                setCreatingDoc(false);
              }, 5000))
          );
        case T.WORK_ORDER:
          const arrayLinkedDocumentsWorkOrder = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsWorkOrder.push(linkedDocumentsIds);
          }
          const linkWorkOrder = arrayLinkedDocumentsWorkOrder.length > 0
            ? {linkType: 'link'}
            : null;
          const dataWorkOrder = {
            ...preparedCommonDocsData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds)
              ? arrayLinkedDocumentsWorkOrder
              : [],
            ...linkWorkOrder,
          };
          return (
            Promise.resolve()
              .then(() => {
                setCreatingDoc(true);
              })
              .then(() => {
                dispatch(documentActions.createWorkOrder(dataWorkOrder, greenInvoiceApiKey, greenInvoiceSecret));
              })
              .then(() => {
                setTimeout(() => {
                  dispatch(documentActions.getDocuments(greenInvoiceClientID, greenInvoiceApiKey, greenInvoiceSecret));
                }, 5000)
              })
              .finally(() => setTimeout(() => {
                setCreatingDoc(false);
              }, 5000))
          );
        case T.INVOICE:
          const arrayLinkedDocumentsInvoice = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsInvoice.push(linkedDocumentsIds);
          }
          const linkInvoice = arrayLinkedDocumentsInvoice.length > 0
            ? {linkType: 'link'}
            : null;
          const dataInvoice = {
            ...preparedCommonDocsData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds) ? arrayLinkedDocumentsInvoice : [],
            ...linkInvoice,
          };
          return (
            Promise.resolve()
              .then(() => {
                setCreatingDoc(true);
              })
              .then(() => {
                dispatch(documentActions.createInvoice(dataInvoice, greenInvoiceApiKey, greenInvoiceSecret));
              })
              .then(() => {
                setTimeout(() => {
                  dispatch(documentActions.getDocuments(greenInvoiceClientID, greenInvoiceApiKey, greenInvoiceSecret));
                }, 5000)
              })
              .finally(() => setTimeout(() => {
                setCreatingDoc(false);
              }, 5000))
          );
        case T.RECEIPT:
          const arrayLinkedDocumentsReceipt = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsReceipt.push(linkedDocumentsIds);
          }
          const linkReceipt = createdFromDocType === 305
            ? {linkType: 'cancel'}
            : null;
          const data = {
            ...preparedReceiptData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds)
              ? arrayLinkedDocumentsReceipt
              : [],
            ...linkReceipt,
          };
          return (
            Promise.resolve()
              .then(() => {
                setCreatingDoc(true);
              })
              .then(() => {
                dispatch(documentActions.createReceipt(data, greenInvoiceApiKey, greenInvoiceSecret));
              })
              .then(() => {
                setTimeout(() => {
                  dispatch(documentActions.getDocuments(greenInvoiceClientID, greenInvoiceApiKey, greenInvoiceSecret));
                }, 5000)
              })
              .finally(() => setTimeout(() => {
                setCreatingDoc(false);
              }, 5000))
          );
        case T.TRANSACTION_ACCOUNT:
          return (
            Promise.resolve()
              .then(() => {
                setCreatingDoc(true);
              })
              .then(() => {
                dispatch(documentActions.createTransactionAccount(preparedCommonDocsData(), greenInvoiceApiKey, greenInvoiceSecret));
              })
              .then(() => {
                setTimeout(() => {
                  dispatch(documentActions.getDocuments(greenInvoiceClientID, greenInvoiceApiKey, greenInvoiceSecret));
                }, 5000)
              })
              .finally(() => setTimeout(() => {
                setCreatingDoc(false);
              }, 5000))
          );
        case T.INVOICE_RECEIPT:
          const arrayLinkedDocumentsInvoiceReceipt = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsInvoiceReceipt.push(linkedDocumentsIds);
          }
          const linkInvoiceReceipt = arrayLinkedDocumentsInvoiceReceipt.length > 0
            ? {linkType: 'link'}
            : null;
          const dataInvoiceReceipt = {
            ...preparedInvoiceReceiptData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds) ? arrayLinkedDocumentsInvoiceReceipt : [],
            ...linkInvoiceReceipt,
          };
          return (
            Promise.resolve()
              .then(() => {
                setCreatingDoc(true);
              })
              .then(() => {
                dispatch(documentActions.createInvoiceReceipt(dataInvoiceReceipt, greenInvoiceApiKey, greenInvoiceSecret));
              })
              .then(() => {
                setTimeout(() => {
                  dispatch(documentActions.getDocuments(greenInvoiceClientID, greenInvoiceApiKey, greenInvoiceSecret));
                }, 5000)
              })
              .finally(() => setTimeout(() => {
                setCreatingDoc(false);
              }, 5000))
          );
      }
      setCreateFromCopiedDataMark(false);
    }
  };

  const handlePreviewDocument = (type, linkedDocumentsIds, createdFromDocType) => {
    if (greenInvoiceIsEnabled) {
      switch (type) {
        case T.PRICE_QUOTE:
          return (
            dispatch(documentActions.previewPriceQuote(preparedCommonDocsData(), greenInvoiceApiKey, greenInvoiceSecret))
          );
        case T.WORK_ORDER:
          const arrayLinkedDocumentsWorkOrder = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsWorkOrder.push(linkedDocumentsIds);
          }
          const linkWorkOrder = arrayLinkedDocumentsWorkOrder.length > 0
            ? {linkType: 'link'}
            : null;
          const dataWorkOrder = {
            ...preparedCommonDocsData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds) ? arrayLinkedDocumentsWorkOrder : [],
            ...linkWorkOrder,
          };
          return (
            dispatch(documentActions.previewWorkOrder(dataWorkOrder, greenInvoiceApiKey, greenInvoiceSecret))
          );
        case T.INVOICE:
          const arrayLinkedDocumentsInvoice = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsInvoice.push(linkedDocumentsIds);
          }
          const linkInvoice = arrayLinkedDocumentsInvoice.length > 0
            ? {linkType: 'link'}
            : null;
          const dataInvoice = {
            ...preparedCommonDocsData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds)
              ? arrayLinkedDocumentsInvoice
              : [],
            ...linkInvoice,
          };
          return (
            dispatch(documentActions.previewInvoice(dataInvoice, greenInvoiceApiKey, greenInvoiceSecret))
          );
        case T.RECEIPT:
          const arrayLinkedDocumentsReceipt = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsReceipt.push(linkedDocumentsIds);
          }
          const linkReceipt = createdFromDocType === 305 && createFromCopiedDataMark
            ? {linkType: 'cancel'}
            : null;
          const data = {
            ...preparedReceiptData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds)
              ? arrayLinkedDocumentsReceipt
              : [],
            ...linkReceipt,
          };
          return (
            dispatch(documentActions.previewReceipt(data, greenInvoiceApiKey, greenInvoiceSecret))
          );
        case T.TRANSACTION_ACCOUNT:
          return (
            dispatch(documentActions.previewTransactionAccount(preparedCommonDocsData(), greenInvoiceApiKey, greenInvoiceSecret))
          );
        case T.INVOICE_RECEIPT:
          const arrayLinkedDocumentsInvoiceReceipt = [];
          if (createFromCopiedDataMark) {
            arrayLinkedDocumentsInvoiceReceipt.push(linkedDocumentsIds);
          }
          const linkInvoiceReceipt = arrayLinkedDocumentsInvoiceReceipt.length > 0
            ? {linkType: 'link'}
            : null;
          const dataInvoiceReceipt = {
            ...preparedInvoiceReceiptData(),
            linkedDocumentIds: Boolean(linkedDocumentsIds)
              ? arrayLinkedDocumentsInvoiceReceipt
              : [],
            ...linkInvoiceReceipt,
          };
          return (
            dispatch(documentActions.previewInvoiceReceipt(dataInvoiceReceipt, greenInvoiceApiKey, greenInvoiceSecret))
          );
      }
    }
  };

  const successMessage = intl.formatMessage({
    id: 'data-sent-success',
    defaultMessage: 'Success'
  });

  const buildAddCustomerBody = (formik) => {
    switch (formik.values.customerType.value) {
      case 1:
      case 2:
      case 3:
        return {
          customerID,
          firstName: formik.values.firstName,
          lastName: '',
          phone: formik.values.phone,
          email: formik.values.email,
          idNumber: formik.values.idNumber,
          address: formik.values.address,
          customerType: formik.values.customerType.value,
          genderID: null,
          status: formik.values.status.value,
          greenInvoiceClientID
        };
      case 4:
        return {
          customerID,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          phone: formik.values.phone,
          email: formik.values.email,
          idNumber: formik.values.idNumber,
          address: formik.values.address,
          customerType: formik.values.customerType.value,
          genderID: formik.values.genderID.value,
          status: formik.values.status.value,
          greenInvoiceClientID
        };
      case 5:
        return {
          customerID,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          phone: formik.values.phone,
          email: formik.values.email,
          idNumber: formik.values.idNumber,
          address: formik.values.address,
          customerType: formik.values.customerType.value,
          genderID: null,
          status: formik.values.status.value,
          greenInvoiceClientID
        };
    }
  };

  const update = async () => {
    const data = buildAddCustomerBody(formik);
    await dispatch(actions.editCustomer(data, successMessage));
  };

  const updateCustomer = async (exit = false) => {
    try {
      await update();
      if (exit) {
        await clearCustomer();
        history.push(P.CUSTOMERS_ENTITY_PAGE);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const clearCustomer = async () => {
    await dispatch(actions.clearCustomerData());
  };

  const [queryParams, setQueryParamsBase] = useState({});
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      for (let key in nextQueryParams) {
        if (nextQueryParams.hasOwnProperty(key) && !nextQueryParams[key]) delete nextQueryParams[key];
      }
      const path = history.location.pathname;
      const newQueryParams = new URLSearchParams(nextQueryParams);
      history.replace(`${path}?${newQueryParams.toString()}`);

      return nextQueryParams;
    });
  }, []);

  const [creatingClient, setCreatingClient] = useState(false);

  const createGreenInvoiceClientForCurrentCustomer = async () => {
    if (Boolean(greenInvoiceApiKey) && Boolean(greenInvoiceSecret)) {
      const {firstName, lastName, phone, email, idNumber, address} = generalDetails;
      const clientData = {
        customerID,
        name: `${firstName} ${lastName}`,
        phone,
        emails: [email],
        taxId: idNumber,
        address
      };
      const greenInvoiceCredentials = {greenInvoiceApiKey, greenInvoiceSecret};
      setCreatingClient(true);
      try {
        await dispatch(giActions.createClient(clientData, greenInvoiceCredentials));
        await dispatch(actions.getCustomerDetails(customerID));
      } catch (e) {
        console.error(e.message);
        await dispatch(showGlobalMessage(M.GREEN_INVOICE_CREATE_CUSTOMER_ERROR));
      } finally {
        setCreatingClient(false);
      }
    }
  };

  const handleFileUpload = (e) => {
    const fileToSave = e.target.files[0];
    const fileName = e.target.files[0].name;
    setLoadingFile(true);
    dispatch(filesActions.uploadFile(customerID, fileToSave, fileName))
      .then(() => dispatch(filesActions.getFileList(customerID)))
      .finally(() => setTimeout(() => {
        setLoadingFile(false);
      }, 1000));
  };

  const handleFileDownload = (fileName) => {
    dispatch(filesActions.getFile(customerID, fileName));
  };

  const handleFileDelete = (fileName, fileID) => {
    dispatch(filesActions.deleteFile(customerID, fileName, fileID))
      .then(() => dispatch(filesActions.getFileList(customerID)));
  };

  const value = {
    textContent,
    customerID,
    generalDetails,
    loading,
    loadingFile,
    creatingDoc,
    formik,
    updateCustomer,
    clearCustomer,
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    documentsData,
    formikCreateDocument,
    handleCreateDocument,
    handlePreviewDocument,
    previewDocumentLink,
    createClient: {
      createGreenInvoiceClientForCurrentCustomer,
      creatingClient,
    },
    fileList,
    handleFileUpload,
    handleFileDownload,
    handleFileDelete,
    getCopiedDocumentData,
    copiedDocumentData,
    getCopiedDocumentDataCondition,
    setCreateFromCopiedDataMark,
    loadingCopiedDocumentData,
    emailError
  };

  return (
    <CustomerDetailsUIContext.Provider value={value}>
      {children}
    </CustomerDetailsUIContext.Provider>
  );
}

CustomerDetailsUIProvider.propTypes = {
  children: PropTypes.node,
  customerID: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import {shallowEqual, useSelector} from 'react-redux';
import ButtonCreateClient from '../ButtonCreateClient';
import {StyledFieldInput} from '../../../_common/components/FieldInput';
import {integerValueCanStartFromNull} from '../../../_common/utils/changeInputValue';
import {optionsCustomerStatus, optionsCustomerTypes, optionsGenders} from '../../constants/options';
import {useCustomerDetailsUIContext} from '../CustomerDetailsUIContext';
import {commonStyles} from '../../../_common/selectStyles/customSelectStyles';

const FormCustomerTypePerson = () => {
  const {
    formik: {
      values,
      setFieldValue,
      handleChange
    },
  } = useCustomerDetailsUIContext();

  return (
    <>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'firstName'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-first-name"
                defaultMessage="First name"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'lastName'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-last-name"
                defaultMessage="Last name"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <div className="font-weight-bolder font-size-lg text-dark-75 pl-6">
            <FormattedMessage
              id="customers-entity-popup-edit-customer-gender"
              defaultMessage="Gender"
            />
          </div>
          <Select
            name={'genderID'}
            value={values.genderID}
            options={optionsGenders}
            styles={commonStyles}
            onChange={value => setFieldValue('genderID', value)}
            placeholder={
              <FormattedMessage
                id="select"
                defaultMessage="Select"
              />
            }
          />
        </div>
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'idNumber'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-idNumber"
                defaultMessage="ID number"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.idNumber}
            onChange={e => setFieldValue('idNumber', integerValueCanStartFromNull(e))}
          />
        </div>
      </div>
    </>
  );
};

const FormCustomerTypesCompany = () => {
  const {
    formik: {
      values,
      setFieldValue,
      handleChange
    },
  } = useCustomerDetailsUIContext();

  return (
    <>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'firstName'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-name"
                defaultMessage="Name"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.firstName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'idNumber'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-companyID"
                defaultMessage="Company ID"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.idNumber}
            onChange={e => setFieldValue('idNumber', integerValueCanStartFromNull(e))}
          />
        </div>
      </div>
    </>
  );
};

const FormCustomerTypeGroup = () => {
  const {
    formik: {
      values,
      setFieldValue,
      handleChange
    },
  } = useCustomerDetailsUIContext();

  return (
    <>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'firstName'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-groupName"
                defaultMessage="Group name"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.firstName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'idNumber'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-groupID"
                defaultMessage="Group ID"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.idNumber}
            onChange={e => setFieldValue('idNumber', integerValueCanStartFromNull(e))}
          />
        </div>
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'lastName'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-groupSubName"
                defaultMessage="Group sub name"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.lastName}
            onChange={handleChange}
          />
        </div>
      </div>

    </>
  );
};

const TabGeneralDetails = () => {
  const {
    formik: {
      values,
      setFieldValue,
      handleChange,
    },
    generalDetails,
    emailError
  } = useCustomerDetailsUIContext();

  const {
    greenInvoiceApiKey,
    greenInvoiceSecret
  } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const showCreateCustomerButton = () => {
    return Boolean(greenInvoiceApiKey)
      && Boolean(greenInvoiceSecret)
      && !Boolean(generalDetails.greenInvoiceClientID)
      && !emailError;
  };

  const renderFormByCustomerType = (customerType) => {
    switch (customerType) {
      case 1:
      case 2:
      case 3:
        return <FormCustomerTypesCompany/>;
      case 4:
        return <FormCustomerTypePerson/>;
      case 5:
        return <FormCustomerTypeGroup/>;
      default:
        return null;
    }
  };

  const resetFields = (customerType, setFieldValue) => {
    switch (customerType) {
      case 2:
      case 3:
        setFieldValue('lastName', '');
        setFieldValue('genderID', {value: null});
        break;
      case 4:
        break;
      case 5:
        setFieldValue('genderID', {value: null});
        break;
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <div className="font-weight-bolder font-size-lg text-dark-75 pl-6">
            <FormattedMessage
              id="customers-entity-popup-edit-customer-type"
              defaultMessage="Type"
            />
          </div>
          <Select
            name={'customerType'}
            value={values.customerType}
            options={optionsCustomerTypes}
            styles={commonStyles}
            onChange={value => {
              resetFields(value?.value, setFieldValue);
              setFieldValue('customerType', value);
            }}
            placeholder={
              <FormattedMessage
                id="select"
                defaultMessage="Select"
              />
            }
          />
        </div>
      </div>
      {renderFormByCustomerType(values.customerType?.value)}
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'phone'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-phone"
                defaultMessage="Phone"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.phone}
            onChange={e => setFieldValue('phone', integerValueCanStartFromNull(e))}
          />
        </div>
        <div className="py-3 w-25 mr-4 position-relative">
          <StyledFieldInput
            type={'email'}
            fieldId={'email'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-email"
                defaultMessage="Email"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.email}
            onChange={handleChange}
          />
          {emailError && (
            <div className={'text-danger font-weight-bold position-absolute bottom-0 pl-6'}>
              <FormattedMessage
                id="invalid-email-message"
                defaultMessage="Invalid email"
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25 mr-4">
          <StyledFieldInput
            fieldId={'address'}
            label={
              <FormattedMessage
                id="customers-entity-popup-edit-customer-address"
                defaultMessage="Address"
              />
            }
            customLabelStyles={'pl-6'}
            value={values.address}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap">
        <div className="py-3 w-25">
          <div className="font-weight-bolder font-size-lg text-dark-75 pl-6">
            <FormattedMessage
              id="customers-entity-popup-edit-customer-status"
              defaultMessage="Status"
            />
          </div>
          <Select
            name={'status'}
            value={values.status}
            options={optionsCustomerStatus}
            styles={commonStyles}
            onChange={value => setFieldValue('status', value)}
            placeholder={
              <FormattedMessage
                id="select"
                defaultMessage="Select"
              />
            }
          />
        </div>
      </div>
      {showCreateCustomerButton() && <ButtonCreateClient/>}
    </div>
  );
};

TabGeneralDetails.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default TabGeneralDetails;

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import classnames from 'classnames';
import styles from '../styles.module.scss';
import moment from 'moment';

const ShumaType1and2BuildingPermit = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const buildPermitDateValue = Boolean(context.data.pdfData.buildPermitDate) ? moment(context.data.pdfData.buildPermitDate).format('DD/MM/YYYY') : '';

  return (
    <div className={classnames(styles.innerPadding, styles.itemText)} ref={ref}>
      <span className={styles.bold}>רישוי: </span>
      {Boolean(context.data.pdfData.buildPermitNumber || buildPermitDateValue)
        ?
        <>
          הבניין הוקם במקורו עפ"י היתר בניה
          מס' {context.data.pdfData.buildPermitNumber} מתאריך {buildPermitDateValue}
          {Boolean(context.data.pdfData.buildPermitReason === 1) &&
            <span> ובוצעו בו שינויים עפ"י היתרים המפורטים בפרק רישוי</span>
          }
        </>
        :
        <span>לא נמצאו מסמכי רישוי מקוריים</span>
      }
      <span>.</span>
    </div>
  );
});

export default ShumaType1and2BuildingPermit;

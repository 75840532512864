import React from 'react';
import {useIntl} from "react-intl";
import {StyledFieldDatepicker}  from "../../../FieldDatepicker";
import {StyledFieldSelect} from "../../../FieldSelect";
import {optionsTaskStatus} from "../../../../../CustomerDetails/constants/options";
import {shallowEqual, useSelector} from "react-redux";
import {filterOptions} from "../../../../utils/options";
import {TaskVisit} from './TaskVisit';
import {useGridTasksContext} from '../../gridTasksContext';
import {StyledFieldTextareaAutosize} from "../../../FieldTextareaAutosize";

export function TaskGeneral({header}) {
  const intl = useIntl();
  const labelTaskDescription = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-taskDescription',
    defaultMessage: 'Task description',
  });
  const labelResponsibleUser = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-responsibleUser',
    defaultMessage: 'Responsible user',
  });
  const labelDueDate = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-dueDate',
    defaultMessage: 'Due date',
  });
  const labelStatus = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-status',
    defaultMessage: 'Status',
  });

  const {formik, fieldNames} = useGridTasksContext();
  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );

  const getFieldLabel = (text, mandatory = true) => {
    return (
      <div className={'font-weight-bolder pl-6'}>
        {mandatory && <span className={'text-danger'}>*</span>}
        <span>{text}</span>
      </div>
    )
  };

  return (
    <>
      <h2
        className="font-weight-normal text-dark w-100 d-flex justify-content-center mb-6"
      >
        {header}
      </h2>
      <div className={'py-4'}>
        <StyledFieldTextareaAutosize
          fieldId={fieldNames.taskDescription}
          onChange={(e) => formik.setFieldValue(fieldNames.taskDescription, e.target.value)}
          value={formik.values[fieldNames.taskDescription]}
          label={getFieldLabel(labelTaskDescription)}
          customContainerStyles={'py-3'}
          customLabelStyles={'fs-6'}
          customTextareaStyles={'overflow-hidden'}
        />
        <StyledFieldSelect
          fieldId={fieldNames.responsibleUserID}
          onChange={(option) => formik.setFieldValue(fieldNames.responsibleUserID, option.value)}
          value={filterOptions(formik.values.responsibleUserID)(users)}
          options={users}
          label={getFieldLabel(labelResponsibleUser)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldDatepicker
          value={formik.values.taskDueDate}
          onChange={(date) => {
            formik.setFieldValue(fieldNames.taskDueDate, new Date(date));
          }}
          label={getFieldLabel(labelDueDate)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldSelect
          fieldId={fieldNames.taskStatusID}
          onChange={(option) => formik.setFieldValue(fieldNames.taskStatusID, option.value)}
          value={filterOptions(formik.values.taskStatusID)(optionsTaskStatus)}
          options={optionsTaskStatus}
          label={getFieldLabel(labelStatus)}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
}

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import classnames from 'classnames';
import styles from '../styles.module.scss';

const ShumaType1BuildingPopulatedText = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingPopulatedValue = getSelectedOptionLabel(context.data.pdfData.buildingPopulated, options.buildingPopulatedOptions);

  return (
    <div ref={ref}>
      {Boolean(buildingPopulatedValue) && (
        <div className={classnames(styles.innerPadding, styles.itemText)}>
          <span className={styles.bold}>האם הבניין מאוכלס: </span>
          {` ${buildingPopulatedValue}.`}
        </div>
      )}
    </div>
  );
});

export default ShumaType1BuildingPopulatedText;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {formattingPercents, formattingPrice} from '../../utils/formattingHelper';
import styles from './styles.module.scss';

const TablePrices = ({customClassNames = {}}) => {
  const context = useCompleteInspectionContext();
  const {
    pdfData: {
      averageSqmPrice,
      averageSqmPriceSD,
      deviationPercent,
      averageSqmPriceLowerBorder,
      averageSqmPriceUpperBorder,
    },
  } = context.data;

  return (
    <table
      className={classnames(
        styles.simpleTable,
        styles.autoWidth,
        styles.noCellBorders,
        customClassNames.container
      )}
    >
      <thead>
      <tr>
        <th className={styles.greenBg} colSpan={2}>
          ריכוז נתוני שווי
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>מחיר ממוצע למ"ר רשום</td>
        <td>{formattingPrice(averageSqmPrice)}</td>
      </tr>
      <tr>
        <td>סטית תקן</td>
        <td>{formattingPrice(averageSqmPriceSD)}</td>
      </tr>
      <tr>
        <td>אחוז סטיה</td>
        <td>{formattingPercents(deviationPercent)}</td>
      </tr>
      <tr>
        <td>גבול תחתון</td>
        <td>{formattingPrice(averageSqmPriceLowerBorder)}</td>
      </tr>
      <tr>
        <td>גבול עליון</td>
        <td>{formattingPrice(averageSqmPriceUpperBorder)}</td>
      </tr>
      </tbody>
    </table>
  );
};

TablePrices.propTypes = {
  customClassNames: PropTypes.shape({
    container: PropTypes.string,
  })
};

export default TablePrices;

import React from 'react';
import {useHeader} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import SubheaderBar from '../../_common/components/SubheaderBar';
import CustomersEntityFilters from '../components/CustomersEntityFilter';
import CustomersEntityTable from '../components/CustomersEntityTable';
import {CustomersEntityUIProvider} from '../components/CustomersEntityUIContext';

const CustomersEntity = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'customers-entity-page-header',
    defaultMessage: 'Customers entity page'
  }));

  return (
    <CustomersEntityUIProvider>
      <SubheaderBar>
        <CustomersEntityFilters/>
      </SubheaderBar>
      <CustomersEntityTable/>
    </CustomersEntityUIProvider>
  );
};

export default CustomersEntity;

import React from 'react';
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Button} from "react-bootstrap";
import {tooltips as TTS} from '../constants/tooltips';
import {can} from '../components/Permissions';
import {subjects as S, permissionTypes as T} from '../constants/permissionSubjects';

export function EditOrderColumnFormatter(cellContent, row, {history}) {
  const {orderNumber} = row;
  const handleEditOrder = () => {
    history.push(`/order-details/${orderNumber}`)
  };

  return (
    <div>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={TTS.EDIT_ORDER}
        disabled={!can(T.FULL, S.ORDERS_PAGE)}
        onClick={handleEditOrder}
      >
        <img
          src={toAbsoluteUrl('media/common/Edit.svg')}
          alt="edit-order"
        />
      </Button>
    </div>
  );
}

import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {Button} from "react-bootstrap";
import {useIntl} from 'react-intl';
import CommonButton from "../../../_common/components/CommonButton";
import {tooltips as TTS} from "../../../_common/constants/tooltips";
import {
  subjects as S,
  permissionTypes as T
} from '../../../_common/constants/permissionSubjects';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {can} from '../../../_common/components/Permissions';
import {deleteTask} from "../../actions/TasksTableActions";
import {useDispatch} from "react-redux";

const PopupDeleteTask = ({id}) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const dispatch = useDispatch();

  const intl = useIntl();
  const header = intl.formatMessage({
    id: 'tasks-page-popup-delete-task-header',
    defaultMessage: `Delete task ${id}`
  }, {id});
  const confirm = intl.formatMessage({
    id: 'tasks-page-column-delete',
    defaultMessage: 'Delete'
  });
  const decline = intl.formatMessage({
    id: 'button-decline',
    defaultMessage: 'Decline'
  });

  const handleDelete = async (taskID) => {
    try {
      await dispatch(deleteTask(taskID));
    } catch (e) {
      console.error(e);
    }
    handleCloseModal();
  };

  return (
    <>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={TTS.DELETE_TASK}
        onClick={handleShowModal}
        disabled={!can(T.FULL, S.TASKS_PAGE)}
      >
        <img
          src={toAbsoluteUrl('media/common/Delete.svg')}
          alt="delete"
        />
      </Button>
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <div className={'py-4'}>
            <div className={'h2 text-center'}>{header}</div>
            <div className="w-75 mx-auto d-flex justify-content-between pt-10">
              <CommonButton
                variant={'button'}
                onClick={handleCloseModal}
                label={decline}
              />
              <CommonButton
                variant={'submit'}
                onClick={() => handleDelete(id)}
                label={confirm}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PopupDeleteTask;

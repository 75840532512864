import React from 'react';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Footer = () => {
  const context = useCompleteInspectionContext();
  const {
    firmData: {
      footerText,
      footerLogoURL,
    }
  } = context.data;

  return (
    <div className={styles.footer}>
      {footerLogoURL &&
        <img src={footerLogoURL} alt="footer" className={styles.footerImage}/>}
      <div className={classnames(styles.footerText, {[styles.customFontSize]: context.shumaTypeID === 3})}>
        {footerText}
      </div>
    </div>
  );
};

export default Footer;

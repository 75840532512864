import axios from 'axios';

export const GET_FINAL_PDF_DATA = '/inspections/getFinalPDFData';

export const getInspectionData = ({shumaID}) => {

  return axios.post(GET_FINAL_PDF_DATA, {shumaID})
    .then(response => {
      const {error, body} = response.data;
      if (error < 0) throw new Error('server returned error');

      return body;
    });
};

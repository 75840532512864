import React from 'react';
import OrderInspection from './OrderInspection';
import Empty from '../../../_common/components/Empty';
import Tabs from '../../../_common/components/Tabs';
import CommonButton from '../../../_common/components/CommonButton';
import {useOrderDetailsUIGlobalContext} from '../OrderDetailsUIGlobalContext';
import {
  useOrderDetailsUIInspectionsListContext
} from '../OrderDetailsUIInspectionsListContext';
import {OrderDetailsTaskProvider} from '../OrderDetailsTaskContext';
import {
  OrderDetailsUIInspectionProvider,
  useOrderDetailsUIInspectionContext
} from '../OrderDetailsUIInspectionContext';
import {
  StreetsProvider
} from '../../../_common/components/CitiesAndStreets';
import {
  DecisionTreeShumaTypesOptionsContextProvider
} from '../../../_common/components/DecisionTreeOptions';
import {
  OrderDetailsUINewInspectionProvider,
  useOrderDetailsUINewInspectionContext
} from '../OrderDetailsUINewInspectionContext';
import {
  useOrderDetailsUIOrderContext
} from '../OrderDetailsUIOrderContext';

const TabOrderInspections = () => {
  const {textContent, orderID} = useOrderDetailsUIGlobalContext();
  const {
    noInspectionsAdded,
    buttonCreateInspectionsLabel
  } = textContent;
  const {
    formikOrderInspections,
    handleCreateInspectionForLinkedOrder
  } = useOrderDetailsUIInspectionsListContext();
  const {updateOrder, oldInspectionsForms} = useOrderDetailsUIOrderContext();

  const {values, isValid, dirty} = formikOrderInspections;
  const {inspections} = values;
  const inspectionsTabs = inspections.map((item, index) => {
    const {shumaID} = inspections[index];

    if (oldInspectionsForms) {
      return {
        key: index,
        title: `דוח ${Boolean(item.firmShumaID) ? item.firmShumaID : 'חדש'}`,
        icon: <i className="flaticon2-file-1"/>,
        content: shumaID
          ? (
            <StreetsProvider>
              <DecisionTreeShumaTypesOptionsContextProvider>
                <OrderDetailsUIInspectionProvider inspectionDetails={item}>
                  <OrderInspection
                    shumaID={shumaID}
                    useContext={useOrderDetailsUIInspectionContext}
                  />
                </OrderDetailsUIInspectionProvider>
              </DecisionTreeShumaTypesOptionsContextProvider>
            </StreetsProvider>
          )
          : (
            <StreetsProvider>
              <DecisionTreeShumaTypesOptionsContextProvider>
                <OrderDetailsUINewInspectionProvider index={index}>
                  <OrderInspection
                    shumaID={shumaID}
                    useContext={useOrderDetailsUINewInspectionContext}
                  />
                </OrderDetailsUINewInspectionProvider>
              </DecisionTreeShumaTypesOptionsContextProvider>
            </StreetsProvider>
          )
      };
    } else {
      return {
        key: index,
        title: `דוח ${Boolean(item.firmShumaID) ? item.firmShumaID : 'חדש'}`,
        icon: <i className="flaticon2-file-1"/>,
        content: shumaID
          ? (
            <StreetsProvider>
              <DecisionTreeShumaTypesOptionsContextProvider>
                <OrderDetailsUIInspectionProvider inspectionDetails={item}>
                  <OrderDetailsTaskProvider shumaID={shumaID}>
                    <OrderInspection
                      shumaID={shumaID}
                      useContext={useOrderDetailsUIInspectionContext}
                    />
                  </OrderDetailsTaskProvider>
                </OrderDetailsUIInspectionProvider>
              </DecisionTreeShumaTypesOptionsContextProvider>
            </StreetsProvider>
          )
          : (
            <StreetsProvider>
              <DecisionTreeShumaTypesOptionsContextProvider>
                <OrderDetailsUINewInspectionProvider index={index}>
                  <OrderDetailsTaskProvider shumaID={shumaID}>
                    <OrderInspection
                      shumaID={shumaID}
                      useContext={useOrderDetailsUINewInspectionContext}
                    />
                  </OrderDetailsTaskProvider>
                </OrderDetailsUINewInspectionProvider>
              </DecisionTreeShumaTypesOptionsContextProvider>
            </StreetsProvider>
          )
      };
    }
  });

  const tabsWithShumaID = inspections.filter(item => Boolean(item.shumaID)).length;
  const allTabsHasShumaID = inspectionsTabs.length === tabsWithShumaID;

  const handleClick = async () => {
    await handleCreateInspectionForLinkedOrder();
    await updateOrder(orderID);
  };

  return (
    <div className={'w-100 px-4'}>
      {inspections.length > 0
        ? (
          <>
            <Tabs items={inspectionsTabs}/>
            <CommonButton
              customButtonClassNames={'w-250px ml-10 btn btn-warning font-weight-bolder text-dark my-6'}
              label={buttonCreateInspectionsLabel}
              onClick={handleClick}
              disabled={!(isValid && dirty) || allTabsHasShumaID}
            />
          </>
        )
        : <Empty message={noInspectionsAdded} customContainerStyles={'py-20'}/>
      }
    </div>
  );
};

export default TabOrderInspections;

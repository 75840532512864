import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormikProvider} from 'formik';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import moment from "moment";
import {showGlobalMessage} from "../../_common/components/GlobalMessage/actions/showGlobalMessage";
import {useDecisionTreeOptionsContext} from "../../_common/components/DecisionTreeOptions";
import {copyShuma} from "../_redux/actions/shumaActions";
import {decisionTree} from "../../_common/constants/decisionTree";

const PopupCopyInspectionContext = createContext('');

export function usePopupCopyInspectionContext() {
  return useContext(PopupCopyInspectionContext);
}

export function PopupCopyInspectionProvider({children, shumaReasonID}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );
  const {
    reasons,
    banks,
    propertyTypes,
    loanTypes,
    loanSubTypes,
    shumaMazminIdentities
  } = useDecisionTreeOptionsContext();
  const successMessage = intl.formatMessage({
    id: 'inspections-popup-copy-message-success',
    defaultMessage: 'Inspection was copied!'
  });

  const validate = (values) => {
    let errors = {};
    const {customers, loanTypeID, loanText} = values;
    ['responsibleUser', 'shumaMazminIdentityID']
      .forEach(function (item) {
        if (!values[item]) errors[item] = 'Required field!';
      });

    if (shumaReasonID === 2 && !values.bankID) {
      errors.bankID = 'Required field!';
    }

    if (
      loanTypeID &&
      `${loanTypeID.value}` === decisionTree.loanTypeID.another &&
      !loanText
    ) {
      errors.loanText = 'Required field!';
    }

    if (customers.length < 1) errors.customers = 'Need at least one customer!';

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      customers: [],
      responsibleUser: null,
      dueDate: null,
      shumaMazminIdentityID: null,
      bankID: null,
      bankBranchNumber: null,
      bankBranchName: null,
      bankClerkFullName: null,
      bankEmail: null,
      bankRequestID: null,
      bankBuildType: null,
      loanTypeID: null,
      loanSubTypeID: null,
      loanText: null,
      underConstruction: false,
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (shumaID) => {
    const {
      customers,
      responsibleUser,
      dueDate,
      shumaMazminIdentityID,
      bankID,
      loanTypeID,
      loanSubTypeID,
      ...rest
    } = formik.values;
    const data = {
      shumaID,
      customers: customers.map(item => item.customerID),
      responsibleUser: responsibleUser ? responsibleUser.value : null,
      dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : '',
      shumaMazminIdentityID: shumaMazminIdentityID ? shumaMazminIdentityID.value : null,
      bankID: bankID ? bankID.value : null,
      loanTypeID: loanTypeID ? loanTypeID.value : null,
      loanSubTypeID: loanSubTypeID ? loanSubTypeID.value : null,
      ...rest
    };
    try {
      const {errorCode} = await dispatch(copyShuma(data));
      if (errorCode === 0) {
        await dispatch(showGlobalMessage(successMessage));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const value = {
    formik,
    handleSubmit,
    options: {
      users,
      reasons,
      banks,
      propertyTypes,
      loanTypes,
      loanSubTypes,
      shumaMazminIdentities
    }
  };

  return (
    <PopupCopyInspectionContext.Provider value={value}>
      <FormikProvider value={formik}>
        {children}
      </FormikProvider>
    </PopupCopyInspectionContext.Provider>
  );
}

PopupCopyInspectionProvider.propTypes = {
  children: PropTypes.node,
  shumaReasonID: PropTypes.number,
};

import React from 'react';
import PopupFinalPDFData from "../PopupFinalPDFData";
import PopupPreviewDocument from "../PopupPreviewDocument";

const ButtonPreview = ({data}) => {

  return (data.originTypeID === 1)
    ? <PopupFinalPDFData content={data}/>
    : <PopupPreviewDocument content={data}/>;
};

export default ButtonPreview;
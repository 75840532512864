import React from 'react';
import {useHeader} from '../../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import SubheaderBar from '../../../_common/components/SubheaderBar';
import OldInspectionsPageFilters
  from '../../components/OldInspectionsPageFilter';
import InspectionsPageTable from '../../components/OldInspectionsPageTable';
import {
  OldInspectionsPageUIProvider
} from '../../components/OldInspectionsPageUIContext';
import {
  StreetsProvider
} from '../../../_common/components/CitiesAndStreets/components/CitiesAndStreetsContext';

const OldInspectionsPage = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'old-inspections-page-header',
    defaultMessage: 'Old inspections page'
  }));

  return (
    <StreetsProvider>
      <OldInspectionsPageUIProvider>
        <SubheaderBar>
          <OldInspectionsPageFilters/>
        </SubheaderBar>
        <InspectionsPageTable/>
      </OldInspectionsPageUIProvider>
    </StreetsProvider>
  );
};

export default OldInspectionsPage;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const NoteNumber = ({children, id}) => {
  return (
    <span
      className={styles.upperNote}
      data-label="noteNumber"
      data-id={id}
    >
      {children}
    </span>
  );
};

NoteNumber.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default NoteNumber;

import Chart from "react-apexcharts";
import React from "react";
import Empty from "../Empty";
import {FormattedMessage} from "react-intl";
import MultipleTooltip from "../MultipleTooltip";

export const ChartView = ({
                            options,
                            series,
                            type,
                            width,
                            height,
                            containerClassNames = '',
                            header,
                            tooltip = ''
                          }) => {

  const conditionShowChart = () => {
    switch (type) {
      case 'line':
        return series && series[0].data?.length > 0
      case 'bar':
      default:
        return series && series.length > 0
    }
  };

  return (
    <div className={containerClassNames}>
      <div className={'d-flex justify-content-center align-items-center'}>
        <h3 className={'text-center font-weight-normal py-6 mb-0'}>
          {header}
        </h3>
        {Boolean(tooltip) && (
          <MultipleTooltip
            id={`chart-${tooltip}`}
            customIconClassName={'cursor-pointer mx-2 position-static'}
            label={tooltip}
          />
        )}
      </div>
      {conditionShowChart()
        ?
        (
          <Chart
            options={options}
            series={series}
            type={type}
            width={width}
            height={height}
          />
        )
        :
        (
          <Empty
            icon={'../../../media/common/Nothing.svg'}
            customContainerStyles={'py-20 my-8 d-flex justify-content-center align-items-center'}
            message={
              <FormattedMessage
                id="no-data"
                defaultMessage="No data"
              />
            }
          />
        )
      }
    </div>
  )
}



import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {checkIfStartsWithComma, checkIfTextEndsWithDot, getBreakLinesTextShumaType3} from '../../../utils/texts';
import {getSelectedOptionLabel} from '../../../utils/options';
import {propertyIdentifiedByOptions} from '../../../constants/options';
import {propertyTypesGroups as PT} from '../../../constants/propertyTypesGroups';
import styles from './styles.module.scss';

const HeadLine1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {},
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      <HeadContent/>
      <Line1Content/>
    </div>
  );
});

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section6}. `}</span>
        <span>פרטי זיהוי הנכס, הגדרתו, וזיקת הלווים לנכס</span>
      </span>
    </div>
  );
};

const Line1Content = React.forwardRef(() => {
  const {
    data: {
      pdfData: {
        propertyLegalDefinition
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(propertyLegalDefinition) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>הגדרה משפטית של הנכס: </span>
      <span>{getBreakLinesTextShumaType3(propertyLegalDefinition)}</span>
    </div>
  );
});

const Line3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        otherPropertyTypeName
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(otherPropertyTypeName) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            מהות הנכס: 
          </span>
          <span>{otherPropertyTypeName}{checkIfTextEndsWithDot(otherPropertyTypeName)}</span>
        </div>
      )}
    </div>
  );
});

const Line4 = React.forwardRef(({}, ref) => {

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.textUnderline, styles.bold, styles.innerPadding)}
      ref={ref}
    >
      שטח הנכס:
    </div>
  );
});

const Line5 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        housePermitArea,
        housePermitAreaIncludeBalcony,
        housePermitAreaIncludeStorage,
        houseBalconyOpenPermitArea,
        houseBalconyClosedPermitArea,
        houseYardPermitArea,
        houseStoragePermitArea,
        houseRoofPermitArea,
        houseParkingPermitArea,
        housePermitAreaText
      },
    },
  } = useCompleteInspectionContext();

  const text1 = [
    [
      (Boolean(housePermitArea) && !PT.LOT.includes(propertyTypeID)) ? `${housePermitArea} מ"ר` : '',
      Boolean(housePermitAreaIncludeBalcony) ? 'כולל שטחי מרפסות' : '',
      Boolean(housePermitAreaIncludeStorage) ? 'כולל שטחי מחסן' : '',
    ].filter(Boolean).join(', '),
    _.some([
      housePermitArea && !PT.LOT.includes(propertyTypeID),
      housePermitAreaIncludeBalcony,
      housePermitAreaIncludeStorage
    ]) ? '. ' : '',
  ].filter(Boolean).join('');
  const text2 = [
    Boolean(houseBalconyOpenPermitArea) ? `${houseBalconyOpenPermitArea} מ"ר שטח מרפסות פתוחות` : '',
    Boolean(houseBalconyClosedPermitArea) ? `, ${houseBalconyClosedPermitArea} מ"ר שטח מרפסות סגורות` : '',
    Boolean(houseYardPermitArea) ? `, ${houseYardPermitArea} מ"ר שטח חצר` : '',
    Boolean(houseStoragePermitArea) ? `, ${houseStoragePermitArea} מ"ר מחסן` : '',
    Boolean(houseRoofPermitArea) ? `, ${houseRoofPermitArea} מ"ר שטח גג` : '',
    Boolean(houseParkingPermitArea) ? `, ${houseParkingPermitArea} מ"ר שטח חניה` : '',
    _.some([
      houseBalconyOpenPermitArea,
      houseBalconyClosedPermitArea,
      houseYardPermitArea,
      houseStoragePermitArea,
      houseRoofPermitArea,
      houseParkingPermitArea
    ]) ? '. ' : '',
    Boolean(housePermitAreaText)
      ? `${housePermitAreaText}${checkIfTextEndsWithDot(housePermitAreaText)}`
      : ''
  ].filter(Boolean).join('');

  return (
    <div ref={ref}>
      {Boolean(text1 || text2) && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם להיתר בניה: 
          </span>
          <span>{text1}</span>
          {_.some([
            houseBalconyOpenPermitArea,
            houseBalconyClosedPermitArea,
            houseYardPermitArea,
            houseStoragePermitArea,
            houseRoofPermitArea,
            houseParkingPermitArea
          ]) && (<span> הצמדות: </span>)}
          <span>{checkIfStartsWithComma(text2)}</span>
        </div>
      )}
    </div>
  );
});

const Line6 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingTasritTaba
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingTasritTaba) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לתשריט תב"ע: 
          </span>
          <span>{houseAreaAccordingTasritTaba}{checkIfTextEndsWithDot(houseAreaAccordingTasritTaba)}</span>
        </div>
      )}
    </div>
  );
});

const Line7 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseRegisteredArea,
        houseRegisteredAreaIncludeBalcony,
        houseRegisteredAreaIncludeStorage,
        houseBalconyOpenRegisteredArea,
        houseBalconyClosedRegisteredArea,
        houseYardRegisteredArea,
        houseStorageRegisteredArea,
        houseRoofRegisteredArea,
        houseParkingRegisteredArea,
        houseJointHomeRegistered,
        registeredAreaAdditionalDescription
      },
    },
  } = useCompleteInspectionContext();

  const text1 = [
    Boolean(houseRegisteredArea) ? `${houseRegisteredArea} מ"ר` : '',
    houseRegisteredArea && houseRegisteredAreaIncludeBalcony ? ', ' : '',
    Boolean(houseRegisteredAreaIncludeBalcony) ? 'כולל שטחי מרפסות' : '',
    (houseRegisteredAreaIncludeBalcony && houseRegisteredAreaIncludeStorage) || (houseRegisteredArea && houseRegisteredAreaIncludeStorage) ? ', ' : '',
    Boolean(houseRegisteredAreaIncludeStorage) ? 'כולל שטחי מחסן' : '',
    _.some([
      houseRegisteredArea,
      houseRegisteredAreaIncludeBalcony,
      houseRegisteredAreaIncludeStorage
    ]) ? '.' : '',
  ].filter(Boolean).join('');

  const text2 = [
    Boolean(houseBalconyOpenRegisteredArea) ? `${houseBalconyOpenRegisteredArea} מ"ר שטח מרפסות פתוחות` : '',
    Boolean(houseBalconyClosedRegisteredArea) ? `, ${houseBalconyClosedRegisteredArea} מ"ר שטח מרפסות סגורות` : '',
    Boolean(houseYardRegisteredArea) ? `, ${houseYardRegisteredArea} מ"ר שטח חצר` : '',
    Boolean(houseStorageRegisteredArea) ? `, ${houseStorageRegisteredArea} מ"ר מחסן` : '',
    Boolean(houseRoofRegisteredArea) ? `, ${houseRoofRegisteredArea} מ"ר שטח גג` : '',
    Boolean(houseParkingRegisteredArea) ? `, ${houseParkingRegisteredArea} מ"ר שטח חניה` : '',
  ].filter(Boolean).join('');

  const text3 = Boolean(registeredAreaAdditionalDescription) ? ` ${registeredAreaAdditionalDescription}${checkIfTextEndsWithDot(registeredAreaAdditionalDescription)}` : '';

  return (
    <div ref={ref}>
      {Boolean(text1 || text2) && houseJointHomeRegistered !== 2 && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לנסח הרישום ותשריט הבית המשותף: 
          </span>
          <span>{text1}</span>
          {_.some([
            houseBalconyOpenRegisteredArea,
            houseBalconyClosedRegisteredArea,
            houseYardRegisteredArea,
            houseStorageRegisteredArea,
            houseRoofRegisteredArea,
            houseParkingRegisteredArea,
          ]) && (<span> הצמדות: </span>)}
          <span>
            {checkIfStartsWithComma(text2)}
            {text2 && text3 && ','}
            {text2 && !text3 && '.'}
          </span>
          <span>{text3}</span>
        </div>
      )}
    </div>
  );
});

const Line8 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingNesahRishum
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingNesahRishum) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לנסח הרישום (מפנקס הזכויות): 
          </span>
          <span>{houseAreaAccordingNesahRishum}{checkIfTextEndsWithDot(houseAreaAccordingNesahRishum)}</span>
        </div>
      )}
    </div>
  );
});

const Line9 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingTasritHahira
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingTasritHahira) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לתשריט חכירה: 
          </span>
          <span>{houseAreaAccordingTasritHahira}{checkIfTextEndsWithDot(houseAreaAccordingTasritHahira)}</span>
        </div>
      )}
    </div>
  );
});

const Line10 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingTasritMeher
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingTasritMeher) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
           <span className={classnames(styles.bold)}>
             בהתאם לתשריט מכר: 
           </span>
          <span>{houseAreaAccordingTasritMeher}{checkIfTextEndsWithDot(houseAreaAccordingTasritMeher)}</span>
        </div>
      )}
    </div>
  );
});

const Line11 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingHeskemShituf
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingHeskemShituf) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לתשריט הסכם שיתוף: 
          </span>
          <span>{houseAreaAccordingHeskemShituf}{checkIfTextEndsWithDot(houseAreaAccordingHeskemShituf)}</span>
        </div>
      )}
    </div>
  );
});

const Line12 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyAreaByMunicipal
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(propertyAreaByMunicipal) && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לחיוב ארנונה: 
          </span>
          <span>{propertyAreaByMunicipal}{checkIfTextEndsWithDot(propertyAreaByMunicipal)}</span>
        </div>
      )}
    </div>
  );
});

const Line13 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        cityName,
        neighborhoodName,
        streetName,
        houseNumber
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
      <div className={classnames(styles.bold, styles.textUnderline)}>
        כתובת מלאה:
      </div>
      {Boolean(cityName) && <span className={styles.bold}>ישוב: </span>}
      {Boolean(cityName) && <span>{`${cityName}`}</span>}
      {Boolean(neighborhoodName) && <span className={styles.bold}>    שכונה: </span>}
      {Boolean(neighborhoodName) && <span>{`${neighborhoodName}`}</span>}
      {Boolean(streetName) && <span className={styles.bold}>    רחוב: </span>}
      {Boolean(streetName) && <span>{`${streetName}`}</span>}
      {Boolean(houseNumber) && <span className={styles.bold}>    מס' בית: </span>}
      {Boolean(houseNumber) && <span>{`${houseNumber}`}</span>}
    </div>
  );
});

const Line14 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        structure,
        entrance,
        apartmentNumber,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
      {Boolean(structure) && <span className={styles.bold}>מבנה: </span>}
      {Boolean(structure) && <span>{`${structure}    `}</span>}
      {Boolean(entrance) && <span className={styles.bold}>כניסה: </span>}
      {Boolean(entrance) && <span>{`${entrance}`}</span>}
      {(entrance && apartmentNumber)
        ? <span>    </span>
        : ''
      }
      {Boolean(apartmentNumber) && <span className={styles.bold}>מס' דירה: </span>}
      {Boolean(apartmentNumber) && <span>{apartmentNumber}</span>}
    </div>
  );
});

const Line15 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        gush,
        helka,
        ttHelka,
        landLotNum,
        landLotNumAccordingTaba,
        landLotNumShituf,
        noKadestritDivideText
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
      <div className={classnames(styles.bold, styles.textUnderline)}>
        פרטי הרישום:
      </div>
      {Boolean(gush) && <span className={styles.bold}>גוש: </span>}
      {Boolean(gush) && <span>{`${gush}`}</span>}
      {Boolean(helka) && <span className={styles.bold}>    חלקה: </span>}
      {Boolean(helka) && <span>{`${helka}`}</span>}
      {Boolean(ttHelka) && <span className={styles.bold}>    תת חלקה: </span>}
      {Boolean(ttHelka) && <span>{`${ttHelka}`}</span>}
      {Boolean(landLotNum) && <span className={styles.bold}>    מס' מגרש: </span>}
      {Boolean(landLotNum) && <span>{`${landLotNum}`}</span>}
      {Boolean(landLotNumAccordingTaba) && <span className={styles.bold}>    לפי תב"ע: </span>}
      {Boolean(landLotNumAccordingTaba) && <span>{`${landLotNumAccordingTaba}`}</span>}
      {Boolean(landLotNumShituf) && <span className={styles.bold}>    מספר מגרש לפי הסכם שיתוף: </span>}
      {Boolean(landLotNumShituf) && <span>{`${landLotNumShituf}`}</span>}
      {Boolean(noKadestritDivideText) && <div>{`${noKadestritDivideText}`}</div>}
    </div>
  );
});

const Line16 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        registerDetailsNotes
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(registerDetailsNotes) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span>{getBreakLinesTextShumaType3(registerDetailsNotes)}</span>
        </div>
      )}
    </div>
  );
});

const Line17 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyIdentifiedBy
      },
    },
  } = useCompleteInspectionContext();
  const propertyIdentifiedByText = getSelectedOptionLabel(propertyIdentifiedBy, propertyIdentifiedByOptions);

  return (
    <div ref={ref}>
      {Boolean(propertyIdentifiedBy) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold, styles.textUnderline)}>
          זיהוי הנכס על ידי:
          </span>
          <span> {propertyIdentifiedByText}{checkIfTextEndsWithDot(propertyIdentifiedByText)}</span>
        </div>
      )}
    </div>
  );
});

export default {
  HeadLine1,
  Line3,
  Line4,
  Line5,
  Line6,
  Line7,
  Line8,
  Line9,
  Line10,
  Line11,
  Line12,
  Line13,
  Line14,
  Line15,
  Line16,
  Line17,
};

import React from 'react';

export function GushAndHelkaFormatter(gush, helka) {
  const text = (gush || helka)
    ? [gush || '', '/', helka || ''].join('')
    : ''
  ;

  return (
    <div className="font-weight-bold">
      {text}
    </div>
  );
}

import React from 'react';
import classnames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {FormikProvider} from 'formik';
import {
  SectionGeneralInfo,
  SectionAdditionalInfo,
  SectionBuildingInfo,
  SectionCustomers
} from '../OldInspectionFormSections';
import {SectionFiles} from '../SectionFiles/components/SectionFiles';
import Spinner from '../../../_common/components/Spinner';
import {
  useOldInspectionDetailsUIContext
} from '../OldInspectionsDetailsUIContext';
import styles from '../styles.module.scss';

export function SectionsContainer() {
  const {
    shumaID,
    orderID,
    fieldNames,
    formik,
    loading,
    editOldInspection
  } = useOldInspectionDetailsUIContext();

  return (
    <FormikProvider value={formik}>
      {(!loading)
        ? (
          <>
            <div
              className={classnames(`d-flex justify-content-between p-4 mx-auto mt-20`, styles.inspectionDetailsContainer)}
            >
              <div>
                <SectionGeneralInfo
                  formik={formik}
                  fieldNames={fieldNames}
                />
                <SectionCustomers
                  formik={formik}
                  fieldNames={fieldNames}
                />
              </div>
              <div className={styles.additionalMenuContainer}>
                <div className="d-flex justify-content-between">
                  <SectionBuildingInfo
                    formik={formik}
                    fieldNames={fieldNames}
                  />
                  <SectionAdditionalInfo
                    formik={formik}
                    fieldNames={fieldNames}
                  />
                </div>
                <SectionFiles shumaID={shumaID} orderID={orderID}/>
              </div>
            </div>
            <button
              type="submit"
              className={classnames(styles.submitButton, `btn btn-warning font-weight-bolder btn-m text-dark`)}
              onClick={() => editOldInspection(formik.values)}
              disabled={!(formik.dirty && formik.isValid)}
            >
              <img
                src={'../../../media/common/Save.svg'}
                alt="save"
              />
              <FormattedMessage
                id="submit"
                defaultMessage="Submit"
              />
            </button>
          </>
        )
        : (
          <div
            className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner/>
          </div>
        )
      }
    </FormikProvider>
  );
}

export default SectionsContainer;

const defaultState = {
  documentData: {},
  loadingDocumentData: true,
  error: false,
  errorMessage: null
};

export const actionTypes = {
  FETCH_DOCUMENT_DATA: 'FETCH_DOCUMENT_DATA',
  catchError: 'CATCH_ERROR'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_DATA: {

      return {
        ...state,
        loadingDocumentData: false,
        documentData: action.payload,
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.catchError: {
      return {
        ...state,
        loadingDocumentData: false,
        error: true,
        errorMessage: `FETCH_DOCUMENT_DATA: ${action.payload.error}`
      };
    }
    default:
      return state;
  }
};

export const actionsDocumentData = {
  fetchDocumentData: (data) => ({
    type: actionTypes.FETCH_DOCUMENT_DATA,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: {error}
  }),
};

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import classnames from 'classnames';
import styles from './styles.module.scss';

const DocumentTitle = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyTypeName,
        streetName,
        houseNumber,
        cityName,
        gush, helka, ttHelka,
        landLotNum,
      },
    },
  } = useCompleteInspectionContext();
  const getPropertyTypeName = () => {
    switch (propertyTypeID) {
      case 1: return 'דירת מגורים'
      case 11: return Boolean(landLotNum) ? '' : propertyTypeName
      default: return propertyTypeName
    }
  };
  const text = [
    'חוות דעת להערכת נכס מקרקעין -',
    getPropertyTypeName(),
    Boolean(streetName) && 'ברחוב',
    Boolean(streetName) && streetName,
    Boolean(houseNumber) && `${houseNumber},`,
    propertyTypeID === 11
      ? Boolean(landLotNum) && `מגרש ${landLotNum},`
      : (!streetName || !houseNumber) && Boolean(landLotNum) && `מגרש ${landLotNum},`,
    `${cityName},`,
    'גוש',
    `${gush}`,
    'חלקה',
    `${helka}`,
    ttHelka && 'תת חלקה',
    Boolean(ttHelka) ? `${ttHelka}` : '',
  ].filter(Boolean).join(' ');

  return (
    <div
      className={classnames(styles.bold, styles.textCentered, styles.textUnderline, styles.documentTitleContainer)}
      ref={ref}
    >
      {text}
    </div>
  );
});

export default DocumentTitle;

import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useTable} from "react-table";
import {useHistory} from 'react-router-dom';
import * as actions from '../../actions/OldInspectionsPageTableActions';
import * as columnFormatters from '../../../_common/column-formatters';
import {useOldInspectionsPageUIContext} from '../OldInspectionsPageUIContext';
import useInfiniteScrollControl
  from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import CommonGrid from "../../../_common/components/CommonGrid";
import styles from "./styles.module.scss";

export default function OldInspectionsPageTable() {
  const intl = useIntl();
  const history = useHistory();
  const [tableRows, setTableRows] = useState([]);
  const columns = useMemo(() => [
    {
      accessor: 'shumaID',
      Header: intl.formatMessage({
        id: 'column-shumaID', defaultMessage: '#'
      })
    },
    {
      accessor: 'shumaOrigin',
      Header: intl.formatMessage({
        id: 'column-shumaOrigin', defaultMessage: 'Origin'
      })
    },
    {
      accessor: 'address',
      Header: intl.formatMessage({
        id: 'column-address', defaultMessage: 'Address'
      })
    },
    {
      accessor: 'gush',
      Header: intl.formatMessage({
        id: 'column-gush', defaultMessage: 'Gush'
      })
    },
    {
      accessor: 'helka',
      Header: intl.formatMessage({
        id: 'column-helka', defaultMessage: 'Helka'
      })
    },
    {
      accessor: 'ttHelka',
      Header: intl.formatMessage({
        id: 'column-ttHelka', defaultMessage: 'ttHelka'
      })
    },
    {
      accessor: 'officialDate',
      Header: intl.formatMessage({
        id: 'column-officialDate', defaultMessage: 'Official date'
      })
    },
    {
      accessor: 'averageSqmPrice',
      Header: intl.formatMessage({
        id: 'column-pricePerSQM', defaultMessage: 'averageSqmPrice'
      })
    },
    {
      accessor: 'estimatePriceFinal',
      Header: intl.formatMessage({
        id: 'column-price', defaultMessage: 'estimatePriceFinal'
      })
    },
    {
      accessor: 'customers',
      Header: intl.formatMessage({
        id: 'column-customers', defaultMessage: 'customers'
      })
    },
    {
      accessor: 'editButton',
      Header: intl.formatMessage({
        id: 'column-editButton', defaultMessage: 'Edit'
      })
    },
  ], []);
  const data = useMemo(() => [
    ...tableRows.map((item) => {
      return {
        shumaID: columnFormatters.BoldColumnFormatter(item.shumaID),
        shumaOrigin: columnFormatters.BoldColumnFormatter(item.shumaOriginTypeName),
        address: columnFormatters.AddressColumnFormatter(item.cityName, item.streetName, item.houseNum),
        gush: columnFormatters.BoldColumnFormatter(item.gush),
        helka: columnFormatters.BoldColumnFormatter(item.helka),
        ttHelka: columnFormatters.BoldColumnFormatter(item.ttHelka),
        officialDate: columnFormatters.DateColumnFormatter(item.officialDate),
        averageSqmPrice: columnFormatters.BoldColumnFormatter(item.averageSqmPrice),
        estimatePriceFinal: columnFormatters.PriceColumnFormatter(item.estimatePriceFinal),
        customers: columnFormatters.SeveralItemsColumnFormatter(item.customers),
        editButton: columnFormatters.EditRowColumnFormatter(null, item, {history}),
      };
    })
  ], [tableRows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'oldInspectionsPageTableReducer',
    UIContext: useOldInspectionsPageUIContext(),
    getNewEntities: actions.getOldInspections,
    getNextEntities: actions.getOldNextInspections,
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([])
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-50px d-flex justify-content-center'}>
        <span className='spinner spinner-md spinner-warning'/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  const gridProps = {
    infiniteScrollProps,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows
  }

  return (
    <CommonGrid
      customHeaderStyles={styles.customHeader}
      customBodyStyles={styles.customBody}
      {...gridProps}
    />
  );
}

import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';
import FieldInput from '../../../_common/components/FieldInput';
import FieldSelect from '../../../_common/components/FieldSelect';
import CommonButton from "../../../_common/components/CommonButton";
import * as actions from '../../actions/CustomersEntityTableActions';
import * as giActions from '../../../_common/actions/greenInvoice';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import {showGlobalMessage} from '../../../_common/components/GlobalMessage/actions/showGlobalMessage';
import {can} from '../../../_common/components/Permissions';
import {permissionTypes as T, subjects as S} from '../../../_common/constants/permissionSubjects';
import {filterOptions} from '../../../_common/utils/options';
import {customerStatus, customerTypes, genders} from '../../../_common/constants/options';
import {messages as M} from '../../../../../_metronic/i18n/messages';
import {checkEmailIsValid} from '../../../_common/utils/checkEmailIsValid';
import styles from '../CustomersEntityFilter/styles.module.scss';

const FormCustomerTypePerson = ({values}) => {
  const intl = useIntl();
  const labelFirstName = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-first-name',
    defaultMessage: 'First name',
  });
  const labelLastName = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-last-name',
    defaultMessage: 'Last name',
  });
  const labelGender = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-gender',
    defaultMessage: 'Gender',
  });
  const labelIdNumber = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-idNumber',
    defaultMessage: 'ID number',
  });
  const placeholderSelect = intl.formatMessage({
    id: 'select',
    defaultMessage: 'Select',
  });

  return (
    <>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'firstName'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelFirstName}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'lastName'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelLastName}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
      <FieldSelect
        fieldId={'genderID'}
        value={filterOptions(values.genderID)(genders)}
        options={genders}
        label={(
          <div className="d-inline-block font-weight-bolder text-dark-75">
            <span className={'text-danger'}>*</span>
            {labelGender}
          </div>
        )}
        placeholder={placeholderSelect}
        customLabelStyles={'pl-6'}
        customContainerStyles={'py-3'}
      />
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'idNumber'}
          label={labelIdNumber}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
};

const FormCustomerTypesCompany = () => {
  const intl = useIntl();
  const labelFirstName = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-name',
    defaultMessage: 'Name',
  });
  const labelIdNumber = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-companyID',
    defaultMessage: 'Company ID',
  });

  return (
    <>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'firstName'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelFirstName}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'idNumber'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelIdNumber}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
};

const FormCustomerTypeGroup = () => {
  const intl = useIntl();
  const labelFirstName = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-groupName',
    defaultMessage: 'Group name',
  });
  const labelIdNumber = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-groupID',
    defaultMessage: 'Group ID',
  });
  const labelLastName = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-groupSubName',
    defaultMessage: 'Group sub name',
  });

  return (
    <>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'firstName'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelFirstName}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'idNumber'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelIdNumber}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
      <div className={'position-relative'}>
        <FieldInput
          fieldId={'lastName'}
          label={(
            <div className="d-inline-block font-weight-bolder text-dark-75">
              <span className={'text-danger'}>*</span>
              {labelLastName}
            </div>
          )}
          type={'text'}
          customLabelStyles={'pl-6'}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
};

const CustomerDataForm = ({close, formikProps}) => {
  const intl = useIntl();
  const header = intl.formatMessage({
    id: 'customers-entity-popup-create-customer-header',
    defaultMessage: 'Add customer',
  });
  const labelButtonClose = intl.formatMessage({
    id: 'button-close',
    defaultMessage: 'Close',
  });
  const labelButtonSubmit = intl.formatMessage({
    id: 'button-submit',
    defaultMessage: 'Submit',
  });
  const labelCustomerType = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-type',
    defaultMessage: 'Type',
  });
  const successMessage = intl.formatMessage({
    id: 'data-sent-success',
    defaultMessage: 'Success',
  });
  const placeholderSelect = intl.formatMessage({
    id: 'select',
    defaultMessage: 'Select',
  });
  const invalidEmail = intl.formatMessage({
    id: 'invalid-email-message',
    defaultMessage: 'Invalid email',
  });
  const labelPhone = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-phone',
    defaultMessage: 'Phone',
  });
  const labelEmail = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-email',
    defaultMessage: 'Email',
  });
  const labelAddress = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-address',
    defaultMessage: 'Address',
  });
  const labelStatus = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-status',
    defaultMessage: 'Status',
  });


  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);

  const {
    greenInvoiceApiKey,
    greenInvoiceSecret
  } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    idNumber: '',
    address: '',
    genderID: null,
    customerType: null,
    status: 1,
    customerID: -1
  };

  const validate = (values) => {
    setEmailError(false);
    let errors = {};

    if (!values.customerType) {
      errors.customerType = 'Required';
    }

    switch (values.customerType) {
      case 1:
      case 2:
      case 3:
        ['firstName', 'idNumber'].forEach(function (item) {
          if (!values[item]) errors[item] = 'Required';
        });
        break;
      case 4:
        ['firstName', 'lastName', 'genderID'].forEach(function (item) {
          if (!values[item]) errors[item] = 'Required';
        });
        break;
      case 5:
        ['firstName', 'lastName', 'idNumber'].forEach(function (item) {
          if (!values[item]) errors[item] = 'Required';
        });
        break;
    }

    if (values.email && !checkEmailIsValid(values.email)) {
      errors['email'] = 'Invalid email address';
      setEmailError(true);
    }

    return errors;
  };

  const buildAddCustomerBody = (values) => {
    switch (values.customerType) {
      case 1:
      case 2:
      case 3:
        return {...values, lastName: '', genderID: null};
      case 4:
        return {...values};
      case 5:
        return {...values, genderID: null};
    }
  };

  const addCustomerData = (values) => async () => {
    try {
      const data = buildAddCustomerBody(values);
      const customerID = await dispatch(actions.addCustomer(data, Boolean(formikProps)));
      await createGreenInvoiceClient(customerID, data);

      if (formikProps) {
        const customerData = {
          customerID: customerID,
          email: data?.email,
          firstName: data?.firstName,
          idNumber: data?.idNumber,
          lastName: data?.lastName,
          phone: data?.phone,
        };
        formikProps.setFieldValue('customers', [...formikProps.values.customers, customerData]);
      }

      await dispatch(showGlobalMessage(successMessage));
    } catch (e) {
      console.error('addCustomerData:', e);
      await dispatch(showGlobalMessage(M['-1']));
    } finally {
      await close();
    }
  };

  const createGreenInvoiceClient = async (customerID, data) => {
    try {
      await createClient({...data, customerID});
    } catch (e) {
      console.error('createGreenInvoiceClient:'. e);
      await dispatch(showGlobalMessage(M.GREEN_INVOICE_CREATE_CUSTOMER_ERROR));
    }
  }

  const createClient = ({customerID, firstName, lastName, phone, email, idNumber, address}) => {
    if (Boolean(greenInvoiceApiKey) && Boolean(greenInvoiceSecret)) {
      dispatch(giActions.createClient({
        customerID,
        name: `${firstName} ${lastName}`,
        phone,
        emails: Boolean(email) ? [email] : [],
        taxId: idNumber,
        address
      }, {
        greenInvoiceApiKey,
        greenInvoiceSecret
      }));
    }
  };

  const renderFormByCustomerType = (values) => {
    switch (values.customerType) {
      case 1:
      case 2:
      case 3:
        return <FormCustomerTypesCompany/>;
      case 4:
        return <FormCustomerTypePerson values={values}/>;
      case 5:
        return <FormCustomerTypeGroup/>;
      default:
        return null;
    }
  };

  const resetFields = (values, setFieldValue) => {
    switch (values.customerType) {
      case 1:
      case 2:
      case 3:
        setFieldValue('lastName', initialValues.lastName);
        setFieldValue('genderID', initialValues.genderID);
        break;
      case 4:
        break;
      case 5:
        setFieldValue('genderID', initialValues.genderID);
        break;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize={true}
      validate={validate}
    >
      {({values, setFieldValue, dirty, isValid}) => (
        <div className="d-flex justify-content-center flex-column py-4 px-14">
          <h2 className="font-weight-normal text-dark w-100 d-flex justify-content-center mb-6">
            {header}
          </h2>
          <FieldSelect
            fieldId={'customerType'}
            value={filterOptions(values.customerType)(customerTypes)}
            options={customerTypes}
            label={
              <div className="font-weight-bolder text-dark-75 pl-6">
                <div className="d-inline-block font-weight-bolder text-dark-75">
                  <span className={'text-danger'}>*</span>
                  {labelCustomerType}
                </div>
              </div>
            }
            onChange={() => resetFields(values, setFieldValue)}
            placeholder={placeholderSelect}
            customContainerStyles={'py-3'}
          />
          {renderFormByCustomerType(values)}
          <div className={'position-relative'}>
            <FieldInput
              fieldId={'phone'}
              label={labelPhone}
              type={'text'}
              customLabelStyles={'pl-6'}
              customContainerStyles={'py-3'}
            />
          </div>
          <div className={'position-relative'}>
            <FieldInput
              dir={'ltr'}
              fieldId={'email'}
              label={labelEmail}
              type={'email'}
              customLabelStyles={'pl-6'}
              customContainerStyles={'py-3'}
              customInputStyles={'text-left'}
            />
            {emailError && (
              <div className={styles.invalidEmailMessage}>
                {invalidEmail}
              </div>
            )}
          </div>
          <div className={'position-relative'}>
            <FieldInput
              fieldId={'address'}
              label={labelAddress}
              type={'text'}
              customLabelStyles={'pl-6'}
              customContainerStyles={'py-3'}
            />
          </div>
          <FieldSelect
            fieldId={'status'}
            value={filterOptions(values.status)(customerStatus)}
            options={customerStatus}
            label={labelStatus}
            placeholder={placeholderSelect}
            customLabelStyles={'font-weight-bolder pl-6'}
            customContainerStyles={'py-3'}
          />

          <div className="d-flex justify-content-between py-10">
            <CommonButton
              variant={'button'}
              onClick={close}
              label={labelButtonClose}
            />
            <CommonButton
              variant={'submit'}
              onClick={addCustomerData(values)}
              disabled={!dirty || !isValid}
              label={labelButtonSubmit}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default function PopupAddCustomer({
                                               formikProps,
                                               customButtonClassNames
                                             }) {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const intl = useIntl();
  const addRowButton = intl.formatMessage({
    id: 'customers-entity-add-row-button',
    defaultMessage: 'Add customer',
  });

  return (
    <div className={customButtonClassNames}>
      <button
        onClick={handleShowModal}
        className={`${styles.addRowButton} btn btn-warning btn-sm font-weight-bolder text-dark text-nowrap mr-5`}
        disabled={!can(T.FULL, S.CUSTOMERS_ENTITY_PAGE)}
      >
        <img
          src={toAbsoluteUrl('/media/common/Plus.svg')}
          alt="add-row"
          className={styles.addRowIcon}
        />
        <span>{addRowButton}</span>
      </button>
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <CustomerDataForm close={handleCloseModal} formikProps={formikProps}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getUsers} from '../actions/UsersActions';

function withUsers(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      const {users, getUsers} = this.props;
      if (users.length < 1) {
        getUsers();
      }
    }

    getOptions = () => {
      const {users} = this.props;
      return {
        users: users || [],
      }
    };

    render() {
      return <WrappedComponent {...this.props} {...this.getOptions()}/>;
    }
  };
}

const mapStateToProps = ({
                           usersReducer: {users}
                         }) => {
  return {users};
};

export default compose(
  connect(
    mapStateToProps,
    {getUsers}
  ),
  withUsers
);

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import classnames from 'classnames';
import styles from '../styles.module.scss';

const ShumaType2and3BuildingPopulatedText = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingPopulatedValue = getSelectedOptionLabel(context.data.pdfData.buildingPopulated, options.buildingPopulatedOptions);

  return (
    <div className={classnames(styles.innerPadding, styles.itemText)} ref={ref}>
      <span className={styles.bold}>האם הבניין מאוכלס: </span>
      {` ${buildingPopulatedValue}.`}
    </div>
  );
});

export default ShumaType2and3BuildingPopulatedText;

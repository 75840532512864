import React from 'react';
import PropTypes from 'prop-types';
import FieldInput from '../../../../_common/components/FieldInput';
import ButtonRemoveRow from '../ButtonRemoveRow';
import {floatValue} from '../../../../../../utils/format';
import styles from '../styles.module.scss';

const RowWithholdingTax = ({
                             fieldsSet,
                             removeRow
                           }) => {
  return (
    <div className={styles.withholdingTaxRowContainer}>
      <FieldInput
        fieldId={fieldsSet.price}
        customContainerStyles={styles.gridCellPrice}
        onChange={floatValue}
      />
      <ButtonRemoveRow onClick={removeRow} styles={styles}/>
    </div>
  );
};

RowWithholdingTax.propTypes = {
  fieldsSet: PropTypes.object,
  removeRow: PropTypes.func,
};

export default RowWithholdingTax;

import React from 'react';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {isDifferenceOutOfLimit} from '../../../utils/texts';
import styles from './styles.module.scss';

const Section6Text78 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseExistingArea,
    housePermitArea,
  } = context.data.pdfData;
  const permitAndExistingAreaComparisonText = isDifferenceOutOfLimit(houseExistingArea, housePermitArea)
    ? 'לא תואם מהותית את שטח הדירה ברישוי'
    : 'תואם מהותית את שטח הדירה ברישוי';

  const {noteNumbers} = context;

  return (
    <div ref={ref}>
      {Boolean(houseExistingArea) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
      <span className={styles.bold}>
        שטח קיים
        <NoteNumber
          id={'permitAndExistingAreaComparisonText'}>{noteNumbers.permitAndExistingAreaComparisonText}</NoteNumber>
        {`: `}
      </span>
          <span>{permitAndExistingAreaComparisonText}</span>
          <span>.</span>
        </div>
      )}
    </div>
  );
});

export default Section6Text78;

import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styles from './styles.module.scss';

const PDFPage = ({
                   page,
                   file,
                   className,
                   onLoad,
                 }) => {
  const src = file.convertedPages[page - 1];

  return (
    <div className={`${styles.container} ${className}`}>
      <Image src={src} alt={`page-${page}`} onImageLoad={onLoad}/>
    </div>
  );
};

PDFPage.propTypes = {
  page: PropTypes.number,
  className: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number,
  onLoad: PropTypes.func,
};

export default PDFPage;

import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import {getSelectedOptionLabel} from '../../../utils/options';
import {floorOptions, lotPropertyFloorOptions} from '../../../constants/options';
import {propertyTypesGroups as PT} from '../../../constants/propertyTypesGroups';
import styles from './styles.module.scss';

const imagesPerRow = 2;

const Floor = ({files, label, className}) => {
  const mapImages = () => {
    return files.map((file, index) => {
      const {body, file_name, free_text, file_extension} = file;
      const src = getFullDataURI({body, file_extension});
      return (
        <div className={classnames(
          styles.imageItem,
          styles.innerPadding,
          {
            [styles.twoInRow]: files.length >= 2,
            [styles.oneInRow]: files.length < 2,
          }
        )} key={index}>
          <div className={styles.imageWrapper}>
            <div className={classnames(styles.freeText, styles.bold, styles.textRegular)}>
              {free_text}
            </div>
            <img
              src={src}
              alt={file_name}
              key={index}
              className={styles.floorImage}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classnames(styles.floorContainer, styles.marginAfterPlan, className)}>
      {Boolean(label) && (label !== 'undefined') && (
        <div className={classnames(styles.label, styles.textRegular, styles.innerPadding)}>
          {label}
        </div>
      )}
      <div className={styles.images}>
        {mapImages()}
      </div>
    </div>
  );
};

const getImagesSortedByFloor = (context, fileTypeID) => context.files
  .filter(file => file.file_type_id === fileTypeID)
  .sort((prev, next) => {
    if (prev.free_id > next.free_id) {
      return 1;
    }
    if (prev.free_id < next.free_id) {
      return -1;
    }
    return 0;
  });

const FloorsHeader = ({header}) => (
  <div className={classnames(
    styles.bold,
    styles.marginVertFloors,
    styles.innerPadding,
    styles.textRegular
  )}>
    {header}
  </div>
);

const mapFloors = imagesGroupedByFloor => (label) => {
  const images = imagesGroupedByFloor[label];
  const rows = _.chunk(images, imagesPerRow);

  return rows
    .map((row, index) => (
      <Floor
        files={row}
        label={(index === 0) && label}
        className={(index !== rows.length - 1) ? styles.floorRow : ''}
      />
    ));
};

export const getFloors = (context, fileTypeID, header) => {
  const options = PT.GROUND_OR_LOT.includes(context.data.pdfData.propertyTypeID)
    ? lotPropertyFloorOptions
    : floorOptions;
  const imagesSortedByFloor = getImagesSortedByFloor(context, fileTypeID);
  const imagesWithNamedFloor = imagesSortedByFloor.map(image => {
    const labelText = getSelectedOptionLabel(image.free_id, options);
    const floorLabel = (parseInt(image.free_id, 10) <= 3) ? `קומה ${labelText}` : labelText;

    return {...image, floorLabel};
  });
  const imagesGroupedByFloor = _.groupBy(imagesWithNamedFloor, 'floorLabel');
  const floorLabels = Object.keys(imagesGroupedByFloor);

  return floorLabels.map(mapFloors(imagesGroupedByFloor))
    .flat()
    .map((row, index) => {
      return (index === 0)
        ? (
          <div>
            <FloorsHeader header={header}/>
            <div>{row}</div>
          </div>
        )
        : row;
    });
};

import React, {useState, useEffect} from 'react';
import * as _ from 'lodash';
import PageContainer from '../PageContainer';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import * as contentShumaType1and2 from './content';
import * as contentShumaType3 from './contentShumaType3';
import {pdfPreviewConfig} from '../../constants/pdfPreviewConfig';
import {CommentsBlock} from './content/Comments';
import {parseStyleValue} from '../../utils/parse';
import styles from './styles.module.scss';

let i = 1000;

const PagesStack = () => {
  const context = useCompleteInspectionContext();

  const getContentAndRefs = context => {
    if (context.shumaTypeID === 3 ||  context.shumaTypeID === 4) {
      return contentShumaType3.getContentAndRefs(context);
    } else {
      return contentShumaType1and2.getContentAndRefs(context);
    }
  }

  const {
    content,
    refs,
    commentsContent,
    commentRefs
  } = getContentAndRefs(context);

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const paginatedPages = paginate(content, _.cloneDeep(refs));
    setPages(paginatedPages);
  }, refs.map(ref => Boolean(ref.current)));

  const paginate = (content, refs) => {
    let pages = [];
    let currentPageHeight = 0;
    let currentElementNoteNumberIds = [];
    let currentPageNoteNumberIds = [];

    refs.forEach((ref, index) => {
      if (Boolean(ref)) {
        const comments = ref.querySelectorAll('[data-label="noteNumber"]');
        let commentsHeight = 0;
        if (Boolean(comments) && comments.length > 0) {
          currentElementNoteNumberIds = [...Array.from(comments).map(note => note.attributes['data-id'].value)];
          currentElementNoteNumberIds.forEach(id => {
            const noteRef = commentRefs[id].current;
            if (Boolean(noteRef)) {
              commentsHeight = commentsHeight + noteRef.offsetHeight;
            }
          });
        }

        const computedStyles = window.getComputedStyle(ref);
        const [marginTop] = parseStyleValue(computedStyles.marginTop);
        const [marginBottom] = parseStyleValue(computedStyles.marginBottom);
        const elementHeight = ref.offsetHeight + marginTop + marginBottom + commentsHeight;
        const updatedCurrentPageHeight = currentPageHeight + elementHeight;
        const currentElement = content[index];

        const restPageHeightElement = ref.querySelectorAll('[data-label="restPageHeightElement"]');

        if (updatedCurrentPageHeight <= pdfPreviewConfig.pageContentHeightPx && restPageHeightElement.length === 0) {
          pages = (pages.length > 0)
            ? updateLastPage(currentElement, pages)
            : startNewPage(currentElement, pages);
          currentPageHeight = updatedCurrentPageHeight;
          currentPageNoteNumberIds = [...currentPageNoteNumberIds, ...currentElementNoteNumberIds];
        } else {
          pages = updateComments(currentPageNoteNumberIds, pages);
          pages = startNewPage(currentElement, pages);
          currentPageHeight = elementHeight;
          currentPageNoteNumberIds = [...currentElementNoteNumberIds];
        }
        currentElementNoteNumberIds = []
      }
    });
    pages = updateComments(currentPageNoteNumberIds, pages);

    return pages;
  };

  const updateLastPage = (element, pages) => {
    const pagesWithoutLastOne = _.slice(pages, 0, pages.length - 1);
    const lastPage = _.last(pages);

    return [...pagesWithoutLastOne, [...lastPage, element]];
  };

  const updateComments = (currentPageNoteNumberIds, pages) => {
    const pagesWithoutLastOne = _.slice(pages, 0, pages.length - 1);
    const lastPage = _.last(pages);
    let updatedPages = [...pages];

    if (Boolean(lastPage)) {
      const commentsExistOnThisPage = (currentPageNoteNumberIds.length > 0);
      if (commentsExistOnThisPage) {
        updatedPages = [
          ...pagesWithoutLastOne,
          [
            ...lastPage,
            <CommentsBlock key={i++}>
              {currentPageNoteNumberIds.map((id) => {
                return commentsContent[id];
              })}
            </CommentsBlock>
          ]
        ];
      }
    }
    return updatedPages;
  };

  const startNewPage = (element, pages) => {
    let updatedPages = _.slice(pages, 0, pages.length);
    return [...updatedPages, [element]];
  };

  const mapPages = pages => {
    return pages.map((page, index) => {
      return (
        <PageContainer data-testid={`pages-${index}`} key={index}>
          {page}
          {(context.shumaTypeID === 3 || context.shumaTypeID === 4) && (
            <div className={styles.pageNumber}>{`עמוד ${parseInt(index) + 1}`}</div>
          )}
        </PageContainer>
      );
    });
  };

  return (
    <div className={'pt-3'}>
      <PageContainer
        className={styles.invisible}
        data-testid="universal-page"
        key={9999}
      >
        {content}
        {Object.values(commentsContent)}
      </PageContainer>
      {mapPages(pages)}
    </div>
  );
};

export default PagesStack;

import React from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {v4 as guid} from 'uuid';
import styles from './styles.module.scss';
import classNames from 'classnames';

const Tabs = ({items, containerClassnames = ''}) => {
  const id = guid();
  const defaultTab = (Boolean(items)) ? items[0].key : '';

  const renderTabs = items => {
    return items.map(item => {
      const {key, title, icon, disabled} = item;
      return (
        <Nav.Item key={key}>
          <Nav.Link
            eventKey={key}
            href="#"
            className={styles.tab}
            disabled={disabled}
          >
            {icon && (
              <span className="navi-icon pr-3">
                {icon}
              </span>
            )}
            <span>{title}</span>
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  const renderContent = items => {
    return items.map(item => {
      const {key, content} = item;
      return (
        <Tab.Pane eventKey={key}>
          {content}
        </Tab.Pane>
      );
    });
  };

  return (
    <Tab.Container
      id={id}
      defaultActiveKey={defaultTab}
    >
      <Nav variant="tabs" className={containerClassnames}>
        {renderTabs(items)}
      </Nav>
      <Tab.Content className={classNames('flex-grow-1', styles.content)}>
        {renderContent(items)}
      </Tab.Content>
    </Tab.Container>
  );
};

Tabs.propTypes = {
  items: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.node,
    icon: PropTypes.node,
    content: PropTypes.node
  }),
  containerClassnames: PropTypes.string
};

export default Tabs;

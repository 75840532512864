export const columnNamesOrders = [
  '#',
  'מספר הזמנה',
  'תאריך פתיחה',
  'לקוחות',
  "מספר דוחות",
  "תאריך יעד",
  "סטטוס",
  "שם מלא",
  "נייד",
  "תפקיד",
  "ארגון",
  "מייל",
  "כתובת",
  "שם עו\"ד",
]

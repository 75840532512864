import React, {useEffect, useState} from 'react';
import mime from 'mime-types';
import {getInspectionData} from '../../actions/getInspectionData';
import {getFile} from '../../../_common/actions/getFile';
import Spinner from '../../../_common/components/Spinner';
import CompleteInspectionPagesView from './CompleteInspectionPages-view';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {convertPdfDocumentToPngArray} from '../../../../../utils/covertPDFtoPNG';

const CompleteInspectionPages = ({cellContent}) => {
  const [loading, setLoading] = useState(true);
  const {
    setData,
    setFiles,
    setShumaTypeID,
    accessToken,
  } = useCompleteInspectionContext();
  const shumaID = cellContent.shumaID;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await updateContext();
        setLoading(false);
      } catch (e) {
        console.error(e);
        setData(null);
        setFiles(null);
        cellContent.closeModal();
      }
    })();
  }, []);

  const updateContext = async () => {
    setData(null);
    setFiles(null);
    const data = await updateData();
    setData(data);
    setShumaTypeID(data.pdfData.shumaTypeID);
    await updateFiles(data);
  };

  const updateData = async () => {
    return await getInspectionData({
      accessToken,
      shumaID
    });
  };

  const updateFiles = async (data) => {
    const filesFetches = data.files.map(async file => {
      const {shuma_id, file_id, file_extension} = file;
      const body = await getFile({
        shumaId: shuma_id,
        fileID: file_id,
        accessToken
      });
      const mediaType = mime.lookup(file_extension);
      return (mediaType === 'application/pdf')
        ? {
          ...file,
          body,
          convertedPages: await convertPdfDocumentToPngArray(body)
        }
        : {...file, body};
    });
    const filesWithBodies = await Promise.all(filesFetches);
    setFiles(filesWithBodies);
  };

  return !loading
    ? <CompleteInspectionPagesView/>
    : <Spinner/>;
};

export default CompleteInspectionPages;

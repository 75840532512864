import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import moment from 'moment/moment';
import {shallowEqual, useSelector} from 'react-redux';
import {useTasksUIContext} from '../TasksUIContext';
import {useCitiesContext} from '../../../_common/components/CitiesAndStreets';
import FilterTextBox from '../../../_common/components/FilterTextBox';
import FilterDropdown from '../../../_common/components/FilterDropdown';
import FiltersLabel from '../../../_common/components/FiltersLabel';
import {DatePicker} from '../../../_common/components/FieldDatepicker';
import {taskStatuses, taskTypes} from '../../../_common/constants/options';
import {filterOptions} from '../../../../../utils/filterOptions';
import {clearFilterValue, onChangeDDValue, onChangeTextValue} from '../../../../../utils/changeFilterValues';
import {applyFilter} from '../../../../../utils/prepareApplyFilter';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import styles from './styles.module.scss';

export function TasksFilters({users = []}) {
  const tasksUIContext = useTasksUIContext();
  const tasksUIProps = useMemo(() => {
    return {
      queryParams: tasksUIContext.queryParams,
      setQueryParams: tasksUIContext.setQueryParams,
    };
  }, [tasksUIContext]);
  const {cities} = useCitiesContext();
  const {propertyTypes} = useSelector(
    (state) => ({
      propertyTypes: state.decisionTreeOptionsReducer.propertyTypes,
    }),
    shallowEqual
  );

  const intl = useIntl();
  const searchByType = intl.formatMessage({
    id: 'tasks-page-filter-type',
    defaultMessage: 'Type',
  });
  const searchByStatus = intl.formatMessage({
    id: 'tasks-page-filter-status',
    defaultMessage: 'Status',
  });
  const searchByCity = intl.formatMessage({
    id: 'filter-placeholder-city',
    defaultMessage: 'City',
  });
  const searchByPropertyType = intl.formatMessage({
    id: 'tasks-page-filter-propertyType',
    defaultMessage: 'Property type',
  });
  const searchByResponsibleUser = intl.formatMessage({
    id: 'tasks-page-filter-responsibleUser',
    defaultMessage: 'Responsible user',
  });
  const searchByCustomerName = intl.formatMessage({
    id: 'tasks-page-filter-customerName',
    defaultMessage: 'Name',
  });
  const searchByFromDate = intl.formatMessage({
    id: 'filter-placeholder-fromDate',
    defaultMessage: 'From date',
  });
  const searchByToDate = intl.formatMessage({
    id: 'filter-placeholder-toDate',
    defaultMessage: 'To date',
  });

  const qs = new URLSearchParams(window.location.search);
  let taskType = qs.get('taskType') || '';
  let taskStatus = qs.get('taskStatus') || '';
  let cityID = qs.get('cityID') || '';
  let propertyTypeID = qs.get('propertyTypeID') || '';
  let responsibleUserID = qs.get('responsibleUserID') || '';
  let customerName = qs.get('customerName') || '';
  let fromDueDate = qs.get('fromDueDate') || '';
  let toDueDate = qs.get('toDueDate') || '';

  const onChangeFromDueDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('fromDueDate', moment(date).format('YYYY-MM-DD'));
    handleSubmit();
  };
  const onChangeToDueDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('toDueDate', moment(date).format('YYYY-MM-DD'));
    handleSubmit();
  };

  const clearFromDueDate = (setFieldValue, handleSubmit) => {
    setFieldValue('fromDueDate', '');
    handleSubmit();
  };
  const clearToDueDate = (setFieldValue, handleSubmit) => {
    setFieldValue('toDueDate', '');
    handleSubmit();
  };

  const commonFilterStyle = 'col-sm position-relative';

  return (
    <Formik
      initialValues={{
        taskType,
        taskStatus,
        cityID,
        propertyTypeID,
        responsibleUserID,
        customerName,
        toDueDate,
        fromDueDate,
      }}
      onSubmit={(values) => {
        applyFilter(values, tasksUIProps);
      }}
      enableReinitialize={true}
    >
      {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit} className={styles.formFilters}>
          <div className="form-group row d-flex justify-content-between mb-0 ml-8">
            <FiltersLabel/>
            <FilterDropdown
              name="taskType"
              value={filterOptions(values.taskType)(taskTypes)}
              onChangeValue={({value}) => onChangeDDValue('taskType', value)(setFieldValue, handleSubmit)}
              options={taskTypes}
              placeholder={searchByType}
              onClearValue={() => clearFilterValue('taskType')(setFieldValue, handleSubmit)}
              customContainerClassname={commonFilterStyle}
            />
            <FilterDropdown
              name="taskStatus"
              value={filterOptions(values.taskStatus)(taskStatuses)}
              onChangeValue={({value}) => onChangeDDValue('taskStatus', value)(setFieldValue, handleSubmit)}
              options={taskStatuses}
              placeholder={searchByStatus}
              onClearValue={() => clearFilterValue('taskStatus')(setFieldValue, handleSubmit)}
              customContainerClassname={commonFilterStyle}
            />
            <FilterDropdown
              name="cityID"
              value={filterOptions(values.cityID)(cities)}
              onChangeValue={({value}) => onChangeDDValue('cityID', value)(setFieldValue, handleSubmit)}
              options={cities}
              placeholder={searchByCity}
              onClearValue={() => clearFilterValue('cityID')(setFieldValue, handleSubmit)}
              customContainerClassname={commonFilterStyle}
            />
            <FilterDropdown
              name="propertyTypeID"
              value={filterOptions(values.propertyTypeID)(propertyTypes)}
              onChangeValue={({value}) => onChangeDDValue('propertyTypeID', value)(setFieldValue, handleSubmit)}
              options={propertyTypes}
              placeholder={searchByPropertyType}
              onClearValue={() => clearFilterValue('propertyTypeID')(setFieldValue, handleSubmit)}
              customContainerClassname={commonFilterStyle}
            />
            <FilterDropdown
              name="responsibleUserID"
              value={filterOptions(values.responsibleUserID)(users)}
              onChangeValue={({value}) => onChangeDDValue('responsibleUserID', value)(setFieldValue, handleSubmit)}
              options={users}
              placeholder={searchByResponsibleUser}
              onClearValue={() => clearFilterValue('responsibleUserID')(setFieldValue, handleSubmit)}
              customContainerClassname={commonFilterStyle}
            />
            <FilterTextBox
              name={'customerName'}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue('customerName', e)(setFieldValue, handleSubmit)}
              placeholder={searchByCustomerName}
              value={values.customerName}
              onClearValue={() => clearFilterValue('customerName')(setFieldValue, handleSubmit)}
              customContainerClassName={commonFilterStyle}
            />
            <div className={commonFilterStyle}>
              <DatePicker
                className="h-35px pl-4"
                placeholder={searchByFromDate}
                value={fromDueDate}
                onChange={date => onChangeFromDueDate(date, setFieldValue, handleSubmit)}
                maxDate={toDueDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearFromDueDate(setFieldValue, handleSubmit)}
              />
            </div>
            <div className={commonFilterStyle}>
              <DatePicker
                className="h-35px pl-4"
                placeholder={searchByToDate}
                value={toDueDate}
                onChange={date => onChangeToDueDate(date, setFieldValue, handleSubmit)}
                minDate={fromDueDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearToDueDate(setFieldValue, handleSubmit)}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default TasksFilters;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {SectionRef} from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import * as options from '../../../constants/options';
import styles from './styles.module.scss';

const ShumaType1and2Table = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    cityName,
    streetName,
    houseNumber,
    entrance,
    ttHelka,
    surveyArea,
    landLotNum,
    floor,
    houseRegisteredArea,
    sharedParts,
    attached,
    attachedText,
    buildingFloorsCount,
    buildingOnPillars,
    neighborhoodName,
    rightsType,
    other,
    gush,
    helka,
    registeredArea,
    landLotNumShituf,
    houseJointHomeRegistered
  } = context.data.pdfData;
  const {noteNumbers} = context;

  const currentBuildingOnPillars = getSelectedOptionLabel(buildingOnPillars, options.buildingOnPillarsOptions);

  const attachedTextValue = (attached === 1)
    ? Boolean(attachedText) ? getTextChunks(attachedText).map(mapTextChunks) : ''
    : 'ללא הצמדות';
  const buildingOnPillarsValue = ` מעל קומת ${currentBuildingOnPillars} `;
  const addressValue = (Boolean(entrance)) ? `רח' ${streetName} ${houseNumber}, ${cityName}, כניסה ${entrance}` : `רח' ${streetName} ${houseNumber}, ${cityName}`;
  const rightsTypeValue = getSelectedOptionLabel(rightsType, options.rightsTypeOptions);
  const houseJointHomeRegisteredLabel = getSelectedOptionLabel(houseJointHomeRegistered, options.houseJointHomeRegisteredOptions);
  const houseJointHomeRegisteredYesOption = getSelectedOptionLabel(1, options.houseJointHomeRegisteredOptions);
  const rightsTypeNote = (houseJointHomeRegisteredLabel === houseJointHomeRegisteredYesOption)
    ? ' (קיים רישום בית משותף)'
    : '';
  const title = (
    <>
      <span>{context.sectionNumbers.section4}</span>
      <span>. פרטי הנכס</span>
    </>
  );
  const otherValue = Boolean(other) && getTextChunks(other).map(mapTextChunks);

  return (
    <SectionRef title={title} ref={ref}>
      <table className={classnames(styles.simpleTable, styles.w75)}>
        <tbody>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            גוש
          </td>
          <td>{gush}</td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            חלקה
          </td>
          <td>{helka}</td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            שטח החלקה הרשום
            <NoteNumber
              id={'registeredArea'}>{noteNumbers.registeredArea}</NoteNumber>
          </td>
          <td>{registeredArea} מ"ר</td>
        </tr>
        {Boolean(surveyArea) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              שטח חלקה נסקר
              <NoteNumber
                id={'surveyArea'}>{noteNumbers.surveyArea}</NoteNumber>
            </td>
            <td>{surveyArea} מ"ר</td>
          </tr>
        )}
        {Boolean(ttHelka) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              תת חלקה
            </td>
            <td>{ttHelka}</td>
          </tr>
        )}
        {Boolean(landLotNum) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              מספר מגרש לפי תב"ע
            </td>
            <td>{landLotNum}</td>
          </tr>
        )}
        {Boolean(landLotNumShituf) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              מספר מגרש לפי הסכם שיתוף
            </td>
            <td>{landLotNumShituf}</td>
          </tr>
        )}
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            שטח ת"ח רשום
          </td>
          <td>
            <div>דירה בקומה {floor} בשטח {houseRegisteredArea || ''} מ"ר</div>
            <div>{attachedTextValue}</div>
            <div>חלק ברכוש המשותף: {sharedParts}</div>
          </td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            מהות הנכס הנסקר
          </td>
          <td>
            דירת מגורים בקומה {floor} בבניין
            בן {buildingFloorsCount} קומות
            <span>
              {buildingOnPillars !== 2 && buildingOnPillarsValue}
            </span>
          </td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            כתובת
          </td>
          <td>{addressValue}</td>
        </tr>
        {Boolean(neighborhoodName) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              מיקום
            </td>
            <td>{neighborhoodName}</td>
          </tr>
        )}
        <tr className={classnames({
          [styles.lastSectionLine]: !Boolean(other)
        })}>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            זכויות
          </td>
          <td>
            {rightsTypeValue}
            {rightsTypeNote}
          </td>
        </tr>
        {other && (
          <tr className={styles.lastSectionLine}>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              זיהוי אחר
            </td>
            <td>{otherValue}</td>
          </tr>
        )}
        </tbody>
      </table>
    </SectionRef>
  );
});

ShumaType1and2Table.propTypes = {
  className: PropTypes.string,
};

export default ShumaType1and2Table;

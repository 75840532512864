import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';
import {FormattedMessage} from "react-intl";
import {Button} from "react-bootstrap";

const CommonButton = ({
                        variant,
                        icon = '',
                        label,
                        onClick,
                        customButtonClassNames = 'w-140px',
                        disabled,
                        ...rest
                      }) => {

  switch (variant) {
    case 'button':
      return (
        <Button
          className={classnames("font-weight-bold font-size-lg text-dark btn btn-light border-1 border-dark", customButtonClassNames)}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && (
            <img
              src={icon}
              alt="icon"
              className='w-24px h-24px'
            />
          )}
          {label}
        </Button>
      )
    case 'submit':
      return (
        <Button
          className={classnames("font-weight-bold font-size-lg text-dark btn btn-warning border-1", customButtonClassNames)}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && (
            <img
              src={icon}
              alt="icon"
              className='w-24px h-24px mr-4'
            />
          )}
          {label}
        </Button>
      )
    default:
      return (
        <button
          className={classnames(styles.closeButton, customButtonClassNames)}
          onClick={onClick}
          disabled={disabled}
          {...rest}
        >
          {icon && (
            <img
              src={icon}
              alt="select"
              className='w-24px h-24px'
            />
          )}
          {label}
        </button>
      )
  }
};

CommonButton.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func,
  customButtonClassNames: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CommonButton;

const defaultState = {
  reasons: [],
  banks: [],
  propertyTypes: [],
  loanTypes: [],
  loanSubTypes: [],
  shumaMazminIdentities: [],
  shumaTypes: [],
  loading: false,
  error: false,
  errorMessage: null
};

export const actionTypes = {
  getShumaReasons: 'GET_SHUMA_REASONS',
  getBanks: 'GET_BANKS',
  getPropertyTypes: 'GET_PROPERTY_TYPES',
  getLoanTypes: 'GET_LOAN_TYPES',
  getLoanSubTypes: 'GET_LOAN_SUB_TYPES',
  getShumaMazminIdentities: 'GET_SHUMA_MAZMIN_IDENTITIES',
  getShumaTypes: 'GET_SHUMA_TYPES',
  catchError: 'CATCH_ERROR',
};

export const decisionTreeOptionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.getShumaReasons: {
      const {reasons} = action.payload;
      return {
        ...state,
        reasons,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getBanks: {
      const {banks} = action.payload;
      return {
        ...state,
        banks,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getPropertyTypes: {
      const {propertyTypes} = action.payload;
      return {
        ...state,
        propertyTypes,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getLoanTypes: {
      const {loanTypes} = action.payload;
      return {
        ...state,
        loanTypes,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getLoanSubTypes: {
      const {loanSubTypes} = action.payload;
      return {
        ...state,
        loanSubTypes,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getShumaMazminIdentities: {
      const {shumaMazminIdentities} = action.payload;
      return {
        ...state,
        shumaMazminIdentities,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getShumaTypes: {
      const {shumaTypes} = action.payload;
      return {
        ...state,
        shumaTypes,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.catchError: {
      const error = `DECISION_TREE: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
};


export const actions = {
  getShumaReasons: (data) => ({
    type: actionTypes.getShumaReasons,
    payload: data
  }),
  getBanks: (data) => ({
    type: actionTypes.getBanks,
    payload: data
  }),
  getPropertyTypes: (data) => ({
    type: actionTypes.getPropertyTypes,
    payload: data
  }),
  getLoanTypes: (data) => ({
    type: actionTypes.getLoanTypes,
    payload: data
  }),
  getLoanSubTypes: (data) => ({
    type: actionTypes.getLoanSubTypes,
    payload: data
  }),
  getShumaMazminIdentities: (data) => ({
    type: actionTypes.getShumaMazminIdentities,
    payload: data
  }),
  getShumaTypes: (data) => ({
    type: actionTypes.getShumaTypes,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: error
  }),
};

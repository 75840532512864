import React from 'react';
import {FormattedMessage} from 'react-intl';
import FieldFindCustomers from '../../../_common/components/FieldFindCustomers';
import styles from '../styles.module.scss';

export const SectionCustomers = ({formik, fieldNames}) => {
  const {values, setFieldValue} = formik;

  return (
    <div className={`${styles.sectionContainer} mr-6 mt-6`}>
      <div className={styles.formContainer}>
        <h4>
          <FormattedMessage
            id="inspection-details-page-section-customers"
            defaultMessage="Customers"
          />
        </h4>
        <FieldFindCustomers
          name={fieldNames.customers}
          formik={{values, setFieldValue}}
          readOnlyMode={true}
        />
      </div>
    </div>
  );
};

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import styles from './styles.module.scss';

const ShumaType3Line = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyLoanLegalRelation,
        propertyLoanLegalRelationText
      },
    },
  } = useCompleteInspectionContext();

  const selectedOption = getSelectedOptionLabel(propertyLoanLegalRelation, options.propertyLoanLegalRelationOptions);
  const propertyLoanLegalRelationValue = (propertyLoanLegalRelation === 4)
    ? `${selectedOption}, ${propertyLoanLegalRelationText}.`
    : `${selectedOption}.`;

  return (
    <div ref={ref}>
      {Boolean(propertyLoanLegalRelation) && (
        <div className={styles.itemText}>
          <span>הקשר המשפטי בין הלווה לבין הנכס: </span>
          <span>
            {propertyLoanLegalRelationValue}
          </span>
        </div>
      )}
    </div>
  );
});

export default ShumaType3Line;

import React from 'react';
import classnames from 'classnames';
import Media from '../../Media';
import Section from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import styles from './styles.module.scss';

const fileTypeIDScheme = 9;

const SchemeImageWithText = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const scheme = findFileByTypeId(context.files, fileTypeIDScheme);
  const schemeSrc = scheme && getFullDataURI(scheme);
  const schemeMediaType = scheme && getMediaType(scheme);

  return (
    <div ref={ref}>
      {Boolean(scheme) && (
        <>
          <Section
            customClassNames={{
              container: classnames(styles.itemText, styles.bold, styles.noMarginAfter)
            }}
          >
            להלן ייעודים בסביבת הנכס (מתוך ממ"ג עירונית):
          </Section>
          <Media
            src={schemeSrc}
            file={scheme}
            alt="scheme"
            className={classnames(styles.schemePlan, styles.marginAfterPlan)}
            type={schemeMediaType}
          />
        </>
      )}
    </div>
  );
});

export default SchemeImageWithText;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

export const TextItem19 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingAge) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, className)}
        >
          <span className={styles.bold}>גיל הבניין: </span>
          {` ${context.data.pdfData.buildingAge}.`}
        </div>
      )}
    </div>
  );
});

TextItem19.propTypes = {
  className: PropTypes.string
};

export default TextItem19;

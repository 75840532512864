import React from 'react';
import {useIntl} from "react-intl";


const FiltersLabel = ({label, classNames = ''}) => {
  const intl = useIntl();
  const commonLabel = intl.formatMessage({
    id: 'label-filters',
    defaultMessage: 'Search',
  });

  const filtersLabel = label ? label : commonLabel;

  return (
    <h4 className={`d-flex align-items-center mb-0 font-weight-bolder text-nowrap ${classNames}`}>
      {filtersLabel}
    </h4>
  );
};


export default FiltersLabel;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonRemoveRow from '../ButtonRemoveRow';
import FieldInput from '../../../../_common/components/FieldInput';
import FieldSelect from '../../../../_common/components/FieldSelect';
import {DatePicker} from '../../../../_common/components/FieldDatepicker';
import {floatValue, formatStringToNumber} from '../../../../../../utils/format';
import {optionsCardType, optionsDealType} from '../../../constants/options';
import {
  createDocumentGridReceiptSelectStyles,
  createDocumentGridSelectStyles
} from '../../../../_common/selectStyles/customSelectStyles';
import styles from '../styles.module.scss';

const RowCreditCard = ({
                         setFieldValue,
                         fieldsSet,
                         values,
                         removeRow
                       }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={styles.creditCardRowContainer}>
      <div className={styles.rowFieldsContainer}>
        <DatePicker
          className={styles.gridCellDate}
          value={startDate}
          onChange={(date) => {
            setStartDate(date);
            setFieldValue(fieldsSet.date, date);
          }}
          minDate={moment().format('YYYY-MM-DD')}
        />
        <FieldInput
          fieldId={fieldsSet.cardNum}
          customContainerStyles={styles.gridCell}
          onChange={formatStringToNumber}
        />
        <FieldSelect
          fieldId={fieldsSet.cardType}
          customContainerStyles={styles.gridCell}
          customStyles={{...createDocumentGridSelectStyles, ...createDocumentGridReceiptSelectStyles}}
          options={optionsCardType}
          value={values[fieldsSet.cardType]}
        />
        <FieldSelect
          fieldId={fieldsSet.dealType}
          customContainerStyles={styles.gridCell}
          customStyles={{...createDocumentGridSelectStyles, ...createDocumentGridReceiptSelectStyles}}
          options={optionsDealType}
          value={values[fieldsSet.dealType]}
        />
        <FieldInput
          fieldId={fieldsSet.numPayments}
          customContainerStyles={styles.gridCell}
          onChange={formatStringToNumber}
        />
        <FieldInput
          fieldId={fieldsSet.price}
          customContainerStyles={styles.gridCellPrice}
          onChange={floatValue}
        />
      </div>
      <ButtonRemoveRow onClick={removeRow} styles={styles}/>
    </div>
  );
};

RowCreditCard.propTypes = {
  setFieldValue: PropTypes.func,
  fieldsSet: PropTypes.object,
  values: PropTypes.object,
  removeRow: PropTypes.func,
};

export default RowCreditCard;


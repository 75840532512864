export const tooltips = {
  ADD_ROW: 'הוספת שומה קיימת',
  EDIT_ROW: 'עריכת דוח ישן',
  EDIT_CUSTOMER: 'עריכת פרטי לקוח',
  DELETE_CUSTOMER: 'מחיקת לקוח',
  RESTORE_CUSTOMER: 'שחזור לקוח',
  VIEW_SHUMA: 'הצג טופס',
  EDIT_ORDER: 'עריכה',
  EDIT_INSPECTION: 'עריכת פרטים כלליים עבור דוח זה',
  REDIRECT_TO_PLATFORM: 'עריכת הדוח עצמו בפלטפורמה',
  EDIT_PRICE_QUOTE: 'עריכה',
  DELETE_PRICE_QUOTE: 'מחיקה',
  DOWNLOAD_FILE: 'הורדה',
  CREATE_ORDER: 'יצירת הזמנה',
  EDIT_TASK: 'פרטים משימת',
  DELETE_TASK: 'מחיקה משימת',
  COPY_INSPECTION: 'שכפול דוח',
  DOWNLOAD_ALL_FILES: 'הורדת קבצי הדוח',
};

import React from 'react';
import ChartView from '../../../_common/components/ChartView';
import {chartColorsArray} from '../../../_common/styles/chartColors';

export const ChartPie = ({
                           data,
                           labels,
                           header,
                           ...rest
                         }) => {
  const options = {
    labels: labels,
    colors: chartColorsArray,
    legend: {
      width: 200,
      markers: {
        offsetY: '2px',
        offsetX: '3px'
      },
    },
    responsive: [{
      breakpoint: 1400,
      options: {
        legend: {
          position: 'bottom'
        }
      }
    }]
  };
  const type = 'pie';
  const width = '100%';
  const height = '280px';

  return (
    <ChartView
      options={options}
      series={data}
      type={type}
      width={width}
      height={height}
      containerClassNames={'bg-white rounded mx-2 w-50 h-350px'}
      header={header}
      {...rest}
    />
  )
}


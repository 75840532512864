import React from 'react';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {SectionRef} from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {getTableTextChunks, getTextChunks, mapTableTextChunks, mapTextChunks} from '../../../utils/texts';
import * as options from '../../../constants/options';
import styles from './styles.module.scss';

const ShumaType3Table = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    data: {
      pdfData: {
        cityName,
        streetName,
        houseNumber,
        entrance,
        ttHelka,
        surveyArea,
        landLotNum,
        floor,
        houseRegisteredArea,
        sharedParts,
        attached,
        attachedText,
        buildingFloorsCount,
        buildingOnPillars,
        neighborhoodName,
        rightsType,
        other,
        gush,
        helka,
        registeredArea,
        landLotNumShituf,
        houseJointHomeRegistered,
        propertyAreaByRule9,
        propertyAreaByMunicipal,
        houseRoomCount,
        apartmentNum,
        propertyIdentifiedBy
      },
    },
    noteNumbers
  } = context;

  const currentBuildingOnPillars = getSelectedOptionLabel(buildingOnPillars, options.buildingOnPillarsOptions);

  const attachedTextValue = (attached === 1)
    ? Boolean(attachedText) ? getTextChunks(attachedText).map(mapTextChunks) : ''
    : 'ללא הצמדות';
  const buildingOnPillarsValue = ` מעל קומת ${currentBuildingOnPillars}`;
  const addressValue = (Boolean(entrance))
    ? `רח' ${streetName || ''} ${houseNumber || ''}, ${cityName || ''}, כניסה ${entrance || ''}, דירה מס' ${apartmentNum || ''}`
    : `רח' ${streetName || ''} ${houseNumber || ''}, ${cityName || ''}, דירה מס' ${apartmentNum || ''}`;
  const rightsTypeValue = getSelectedOptionLabel(rightsType, options.rightsTypeOptions);
  const houseJointHomeRegisteredLabel = getSelectedOptionLabel(houseJointHomeRegistered, options.houseJointHomeRegisteredOptions);
  const houseJointHomeRegisteredYesOption = getSelectedOptionLabel(1, options.houseJointHomeRegisteredOptions);
  const rightsTypeNote = (houseJointHomeRegisteredLabel === houseJointHomeRegisteredYesOption)
    ? ' (קיים רישום בית משותף)'
    : '';
  const propertyIdentifiedByLabels = (Boolean(propertyIdentifiedBy))
    ? propertyIdentifiedBy.split(',')
      .map(item => item.trim())
      .map(item => parseInt(item, 10))
      .map(item => getSelectedOptionLabel(item, options.propertyIdentifiedByOptions))
      .join(', ')
    : '';
  const title = (
    <>
      <span>{context.sectionNumbers.section4}</span>
      <span>. פרטי הנכס</span>
    </>
  );
  const otherValue = Boolean(other) && getTextChunks(other).map(mapTextChunks);

  const getTableBreakLineText = (value) => {
    let firstLineText;
    let restChunks = [];

    if (Boolean(value)) {
      [firstLineText, ...restChunks] = getTableTextChunks(value);
    }

    return (Boolean(value))
      ? [
        <div>{Boolean(firstLineText) ? ` ${firstLineText}` : ''}</div>,
        ...restChunks
          .map(mapTableTextChunks)
      ]
      : [];
  };

  return (
    <SectionRef title={title} ref={ref}>
      <table className={classnames(styles.simpleTable, styles.w75)}>
        <tbody>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            גוש
          </td>
          <td>{gush}</td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            חלקה
          </td>
          <td>{helka}</td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            שטח החלקה הרשום
            <NoteNumber
              id={'registeredArea'}>{noteNumbers.registeredArea}</NoteNumber>
          </td>
          <td>{registeredArea} מ"ר</td>
        </tr>
        {Boolean(surveyArea) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              שטח חלקה נסקר
              <NoteNumber
                id={'surveyArea'}>{noteNumbers.surveyArea}</NoteNumber>
            </td>
            <td>{surveyArea} מ"ר</td>
          </tr>
        )}
        {Boolean(ttHelka) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              תת חלקה
            </td>
            <td>{ttHelka}</td>
          </tr>
        )}
        {Boolean(landLotNum) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              מספר מגרש לפי תב"ע
            </td>
            <td>{landLotNum}</td>
          </tr>
        )}
        {Boolean(landLotNumShituf) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              מספר מגרש לפי הסכם שיתוף
            </td>
            <td>{landLotNumShituf}</td>
          </tr>
        )}
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            שטח ת"ח רשום
          </td>
          <td>
            <div>דירה בקומה {floor} בשטח {houseRegisteredArea || ''} מ"ר</div>
            <div>{attachedTextValue}</div>
            <div>חלק ברכוש המשותף: {sharedParts}</div>
          </td>
        </tr>
        {(Boolean(propertyAreaByRule9)) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              שטח הנכס לפי תקן 9
            </td>
            <td>
              {getTableBreakLineText(propertyAreaByRule9)}
            </td>
          </tr>
        )}
        {Boolean(propertyAreaByMunicipal) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              שטח הנכס לפי ארנונה
            </td>
            <td>
              {getTableBreakLineText(propertyAreaByMunicipal)}
            </td>
          </tr>
        )}
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            מהות הנכס הנסקר
          </td>
          <td>
            <span>דירת מגורים</span>
            <span>{` ${houseRoomCount} `}</span>
            <span>חדרים בקומה</span>
            <span>{` ${floor} `}</span>
            <span>בבניין בן</span>
            <span>{` ${buildingFloorsCount} `}</span>
            <span>קומות</span>
            <span>
            {buildingOnPillars !== 2 && ` ${buildingOnPillarsValue}`}
          </span>
            <span>.</span>
          </td>
        </tr>
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            כתובת
          </td>
          <td>{addressValue}</td>
        </tr>
        {Boolean(neighborhoodName) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              מיקום
            </td>
            <td>{neighborhoodName}</td>
          </tr>
        )}
        <tr>
          <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
            זכויות
          </td>
          <td>
            {rightsTypeValue}
            {rightsTypeNote}
          </td>
        </tr>
        {Boolean(propertyIdentifiedBy) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              זיהוי הנכס בוצע באמצעות
            </td>
            <td id={'propertyIdentifiedByLabels'}>
              {propertyIdentifiedByLabels}
            </td>
          </tr>
        )}
        {Boolean(other) && (
          <tr>
            <td className={classnames(styles.greenBg, styles.bold, styles.w30)}>
              זיהוי אחר
            </td>
            <td>{otherValue}</td>
          </tr>
        )}
        </tbody>
      </table>
    </SectionRef>
  );
});

export default ShumaType3Table;

import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import InspectionDetailsPage from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const inspectionDetailsPageRoute = (
  <ProtectedRoute
    subject={S.NEW_INSPECTION_DETAILS_PAGE}
    path={P.NEW_INSPECTION_DETAILS}
    component={InspectionDetailsPage}
  />
);

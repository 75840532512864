import React from 'react';
import CellEditPriceQuote
  from "../../PriceQuotesPage/components/PopupCreateEditPriceQuote/CellEditPriceQuote";

export function EditPriceQuoteColumnFormatter(cellContent) {

  return (
    <CellEditPriceQuote cellContent={cellContent}/>
  );
}

import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useTable} from 'react-table';
import * as actions from '../../_redux/actions/InspectionsPageTableActions';
import * as columnFormatters from '../../../_common/column-formatters';
import * as customFormatters from '../column-formatters';
import {useInspectionsPageUIContext} from '../InspectionsPageUIContext';
import useInfiniteScrollControl from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import CommonGrid from '../../../_common/components/CommonGrid';
import styles from './styles.module.scss';

export default function InspectionsPageTable() {
  const intl = useIntl();
  const [tableRows, setTableRows] = useState([]);
  const columns = useMemo(() => [
    {
      accessor: 'firmShumaID',
      Header: intl.formatMessage({
        id: 'inspections-column-firmShumaID', defaultMessage: 'Firm shuma ID'
      })
    },
    {
      accessor: 'userFullName',
      Header: intl.formatMessage({
        id: 'inspections-column-fullName', defaultMessage: 'Full name'
      })
    },
    {
      accessor: 'createDateAndStatus',
      Header: intl.formatMessage({
        id: 'inspections-column-create-date-and-status', defaultMessage: 'Create date and status'
      })
    },
    {
      accessor: 'shumaDueDate',
      Header: intl.formatMessage({
        id: 'inspections-column-due-date', defaultMessage: 'Due date'
      })
    },
    {
      accessor: 'address',
      Header: intl.formatMessage({
        id: 'inspections-column-address', defaultMessage: 'Address'
      })
    },
    {
      accessor: 'gushHelka',
      Header: intl.formatMessage({
        id: 'inspections-column-gush-helka', defaultMessage: 'Gush/helka'
      })
    },
    {
      accessor: 'shumaTypeAndOrigin',
      Header: intl.formatMessage({
        id: 'inspections-column-shuma-type-and-origin',
        defaultMessage: 'Type and Origin'
      },
    {
      p: (...chunks) => <p>{chunks}</p>,
      br: <br/>
    })
    },
    {
      accessor: 'propertyType',
      Header: intl.formatMessage({
        id: 'inspections-column-property-type', defaultMessage: 'Property Type'
      })
    },
    {
      accessor: 'estimatePriceFinal',
      Header: intl.formatMessage({
        id: 'inspections-column-estimate-price', defaultMessage: 'Estimate price'
      })
    },
    {
      accessor: 'customers',
      Header: intl.formatMessage({
        id: 'inspections-column-customers', defaultMessage: 'Customers'
      })
    },
    {
      accessor: 'actions',
      Header: intl.formatMessage({
        id: 'inspections-column-actions', defaultMessage: 'Actions'
      })
    },
  ], []);

  const data = useMemo(() => [
    ...tableRows.map((item) => {
      return {
        firmShumaID: columnFormatters.BoldColumnFormatter(item.firmShumaID),
        userFullName: columnFormatters.BoldColumnFormatter([item.userFirstName, item.userLastName].filter(Boolean).join(' ')),
        createDateAndStatus: customFormatters.CreateAndStatusFormatter(item.createTime, item.shumaStatusTypeID, item.shumaStatusTypeName),
        shumaDueDate: columnFormatters.DateColumnFormatter(item.shumaDueDate),
        address: customFormatters.AddressFormatter(item.cityName, item.streetName, item.houseNumber),
        gushHelka: customFormatters.GushAndHelkaFormatter(item.gush, item.helka),
        shumaTypeAndOrigin: customFormatters.TypeAndOriginFormatter(item.shumaTypeName, item.originTypeName),
        propertyType: columnFormatters.BoldColumnFormatter(item.propertyTypeName),
        estimatePriceFinal: columnFormatters.PriceColumnFormatter(item.estimatePriceFinal),
        customers: columnFormatters.SeveralItemsColumnFormatter(item.customers),
        actions: customFormatters.ActionsColumnFormatter(item),
      };
    })
  ], [tableRows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'inspectionsPageTableReducer',
    UIContext: useInspectionsPageUIContext(),
    getNewEntities: actions.getInspections,
    getNextEntities: actions.getNextInspections,
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([]);
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-50px d-flex justify-content-center'}>
        <span className="spinner spinner-md spinner-warning"/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  const gridProps = {
    infiniteScrollProps,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows
  };

  return (
    <CommonGrid
      customHeaderStyles={styles.customHeader}
      customBodyStyles={styles.customBody}
      {...gridProps}
    />
  );
}

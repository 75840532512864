import React from 'react';
import moment from "moment";
import CommonButton from "../../../_common/components/CommonButton";
import {useOrderDetailsUIGlobalContext} from "../OrderDetailsUIGlobalContext";
import {useOrderDetailsUIFilesContext} from '../OrderDetailsUIFilesContext';
import styles from '../styles.module.scss';


export function FileItem({
                           fileID,
                           uploadTime,
                           fileName,
                           fileExtension,
                           orderFileTypeID
                         }) {
  const {textContent} = useOrderDetailsUIGlobalContext();
  const {handleDownloadFile, handleDeleteFile} = useOrderDetailsUIFilesContext();
  const {
    tabOrderDocumentsItemFileTypeIDGeneral,
    tabOrderDocumentsItemFileTypeIDFinancial,
  } = textContent;
  const getFileTypeIDLabel = (orderFileTypeID) => {
    switch (orderFileTypeID) {
      case (1):
        return tabOrderDocumentsItemFileTypeIDGeneral
      case (2):
        return tabOrderDocumentsItemFileTypeIDFinancial
      default:
        return null
    }
  };

  return (
    <div
      key={fileID}
      className={`mt-2 ${styles.filesList}`}
    >
      <div className="font-weight-bolder font-size-m">
        {moment(uploadTime).format('DD-MM-YYYY')}
      </div>
      <div dir={'ltr'} className="font-weight-bolder font-size-m">
        {fileName}
      </div>
      <div className="font-weight-bolder font-size-m">
        {fileExtension}
      </div>
      <div className="font-weight-bolder font-size-m">
        {getFileTypeIDLabel(orderFileTypeID)}
      </div>
      <div className="d-flex px-1 justify-content-center">
        <CommonButton
          customButtonClassNames={'btn btn-sm btn-warning text-dark font-weight-bolder d-flex align-items-center'}
          label={
            <img
              src={'../../../media/common/Delete.svg'}
              alt="delete"
              className='w-20px h-20px'
            />
          }
          onClick={() => {
            handleDeleteFile(fileID, fileName)
          }}
        />
      </div>
      <div className="d-flex px-1 justify-content-center">
        <CommonButton
          customButtonClassNames={'btn btn-sm btn-warning text-dark font-weight-bolder d-flex align-items-center'}
          label={
            <img
              src={'../../../media/common/Download.svg'}
              alt="delete"
              className='w-20px h-20px'
            />
          }
          onClick={() => handleDownloadFile(fileName)}
        />
      </div>
    </div>
  );
}

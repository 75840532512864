import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';

const Section6Text72 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {floor, buildingOnPillars, apartmentNum} = context.data.pdfData;
  const currentBuildingOnPillars = getSelectedOptionLabel(buildingOnPillars, options.buildingOnPillarsOptions);
  const buildingOnPillarsValue = ` מעל קומת ${currentBuildingOnPillars}`;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>מיקום: </span>
      <span>קומה</span>
      <span>{` ${floor}`}</span>
      {buildingOnPillars !== 2 && <span>{` ${buildingOnPillarsValue}`}</span>}
      <span>, </span>
      <span>ספרור דירתי- דירה מס' </span>
      <span>{` ${apartmentNum}.`}</span>
    </div>
  );
});

export default Section6Text72;

import React from 'react';
import {Button} from "react-bootstrap";
import {tooltips as TTS} from '../../../_common/constants/tooltips';
import {can} from '../../../_common/components/Permissions';
import {subjects as S, permissionTypes as T} from '../../../_common/constants/permissionSubjects';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";


export function CreateOrderColumnFormatter(cellContent, row, {history}) {
  const {priceQuoteID, orderID, customers} = row;
  const handleCreateOrder = () => {
    history.push(`/order-details/-1`, [priceQuoteID, customers])
  };

  return (
    <div>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={TTS.CREATE_ORDER}
        disabled={!can(T.FULL, S.PRICE_QUOTES_PAGE) || orderID}
        onClick={handleCreateOrder}
      >
        <img
          src={toAbsoluteUrl('media/common/Create-document.svg')}
          alt="create-document"
        />
      </Button>
    </div>
  );
}

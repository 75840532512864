import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import moment from "moment";
import {useSimpleDateRangeContext} from "../../../SimpleDateRangeContext";
import {applyFilter} from "../../../../../../utils/prepareApplyFilter";
import FieldRadiobutton from "../../../../_common/components/FieldRadiobutton";
import FiltersLabel from "../../../../_common/components/FiltersLabel";
import {DatePicker} from "../../../../_common/components/FieldDatepicker";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import styles from "./styles.module.scss";

export function SimpleDateRangeFilters({}) {
  const intl = useIntl();
  const searchByDateRange = intl.formatMessage({
    id: 'dashboard-page-simpleDateRange-filters-label',
    defaultMessage: 'Time period: ',
  });
  const searchByDate = intl.formatMessage({
    id: 'dashboard-page-simpleDateRange-dates-filters-label',
    defaultMessage: 'Dates period:',
  });
  const dateRangeCurrentYear = intl.formatMessage({
    id: 'dashboard-page-simpleDateRange-currentYear',
    defaultMessage: 'Current year',
  });
  const dateRangeLast6Months = intl.formatMessage({
    id: 'dashboard-page-simpleDateRange-last6Months',
    defaultMessage: 'Last 6 months',
  });
  const dateRangeLast12Months = intl.formatMessage({
    id: 'dashboard-page-simpleDateRange-last12Months',
    defaultMessage: 'Last 12 months',
  });
  const dateRangeFreeDates = intl.formatMessage({
    id: 'dashboard-page-simpleDateRange-freeDates',
    defaultMessage: 'Free dates',
  });
  const searchByFromDate = intl.formatMessage({
    id: 'filter-placeholder-fromDate',
    defaultMessage: 'From date',
  });
  const searchByToDate = intl.formatMessage({
    id: 'filter-placeholder-toDate',
    defaultMessage: 'To date',
  });

  const simpleDateRangeContext = useSimpleDateRangeContext();
  const simpleDateRangeProps = useMemo(() => {
    return {
      queryParams: simpleDateRangeContext.simpleQueryParams,
      setQueryParams: simpleDateRangeContext.setSimpleQueryParams,
    };
  }, [simpleDateRangeContext]);
  useEffect(() => simpleDateRangeProps.setQueryParams({simpleDateRange, fromDate, toDate}), []);

  const initialDateRange = window.localStorage.getItem('simpleDateRange') || 'last 6 months';
  const initialFromDate = window.localStorage.getItem('fromDate') || '';
  const initialToDate = window.localStorage.getItem('toDate') || '';
  const [simpleDateRange, setSimpleDateRange] = useState(initialDateRange);
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);

  const getCommonProps = (handleSubmit, setFieldValue) => {

    return {
      fieldId: 'simpleDateRange',
      onChange: ({value}) => {
        handleSubmit();
        setFieldValue('simpleDateRange', value);
        setSimpleDateRange(value);
        window.localStorage.setItem('simpleDateRange', value);
        if (value !== 'free dates') {
          onChangeDate('fromDate', '', setFieldValue, handleSubmit)
          onChangeDate('toDate', '', setFieldValue, handleSubmit)
        }
      },
      customContainerStyles: 'mx-4',
      customClassNames: {container: 'w-120px h-40px'}
    }
  };

  const commonFilterStyles = 'position-relative mx-4';
  const commonDatepickerStyles = 'h-35px w-150px pl-4 border border-warning bg-white';
  const onChangeDate = (fieldID, date, setFieldValue, handleSubmit) => {
    const value = date ? moment(date).format('YYYY-MM-DD') : '';
    setFieldValue(fieldID, value);
    if (fieldID === 'fromDate') {
      setFromDate(value);
      window.localStorage.setItem('fromDate', value);
    } else {
      setToDate(value);
      window.localStorage.setItem('toDate', value);
    }
    handleSubmit();
  };

  return (
    <Formik
      initialValues={{
        simpleDateRange,
        fromDate,
        toDate
      }}
      onSubmit={(values) => {
        applyFilter(values, simpleDateRangeProps);
      }}
      enableReinitialize={false}
    >
      {({handleSubmit, setFieldValue}) => (
        <form onSubmit={handleSubmit}>
          <div className="w-75 d-flex align-items-center mx-auto pt-12 px-12">
            <FiltersLabel label={searchByDateRange}/>
            <FieldRadiobutton
              value={'current year'}
              label={dateRangeCurrentYear}
              {...getCommonProps(handleSubmit, setFieldValue)}
            />
            <FieldRadiobutton
              value={'last 6 months'}
              label={dateRangeLast6Months}
              {...getCommonProps(handleSubmit, setFieldValue)}
            />
            <FieldRadiobutton
              value={'last 12 months'}
              label={dateRangeLast12Months}
              {...getCommonProps(handleSubmit, setFieldValue)}
            />
            <FieldRadiobutton
              value={'free dates'}
              label={dateRangeFreeDates}
              {...getCommonProps(handleSubmit, setFieldValue)}
            />
            {simpleDateRange === 'free dates' && (
              <>
                <FiltersLabel label={searchByDate} classNames={'ml-6'}/>
                <div className={commonFilterStyles}>
                  <DatePicker
                    className={commonDatepickerStyles}
                    placeholder={searchByFromDate}
                    value={fromDate}
                    onChange={date => onChangeDate('fromDate', date, setFieldValue, handleSubmit)}
                    maxDate={toDate}
                  />
                  <img
                    src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                    alt="clear-filter"
                    className={styles.clearFilterIcon}
                    onClick={() => onChangeDate('fromDate', '', setFieldValue, handleSubmit)}
                  />
                </div>
                <div className={commonFilterStyles}>
                  <DatePicker
                    className={commonDatepickerStyles}
                    placeholder={searchByToDate}
                    value={toDate}
                    onChange={date => onChangeDate('toDate', date, setFieldValue, handleSubmit)}
                    minDate={fromDate}
                  />
                  <img
                    src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                    alt="clear-filter"
                    className={styles.clearFilterIcon}
                    onClick={() => onChangeDate('toDate', '', setFieldValue, handleSubmit)}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

export default SimpleDateRangeFilters;

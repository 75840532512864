import React from 'react';
import classnames from 'classnames';
import {SectionRef} from '../../Section';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingPercents, formattingPrice} from '../../../utils/formattingHelper';
import styles from './styles.module.scss';

const ShumaType3FinalPrice = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        finalMarketPrice,
        propertyBuildCostEstimate,
        propertyLandRemainingValueEstimate,
        quickRealizeCost,
        quickRealizeDeduction,
      }
    },
    noteNumbers
  } = useCompleteInspectionContext();

  return (
    <SectionRef ref={ref}>
      <table
        className={classnames(styles.simpleTable, styles.w100, styles.longTable)}
      >
        <thead className={styles.greenBg}>
        <tr>
          <th>רכיב</th>
          <th className={styles.w30}>שווי בש"ח</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>שווי שוק הנכס</td>
          <td>{formattingPrice(finalMarketPrice)}</td>
        </tr>
        <tr>
          <td>אומדן עלות בניה של הנכס (ערך כינון)
            <NoteNumber id={'propertyBuildCostEstimate'}>
              {noteNumbers.propertyBuildCostEstimate}
            </NoteNumber>
          </td>
          <td>{formattingPrice(propertyBuildCostEstimate)}</td>
        </tr>
        <tr>
          <td>אומדן יתרת שווי הקרקע של הנכס
            <NoteNumber id={'priceDelta'}>{noteNumbers.priceDelta}</NoteNumber>
          </td>
          <td>{formattingPrice(propertyLandRemainingValueEstimate)}</td>
        </tr>
        <tr>
          <td>
            <span>שווי למימוש מהיר לצורך מכירה באילוץ (בהפחתה </span>
            {` ${formattingPercents(quickRealizeDeduction || 0)} `}
            <span>)</span>
          </td>
          <td>{formattingPrice(quickRealizeCost)}</td>
        </tr>
        </tbody>
      </table>
    </SectionRef>
  );
});

export default ShumaType3FinalPrice;

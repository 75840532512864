import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useTable} from "react-table";
import {useHistory} from 'react-router-dom';
import * as actions from '../../_redux/actions/OrdersPageTableActions';
import * as columnFormatters from '../../../_common/column-formatters';
import {useOrdersPageUIContext} from '../OrdersPageUIContext';
import useInfiniteScrollControl
  from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import CommonGrid from "../../../_common/components/CommonGrid";
import styles from './styles.module.scss';

export function OrdersPageTable() {
  const intl = useIntl();
  const history = useHistory();
  const [tableRows, setTableRows] = useState([]);
  const columns = useMemo(() => [
    {
      accessor: 'orderNumber',
      Header: intl.formatMessage({
        id: 'orders-page-column-orderNumber', defaultMessage: 'Order number'
      })
    },
    {
      accessor: 'createdDate',
      Header: intl.formatMessage({
        id: 'orders-page-column-createdDate', defaultMessage: 'Created date'
      })
    },
    {
      accessor: 'customers',
      Header: intl.formatMessage({
        id: 'orders-page-column-customers', defaultMessage: 'Customers'
      })
    },
    {
      accessor: 'shumaCount',
      Header: intl.formatMessage({
        id: 'orders-page-column-shumaCount', defaultMessage: 'Shuma count'
      })
    },
    {
      accessor: 'dueDate',
      Header: intl.formatMessage({
        id: 'orders-page-column-dueDate', defaultMessage: 'Due date'
      })
    },
    {
      accessor: 'status',
      Header: intl.formatMessage({
        id: 'orders-page-column-status', defaultMessage: 'Status'
      })
    },
    {
      accessor: 'editButton',
      Header: intl.formatMessage({
        id: 'orders-page-column-edit', defaultMessage: 'Edit'
      })
    },
  ], []);

  const data = tableRows.map((item) => {

    return {
      orderNumber: columnFormatters.BoldColumnFormatter(item.orderNumber),
      createdDate: columnFormatters.DateColumnFormatter(item.createdDate),
      customers: columnFormatters.SeveralItemsNoRepeatColumnFormatter(item.customers),
      shumaCount: columnFormatters.BoldColumnFormatter(item.shumaCount),
      dueDate: columnFormatters.DateColumnFormatter(item.dueDate),
      status: columnFormatters.OrderStatusColumnFormatter(item.status),
      editButton: columnFormatters.EditOrderColumnFormatter(null, item, {history}),
    };
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'ordersPageTableReducer',
    UIContext: useOrdersPageUIContext(),
    getNewEntities: actions.getOrders,
    getNextEntities: actions.getNextOrders,
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([])
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-100px d-flex justify-content-center'}>
        <span className='spinner spinner-md spinner-warning'/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  const gridProps = {
    infiniteScrollProps,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows
  }

  return (
    <CommonGrid
      customHeaderStyles={styles.customHeader}
      customBodyStyles={styles.customBody}
      {...gridProps}
    />
  );
}

export default OrdersPageTable;

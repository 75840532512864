import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const SimpleButtonDropdown = ({
                                title,
                                header = '',
                                type,
                                variant = 'warning',
                                id = "dropdown-basic",
                                drop = 'down',
                                autoClose = true,
                                items = [],
                                customToggleStyles = {},
                                customMenuStyles = {},
                              }) => {
  const [isShown, setIsShown] = useState(false);
  const onToggleHandler = (isOpen, e, metadata) => {
    if (metadata.source && metadata.source !== 'select') {
      setIsShown(isOpen);
    }
  };
  const renderMenuItems = items => {
    return items.map((item, index) => {
      const {itemContent, onClick, customStyles, disabled} = item;
      return (
        <Dropdown.Item
          key={index}
          eventKey={index + 1}
          onClick={onClick}
          className={customStyles}
          disabled={disabled}
        >
          {itemContent}
        </Dropdown.Item>
      );
    });
  };

  switch (type) {
    case ('closeOnButton'):
      return (
        <Dropdown
          show={isShown}
          onToggle={(isOpen, e, metadata) => onToggleHandler(isOpen, e, metadata)}
          drop={drop}
          autoClose={autoClose}
        >
          <Dropdown.Toggle variant={variant} bsPrefix="p-0" id={id} style={customToggleStyles}>
            {title}
          </Dropdown.Toggle>
          <Dropdown.Menu style={customMenuStyles}>
            {header}
            {renderMenuItems(items)}
          </Dropdown.Menu>
        </Dropdown>
      )
    default:
      return (
        <Dropdown
          drop={drop}
          autoClose={autoClose}
        >
          <Dropdown.Toggle variant={variant} bsPrefix="p-0" id={id} style={customToggleStyles}>
            {title}
          </Dropdown.Toggle>
          <Dropdown.Menu style={customMenuStyles}>
            {header}
            {renderMenuItems(items)}
          </Dropdown.Menu>
        </Dropdown>
      );
  }
};

SimpleButtonDropdown.propTypes = {
  title: PropTypes.node,
  header: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
  drop: PropTypes.string,
  autoClose: PropTypes.bool,
  items: PropTypes.array,
};

export default SimpleButtonDropdown;

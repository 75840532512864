import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Modal from "react-bootstrap/Modal";
import CommonButton from "../../../_common/components/CommonButton";
import styles from './styles.module.scss';


const PopupApproveDecline = ({
                               message,
                               handleApprove,
                               disabledButtonCondition,
                             }) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  return (
    <>
      <CommonButton
        label={
          <FormattedMessage
            id="customer-details-popup-create-document-button-label-create-document"
            defaultMessage="Create document"
          />
        }
        customButtonClassNames={'btn btn-warning font-weight-bold text-dark text-nowrap font-size-lg'}
        onClick={handleShowModal}
        disabled={disabledButtonCondition}
      />
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <div className={styles.popupContainer}>
            <p className={styles.message}>
              {message}
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <CommonButton
              label={
                <FormattedMessage
                  id="button-decline"
                  defaultMessage="Decline"
                />
              }
              onClick={handleCloseModal}
            />
            <CommonButton
              label={
                <FormattedMessage
                  id="button-approve"
                  defaultMessage="Approve"
                />
              }
              customButtonClassNames={'w-100px btn btn-sm btn-warning d-flex align-items-center text-nowrap font-size-lg text-dark'}
              onClick={() => {
                handleApprove();
                handleCloseModal();
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PopupApproveDecline.propTypes = {
  message: PropTypes.string,
  disabledButtonCondition: PropTypes.bool,
  handleCreateDocument: PropTypes.func,
}

export default PopupApproveDecline;

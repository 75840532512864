import React, {createContext, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {FormikProvider, useFormik} from "formik";
import {
  addEditPriceQuote,
  getPriceQuoteDetails,
  uploadPriceQuoteFile,
  deletePriceQuoteFile,
  downloadPriceQuoteFile
} from "../../_redux/actions/PopupCreateEditPriceQuoteActions";
import {priceQuoteStatus} from "../../../_common/constants/options";
import {filterOptions} from "../../../_common/utils/options";

const PriceQuoteEditUIGlobalContext = createContext('');

export function usePriceQuoteEditUIGlobalContext() {
  return useContext(PriceQuoteEditUIGlobalContext);
}

export function PriceQuoteEditUIGlobalProvider({children, closeModal, id}) {
  const [data, setData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const dispatch = useDispatch();

  const getData = async () => {
    setLoadingData(true)
    try {
      const details = await getPriceQuoteDetails(id)
      setData(details.priceQuote);
      setLoadingData(false)
    } catch (e) {
      console.error(e);
      setData(null);
      setLoadingData(false)
    }
  };

  const editPriceQuote = () => {
    dispatch(addEditPriceQuote({
      priceQuoteID: formik.values.priceQuoteID,
      status: formik.values.status,
      price: formik.values.price,
      customers: formik.values.customers,
      createMode: false
    }))
  }

  const initialValues = {
    inputValue: '',
    customers: data ? data.customers : [],
    priceQuoteID: id,
    status: (data && data.priceQuoteStatusID)
      ? filterOptions(data.priceQuoteStatusID)(priceQuoteStatus)[0]
      : priceQuoteStatus[0],
    price: data ? data.price : '',
    fileName: data ? data.fileName : ''
  };

  const validate = (values) => {
    let errors = {};
    const {
      status,
      price,
      customers,
    } = values;

    if (!status) errors[`status`] = 'Required field!';
    if (!price) errors[`price`] = 'Required field!';
    if (customers.length < 1) errors[`customers`] = 'Must be at least one customer!';

    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    validate,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const uploadFile = (e) => {
    const fileToSave = e.target.files[0];
    const fullFileName = e.target.files[0].name;

    setUploadingFile(true)
    dispatch(uploadPriceQuoteFile(id, fileToSave, fullFileName))
      .then(() => getData(id))
      .then(() => {
        document.body.click()
      })
      .finally(() => setTimeout(() => {
        setUploadingFile(false)
      }, 1000))
  };

  const handleDeleteFile = (fileName) => {
    dispatch(deletePriceQuoteFile(id, fileName))
      .then(() => getData(id))
  };

  const handleDownloadFile = (fileName) => {
    dispatch(downloadPriceQuoteFile(id, fileName))
  };


  const value = {
    id,
    formik,
    closeModal,
    getData,
    editPriceQuote,
    loadingData,
    uploadFile,
    uploadingFile,
    handleDeleteFile,
    handleDownloadFile
  };

  return (
    <PriceQuoteEditUIGlobalContext.Provider value={value}>
      <FormikProvider value={formik}>
        {children}
      </FormikProvider>
    </PriceQuoteEditUIGlobalContext.Provider>
  );
}

PriceQuoteEditUIGlobalProvider.propTypes = {
  children: PropTypes.node,
};

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MultipleTooltip from "../MultipleTooltip";
import cross from './assets/Cross.svg';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const StyledFieldEmailChips = ({
                                        fieldId,
                                        type = 'text',
                                        label = null,
                                        customContainerStyles = null,
                                        customLabelStyles = null,
                                        customInputStyles = null,
                                        setFieldValue,
                                        errorLabels,
                                        customerEmails,
                                        ...rest
                                      }) => {
  const [items, setItems] = useState([...customerEmails]);
  const [value, setValue] = useState('');
  const [error, setError] = useState([]);

  const isInList = (email) => {
    return items.includes(email);
  }

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  const isValid = (email, items) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} ${errorLabels.alreadyAdded}`;
    }

    if (!isEmail(email)) {
      error = `${email} ${errorLabels.notValidEmail}`;
    }

    if (items.length > 4) {
      error = `${errorLabels.noMoreEmailsRequired}`
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  }

  const handleKeyDown = e => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      let trimmedValue = value.trim();

      if (trimmedValue && isValid(value, items)) {
        setItems([...items, trimmedValue]);
        setValue('');
        setFieldValue(fieldId, [...items, trimmedValue]);
      }
    }
  };
  const handleBlur = e => {
    e.preventDefault();
    let trimmedValue = value.trim();
    if (trimmedValue && isValid(value, items)) {
      setItems([...items, trimmedValue]);
      setValue('');
      setFieldValue(fieldId, [...items, trimmedValue]);
    }
  };

  const handleChange = e => {
    setValue(e.target.value);
    setError(null);
  };

  const handleDelete = item => {
    setItems(items.filter(i => i !== item))
    setFieldValue(fieldId, items.filter(i => i !== item));
  };

  const handlePaste = e => {
    e.preventDefault();

    let paste = e.clipboardData.getData("text");
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter(email => !isInList(email));
      setItems([...items, ...toBeAdded]);
    }
  };

  return (
    <div className={styles.styledFieldEmailChipsContainer}>
      <label className={classnames(styles.inputContainer, customContainerStyles)}>
        <div className={classnames(styles.labelContainer, customLabelStyles)}>
          {label}
          <MultipleTooltip
            id={'emails'}
            label={'להוספת כתובות אימייל, הזינו את הכתובת וליחצו אנטר או טאב'}
          />
        </div>
        <input
          className={`form-control form-control-lg form-control-solid text-left ${customInputStyles}`}
          id={fieldId}
          type={type}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          onBlur={handleBlur}
          value={value}
          dir={'ltr'}
          {...rest}
        />
      </label>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.tagItemContainer}>
        {items.map(item => (
          <div className={styles.tagItem} key={item}>
            <div dir={'ltr'}>{item}</div>
            <div
              className={styles.removeButton}
              onClick={() => handleDelete(item)}
            >
              <img className={styles.removeIcon} src={cross} alt="remove"/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

StyledFieldEmailChips.propTypes = {
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel']),
  label: PropTypes.node,
  customContainerStyles: PropTypes.string,
  customLabelStyles: PropTypes.string,
  customInputStyles: PropTypes.string,
  setFieldValue: PropTypes.func,
  errorLabels: PropTypes.object,
  customerEmail: PropTypes.string,
};

export default StyledFieldEmailChips;

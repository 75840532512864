import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const DocProps = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    bankName,
    bankBranchNumber,
    bankBranchName,
    bankClerkFullName,
    bankEmail,
    bankRequestID,
    bankBuildType,
    loanSubTypeName,
    propertyTypeID
  } = context.data.pdfData;

  const bankBranchNameValue = Boolean(bankBranchName) ? bankBranchName : '';
  const bankBranchNumberValue = Boolean(bankBranchNumber) ? bankBranchNumber : '';
  const branchValue = `${bankBranchNameValue} ${bankBranchNumberValue}`;

  const getPropertyTypeItem = () => {
    switch (propertyTypeID) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return (
          <div className={styles.docPropsItem}>
            <div className={styles.underline}>סוג בניה בהתאם להנחיות משרד השיכון: </div>
            <div>בנייה רוויה</div>
          </div>
        )
      default:
        return null
    }
  };

  return (
    <div ref={ref}>
      <div className={styles.docProps}>
        <div className={styles.rightSideRows}>
          <div className={classnames(styles.bigColumn, styles.docPropsItem, styles.emptyDocPropsItemHeight)}/>
          <div className={classnames(styles.bigColumn, styles.docPropsItem, styles.emptyDocPropsItemHeight)}/>
          <div className={classnames(styles.bold, styles.bigColumn, styles.docPropsItemLarge)}>
            לכבוד,
          </div>
          <div className={classnames(styles.bold, styles.bigColumn, styles.docPropsItemLarge)}>
            {bankName}
          </div>
          {(Boolean(bankBranchName) || Boolean(bankBranchNumber)) && (
            <div className={classnames(styles.bigColumn, styles.docPropsItem)}>
              <span className={styles.bold}>סניף: </span>{branchValue}
            </div>
          )}
          {Boolean(bankClerkFullName) && (
            <div className={classnames(styles.bigColumn, styles.docPropsItem)}>
              <span className={styles.bold}>לידי: </span>{bankClerkFullName}
            </div>
          )}
          {Boolean(bankEmail) && (
            <div className={classnames(styles.bigColumn, styles.docPropsItem)}>
              <span className={styles.bold}>מייל: </span> {bankEmail}
            </div>
          )}
        </div>
        <div>
          {Boolean(bankRequestID) && (
            <div className={styles.docPropsItem}>
              מס' הלוואה/תיק:{bankRequestID}
            </div>
          )}
          {Boolean(loanSubTypeName) && (
            <div className={styles.docPropsItem}>
              סוג הלוואה:  {loanSubTypeName}
            </div>
          )}
          {getPropertyTypeItem()}
          {Boolean(bankBuildType) && (
            <div className={styles.docPropsItem}>
              צפיפות לדונם:  {bankBuildType}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default DocProps;

import React from 'react';
import GreenBox from '../../GreenBox';
import {getGushHelkaTTHelkaRTLString} from '../../../utils/texts';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {rightsTypeOptions} from '../../../constants/options';
import styles from './styles.module.scss';

const ShumaType1GreenBox = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    streetName,
    houseNumber,
    cityName,
    gush, helka, ttHelka,
    rightsType,
    rightsOwnerName,
  } = context.data.pdfData;
  const rightsTypeValue = getSelectedOptionLabel(rightsType, rightsTypeOptions);

  return (
    <GreenBox containerClassName={styles.containerGreenBox} ref={ref}>
      <div className={styles.blockLine}>
        הנדון: דירת מגורים ברח' {streetName} {houseNumber}, {cityName || ''}
      </div>
      <div className={styles.blockLine}>
        גו"ח {getGushHelkaTTHelkaRTLString({gush, helka, ttHelka})}
      </div>
      <div className={styles.blockLine}>
        זכויות {rightsTypeValue} רשומות ע"ש {rightsOwnerName}
      </div>
    </GreenBox>
  );
});

export default ShumaType1GreenBox;

import {connect} from 'react-redux';
import GlobalMessage from './GlobalMessage';
import {hideGlobalMessage} from '../actions/hideGlobalMessage';

const mapStateToProps = ({
                           globalMessage: {showGlobalMessage, message},
                         }) => ({
  showGlobalMessage,
  message,
});

export default connect(
  mapStateToProps,
  {hideGlobalMessage}
)(GlobalMessage);

import React from "react";
import moment from 'moment';

export function DateColumnFormatter(cellContent) {

  return (
    <div className="d-flex justify-content-start">
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
       {cellContent ? moment(cellContent).format('DD-MM-YYYY') : ''}
      </span>
    </div>
  );
}
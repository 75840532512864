import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../../_metronic/layout";
import {logout} from '../../_redux/authActions';
import {hideGlobalMessage} from '../../../_common/components/GlobalMessage/actions/hideGlobalMessage';

class Logout extends Component {
  componentDidMount() {
    Promise.resolve()
      .then(() => this.props.hideGlobalMessage())
      .finally(() => this.props.logout());
  }

  render() {
    return this.props.hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.accessToken) }),
  {
    logout,
    hideGlobalMessage
  }
)(Logout);

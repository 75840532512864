import React from 'react';
import classnames from 'classnames';
import {convertFileToContent} from '../../FileToContentConverter';
import {landFileTypeIDs, landLabels} from '../../../constants/landLabels';
import {findFileByTypeId} from '../../../utils/files';
import styles from './styles.module.scss';

const FilePageLabel = ({label}) => (
  <div
    className={classnames(styles.listItem, styles.bold, styles.innerPadding, styles.paddingTop)}
  >
    {label}
  </div>
);

export const getStep5MediaFiles = (context) => {
  const mapFilePages = () => {
    return Object.keys(landFileTypeIDs)
      .map((fileName, index) => {
        const file = findFileByTypeId(context.files, landFileTypeIDs[fileName]);
        return Boolean(context.data.pdfData[fileName])
          && Boolean(file)
          && mapPage(file, fileName, landFileTypeIDs[fileName], index);
      })
      .filter(Boolean);
  };

  const getPageLabel = (fileName) => {
    return (fileName === 'landOther')
      ? context.data.pdfData.landOtherText
      : landLabels[fileName];
  };

  const mapPage = (file, fileName, fileTypeID) => {
    const label = getPageLabel(fileName);
    return convertFileToContent(context, fileTypeID, {
      firstPageHeader: <FilePageLabel label={label}/>,
    });
  };

  return mapFilePages().flat();
  /*
  return step5FileTypeIDs.map(fileTypeID => {
    const file = findFileByTypeId(context.files, fileTypeID);
    const label = getPageLabel(fileName);

    return Boolean(file) && convertFileToContent(context, fileTypeID, {
      firstPageHeader: <FilePageLabel label={label}/>
    })
  })*/
};

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {FormikProvider} from "formik";
import {useCustomerDetailsUIContext} from "../CustomerDetailsUIContext";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import StyledFieldEmailChips from "../../../_common/components/StyledFieldEmailChips/StyledFieldEmailChips";
import {StyledFieldInput} from "../../../_common/components/FieldInput";
import FieldInput from "../../../_common/components/FieldInput";
import {StyledFieldTextareaAutosize} from "../../../_common/components/FieldTextareaAutosize";
import CommonButton from "../../../_common/components/CommonButton";
import GridEditableRows from "../GridEditableRows";
import Tabs from '../../../_common/components/Tabs';
import PopupPreviewDocument from "../PopupPreviewDocument";
import PopupApproveDecline from "../PopupApproveDecline";
import ButtonTextSuggestions from "../../../_common/components/ButtonTextSuggestions";
import {getDocumentTypeData} from '../../utils/getDocumentTypeData';
import {divideThousands, floatValue} from '../../../../../utils/format';
import {getTotalCommonDocsAmounts, getTotalPaid} from '../../utils/countTotalAmounts';
import {optionsDiscountType} from '../../constants/options';
import {documentTypes as T} from "../../constants/documentTypes";
import {gridTypes as G} from "../../constants/gridTypes";
import {popupCreateDocument} from "../../../_common/selectStyles/customSelectStyles";
import
{
  descriptionTextSuggestions,
  remarksTextSuggestions,
  footerTextSuggestions,
} from '../../../_common/constants/textSuggestions';
import Spinner from "../../../_common/components/Spinner";

const PopupCreateDocument = ({
                               label,
                               documentType,
                               customOpenButton = null,
                               documentId = '',
                               withCopiedData = false
                             }) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    resetForm();
    setShow(false);
  };
  const handleShowModal = (documentId) => {
    if (withCopiedData) {
      getCopiedDocumentData(documentId);
    }
    setShow(true);
  };

  const {
    generalDetails,
    handleCreateDocument,
    formikCreateDocument,
    handlePreviewDocument,
    previewDocumentLink,
    getCopiedDocumentData,
    getCopiedDocumentDataCondition,
    copiedDocumentData,
    textContent,
    documentsData,
    setCreateFromCopiedDataMark,
    loadingCopiedDocumentData
  } = useCustomerDetailsUIContext();

  const errorLabels = {
    alreadyAdded: textContent().alreadyAdded,
    notValidEmail: textContent().notValidEmail,
    noMoreEmailsRequired: textContent().noMoreEmailsRequired
  }
  const {values, setFieldValue, handleChange, resetForm, isValid, dirty, validateForm} = formikCreateDocument;
  const {email} = generalDetails;
  const getCopiedEmails = () => {
    const filteredInitDocData = documentsData.filter(item => item.id === documentId);

    return filteredInitDocData[0].client.emails;
  };
  const initialEmailsValue = withCopiedData
    ? getCopiedEmails()
    : [email];
  const createdFromDocType = copiedDocumentData.type;
  const linkedDocumentsIds = copiedDocumentData.id;
  const publicDocId = copiedDocumentData.number;

  const customLabelStyles = 'pl-6';
  const markNecessaryClassName = 'text-danger position-absolute top-0 start-0';

  useEffect(() => {
    if (getCopiedDocumentDataCondition()) {
      setTimeout(() => {
        validateForm()
      }, 1000)
    }
  }, [values]);

  const disabledButtonCondition = getCopiedDocumentDataCondition()
    ? !isValid
    : !(isValid && dirty);

  const amountSection = (containerClassnames) => {
    return (
      <div className={containerClassnames}>
        <StyledFieldInput
          dir={'ltr'}
          fieldId={'totalWithoutVAT'}
          label={
            <FormattedMessage
              id="customer-details-popup-create-document-label-totalWithoutVAT"
              defaultMessage="totalWithoutVAT"
            />
          }
          customLabelStyles={customLabelStyles}
          customInputStyles={'text-left bg-secondary'}
          customContainerStyles={'mb-2 w-25'}
          value={divideThousands(getTotalCommonDocsAmounts(values, getCopiedDocumentDataCondition()).totalWithoutVAT)}
          disabled={true}
        />
        <div className='d-flex justify-content-between'>
          <FormikProvider value={formikCreateDocument}>
            <FieldInput
              fieldId={'amount'}
              label={
                <FormattedMessage
                  id="customer-details-popup-create-document-label-amount"
                  defaultMessage="amount"
                />
              }
              onChange={floatValue}
              customLabelStyles={customLabelStyles}
              customInputStyles={'bg-secondary'}
              customContainerStyles={'mb-2 w-25'}
            />
          </FormikProvider>
          <FormikProvider value={formikCreateDocument}>
            <div className={'mb-2 w-25'}>
              <div className="font-weight-bold text-dark-75 pl-6">
                <FormattedMessage
                  id="customer-details-popup-create-document-label-type"
                  defaultMessage="type"
                />
              </div>
              <Select
                name={'type'}
                value={values.type}
                options={optionsDiscountType}
                styles={popupCreateDocument}
                onChange={value => setFieldValue('type', value)}
              />
            </div>
          </FormikProvider>
          <StyledFieldInput
            dir={'ltr'}
            fieldId={'totalDiscount'}
            label={
              <FormattedMessage
                id="customer-details-popup-create-document-label-totalDiscount"
                defaultMessage="totalDiscount"
              />
            }
            customLabelStyles={customLabelStyles}
            customInputStyles={'text-left bg-secondary'}
            customContainerStyles={'mb-2 w-25'}
            value={divideThousands(getTotalCommonDocsAmounts(values, getCopiedDocumentDataCondition()).totalDiscount)}
            disabled={true}
          />
        </div>
        <StyledFieldInput
          dir={'ltr'}
          fieldId={'totalVAT'}
          label={
            <FormattedMessage
              id="customer-details-popup-create-document-label-totalVAT"
              defaultMessage="totalVAT"
            />
          }
          customLabelStyles={customLabelStyles}
          customInputStyles={'text-left bg-secondary'}
          customContainerStyles={'mb-2 w-25'}
          value={divideThousands(getTotalCommonDocsAmounts(values, getCopiedDocumentDataCondition()).totalVAT)}
          disabled={true}
        />
        <StyledFieldInput
          dir={'ltr'}
          fieldId={'total'}
          label={
            <FormattedMessage
              id="customer-details-popup-create-document-label-total"
              defaultMessage="total"
            />
          }
          customLabelStyles={customLabelStyles}
          customInputStyles={'text-left bg-secondary'}
          customContainerStyles={'mb-2 w-25'}
          value={divideThousands(getTotalCommonDocsAmounts(values, getCopiedDocumentDataCondition()).total)}
          disabled={true}
        />
      </div>
    )
  };

  const totalPaidSection = (containerClassnames) => {
    return (
      <div className={containerClassnames}>
        <StyledFieldInput
          dir={'ltr'}
          fieldId={'totalPaid'}
          label={
            <FormattedMessage
              id="customer-details-popup-create-document-label-total-receipts"
              defaultMessage="total"
            />
          }
          customLabelStyles={customLabelStyles}
          customContainerStyles={'mb-2 w-200px'}
          customInputStyles={'text-left bg-secondary'}
          value={divideThousands(getTotalPaid(values)) || '0.00'}
          disabled={true}
        />
      </div>
    )
  };

  const footerSection = (containerClassnames) => {

    return (
      <div className={containerClassnames}>
        <StyledFieldTextareaAutosize
          fieldId={'remarks'}
          label={
            <>
              <FormattedMessage
                id="customer-details-popup-create-document-label-remarks"
                defaultMessage="remarks"
              />
              <ButtonTextSuggestions
                fieldName={'remarks'}
                currentFieldValue={values.remarks}
                setFieldValue={setFieldValue}
                textSuggestionsArray={remarksTextSuggestions(documentType)}
                disabled={remarksTextSuggestions(documentType).length === 0}
              />
            </>
          }
          customContainerStyles={'mb-2'}
          customLabelStyles={'pl-6 d-inline-block'}
          onChange={handleChange}
          value={values.remarks}
        />
        <StyledFieldTextareaAutosize
          fieldId={'footer'}
          label={
            <>
              <FormattedMessage
                id="customer-details-popup-create-document-label-footer"
                defaultMessage="footer"
              />
              <ButtonTextSuggestions
                fieldName={'footer'}
                currentFieldValue={values.footer}
                setFieldValue={setFieldValue}
                textSuggestionsArray={footerTextSuggestions(documentType)}
                disabled={footerTextSuggestions(documentType).length === 0}
              />
            </>
          }
          customContainerStyles={'mb-2'}
          customLabelStyles={'pl-6'}
          onChange={handleChange}
          value={values.footer}
        />
        <StyledFieldTextareaAutosize
          fieldId={'emailContent'}
          label={
            <FormattedMessage
              id="customer-details-popup-create-document-label-emailContent"
              defaultMessage="emailContent"
            />
          }
          customContainerStyles={'mb-2'}
          customLabelStyles={'pl-6'}
          onChange={handleChange}
        />
      </div>
    )
  };

  const renderGridsAmounts = (type) => {
    switch (type) {
      case T.PRICE_QUOTE:
      case T.WORK_ORDER:
      case T.INVOICE:
      case T.TRANSACTION_ACCOUNT:
        return (
          <>
            <GridEditableRows
              gridType={G.COMMON_DOCS}
              containerClassnames='mb-2 pb-2 border-bottom'
            />
            <div className='mb-2 pb-2 border-bottom d-flex justify-content-between'>
              {footerSection('w-50 border-right px-8')}
              {amountSection('w-50 px-8 d-flex align-items-end flex-column')}
            </div>
          </>
        )
      case T.RECEIPT:
        return (
          <>
            <Tabs
              containerClassnames={'mt-6 mb-2 mb-2 pb-2 border-bottom'}
              items={[
                {
                  key: 'cheque',
                  title: 'צ\'ק',
                  icon: null,
                  content: <GridEditableRows gridType={G.CHEQUE}/>,
                },
                {
                  key: 'bankTransfer',
                  title: 'העברה בנקאית',
                  icon: null,
                  content: <GridEditableRows gridType={G.BANK_TRANSFER}/>,
                },
                {
                  key: 'creditCard',
                  title: 'אשראי',
                  icon: null,
                  content: <GridEditableRows gridType={G.CREDIT_CARD}/>,
                },
                {
                  key: 'paymentApp',
                  title: 'אפליקציית תשלום',
                  icon: null,
                  content: <GridEditableRows gridType={G.PAYMENT_APP}/>,
                },
                {
                  key: 'payPal',
                  title: 'פייפאל',
                  icon: null,
                  content: <GridEditableRows gridType={G.PAY_PAL}/>,
                },
                {
                  key: 'cash',
                  title: 'מזומן',
                  icon: null,
                  content: <GridEditableRows gridType={G.CASH}/>,
                },
                {
                  key: 'other',
                  title: 'אחר',
                  icon: null,
                  content: <GridEditableRows gridType={G.OTHER}/>,
                },
                {
                  key: 'withholdingTax',
                  title: 'ניכוי במקור',
                  icon: null,
                  content: <GridEditableRows gridType={G.WITHHOLDING_TAX}/>,
                },
              ]}
            />
            <div className='mb-2 pb-2 border-bottom d-flex justify-content-between'>
              {footerSection('w-50 border-right px-8')}
              {totalPaidSection('d-flex justify-content-end pr-8 pl-20')}
            </div>
          </>
        )
      case T.INVOICE_RECEIPT:
        return (
          <>
            <h3
              className={'w-100 d-flex justify-content-center mt-4 mb-0'}>{textContent().subHeaderServicesDetails}</h3>
            <GridEditableRows
              gridType={G.COMMON_DOCS}
              containerClassnames='my-2 py-2'
            />
            {amountSection('w-50 px-8 d-flex align-items-end flex-column float-right')}
            <div>
              <h3
                className={'w-100 d-flex justify-content-center mt-4 mb-0'}>{textContent().subHeaderReceiptsDetails}</h3>
              <Tabs
                containerClassnames={'my-2'}
                items={[
                  {
                    key: 'cheque',
                    title: 'צ\'ק',
                    icon: null,
                    content: <GridEditableRows gridType={G.CHEQUE}/>,
                  },
                  {
                    key: 'bankTransfer',
                    title: 'העברה בנקאית',
                    icon: null,
                    content: <GridEditableRows gridType={G.BANK_TRANSFER}/>,
                  },
                  {
                    key: 'creditCard',
                    title: 'אשראי',
                    icon: null,
                    content: <GridEditableRows gridType={G.CREDIT_CARD}/>,
                  },
                  {
                    key: 'paymentApp',
                    title: 'אפליקציית תשלום',
                    icon: null,
                    content: <GridEditableRows gridType={G.PAYMENT_APP}/>,
                  },
                  {
                    key: 'payPal',
                    title: 'פייפאל',
                    icon: null,
                    content: <GridEditableRows gridType={G.PAY_PAL}/>,
                  },
                  {
                    key: 'cash',
                    title: 'מזומן',
                    icon: null,
                    content: <GridEditableRows gridType={G.CASH}/>,
                  },
                  {
                    key: 'other',
                    title: 'אחר',
                    icon: null,
                    content: <GridEditableRows gridType={G.OTHER}/>,
                  },
                  {
                    key: 'withholdingTax',
                    title: 'ניכוי במקור',
                    icon: null,
                    content: <GridEditableRows gridType={G.WITHHOLDING_TAX}/>,
                  },
                ]}
              />
              <div className='mb-2 py-2 d-flex justify-content-between'>
                {footerSection('w-50 border-right px-8')}
                {totalPaidSection('d-flex justify-content-end pr-8 pl-20')}
              </div>
            </div>
          </>
        )
    }
  };

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
      onMouseOver={e => e.stopPropagation()}
      className={'w-100'}
    >
      <div onClick={() => {
        handleShowModal(documentId)
      }}>
        {customOpenButton ||
        <div className={`d-flex align-items-center px-3 py-3 font-weight-bold text-nowrap font-size-lg`}>
          <div className={`rounded-circle mr-2 w-20px h-20px ${getDocumentTypeData(documentType).backgroundColor}`}/>
          {label}
        </div>}
      </div>
      <Modal show={show} onHide={handleCloseModal} size={'xl'} backdrop={'static'}>
        <Modal.Body>
          {!(loadingCopiedDocumentData && withCopiedData)
            ?
            <div>
              <h2 className='text-center font-weight-bolder mb-2'>{label}</h2>
              {Boolean(linkedDocumentsIds) &&
              documentType === T.RECEIPT &&
              <div className='d-flex justify-content-center align-items-center'>
                <h2 className='text-center font-weight-bolder mr-4'>
                  <FormattedMessage
                    id="customer-details-popup-create-document-label-related-to-invoice"
                    defaultMessage="Related to invoice"
                  />
                </h2>
                <StyledFieldInput
                  fieldId={'linkedDocumentIds'}
                  value={publicDocId}
                  customContainerStyles='w-100px'
                  title={values.linkedDocumentIds}
                  customInputStyles={'text-nowrap text-truncate bg-secondary'}
                  customLabelStyles={customLabelStyles}
                  disabled={true}
                />
              </div>}
              <div className={'mb-2'}>
                <div className='w-50 px-8 mt-8 mb-2'>
                  <StyledFieldEmailChips
                    fieldId={'emails'}
                    customLabelStyles={customLabelStyles}
                    label={
                      <div className='position-relative'>
                        <span className={markNecessaryClassName} style={{translate: '100%'}}>*</span>
                        <FormattedMessage
                          id="customer-details-popup-create-document-label-emails"
                          defaultMessage="Emails"
                        />
                      </div>
                    }
                    errorLabels={errorLabels}
                    customerEmails={initialEmailsValue}
                    setFieldValue={setFieldValue}
                  />
                  <StyledFieldTextareaAutosize
                    fieldId={'description'}
                    label={
                      <>
                        <FormattedMessage
                          id="customer-details-popup-create-document-label-description"
                          defaultMessage="description"
                        />
                        <ButtonTextSuggestions
                          fieldName={'description'}
                          currentFieldValue={values.description}
                          setFieldValue={setFieldValue}
                          textSuggestionsArray={descriptionTextSuggestions(documentType)}
                          disabled={descriptionTextSuggestions(documentType).length === 0}
                        />
                      </>
                    }
                    customLabelStyles={customLabelStyles}
                    customContainerStyles={'mb-2'}
                    onChange={handleChange}
                    value={values.description}
                  />
                </div>
                {renderGridsAmounts(documentType)}
                <div className={'w-400px mt-8 d-flex justify-content-between mx-auto'}>
                  <CommonButton
                    variant={'button'}
                    label={
                      <FormattedMessage
                        id="button-close"
                        defaultMessage="Close"
                      />
                    }
                    onClick={() => {
                      setCreateFromCopiedDataMark(false);
                      handleCloseModal();
                    }}
                    customButtonClassNames={'w-100px'}
                  />
                  <PopupPreviewDocument
                    documentPath={`data:application/pdf;base64,${previewDocumentLink}`}
                    handlePreviewDocument={() => {
                      handlePreviewDocument(
                        documentType,
                        linkedDocumentsIds,
                        createdFromDocType
                      )
                    }}
                    documentType={documentType}
                    allowHandlePreviewDocument={true}
                    disableButton={disabledButtonCondition}
                  />
                  {withCopiedData && documentType === 400 &&
                  (copiedDocumentData.amount < getTotalPaid(values))
                    ?
                    <PopupApproveDecline
                      message={
                        <FormattedMessage
                          id="customer-details-popup-approve-decline-create-receipt"
                          defaultMessage="The amount typed is higher than the invoice total!"
                        />
                      }
                      handleApprove={() => {
                        handleCreateDocument(
                          documentType,
                          linkedDocumentsIds,
                          createdFromDocType
                        );
                        setCreateFromCopiedDataMark(false)
                        handleCloseModal();
                      }}

                      disabledButtonCondition={disabledButtonCondition}
                    />
                    :
                    (
                      <CommonButton
                        label={
                          <FormattedMessage
                            id="customer-details-popup-create-document-button-label-create-document"
                            defaultMessage="Create document"
                          />
                        }
                        customButtonClassNames={'btn btn-warning font-weight-bold text-dark text-nowrap font-size-lg'}
                        onClick={() => {
                          handleCreateDocument(
                            documentType,
                            linkedDocumentsIds,
                            createdFromDocType
                          )
                          setCreateFromCopiedDataMark(false);
                          handleCloseModal();
                        }}
                        disabled={disabledButtonCondition}
                      />
                    )
                  }
                </div>
              </div>
            </div>
            :
            <div className={'w-100 d-flex justify-content-center'}>
              <Spinner/>
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

PopupCreateDocument.propTypes = {
  label: PropTypes.node,
  documentType: PropTypes.number,
  linkedDocuments: PropTypes.string,
  customOpenButton: PropTypes.node,
  documentId: PropTypes.string,
  withCopiedData: PropTypes.bool,
}

export default PopupCreateDocument;

import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {
  buildingLocationOptions,
  buildingOnPillarsOptions,
  houseFinishGradeOptions,
  houseMaintenanceStatusOptions,
  houseMatchingBuildingOptions,
  houseParkingTypeOptions,
  housePhysicalStateOptions,
  houseSecureRoomOptions,
  buildingRoofTypeOptions,
  buildingOuterLayerOptions,
  buildingBuildTypeOptions,
} from '../../../constants/options';
import {getSelectedOptionLabel} from '../../../utils/options';
import {
  checkIfStartsWithComma,
  checkIfTextEndsWithDot,
  combineOptionalText,
  getBreakLinesTextShumaType3,
  getTextChunks,
  joinAirDirections,
  mapTextChunks
} from '../../../utils/texts';
import {propertyTypesGroups as PT} from '../../../constants/propertyTypesGroups';
import styles from './styles.module.scss';


const HeadLine1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        landDescription,
        propertyAge,
        propertyFloorsCount,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding,
        renovationDetailsDesc,
        houseAirDirectionNorth,
        houseAirDirectionSouth,
        houseAirDirectionEast,
        houseAirDirectionWest,
        houseAirDirectionNorthEast,
        houseAirDirectionNorthWest,
        houseAirDirectionSouthEast,
        houseAirDirectionSouthWest
      },
    },
  } = useCompleteInspectionContext();
  const context = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);

  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;
  const showLine3 = !isLot;
  const showLine4 = !isLot;
  const showLine5 = (
    houseAirDirectionNorth ||
    houseAirDirectionSouth ||
    houseAirDirectionEast ||
    houseAirDirectionWest ||
    houseAirDirectionNorthEast ||
    houseAirDirectionNorthWest ||
    houseAirDirectionSouthEast ||
    houseAirDirectionSouthWest
  );
  const showLine6 = Boolean(renovationDetailsDesc);
  const showLine7 = !isLot;
  const showLine8 = !isLot;
  const showLine9 = !isLot;


  return (
    <div ref={ref}>
      <HeadContent/>
      <Line0Content/>
      {!showLine0 && <Line01Content/>}
      {!_.some([showLine0, showLine01]) && <Line02Content/>}
      {!_.some([showLine0, showLine01, showLine02]) && <Line03Content/>}
      {!_.some([showLine0, showLine01, showLine02, showLine03]) && <Line04Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
      ]) && <Line05Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
      ]) && <Line06Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
      ]) && <Line1Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
      ]) && <Line2Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
      ]) && <Line3Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
      ]) && getLine4Content(context)}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
      ]) && <Line5Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
      ]) && <Line6Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
      ]) && <Line7Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
        showLine7,
      ]) && <Line8Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
        showLine7,
        showLine8,
      ]) && <Line9Content/>}
      {!_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
        showLine7,
        showLine8,
        showLine9,
      ]) && <Line10Content/>}
    </div>
  );
});

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section9}. `}</span>
        <span>תיאור הנכס</span>
      </span>
    </div>
  );
};

const Line0Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        landDescription
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {landDescription && PT.LOT.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            תיאור מגרש: 
          </span>
          <span>{landDescription}{checkIfTextEndsWithDot(landDescription)}</span>
        </>
      )}
    </div>
  );
};

const Line01Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyAge
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {propertyAge && PT.GROUND.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            גיל הנכס: 
          </span>
          <span>{propertyAge}{checkIfTextEndsWithDot(propertyAge)}</span>
        </>
      )}
    </div>
  );
};

const Line02Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {propertyFloorsCount && PT.GROUND.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            מספר קומות: 
          </span>
          <span>{propertyFloorsCount}{checkIfTextEndsWithDot(propertyFloorsCount)}</span>
        </>
      )}
    </div>
  );
};

const Line03Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyOnPillars,
        propertyOnPillarsOtherText
      },
    },
  } = useCompleteInspectionContext();
  const propertyOnPillarsText = (propertyOnPillars === 12)
    ? propertyOnPillarsOtherText
    : getSelectedOptionLabel(propertyOnPillars, buildingOnPillarsOptions);

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {propertyOnPillars && PT.GROUND.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            מעל קומת: 
          </span>
          <span>{propertyOnPillarsText}{checkIfTextEndsWithDot(propertyOnPillarsText)}</span>
        </>
      )}
    </div>
  );
};

const Line04Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyRoofType
      },
    },
  } = useCompleteInspectionContext();
  const buildingRoofTypeText = getSelectedOptionLabel(propertyRoofType, buildingRoofTypeOptions);

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {propertyRoofType && PT.GROUND.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            סוג גג: 
          </span>
          <span>{buildingRoofTypeText}{checkIfTextEndsWithDot(buildingRoofTypeText)}</span>
        </>
      )}
    </div>
  );
};

const Line05Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyOuterLayer,
        propertyOuterLayerText
      },
    },
  } = useCompleteInspectionContext();
  const propertyOuterLayerLabel = getSelectedOptionLabel(propertyOuterLayer, buildingOuterLayerOptions);
  const optionalText = (propertyOuterLayer === 6) ? propertyOuterLayerText : '';

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {propertyOuterLayer && PT.GROUND.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            חיפוי חיצוני: 
          </span>
          <span>{combineOptionalText(propertyOuterLayerLabel, optionalText)}</span>
        </>
      )}
    </div>
  );
};

const Line06Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyBuildType,
        propertyBuildTypeText
      },
    },
  } = useCompleteInspectionContext();
  const propertyBuildTypeLabel = getSelectedOptionLabel(propertyBuildType, buildingBuildTypeOptions);
  const optionalText = (propertyBuildType === 3) ? propertyBuildTypeText : '';

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {propertyBuildType && PT.GROUND.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            סוג בנייה: 
          </span>
          <span>{combineOptionalText(propertyBuildTypeLabel, optionalText)}</span>
        </>
      )}
    </div>
  );
};

const Line1Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        apartmentNumber
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div>
      {Boolean(apartmentNumber) && PT.REGULAR.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            מספר הדירה עפ"י הסימון בבניין: 
          </span>
          <span>{apartmentNumber}{checkIfTextEndsWithDot(apartmentNumber)}</span>
        </div>
      )}
    </div>
  );
};

const Line2Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseFloorInBuilding,
        buildingOnPillars,
        buildingOnPillarsOtherText,
        floor,
      },
    },
  } = useCompleteInspectionContext();
  const buildingOnPillarsValue = buildingOnPillars === 12
    ? buildingOnPillarsOtherText
    : getSelectedOptionLabel(buildingOnPillars, buildingOnPillarsOptions);
  const text = [
    Boolean(houseFloorInBuilding) ? `${houseFloorInBuilding} מעל קומת ` : '',
    Boolean(buildingOnPillarsValue) && PT.REGULAR.includes(propertyTypeID) ? `${buildingOnPillarsValue}` : '',
    (Boolean(floor) && houseFloorInBuilding !== floor) ? ` (בנסח הרישום קומה ${floor})` : '',
    _.some([
      houseFloorInBuilding,
      buildingOnPillars,
      floor,
    ]) ? '.' : '',
  ].filter(Boolean).join('');

  return (
    <div>
      {houseFloorInBuilding && PT.REGULAR.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            קומת הדירה בבניין: 
          </span>
          <span>{text}</span>
        </div>
      )}
    </div>
  );
};

const Line3Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseRoomCount,
        houseFunctionalDivide
      },
    },
  } = useCompleteInspectionContext();
  const getFirstSpecialPropertyTypeValue = () => {
    switch (propertyTypeID) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return <span>דירת </span>;
      case 7:
      case 8:
      case 9:
      case 10:
        return <span>צמוד קרקע </span>;
      case 11:
        return <span>מגרש </span>;
      default:
        return null;
    }
  };
  const getSecondSpecialPropertyTypeValue = () => {
    switch (propertyTypeID) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return <span>הכוללת </span>;
      case 7:
      case 8:
      case 9:
      case 10:
        return <span>הכולל </span>;
      case 11:
        return <span>מגרש</span>;
      default:
        return null;
    }
  };

  const text = [
    getFirstSpecialPropertyTypeValue(),
    Boolean(houseRoomCount) ? `${houseRoomCount} ` : '',
    Boolean(houseRoomCount) ? `חדרים ` : '',
    Boolean(houseFunctionalDivide) ? getSecondSpecialPropertyTypeValue() : '',
    Boolean(propertyTypeID !== 11)
      ? `${houseFunctionalDivide}${checkIfTextEndsWithDot(houseFunctionalDivide)}`
      : '.'
  ];

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
    >
      {!PT.LOT.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
             תאור הדירה/הנכס: 
          </span>
          <span>{text}</span>
        </>
      )}
    </div>
  );
};

export const getLine4Content = (context) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseFinishGrade,
        houseFinishGradeDesc,
      },
    },
  } = context;
  const houseFinishGradeValue = getSelectedOptionLabel(houseFinishGrade, houseFinishGradeOptions);
  let firstLineText;
  let restChunks = [];

  if (Boolean(houseFinishGradeDesc)) {
    [firstLineText, ...restChunks] = getTextChunks(houseFinishGradeDesc);
  }

  return !PT.LOT.includes(propertyTypeID)
    ? [
      <span>
        <span className={classnames(styles.bold)}>רמת גמר: </span>
        <span>{Boolean(houseFinishGrade) ? `${houseFinishGradeValue}. ` : ''}</span>
        <span>{Boolean(houseFinishGradeDesc) ? `פירוט:${firstLineText}${(restChunks.length > 0) ? '' : checkIfTextEndsWithDot(firstLineText)} ` : ''}</span>
      </span>,
      ...restChunks
        .map(mapTextChunks)
        .map((item, index, array) => (
          <div
            className={{[styles.lastSectionLine]: index === array.length - 1}}
          >
            {item}
          </div>
        ))
    ]
    : [];
};

const Line5Content = () => {
  const {
    data: {
      pdfData: {
        houseAirDirectionNorth,
        houseAirDirectionSouth,
        houseAirDirectionEast,
        houseAirDirectionWest,
        houseAirDirectionNorthEast,
        houseAirDirectionNorthWest,
        houseAirDirectionSouthEast,
        houseAirDirectionSouthWest,
        buildingLocation,
        streetName
      },
    },
  } = useCompleteInspectionContext();
  const houseAirDirectionSelected = (
    houseAirDirectionNorth ||
    houseAirDirectionSouth ||
    houseAirDirectionEast ||
    houseAirDirectionWest ||
    houseAirDirectionNorthEast ||
    houseAirDirectionNorthWest ||
    houseAirDirectionSouthEast ||
    houseAirDirectionSouthWest
  );

  const text = [
    `${joinAirDirections({
      houseAirDirectionNorth,
      houseAirDirectionSouth,
      houseAirDirectionEast,
      houseAirDirectionWest,
      houseAirDirectionNorthEast,
      houseAirDirectionNorthWest,
      houseAirDirectionSouthEast,
      houseAirDirectionSouthWest,
    })}. `,
    Boolean(buildingLocation) ? `${getSelectedOptionLabel(buildingLocation, buildingLocationOptions)} ` : '',
    Boolean(buildingLocation) ? `לרחוב ` : '',
    Boolean(streetName) && Boolean(buildingLocation) ? `${streetName}.` : '',
  ].filter(Boolean).join('');

  return (
    <div>
      {Boolean(houseAirDirectionSelected) && (
        <div className={classnames(styles.textRegular, styles.innerPadding)}>
          <span className={styles.bold}>כיוונים: </span>
          <span>{text}</span>
        </div>
      )}
    </div>
  );
};

const Line6Content = () => {
  const {
    data: {
      pdfData: {
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div>
      {Boolean(renovationDetailsDesc) && (
        <div className={classnames(styles.textRegular, styles.innerPadding)}>
          <span className={styles.bold}>שיפוצים: </span>
          <span>{getBreakLinesTextShumaType3(renovationDetailsDesc)}</span>
        </div>
      )}
    </div>
  );
};

const Line7Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseMaintenanceStatus,
        houseMaintenanceStatusText,
      },
    },
  } = useCompleteInspectionContext();
  const houseMaintenanceStatusValue = getSelectedOptionLabel(houseMaintenanceStatus, houseMaintenanceStatusOptions);
  const text = [
    Boolean(houseMaintenanceStatusValue) ? `${houseMaintenanceStatusValue}. ` : '',
    ...getBreakLinesTextShumaType3(houseMaintenanceStatusText)
  ];

  return (
    <div
      className={classnames(styles.textRegular, styles.innerPadding)}
    >
      {!PT.LOT.includes(propertyTypeID) && (
        <>
          <span className={styles.bold}>מצב תחזוקה: </span>
          <span>{text}</span>
        </>
      )}
    </div>
  );
};

const Line8Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        housePhysicalState,
      },
    },
  } = useCompleteInspectionContext();
  const housePhysicalStateValue = getSelectedOptionLabel(housePhysicalState, housePhysicalStateOptions);

  return (
    <div className={classnames(styles.textRegular, styles.innerPadding)}>
      {!PT.LOT.includes(propertyTypeID) && (
        <>
          <span className={styles.bold}>מצב פיזי: </span>
          <span>{housePhysicalStateValue}{checkIfTextEndsWithDot(housePhysicalStateValue)}</span>
        </>
      )}
    </div>
  );
};

const Line9Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseSecureRoom,
      },
    },
  } = useCompleteInspectionContext();
  const houseSecureRoomValue = getSelectedOptionLabel(houseSecureRoom, houseSecureRoomOptions);

  return (
    <div className={classnames(styles.textRegular, styles.innerPadding)}>
      {!PT.LOT.includes(propertyTypeID) && (
        <>
          <span className={styles.bold}>ממ"ד: </span>
          <span>{houseSecureRoomValue}{checkIfTextEndsWithDot(houseSecureRoomValue)}</span>
        </>
      )}
    </div>
  );
};

const Line10Content = () => {
  const {
    data: {
      pdfData: {
        houseParking,
        houseParkingType,
      },
    },
  } = useCompleteInspectionContext();
  const houseParkingTypeValue = getSelectedOptionLabel(houseParkingType, houseParkingTypeOptions);
  const houseParkingValue = (houseParking === 1)
    ? `${houseParkingTypeValue}`
    : 'אין';

  return (
    <div
      className={classnames(styles.textRegular, styles.innerPadding)}
    >
      <span className={styles.bold}>חניה: </span>
      <span>{houseParkingValue}{checkIfTextEndsWithDot(houseParkingValue)}</span>
    </div>
  );
};

const Line01 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        landDescription
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;

  return (
    <div ref={ref}>
      {showLine0 && <Line01Content/>}
    </div>
  );
});

const Line02 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        landDescription,
        propertyAge
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;

  return (
    <div ref={ref}>
      {_.some([showLine0, showLine01]) && <Line02Content/>}
    </div>
  );
});

const Line03 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;

  return (
    <div ref={ref}>
      {_.some([showLine0, showLine01, showLine02]) && <Line03Content/>}
    </div>
  );
});

const Line04 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03
      ]) && <Line04Content/>}
    </div>
  );
});

const Line05 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
      ]) && <Line05Content/>}
    </div>
  );
});

const Line06 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
      ]) && <Line06Content/>}
    </div>
  );
});

const Line1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
      ]) && <Line1Content/>}
    </div>
  );
});

const Line2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
      ]) && <Line2Content/>}
    </div>
  );
});

const Line3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
      ]) && <Line3Content/>}
    </div>
  );
});

const Line5 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;
  const showLine3 = !isLot;
  const showLine4 = !isLot;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
      ]) && <Line5Content/>}
    </div>
  );
});

const Line6 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding,
        houseAirDirectionSelected
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;
  const showLine3 = !isLot;
  const showLine4 = !isLot;
  const showLine5 = Boolean(houseAirDirectionSelected);

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
      ]) && <Line6Content/>}
    </div>
  );
});

const Line7 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding,
        houseAirDirectionSelected,
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;
  const showLine3 = !isLot;
  const showLine4 = !isLot;
  const showLine5 = Boolean(houseAirDirectionSelected);
  const showLine6 = Boolean(renovationDetailsDesc);

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
      ]) && <Line7Content/>}
    </div>
  );
});

const Line8 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding,
        houseAirDirectionSelected,
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;
  const showLine3 = !isLot;
  const showLine4 = !isLot;
  const showLine5 = Boolean(houseAirDirectionSelected);
  const showLine6 = Boolean(renovationDetailsDesc);
  const showLine7 = !isLot;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
        showLine7,
      ]) && <Line8Content/>}
    </div>
  );
});

const Line9 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyFloorsCount,
        landDescription,
        propertyAge,
        propertyOnPillars,
        propertyRoofType,
        propertyOuterLayer,
        propertyBuildType,
        apartmentNumber,
        houseFloorInBuilding,
        houseAirDirectionSelected,
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();
  const isLot = PT.LOT.includes(propertyTypeID);
  const isGround = PT.GROUND.includes(propertyTypeID);
  const isRegular = PT.REGULAR.includes(propertyTypeID);
  const showLine0 = landDescription && isLot;
  const showLine01 = propertyAge && isGround;
  const showLine02 = propertyFloorsCount && isGround;
  const showLine03 = propertyOnPillars && isGround;
  const showLine04 = propertyRoofType && isGround;
  const showLine05 = propertyOuterLayer && isGround;
  const showLine06 = propertyBuildType && isGround;
  const showLine1 = apartmentNumber && isRegular;
  const showLine2 = houseFloorInBuilding && isRegular;
  const showLine3 = !isLot;
  const showLine4 = !isLot;
  const showLine5 = Boolean(houseAirDirectionSelected);
  const showLine6 = Boolean(renovationDetailsDesc);
  const showLine7 = !isLot;
  const showLine8 = !isLot;

  return (
    <div ref={ref}>
      {_.some([
        showLine0,
        showLine01,
        showLine02,
        showLine03,
        showLine04,
        showLine05,
        showLine06,
        showLine1,
        showLine2,
        showLine3,
        showLine4,
        showLine5,
        showLine6,
        showLine7,
        showLine8,
      ]) && <Line9Content/>}
    </div>
  );
});

const Line10 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseParking,
        houseParkingType,
      },
    },
  } = useCompleteInspectionContext();
  const houseParkingTypeValue = getSelectedOptionLabel(houseParkingType, houseParkingTypeOptions);
  const houseParkingValue = (houseParking === 1)
    ? `${houseParkingTypeValue}`
    : 'אין';

  return (
    <div
      className={classnames(styles.textRegular, styles.innerPadding)}
      ref={ref}
    >
      <span className={styles.bold}>חניה: </span>
      <span>{houseParkingValue}{checkIfTextEndsWithDot(houseParkingValue)}</span>
    </div>
  );
});

const Line11 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseMatchingBuilding,
        houseMatchingBuildingText
      },
    },
  } = useCompleteInspectionContext();
  let houseMatchingBuildingValue = getSelectedOptionLabel(houseMatchingBuilding, houseMatchingBuildingOptions);
  if (houseMatchingBuilding === 1 || (houseMatchingBuilding === 2 && Boolean(!houseMatchingBuildingText))) {
    houseMatchingBuildingValue = `${houseMatchingBuildingValue}.`;
  }
  if ((houseMatchingBuilding === 2) && Boolean(houseMatchingBuildingText)) {
    houseMatchingBuildingValue = `${houseMatchingBuildingValue}. ${houseMatchingBuildingText}${checkIfTextEndsWithDot(houseMatchingBuildingText)}`;
  }

  return (
    <div
      className={classnames(styles.textRegular, styles.innerPadding)}
      ref={ref}
    >
      {Boolean(houseMatchingBuilding) && (
        <>
          <span className={styles.bold}>תואמת/חורגת את הבנוי בבניין: </span>
          <span>{houseMatchingBuildingValue}</span>
        </>
      )}
    </div>
  );
});

const Line12 = React.forwardRef(({}, ref) => {

  return (
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.textUnderline,
        styles.bold
      )}
      ref={ref}
    >
      שטח הנכס והצמדות:
    </div>
  );
});

const Line13 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        housePermitArea,
        housePermitAreaIncludeBalcony,
        housePermitAreaIncludeStorage,
        houseBalconyOpenPermitArea,
        houseBalconyClosedPermitArea,
        houseYardPermitArea,
        houseStoragePermitArea,
        houseRoofPermitArea,
        houseParkingPermitArea,
        housePermitAreaText
      },
    },
  } = useCompleteInspectionContext();

  const text1 = [
    [
      (Boolean(housePermitArea) && !PT.LOT.includes(propertyTypeID)) ? `${housePermitArea} מ"ר` : '',
      Boolean(housePermitAreaIncludeBalcony) ? 'כולל שטחי מרפסות' : '',
      Boolean(housePermitAreaIncludeStorage) ? 'כולל שטחי מחסן' : '',
    ].filter(Boolean).join(', '),
    _.some([
      housePermitArea && !PT.LOT.includes(propertyTypeID),
      housePermitAreaIncludeBalcony,
      housePermitAreaIncludeStorage
    ]) ? '. ' : '',
  ].filter(Boolean).join('');
  const text2 = [
    Boolean(houseBalconyOpenPermitArea) ? `${houseBalconyOpenPermitArea} מ"ר שטח מרפסות פתוחות` : '',
    Boolean(houseBalconyClosedPermitArea) ? `, ${houseBalconyClosedPermitArea} מ"ר שטח מרפסות סגורות` : '',
    Boolean(houseYardPermitArea) ? `, ${houseYardPermitArea} מ"ר שטח חצר` : '',
    Boolean(houseStoragePermitArea) ? `, ${houseStoragePermitArea} מ"ר מחסן` : '',
    Boolean(houseRoofPermitArea) ? `, ${houseRoofPermitArea} מ"ר שטח גג` : '',
    Boolean(houseParkingPermitArea) ? `, ${houseParkingPermitArea} מ"ר שטח חניה` : '',
    _.some([
      houseBalconyOpenPermitArea,
      houseBalconyClosedPermitArea,
      houseYardPermitArea,
      houseStoragePermitArea,
      houseRoofPermitArea,
      houseParkingPermitArea
    ]) ? '. ' : '',
    Boolean(housePermitAreaText)
      ? `${housePermitAreaText}${checkIfTextEndsWithDot(housePermitAreaText)}`
      : ''
  ].filter(Boolean).join('');

  return (
    <div ref={ref}>
      {Boolean(text1 || text2) && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם להיתר בניה: 
          </span>
          <span>{text1}</span>
          {_.some([
            houseBalconyOpenPermitArea,
            houseBalconyClosedPermitArea,
            houseYardPermitArea,
            houseStoragePermitArea,
            houseRoofPermitArea,
            houseParkingPermitArea
          ]) && (<span> הצמדות: </span>)}
          <span>{checkIfStartsWithComma(text2)}</span>
        </div>
      )}
    </div>
  );
});

const Line14 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingTasritTaba
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingTasritTaba) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לתשריט תב"ע: 
          </span>
          <span>{houseAreaAccordingTasritTaba}{checkIfTextEndsWithDot(houseAreaAccordingTasritTaba)}</span>
        </div>
      )}
    </div>
  );
});

const Line15 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        houseRegisteredArea,
        houseRegisteredAreaIncludeBalcony,
        houseRegisteredAreaIncludeStorage,
        houseBalconyOpenRegisteredArea,
        houseBalconyClosedRegisteredArea,
        houseYardRegisteredArea,
        houseStorageRegisteredArea,
        houseRoofRegisteredArea,
        houseParkingRegisteredArea,
        houseJointHomeRegistered,
        registeredAreaAdditionalDescription
      },
    },
  } = useCompleteInspectionContext();
  const text1 = [
    Boolean(houseRegisteredArea) ? `${houseRegisteredArea} מ"ר` : '',
    houseRegisteredArea && houseRegisteredAreaIncludeBalcony ? ', ' : '',
    Boolean(houseRegisteredAreaIncludeBalcony) ? 'כולל שטחי מרפסות' : '',
    (houseRegisteredAreaIncludeBalcony && houseRegisteredAreaIncludeStorage) || (houseRegisteredArea && houseRegisteredAreaIncludeStorage) ? ', ' : '',
    Boolean(houseRegisteredAreaIncludeStorage) ? 'כולל שטחי מחסן' : '',
    _.some([
      houseRegisteredArea,
      houseRegisteredAreaIncludeBalcony,
      houseRegisteredAreaIncludeStorage
    ]) ? '.' : '',
  ].filter(Boolean).join('');

  const text2 = [
    Boolean(houseBalconyOpenRegisteredArea) ? `${houseBalconyOpenRegisteredArea} מ"ר שטח מרפסות פתוחות` : '',
    Boolean(houseBalconyClosedRegisteredArea) ? `, ${houseBalconyClosedRegisteredArea} מ"ר שטח מרפסות סגורות` : '',
    Boolean(houseYardRegisteredArea) ? `, ${houseYardRegisteredArea} מ"ר שטח חצר` : '',
    Boolean(houseStorageRegisteredArea) ? `, ${houseStorageRegisteredArea} מ"ר מחסן` : '',
    Boolean(houseRoofRegisteredArea) ? `, ${houseRoofRegisteredArea} מ"ר שטח גג` : '',
    Boolean(houseParkingRegisteredArea) ? `, ${houseParkingRegisteredArea} מ"ר שטח חניה` : '',
  ].filter(Boolean).join('');

  const text3 = Boolean(registeredAreaAdditionalDescription) ? ` ${registeredAreaAdditionalDescription}${checkIfTextEndsWithDot(registeredAreaAdditionalDescription)}` : '';

  return (
    <div ref={ref}>
      {Boolean(text1 || text2) && houseJointHomeRegistered !== 2 && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לנסח הרישום ותשריט הבית המשותף: 
          </span>
          <span>{text1}</span>
          {_.some([
            houseBalconyOpenRegisteredArea,
            houseBalconyClosedRegisteredArea,
            houseYardRegisteredArea,
            houseStorageRegisteredArea,
            houseRoofRegisteredArea,
            houseParkingRegisteredArea
          ]) && (<span> הצמדות: </span>)}
          <span>
            {checkIfStartsWithComma(text2)}
            {text2 && text3 && ','}
            {text2 && !text3 && '.'}
          </span>
          <span>{text3}</span>
        </div>
      )}
    </div>
  );
});

const Line16 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingNesahRishum
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingNesahRishum) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לנסח הרישום (מפנקס הזכויות): 
          </span>
          <span>{houseAreaAccordingNesahRishum}{checkIfTextEndsWithDot(houseAreaAccordingNesahRishum)}</span>
        </div>
      )}
    </div>
  );
});

const Line17 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingTasritHahira
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingTasritHahira) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לתשריט חכירה: 
          </span>
          <span>{houseAreaAccordingTasritHahira}{checkIfTextEndsWithDot(houseAreaAccordingTasritHahira)}</span>
        </div>
      )}
    </div>
  );
});

const Line18 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingTasritMeher
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingTasritMeher) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
           <span className={classnames(styles.bold)}>
             בהתאם לתשריט מכר: 
           </span>
          <span>{houseAreaAccordingTasritMeher}{checkIfTextEndsWithDot(houseAreaAccordingTasritMeher)}</span>
        </div>
      )}
    </div>
  );
});

const Line19 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseAreaAccordingHeskemShituf
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseAreaAccordingHeskemShituf) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לתשריט הסכם שיתוף: 
          </span>
          <span>{houseAreaAccordingHeskemShituf}{checkIfTextEndsWithDot(houseAreaAccordingHeskemShituf)}</span>
        </div>
      )}
    </div>
  );
});

const Line20 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyAreaByMunicipal
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(propertyAreaByMunicipal) && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            בהתאם לחיוב ארנונה: 
          </span>
          <span>{propertyAreaByMunicipal}{checkIfTextEndsWithDot(propertyAreaByMunicipal)}</span>
        </div>
      )}
    </div>
  );
});

const Line21 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        propertyAreaByRule9
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(propertyAreaByRule9) && !PT.LOT.includes(propertyTypeID) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            שטח בנוי ברוטו (כהגדרתו בתקן שמאי מספר 9): 
          </span>
          <span>{getBreakLinesTextShumaType3(propertyAreaByRule9)}</span>
        </div>
      )}
    </div>
  );
});

const Line22 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        landShetachText
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(landShetachText) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            שטח המגרש: 
          </span>
          <span>{landShetachText}{checkIfTextEndsWithDot(landShetachText)}</span>
        </div>
      )}
    </div>
  );
});

export default {
  HeadLine1,
  Line01,
  Line02,
  Line03,
  Line04,
  Line05,
  Line06,
  Line1,
  Line2,
  Line3,
  Line5,
  Line6,
  Line7,
  Line8,
  Line9,
  Line10,
  Line11,
  Line12,
  Line13,
  Line14,
  Line15,
  Line16,
  Line17,
  Line18,
  Line19,
  Line20,
  Line21,
  Line22,
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {shallowEqual, useSelector} from "react-redux";

const OrderStatus = ({
                       variant,
                       size,
                       statusID,
                       customClassNames,
                     }) => {

  const {statusList} = useSelector(
    (state) => ({
      statusList: state.statusListReducer.statusList,
    }),
    shallowEqual
  );
  const getStatusName = (statusID) => {
    let filteredArray = statusList.filter(status => status.value === statusID);

    return filteredArray.length > 0 ? filteredArray[0].label : ''
  }
  const getSize = (size) => {
    switch (size) {
      case 'sm':
        return 'font-size-h7';
      case 'm':
        return 'font-size-h6';
      case 'lg':
      default:
        return 'font-size-h4';
    }
  };

  switch (variant) {
    case 'badge':
      return (
        <div
          className={classnames(
            'badge badge-pill',
            getSize(size),
            customClassNames,
            {
              ['badge-dark']: statusID === 1,
              ['badge-success']: statusID === 2,
              ['badge-warning']: statusID === 3,
            }
          )}
        >
          {getStatusName(statusID)}
        </div>
      )
    default:
      return (
        <div
          className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap"
        >
          {getStatusName(statusID)}
        </div>
      )
  }


};

OrderStatus.propTypes = {
  statusID: PropTypes.number,
  customClassNames: PropTypes.string
};

export default OrderStatus;

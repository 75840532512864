import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {can} from '../../../_common/components/Permissions';
import useActiveLinkClass from '../../../_common/components/_custom-hooks/useActiveLinkClass';
import {pathnames as P} from '../../../_common/constants/pathnames';
import {subjects as S, permissionTypes as T} from '../../../_common/constants/permissionSubjects';

const CustomersEntityLink = () => {
  const {activeClassName} = useActiveLinkClass({url: P.CUSTOMERS_ENTITY_PAGE});

  return can(T.VIEW, S.CUSTOMERS_ENTITY_PAGE) && (
    <li
      className={`menu-item ${activeClassName}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={P.CUSTOMERS_ENTITY_PAGE}>
        <img
          src={'../../media/common/Group_BC.svg'}
          alt="list"
          className="mr-3"
        />
        <div className="menu-text">
          <span className="font-size-lg">
            <FormattedMessage
            id="customers-entity-page-link"
            defaultMessage="Customers entity"
          />
          </span>
        </div>
      </NavLink>
    </li>
  );
};

CustomersEntityLink.propTypes = {};

export default CustomersEntityLink;

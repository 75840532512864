const defaultState = {
    users: [],
    loading: false,
    error: false,
    errorMessage: null
};
export const actionTypes = {
  getUsers: 'GET_USERS',
  catchError: 'CATCH_ERROR',
};
export const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.getUsers: {
      const {users} = action.payload;
      return {
        ...state,
        users,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.catchError: {
      const error = `INSPECTIONS_FILTER: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
}
export const actions = {
  getUsers: (data) => ({
    type: actionTypes.getUsers,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: error
  }),
};

import React from 'react';
import {ChartPie} from "./ChartPie";
import {useIntl} from "react-intl";
import {useSimpleDateRangeContext} from "../../SimpleDateRangeContext";

export const SimpleDateRange = () => {
  const intl = useIntl();
  const headerCities = intl.formatMessage({
    id: 'dashboard-page-cities-header',
    defaultMessage: 'Common cities',
  });
  const headerShumaStatuses = intl.formatMessage({
    id: 'dashboard-page-shuma-statuses-header',
    defaultMessage: 'Segmentation of inspections in progress',
  });
  const headerBanks = intl.formatMessage({
    id: 'dashboard-page-banks-header',
    defaultMessage: 'Banks',
  });
  const headerUsers = intl.formatMessage({
    id: 'dashboard-page-users-header',
    defaultMessage: 'Users',
  });
  const usersTooltip = intl.formatMessage({
    id: 'dashboard-page-users-tooltip',
    defaultMessage: 'Final reports, no past reports',
  });

  const {data} = useSimpleDateRangeContext();
  const popularCities = data?.popularCities
    ? data?.popularCities.map(item => item.subCount)
    : [];
  const shumaStatuses = data?.statusTypes
    ? data?.statusTypes.map(item => item.subCount)
    : [];
  const banksRating = data?.banksRating
    ? data?.banksRating.map(item => item.subCount)
    : [];
  const usersRating = data?.usersRating
    ? data?.usersRating.map(item => item.subCount)
    : [];

  const popularCitiesLabels = data?.popularCities
    ? data?.popularCities.map(item => item.city_name)
    : [];
  const shumaStatusesLabels = data?.statusTypes
    ? data?.statusTypes.map(item => item.shuma_status_type_name_he)
    : [];
  const banksLabels = data?.popularCities
    ? data?.banksRating.map(item => item.bankName)
    : [];
  const usersLabels = data?.popularCities
    ? data?.usersRating.map(item => item.userName)
    : [];

  return (
    <div className='mx-auto my-4 px-10 w-75'>
      <div className={'d-flex justify-content-between mb-4'}>
        <ChartPie
          data={shumaStatuses}
          labels={shumaStatusesLabels}
          header={headerShumaStatuses}
        />
        <ChartPie
          data={usersRating}
          labels={usersLabels}
          header={headerUsers}
          tooltip={usersTooltip}
        />
      </div>
      <div className={'d-flex justify-content-between'}>
        <ChartPie
          data={popularCities}
          labels={popularCitiesLabels}
          header={headerCities}
        />
        <ChartPie
          data={banksRating}
          labels={banksLabels}
          header={headerBanks}
        />
      </div>
    </div>
  )
}


import React from 'react';
import {useHistory} from 'react-router-dom';
import {FileList} from "./FileList";
import Empty from "../../../_common/components/Empty";
import AnimatedUploadingFilePopUp from "../../../_common/components/AnimatedUploadingFilePopUp";
import ButtonUploadFile from "../../../_common/components/ButtonUploadFile";
import CommonButton from "../../../_common/components/CommonButton";
import {useOrderDetailsUIFilesContext} from '../OrderDetailsUIFilesContext';
import {useOrderDetailsUIGlobalContext} from '../OrderDetailsUIGlobalContext';
import {pathnames as P} from '../../../_common/constants/pathnames';

const TabOrderDocuments = () => {
  const history = useHistory();
  const {textContent} = useOrderDetailsUIGlobalContext();
  const {
    fileList,
    uploadingFile,
    onUploadFile,
  } = useOrderDetailsUIFilesContext();
  const {
    noFiles,
    labelUploadFiles,
    buttonLabelUploadGeneral,
    buttonLabelUploadFinancial,
    tabOrderDocumentsGridHeader,
    tabOrderDocumentsButtonLabelComplete
  } = textContent;

  return (
    <div className={'p-4'}>
      <AnimatedUploadingFilePopUp show={uploadingFile}/>
      <div className={'my-4 d-flex align-items-center'}>
        <h4 className='h-100 font-weight-bolder text-nowrap d-inline-block mr-8 border-warning border-bottom'>
          {labelUploadFiles}
        </h4>
        <ButtonUploadFile
          label={buttonLabelUploadGeneral}
          onChange={(e) => {
            onUploadFile(e, 1)
          }}
          multiple={true}
          customContainerStyles={'mr-8'}
        />
        <ButtonUploadFile
          label={buttonLabelUploadFinancial}
          onChange={(e) => {
            onUploadFile(e, 2)
          }}
          multiple={true}
        />
      </div>
      <h4 className={'font-weight-bolder d-inline-block mt-3 mb-0 border-warning border-bottom'}>
        {tabOrderDocumentsGridHeader}
      </h4>
      <div>
        {(fileList.length > 0)
          ? (
            <FileList/>
          )
          : (
            <Empty
              message={noFiles}
              customContainerStyles={'pt-16'}
            />
          )
        }
      </div>
      <div className={'d-flex justify-content-start'}>
        <CommonButton
          customButtonClassNames={'w-250px btn btn-warning font-weight-bolder text-dark mt-8'}
          label={tabOrderDocumentsButtonLabelComplete}
          onClick={() => {history.push(P.ORDERS_PAGE)}}
        />
      </div>
    </div>
  );
};

export default TabOrderDocuments;

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getFinalPriceWithText} from '../../../utils/texts';
import styles from './styles.module.scss';

const ShumaType1FinalPrice = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        estimatePriceFinalRounded,
      }
    },
  } = useCompleteInspectionContext();

  return (
    <div className={styles.blockTitle} data-testid="finalPrice" ref={ref}>
      {getFinalPriceWithText(estimatePriceFinalRounded)}
    </div>
  );
});

export default ShumaType1FinalPrice;

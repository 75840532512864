import React, {useMemo} from "react";
import objectPath from "object-path";
// LayoutContext
import {useHtmlClassService} from "../_core/MetronicLayout";
// Import Layout components
import Header from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {LayoutInit} from "./LayoutInit";
import {ScrollTop} from "./extras/ScrollTop";
import styles from './styles.module.scss';

export function Layout({children}) {
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      <HeaderMobile/>
      <div className="d-flex flex-column flex-root">
        <div className={`d-flex flex-row flex-column-fluid page`}>
          {layoutProps.asideDisplay && (<Aside/>)}
          <div
            className={`d-flex flex-column flex-row-fluid wrapper ${styles.contentWrapper}`}
            id="kt_wrapper"
          >
            <Header/>
            <div
              id="kt_content"
              className={`${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      <ScrollTop/>
      <LayoutInit/>
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}

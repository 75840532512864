import React from 'react';
import {Button} from 'react-bootstrap';
import {tooltips as TTS} from '../constants/tooltips';
import {toAbsoluteUrl} from '../../../../_metronic/_helpers';
import {getCustomerDetailsLink} from '../utils/url';
import {can} from '../components/Permissions';
import {subjects as S, permissionTypes as T} from '../constants/permissionSubjects';

export function EditCustomerColumnFormatter(item, history) {
  const goToCustomer = () => history.push(getCustomerDetailsLink(item.customerID))

  return (
    <Button
      className="btn btn-icon btn-warning border-1"
      title={TTS.EDIT_CUSTOMER}
      onClick={goToCustomer}
      disabled={!can(T.FULL, S.CUSTOMERS_ENTITY_PAGE)}
    >
      <img
        src={toAbsoluteUrl('media/common/Edit.svg')}
        alt="edit-row"
      />
    </Button>
  );
}

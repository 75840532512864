import {actionTypes} from '../reducers/citiesAndStreetsReducer';
import axios from 'axios';

export const GET_CITIES = '/generaldata/getCities';
export const GET_STREETS = '/generaldata/getStreets';
export const ADD_STREET = '/generaldata/addStreet';

export class StreetExistError extends Error {}

export const getCities = () => dispatch => {
  return axios.post(GET_CITIES)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {cities} = body;

      dispatch({
        type: actionTypes.getCities,
        payload: {cities: transformCities(cities)},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getCitiesList = async () => {
  const response = await axios.post(GET_CITIES)
  const {body, errorCode} = response.data;
  if (errorCode < 0) throw new Error();
  const {cities} = body;

  return transformCities(cities);
};

export const getStreets = cityID => dispatch => {
  return axios.post(GET_STREETS, {cityID})
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {streets} = body;

      dispatch({
        type: actionTypes.getStreets,
        payload: {streets: transformStreets(streets)},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getStreetsList = async cityID => {
  const response = await axios.post(GET_STREETS, {cityID})
  const {body, errorCode} = response.data;
  if (errorCode < 0) throw new Error();

  return transformStreets(body.streets);
};

export const addStreet = async (cityID, streetName) => {
  const response = await axios.post(ADD_STREET, {cityID, streetName})
  const {body, errorCode} = response.data;
  if (errorCode === -2) throw new StreetExistError();
  if (errorCode === -1) throw new Error();

  return transformStreets(body.streets);
}

const transformCities = cities => cities.map(city => {
  const {cityID, cityName} = city;
  return {label: cityName, value: cityID};
});
const transformStreets = streets => streets.map(street => {
  const {streetID, streetName} = street;
  return {label: streetName, value: streetID};
});

import React from 'react';
import PropTypes from 'prop-types';

const InfoItem = ({
                    label,
                    value,
                    customValueClassName,
                    allowShowTitle = false
                  }) => {

  return (
    <div className='d-flex justify-content-between my-2'>
      <div className='font-weight-bolder font-size-lg'>{label}:</div>
      <div
        className={`text-break ml-4 ${customValueClassName}`}
        title={allowShowTitle ? value : null}
      >
        {value}
      </div>
    </div>
  );
};

InfoItem.propTypes = {
  label: PropTypes.node,
  customValueClassName: PropTypes.string,
  allowShowTitle: PropTypes.bool,
};

export default InfoItem;

export const pathnames = {
  HOME: '/',
  DASHBOARD_PAGE: '/dashboard-page',
  INSPECTIONS_PAGE: '/inspections-page',
  OLD_INSPECTIONS_PAGE: '/old-inspections-page',
  OLD_INSPECTION_DETAILS: '/old-inspection-details',
  OLD_INSPECTION_DETAILS_LINK: (shumaID) => `/old-inspection-details/${shumaID}`,
  OLD_INSPECTION_DETAILS_NEW_SHUMA: '/old-inspection-details/-1',
  NEW_INSPECTION_DETAILS: '/inspection-details/:shumaID',
  NEW_INSPECTION_DETAILS_LINK: (shumaID) => `/inspection-details/${shumaID}`,
  COMPLETE_INSPECTION: '/complete-inspection',
  CUSTOMERS_ENTITY_PAGE: '/customers-entity',
  CUSTOMER_DETAILS: '/customer-details',
  CUSTOMER_DETAILS_CUSTOMER_ID: '/customer-details/:customerID',
  ORDERS_PAGE: '/orders-page',
  ORDER_DETAILS: '/order-details',
  PRICE_QUOTES_PAGE: '/price-quotes-page',
  TASKS_PAGE: '/tasks-page',
};

const defaultState = {
  listLoading: false,
  pageCount: 0,
  currentPageNumber: 0,
  entities: [],
  totalResult: 0,
  error: null,
};
export const actionTypes = {
  priceQuotesFetched: 'PRICE_QUOTES_FETCHED',
  priceQuotesAppended: 'PRICE_QUOTES_APPENDED',
  addPriceQuote: 'ADD_PRICE_QUOTE',
  editPriceQuote: 'EDIT_PRICE_QUOTE',
  deletePriceQuote: 'DELETE_PRICE_QUOTE',
  editPriceQuoteFileName: 'EDIT_PRICE_QUOTE_FILE_NAME',
  catchError: 'CATCH_ERROR'
};

const getIndex = (array, priceQuoteID) => {
  const mappedArray = array.map(item => item.priceQuoteID);
  return mappedArray.indexOf(priceQuoteID);
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.priceQuotesFetched: {
      const {totalPageCount, currentPageNumber, priceQuotes, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: priceQuotes,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.priceQuotesAppended: {
      const {totalPageCount, currentPageNumber, priceQuotes, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...priceQuotes]
          : [...priceQuotes],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.addPriceQuote: {
      const {customers, status, ...rest} = action.payload;
      state.entities.unshift({});
      state.entities[0] = {
        customers,
        createDate: new Date(),
        priceQuoteStatusName: status,
        ...rest
      };
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.editPriceQuote: {
      const {priceQuoteID, customers, status, price} = action.payload;
      const index = getIndex(state.entities, priceQuoteID);
      const mappedCustomers = customers.map(item => {
        return {
          firstName: item.firstName,
          lastName: item.lastName,
        }
      })
      state.entities[index].priceQuoteID = priceQuoteID;
      state.entities[index].customers = mappedCustomers;
      state.entities[index].priceQuoteStatusName = status;
      state.entities[index].price = price;

      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.deletePriceQuote: {
      const {priceQuoteID} = action.payload;
      const index = getIndex(state.entities, priceQuoteID);
      state.entities.splice(index, 1)
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.editPriceQuoteFileName: {
      const {priceQuoteID, fileName} = action.payload;
      const index = getIndex(state.entities, priceQuoteID);

      state.entities[index].fileName = fileName;
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.catchError: {
      const error = `PRICE QUOTES: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
};

export const actions = {
  priceQuotesFetched: (data) => ({
    type: actionTypes.priceQuotesFetched,
    payload: data
  }),
  priceQuotesAppended: (data) => ({
    type: actionTypes.priceQuotesAppended,
    payload: data
  }),
  addPriceQuote: (data) => ({
    type: actionTypes.addPriceQuote,
    payload: data
  }),
  editPriceQuote: (data) => ({
    type: actionTypes.editPriceQuote,
    payload: data
  }),
  deletePriceQuote: (data) => ({
    type: actionTypes.deletePriceQuote,
    payload: data
  }),
  editPriceQuoteFileName: (data) => ({
    type: actionTypes.editPriceQuoteFileName,
    payload: data
  }),
  catchError: (error) => ({type: actionTypes.catchError, payload: {error}}),
};

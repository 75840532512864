import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from './FileIcon';
import InteractionsMenu from './InteractionsMenu';
import styles from './styles.module.scss';

const UploadedFile = ({
                        fileName,
                        fileExtension,
                        fileID,
                        containerClassName = '',
                        InteractionsMenuComponent = InteractionsMenu,
                      }) => {

  return (
    <div className={`${styles.uploadedFile} ${containerClassName}`}>
      <div className={styles.imageContainer}>
        <FileIcon fileExtension={fileExtension}/>
      </div>
      <div className={styles.description}>
        <div className={styles.fileInfo}>
          <div className={styles.fileName}>
            <span title={fileName}>{fileName}</span>
          </div>
        </div>
        <InteractionsMenuComponent
          fileName={fileName}
          fileID={fileID}
        />
      </div>
    </div>
  );
};

UploadedFile.propTypes = {
  fileName: PropTypes.string,
  fileExtension: PropTypes.string,
  containerClassName: PropTypes.string,
  InteractionsMenuComponent: PropTypes.node,
};

export default UploadedFile;

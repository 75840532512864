import {chartColorsArray} from "../../_common/styles/chartColors";

export const getOptionsLine = (xData, yData, getCustomTooltip) => {

  return {
    series: [{
      data: yData
    }],
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    colors: chartColorsArray,
    legend: {
      horizontalAlign: 'left'
    },
    yaxis: {
      opposite: false,
      decimalsInFloat: 0,
      labels: {
        show: true,
        align: 'center',
        formatter: function (val) {
          let str = val.toString().split(".");
          str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return str.join(".");
        }
      },
      axisBorder: {
        show: true,
        color: '#E5E6E6',
      },
    },
    xaxis: {
      categories: xData,
      labels: {
        rotate: 60,
        style: {
          fontSize: '12px',
        }
      }
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex}) {
        return getCustomTooltip({series, seriesIndex, dataPointIndex})
      }
    },
    grid: {
      row: {
        colors: ['#F3F6F9', 'transparent'],
        opacity: 0.3
      },
    },
  }
};

export const getOptionsBar = (xData, yData, getCustomTooltip) => {

  return {
    series: yData,
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff']
      }
    },
    colors: [chartColorsArray[2], chartColorsArray[3]],
    legend: {
      offsetY: 8,
      horizontalAlign: 'center',
      inverseOrder: true,
      markers: {
        width: 12,
        height: 12,
        strokeColor: '#fff',
        offsetX: '5px',
      },
    },
    yaxis: {
      opposite: false,
      decimalsInFloat: 0,
      labels: {
        show: true,
        align: 'center',
      },
      tickAmount: 5,
      axisBorder: {
        show: true,
        color: '#E5E6E6',
      },
    },
    xaxis: {
      categories: xData,
      labels: {
        rotate: 60,
        style: {
          fontSize: '12px',
        }
      }
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex}) {
        return getCustomTooltip({series, seriesIndex, dataPointIndex})
      }
    },
    grid: {
      row: {
        colors: ['#F3F6F9', 'transparent'],
        opacity: 0.3
      },
    },
    fill: {
      opacity: 1
    },
  }
};

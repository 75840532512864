import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {formattingPrice} from '../../utils/formattingHelper';
import styles from './styles.module.scss';

export const getLabelByBalconyAndStorage = (balcony, storage, labels = {
  noOne: '',
  balcony: '',
  storage: '',
  both: '',
}) => {
  if (!Boolean(balcony) && !Boolean(storage)) {
    return labels.noOne;
  } else if (Boolean(balcony) && !Boolean(storage)) {
    return labels.balcony;
  } else if (!Boolean(balcony) && Boolean(storage)) {
    return labels.storage;
  } else if (Boolean(balcony) && Boolean(storage)) {
    return labels.both;
  } else {
    return '';
  }
};

export const permitAreaLabels = {
  noOne: 'מגורים',
  balcony: 'מגורים כולל מרפסות',
  storage: 'מגורים כולל מחסן',
  both: 'מגורים כולל מרפ  סות\\מחסן',
};

export const registeredAreaSqmLabels = {
  noOne: 'מגורים',
  balcony: 'מגורים כולל מרפסות',
  storage: 'מגורים כולל מחסן',
  both: 'מגורים כולל מרפסות\\מחסן',
};

const TableCalculations = ({customClassNames = {}}) => {
  const context = useCompleteInspectionContext();
  const {
    pdfData: {
      housePermitAreaIncludeBalcony,
      housePermitAreaIncludeStorage,
      housePermitArea,
      housePermitAreaWeightedValue,
      housePermitAreaAdjusted,
      houseBalconyOpenPermitArea,
      permitSqmOpenBalconyWeightedValue,
      permitSqmOpenBalconyAreaAdjusted,
      houseBalconyClosedPermitArea,
      permitSqmClosedBalconyWeightedValue,
      permitSqmClosedBalconyAreaAdjusted,
      houseYardPermitArea,
      permitSqmYardWeightedValue,
      permitSqmYardAreaAdjusted,
      houseRoofPermitArea,
      permitSqmRoofWeightedValue,
      permitSqmRoofAreaAdjusted,
      houseStoragePermitArea,
      permitSqmStorageWeightedValue,
      permitSqmStorageAreaAdjusted,
      permitSqmTotalAdjustedArea,
      permitSqmAveragePriceAdjusted,
      estimatePriceMethod1,
      houseRegisteredAreaIncludeBalcony,
      houseRegisteredAreaIncludeStorage,
      houseRegisteredArea,
      registeredRoomHouseRegisteredAreaWeightedValue,
      houseRegisteredAreaAdjusted,
      houseBalconyOpenRegisteredArea,
      registeredRoomOpenBalconyWeightedValue,
      registeredRoomOpenBalconyAreaAdjusted,
      houseBalconyClosedRegisteredArea,
      registeredRoomClosedBalconyWeightedValue,
      registeredRoomClosedBalconyAreaAdjusted,
      houseYardRegisteredArea,
      registeredRoomYardWeightedValue,
      registeredRoomYardAreaAdjusted,
      houseRoofRegisteredArea,
      registeredRoomRoofWeightedValue,
      registeredRoomRoofAreaAdjusted,
      houseStorageRegisteredArea,
      registeredRoomStorageWeightedValue,
      registeredRoomStorageAreaAdjusted,
      registeredRoomTotalAdjustedArea,
      averageRoomSq,
      roomAdjustedAveragePrice,
      estimatePriceMethod2,
      houseRegisteredSqmAreaIncludeBalcony,
      houseRegisteredSqmAreaIncludeStorage,
      houseRegisteredSqmArea,
      registeredSqmHouseRegisteredAreaWeightedValue,
      houseRegisteredSqmAreaAdjusted,
      houseBalconyOpenRegisteredSqmArea,
      registeredSqmOpenBalconyWeightedValue,
      registeredSqmOpenBalconyAreaAdjusted,
      houseBalconyClosedRegisteredSqmArea,
      registeredSqmClosedBalconyWeightedValue,
      registeredSqmClosedBalconyAreaAdjusted,
      houseYardRegisteredSqmArea,
      registeredSqmYardWeightedValue,
      registeredSqmYardAreaAdjusted,
      houseRoofRegisteredSqmArea,
      registeredSqmRoofWeightedValue,
      registeredSqmRoofAreaAdjusted,
      houseStorageRegisteredSqmArea,
      registeredSqmStorageWeightedValue,
      registeredSqmStorageAreaAdjusted,
      registeredSqmTotalAdjustedArea,
      registeredSqmAverageUpperBorderPriceAdjusted,
      estimatePriceMethod3,
      permitSqmFinalWeightedScale,
      registeredRoomFinalWeightedScale,
      registeredSqmFinalWeightedScale,
      estimatePriceFinal,
      houseJointHomeRegistered
    },
  } = context.data;

  return (
    <table
      className={classnames(
        styles.simpleTable,
        styles.autoWidth,
        styles.noCellBorders,
        customClassNames.container
      )}
    >
      <thead>
      <tr className={styles.greenBg}>
        <th
          className={styles.textAlignRight}
          colSpan={4}
        >
          אומדן שווי
        </th>
      </tr>
      </thead>
      <tbody>

      {/* part 1 */}
      <tr>
        <td/>
        <td>שטח דירה ברישוי</td>
        <td>מקדם אקוו'</td>
        <td>שטח אקוו'</td>
      </tr>
      <tr>
        <td data-testid="housePermitAreaLabel">
          {getLabelByBalconyAndStorage(housePermitAreaIncludeBalcony, housePermitAreaIncludeStorage, permitAreaLabels)}
        </td>
        <td>{housePermitArea}</td>
        <td>{housePermitAreaWeightedValue}</td>
        <td>{housePermitAreaAdjusted}</td>
      </tr>
      {Boolean(permitSqmOpenBalconyAreaAdjusted) && (
        <tr>
          <td>מרפסות פתוחות</td>
          <td>{houseBalconyOpenPermitArea}</td>
          <td>{permitSqmOpenBalconyWeightedValue}</td>
          <td>{permitSqmOpenBalconyAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(permitSqmClosedBalconyAreaAdjusted) && (
        <tr>
          <td>מרפסות סגורות</td>
          <td>{houseBalconyClosedPermitArea}</td>
          <td>{permitSqmClosedBalconyWeightedValue}</td>
          <td>{permitSqmClosedBalconyAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(permitSqmYardAreaAdjusted) && (
        <tr>
          <td>חצר</td>
          <td>{houseYardPermitArea}</td>
          <td>{permitSqmYardWeightedValue}</td>
          <td>{permitSqmYardAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(permitSqmRoofAreaAdjusted) && (
        <tr>
          <td>גג</td>
          <td>{houseRoofPermitArea}</td>
          <td>{permitSqmRoofWeightedValue}</td>
          <td>{permitSqmRoofAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(permitSqmStorageAreaAdjusted) && (
        <tr>
          <td>מחסן</td>
          <td>{houseStoragePermitArea}</td>
          <td>{permitSqmStorageWeightedValue}</td>
          <td>{permitSqmStorageAreaAdjusted}</td>
        </tr>
      )}
      <tr>
        <td>סה"כ</td>
        <td/>
        <td/>
        <td>{permitSqmTotalAdjustedArea}</td>
      </tr>
      <tr>
        <td/>
        <td>שווי מ"ר דירתי</td>
        <td/>
        <td>{formattingPrice(permitSqmAveragePriceAdjusted)}</td>
      </tr>
      <tr className={styles.bold}>
        <td/>
        <td>שווי מתואם</td>
        <td/>
        <td>{formattingPrice(estimatePriceMethod1)}</td>
      </tr>

      {/* part 2 */}
      <tr>
        <td/>
        <td>שטח דירה ברישוי</td>
        <td>מקדם אקוו'</td>
        <td>שטח אקוו'</td>
      </tr>
      <tr>
        <td data-testid="houseRegisteredAreaLabel">
          {getLabelByBalconyAndStorage(houseRegisteredAreaIncludeBalcony, houseRegisteredAreaIncludeStorage, registeredAreaSqmLabels)}
        </td>
        <td>{houseRegisteredArea || ''}</td>
        <td>{registeredRoomHouseRegisteredAreaWeightedValue}</td>
        <td>{houseRegisteredAreaAdjusted}</td>
      </tr>
      {Boolean(registeredRoomOpenBalconyAreaAdjusted) && (
        <tr>
          <td>מרפסות פתוחות</td>
          <td>{houseBalconyOpenRegisteredArea}</td>
          <td>{registeredRoomOpenBalconyWeightedValue}</td>
          <td>{registeredRoomOpenBalconyAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredRoomClosedBalconyAreaAdjusted) && (
        <tr>
          <td>מרפסות סגורות</td>
          <td>{houseBalconyClosedRegisteredArea}</td>
          <td>{registeredRoomClosedBalconyWeightedValue}</td>
          <td>{registeredRoomClosedBalconyAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredRoomYardAreaAdjusted) && (
        <tr>
          <td>חצר</td>
          <td>{houseYardRegisteredArea}</td>
          <td>{registeredRoomYardWeightedValue}</td>
          <td>{registeredRoomYardAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredRoomRoofAreaAdjusted) && (
        <tr>
          <td>גג</td>
          <td>{houseRoofRegisteredArea}</td>
          <td>{registeredRoomRoofWeightedValue}</td>
          <td>{registeredRoomRoofAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredRoomStorageAreaAdjusted) && (
        <tr>
          <td>מחסן</td>
          <td>{houseStorageRegisteredArea}</td>
          <td>{registeredRoomStorageWeightedValue}</td>
          <td>{registeredRoomStorageAreaAdjusted}</td>
        </tr>
      )}
      <tr>
        <td>סה"כ</td>
        <td/>
        <td/>
        <td>{registeredRoomTotalAdjustedArea}</td>
      </tr>
      <tr>
        <td/>
        <td>חדר ממוצע</td>
        <td/>
        <td>{averageRoomSq}</td>
      </tr>
      <tr>
        <td/>
        <td>שווי חדר ממוצע</td>
        <td/>
        <td>{formattingPrice(roomAdjustedAveragePrice)}</td>
      </tr>
      <tr className={styles.bold}>
        <td/>
        <td>שווי מתואם</td>
        <td/>
        <td>{formattingPrice(estimatePriceMethod2)}</td>
      </tr>

      {/* part 3 */}
      <tr>
        <td/>
        <td>שטח דירה ברישוי</td>
        <td>מקדם אקוו'</td>
        <td>שטח אקוו'</td>
      </tr>
      <tr>
        <td data-testid="houseRegisteredSqmLabel">{
          getLabelByBalconyAndStorage(houseRegisteredSqmAreaIncludeBalcony, houseRegisteredSqmAreaIncludeStorage, registeredAreaSqmLabels)}
        </td>
        <td>{houseRegisteredSqmArea}</td>
        <td>{registeredSqmHouseRegisteredAreaWeightedValue}</td>
        <td>{houseRegisteredSqmAreaAdjusted}</td>
      </tr>
      {Boolean(registeredSqmOpenBalconyAreaAdjusted) && (
        <tr>
          <td>מרפסות פתוחות</td>
          <td>{houseBalconyOpenRegisteredSqmArea}</td>
          <td>{registeredSqmOpenBalconyWeightedValue}</td>
          <td>{registeredSqmOpenBalconyAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredSqmClosedBalconyAreaAdjusted) && (
        <tr>
          <td>מרפסות סגורות</td>
          <td>{houseBalconyClosedRegisteredSqmArea}</td>
          <td>{registeredSqmClosedBalconyWeightedValue}</td>
          <td>{registeredSqmClosedBalconyAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredSqmYardAreaAdjusted) && (
        <tr>
          <td>חצר</td>
          <td>{houseYardRegisteredSqmArea}</td>
          <td>{registeredSqmYardWeightedValue}</td>
          <td>{registeredSqmYardAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredSqmRoofAreaAdjusted) && (
        <tr>
          <td>גג</td>
          <td>{houseRoofRegisteredSqmArea}</td>
          <td>{registeredSqmRoofWeightedValue}</td>
          <td>{registeredSqmRoofAreaAdjusted}</td>
        </tr>
      )}
      {Boolean(registeredSqmStorageAreaAdjusted) && (
        <tr>
          <td>מחסן</td>
          <td>{houseStorageRegisteredSqmArea}</td>
          <td>{registeredSqmStorageWeightedValue}</td>
          <td>{registeredSqmStorageAreaAdjusted}</td>
        </tr>
      )}
      <tr>
        <td>סה"כ</td>
        <td/>
        <td/>
        <td>{registeredSqmTotalAdjustedArea}</td>
      </tr>
      <tr>
        <td/>
        <td>שווי מ"ר רשום</td>
        <td/>
        {houseJointHomeRegistered === 2
          ? <td/>
          : <td>{formattingPrice(registeredSqmAverageUpperBorderPriceAdjusted)}</td>
        }
      </tr>
      <tr className={styles.bold}>
        <td/>
        <td>שווי מתואם</td>
        <td/>
        <td>{formattingPrice(estimatePriceMethod3)}</td>
      </tr>

      {/*part 4*/}
      <tr>
        <td/>
        <td>שקלול</td>
        <td>מקדם</td>
        <td/>
      </tr>
      <tr>
        <td/>
        <td>{formattingPrice(estimatePriceMethod1)}</td>
        <td>{permitSqmFinalWeightedScale}</td>
        <td/>
      </tr>
      <tr>
        <td/>
        <td>{formattingPrice(estimatePriceMethod2)}</td>
        <td>{registeredRoomFinalWeightedScale}</td>
        <td/>
      </tr>
      <tr>
        <td/>
        <td>{formattingPrice(estimatePriceMethod3)}</td>
        <td>{registeredSqmFinalWeightedScale}</td>
        <td/>
      </tr>
      </tbody>

      <tfoot>
      <tr className={classnames(styles.bold, styles.greenBg)}>
        <td>
          שווי מתואם
        </td>
        <td className={styles.textAlignLeft} colSpan={3}>
          {formattingPrice(estimatePriceFinal, true)}
        </td>
      </tr>
      </tfoot>
    </table>
  );
};

TableCalculations.propTypes = {
  customClassNames: PropTypes.shape({
    container: PropTypes.string,
  })
};

export default TableCalculations;

import axios from 'axios';

export const GET_FILE = '/files/getFile';

export const getFile = ({shumaId, fileID}) => {
  const data = {
    shumaID: parseInt(shumaId, 10),
    fileID: parseInt(fileID, 10),
  };

  return axios.post(GET_FILE, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error;

      return body.file;
    });
};

import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import {useLocation} from 'react-router';
import moment from 'moment';
import * as actions from '../_redux/actions/OrderDetailsActions';
import {checkEmailIsValid} from '../../_common/utils/checkEmailIsValid';
import {getArrayFromString} from '../../_common/utils/getArrayFromString';
import {useOrderDetailsUIGlobalContext} from './OrderDetailsUIGlobalContext';
import {
  showGlobalMessage
} from '../../_common/components/GlobalMessage/actions/showGlobalMessage';
import {filterOptions} from '../../_common/utils/filterOptions';
import {getStatusList} from "../../_common/components/StatusList/actions/StatusListActions";

const OrderDetailsUIOrderContext = createContext('');

export function useOrderDetailsUIOrderContext() {
  return useContext(OrderDetailsUIOrderContext);
}

export function OrderDetailsUIOrderProvider({children}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    orderID,
    textContent,
    users,
  } = useOrderDetailsUIGlobalContext();
  const [inspectionsCount, setInspectionsCount] = useState(0);
  const [oldInspectionsForms, setOldInspectionForms] = useState(false);
  const toggleOldInspectionForms = () => setOldInspectionForms(prevState => !prevState);

  const {orderDetails, orderDetailsLoading} = useSelector(
    (state) => ({
      orderDetails: state.orderDetailsReducer.orderDetails,
      orderDetailsLoading: state.orderDetailsReducer.orderDetailsLoading,
    }),
    shallowEqual
  );

  const linkedPriceQuoteID = Boolean(location.state) ? location.state[0] : null;
  const linkedCustomers = Boolean(location.state) && Boolean(location.state[1]) ? location.state[1] : [];

  useEffect(() => {
    if (orderID !== -1) {
      setOldInspectionForms(location.state?.oldInspectionsForms);
      updateOrder(orderID)
        .catch(console.error);
    }
  }, [orderID]);

  useEffect(() => {
    if (location.state?.oldInspectionsForms) {
      setOldInspectionForms(location.state?.oldInspectionsForms);
    } else if (orderDetails?.inspections) {
      const oldInspectionCount = orderDetails.inspections
        .filter(item => item?.originTypeID === 2)
        .length;
      setOldInspectionForms(oldInspectionCount > 0);
    }
  }, [orderDetails]);

  useEffect(() => {
    dispatch(getStatusList());
  }, []);

  const updateOrder = async (orderID) => {
    await dispatch(actions.getOrderByID(orderID));
  };

  const {
    customers,
    usingLawyer,
    lawyerName,
    contactFullName,
    contactPhone,
    contactJob,
    contactOrganisation,
    contactEmail,
    contactAddress,
    shumaForOrderCount,
    dueDate,
    responsibleUser,
    orderStatus
  } = orderDetails;

  const initialTabOrderGeneralValuesAddOrder = {
    customers: linkedCustomers || [],
    usingLawyer: false,
    lawyerName: '',
    contactFullName: '',
    contactPhone: '',
    contactJob: '',
    contactOrganisation: '',
    contactEmail: '',
    contactAddress: '',
    shumaForOrderCount: '',
    dueDate: null,
    responsibleUser: null,
    priceQuoteID: linkedPriceQuoteID || null
  };
  const initialTabOrderGeneralValuesEditOrder = {
    customers: getArrayFromString(customers) || [],
    usingLawyer: Boolean(usingLawyer) || false,
    lawyerName: lawyerName || '',
    contactFullName: contactFullName || '',
    contactPhone: contactPhone || '',
    contactJob: contactJob || '',
    contactOrganisation: contactOrganisation || '',
    contactEmail: contactEmail || '',
    contactAddress: contactAddress || '',
    shumaForOrderCount: shumaForOrderCount || '',
    dueDate: dueDate ? new Date(dueDate) : null,
    responsibleUser: (responsibleUser && users.length > 0)
      ? filterOptions(responsibleUser)(users)[0] : null,
    priceQuoteID: null
  };
  const initialShumaForOrderCount = shumaForOrderCount;

  const validate = (values) => {
    let errors = {};
    const {customers, shumaForOrderCount, contactEmail} = values;

    if (customers.length < 1) {
      errors['customers'] = 'Must be at least one customer!';
    }
    if (!shumaForOrderCount) {
      errors['shumaForOrderCount'] = 'Must have value!';
    }
    if (contactEmail && !checkEmailIsValid(contactEmail)) {
      errors['contactEmail'] = 'Invalid email address';
    }
    if (orderID !== -1) {
      if (parseInt(initialShumaForOrderCount, 10) > parseInt(shumaForOrderCount, 10)) {
        errors['shumaForOrderCount'] = 'Can\'t be less than previous value';
      }
    }

    return errors;
  };

  const handleCreateOrder = async (data) => {
    const {
      usingLawyer,
      lawyerName,
      shumaForOrderCount,
      customers,
      dueDate,
      responsibleUser,
      priceQuoteID,
      ...rest
    } = data;
    const mappedCustomersValue = customers.map(item => parseInt(item.customerID, 10));

    const editedData = {
      orderID: orderID,
      customers: mappedCustomersValue,
      shumaForOrderCount: shumaForOrderCount,
      usingLawyer: usingLawyer,
      lawyerName: usingLawyer ? lawyerName : '',
      dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : '',
      responsibleUser: responsibleUser ? responsibleUser.value : null,
      priceQuoteID: priceQuoteID || null,
      ...rest
    };
    setInspectionsCount(shumaForOrderCount);

    try {

      const orderID = await dispatch(actions.createOrder(editedData));
      await updateOrder(orderID);
      await dispatch(showGlobalMessage(textContent.successMessage));
      history.push(`/order-details/${orderID}`, {oldInspectionsForms});
    } catch (e) {
      console.error(`Can\'t create order, ${e}`);
    }
  };

  const formikOrderGeneral = useFormik({
    initialValues: orderID !== -1
      ? {...initialTabOrderGeneralValuesEditOrder}
      : {...initialTabOrderGeneralValuesAddOrder},
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const value = {
    nextTabsEnabled: orderID !== -1,
    shumaForOrderCount,
    orderDetailsLoading,
    handleCreateOrder,
    formikOrderGeneral,
    inspectionsCount,
    updateOrder,
    oldInspectionsForms,
    toggleOldInspectionForms,
    orderStatus
  };

  return (
    <OrderDetailsUIOrderContext.Provider value={value}>
      {children}
    </OrderDetailsUIOrderContext.Provider>
  );
}

OrderDetailsUIOrderProvider.propTypes = {
  children: PropTypes.node,
};

import React, {createRef} from 'react';
import classnames from 'classnames';
import ShumaType1DocTitle from './content/ShumaType1DocTitle';
import ShumaType2DocTitle from './content/ShumaType2DocTitle';
import DocProps from '../DocProps';
import DocumentCodeAndTimestamp from '../DocumentCodeAndTimestamp';
import ShumaType1Title from './content/ShumaType1Title';
import ShumaType2Title from './content/ShumaType2Title';
import ShumaType3Title from './content/ShumaType3Title';
import ShumaType1GreenBox from './content/ShumaType1GreenBox';
import ShumaType2and3GreenBox from './content/ShumaType2and3GreenBox';
import ShumaType1Section1 from './content/ShumaType1Section1';
import ShumaType2Section1 from './content/ShumaType2Section1';
import ShumaType3Section1 from './content/ShumaType3Section1';
import Section2Title from './content/Section2Title';
import ShumaType1and2Section2Text from './content/ShumaType1and2Section2Text';
import ShumaType3Section2Text from './content/ShumaType3Section2Text';
import ShumaType1and2PlansCheckDate from './content/ShumaType1and2PlansCheckDate';
import Section3 from './content/Section3';
import ShumaType1and2Table from './content/ShumaType1and2Table';
import ShumaType3Table from './content/ShumaType3Table';
import {getShumaType2and3MortgageEntityEmphasis} from './content/ShumaType2and3MortgageEntityEmphasis';
import Section5Title from './content/Section5Title';
import Section5TextContent, {
  FacingText,
  getAreaFeatures,
  getAreaLimitText,
  getEnvDevelopmentLevel,
} from './content/Section5TextContent';
import MapImageWithText from './content/MapImageWithText';
import Section6Title from './content/Section6Title';
import Section6Text1 from './content/Section6Text1';
import ShumaType1and2Section6Text1 from './content/ShumaType1and2Section6Text1';
import Section6Text2 from './content/Section6Text2';
import {getAdjacentStreets} from './content/SectionAdjacentStreets';
import SatelliteImageWithText from './content/SatelliteImageWithText';
import SchemeImageWithText from './content/SchemeImageWithText';
import SelectedLandsTexts from './content/SelectedLandsTexts';
import Section6Text71 from './content/Section6Text71';
import ShumaType3Line1 from './content/ShumaType3Line1';
import ShumaType3Line2 from './content/ShumaType3Line2';
import Section6Text72 from './content/Section6Text72';
import Section6Text73 from './content/Section6Text73';
import Section6Text74 from './content/Section6Text74';
import Section6Text75 from './content/Section6Text75';
import Section6Text76 from './content/Section6Text76';
import {getSection6Text77} from './content/Section6Text77';
import Section6Text78 from './content/Section6Text78';
import {getSection6Text79} from './content/Section6Text79';
import ShumaType3HouseMatchingBuilding from './content/ShumaType3HouseMatchingBuilding';
import Section6Text710 from './content/Section6Text710';
import Section6Text711 from './content/Section6Text711';
import Section6Text712 from './content/Section6Text712';
import Section6Text713 from './content/Section6Text713';
import {getSection6Text714} from './content/Section6Text714';
import Section6HouseAttributes from './content/Section6HouseAttributes';
import Page6TextContentItems1and2 from './content/Page6TextContentItems1and2';
import Page6TextContentItemsFrom4to14 from './content/Page6TextContentItemsFrom4to14';
import Page6TextContentItem16 from './content/Page6TextContentItem16';
import TextItem19 from './content/Page6TextContentItem19';
import ShumaType1BuildingPopulatedText from './content/ShumaType1BuildingPopulatedText';
import ShumaType2and3BuildingPopulatedText from './content/ShumaType2and3BuildingPopulatedText';
import ShumaType1BuildingRoofTypeText from './content/ShumaType1BuildingRoofTypeText';
import ShumaType2and3BuildingRoofTypeText from './content/ShumaType2and3BuildingRoofTypeText';
import ShumaType3BuildingMatchingEnvironment from './content/ShumaType3BuildingMatchingEnvironment';
import ShumaType1and2BuildingPermit from './content/ShumaType1and2BuildingPermit';
import ShumaType3BuildingPermit from './content/ShumaType3BuildingPermit';
import GoogleViewImageWithLabel from './content/GoogleViewImageWithLabel';
import {getTypicalFloorPlanFiles} from './content/TypicalFloorPlanFiles';
import {getPropertyIdentification} from './content/PropertyIdentification';
import {getFloors} from './content/Floors';
import {getSection7} from './content/Section7';
import getValidLocalPlansDetailsSection from './content/ValidLocalPlansDetailsSection';
import getCountryStateDataSection from './content/CountryStateDataSection';
import getPlansInProgressSection from './content/PlansInProgressSection';
import {getLicensingSection} from './content/LicensingSection';
import {getSupervisionSection} from './content/SupervisionSection';
import {getCompleteCert, getDangerousStructure, getForm4} from './content/Page19AttachedFiles';
import {getSection8} from './content/Section8';
import {getStep5MediaFiles} from './content/Step5MediaFiles';
import {getNotes} from './content/Notes';
import {getShumaType1Section9} from './content/ShumaType1Section9';
import {getShumaType2Section9} from './content/ShumaType2Section9';
import {getShumaType3Section9} from './content/ShumaType3Section9';
import {getShumaType2and3FilesList} from './content/ShumaType2and3FilesList';
import {getSelectedRowsAndColumnsTables} from './content/SelectedRowsAndColumnsTables';
import Shuma1and2CalculationTables from './content/Shuma1and2CalculationTables';
import ShumaType1Section10Title from './content/ShumaType1Section10Title';
import ShumaType2Section10Title from './content/ShumaType2Section10Title';
import ShumaType3Section10Title from './content/ShumaType3Section10Title';
import ShumaType1Section10Subtitle from './content/ShumaType1Section10Subtitle';
import Section10Text from './content/Section10Text';
import ShumaType1FinalPrice from './content/ShumaType1FinalPrice';
import ShumaType2FinalPrice from './content/ShumaType2FinalPrice';
import ShumaType3FinalPrice from './content/ShumaType3FinalPrice';
import {getCalculationNotesText} from './content/ShumaType2and3CalculationNotesText';
import ShumaType1GrayBox from './content/ShumaType1GrayBox';
import ShumaType2GrayBox from './content/ShumaType2GrayBox';
import ShumaType3GrayBox from './content/ShumaType3GrayBox';
import Signature from './content/Signature';
import ShumaType2LastPage from './content/ShumaType2LastPage';
import {
  CommentBettermentTax,
  CommentBuildingPhysicalStateValue,
  CommentBuildPermitNotSigned,
  CommentEstimateText,
  CommentHousePhysicalStateValue,
  CommentLandShapeValueText,
  CommentPermitAndExistingAreaComparisonText,
  CommentPriceDelta,
  CommentPropertyBuildCostEstimate,
  CommentRamiCost,
  CommentRealizeCost,
  CommentRegisteredArea,
  CommentSection7,
  CommentSurveyArea
} from './content/Comments';
import {findFileByTypeId} from '../../utils/files';
import styles from './styles.module.scss';

let i = 0;

const mapItem = ({refs, id, getClassName}) => (item, index, array) => {
  const Component = React.forwardRef(({}, ref) => (
    <div
      ref={ref}
      data-testid={id}
      className={Boolean(getClassName) ? getClassName({index, array}) : ''}
    >
      {item}
    </div>
  ));

  return <Component ref={addRef(refs)} key={i++}/>;
};

export const getContentAndRefs = (context) => {
  let refs = [];
  const assets = getAssets(context.shumaTypeID);
  const content = [
    <assets.docTitle ref={addRef(refs)} key={i++}/>,
    <assets.docProps ref={addRef(refs)} key={i++}/>,
    <assets.docCode ref={addRef(refs)} key={i++}/>,
    <assets.titleText ref={addRef(refs)} key={i++}/>,
    <assets.greenBox ref={addRef(refs)} key={i++}/>,
    <assets.section1 ref={addRef(refs)} key={i++}/>,
    <Section2Title ref={addRef(refs)} key={i++}/>,
    <assets.section2Text ref={addRef(refs)} key={i++}/>,
    <assets.section2PlansCheckDate ref={addRef(refs)} key={i++}/>,
    <Section3 ref={addRef(refs)} key={i++}/>,
    <assets.section4 ref={addRef(refs)} key={i++}/>,
    ...assets.section4MortgageEntityEmphasis(context).map(mapItem({
      refs,
      id: 'section4MortgageEntityEmphasis'
    })),
    <Section5Title ref={addRef(refs)} key={i++}/>,
    <FacingText ref={addRef(refs)} key={i++}/>,
    ...getAreaLimitText(context).map(mapItem({
      refs,
      id: 'areaLimit'
    })),
    ...getAreaFeatures(context).map(mapItem({
      refs,
      id: 'areaFeatures'
    })),
    ...Section5TextContent.map((TextItem) => (
      <TextItem ref={addRef(refs)} key={i++}/>
    )),
    ...getEnvDevelopmentLevel(context).map(mapItem({
      refs,
      id: 'envDevelopmentLevel'
    })),
    ...getAdjacentStreets(context).map(mapItem({
      refs,
      id: 'adjacentStreets'
    })),
    <MapImageWithText ref={addRef(refs)} key={i++}/>,
    <Section6Title ref={addRef(refs)} key={i++}/>,
    <Section6Text1 ref={addRef(refs)} key={i++}/>,
    <assets.section6Text ref={addRef(refs)} key={i++}/>,
    <Section6Text2 ref={addRef(refs)} key={i++}/>,
    <assets.satelliteImage ref={addRef(refs)} key={i++}/>,
    <assets.schemeImage ref={addRef(refs)} key={i++}/>,
    ...SelectedLandsTexts.map((LandTextItem) => (
      <LandTextItem ref={addRef(refs)} key={i++}/>
    )),
    ...Page6TextContentItems1and2.map((TextItem) => (
      <TextItem ref={addRef(refs)} key={i++}/>
    )),
    <assets.buildingPopulatedText ref={addRef(refs)} key={i++}/>,
    ...Page6TextContentItemsFrom4to14.map((TextItem) => (
      <TextItem ref={addRef(refs)} key={i++}/>
    )),
    <assets.buildingRoofTypeText ref={addRef(refs)} key={i++}/>,
    ...Page6TextContentItem16.map((TextItem) => (
      <TextItem ref={addRef(refs)} key={i++}/>
    )),
    <assets.buildingMatchingEnvironment ref={addRef(refs)} key={i++}/>,
    <assets.buildingPermit ref={addRef(refs)} key={i++}/>,
    <TextItem19 ref={addRef(refs)} key={i++}/>,
    <GoogleViewImageWithLabel ref={addRef(refs)} key={i++}/>,
    <Section6Text71 ref={addRef(refs)} key={i++}/>,
    <assets.page7line1 ref={addRef(refs)} key={i++}/>,
    <assets.page7line2 ref={addRef(refs)} key={i++}/>,
    <Section6Text72 ref={addRef(refs)} key={i++}/>,
    <Section6Text73 ref={addRef(refs)} key={i++}/>,
    <Section6Text74 ref={addRef(refs)} key={i++}/>,
    <Section6Text75 ref={addRef(refs)} key={i++}/>,
    <Section6Text76 ref={addRef(refs)} key={i++}/>,
    ...getSection6Text77(context).map(mapItem({
      refs,
      id: 'housePermitArea'
    })),
    <Section6Text78 ref={addRef(refs)} key={i++}/>,
    ...getSection6Text79(context).map(mapItem({
      refs,
      id: 'houseMaintenanceStatusText'
    })),
    <Section6Text710 ref={addRef(refs)} key={i++}/>,
    <Section6Text711 ref={addRef(refs)} key={i++}/>,
    <Section6Text712 ref={addRef(refs)} key={i++}/>,
    <assets.houseMatchingBuilding ref={addRef(refs)} key={i++}/>,
    <Section6Text713 ref={addRef(refs)} key={i++}/>,
    ...getSection6Text714(context).map(mapItem({
      refs,
      id: 'houseFunctionalDivide'
    })),
    ...Section6HouseAttributes.map((Item) => (
      <Item ref={addRef(refs)} key={i++}/>
    )),
    ...getTypicalFloorPlanFiles(context).map(mapItem({
      refs,
      id: 'getTypicalFloorPlanFiles'
    })),
    ...getPropertyIdentification(context).map(mapItem({
      refs,
      id: 'getPropertyIdentification',
      getClassName: ({index}) => classnames(styles.pageHeight, {
        [styles.smallerHeight]: index === 0,
      })
    })),
    ...getFloors(context).map(mapItem({
      refs,
      id: 'getFloors',
      getClassName: ({index, array}) => classnames({
        [styles.mb5]: index === array.length - 1
      })
    })),
    ...getSection7(context).map((page, index) => {
      const fileTypeID = 14;
      const file = findFileByTypeId(context.files, fileTypeID);
      const Component = React.forwardRef(({}, ref) => (
        <div
          ref={ref}
          data-testid={'getSection7page'}
          className={classnames({
            [styles.pageHeight]: Boolean(file),
            [styles.smallerHeight]: index === 0,
          })}
        >
          {page}
        </div>
      ));

      return <Component ref={addRef(refs)} key={i++}/>;
    }),
    ...getValidLocalPlansDetailsSection(context).map(mapItem({
      refs,
      id: 'getValidLocalPlansDetailsSection'
    })),
    ...getCountryStateDataSection(context).map(mapItem({
      refs,
      id: 'getCountryStateDataSection'
    })),
    ...getPlansInProgressSection(context).map(mapItem({
      refs,
      id: 'getPlansInProgressSection'
    })),
    ...getLicensingSection(context).map(mapItem({
      refs,
      id: 'LicensingSection',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...getSupervisionSection(context).map(mapItem({
      refs,
      id: 'SupervisionSection',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...getForm4(context).map(mapItem({
      refs,
      id: 'getForm4'
    })),
    ...getCompleteCert(context).map(mapItem({
      refs,
      id: 'getCompleteCert',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...getDangerousStructure(context).map(mapItem({
      refs,
      id: 'getDangerousStructure',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...getSection8(context).map((page, index) => {
      const fileTypeID = 1;
      const file = findFileByTypeId(context.files, fileTypeID);
      const Component = React.forwardRef(({}, ref) => (
        <div
          ref={ref}
          data-testid={'getSection8page'}
          className={classnames({
            [styles.pageHeight]: Boolean(file),
            [styles.smallerHeight720]: index === 0
          })}
        >
          {page}
        </div>
      ));

      return <Component ref={addRef(refs)} key={i++}/>;
    }),
    ...getStep5MediaFiles(context).map(mapItem({
      refs,
      id: 'getStep5MediaFiles',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...assets.notes(context).map(mapItem({
      refs,
      id: 'notes',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...assets.filesList(context).map(mapItem({
      refs,
      id: 'filesList',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...assets.section9Refactored(context).map(mapItem({
      refs,
      id: 'section9',
      getClassName: () => classnames(styles.pageHeight)
    })),
    ...assets.selectedRowsAndColumnsTables(context).map(mapItem({
      refs,
      id: 'selectedRowsAndColumnsTables',
      getClassName: () => classnames(styles.pageHeight)
    })),
    <assets.calculationTables ref={addRef(refs)} key={i++}/>,
    <assets.section10Title ref={addRef(refs)} key={i++}/>,
    <assets.section10Subtitle ref={addRef(refs)} key={i++}/>,
    <Section10Text ref={addRef(refs)} key={i++}/>,
    <assets.finalPrice ref={addRef(refs)} key={i++}/>,
    ...assets.calculationNotesText(context).map(mapItem({
      refs,
      id: 'calculationNotesText',
    })),
    <assets.greyBox ref={addRef(refs)} key={i++}/>,
    <Signature ref={addRef(refs)} key={i++}/>,
    <assets.lastPage ref={addRef(refs)} key={i++}/>,
  ];

  let commentRefs = {};
  const commentsContent = {
    registeredArea: (
      <CommentRegisteredArea
        ref={addNoteRef(commentRefs, 'registeredArea')}
        key={i++}
      />
    ),
    surveyArea: (
      <CommentSurveyArea
        ref={addNoteRef(commentRefs, 'surveyArea')}
        key={i++}
      />
    ),
    landShapeValueText: (
      <CommentLandShapeValueText
        ref={addNoteRef(commentRefs, 'landShapeValueText')}
        key={i++}
      />
    ),
    buildingPhysicalStateValue: (
      <CommentBuildingPhysicalStateValue
        ref={addNoteRef(commentRefs, 'buildingPhysicalStateValue')}
        key={i++}
      />
    ),
    buildPermitNotSigned: (
      <CommentBuildPermitNotSigned
        ref={addNoteRef(commentRefs, 'buildPermitNotSigned')}
        key={i++}/>
    ),
    permitAndExistingAreaComparisonText:
      (
        <CommentPermitAndExistingAreaComparisonText
          ref={addNoteRef(commentRefs, 'permitAndExistingAreaComparisonText')}
          key={i++}
        />
      ),
    housePhysicalStateValue: (
      <CommentHousePhysicalStateValue
        ref={addNoteRef(commentRefs, 'housePhysicalStateValue')}
        key={i++}
      />
    ),
    section7: (
      <CommentSection7
        ref={addNoteRef(commentRefs, 'section7')}
        key={i++}
      />
    ),
    estimateText: (
      <CommentEstimateText
        ref={addNoteRef(commentRefs, 'estimateText')}
        key={i++}
      />
    ),
    propertyBuildCostEstimate: (
      <CommentPropertyBuildCostEstimate
        ref={addNoteRef(commentRefs, 'propertyBuildCostEstimate')}
        key={i++}
      />
    ),
    priceDelta: (
      <CommentPriceDelta
        ref={addNoteRef(commentRefs, 'priceDelta')}
        key={i++}
      />
    ),
    bettermentTax: (
      <CommentBettermentTax
        ref={addNoteRef(commentRefs, 'bettermentTax')}
        key={i++}
      />
    ),
    ramiCost: (
      <CommentRamiCost
        ref={addNoteRef(commentRefs, 'ramiCost')}
        key={i++}
      />
    ),
    realizeCost: (
      <CommentRealizeCost
        ref={addNoteRef(commentRefs, 'realizeCost')}
        key={i++}
      />
    ),
  };

  return {content, refs, commentsContent, commentRefs};
};

const getAssets = shumaTypeID => {
  switch (shumaTypeID) {
    case 3:
      return {
        docTitle: EmptyEntity,
        docProps: React.forwardRef(({}, ref) => (
          <DocProps ref={ref}/>
        )),
        docCode: EmptyEntity,
        titleText: React.forwardRef(({}, ref) => (
          <ShumaType3Title ref={ref}/>
        )),
        greenBox: React.forwardRef(({}, ref) => (
          <ShumaType2and3GreenBox ref={ref}/>
        )),
        section1: React.forwardRef(({}, ref) => (
          <ShumaType3Section1 ref={ref}/>
        )),
        section2Text: React.forwardRef(({}, ref) => (
          <ShumaType3Section2Text ref={ref}/>
        )),
        section2PlansCheckDate: EmptyEntity,
        section4: React.forwardRef(({}, ref) => (
          <ShumaType3Table ref={ref}/>
        )),
        section4MortgageEntityEmphasis: context => getShumaType2and3MortgageEntityEmphasis(context),
        section6Text: EmptyEntity,
        satelliteImage: EmptyEntity,
        schemeImage: EmptyEntity,
        page7line1: React.forwardRef(({}, ref) => (
          <ShumaType3Line1 ref={ref}/>
        )),
        page7line2: React.forwardRef(({}, ref) => (
          <ShumaType3Line2 ref={ref}/>
        )),
        houseMatchingBuilding: React.forwardRef(({}, ref) => (
          <ShumaType3HouseMatchingBuilding ref={ref}/>
        )),
        buildingPopulatedText: React.forwardRef(({}, ref) => (
          <ShumaType2and3BuildingPopulatedText ref={ref}/>
        )),
        buildingRoofTypeText: React.forwardRef(({}, ref) => (
          <ShumaType2and3BuildingRoofTypeText ref={ref}/>
        )),
        buildingMatchingEnvironment: React.forwardRef(({}, ref) => (
          <ShumaType3BuildingMatchingEnvironment ref={ref}/>
        )),
        buildingPermit: React.forwardRef(({}, ref) => (
          <ShumaType3BuildingPermit ref={ref}/>
        )),
        notes: () => [],
        section9Refactored: context => getShumaType3Section9(context),
        filesList: context => getShumaType2and3FilesList(context),
        selectedRowsAndColumnsTables: () => [],
        calculationTables: EmptyEntity,
        section10Title: React.forwardRef(({}, ref) => (
          <ShumaType3Section10Title ref={ref}/>
        )),
        section10Subtitle: EmptyEntity,
        finalPrice: React.forwardRef(({}, ref) => (
          <ShumaType3FinalPrice ref={ref}/>
        )),
        calculationNotesText: context => getCalculationNotesText(context),
        greyBox: React.forwardRef(({}, ref) => (
          <ShumaType3GrayBox ref={ref}/>
        )),
        lastPage: EmptyEntity,
      };
    case 2:
      return {
        docTitle: React.forwardRef(({}, ref) => (
          <ShumaType2DocTitle ref={ref}/>
        )),
        docProps: React.forwardRef(({}, ref) => (
          <DocProps ref={ref}/>
        )),
        docCode: EmptyEntity,
        titleText: React.forwardRef(({}, ref) => (
          <ShumaType2Title ref={ref}/>
        )),
        greenBox: React.forwardRef(({}, ref) => (
          <ShumaType2and3GreenBox ref={ref}/>
        )),
        section1: React.forwardRef(({}, ref) => (
          <ShumaType2Section1 ref={ref}/>
        )),
        section2Text: React.forwardRef(({}, ref) => (
          <ShumaType1and2Section2Text ref={ref}/>
        )),
        section2PlansCheckDate: React.forwardRef(({}, ref) => (
          <ShumaType1and2PlansCheckDate ref={ref}/>
        )),
        section4: React.forwardRef(({}, ref) => (
          <ShumaType1and2Table ref={ref}/>
        )),
        section4MortgageEntityEmphasis: context => getShumaType2and3MortgageEntityEmphasis(context),
        section6Text: React.forwardRef(({}, ref) => (
          <ShumaType1and2Section6Text1 ref={ref}/>
        )),
        satelliteImage: React.forwardRef(({}, ref) => (
          <SatelliteImageWithText ref={ref}/>
        )),
        schemeImage: React.forwardRef(({}, ref) => (
          <SchemeImageWithText ref={ref}/>
        )),
        page7line1: EmptyEntity,
        page7line2: EmptyEntity,
        houseMatchingBuilding: EmptyEntity,
        buildingPopulatedText: React.forwardRef(({}, ref) => (
          <ShumaType2and3BuildingPopulatedText ref={ref}/>
        )),
        buildingRoofTypeText: React.forwardRef(({}, ref) => (
          <ShumaType2and3BuildingRoofTypeText ref={ref}/>
        )),
        buildingMatchingEnvironment: EmptyEntity,
        buildingPermit: React.forwardRef(({}, ref) => (
          <ShumaType1and2BuildingPermit ref={ref}/>
        )),
        notes: () => [],
        section9Refactored: context => getShumaType2Section9(context),
        filesList: context => getShumaType2and3FilesList(context),
        selectedRowsAndColumnsTables: context => getSelectedRowsAndColumnsTables(context),
        calculationTables: React.forwardRef(({}, ref) => (
          <Shuma1and2CalculationTables ref={ref}/>
        )),
        section10Title: React.forwardRef(({}, ref) => (
          <ShumaType2Section10Title ref={ref}/>
        )),
        section10Subtitle: EmptyEntity,
        finalPrice: React.forwardRef(({}, ref) => (
          <ShumaType2FinalPrice ref={ref}/>
        )),
        calculationNotesText: context => getCalculationNotesText(context),
        greyBox: React.forwardRef(({}, ref) => (
          <ShumaType2GrayBox ref={ref}/>
        )),
        lastPage: React.forwardRef(({}, ref) => (
          <ShumaType2LastPage ref={ref}/>
        )),
      };
    case 1:
      return {
        docTitle: React.forwardRef(({}, ref) => (
          <ShumaType1DocTitle ref={ref}/>
        )),
        docProps: EmptyEntity,
        docCode: React.forwardRef(({}, ref) => (
          <DocumentCodeAndTimestamp ref={ref}/>
        )),
        titleText: React.forwardRef(({}, ref) => (
          <ShumaType1Title ref={ref}/>
        )),
        greenBox: React.forwardRef(({}, ref) => (
          <ShumaType1GreenBox ref={ref}/>
        )),
        section1: React.forwardRef(({}, ref) => (
          <ShumaType1Section1 ref={ref}/>
        )),
        section2Text: React.forwardRef(({}, ref) => (
          <ShumaType1and2Section2Text ref={ref}/>
        )),
        section2PlansCheckDate: React.forwardRef(({}, ref) => (
          <ShumaType1and2PlansCheckDate ref={ref}/>
        )),
        section4: React.forwardRef(({}, ref) => (
          <ShumaType1and2Table ref={ref}/>
        )),
        section4MortgageEntityEmphasis: () => [],
        section6Text: React.forwardRef(({}, ref) => (
          <ShumaType1and2Section6Text1 ref={ref}/>
        )),
        satelliteImage: React.forwardRef(({}, ref) => (
          <SatelliteImageWithText ref={ref}/>
        )),
        schemeImage: React.forwardRef(({}, ref) => (
          <SchemeImageWithText ref={ref}/>
        )),
        page7line1: EmptyEntity,
        page7line2: EmptyEntity,
        houseMatchingBuilding: EmptyEntity,
        buildingPopulatedText: React.forwardRef(({}, ref) => (
          <ShumaType1BuildingPopulatedText ref={ref}/>
        )),
        buildingRoofTypeText: React.forwardRef(({}, ref) => (
          <ShumaType1BuildingRoofTypeText ref={ref}/>
        )),
        buildingMatchingEnvironment: EmptyEntity,
        buildingPermit: React.forwardRef(({}, ref) => (
          <ShumaType1and2BuildingPermit ref={ref}/>
        )),
        notes: context => getNotes(context),
        section9Refactored: context => getShumaType1Section9(context),
        filesList: () => [],
        selectedRowsAndColumnsTables: context => getSelectedRowsAndColumnsTables(context),
        calculationTables: React.forwardRef(({}, ref) => (
          <Shuma1and2CalculationTables ref={ref}/>
        )),
        section10Title: React.forwardRef(({}, ref) => (
          <ShumaType1Section10Title ref={ref}/>
        )),
        section10Subtitle: React.forwardRef(({}, ref) => (
          <ShumaType1Section10Subtitle ref={ref}/>
        )),
        finalPrice: React.forwardRef(({}, ref) => (
          <ShumaType1FinalPrice ref={ref}/>
        )),
        calculationNotesText: () => [],
        greyBox: React.forwardRef(({}, ref) => (
          <ShumaType1GrayBox ref={ref}/>
        )),
        lastPage: EmptyEntity,
      };
    default:
      return {
        docTitle: EmptyEntity,
        docProps: EmptyEntity,
        docCode: EmptyEntity,
        titleText: EmptyEntity,
        greenBox: EmptyEntity,
        section1: EmptyEntity,
        section2Text: EmptyEntity,
        section2PlansCheckDate: EmptyEntity,
        section4: EmptyEntity,
        section4MortgageEntityEmphasis: () => [],
        section6Text: EmptyEntity,
        satelliteImage: EmptyEntity,
        schemeImage: EmptyEntity,
        page7line1: EmptyEntity,
        page7line2: EmptyEntity,
        houseMatchingBuilding: EmptyEntity,
        buildingPopulatedText: EmptyEntity,
        buildingRoofTypeText: EmptyEntity,
        buildingMatchingEnvironment: EmptyEntity,
        buildingPermit: EmptyEntity,
        notes: () => [],
        section9Refactored: () => [],
        filesList: () => [],
        selectedRowsAndColumnsTables: () => [],
        calculationTables: EmptyEntity,
        section10Title: EmptyEntity,
        section10Subtitle: EmptyEntity,
        finalPrice: EmptyEntity,
        calculationNotesText: () => [],
        greyBox: EmptyEntity,
        lastPage: EmptyEntity,
      };
  }
};

const addRef = refs => element => {
  refs.push(element);
};

const addNoteRef = (refs, id) => {
  const ref = createRef();
  refs[id] = ref;
  return ref;
};

const EmptyEntity = React.forwardRef(({}, ref) => <div ref={ref}/>);

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import ButtonDropdown from '../../../_common/components/ButtonDropdown';
import {useCustomerDetailsUIContext} from '../CustomerDetailsUIContext';
import {pathnames as P} from '../../../_common/constants/pathnames';
import styles from './styles.module.scss';

const EditPanel = () => {
  const {formik, updateCustomer, clearCustomer} = useCustomerDetailsUIContext();
  const name = `${formik.values.firstName} ${formik.values.lastName}`;

  return (
    <div className="form-group row d-flex mb-0 ml-8 mr-8 subheader w-100">
      <h2 className="d-flex align-items-center mb-0 font-weight-bolder">
        <FormattedMessage
          id="customer-details-page-subheader"
          defaultMessage="Customer"
        />
      </h2>
      <div
        className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 ml-5 bg-gray-200"/>
      <h5 className="text-dark-50 font-weight-bold mb-0">{name || 'name'}</h5>
      <div className={styles.buttonGroup}>
        <div className="col-lg pl-2 pr-2 position-relative d-inline">
          <NavLink
            to={P.CUSTOMERS_ENTITY_PAGE}
            className="btn btn-default font-weight-bold btn-sm px-3 font-size-base"
            onClick={clearCustomer}
          >
            <FormattedMessage
              id="customer-details-subheader-back"
              defaultMessage="Back"
            />
          </NavLink>
        </div>
        <ButtonDropdown
          title={(
            <FormattedMessage
              id="customer-details-subheader-save"
              defaultMessage="Save changes"
            />)}
          onBigButtonClick={() => updateCustomer()}
          disabled={!formik.isValid}
          items={[
            {
              label: (
                <FormattedMessage
                  id="customer-details-subheader-saveExit"
                  defaultMessage="Save & exit"
                />),
              icon: <i className="flaticon2-hourglass-1"/>,
              onClick: () => updateCustomer(true),
            }
          ]}
        />
      </div>
    </div>
  );
};

export default EditPanel;

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from 'react-router';
import * as actions from "../../../actions/SectionFilesActions";
import {closeShuma} from "../../../../_common/actions/oldInspectionDetailsActions";
import AnimatedUploadingFilePopUp from "../../../../_common/components/AnimatedUploadingFilePopUp";


export const ItemUploadFile = ({
                                 shumaID,
                                 label,
                                 fileTypeID
                               }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const shumaOriginID = Boolean(location.state) ? location.state[0] : null;

  const [uploadingFile, setUploadingFile] = useState(false);

  const onChange = (e, fileTypeID) => {
    const fileToSave = e.target.files[0];
    const fullFileName = e.target.files[0].name;
    uploadFile(fileToSave, shumaID, fileTypeID, fullFileName);
  };

  const uploadFile = (fileToSave, shumaID, fileTypeID, fullFileName) => {
    setUploadingFile(true)
    dispatch(actions.uploadFile(fileToSave, shumaID, fileTypeID, fullFileName))
      .then(() => {
        if (fileTypeID === 21 && shumaOriginID === 3) {
          dispatch(closeShuma(shumaID))
        }
      })
      .then(() => dispatch(actions.getFileList(shumaID)))
      .then(() => {
        document.body.click()
      })
      .finally(() => setTimeout(() => {
        setUploadingFile(false)
      }, 1000))
  };

  return (
    <div>
      <AnimatedUploadingFilePopUp show={uploadingFile}/>
      <div>
        <img
          src={'../../../media/common/File-plus_BC.svg'}
          alt="file"
        />
        <span className='text-dark mr-4 ml-1'>{label}</span>
        <input
          type="file"
          className="d-none"
          id="uploadedFile"
          onChange={(e) => {
            onChange(e, fileTypeID)
          }}
        />
      </div>
    </div>
  )
}

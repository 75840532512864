import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Section2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        shumaMazminIdentityName
      },
    },
    sectionNumbersShumaType3
  } = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)} ref={ref}>
      <span className={classnames(styles.textUnderline, styles.bold, styles.sectionHeader)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section2}. `}</span>
        <span>זהות מזמין השומה:</span>
      </span>
      <span>{shumaMazminIdentityName}</span>
    </div>
  );
});

export default Section2;

const REGULAR = [1, 2, 3, 4, 5, 6];
const GROUND = [7, 8, 9, 10];
const LOT = [11];

export const propertyTypesGroups = {
  REGULAR,
  GROUND_OR_LOT: [...GROUND, ...LOT],
  GROUND,
  LOT,
};


import {convertFileToContent} from '../../FileToContentConverter';
import {findFileByTypeId} from '../../../utils/files';

const fileTypeID = 1;

const getFile = (context) => {
  return convertFileToContent(context, fileTypeID, {});
};

export const getSection11Image = (context) => {
  const file = findFileByTypeId(context.files, fileTypeID);

  if (file) {
    return getFile(context);
  } else return []
};

import React from 'react';
import classnames from 'classnames';
import PresenterDetails from '../../PresenterDetails';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingDate} from '../../../utils/formattingHelper';
import {getSelectedOptionLabel} from '../../../utils/options';
import {visitorJobOptions} from '../../../constants/options';
import styles from './styles.module.scss';

const ShumaType1and2Section2Text = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    visitDate,
    visitorName,
    visitorJob,
  } = context.data.pdfData;
  const visitorJobValue = getSelectedOptionLabel(visitorJob, visitorJobOptions);
  const visitorNameAndJob = `${visitorName}, ${visitorJobValue}`;
  const visitDateValue = Boolean(visitDate) ? formattingDate(visitDate) : '';

  return (
    <div
      className={classnames(styles.itemText, styles.innerPadding)}
      ref={ref}
    >
      <span>1. </span>
      <span>
       בתאריך {visitDateValue} נערך ביקור בנכס ובסביבתו
      ע"י {visitorNameAndJob}
      </span>
      <PresenterDetails/>
    </div>
  );
});

export default ShumaType1and2Section2Text;

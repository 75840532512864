import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import * as options from '../../../constants/options';
import styles from './styles.module.scss';

export const FacingText = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const facingValue = getSelectedOptionLabel(context.data.pdfData.facing, options.facingOptions);

  return (
    <div className={classnames(styles.itemText, styles.sectionContent)}
         ref={ref}>
      <>הנכס שבנדון מהווה דירת מגורים בבנין הממוקם בפאה</>
      {` ${facingValue} `}
      <>ברח'</>
      {` ${context.data.pdfData.streetName}, ${context.data.pdfData.cityName}.`}
    </div>
  );
});

FacingText.propTypes = {
  className: PropTypes.string
};

export const getAreaLimitText = (context) => {
  const {areaLimits, neighborhoodName} = context.data.pdfData;
  let firstTextLine;
  let areaLimitsValues = [];
  if (Boolean(areaLimits)) {
    [firstTextLine, ...areaLimitsValues] = getTextChunks(areaLimits);
  }

  return [
    <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
      <span>הסביבה מהווה את</span>
      <span>{` ${neighborhoodName}`}</span>
      <span>
        {Boolean(areaLimits)
          ? ` באזור התחום ב${firstTextLine ? firstTextLine.trim() : ''}`
          : ''}
      </span>
    </div>,
    ...areaLimitsValues
      .map(mapTextChunks)
      .map((item, index) => (
        <div
          className={classnames(styles.itemText, styles.innerPadding)}
          key={index + 1}
        >
          {item}
        </div>
      ))
  ];
};

export const getAreaFeatures = (context) => {
  const {
    buildType,
    buildPeriod,
    streetMainUsage,
    areaUniqueFeatures
  } = context.data.pdfData;
  const buildTypeValue = getSelectedOptionLabel(buildType, options.buildTypeOptions);
  const buildPeriodValue = getSelectedOptionLabel(buildPeriod, options.buildPeriodOptions);
  let firstAreaUniqueFeaturesLine, firstStreetMainUsageLine;
  let areaUniqueFeaturesValues = [];
  let streetMainUsageValues = [];

  if (Boolean(areaUniqueFeatures)) {
    [firstAreaUniqueFeaturesLine, ...areaUniqueFeaturesValues] = getTextChunks(areaUniqueFeatures);
  }
  if (Boolean(streetMainUsage)) {
    [firstStreetMainUsageLine, ...streetMainUsageValues] = getTextChunks(streetMainUsage);
  }

  const firstLinesSection = (streetMainUsageValues.length === 0)
    ? [
      <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
        <span> הסביבה מאופיינת ברובה ב</span>
        {`${streetMainUsage}, ${buildPeriodValue}, `}
        <span>הבנייה הינה </span>
        {` ${buildTypeValue}`}
        {`${areaUniqueFeatures ? `, לצד ${firstAreaUniqueFeaturesLine}` : ''}`}
      </div>
    ] : [
      <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
        <span> הסביבה מאופיינת ברובה ב</span>
        {`${firstStreetMainUsageLine}`}
      </div>,
      ...streetMainUsageValues
        .map(mapTextChunks)
        .map((item, index) => (
          <div
            className={classnames(styles.itemText, styles.innerPadding)}
            key={index + 1}
          >
            {item}
          </div>
        )),
      <div
        className={classnames(styles.itemText, styles.innerPadding)}
        key={streetMainUsageValues.length + 2}
      >
        {`${buildPeriodValue}, `}
        <span>הבנייה הינה </span>
        {` ${buildTypeValue}`}
        {`${areaUniqueFeatures ? `, לצד ${firstAreaUniqueFeaturesLine}` : ''}`}
      </div>
    ];

  return [
    ...firstLinesSection,
    ...areaUniqueFeaturesValues
      .map(mapTextChunks)
      .map((item, index) => (
        <div
          className={classnames(styles.itemText, styles.innerPadding)}
          key={index + firstLinesSection.length + 1}
        >
          {item}
        </div>
      ))
  ];
};

export const TextItem4 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const streetTypeValue = getSelectedOptionLabel(context.data.pdfData.streetType, options.streetTypeOptions);
  const streetLocationValue = getSelectedOptionLabel(context.data.pdfData.streetLocation, options.streetLocationOptions);
  const streetTrafficValue = getSelectedOptionLabel(context.data.pdfData.streetTraffic, options.streetTrafficOptions);

  return (
    <div className={classnames(styles.itemText, styles.sectionContent)}
         ref={ref}>
      <>רח'</>
      {` ${context.data.pdfData.streetName} `}
      <>
        מהווה
        רחוב {streetLocationValue} {streetTypeValue} עם
        תנועה {streetTrafficValue}.
      </>
    </div>
  );
});

TextItem4.propTypes = {
  className: PropTypes.string
};

export const TextItem5 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const parkingIssuesTimesValue = getSelectedOptionLabel(context.data.pdfData.parkingIssuesTimes, options.parkingIssuesTimesOptions);

  return (
    <div ref={ref}>
      {(context.data.pdfData.parkingIssues === 1) && (
        <div className={classnames(styles.itemText, styles.sectionContent)}>
          ניכרת
          מצוקת חניה האופיינית
          לסביבה {parkingIssuesTimesValue}.
        </div>
      )}
    </div>
  );
});

TextItem5.propTypes = {
  className: PropTypes.string
};

export const TextItem6 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {(context.data.pdfData.publicTransport === 1) && (
        <div className={classnames(styles.itemText, styles.sectionContent)}>
          ברחוב קיימת תחבורה ציבורית.
        </div>
      )}
    </div>
  );
});

TextItem6.propTypes = {
  className: PropTypes.string
};

export const getEnvDevelopmentLevel = (context) => {
  const {envDevelopmentLevel} = context.data.pdfData;

  return Boolean(envDevelopmentLevel)
    ? getTextChunks(envDevelopmentLevel)
      .map(mapTextChunks)
      .map((item, index) => (
        <div
          className={classnames(styles.itemText, styles.innerPadding)}
          key={index}
        >
          {item}
        </div>
      ))
    : [];
};

export default [
  TextItem4,
  TextItem5,
  TextItem6,
];

import React from 'react';
import spinner from './spinner.gif';
import styles from './styles.module.scss';

const Spinner = ({customContainerStyles}) => (
  <div className={customContainerStyles}>
    <div className={styles.spinnerContainer}>
      <img
        src={spinner}
        alt="spinner"
        className={styles.spinner}
      />
    </div>
  </div>
);

export default Spinner;

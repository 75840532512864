import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import useActiveLinkClass from '../../../_common/components/_custom-hooks/useActiveLinkClass';
import {can} from '../../../_common/components/Permissions';
import {pathnames as P} from '../../../_common/constants/pathnames';
import {subjects as S, permissionTypes as T} from '../../../_common/constants/permissionSubjects';

const InspectionsPageLink = () => {
  const {activeClassName} = useActiveLinkClass({url: P.INSPECTIONS_PAGE});

  return can(T.VIEW, S.INSPECTIONS_PAGE) && (
    <li
      className={`menu-item ${activeClassName}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={P.INSPECTIONS_PAGE}>
        <img
          src={'../../media/common/Clipboard-list_BC.svg'}
          alt="list"
          className="mr-3"
        />
        <div className="menu-text">
          <span className="font-size-lg">
            <FormattedMessage
            id="inspections-page-link"
            defaultMessage="Form details page"
          />
          </span>
        </div>
      </NavLink>
    </li>
  );
};

InspectionsPageLink.propTypes = {};

export default InspectionsPageLink;

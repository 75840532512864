import React from 'react';
import styles from './styles.module.scss';

const ShumaType3Title = React.forwardRef(({}, ref) => (
  <div className={styles.title} ref={ref}>
    חוות דעת במקרקעין
  </div>
));

export default ShumaType3Title;

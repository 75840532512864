import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import {getTextChunks, mapTextChunks} from '../../utils/texts';
import styles from './styles.module.scss';

const Item = ({name, value}) => {
  return (
    <div>
        <span>
          {name} -
        </span>
      {` ${value} `}
    </div>
  );
};

const mapItems = items => items.map((item, ind) => {
  let firstLineText;
  let restChunks = [];

  if (Boolean(item.value)) {
    [firstLineText, ...restChunks] = getTextChunks(`${item.value}`);
  }

  return Boolean(item.value) && typeof item.value === 'string'
    ? [
      <div key={0}>
        <span>{item.name} - </span>
        <span>{Boolean(firstLineText) ? ` ${firstLineText}` : ''}</span>
      </div>,
      ...restChunks
        .map(mapTextChunks)
        .map((item, index) => (
          <div key={index + 1}>{item}</div>
        ))
    ]
    : (Boolean(item.value) && <Item {...item} key={ind}/>);
});


const ItemList = ({
                    title,
                    items,
                  }) => {
  return (
    <Section customClassNames={{content: styles.list, container: styles.container}}>
      <div className={styles.bold}>
        {title}:
      </div>
      {mapItems(items)}
    </Section>
  );
};

ItemList.propTypes = {
  title: PropTypes.node,
  items: PropTypes.array,
  fileTypeId: PropTypes.number,
  altText: PropTypes.string
};

export default ItemList;

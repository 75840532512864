import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import classnames from 'classnames';
import {getSelectedOptionLabel} from './options';
import * as options from '../constants/options';
import {propertyPopulatedOptions} from '../constants/options';
import {formattingPrice} from './formatting';
import {numberToWords} from 'number-to-words-hebrew';
import styles from '../components/PagesStack/content/styles.module.scss';

export const getGushHelkaTTHelkaRTLString = ({gush, helka, ttHelka}) => {
  return [gush, helka, ttHelka].filter(Boolean).join('/');
};

export const getDocCodeString = ({gush, helka, ttHelka, pdfCreateTime}) => {
  const momentDate = moment(pdfCreateTime).format('MMYY');
  return [gush, helka, ttHelka, momentDate].filter(Boolean).join('-');
};

const ACCEPTABLE_TEXT_LENGTH = 375;

export const isSelectedLandsTextLengthNotAcceptable = (lands, texts) => {
  return isToMuchLines(lands) || isLandTextBig(texts);
};

const isToMuchLines = ({
                         landShituf,
                         landHahira,
                         landHafkaa,
                         landHazmada,
                         landZikatHanaa,
                         landOther,
                       }) => {
  const selectedLands = [
    landShituf,
    landHahira,
    landHafkaa,
    landHazmada,
    landZikatHanaa,
    landOther
  ].filter(Boolean);

  return selectedLands.length > 2;
};

const isLandTextBig = ({
                         landShitufText,
                         landHahiraText,
                         landHafkaaText,
                         landHazmadaText,
                         landZikatHanaaText,
                         landOtherText,
                       }) => {
  const summaryText = [
    landShitufText,
    landHahiraText,
    landHafkaaText,
    landHazmadaText,
    landZikatHanaaText,
    landOtherText,
  ].filter(Boolean).join('\n');

  return summaryText.length > ACCEPTABLE_TEXT_LENGTH;
};

export const chunkString = (str, length) => {
  if (Boolean(str)) {
    const chars = str.split('');
    const chunks = _.chunk(chars, length);
    return chunks.map(chunk => chunk.join(''));
  } else {
    return [];
  }
};
export const getLandSurroundingsLabel = (landSurroundings, landSurroundingsText) => {
  const optionOtherLabel = getSelectedOptionLabel(9, options.landSurroundingsOptions);
  const landSurroundingsValue = (landSurroundings === null) ? '' : getSelectedOptionLabel(landSurroundings, options.landSurroundingsOptions);
  const valuesList = landSurroundingsValue.split(',').map(str => str.trim());
  const valueOther = valuesList.filter(item => item === optionOtherLabel);

  if (valueOther.length > 0) {
    const valuesWithoutOther = valuesList.filter(item => item !== optionOtherLabel);
    return [...valuesWithoutOther, landSurroundingsText].join(', ');
  } else {
    return landSurroundingsValue;
  }
};

export const getBuildingLocationValue = (buildingLocation) => {
  if (buildingLocation === 1) {
    return 'חזיתית';
  } else if (buildingLocation === 2) {
    return 'עורפית';
  } else {
    return '';
  }
};

export const joinAirDirections = ({
                                    houseAirDirectionNorth,
                                    houseAirDirectionSouth,
                                    houseAirDirectionEast,
                                    houseAirDirectionWest,
                                    houseAirDirectionNorthEast,
                                    houseAirDirectionNorthWest,
                                    houseAirDirectionSouthEast,
                                    houseAirDirectionSouthWest,
                                  }) => {
  const houseAirDirectionNorthValue = Boolean(houseAirDirectionNorth) ? 'צפון' : null;
  const houseAirDirectionSouthValue = Boolean(houseAirDirectionSouth) ? 'דרום' : null;
  const houseAirDirectionEastValue = Boolean(houseAirDirectionEast) ? 'מזרח' : null;
  const houseAirDirectionWestValue = Boolean(houseAirDirectionWest) ? 'מערב' : null;
  const houseAirDirectionNorthEastValue = Boolean(houseAirDirectionNorthEast) ? 'צפון - מזרח' : null;
  const houseAirDirectionNorthWestValue = Boolean(houseAirDirectionNorthWest) ? 'צפון - מערב' : null;
  const houseAirDirectionSouthEastValue = Boolean(houseAirDirectionSouthEast) ? 'דרום - מזרח' : null;
  const houseAirDirectionSouthWestValue = Boolean(houseAirDirectionSouthWest) ? 'דרום - מערב' : null;

  return [
    houseAirDirectionNorthValue,
    houseAirDirectionSouthValue,
    houseAirDirectionEastValue,
    houseAirDirectionWestValue,
    houseAirDirectionNorthEastValue,
    houseAirDirectionNorthWestValue,
    houseAirDirectionSouthEastValue,
    houseAirDirectionSouthWestValue,
  ].filter(Boolean).join(', ');
};
export const getBalconyYardRoofRegisteredArea = (
  houseBalconyOpenRegisteredArea,
  houseBalconyClosedRegisteredArea,
  houseYardRegisteredArea,
  houseRoofRegisteredArea
) => {
  const houseBalconyOpenRegisteredAreaValue = Boolean(houseBalconyOpenRegisteredArea)
    ? ` ${houseBalconyOpenRegisteredArea} מ"ר שטח מרפסות פתוחות`
    : '';
  const houseBalconyClosedRegisteredAreaValue = Boolean(houseBalconyClosedRegisteredArea)
    ? ` ${houseBalconyClosedRegisteredArea} מ"ר שטח מרפסות סגורות`
    : '';
  const houseYardRegisteredAreaValue = Boolean(houseYardRegisteredArea)
    ? ` ${houseYardRegisteredArea} מ"ר שטח חצר`
    : '';
  const houseRoofRegisteredAreaValue = Boolean(houseRoofRegisteredArea)
    ? ` ${houseRoofRegisteredArea} מ"ר שטח גג`
    : '';

  return (
    houseBalconyOpenRegisteredAreaValue ||
    houseBalconyClosedRegisteredAreaValue ||
    houseYardRegisteredAreaValue ||
    houseRoofRegisteredAreaValue
  )
    ? `${[
      houseBalconyOpenRegisteredAreaValue,
      houseBalconyClosedRegisteredAreaValue,
      houseYardRegisteredAreaValue,
      houseRoofRegisteredAreaValue,
    ].filter(Boolean).join(', ')}.`
    : '';
};

export const getStorageParkingRegisteredArea = (houseStorageRegisteredArea, houseParkingRegisteredArea) => {
  const houseStorageRegisteredAreaValue = Boolean(houseStorageRegisteredArea)
    ? `${houseStorageRegisteredArea} מ"ר שטח מחסן`
    : '';
  const houseParkingRegisteredAreaValue = Boolean(houseParkingRegisteredArea)
    ? `${houseParkingRegisteredArea} מ"ר שטח חנייה`
    : '';

  return (houseStorageRegisteredAreaValue || houseParkingRegisteredAreaValue)
    ? `${[houseStorageRegisteredAreaValue, houseParkingRegisteredAreaValue].filter(Boolean).join(', ')}.`
    : '';
};

export const getBalconyAndStorageLabels = ({
                                             houseAreaIncludeBalcony,
                                             houseBalconyOpenArea,
                                             houseBalconyClosedArea,
                                             houseAreaIncludeStorage,
                                             houseStorageArea,
                                           }) => {
  const balcony = getBalconyLabel({
    houseAreaIncludeBalcony,
    houseBalconyOpenArea,
    houseBalconyClosedArea,
  });
  const storage = getStorageLabel({
    houseAreaIncludeStorage,
    houseStorageArea,
  });

  return [balcony, storage].filter(Boolean).join(', ');
};

const getBalconyLabel = ({
                           houseAreaIncludeBalcony,
                           houseBalconyOpenArea,
                           houseBalconyClosedArea,
                         }) => {
  if (Boolean(houseAreaIncludeBalcony)) {
    return 'כולל שטחי מרפסות';
  } else if (Boolean(houseBalconyOpenArea) || Boolean(houseBalconyClosedArea)) {
    return 'לא כולל מרפסת';
  } else {
    return '';
  }
};

const getStorageLabel = ({
                           houseAreaIncludeStorage,
                           houseStorageArea,
                         }) => {
  if (Boolean(houseAreaIncludeStorage)) {
    return 'כולל שטח מחסן';
  } else if (Boolean(houseStorageArea)) {
    return 'לא כולל מחסן';
  } else {
    return '';
  }
};

export const getPropertyPopulatedLabel = propertyPopulated => {
  const propertyPopulatedLabel = getSelectedOptionLabel(propertyPopulated, propertyPopulatedOptions);
  if (propertyPopulated === 4) {
    return `בעת הביקור ניכר כי הנכס ${propertyPopulatedLabel}, בהתאם למידע שנמסר על ידי מזמין חוות הדעת`;
  } else {
    return `בעת הביקור ניכר כי הנכס מאוכלס על ידי ${propertyPopulatedLabel}, בהתאם למידע שנמסר על ידי מזמין חוות הדעת`;
  }
};

export const getStorageParkingPermitArea = (houseStoragePermitArea, houseParkingPermitArea) => {
  const houseStoragePermitAreaValue = Boolean(houseStoragePermitArea)
    ? `${houseStoragePermitArea} מ"ר שטח מחסן` : '';
  const houseParkingPermitAreaValue = Boolean(houseParkingPermitArea)
    ? `${houseParkingPermitArea} מ"ר שטח חנייה` : '';

  return (houseStoragePermitAreaValue || houseParkingPermitAreaValue)
    ? `${[houseStoragePermitAreaValue, houseParkingPermitAreaValue].filter(Boolean).join(', ')}.`
    : '';
};

const differenceLimitPercents = 3;
export const isDifferenceOutOfLimit = (houseExistingArea = 0, housePermitArea = 0) => {
  const differencePercents = 100 * Math.abs(housePermitArea - houseExistingArea) / housePermitArea;
  return differencePercents > differenceLimitPercents;
};

const charsPerLineLimit = 93;
export const getTextChunks = fullText => {
  return fullText
    .split(/ /g)
    .reduce((acc, word) => {
      const accWithoutLastLine = _.slice(acc, 0, acc.length - 1);
      const accWithLastLine = _.slice(acc, 0, acc.length);
      const lastLine = _.last(acc);
      if (word.includes('\n')) {
        const [firstWord, ...restWords] = word.split('\n');
        const updatedLastLineLength = lastLine.length + firstWord.length + 1;
        const wordsWithLineBreaks = restWords
          .map((word, index, array) => {
            return (index < array.length - 2) ? [word, ''] : [word];
          })
          .flat();
        return (updatedLastLineLength < charsPerLineLimit)
          ? [...accWithoutLastLine, `${lastLine} ${firstWord}`, ...wordsWithLineBreaks]
          : [...accWithLastLine, firstWord, ...wordsWithLineBreaks];

      } else {
        const updatedLastLineLength = lastLine.length + word.length + 1;
        return (updatedLastLineLength < charsPerLineLimit)
          ? [...accWithoutLastLine, `${lastLine} ${word}`]
          : [...accWithLastLine, word];
      }
    }, ['']);
};

export const getTableTextChunks = fullText => {
  return fullText
    .split(/ /g)
    .reduce((acc, word) => {
      const accWithoutLastLine = _.slice(acc, 0, acc.length - 1);
      const accWithLastLine = _.slice(acc, 0, acc.length);
      const lastLine = _.last(acc);
      if (word.includes('\n')) {
        const [firstWord, ...restWords] = word.split('\n');
        const updatedLastLineLength = lastLine.length + firstWord.length + 1;
        const wordsWithLineBreaks = restWords
          .map((word, index, array) => {
            return (index < array.length - 3) ? [word, ''] : [word];
          })
          .flat();

        return (updatedLastLineLength < charsPerLineLimit)
          ? [...accWithoutLastLine, `${lastLine} ${firstWord}`, ...wordsWithLineBreaks]
          : [...accWithLastLine, firstWord, ...wordsWithLineBreaks];

      } else {
        const updatedLastLineLength = lastLine.length + word.length + 1;
        return (updatedLastLineLength < charsPerLineLimit)
          ? [...accWithoutLastLine, `${lastLine} ${word}`]
          : [...accWithLastLine, word];
      }
    }, ['']);
};

export const mapTextChunks = (chunk, index, chunks) => {
  return (
    <div
      className={classnames(
        styles.itemText,
        {[styles.emptyLine]: chunk.length === 0}
      )}
      key={index + 1}
    >
      {chunk}
      {(index === chunks.length - 1) ? checkIfTextEndsWithDot(chunk) : ''}
    </div>
  );
};

export const mapTrimmedTextChunks = (chunk, index, chunks) => {
  return (
    <div
      className={classnames(
        styles.itemText,
        {[styles.emptyLine]: chunk.length === 0}
      )}
      key={index + 1}
    >
      {chunk}
      {(index === chunks.length - 1) ? checkIfTrimmedTextEndsWithDot(chunk) : ''}
    </div>
  );
};

export const mapTextChunksWithoutDot = (chunk, index) => {
  return (
    <div
      className={classnames(
        styles.itemText,
        {[styles.emptyLine]: chunk.length === 0}
      )}
      key={index + 1}
    >
      {chunk}
    </div>
  );
};

export const mapTableTextChunks = (chunk, index) => {

  return (
    <div
      className={classnames({[styles.emptyLine]: chunk.length === 0})}
      key={index + 1}
    >
      {chunk}
    </div>
  );
};

export const getShumaType2and3Section1LoanTypeSentenceText = (context) => {
  const {
    loanTypeName,
    otherPropertyTypeName,
    loanText,
  } = context.data.pdfData;

  switch (loanTypeName) {
    case 'רכישת מקרקעין שבנידון':
    case 'נכס קיים':
      return (
        <span>
          {` ${loanTypeName} `}
          <span>המהווה דירת מגורים</span>
        </span>
      );
    case 'רכישת מקרקעין אחר':
      return (
        <span>
          {` ${loanTypeName} `}
          <span>המהווה </span>
          {`${otherPropertyTypeName || ''}`}
        </span>
      );
    case 'שיפוצים':
    case 'בניה עצמית':
      return (
        <span>{` ${loanTypeName}`}</span>
      );
    case 'מימון בנקאי':
      return (
        <span> בנקאי</span>
      );
    case 'אחר':
      return (
        <span>{` ${loanText || ''}`}</span>
      );
    default:
      return null;
  }
};

export const getCustomersDataString = pdfDataCustomers => pdfDataCustomers
  .map(({name, idNumber}) => {
    const idNumberSubstring = Boolean(idNumber) ? ` ת.ז. ${idNumber}` : '';
    return `${name}${idNumberSubstring}`;
  })
  .join(', ');

export const getWrittenPrice = (roundedPrice) => {
  let writtenPrice = numberToWords(roundedPrice, 'f');
  if (writtenPrice.startsWith('שניים')) {
    writtenPrice = writtenPrice.replace('שניים', 'שני');
  }
  return writtenPrice;
};

export const getFinalPriceWithText = finalPrice => {
  const roundedPrice = Math.round(finalPrice);
  const formattedPrice = formattingPrice(roundedPrice, true);
  const writtenPrice = getWrittenPrice(roundedPrice);

  return `${formattedPrice} (${writtenPrice} ₪)`;
};

export const getFinalPriceOnlyText = finalPrice => {
  const roundedPrice = Math.round(finalPrice);
  const writtenPrice = Boolean(roundedPrice) ? getWrittenPrice(roundedPrice) : '';

  return Boolean(writtenPrice) ? `${writtenPrice} ₪` : '';
};

export const emptyLinePlaceholder = '--';

export const formatPriceOrReturnPlaceholder = (value, showSign) =>
  Boolean(value) ? formattingPrice(value, showSign) : emptyLinePlaceholder;

export const checkIfTextEndsWithDot = (value) => {
  return (value && `${value}`.trim().endsWith('.')) ? '' : '.';
};

export const checkIfTrimmedTextEndsWithDot = (value) => {
  const trimmedValue = Boolean(value) ? value.trim() : '';
  if (Boolean(trimmedValue)) {
    return (trimmedValue.endsWith('.') || trimmedValue.endsWith(':')) ? '' : '.';
  } else return '';
};

export const combineOptionalText = (text, optionalText) => {
  if (optionalText) {
    return `${optionalText.trim()}${checkIfTextEndsWithDot(optionalText)}`;
  } else {
    return `${text.trim()}${checkIfTextEndsWithDot(text)}`;
  }
};

export const getBuildingOuterLayerValue = (buildingOuterLayer, buildingOuterLayerText) => {
  switch (buildingOuterLayer) {
    case 6:
      return buildingOuterLayerText;
    default:
      return getSelectedOptionLabel(buildingOuterLayer, options.buildingOuterLayerOptions) || '';
  }
};

export const getBuildingBuildTypeValue = (buildingBuildType, buildingBuildTypeText) => {
  switch (buildingBuildType) {
    case 3:
      return buildingBuildTypeText;
    default:
      return getSelectedOptionLabel(buildingBuildType, options.buildingBuildTypeOptions) || '';
  }
};

export const getBreakLinesTextShumaType3 = (value) => {
  let firstTextLine;
  let restLines = [];
  if (Boolean(value)) {
    [firstTextLine, ...restLines] = getTextChunks(value);
  }
  if (restLines.length > 0) {
    restLines[restLines.length - 1] = restLines[restLines.length - 1] + checkIfTextEndsWithDot(restLines[restLines.length - 1]);
  }

  return Boolean(value)
    ? [
      <span key={0}>
        {Boolean(firstTextLine) ? `${firstTextLine}` : ''}
        {(restLines.length === 0) && checkIfTextEndsWithDot(firstTextLine)}
      </span>,
      ...restLines.map(mapTextChunks).map((item, index) => (
        <div key={index + 1}>
          {item}
        </div>
      ))
    ] : [];
};

export const getBreakLinesTextShumaType3WithoutDot = (value) => {
  let firstTextLine;
  let restLines = [];
  if (Boolean(value)) {
    [firstTextLine, ...restLines] = getTextChunks(value);
  }

  return Boolean(value)
    ? [
      <span key={0}>
        {Boolean(firstTextLine) ? `${firstTextLine}` : ''}
      </span>,
      ...restLines.map(mapTextChunksWithoutDot).map((item, index) => (
        <div key={index + 1}>
          {item}
        </div>
      ))
    ] : [];
};

export const checkIfStartsWithComma = (value) => {
  if (value) {
    let string = `${value}`;
    string.trim();

    if (string.startsWith(',')) {
      return string.substring(1);
    } else {
      return string;
    }
  }
};

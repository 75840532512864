import React from 'react';
import classnames from 'classnames';
import {getSelectedOptionLabel} from '../../../utils/options';
import {checkIfTrimmedTextEndsWithDot, getTextChunks, mapTextChunks} from '../../../utils/texts';
import {houseMaintenanceStatusOptions,} from '../../../constants/options';
import styles from './styles.module.scss';

export const getSection6Text79 = (context) => {
  const {
    houseMaintenanceStatus,
    houseMaintenanceStatusText,
  } = context.data.pdfData;
  const houseMaintenanceStatusValue = getSelectedOptionLabel(houseMaintenanceStatus, houseMaintenanceStatusOptions);
  let firstTextLine;
  let restLines = [];
  if (Boolean(houseMaintenanceStatusText)) {
    [firstTextLine, ...restLines] = getTextChunks(houseMaintenanceStatusText);
  }

  return [
    <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
      <span className={styles.bold}>מצב תחזוקה: </span>
      <span>ניכר</span>
      <span data-testid="houseMaintenanceStatusValue">
          {Boolean(houseMaintenanceStatusValue) && ` כ${houseMaintenanceStatusValue}`}
        </span>
      <span>.</span>
      {Boolean(firstTextLine) && ` ${firstTextLine}${checkIfTrimmedTextEndsWithDot(firstTextLine)}`}
    </div>,
    ...restLines.map(mapTextChunks).map((item, index) => (
      <div
        className={classnames(styles.itemText, styles.innerPadding)}
        key={index + 1}
      >
        {item}
      </div>
    ))
  ];
};

import React from 'react';
import PropTypes from 'prop-types';
import {v4 as guid} from 'uuid';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import styles from './styles.module.scss';

const ButtonDropdown = ({
                          title,
                          onBigButtonClick,
                          items = [],
                          disabled
                        }) => {
  const ddId = guid();

  const renderMenuItems = items => {
    return items.map((item, index) => {
      const {label, icon, onClick} = item;
      return (
        <Dropdown.Item
          eventKey={index + 1}
          onClick={onClick}
          className="align-items-center"
        >
          {icon && (
            <span className="navi-icon pr-3">
              {icon}
            </span>
          )}
          <span className="navi-text">{label}</span>
        </Dropdown.Item>
      );
    });
  };

  return (
    <SplitButton
      id={ddId}
      variant="primary"
      title={title}
      className={styles.button}
      onClick={onBigButtonClick}
      disabled={disabled}
    >
      {renderMenuItems(items)}
    </SplitButton>
  );
};

ButtonDropdown.propTypes = {
  title: PropTypes.node,
  items: PropTypes.shape({
    label: PropTypes.node,
    icon: PropTypes.node,
    onClick: PropTypes.func
  }),
  disabled: PropTypes.bool
};

export default ButtonDropdown;

import {
  commonColumns,
  surveyAssetsColumns,
  crawlerTab5Columns,
} from '../../InspectionsPage/constants/gridColumns';

const getColumnHeader = (columnName, columns) => {
  const [column] = columns.filter(col => col.accessor === columnName);

  return column.Header;
};

export const getCommonAssetsColumnHeader = columnName => getColumnHeader(columnName, commonColumns);
export const getSimilarAssetsCrawlerColumnHeader = columnName => getColumnHeader(columnName, crawlerTab5Columns);
export const getSurveyAssetsColumnHeader = columnName => getColumnHeader(columnName, surveyAssetsColumns);

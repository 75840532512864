import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ModalApproveDecline from "./ModalApproveDecline";
import MultipleTooltip from "../MultipleTooltip";
import textSuggestionsIcon from './Write.svg';
import styles from './styles.module.scss';
import {FormattedMessage, useIntl} from "react-intl";
import SimpleButtonDropdown from "../SimpleButtonDropdown";

const ButtonTextSuggestions = ({
                                 fieldName,
                                 currentFieldValue,
                                 textSuggestionsArray = [],
                                 setFieldValue,
                                 disabled,
                               }) => {
  const [openModalApproveDecline, setOpenModalApproveDecline] = useState(false);
  const [value, setValue] = useState('');
  const intl = useIntl();
  const tooltipText = intl.formatMessage({
    id: 'button-text-suggestions-label',
    defaultMessage: 'Replace text',
  });

  return (
    <div className={styles.textSuggestionsInteraction}>
      {!disabled
        ?
        <>
          <SimpleButtonDropdown
            variant={'link'}
            drop={'up'}
            id={"dropdown-autoclose-false"}
            title={
              <MultipleTooltip
                id={`textSuggestions-${fieldName}`}
                customIconClassName={styles.triggerButton}
                label={tooltipText}
                icon={textSuggestionsIcon}
              />
            }
            header={
              <div className={'p-2'}>
                <FormattedMessage
                  id="button-text-suggestions-header"
                  defaultMessage="Selecting a guide text:"
                />
              </div>
            }
            items={
              textSuggestionsArray.map(item => {
                return {
                  onClick: (
                    () => {
                      if (currentFieldValue !== item.value) {
                        if (Boolean(currentFieldValue)) {
                          setOpenModalApproveDecline(true)
                          setValue(`${item.value}`)
                        } else {
                          setFieldValue(fieldName, `${item.value}`)
                        }
                      }
                    }
                  ),
                  itemContent: item.label,
                  disabled: currentFieldValue === item.value,
                  customStyles: '',
                }
              })
            }
          />
          <ModalApproveDecline
            isOpen={openModalApproveDecline}
            closeModal={() => {
              setOpenModalApproveDecline(false)
            }}
            approveFunc={() => {
              setFieldValue(fieldName, value);
              setOpenModalApproveDecline(false);
            }}
            message={
              <FormattedMessage
                id="ask-if-replace-text-message"
                defaultMessage="There is text in this field, should I replace it or cancel the operation?"
              />
            }
          />
        </>
        : null
      }
    </div>
  )
};

ButtonTextSuggestions.propTypes = {
  fieldName: PropTypes.string,
  currentFieldValue: PropTypes.string,
  textSuggestionsArray: PropTypes.array,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonTextSuggestions;

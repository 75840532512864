import React from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";
import NoteNumber from "../../NoteNumber";
import styles from './styles.module.scss';

const CommentNote = React.forwardRef(({
                                        noteNumber,
                                        children,
                                        className,
                                        id,
                                        ...rest
                                      }, ref) => {
  return (
    <div
      className={classnames(styles.textRegular, styles.lineHeightOne, styles.commentNote, className)}
      ref={ref}
      {...rest}
    >
      <NoteNumber id={id}>{noteNumber}</NoteNumber>
      <span>{children}</span>
    </div>
  );
});

CommentNote.propTypes = {
  noteNumber: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
};

const CommentReplacementValueText = React.forwardRef((props, ref) => {

  return (
    <CommentNote id={'replacementValue'} noteNumber={1} {...props} ref={ref}>
        <span className={styles.bold}> ערך כינון </span>
        <span className={styles.lineHeightOne}> - אומדן עלות הקמת הנכס מחדש, לפי איכות הבניה והסטנדרטים המקובלים היום. האומדן כולל גם את השטחים המוצמדים לנכס והחלק היחסי שלו בשטחים משותפים. כמו כן, כלולים באומדן ערך הכינון גם שטחים שנבנו ללא היתר.</span>
    </CommentNote>
  )
});

export default {
  CommentReplacementValueText
};

import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import {CustomerDetails} from '../';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const customerDetailsRoute = (
  <ProtectedRoute
    subject={S.CUSTOMER_DETAILS_PAGE}
    path={P.CUSTOMER_DETAILS_CUSTOMER_ID}
    component={CustomerDetails}
  />
);

import React from 'react';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import styles from './styles.module.scss';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getGushHelkaTTHelkaRTLString} from '../../../utils/texts';

const Section10Text = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        gush, helka, ttHelka,
        streetName, houseNumber, cityName,
      }
    },
    noteNumbers
  } = useCompleteInspectionContext();
  const gushValues = getGushHelkaTTHelkaRTLString({gush, helka, ttHelka});
  const address = `${streetName} ${houseNumber}, ${cityName}`;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      בשים לב לנכס הנישום, מיקומו וכל שאר נתוניו, הנני להעריך את שווי
      הנכס
      שבנדון בגו"ח
      {` ${gushValues} `}
      ברח'
      {` ${address} `}
      , במכירה שבין מוכר מרצון לקונה מרצון, בתנאי שוק חופשי,
      במצב בו הנכס פנוי וחופשי מכל חוב, שיעבוד ו/או זכויות צד ג' כלשהן
      והכל לתאריך הקובע, שווי החלקה בגבולות
      <NoteNumber id={'estimateText'}>
        {noteNumbers.estimateText}
      </NoteNumber> כדלהלן:
    </div>
  );
});

export default Section10Text;

const defaultState = {
  documents: [],
  error: false,
  errorMessage: null
};

export const actionTypes = {
  FETCH_DOCUMENTS: 'FETCH_DOCUMENTS',
  catchError: 'CATCH_ERROR'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENTS: {
      const {items} = action.payload;

      return {
        ...state,
        documents: items,
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.catchError: {
      return {
        ...state,
        error: true,
        errorMessage: `FETCH_DOCUMENTS: ${action.payload.error}`
      };
    }
    default:
      return state;
  }
};

export const actions = {
  fetchDocuments: (data) => ({
    type: actionTypes.FETCH_DOCUMENTS,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: {error}
  }),
};

import {combineReducers} from 'redux';
import {reducer as auth} from './Auth';
import globalMessageReducer from './_common/components/GlobalMessage/reducers/globalMessageReducer';
import {inspectionsPageTableReducer} from './InspectionsPage';
import {usersReducer} from './_common/components/Users/reducers/usersReduser';
import {allShumaTypesReducer} from './_common/components/ShumaTypes/reducers/allShumaTypesReduсer';
import {statusListReducer} from './_common/components/StatusList/reducers/statusListReduser';
import {citiesAndStreetsReducer} from './_common/components/CitiesAndStreets/reducers/citiesAndStreetsReducer';
import {inspectionDetailsReducer} from './OldInspectionDetails';
import {customersEntityTableReducer} from './CustomersEntity';
import {oldInspectionsPageTableReducer} from './OldInspectionsPage';
import {ordersPageTableReducer} from './OrdersPage';
import {orderDetailsReducer} from './OrderDetails';
import {orderFilesReducer} from './OrderDetails';
import {getFileList} from "./OldInspectionDetails";
import {customerDetailsReducer} from './CustomerDetails';
import {customerGreenInvoiceReducer} from './CustomerDetails';
import {customerGreenInvoicePreviewDocumentReducer} from './CustomerDetails';
import {customerFileListReducer} from './CustomerDetails';
import {customerDocumentDataReducer} from './CustomerDetails';
import {priceQuotesPageTableReducer} from './PriceQuotesPage';
import {uploadingFilePopUpReducer} from './_common';
import {decisionTreeOptionsReducer} from './_common/components/DecisionTreeOptions/reducers/decisionTreeOptionsReducer';
import {tasksTableReducer} from './TasksPage';


export default combineReducers({
  auth,
  globalMessage: globalMessageReducer,
  inspectionsPageTableReducer,
  usersReducer,
  allShumaTypesReducer,
  statusListReducer,
  citiesAndStreetsReducer,
  inspectionDetailsReducer,
  customersEntityTableReducer,
  oldInspectionsPageTableReducer,
  getFileList,
  customerDetailsReducer,
  customerGreenInvoiceReducer,
  customerGreenInvoicePreviewDocumentReducer,
  customerFileListReducer,
  customerDocumentDataReducer,
  uploadingFilePopUpReducer,
  ordersPageTableReducer,
  orderDetailsReducer,
  orderFilesReducer,
  priceQuotesPageTableReducer,
  decisionTreeOptionsReducer,
  tasksTableReducer,
});

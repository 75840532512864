import React, {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as fileActions from '../_redux/actions/OrderFilesActions';
import {useOrderDetailsUIGlobalContext} from './OrderDetailsUIGlobalContext';

const OrderDetailsUIFilesContext = createContext('');

export function useOrderDetailsUIFilesContext() {
  return useContext(OrderDetailsUIFilesContext);
}

export function OrderDetailsUIFilesProvider({children}) {
  const dispatch = useDispatch();
  const {
    orderID,
  } = useOrderDetailsUIGlobalContext();

  const {orderFileList} = useSelector(
    (state) => ({
      orderFileList: state.orderFilesReducer.orderFileList,
    }),
    shallowEqual
  );

  const [uploadingFile, setUploadingFile] = useState(false);
  useEffect(() => {
    if (orderID !== -1) {
      dispatch(fileActions.getFileList(orderID))
    }
  }, []);

  const onUploadFile = async (e, fileTypeID) => {
    setUploadingFile(true)
      const filesArray = Boolean(e.target.files)
        ? Object.values(e.target.files)
        : [];
      const calls = filesArray.length > 0 &&
        filesArray.map(async (item) => await dispatch(fileActions.uploadFile(item, orderID, fileTypeID, item.name)))
      const results = await Promise.all(calls);
      if (results) {
        dispatch(fileActions.getFileList(orderID));
        document.body.click();
      }
    setTimeout(() => setUploadingFile(false), 1000);
  };

  const handleDownloadFile = (fileName) => {
    if (orderID) {
      dispatch(fileActions.getFile({orderID, fileName}))
    }
  };
  const handleDeleteFile = (fileID, fileName) => {
    dispatch(fileActions.deleteFile({orderID, fileID, fileName}))
      .then(() => dispatch(fileActions.getFileList(orderID)))
  };

  const value = {
    fileList: orderFileList,
    uploadingFile,
    onUploadFile,
    handleDownloadFile,
    handleDeleteFile
  };

  return (
    <OrderDetailsUIFilesContext.Provider value={value}>
      {children}
    </OrderDetailsUIFilesContext.Provider>
  );
}

OrderDetailsUIFilesProvider.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import classnames from "classnames";
import styles from "./styles.module.scss";

const CommonGrid = ({
                      infiniteScrollProps,
                      getTableProps,
                      headerGroups,
                      getTableBodyProps,
                      prepareRow,
                      rows,
                      customHeaderStyles,
                      customBodyStyles,
                      customScrollContainerStyles
                    }) => {

  return (
    <div id="scrollContainer" className={classnames(styles.scrollContainer, customScrollContainerStyles)}>
      <InfiniteScroll {...infiniteScrollProps}>
        <div className={styles.infiniteScrollTable}>
          <table {...getTableProps()} className="table table-vertical-center">
            <thead className={classnames(styles.stickyHeader, customHeaderStyles)}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()} className={classnames(styles.body, customBodyStyles)}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  )
};

export default CommonGrid;

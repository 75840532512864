import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import styles from './styles.module.scss';

const CACHE = {};

const FieldInputTypeahead = ({
                               fieldId,
                               onChange,
                               setFieldValue,
                               ...rest
                             }) => {
  const handleChange = ([selected]) => {
    if (onChange) {
      onChange(selected);
    }
    setFieldValue(fieldId, '');
  };

  const handleInputChange = (e, id, inputProps) => {
    setFieldValue(id, e.target.value);
    inputProps.onChange(e);
  };

  return (
    <InputTypeahead
      id={fieldId}
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};

export const InputTypeahead = ({
                                 id,
                                 value,
                                 label = null,
                                 containerCustomStyles = null,
                                 labelCustomStyles = 'pl-6',
                                 inputCustomStyles = null,
                                 onChange,
                                 onInputChange,
                                 readonly,
                                 makeAndHandleRequest,
                                 renderMenuItemChildren,
                                 labelKey,
                                 handleFindItem,
                                 placeholder = '',
                                 ...rest
                               }) => {
  const intl = useIntl();
  const typeaheadEmptyLabel = intl.formatMessage({
    id: 'typeahead-search-customers-empty-label',
    defaultMessage: 'Empty',
  });
  const typeaheadSearch = intl.formatMessage({
    id: 'typeahead-search-customers-text',
    defaultMessage: 'Search',
  });
  const typeaheadPrompt = intl.formatMessage({
    id: 'typeahead-selected-customer-prompt',
    defaultMessage: 'Type',
  });
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    if (CACHE[query]) {
      setOptions(CACHE[query].options);
      return;
    }
    handleFindItem(query)
      .then((resp) => {
        setOptions(resp.options);
      });
  };

  return (
    <AsyncTypeahead
      id={id}
      onSearch={handleSearch}
      onChange={onChange}
      options={options}
      paginate={false}
      labelKey={labelKey}
      emptyLabel={typeaheadEmptyLabel}
      searchText={typeaheadSearch}
      promptText={typeaheadPrompt}
      renderInput={({
                      inputRef,
                      referenceElementRef,
                      className,
                      ...inputProps
                    }) => (
        <label
          className={classnames(styles.inputContainer, containerCustomStyles)}>
          <div
            className={classnames(styles.labelContainer, labelCustomStyles)}>{label}</div>
          <input
            dir={'ltr'}
            id={id}
            name={id}
            value={value}
            placeholder={placeholder}
            className={classnames(`form-control form-control-lg form-control-solid text-left`, inputCustomStyles)}
            type={'text'}
            ref={(input) => {
              inputRef(input);
              referenceElementRef(input);
            }}
            onChange={onInputChange}
            {...inputProps}
          />
        </label>
      )}
      renderMenuItemChildren={renderMenuItemChildren}
      useCache={false}
      {...rest}
    />
  );
};

FieldInputTypeahead.propTypes = {
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.node,
  containerCustomStyles: PropTypes.string,
  labelCustomStyles: PropTypes.string,
  inputCustomStyles: PropTypes.string,
  onChange: PropTypes.func,
  makeAndHandleRequest: PropTypes.func.isRequired,
  renderMenuItemChildren: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  searchText: PropTypes.node,
  emptyLabel: PropTypes.node,
  promptText: PropTypes.node,
  setFieldValue: PropTypes.func,
  placeholder: PropTypes.string,
};

export default FieldInputTypeahead;

import moment from 'moment';

export const rowValuesSetCommonDocs = {
  description: '',
  quantity: '',
  price: '',
  vatType: {value: null},
};

export const rowValuesSetCheque = {
  date: moment().format('YYYY-MM-DD'),
  bankName: '',
  bankBranch: '',
  bankAccount: '',
  chequeNum: '',
  price: '',
  type: 2,
};

export const rowValuesSetBankTransfer = {
  date: moment().format('YYYY-MM-DD'),
  bankName: '',
  bankBranch: '',
  bankAccount: '',
  price: '',
  type: 4,
};

export const rowValuesSetCreditCard = {
  date: moment().format('YYYY-MM-DD'),
  cardNum: '',
  cardType: {value: null},
  dealType: {value: null},
  numPayments: '',
  price: '',
  type: 3,
};

export const rowValuesSetPaymentApp = {
  date: moment().format('YYYY-MM-DD'),
  appType: {value: null},
  accountId: '',
  transactionId: '',
  price: '',
  type: 10,
};

export const rowValuesSetPayPal = {
  date: moment().format('YYYY-MM-DD'),
  accountId: '',
  transactionId: '',
  price: '',
  type: 5,
};

export const rowValuesSetCash = {
  date: moment().format('YYYY-MM-DD'),
  price: '',
  type: 1,
};

export const rowValuesSetOther = {
  date: moment().format('YYYY-MM-DD'),
  subType: {value: null},
  transactionId: '',
  price: '',
  type: 11,
};

export const rowValuesSetWithholdingTax = {
  price: '',
  type: 0,
};

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Modal from "react-bootstrap/Modal";
import CommonButton from "../../../_common/components/CommonButton";
import styles from './styles.module.scss';
import deleteFileIcon from "../../assets/delete.svg";
import {InteractionsMenuItem} from "../UploadedFile/InteractionsMenuItems";
import {useCustomerDetailsUIContext} from "../CustomerDetailsUIContext";


const PopupDeleteFile = ({fileName, fileID}) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const {handleFileDelete} = useCustomerDetailsUIContext();

  return (
    <>
      <InteractionsMenuItem
        label={
          <FormattedMessage
            id="delete-file"
            defaultMessage="delete file"
          />
        }
        icon={deleteFileIcon}
        onClick={() => handleShowModal()}
      />
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <div className={styles.popupDeleteFileContainer}>
            <p className={styles.question}>
              <FormattedMessage
                id="are-you-sure-to-delete-file"
                defaultMessage="are you sure you want to delete file"
              />
            </p>
            <p className={styles.fileName}>{fileName}</p>
          </div>
          <div className={styles.buttonContainer}>
            <CommonButton
              label={
                <FormattedMessage
                  id="button-close"
                  defaultMessage="Close"
                />
              }
              onClick={handleCloseModal}
            />
            <CommonButton
              label={
                <FormattedMessage
                  id="delete-file"
                  defaultMessage="delete file"
                />
              }
              customButtonClassNames={'w-100px btn btn-sm btn-warning d-flex align-items-center text-nowrap font-size-lg text-dark'}
              onClick={() => {
                handleFileDelete(fileName, fileID);
                handleCloseModal();
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PopupDeleteFile.propTypes = {
  fileName: PropTypes.string,
  fileID: PropTypes.number,
}

export default PopupDeleteFile;

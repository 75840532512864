import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import useActiveLinkClass from '../../../_common/components/_custom-hooks/useActiveLinkClass';
import {pathnames as P} from '../../../_common/constants/pathnames';

const OrderDetailsLink = () => {
  const {activeClassName} = useActiveLinkClass({url: P.ORDER_DETAILS});

  return activeClassName && (
    <li
      className={`menu-item ${activeClassName}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={"#"}>
        <img
          src={'../../media/common/Folder.svg'}
          alt="add"
          className="mr-3"
        />
        <div className="menu-text">
          <span className="font-size-lg">
            <FormattedMessage
            id="order-details-page-link"
            defaultMessage="Order details"
          />
          </span>
        </div>
      </NavLink>
    </li>
  );
};

export default OrderDetailsLink;

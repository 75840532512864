import React from 'react';
import {FormattedMessage} from 'react-intl';
import FieldCheckbox from '../../../_common/components/FieldCheckbox';
import styles from '../styles.module.scss';

export const SectionAdditionalInfo = ({formik, fieldNames}) => {
  const {values} = formik;

  return (
    <div className={styles.sectionContainer}>
      <div className={`${styles.formContainer} h-100`}>
        <>
          <h4>
            <FormattedMessage
              id="inspection-details-page-section-moreInfo"
              defaultMessage="More info"
            />
          </h4>
          <div className={styles.itemContainer}>
            <FieldCheckbox
              fieldId={fieldNames.elevator}
              label={
                <FormattedMessage
                  id="inspection-details-page-elevator-label"
                  defaultMessage="Elevator"
                />
              }
              value={values.elevator}
            />
          </div>
          <div className={styles.itemContainer}>
            <FieldCheckbox
              fieldId={fieldNames.parking}
              label={
                <FormattedMessage
                  id="inspection-details-page-parking-label"
                  defaultMessage="Parking"
                />
              }
              value={values.parking}
            />
          </div>
          <div className={styles.itemContainer}>
            <FieldCheckbox
              fieldId={fieldNames.yard}
              label={
                <FormattedMessage
                  id="inspection-details-page-yard-label"
                  defaultMessage="Yard"
                />
              }
              value={values.yard}
            />
          </div>
          <div className={styles.itemContainer}>
            <FieldCheckbox
              fieldId={fieldNames.storage}
              label={
                <FormattedMessage
                  id="inspection-details-page-storage-label"
                  defaultMessage="Storage"
                />
              }
              value={values.storage}
            />
          </div>
          <div className={styles.itemContainer}>
            <FieldCheckbox
              fieldId={fieldNames.roof}
              label={
                <FormattedMessage
                  id="inspection-details-page-roof-label"
                  defaultMessage="Roof"
                />
              }
              value={values.roof}
            />
          </div>
        </>
      </div>
    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Field} from 'formik';
import {commonStyles} from "../../selectStyles/customSelectStyles";

const CustomSelect = ({
                        value,
                        customContainerStyles,
                        customLabelStyles,
                        customStyles = commonStyles,
                        label,
                        placeholder = 'בחר',
                        onChange,
                        disabled,
                        options,
                        isSearchable = false,
                        ...rest
                      }) => {

  const commonProps = {
    value: value,
    onChange: onChange,
    placeholder,
    isSearchable,
    isDisabled: disabled,
    ...rest,
  };
  const staticProps = {
    options
  };
  const props = {...commonProps, ...staticProps};

  return (
    <div className={customContainerStyles}>
      {label && (
        <div className={customLabelStyles}>
          {label}
        </div>
      )}
      <Select styles={customStyles} {...props}/>
    </div>
  );
};

export const StyledFieldSelect = ({
                                    fieldId,
                                    onChange,
                                    ...rest
                                  }) => {

  return (
    <CustomSelect onChange={onChange} fieldId={fieldId} {...rest} />
  )
};

const FieldSelect = ({
                       fieldId,
                       onChange,
                       ...rest
                     }) => {

  const handleChange = (form, field) => option => {
    form.setFieldValue(field.name, option.value);
    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <Field
      id={fieldId}
      name={fieldId}
    >
      {fieldProps => <CustomSelect {...fieldProps} onChange={handleChange(fieldProps.form, fieldProps.field)} value={fieldProps.field.value} {...rest} />}
    </Field>
  )
};

FieldSelect.propTypes = {
  fieldId: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.node,
  customContainerStyles: PropTypes.string,
  customLabelStyles: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldSelect;

import React from 'react';
import {FormattedMessage} from "react-intl";
import {useCustomerDetailsUIContext} from "../CustomerDetailsUIContext";
import {PopupCreatePaymentLinkProvider} from "../PopupCreatePaymentLinkContext";
import DocumentItem from "../DocumentItem";
import SimpleButtonDropdown from "../../../_common/components/SimpleButtonDropdown";
import PopupCreateDocument from "../PopupCreateDocument";
import Empty from "../../../_common/components/Empty";
import PopupCreatePaymentLink from "../PopupCreatePaymentLink";
import AnimatedUploadingFilePopUp from "../../../_common/components/AnimatedUploadingFilePopUp";
import dots from "../../assets/interaction.svg";
import {documentTypes as T} from "../../constants/documentTypes";

const TabDocuments = () => {
  const {documentsData, creatingDoc} = useCustomerDetailsUIContext();

  const mapDocs = (item) => {

    return (
      <DocumentItem key={item.id} data={item}/>
    );
  };
  const customDropdownItemStyles = 'w-100 p-0';

  return (
    <>
      <AnimatedUploadingFilePopUp show={creatingDoc}/>
      <div className='h-40px mb-2 w-100 d-flex justify-content-start'>
        <SimpleButtonDropdown
          title={
            <div className='p-2'>
              <img
                style={{width: '24px'}}
                src={dots}
                alt="dots"
              />
            </div>
          }
          items={[
            {
              itemContent: (
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id="customer-details-documentInfo-label-button-create-10"
                      defaultMessage="Create price quote"
                    />
                  }
                  documentType={T.PRICE_QUOTE}
                />
              ),
              customStyles: customDropdownItemStyles,
            },
            {
              itemContent: (
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id="customer-details-documentInfo-label-button-create-100"
                      defaultMessage="Create work order"
                    />
                  }
                  documentType={T.WORK_ORDER}
                />
              ),
              customStyles: customDropdownItemStyles,
            },
            {
              itemContent: (
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id="customer-details-documentInfo-label-button-create-300"
                      defaultMessage="Create transaction account"
                    />
                  }
                  documentType={T.TRANSACTION_ACCOUNT}
                />
              ),
              customStyles: customDropdownItemStyles,
            },
            {
              itemContent: (
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id="customer-details-documentInfo-label-button-create-305"
                      defaultMessage="Create invoice"
                    />
                  }
                  documentType={T.INVOICE}
                />
              ),
              customStyles: customDropdownItemStyles,
            },
            {
              itemContent: (
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id="customer-details-documentInfo-label-button-create-400"
                      defaultMessage="Create receipt"
                    />
                  }
                  documentType={T.RECEIPT}
                />
              ),
              customStyles: customDropdownItemStyles,
            },
            {
              itemContent: (
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id="customer-details-documentInfo-label-button-create-320"
                      defaultMessage="Create transaction account"
                    />
                  }
                  documentType={T.INVOICE_RECEIPT}
                />
              ),
              customStyles: customDropdownItemStyles,
            },
            {
              itemContent: (
                <PopupCreatePaymentLinkProvider>
                  <PopupCreatePaymentLink/>
                </PopupCreatePaymentLinkProvider>
              ),
              customStyles: customDropdownItemStyles,
            },
          ]}
        />
      </div>
      <div className="w-100 h-1000px overflow-auto align-content-start d-flex flex-wrap justify-content-start px-8 pb-4">
        {documentsData.length > 0
          ? documentsData.map(mapDocs)
          : (
            <div className='w-100 h-100 d-flex align-items-center'>
              <Empty/>
            </div>
          )
        }
      </div>
    </>
  );
};

export default TabDocuments;

import React from 'react';
import * as _ from 'lodash';
import {getSection9} from './Section9';
import SectionTitle from '../../SectionTitle';
import styles from './styles.module.scss';

export const getShumaType3Section9 = (context) => {
  const {
    propertyLocation,
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other
  } = context.data.pdfData;
  const somePage8ValuesExist = _.some([
    propertyLocation,
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other
  ], Boolean);
  const title = (
    <>
      <span>{context.sectionNumbers.section9}</span>
      <span>. עקרונות גורמים ושיקולים לשומה</span>
    </>
  );
  const section9 = (somePage8ValuesExist) ? getSection9(context) : [];
  const sectionTitle = (somePage8ValuesExist) ? <SectionTitle title={title} key={0}/> : [];

  return [
    sectionTitle,
    ...section9,
    <div className={styles.lastSectionLine}/>
  ];
};

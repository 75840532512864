import React from 'react';
import classnames from 'classnames';
import {convertFileToContent} from '../../FileToContentConverter';
import {getSelectedOptionLabel} from '../../../utils/options';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import {completeCertOptions, dangerousStructureOptions, form4Options} from '../../../constants/options';
import {findFileByTypeId} from '../../../utils/files';
import styles from './styles.module.scss';

const fileTypeForm4 = 22;
const fileTypeCompleteCert = 23;
const fileTypeDangerousStructure = 24;

const mapTextLine = (item, index) => (
  <div
    className={classnames(styles.itemText, styles.innerPadding)}
    key={index + 1}
  >
    {item}
  </div>
);

const form4ValueLine = (context) => {
  const {
    data: {
      pdfData: {
        form4,
        completeCert,
        dangerousStructure,
        form4Text,
      },
      files
    },
  } = context;
  const form4Value = (Boolean(form4)) ? getSelectedOptionLabel(form4, form4Options) : '';
  const fileForm4 = findFileByTypeId(files, fileTypeForm4);
  let firstLineElement = (
    <>
      <span className={styles.bold}> הוצג בפני טופס 4: </span>
      <span data-testid="form4Value">{form4Value}.</span>
    </>
  );
  let form4TextChunks = [];
  if (form4Text && form4 === 1) {
    const [firstLineText, ...restChunks] = getTextChunks(form4Text);
    firstLineElement = (
      <>
        <span className={styles.bold}> הוצג בפני טופס 4: </span>
        <span data-testid="form4Value">{form4Value}</span>
        <span>{`, ${firstLineText}`}</span>
      </>
    );
    form4TextChunks = restChunks;
  }

  return (Boolean(form4))
    ? [
      <div
        className={classnames(styles.itemText, styles.innerPadding)}
        key={0}
      >
        {firstLineElement}
      </div>,
      ...form4TextChunks.map(mapTextChunks).map(mapTextLine),
      !completeCert
      && !dangerousStructure
      && !Boolean(fileForm4) && (
        <div
          className={styles.lastSectionLine}
          key={form4TextChunks.length + 2}
        />
      )
    ]
    : [];
};

const completeCertValueLine = (context) => {
  const {
    data: {
      pdfData: {
        completeCert,
        dangerousStructure,
        completeCertText,
      },
      files
    },
  } = context;
  const completeCertValue = (Boolean(completeCert)) ? getSelectedOptionLabel(completeCert, completeCertOptions) : '';
  const fileCompleteCert = findFileByTypeId(files, fileTypeCompleteCert);
  let firstLineElement = (
    <>
      <span className={styles.bold}>הוצג בפני תעודת גמר: </span>
      <span data-testid="completeCertValue">{completeCertValue}</span>
    </>
  );
  let completeCertTextChunks = [];
  if (completeCertText && completeCert === 1) {
    const [firstLineText, ...restChunks] = getTextChunks(completeCertText);
    firstLineElement = (
      <>
        <span className={styles.bold}>הוצג בפני תעודת גמר: </span>
        <span data-testid="completeCertValue">{completeCertValue}</span>
        <span>{`, ${firstLineText}`}</span>
      </>
    );
    completeCertTextChunks = restChunks;
  }

  return Boolean(completeCert)
    ? [
      <div
        className={classnames(styles.itemText, styles.innerPadding)}
        key={0}
      >
        {firstLineElement}
      </div>,
      ...completeCertTextChunks.map(mapTextChunks).map(mapTextLine),
      !dangerousStructure
      && !Boolean(fileCompleteCert) && (
        <div
          className={styles.lastSectionLine}
          key={completeCertTextChunks.length + 2}
        />
      )
    ]
    : [];
};

const dangerousStructureValueLine = (context) => {
  const {
    data: {
      pdfData: {
        dangerousStructure,
        dangerousStructureText,
      },
      files
    },
  } = context;
  const dangerousStructureValue = (Boolean(dangerousStructure)) ? getSelectedOptionLabel(dangerousStructure, dangerousStructureOptions) : '';
  const fileDangerousStructure = findFileByTypeId(files, fileTypeDangerousStructure);
  let firstLineElement = (
    <>
      <span className={styles.bold}>מבנה מסוכן: </span>
      <span data-testid="dangerousStructureValue">
        {dangerousStructureValue}
      </span>
    </>
  );
  let dangerousStructureTextChunks = [];
  if (dangerousStructureText && dangerousStructure === 1) {
    const [firstLineText, ...restChunks] = getTextChunks(dangerousStructureText);
    firstLineElement = (
      <>
        <span className={styles.bold}>מבנה מסוכן: </span>
        <span data-testid="dangerousStructureValue">
          {dangerousStructureValue}
        </span>
        <span>{`, ${firstLineText}`}</span>
      </>
    );
    dangerousStructureTextChunks = restChunks;
  }

  return Boolean(dangerousStructure)
    ? [
      <div
        className={classnames(styles.itemText, styles.innerPadding)}
        key={0}
      >
        {firstLineElement}
      </div>,
      ...dangerousStructureTextChunks.map(mapTextChunks).map(mapTextLine),
      !Boolean(fileDangerousStructure) && (
        <div
          className={styles.lastSectionLine}
          key={dangerousStructureTextChunks.length + 2}
        />
      )
    ]
    : [];
};

export const getForm4 = (context) => {
  const {
    data: {
      pdfData: {
        form4
      },
      files
    }
  } = context;
  const fileForm4 = findFileByTypeId(files, fileTypeForm4);

  if ((form4 === 1) && Boolean(fileForm4)) {
    return [
      ...form4ValueLine(context),
      ...convertFileToContent(context, fileTypeForm4, {
        className: styles.page
      })
    ];
  } else if (Boolean(form4)) {
    return form4ValueLine(context);
  } else {
    return [];
  }
};

export const getCompleteCert = (context) => {
  const {
    data: {
      pdfData: {
        completeCert
      },
      files,
    },
  } = context;
  const fileCompleteCert = findFileByTypeId(files, fileTypeCompleteCert);

  if ((completeCert === 1) && Boolean(fileCompleteCert)) {
    return [
      ...completeCertValueLine(context),
      ...convertFileToContent(context, fileTypeCompleteCert, {
        className: styles.page
      })
    ];
  } else if (Boolean(completeCert)) {
    return completeCertValueLine(context);
  } else {
    return [];
  }
};

export const getDangerousStructure = (context) => {
    const {
      data: {
        pdfData: {
          dangerousStructure,
        },
        files
      }
    } = context;
    const fileDangerousStructure = findFileByTypeId(files, fileTypeDangerousStructure);

    if ((dangerousStructure === 1) && Boolean(fileDangerousStructure)) {
      return [
        ...dangerousStructureValueLine(context),
        ...convertFileToContent(context, fileTypeDangerousStructure, {
          className: styles.page
        })
      ];
    } else if (Boolean(dangerousStructure)) {
      return dangerousStructureValueLine(context);
    } else {
      return [];
    }
  }
;

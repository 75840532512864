import React from 'react';
import {useHeader} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import SubheaderBar from '../../_common/components/SubheaderBar';
import TasksFilters from '../components/TasksFilters';
import TasksTable from '../components/TasksTable';
import {TasksUIProvider} from '../components/TasksUIContext';

const Tasks = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'tasks-page-header',
    defaultMessage: 'Tasks page'
  }));

  return (
    <TasksUIProvider>
      <SubheaderBar>
        <TasksFilters/>
      </SubheaderBar>
      <TasksTable/>
    </TasksUIProvider>
  );
};

export default Tasks;

import React from 'react';
import {useIntl} from 'react-intl';
import {useParams} from "react-router-dom";
import {useHeader} from '../../../../_metronic/layout';
import SubheaderBar from "../../_common/components/SubheaderBar";
import EditPanel from '../components/EditPanel/EditPanel';
import {CustomerDetailsUIProvider} from '../components/CustomerDetailsUIContext';
import SectionsContainer from "../components/SectionsContainer";


const CustomerDetails = () => {
  const {customerID} = useParams();
  const header = useHeader();
  const intl = useIntl();
  const editCustomerHeader = intl.formatMessage({
    id: 'customer-details-page-header',
    defaultMessage: 'Edit customer',
  });

  header.setTitle(editCustomerHeader)

  return (
    <CustomerDetailsUIProvider customerID={customerID}>
      <SubheaderBar>
        <EditPanel/>
      </SubheaderBar>
      <SectionsContainer/>
    </CustomerDetailsUIProvider>
  );
};

export default CustomerDetails;

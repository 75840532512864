const defaultState = {
  statusList: [],
  loading: false,
  error: false,
  errorMessage: null
};
export const actionTypes = {
  getStatusList: 'GET_STATUS_LIST',
  catchError: 'CATCH_ERROR',
};
export const statusListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.getStatusList: {
      const {statusList} = action.payload;
      return {
        ...state,
        statusList,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.catchError: {
      const error = `STATUS_LIST: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
}
export const actions = {
  getUsers: (data) => ({
    type: actionTypes.getStatusList,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: error
  }),
};

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const Section6Text75 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseRoomCount,
  } = context.data.pdfData;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>מס' חדרים: </span>
      {houseRoomCount}.
    </div>
  );
});

export default Section6Text75;

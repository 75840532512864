import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useTable} from 'react-table';
import {useHistory} from "react-router-dom";
import * as actions from '../../actions/CustomersEntityTableActions';
import * as columnFormatters from '../../../_common/column-formatters';
import {useCustomersEntityUIContext} from '../CustomersEntityUIContext';
import useInfiniteScrollControl
  from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import CommonGrid from '../../../_common/components/CommonGrid';
import styles from './styles.module.scss';

export default function CustomersEntityTable() {
  const intl = useIntl();
  const history = useHistory();
  const [tableRows, setTableRows] = useState([]);
  const columns = useMemo(() => [
    {
      accessor: 'customerID',
      Header: intl.formatMessage({
        id: 'customers-entity-column-customerID', defaultMessage: 'Customer ID'
      })
    },
    {
      accessor: 'fullName',
      Header: intl.formatMessage({
        id: 'customers-entity-column-full-name', defaultMessage: 'Name'
      })
    },
    {
      accessor: 'phone',
      Header: intl.formatMessage({
        id: 'customers-entity-column-phone', defaultMessage: 'Phone'
      })
    },
    {
      accessor: 'email',
      Header: intl.formatMessage({
        id: 'customers-entity-column-email', defaultMessage: 'Email'
      })
    },
    {
      accessor: 'customerTypeName',
      Header: intl.formatMessage({
        id: 'customers-entity-column-customer-type', defaultMessage: 'Customer type'
      })
    },
    {
      accessor: 'idNumber',
      Header: intl.formatMessage({
        id: 'customers-entity-column-id-number', defaultMessage: 'ID number'
      })
    },
    {
      accessor: 'customerStatus',
      Header: intl.formatMessage({
        id: 'customers-entity-column-customerStatus', defaultMessage: 'Status'
      })
    },
    {
      accessor: 'edit',
      Header: intl.formatMessage({
        id: 'customers-entity-column-edit', defaultMessage: 'Edit'
      })
    },
    {
      accessor: 'deleteRestore',
      Header: intl.formatMessage({
        id: 'customers-entity-column-delete', defaultMessage: 'Delete'
      })
    },
  ], []);
  const data = useMemo(() => [
    ...tableRows.map((item) => {
      return {
        customerID: columnFormatters.BoldColumnFormatter(item.customerID),
        fullName: columnFormatters.BoldColumnFormatter([item.firstName, item.lastName].filter(Boolean).join(' ')),
        phone: columnFormatters.BoldColumnFormatter(item.phone),
        email: columnFormatters.BoldLTRColumnFormatter(item.email),
        customerTypeName: columnFormatters.BoldColumnFormatter(item.customerTypeName),
        idNumber: columnFormatters.BoldColumnFormatter(item.idNumber),
        customerStatus: columnFormatters.BoldColumnFormatter(item.customerStatus),
        edit: columnFormatters.EditCustomerColumnFormatter(item, history),
        deleteRestore: columnFormatters.DeleteRestoreCustomerColumnFormatter(item),
      };
    })
  ], [tableRows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'customersEntityTableReducer',
    UIContext: useCustomersEntityUIContext(),
    getNewEntities: actions.getCustomers,
    getNextEntities: actions.getNextCustomers,
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([]);
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-50px d-flex justify-content-center'}>
        <span className="spinner spinner-md spinner-warning"/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  const gridProps = {
    infiniteScrollProps,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows
  };

  return (
    <CommonGrid
      customHeaderStyles={styles.customHeader}
      customBodyStyles={styles.customBody}
      {...gridProps}
    />
  );
}

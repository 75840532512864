import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import moment from 'moment';
import {
  getTaskDetails,
  editVisitTask,
  editGeneralTask
} from "../../actions/TasksTableActions";
import {taskTypeIDs} from "../../../_common/constants/taskTypes";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {taskStatuses} from "../../../_common/constants/options";
import {filterOptions} from "../../../_common/utils/options";

const PopupEditTasksContext = createContext('');

export function usePopupEditTasksContext() {
  return useContext(PopupEditTasksContext);
}

export function PopupEditTasksContextProvider({children}) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );
  const getData = async (id) => {
    try {
      let result = await getTaskDetails(id);
      setData(result);
    } catch (e) {
      console.error(`Can\'t get data, ${e}`);
    }
  };

  const initialValues = {
    taskID: data?.taskID || '',
    responsibleUserID: data?.responsibleUserID || '',
    taskTypeID: data?.taskTypeID || 1,
    taskStatusID: data?.taskStatusID || 1,
    taskDueDate: (data?.dueDate) ? moment(data.dueDate).toDate() : null,
    notes: data?.notes || '',
    taskDescription: data?.taskDescription || '',
    visitContactName: data?.visitContactName || '',
    visitContactPhone: data?.visitContactPhone || ''
  };

  const validate = (values) => {
    let errors = {};
    const {
      taskTypeID,
      responsibleUserID,
      taskStatusID,
      taskDescription,
      taskDueDate,
    } = values;

    const commonFields = {
      responsibleUserID,
      taskStatusID,
      taskDueDate,
    };

    const arrCommonFields = Object.keys(commonFields);

    arrCommonFields.forEach(function (item) {
      if (!values[item]) errors[item] = 'Required field';
    });

    if (taskTypeID === taskTypeIDs.general) {
      if (!taskDescription) errors[`taskDescription`] = 'Required field';
    }

    return errors;
  };

  const edit = async () => {
    try {
      if (formik.values.taskTypeID === taskTypeIDs.visit) {
        await dispatch(editVisitTask({
          taskID: formik.values.taskID,
          shumaID: data?.shumaID,
          responsibleUserID: formik.values.responsibleUserID,
          username: filterOptions(formik.values.responsibleUserID)(users)[0].label,
          taskStatusID: formik.values.taskStatusID,
          taskStatus: filterOptions(formik.values.taskStatusID)(taskStatuses)[0].label,
          dueDate: formik.values.taskDueDate.toString(),
          notes: formik.values.notes,
          visitContactName: formik.values.visitContactName,
          visitContactPhone: formik.values.visitContactPhone
        }))
      } else {
        await dispatch(editGeneralTask({
          taskID: formik.values.taskID,
          shumaID: data?.shumaID,
          responsibleUserID: formik.values.responsibleUserID,
          username: filterOptions(formik.values.responsibleUserID)(users)[0].label,
          taskStatusID: formik.values.taskStatusID,
          taskStatus: filterOptions(formik.values.taskStatusID)(taskStatuses)[0].label,
          dueDate: formik.values.taskDueDate.toString(),
          taskDescription: formik.values.taskDescription
        }))
      }
    } catch (e) {
      console.error(e);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const value = {
    getData,
    data,
    formik,
    edit,
  };

  return (
    <PopupEditTasksContext.Provider value={value}>
      {children}
    </PopupEditTasksContext.Provider>
  );
}

PopupEditTasksContextProvider.propTypes = {
  children: PropTypes.node,
};

import moment from "moment";
import {isEqual} from "lodash";

export const prepareFilter = (queryParams, values) => {
  const newQueryParams = {...queryParams};
  const filter = {};

  for (let key in values) {
    if (values[key] !== '') {
      if (key === 'fromDate') {
        filter.fromDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'toDate') {
        filter.toDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'fromCreateDate') {
        filter.fromCreateDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'toCreateDate') {
        filter.toCreateDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'createdFromDate') {
        filter.createdFromDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'createdToDate') {
        filter.createdToDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'dueFromDate') {
        filter.dueFromDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'dueToDate') {
        filter.dueToDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'fromShumaDueDate') {
        filter.fromShumaDueDate = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'toShumaDueDate') {
        filter.toShumaDueDate = moment(values[key]).format('YYYY-MM-DD');

      } else {
        filter[key] = values[key];
      }

    } else {
      delete newQueryParams[key];
    }
  }

  return {...newQueryParams, ...filter};
};

export const applyFilter = (values, props) => {
  const newQueryParams = prepareFilter(props.queryParams, values);
  if (!isEqual(newQueryParams, props.queryParams)) {
    props.setQueryParams(newQueryParams);
  }
};

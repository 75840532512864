export const getUniqueElementsFromArray = (arr) => {
  let preparedArray = [];
  if (Boolean(arr) && typeof arr === 'string') {
    preparedArray = arr.split(',');
  } else if (Boolean(arr) && (arr instanceof Array)) {
    preparedArray = arr;
  }

  let result = [];
  if (preparedArray.length > 0) {
    preparedArray.map(item => {
      if (!result.includes(item)) {
        result.push(item);
      }
    })
  }

  return result;
}


import React, {createContext, useContext, useEffect, useState} from 'react';
import {
  getCitiesList,
  getStreetsList,
  addStreet as addStreetRequest,
  StreetExistError
} from '../actions/citiesAndStreetsActions';
import {useCacheContext} from '../../AppCahce/AppCache';
import {messages as M} from '../../../../../../_metronic/i18n/messages';

const defaultCitiesContext = {
  cities: []
};

const CitiesContext = createContext();

export function useCitiesContext() {
  return useContext(CitiesContext);
}

export function CitiesProvider({children}) {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const {getItem, setItem} = useCacheContext();

  useEffect(() => {
    if (cities.length === 0) {
      setLoading(true);
      updateCities()
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, []);

  const updateCities = async () => {
    const cachedList = getItem('cities');
    if (cachedList) {
      setCities(cachedList);
    } else {
      const list = await getCitiesList();
      setCities(list);
      setItem('cities', list);
    }
  };

  const ctx = {
    ...defaultCitiesContext,
    cities: cities || []
  };

  return (
    <CitiesContext.Provider value={ctx}>
      {children}
    </CitiesContext.Provider>
  );
}

const defaultStreetsContext = {
  streets: null,
  updateStreets: () => {},
  addStreet: () => {},
};

const StreetsContext = createContext();

export function useStreetsContext() {
  return useContext(StreetsContext);
}

export function StreetsProvider({children}) {
  const [streets, setStreets] = useState(null);
  const {getItem, setItem} = useCacheContext();

  const updateStreets = async cityID => {
    const cachedList = getItem(`streets-${cityID}`);
    if (cachedList) {
      setStreets(cachedList);
    } else {
      const list = await getStreetsList(cityID);
      setStreets(list);
      setItem(`streets-${cityID}`, list);
    }
  };

  const addStreet = async (cityID, streetName) => {
    let response = {errorMessage: '', list: []};
    try {
      const list = await addStreetRequest(cityID, streetName);
      setStreets(list);
      setItem(`streets-${cityID}`, list);
      response = {...response, list};
    } catch (e) {
      response = {...response, errorMessage: (e instanceof StreetExistError) ? M.ADD_STREET_NAME_EXISTS : M['-1']}
    }

    return response;
  }

  const ctx = {
    ...defaultStreetsContext,
    streets: streets,
    updateStreets,
    addStreet
  };

  return (
    <StreetsContext.Provider value={ctx}>
      {children}
    </StreetsContext.Provider>
  );
}

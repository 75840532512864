import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from "react";
import {useHistory} from "react-router-dom";
import {isEqual, isFunction} from "lodash";
import {useDispatch} from "react-redux";
import {getDashboardDataSimpleDateRange} from './actions/DashboardPageChartsActions';
import {showGlobalMessage} from "../_common/components/GlobalMessage/actions/showGlobalMessage";
import {messages as M} from '../../../_metronic/i18n/messages';

const SimpleDateRangeContext = createContext('');

export function useSimpleDateRangeContext() {
  return useContext(SimpleDateRangeContext);
}

export function SimpleDateRangeProvider({children}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [simpleQueryParams, setSimpleQueryParamsBase] = useState({
    simpleDateRange: 'last 6 months',
    fromDate: '',
    toDate: '',
  });
  const setSimpleQueryParams = useCallback(nextSimpleQueryParams => {
    setSimpleQueryParamsBase(prevSimpleQueryParams => {
      if (isFunction(nextSimpleQueryParams)) {
        nextSimpleQueryParams = nextSimpleQueryParams(prevSimpleQueryParams);
      }

      if (isEqual(prevSimpleQueryParams, nextSimpleQueryParams)) {
        return prevSimpleQueryParams;
      }
      for (let key in nextSimpleQueryParams) {
        if (nextSimpleQueryParams.hasOwnProperty(key) && !nextSimpleQueryParams[key]) delete nextSimpleQueryParams[key]
      }
      const path = history.location.pathname;
      const newSimpleQueryParams = new URLSearchParams(nextSimpleQueryParams);
      history.replace(`${path}?${newSimpleQueryParams.toString()}`);

      return nextSimpleQueryParams;
    });
  }, []);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      let result = await getDashboardDataSimpleDateRange({
        dateRange: simpleQueryParams.simpleDateRange,
        fromDate: simpleQueryParams.fromDate ? simpleQueryParams.fromDate : '',
        toDate: simpleQueryParams.toDate ? simpleQueryParams.toDate : '',
      });
      setData(result);
    } catch (e) {
      console.log(e);
      dispatch(showGlobalMessage(M["-1"]))
      setData([])
    }
  };

  useEffect(() => {
    getData();
  }, [simpleQueryParams]);

  const value = {
    simpleQueryParams,
    setSimpleQueryParamsBase,
    setSimpleQueryParams,
    data,
  };

  return (
    <SimpleDateRangeContext.Provider value={value}>
      {children}
    </SimpleDateRangeContext.Provider>
  );
}

import {actions as A} from '../../../constants/actions';

const hideGlobalMessage = () => {
  return (dispatch) => {
    dispatch({
      type: A.HIDE_GLOBAL_MESSAGE,
    });
  }
};

export {hideGlobalMessage}

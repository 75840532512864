export const rowFieldsSetCommonDocs = (index) => {
  return {
    description: `rowsCommonDocs.${index}.description`,
    quantity: `rowsCommonDocs.${index}.quantity`,
    price: `rowsCommonDocs.${index}.price`,
    vatType: `rowsCommonDocs.${index}.vatType`,
  }
};

export const rowFieldsSetCheque = (index) => {
  return {
    date: `rowsCheque.${index}.date`,
    bankName: `rowsCheque.${index}.bankName`,
    bankBranch: `rowsCheque.${index}.bankBranch`,
    bankAccount: `rowsCheque.${index}.bankAccount`,
    chequeNum: `rowsCheque.${index}.chequeNum`,
    price: `rowsCheque.${index}.price`,
    type: `rowsCheque.${index}.type`,
  }
};

export const rowFieldsSetBankTransfer = (index) => {
  return {
    date: `rowsBankTransfer.${index}.date`,
    bankName: `rowsBankTransfer.${index}.bankName`,
    bankBranch: `rowsBankTransfer.${index}.bankBranch`,
    bankAccount: `rowsBankTransfer.${index}.bankAccount`,
    price: `rowsBankTransfer.${index}.price`,
    type: `rowsBankTransfer.${index}.type`,
  }
};

export const rowFieldsSetCreditCard = (index) => {
  return {
    date: `rowsCreditCard.${index}.date`,
    cardNum: `rowsCreditCard.${index}.cardNum`,
    cardType: `rowsCreditCard.${index}.cardType`,
    dealType: `rowsCreditCard.${index}.dealType`,
    numPayments: `rowsCreditCard.${index}.numPayments`,
    price: `rowsCreditCard.${index}.price`,
    type: `rowsBankTransfer.${index}.type`,
  }
};

export const rowFieldsSetPaymentApp = (index) => {
  return {
    date: `rowsPaymentApp.${index}.date`,
    appType: `rowsPaymentApp.${index}.appType`,
    accountId: `rowsPaymentApp.${index}.accountId`,
    transactionId: `rowsPaymentApp.${index}.transactionId`,
    price: `rowsPaymentApp.${index}.price`,
    type: `rowsPaymentApp.${index}.type`,
  }
};

export const rowFieldsSetPayPal = (index) => {
  return {
    date: `rowsPayPal.${index}.date`,
    accountId: `rowsPayPal.${index}.accountId`,
    transactionId: `rowsPayPal.${index}.transactionId`,
    price: `rowsPayPal.${index}.price`,
    type: `rowsPayPal.${index}.type`,
  }
};

export const rowFieldsSetCash = (index) => {
  return {
    date: `rowsCash.${index}.date`,
    price: `rowsCash.${index}.price`,
    type: `rowsCash.${index}.type`,
  }
};

export const rowFieldsSetOther = (index) => {
  return {
    date: `rowsOther.${index}.date`,
    subType: `rowsOther.${index}.subType`,
    transactionId: `rowsOther.${index}.transactionId`,
    price: `rowsOther.${index}.price`,
    type: `rowsOther.${index}.type`,
  }
};

export const rowFieldsSetWithholdingTax = (index) => {
  return {
    price: `rowsWithholdingTax.${index}.price`,
    type: `rowsWithholdingTax.${index}.type`,
  }
};

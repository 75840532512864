import React from 'react';
import _ from 'lodash';
import AssetsTitle from '../../AssetsTitle';
import AssetsTable from '../../AssetsTable';

const ROWS_PER_TABLE_LIMIT = 24;

const prepareTables = unpreparedTables => {
  return unpreparedTables
    .map(table => {
      if (table.rows.length > ROWS_PER_TABLE_LIMIT) {
        return _.chunk(table.rows, ROWS_PER_TABLE_LIMIT).map(rows => {
          return {
            ...table,
            rows
          };
        });
      } else {
        return table;
      }
    })
    .flat()
    .filter(table => table.rows.length > 0);
};

const groupTablesByPage = tables => tables.reduce((acc, table) => {
  if (acc.length === 0) {
    return [[table]];
  } else {
    const currentPage = _.last(acc);
    /* total rows on the page amount is number of rows on each page
    * and two rows for the column header and the table title
    * for each page except first one */
    const rowsOnCurrentPage = currentPage.reduce((acc, currentTable) => currentTable.rows.length + acc, 0) + (currentPage.length - 1) * 2;
    const totalExpectedRows = rowsOnCurrentPage + table.rows.length;
    if (totalExpectedRows >= ROWS_PER_TABLE_LIMIT) {
      return [...acc, [table]];
    } else {
      const previousPages = _.dropRight(acc, 1);
      return [...previousPages, [...currentPage, table]];
    }
  }
}, []);

export const getSelectedRowsAndColumnsTables = (context) => {
  const step9Tables = prepareTables(context.data?.step9Tables || []);
  const tableGroupsByPage = groupTablesByPage(step9Tables);

  const renderPage = (page) => {
    return page.map((table, index) => {
      return (
        <>
          {(index === 0) && <AssetsTitle/>}
          <AssetsTable table={table} key={index}/>
        </>
      );
    });
  };

  return tableGroupsByPage.map(renderPage).flat();
};

export const uploadFileTypes = [
  {fileTypeID: 10},
  {fileTypeID: 13},
  {fileTypeID: 12},
  {fileTypeID: 16},
  {fileTypeID: 1},
  {fileTypeID: 18},
  {fileTypeID: 19},
  {fileTypeID: 20},
  {fileTypeID: 25},
  {fileTypeID: 15},
  {fileTypeID: 23},
  {fileTypeID: 22},
  {fileTypeID: 24},
  {fileTypeID: 11},
  {fileTypeID: 21},
  {fileTypeID: 17},
];

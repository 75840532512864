import React from 'react';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../utils/options';
import {
  presenterGenderFirstLabelOptions,
  presenterGenderSecondLabelOptions,
  presenterGenderThirdLabelOptions,
  presentorAssociationOptions
} from '../../constants/options';

const PresenterDetails = () => {
  const {
    data: {
      pdfData: {
        presentorName,
        presentorGender,
        presentorIDNumber,
        presentorAssociation
      },
    }
  } = useCompleteInspectionContext();

  const firstGenderLabel = getSelectedOptionLabel(presentorGender, presenterGenderFirstLabelOptions);
  const secondGenderLabel = getSelectedOptionLabel(presentorGender, presenterGenderSecondLabelOptions);
  const thirdGenderLabel = getSelectedOptionLabel(presentorGender, presenterGenderThirdLabelOptions);
  const presentorAssociationValue = getSelectedOptionLabel(presentorAssociation, presentorAssociationOptions);

  return Boolean(presentorName)
    ? (
      <span data-testid="presenter-details">
 , בנוכחות {firstGenderLabel} {presentorName}
        {Boolean(presentorIDNumber) && (
          <span>, אשר {secondGenderLabel} על ידי ת.ז מספר {presentorIDNumber}</span>
        )}
        . {thirdGenderLabel} לנכס: {presentorAssociationValue}.
      </span>
    )
    : (
      <span>, בידיעת מזמין חוות הדעת נערך ביקור בנכס ללא נוכחות נציג.</span>
    );
};

export default PresenterDetails;

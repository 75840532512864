const defaultState = {
  showUploadingFile: false
};

const actionTypes = {
  SHOW_UPLOADING_FILE_POP_UP: 'SHOW_UPLOADING_FILE_POP_UP',
  HIDE_UPLOADING_FILE_POP_UP: 'HIDE_UPLOADING_FILE_POP_UP'
}

export default (state = defaultState, {type}) => {
  switch(type) {
    case actionTypes.SHOW_UPLOADING_FILE_POP_UP:
      return {...state, showUploadingFile: true};

    case actionTypes.HIDE_UPLOADING_FILE_POP_UP:
      return {...state, showUploadingFile: false};

    default:
      return state;
  }
}

export const actions = {
  showUploadingFilePopup: () => ({
    type: actionTypes.SHOW_UPLOADING_FILE_POP_UP,
    payload: {}
  }),
  hideUploadingFilePopup: () => ({
    type: actionTypes.HIDE_UPLOADING_FILE_POP_UP,
    payload: {}
  }),
};

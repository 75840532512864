/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {Layout} from '../_metronic/layout';
import BasePage from './BasePage';
import {Logout, AuthPage} from './modules/Auth';
import GlobalMessage from './modules/_common/components/GlobalMessage';
import PermissionsProvider
  from './modules/_common/components/Permissions/PermissionsProvider';
import {ErrorPage} from './modules/Error';
import withRefreshToken from './modules/Auth/components/withRefreshToken';

const Content = withRefreshToken(() => (
  <PermissionsProvider>
    <Layout>
      <BasePage/>
      <GlobalMessage/>
    </Layout>
  </PermissionsProvider>
))

function GlobalRoutes() {
  const {isAuthorized} = useSelector(
    ({auth}) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage/>
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/dashboard-page"/>
      )}

      <Route path="/error" component={ErrorPage}/>
      <Route path="/logout" component={Logout}/>

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login"/>
      ) : (
          /* Asynchronously updates users permissions or app refresh */
        <Content/>
      )}

    </Switch>
  );
}

export const Routes = GlobalRoutes;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import UploadingFilePopUp from '../UploadingFilePopUp/UploadingFilePopUp';

const AnimatedUploadingFilePopUp = ({
                                      show,
                                      duration = 50,
                                      percentsPerSec = 2,
                                      delayGrowFactor = 0.1,
                                      progressGrowSize = 10,
                                      type = 'slowing'
}) => {
  const [progress, setProgress] = useState(0);
  const [timeouts, setTimeouts] = useState([]);

  const saveTimeout = timeoutID => setTimeouts(prevState => {
    return [...prevState, timeoutID];
  });

  const clearTimeouts = () => {
    timeouts.forEach(timeoutID => clearTimeout(timeoutID));
  }

  useEffect(() => {
    if (show) {
      setProgress(0);
      uploadingProgress();
    } else {
      clearTimeouts();
    }
  }, [show]);

  const calculateInitialDelay = () => {
    switch (type) {
      case 'slowing':
        return 500;
      case 'linear':
      default:
        return 1000;
    }
  };

  const calculateNextStepDelay = delay => {
    switch (type) {
      case 'slowing':
        return delay * delayGrowFactor;
      case 'linear':
      default:
        return delay;
    }
  };

  const calculateStepSize = () => {
    switch (type) {
      case 'slowing':
        return progressGrowSize;
      case 'linear':
      default:
        return percentsPerSec;
    }
  };

  const uploadingProgress = () => {
    const stepSize = calculateStepSize();
    let delay = calculateInitialDelay();
    let fakeProgress = 0;
    let timerId = setTimeout(function request() {
      fakeProgress += stepSize;
      setProgress(fakeProgress);
      if (fakeProgress < 100) {
        delay = calculateNextStepDelay(delay);
        timerId = setTimeout(request, delay);
        saveTimeout(timerId);
      } else {
        clearTimeouts();
      }
    }, delay);
    saveTimeout(timerId);
  };

  return (
    <UploadingFilePopUp
      showUploadingFilePopUp={show}
      progress={progress}
      message=""
    />
  );
};

AnimatedUploadingFilePopUp.propTypes = {
  show: PropTypes.bool,
  duration: PropTypes.number,
  percentsPerSec: PropTypes.number,
  type: PropTypes.oneOf(['linear', 'slowing'])
};

export default AnimatedUploadingFilePopUp;

import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import {getSelectedOptionLabel} from '../../../utils/options';
import {floorOptions} from '../../../constants/options';
import styles from './styles.module.scss';

const fileTypeID = 11;
const imagesPerRow = 2;

const Floor = ({files, label, className}) => {
  const mapImages = () => {
    return files.map((file, index) => {
      const {body, file_name, free_text, file_extension} = file;
      const src = getFullDataURI({body, file_extension});
      return (
        <div className={classnames(
          styles.imageItem,
          styles.innerPadding,
          {
            [styles.twoInRow]: files.length >= 2,
            [styles.oneInRow]: files.length < 2,
          }
        )} key={index}>
          <div className={styles.imageWrapper}>
            <img
              src={src}
              alt={file_name}
              key={index}
              className={styles.floorImage}
            />
            <div className={styles.freeText}>{free_text}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classnames(styles.floorContainer, className)}>
      {Boolean(label) && (
        <div className={classnames(styles.label, styles.innerPadding)}>
          {label}
        </div>
      )}
      <div className={styles.images}>
        {mapImages()}
      </div>
    </div>
  );
};

const getImagesSortedByFloor = (context) => context.files
  .filter(file => file.file_type_id === fileTypeID)
  .sort((prev, next) => {
    if (prev.free_id > next.free_id) {
      return 1;
    }
    if (prev.free_id < next.free_id) {
      return -1;
    }
    return 0;
  });

const FloorsHeader = () => (
  <div
    className={classnames(styles.bold, styles.marginVertFloors, styles.innerPadding)}>
    תמונות מיום הביקור:
  </div>
);

const mapFloors = imagesGroupedByFloor => (label) => {
  const images = imagesGroupedByFloor[label];
  const rows = _.chunk(images, imagesPerRow);

  return rows
    .map((row, index) => (
      <Floor
        files={row}
        label={(index === 0) && label}
        className={(index !== rows.length - 1) ? styles.floorRow : ''}
      />
    ));
};

export const getFloors = (context) => {
  const imagesSortedByFloor = getImagesSortedByFloor(context);
  const imagesWithNamedFloor = imagesSortedByFloor.map(image => {
    const labelText = getSelectedOptionLabel(image.free_id, floorOptions);
    const floorLabel = (image.free_id <= 3) ? `קומה ${labelText}` : labelText;

    return {...image, floorLabel};
  });
  const imagesGroupedByFloor = _.groupBy(imagesWithNamedFloor, 'floorLabel');
  const floorLabels = Object.keys(imagesGroupedByFloor);
  return floorLabels.map(mapFloors(imagesGroupedByFloor))
    .flat()
    .map((row, index) => {
      return (index === 0)
        ? (
          <div>
            <FloorsHeader/>
            {row}
          </div>
        )
        : row;
    });
};

import {actionTypes} from '../reducers/decisionTreeOptionsReducer';
import axios from 'axios';

export const GET_SHUMA_REASONS = '/generalData/getShumaReasons';
export const GET_BANKS = '/generalData/getBanksIDs';
export const GET_SHUMA_PROPERTY_TYPES = '/generalData/getPropertyTypeIDs';
export const GET_SHUMA_TYPES = 'generalData/getShumaTypeIDs';
export const GET_LOAN_TYPES = 'generalData/getLoanTypesIDs';
export const GET_LOAN_SUB_TYPES = 'generalData/getLoanSubTypesIDs';
export const GET_SHUMA_MAZMIN_IDENTITIES = 'generalData/getShumaMazminIdentityIDs';


export const getShumaReasons = () => dispatch => {
  return axios.post(GET_SHUMA_REASONS)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {reasons} = body;

      dispatch({
        type: actionTypes.getShumaReasons,
        payload: {reasons},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getBanks = () => dispatch => {
  return axios.post(GET_BANKS)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {banks} = body;

      dispatch({
        type: actionTypes.getBanks,
        payload: {banks},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getShumaPropertyTypes = () => dispatch => {
  return axios.post(GET_SHUMA_PROPERTY_TYPES)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {propertyTypes} = body;

      dispatch({
        type: actionTypes.getPropertyTypes,
        payload: {propertyTypes},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getShumaTypes = (shumaReasonID) => dispatch => {
  return axios.post(GET_SHUMA_TYPES, {shumaReasonID})
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaTypes} = body;

      dispatch({
        type: actionTypes.getShumaTypes,
        payload: {shumaTypes},
      });

      return shumaTypes
    })
};

export const getShumaTypesList = (shumaReasonID) => {
  return axios.post(GET_SHUMA_TYPES, {shumaReasonID})
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      return body.shumaTypes
    })
};

export const getLoanTypes = () => dispatch => {
  return axios.post(GET_LOAN_TYPES)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {loanTypes} = body;

      dispatch({
        type: actionTypes.getLoanTypes,
        payload: {loanTypes},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getLoanSubTypes = () => dispatch => {
  return axios.post(GET_LOAN_SUB_TYPES)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {loanSubTypes} = body;

      dispatch({
        type: actionTypes.getLoanSubTypes,
        payload: {loanSubTypes},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getShumaMazminIdentities = () => dispatch => {
  return axios.post(GET_SHUMA_MAZMIN_IDENTITIES)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaMazminIdentities} = body;

      dispatch({
        type: actionTypes.getShumaMazminIdentities,
        payload: {shumaMazminIdentities},
      });
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};


import React from 'react';
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Button} from "react-bootstrap";
import {downloadPriceQuoteFile} from "../../PriceQuotesPage/_redux/actions/PopupCreateEditPriceQuoteActions";
import {tooltips as TTS} from '../constants/tooltips';
import {can} from '../components/Permissions';
import {subjects as S, permissionTypes as T} from '../constants/permissionSubjects';

export function DownloadPriceQuoteColumnFormatter(cellContent, row, dispatch) {
  const {priceQuoteID, fileName} = row;

  const handleDownloadFile = () => {
    dispatch(downloadPriceQuoteFile(priceQuoteID, fileName))
  };

  return (
    <div>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={TTS.DOWNLOAD_FILE}
        disabled={!can(T.FULL, S.PRICE_QUOTES_PAGE) || !fileName}
        onClick={handleDownloadFile}
      >
        <img
          src={toAbsoluteUrl('media/common/Download.svg')}
          alt="download-file"
        />
      </Button>
    </div>
  );
}

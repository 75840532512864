import React, {useState, useEffect} from 'react';
import {useIntl} from "react-intl";
import {StyledFieldInput} from "../../../_common/components/FieldInput";
import FieldFindCustomers from "../../../_common/components/FieldFindCustomers";
import {StyledFieldSelect} from "../../../_common/components/FieldSelect";
import CommonButton from "../../../_common/components/CommonButton";
import ButtonUploadFile from "../../../_common/components/ButtonUploadFile";
import Spinner from '../../../_common/components/Spinner';
import AnimatedUploadingFilePopUp from "../../../_common/components/AnimatedUploadingFilePopUp";
import {filterOptions} from "../../../_common/utils/options";
import {formatStringToFloatNumber} from "../../../_common/utils/changeInputValue";
import {priceQuoteStatus} from '../../../_common/constants/options';

const PriceQuoteForm = ({
                          id,
                          formik,
                          closeModal,
                          handleApprove,
                          loadingData,
                          uploadFile,
                          uploadingFile,
                          handleDeleteFile,
                          handleDownloadFile
                        }) => {
  const intl = useIntl();
  const headerEdit = intl.formatMessage({
    id: 'price-quotes-page-popup-edit-price-quote',
    defaultMessage: 'Edit',
  });
  const headerCreate = intl.formatMessage({
    id: 'price-quotes-page-popup-create-price-quote',
    defaultMessage: 'Create',
  });
  const noOptionsMessage = intl.formatMessage({
    id: 'label-no-options',
    defaultMessage: 'No options',
  });
  const fieldStatus = intl.formatMessage({
    id: 'price-quotes-page-popup-form-field-status',
    defaultMessage: 'Firm users',
  });
  const fieldPrice = intl.formatMessage({
    id: 'price-quotes-page-popup-form-field-price',
    defaultMessage: 'Price',
  });
  const buttonLabelCreate = intl.formatMessage({
    id: 'price-quotes-page-popup-button-label-create',
    defaultMessage: 'Create',
  });
  const buttonLabelEdit = intl.formatMessage({
    id: 'price-quotes-page-popup-button-label-edit',
    defaultMessage: 'Create',
  });
  const buttonLabelCancel = intl.formatMessage({
    id: 'button-close',
    defaultMessage: 'Close',
  });
  const buttonLabelUploadFile = intl.formatMessage({
    id: 'price-quotes-page-popup-button-upload-file',
    defaultMessage: 'Upload file',
  });
  const buttonLabelReplaceFile = intl.formatMessage({
    id: 'price-quotes-page-popup-button-replace-file',
    defaultMessage: 'Replace file',
  });
  const typeaheadLabel = intl.formatMessage({
    id: 'typeahead-customer-label',
    defaultMessage: 'Search customers',
  });

  const {values, setFieldValue} = formik;
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    /* there is a strange issue, formik.errors.customers by some reason sets to [null] if number of customers
    differs from initial and formik.isValid sets to false. And this issue isn't related to the validate method,
    something happens outside it. So form validation state is calculating manually */
    formik.validateForm(values)
      .then(errors => {
        setIsFormValid(Object.keys(errors).length === 0);
      })
  }, [JSON.stringify(values)])

  const editMode = id !== -1;

  const popupHeader = editMode ? `${headerEdit} ${id}` : headerCreate;
  const buttonApproveLabel = editMode ? buttonLabelEdit : buttonLabelCreate;
  const buttonUploadFileLabel = Boolean(values.fileName) ? buttonLabelReplaceFile : buttonLabelUploadFile;

  return (
    <>
      <AnimatedUploadingFilePopUp show={uploadingFile}/>
      {loadingData
        ? <Spinner/>
        : (
          <div className={'w-75 mx-auto'}>
            <h2 className='font-weight-normal text-dark w-100 d-flex justify-content-center mb-6'>
              {popupHeader}
            </h2>
            <div className='w-100'>
              <div className={''}>
                <FieldFindCustomers
                  name='customers'
                  formik={formik}
                  customItemStyles={'d-flex align-items-center'}
                  customLabel={
                    <div className="d-inline-block font-weight-bolder text-dark-75">
                      <span className={'text-danger'}>*</span>
                      {typeaheadLabel}
                    </div>
                  }
                />
                <StyledFieldSelect
                  name={'status'}
                  options={priceQuoteStatus}
                  value={values.status}
                  customContainerStyles={'py-3'}
                  onChange={({value}) => {
                    setFieldValue('status', filterOptions(value)(priceQuoteStatus)[0])
                  }}
                  isSearchable={true}
                  isAsync={true}
                  noOptionsMessage={() => noOptionsMessage}
                  label={
                    <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                      {fieldStatus}
                    </div>
                  }
                />
                <StyledFieldInput
                  fieldId={'price'}
                  value={values.price}
                  onChange={(e) => {
                    setFieldValue('price', formatStringToFloatNumber(e))
                  }}
                  label={
                    <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                      <span className={'text-danger'}>*</span>
                      {fieldPrice}
                    </div>
                  }
                  customContainerStyles={'py-3'}
                />
              </div>
              <div className={`py-4 d-flex align-items-center flex-column`}>
                <ButtonUploadFile
                  icon={true}
                  label={buttonUploadFileLabel}
                  onChange={(e) => uploadFile(e)}
                  customContainerStyles={'w-200px mx-auto mb-8'}
                />
                {values.fileName && (
                  <div className={'mb-10'}>
                    <div
                      className={'border border-warning border-top-0 border-left-0 border-right-0 text-center mb-4 font-weight-bolder text-dark-75 font-size-lg'}
                    >
                      {values.fileName}
                    </div>
                    <div className={'d-flex justify-content-center'}>
                      <CommonButton
                        customButtonClassNames={'btn btn-warning w-40px h-40px mx-2 d-flex align-items-center justify-content-center'}
                        label={
                          <img
                            src={'../../../media/common/Delete.svg'}
                            alt="remove"
                          />
                        }
                        onClick={() => handleDeleteFile(values.fileName)}
                      />
                      {editMode && (
                        <CommonButton
                          customButtonClassNames={'btn btn-warning w-40px h-40px mx-2 d-flex align-items-center justify-content-center'}
                          label={
                            <img
                              src={'../../../media/common/Download.svg'}
                              alt="download"
                            />
                          }
                          onClick={() => handleDownloadFile(values.fileName)}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={'mx-auto d-flex justify-content-between'}>
                <CommonButton
                  variant={'button'}
                  onClick={closeModal}
                  label={buttonLabelCancel}
                />
                <CommonButton
                  variant={'submit'}
                  onClick={handleApprove}
                  disabled={!isFormValid}
                  label={buttonApproveLabel}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default PriceQuoteForm;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

export const CommentsBlock = ({children}) => {
  return (
    <div className={classnames(styles.commentNote)}>
      {children}
    </div>
  );
};

const CommentNote = React.forwardRef(({
                                        noteNumber,
                                        children,
                                        className,
                                        id,
                                        ...rest
                                      }, ref) => {
  return (
    <div
      className={classnames(styles.commentText, className)}
      ref={ref}
      {...rest}
    >
      <NoteNumber id={id}>{noteNumber}</NoteNumber>
      <span>{children}</span>
    </div>
  );
});

CommentNote.propTypes = {
  noteNumber: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
};

const CommentRegisteredArea = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'registeredArea'} noteNumber={context.noteNumbers.registeredArea} {...props} ref={ref}>
      גבולות
      השטח ומימדיו
      יקבעו ע"י מודד מוסמך (לא בתחום סמכותו של שמאי מקרקעין)
    </CommentNote>
  );
});

const CommentSurveyArea = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'surveyArea'} noteNumber={context.noteNumbers.surveyArea} {...props} ref={ref}>
      גבולות
      השטח
      ומימדיו
      יקבעו ע"י מודד מוסמך (לא בתחום סמכותו של שמאי מקרקעין)
    </CommentNote>
  );
});

const CommentLandShapeValueText = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'landShapeValueText'} noteNumber={context.noteNumbers.landShapeValueText} {...props} ref={ref}>
      גבולות
      השטח ומימדיו
      יקבעו ע"י מודד מוסמך (לא בתחום סמכותו של שמאי מקרקעין).
    </CommentNote>
  );
});

const CommentBuildingPhysicalStateValue = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'buildingPhysicalStateValue'}
                 noteNumber={context.noteNumbers.buildingPhysicalStateValue} {...props} ref={ref}>
      חוו"ד זו אינה
      מהווה
      חוו"ד הנדסית.
    </CommentNote>
  );
});

const CommentBuildPermitNotSigned = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  const {buildPermitNotSignedText} = context.data.pdfData;
  return (
    <CommentNote id={'buildPermitNotSigned'} noteNumber={context.noteNumbers.buildPermitNotSigned} {...props} ref={ref}>
      {buildPermitNotSignedText}
    </CommentNote>
  );
});

const CommentPermitAndExistingAreaComparisonText = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'permitAndExistingAreaComparisonText'}
                 noteNumber={context.noteNumbers.permitAndExistingAreaComparisonText} {...props} ref={ref}>
      חוות דעת זו אינה תחליף לחוו"ד אדריכלית. בחו"ד זו לא נבדקו גובה המבנה, קווי
      בניין והעמדה, המצויים בתחום המומחיות של מודד מוסמך.
    </CommentNote>
  );
});

const CommentHousePhysicalStateValue = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'housePhysicalStateValue'} noteNumber={context.noteNumbers.housePhysicalStateValue} {...props}
                 ref={ref}>
      חוו"ד זו אינה מהווה חוו"ד הנדסית ואין להסתמך עליה כראייה למצבו הפיזי של המבנה.
    </CommentNote>
  );
});

const CommentSection7 = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'section7'} noteNumber={context.noteNumbers.section7} {...props} ref={ref}>
      הרקע התכנוני מובא
      כמידע אינפורמטיבי מאומת כבסיס לחוות הדעת. לצורך קבלת מידע תכנוני
      מוסמך,לרבות מידע לצרכי תכנון,יש לפנות לועדה המקומית לתכנון ובניה
      ולקבל זאת בכתב נכון למועד הפקת המידע ובהתאם למדיניות הועדה המקומית
    </CommentNote>
  );
});

const CommentEstimateText = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'estimateText'} noteNumber={context.noteNumbers.estimateText} {...props} ref={ref}>
      'בגבולות' משמעו עד 5% מהשווי הנקוב בחוו"ד.
    </CommentNote>
  );
});

const CommentPropertyBuildCostEstimate = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'propertyBuildCostEstimate'} noteNumber={context.noteNumbers.propertyBuildCostEstimate} {...props}
                 ref={ref}>
      בניית מבנה חדש תחת הקיים באותה רמת גמר.
    </CommentNote>
  );
});

const CommentPriceDelta = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'priceDelta'} noteNumber={context.noteNumbers.priceDelta} {...props} ref={ref}>
      בהפחתת עלויות כינון משווי שוק הנכס
    </CommentNote>
  );
});

const CommentBettermentTax = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'bettermentTax'} noteNumber={context.noteNumbers.bettermentTax} {...props} ref={ref}>
      עלות רכישה גבוהה משווי בניכוי מימוש מהיר.
    </CommentNote>
  );
});

const CommentRamiCost = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  const ramiCostComment = Boolean(context.data.pdfData.ramiCost) ? 'נכס מהוון' : 'קרקע פרטית';
  return (
    <CommentNote id={'ramiCost'} noteNumber={context.noteNumbers.ramiCost} {...props} ref={ref}>
      {ramiCostComment}
    </CommentNote>
  );
});

const CommentRealizeCost = React.forwardRef((props, ref) => {
  const context = useCompleteInspectionContext();
  return (
    <CommentNote id={'realizeCost'} noteNumber={context.noteNumbers.realizeCost} {...props} ref={ref}>
      <span> <span>5%</span> משווי הנכס במכר במימוש מהיר + מע"מ 17%. ערך מעוגל. אומדנא בלבד.</span>
    </CommentNote>
  );
});

export {
  CommentRegisteredArea,
  CommentSurveyArea,
  CommentLandShapeValueText,
  CommentBuildingPhysicalStateValue,
  CommentBuildPermitNotSigned,
  CommentPermitAndExistingAreaComparisonText,
  CommentHousePhysicalStateValue,
  CommentSection7,
  CommentEstimateText,
  CommentPropertyBuildCostEstimate,
  CommentPriceDelta,
  CommentBettermentTax,
  CommentRamiCost,
  CommentRealizeCost
};

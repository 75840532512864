export const commonColumns = [
  {
    Header: '',
    accessor: 'checked',
  },
  {
    Header: 'תאריך העסקה',
    accessor: 'officialDate',
  },
  {
    Header: 'גוש',
    accessor: 'gush',
  },
  {
    Header: 'חלקה',
    accessor: 'helka',
  },
  {
    Header: 'ישוב',
    accessor: 'cityName',
  },
  {
    Header: 'רחוב',
    accessor: 'streetName',
  },
  {
    Header: 'בית',
    accessor: 'houseNum',
  },
  {
    Header: 'שטח רשום',
    accessor: 'houseRegisteredArea',
  },
  {
    Header: 'מס\' חדרים',
    accessor: 'houseRoomCount',
  },
  {
    Header: 'קומה',
    accessor: 'floor',
  },
  {
    Header: ' מס\' קומות',
    accessor: 'buildingFloorsCount',
  },
  {
    Header: 'גיל בניין',
    accessor: 'buildingAge',
  },
  {
    Header: 'חניה צמודה',
    accessor: 'houseParking',
  },
  {
    Header: 'מעלית',
    accessor: 'buildingElevator',
  },
  {
    Header: 'חצר',
    accessor: 'houseYard',
  },
  {
    Header: 'מחסן',
    accessor: 'houseStorage',
  },
  {
    Header: 'גג',
    accessor: 'houseRoof',
  },
  {
    Header: 'שטח מגרש',
    accessor: 'registeredArea',
  },
  {
    Header: 'שווי',
    accessor: 'price',
  }
];

export const similarProperties = [
  {Header: '', accessor: 'checked'},
  {Header: 'תאריך העסקה', accessor: 'officialDate'},
  {Header: 'גוש', accessor: 'gush'},
  {Header: 'חלקה', accessor: 'helka'},
  {Header: 'תת חלקה', accessor: 'ttHelka'},
  {Header: 'ישוב', accessor: 'cityName'},
  {Header: 'רחוב', accessor: 'streetName'},
  {Header: 'בית', accessor: 'houseNum'},
  {Header: 'שטח רשום', accessor: 'houseRegisteredArea'},
  {Header: 'מס\' חדרים', accessor: 'houseRoomCount'},
  {Header: 'קומה', accessor: 'floor'},
  {Header: 'מס\' קומות', accessor: 'buildingFloorsCount'},
  {Header: 'שנת בנייה', accessor: 'buildingBuildYear'},
  {Header: 'מחיר', accessor: 'price'},
];

export const surveyAssetsColumns = [
  {Header: '', accessor: 'checked'},
  {Header: 'ישוב', accessor: 'cityName'},
  {Header: 'רחוב', accessor: 'streetName'},
  {Header: 'בית', accessor: 'houseNum'},
  {Header: 'שטח במ"ר', accessor: 'houseRegisteredArea'},
  {Header: 'מס\' חדרים', accessor: 'houseRoomCount'},
  {Header: 'קומה', accessor: 'floor'},
  {Header: ' מס\' קומות', accessor: 'buildingFloorsCount'},
  {Header: 'שנת בניה', accessor: 'buildYear'},
  {Header: 'חניה צמודה', accessor: 'houseParking'},
  {Header: 'מעלית', accessor: 'buildingElevator'},
  {Header: 'חצר', accessor: 'houseYard'},
  {Header: 'מחסן', accessor: 'houseStorage'},
  {Header: 'גג', accessor: 'houseRoof'},
  {Header: 'מרפסת', accessor: 'houseBalconyCount'},
  {Header: 'מחיר מבוקש', accessor: 'askingPrice'},
  {Header: 'הגורם המשווק', accessor: 'marketingFactor'},
];

export const crawlerColumns = [
  {Header: '', accessor: 'checked'},
  {Header: 'תאריך העסקה', accessor: 'purchaseDate'},
  {Header: 'גוש וחלקה', accessor: 'gushAndHelka'},
  {Header: 'כתובת', accessor: 'address'},
  {Header: 'תמורה מוצהרת', accessor: 'publicPrice'},
  {Header: 'שווי מכירה', accessor: 'salePrice'},
  {Header: 'חלק נמכר', accessor: 'soldPart'},
  {Header: 'שטח רשום', accessor: 'registeredArea'},
  {Header: 'שטח ארנונה', accessor: 'municipalArea'},
  {Header: 'מס\' חדרים', accessor: 'roomCount'},
  {Header: 'קומה', accessor: 'floor'},
  {Header: 'דירות בבנין', accessor: 'apartmentCount'},
  {Header: 'שנת בניה', accessor: 'buildYear'},
  {Header: 'כניסה', accessor: 'entrence'},
  {Header: 'דירה', accessor: 'apartmentNumber'},
  {Header: 'חניה', accessor: 'parking'},
  {Header: 'מעלית', accessor: 'elevator'},
  {Header: 'גלריה', accessor: 'gallery'},
  {Header: 'חצר', accessor: 'yard'},
  {Header: 'מחסן', accessor: 'storage'},
  {Header: 'גג', accessor: 'roof'},
  {Header: 'מגרש', accessor: 'lot'},
  {Header: 'סוג עסקה', accessor: 'dealType'},
  {Header: 'תפקוד הבנין והיחידה', accessor: 'unitPurpase'}
];

export const crawlerTab5Columns = [
  {Header: '', accessor: 'checked'},
  {Header: 'תאריך העסקה', accessor: 'purchaseDate'},
  {Header: 'גוש', accessor: 'gush'},
  {Header: 'חלקה', accessor: 'helka'},
  {Header: 'תת חלקה', accessor: 'ttHelka'},
  {Header: 'ישוב', accessor: 'cityName'},
  {Header: 'רחוב', accessor: 'streetName'},
  {Header: 'בית', accessor: 'houseNum'},
  {Header: 'תמורה מוצהרת', accessor: 'publicPrice'},
  {Header: 'שווי מכירה', accessor: 'salePrice'},
  {Header: 'חלק נמכר', accessor: 'soldPart'},
  {Header: 'שטח רשום', accessor: 'registeredArea'},
  {Header: 'שטח ארנונה', accessor: 'municipalArea'},
  {Header: 'מס\' חדרים', accessor: 'roomCount'},
  {Header: 'קומה', accessor: 'floor'},
  {Header: 'דירות בבנין', accessor: 'apartmentCount'},
  {Header: 'שנת בניה', accessor: 'buildYear'},
  {Header: 'כניסה', accessor: 'entrence'},
  {Header: 'דירה', accessor: 'apartmentNumber'},
  {Header: 'חניה', accessor: 'parking'},
  {Header: 'מעלית', accessor: 'elevator'},
  {Header: 'גלריה', accessor: 'gallery'},
  {Header: 'חצר', accessor: 'yard'},
  {Header: 'מחסן', accessor: 'storage'},
  {Header: 'גג', accessor: 'roof'},
  {Header: 'מגרש', accessor: 'lot'},
  {Header: 'סוג עסקה', accessor: 'dealType'},
  {Header: 'תפקוד הבנין והיחידה', accessor: 'unitPurpase'}
];

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {versions as V} from '../../../constants/versions';
import styles from './styles.module.scss';

const Signature = React.forwardRef(({className}, ref) => {
  const {
    data: {
      userData: {
        userFirstName,
        userLastName,
        gender,
        signatureUrl,
      },
    },
    version
  } = useCompleteInspectionContext();
  const genderSuffix2 = (gender === 2) ? 'ת' : '';
  const userName = `${userFirstName} ${userLastName}`;

  return (
    <div
      ref={ref}
      id="signature-container"
      className={classnames(className)}
    >
      <div className={styles.centeredText}>
        ולראייה באתי על החתום,
      </div>
      <div className={styles.centeredText}>
        {userName} ,שמאי{genderSuffix2} מקרקעין
      </div>
      {(version === V.OFFICIAL) && (
        <div className={classnames(styles.listItem, styles.signatureContainer)}>
          <img
            src={signatureUrl}
            alt="signature"
            className={styles.signature}
          />
        </div>
      )}
    </div>
  );
});

export default Signature;

import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import FittedImage from './FittedImage';
import PDFDocument from './PDFDocument';
import PDFPage from './PDFPage';

const Media = ({
                 type,
                 ...rest
               }) => {
  switch (type) {
    case 'image':
      const {onLoad, ...restImageProps} = rest;
      return <Image onImageLoad={onLoad} {...restImageProps}/>;
    case 'fitted image':
      return <FittedImage {...rest} />;
    case 'pdf':
      return <PDFDocument {...rest} />;
    case 'page':
      return <PDFPage {...rest} />;
    default:
      return null;
  }
};

Media.propTypes = {
  type: PropTypes.oneOf(['image', 'fitted image', 'pdf', 'page']),
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  onLoad: PropTypes.func,
};

export default Media;

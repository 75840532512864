import React from 'react';

export function PriceColumnFormatter(cellContent) {

  return (
    <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
      {cellContent ? (cellContent.toLocaleString()) : ''}
    </div>
  );
}

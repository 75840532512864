import React, {useState} from 'react';
import {useIntl} from "react-intl";
import classnames from "classnames";
import {usePopupCopyInspectionContext} from "../PopupCopyInspectionContext";
import CommonButton from "../../../_common/components/CommonButton";
import {StyledFieldSelect} from "../../../_common/components/FieldSelect";
import FieldDatepicker from "../../../_common/components/FieldDatepicker";
import FieldFindCustomers from "../../../_common/components/FieldFindCustomers";
import PopupAddCustomer from "../../../CustomersEntity/components/PopupAddCustomer";
import {StyledFieldInput} from "../../../_common/components/FieldInput";
import {InputCheckbox} from "../../../_common/components/FieldCheckbox";
import {filterOptions} from "../../../_common/utils/options";
import {integerValue} from "../../../../../utils/format";
import {decisionTree} from "../../../_common/constants/decisionTree";
import styles from "./styles.module.scss";

const getLabel = (label, isMandatory, customStyles) => {
  return (
    <div className={`d-inline-block font-weight-bolder text-dark-75 pl-6 ${customStyles}`}>
      {isMandatory && <span className={'text-danger'}>*</span>}
      {label}
    </div>
  )
};

const PopupForm = ({data}) => {
  const {formik, options} = usePopupCopyInspectionContext();
  const {values, setFieldValue} = formik;
  const {users, shumaMazminIdentities, banks, loanTypes, loanSubTypes} = options;
  const [showBankData, setShowBankData] = useState(false);

  const intl = useIntl();
  const labelCustomers = intl.formatMessage({
    id: 'inspections-popup-copy-form-customers',
    defaultMessage: 'Responsible user'
  });
  const labelResponsibleUser = intl.formatMessage({
    id: 'inspections-popup-copy-form-user',
    defaultMessage: 'Responsible user'
  });
  const labelDueDate = intl.formatMessage({
    id: 'inspections-popup-copy-form-dueDate',
    defaultMessage: 'Due date'
  });
  const labelUnderConstruction = intl.formatMessage({
    id: 'inspections-popup-copy-form-underConstruction',
    defaultMessage: 'Under construction'
  });
  const labelIdentity = intl.formatMessage({
    id: 'inspections-popup-copy-form-shumaMazminIdentityID',
    defaultMessage: 'Identity'
  });
  const labelBank = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bank',
    defaultMessage: 'Bank'
  });
  const labelLoanType = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-loanType',
    defaultMessage: 'Loan type'
  });
  const labelLoanSubType = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-loanSubType',
    defaultMessage: 'Loan sub type'
  });
  const labelLoanText = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-loanText',
    defaultMessage: 'Loan text'
  });
  const labelBankBranchNumber = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bankBranchNumber',
    defaultMessage: 'Bank branch number'
  });
  const labelBankBranchName = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bankBranchName',
    defaultMessage: 'Bank branch name'
  });
  const labelBankClerkFullName = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bankClerkFullName',
    defaultMessage: 'Bank clerk full name'
  });
  const labelBankEmail = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bankEmail',
    defaultMessage: 'Bank email'
  });
  const labelBankRequestID = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bankRequestID',
    defaultMessage: 'Bank request ID'
  });
  const labelBankBuildType = intl.formatMessage({
    id: 'inspections-popup-copy-field-label-bankBuildType',
    defaultMessage: 'Bank build type'
  });
  const showBankPropsLabel = intl.formatMessage({
    id: 'inspections-popup-copy-button-label-showBankProps',
    defaultMessage: 'Show bank props'
  });
  const hideBankPropsLabel = intl.formatMessage({
    id: 'inspections-popup-copy-button-label-hideBankProps',
    defaultMessage: 'Hide bank props'
  });
  const commonStyles = 'mx-2 w-225px';
  const showLoanText = values.loanTypeID && `${values.loanTypeID.value}` === decisionTree.loanTypeID.another;

  return (
    <div className={'py-4 px-2 mx-auto'}>
      <div className={'d-flex justify-content-center'}>
        <FieldFindCustomers
          name='customers'
          formik={formik}
          customLabel={getLabel(labelCustomers, true, styles.customersLabel)}
          customItemStyles={'d-flex align-items-center'}
          customContainerStyles={'py-4 w-450px'}
          customListStyles={styles.customersList}
        />
        <PopupAddCustomer
          customButtonClassNames={'mt-11 ml-4'}
          formikProps={formik}
        />
      </div>
      <div className={'d-flex justify-content-between align-items-center py-4'}>
        <StyledFieldSelect
          fieldId={'responsibleUser'}
          value={values.responsibleUser}
          options={users}
          onChange={({value}) => {
            setFieldValue('responsibleUser', filterOptions(value)(users)[0]);
          }}
          label={getLabel(labelResponsibleUser, true)}
          customContainerStyles={commonStyles}
        />
        <FieldDatepicker
          fieldId={'dueDate'}
          label={getLabel(labelDueDate, false)}
          onChange={(date) => {
            setFieldValue('dueDate', new Date(date))
          }}
          customContainerStyles={commonStyles}
        />
        <StyledFieldSelect
          name={'shumaMazminIdentityID'}
          value={values.shumaMazminIdentityID}
          options={shumaMazminIdentities}
          onChange={({value}) => {
            setFieldValue('shumaMazminIdentityID', filterOptions(value)(shumaMazminIdentities)[0]);
          }}
          label={getLabel(labelIdentity, true)}
          customContainerStyles={commonStyles}
        />
      </div>
      {data.shumaReasonID === 2 && (
        <div className={'d-flex align-items-end py-4'}>
          <StyledFieldSelect
            name={'bankID'}
            value={values.bankID}
            options={banks}
            onChange={({value}) => {
              setFieldValue('bankID', filterOptions(value)(banks)[0]);
            }}
            label={getLabel(labelBank, true)}
            customContainerStyles={'mx-2 w-225px'}
          />
          {values.bankID && (
            <CommonButton
              customButtonClassNames={classnames(
                'btn btn-m btn-warning text-dark font-weight-bolder mx-6',
                styles.bankPropsButton
              )}
              label={showBankData ? hideBankPropsLabel : showBankPropsLabel}
              onClick={() => setShowBankData(!showBankData)}
            />
          )}
        </div>
      )}
      {showBankData && (
        <>
          <div className={'d-flex justify-content-between align-items-center py-4'}>
            <StyledFieldInput
              fieldId={'bankBranchNumber'}
              label={getLabel(labelBankBranchNumber)}
              value={values.bankBranchNumber}
              onChange={e => setFieldValue('bankBranchNumber', integerValue(e))}
              customContainerStyles={`${commonStyles} mb-0`}
            />
            <StyledFieldInput
              fieldId={'bankBranchName'}
              label={getLabel(labelBankBranchName)}
              value={values.bankBranchName}
              onChange={(e) => setFieldValue('bankBranchName', e.target.value)}
              customContainerStyles={`${commonStyles} mb-0`}
            />
            <StyledFieldInput
              fieldId={'bankClerkFullName'}
              label={getLabel(labelBankClerkFullName)}
              value={values.bankClerkFullName}
              onChange={(e) => setFieldValue('bankClerkFullName', e.target.value)}
              customContainerStyles={`${commonStyles} mb-0`}
            />
          </div>
          <div className={'d-flex justify-content-between align-items-center py-4'}>
            <StyledFieldInput
              fieldId={'bankEmail'}
              type={'email'}
              label={getLabel(labelBankEmail)}
              value={values.bankEmail}
              onChange={(e) => setFieldValue('bankEmail', e.target.value)}
              customContainerStyles={`${commonStyles} mb-0`}
            />
            <StyledFieldInput
              fieldId={'bankRequestID'}
              label={getLabel(labelBankRequestID)}
              value={values.bankRequestID}
              onChange={(e) => setFieldValue('bankRequestID', e.target.value)}
              customContainerStyles={`${commonStyles} mb-0`}
            />
            <StyledFieldInput
              fieldId={'bankBuildType'}
              label={getLabel(labelBankBuildType)}
              value={values.bankBuildType}
              onChange={(e) => setFieldValue('bankBuildType', e.target.value)}
              customContainerStyles={`${commonStyles} mb-0`}
            />
          </div>
        </>
      )}
      <div className={'d-flex justify-content-between align-items-center py-4'}>
        <StyledFieldSelect
          name={'loanTypeID'}
          value={values.loanTypeID}
          options={loanTypes}
          onChange={({value}) => {
            setFieldValue('loanTypeID', filterOptions(value)(loanTypes)[0]);
          }}
          label={getLabel(labelLoanType)}
          customContainerStyles={commonStyles}
        />
        <StyledFieldSelect
          name={'loanSubTypeID'}
          value={values.loanSubTypeID}
          options={loanSubTypes}
          onChange={({value}) => {
            setFieldValue('loanSubTypeID', filterOptions(value)(loanSubTypes)[0]);
          }}
          label={getLabel(labelLoanSubType)}
          customContainerStyles={commonStyles}
        />
        {showLoanText ? (
          <StyledFieldInput
            fieldId={'loanText'}
            value={values.loanText}
            label={getLabel(labelLoanText, true)}
            onChange={(e) => setFieldValue('loanText', e.target.value)}
            customContainerStyles={`${commonStyles} mb-0`}
          />
        ) : <div className={commonStyles}/>}
      </div>
      <div className={'d-flex justify-content-between align-items-center py-4'}>
        <div
          onClick={() => {
            setFieldValue('underConstruction', !values.underConstruction)
          }}
        >
          <InputCheckbox
            fieldId={'underConstruction'}
            value={values.underConstruction}
            label={labelUnderConstruction}
            onChange={() => {
              setFieldValue('underConstruction', !values.underConstruction)
            }}
            customContainerStyles={'w-105px mx-2'}
          />
        </div>
      </div>
    </div>
  )
};

export default PopupForm;

export const chartColorsArray  = [
  '#FFA800',
  '#8950FC',
  '#7E8299',
  '#F64E60',
  '#181C32',
  '#0fbe58',
  '#deb705',
  '#dd0a0a',
  '#373232',
  '#007bff',
  '#20c997',
  '#e4e6ef',
  '#e83e8c',
  '#7e8299',
];

import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import SimpleButtonDropdown from "../../../_common/components/SimpleButtonDropdown";
import PopupCopyInspection from "../PopupCopyInspection";
import ButtonDownloadFiles from "../ButtonDownloadFiles";
import ButtonRedirect from "../ButtonRedirect";
import ButtonDetails from "../ButtonDetails";
import ButtonPreview from "../ButtonPreview";
import {can} from "../../../_common/components/Permissions";
import {permissionTypes as T, subjects as S} from "../../../_common/constants/permissionSubjects";

const Actions = ({data}) => {
  const {adminPermissionsLevel} = useSelector(
    (state) => ({
      adminPermissionsLevel: state.auth.adminPermissionsLevel,
    }),
    shallowEqual
  );

  const getDisabled = (item) => {
    switch (item) {
      case 'preview':
        return data.originTypeID === 1
          ? !can(T.FULL, S.INSPECTIONS_PAGE) || data?.originTypeID === 3 || adminPermissionsLevel === 3 || Boolean(!adminPermissionsLevel)
          : !can(T.FULL, S.INSPECTIONS_PAGE) || !data.finalPDFFileID || adminPermissionsLevel === 3 || Boolean(!adminPermissionsLevel)
      case 'details':
        return !can(T.FULL, S.INSPECTIONS_PAGE)
      case 'redirect':
        return !can(T.FULL, S.INSPECTIONS_PAGE) || adminPermissionsLevel !== 2
      case 'copy':
        return data.originTypeID !== 1 || !can(T.FULL, S.INSPECTIONS_PAGE);
      case 'download':
        return !can(T.FULL, S.INSPECTIONS_PAGE)
      default:
        return true
    }
  };

  const getItems = (data) => {

    return [
      {
        itemContent: <ButtonPreview data={data}/>,
        customStyles: 'p-0',
        disabled: getDisabled('preview')
      },
      {
        itemContent: <ButtonDetails shumaID={data.shumaID} firmShumaID={data.firmShumaID}/>,
        customStyles: 'p-0',
        disabled: getDisabled('details')
      },
      {
        itemContent: <ButtonRedirect data={data}/>,
        customStyles: 'p-0',
        disabled: getDisabled('redirect')
      },
      {
        itemContent: <PopupCopyInspection data={data}/>,
        customStyles: 'p-0',
        disabled: getDisabled('copy')
      },
      {
        itemContent: <ButtonDownloadFiles shumaID={data.shumaID} firmShumaID={data.firmShumaID}/>,
        customStyles: 'p-0',
        disabled: getDisabled('download')
      }
    ]
  };


  return (
    <SimpleButtonDropdown
      title={
        <div
          className='btn btn-warning d-flex justify-content-center align-items-center'
          style={{width: '38px', height: '38px'}}
        >
          <img
            src={toAbsoluteUrl('media/common/Dots.svg')}
            alt="menu"
          />
        </div>
      }
      items={getItems(data)}
      customMenuStyles={{width: 'fit-content'}}
    />
  )
}

export function ActionsColumnFormatter(data) {

  return <Actions data={data}/>
}



import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getAllShumaTypes} from '../actions/getAllShumaTypes';
import {allShumaTypesReducer} from "../reducers/allShumaTypesReduсer";

function withAllShumaTypes(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      const {shumaTypes, getAllShumaTypes} = this.props;
      if (shumaTypes.length < 1) {
        getAllShumaTypes();
      }
    }

    getOptions = () => {
      const {shumaTypes} = this.props;
      return {
        shumaTypes: shumaTypes || [],
      }
    };

    render() {
      return <WrappedComponent {...this.props} {...this.getOptions()}/>;
    }
  };
}

const mapStateToProps = ({
                           allShumaTypesReducer: {shumaTypes}
                         }) => {
  return {shumaTypes};
};

export default compose(
  connect(
    mapStateToProps,
    {getAllShumaTypes}
  ),
  withAllShumaTypes
);

import React, {createContext, useContext, useEffect, useState,} from 'react';
import {useIntl} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormikProvider, useFormik} from 'formik';
import moment from 'moment';
import {showGlobalMessage} from '../../_common/components/GlobalMessage/actions/showGlobalMessage';
import * as actions from '../../_common/actions/orderInspectionsActions';
import * as inspectionActions from '../../_common/actions/getInspectionData';
import {decisionTree} from '../../_common/constants/decisionTree';
import {filterOptions} from '../../_common/utils/filterOptions';
import {pathnames as P} from '../../_common/constants/pathnames';
import {
  useDecisionTreeOptionsContext,
  useDecisionTreeShumaTypesOptionsContext
} from '../../_common/components/DecisionTreeOptions';
import {useCitiesContext, useStreetsContext} from '../../_common/components/CitiesAndStreets';

const InspectionDetailsUIContext = createContext('');

export function useInspectionDetailsUIContext() {
  return useContext(InspectionDetailsUIContext);
}

export function InspectionDetailsUIProvider({children}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {params: {shumaID}} = useRouteMatch(P.NEW_INSPECTION_DETAILS);

  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );
  const {cities} = useCitiesContext();
  const {streets, updateStreets} = useStreetsContext();
  const successMessage = intl.formatMessage({
    id: 'data-sent-success',
    defaultMessage: 'Success'
  });

  const fieldNames = {
    propertyTypeIDRightsEstimation: `propertyTypeID-rightsEstimation`,
    bankID: `bankID`,
    shumaTypeID: `shumaTypeID`,
    propertyTypeID: `propertyTypeID`,
    loanTypeID: `loanTypeID`,
    loanText: `loanText`,
    otherPropertyTypeID: `otherPropertyTypeID`,
    shumaReasonID: `shumaReasonID`,
    bankBranchNumber: `bankBranchNumber`,
    bankBranchName: `bankBranchName`,
    bankClerkFullName: `bankClerkFullName`,
    bankEmail: `bankEmail`,
    bankRequestID: `bankRequestID`,
    bankBuildType: `bankBuildType`,
    responsibleUser: `responsibleUser`,
    dueDate: `dueDate`,
    city: `city`,
    gush: `gush`,
    street: `street`,
    helka: `helka`,
    houseNumber: `houseNumber`,
    ttHelka: `ttHelka`,
    apartmentNumber: `apartmentNumber`,
    notes: `notes`,
    landLotNum: 'landLotNum',
    landLotNumAccordingTaba: 'landLotNumAccordingTaba',
    loanSubTypeID: `loanSubTypeID`,
    shumaMazminIdentityID: `shumaMazminIdentityID`,
    underConstruction: `underConstruction`,
  };

  const [loadingData, setLoadingData] = useState(false);
  const [inspectionData, setInspectionData] = useState({});
  const [showBankData, setShowBankData] = useState(false);
  const disabledFirstShumaReasonID = false;

  const updateInspectionData = async (shumaID) => {
    try {
      const data = await inspectionActions.getInspectionData(shumaID);
      setInspectionData(data);
      return data;
    } catch (error) {
      console.error(`inspectionData: ${error}`);
      setInspectionData({});
      return {};
    }
  };

  useEffect(() => {
    if (shumaID) {
      Promise.resolve()
        .then(() => updateInspectionData(shumaID));
    }
  }, []);

  const {
    reasons,
    banks,
    propertyTypes,
    loanTypes,
    loanSubTypes,
    shumaMazminIdentities
  } = useDecisionTreeOptionsContext();

  const {
    shumaTypes,
    updateShumaTypes
  } = useDecisionTreeShumaTypesOptionsContext();

  const getInitialValues = async () => {
    const {
      orderID,
      shumaID,
      shumaReasonID,
      propertyTypeID,
      bankID,
      bankBranchNumber,
      bankBranchName,
      bankClerkFullName,
      bankEmail,
      bankRequestID,
      bankBuildType,
      shumaTypeID,
      loanTypeID,
      loanText,
      otherPropertyTypeID,
      responsibleUserID,
      dueDate,
      cityID,
      gush,
      streetID,
      helka,
      houseNumber,
      ttHelka,
      apartmentNumber,
      landLotNum,
      landLotNumAccordingTaba,
      notes,
      loanSubTypeID,
      shumaMazminIdentityID,
      underConstruction
    } = inspectionData;

    if (shumaReasonID && (!shumaTypes)) {
      await updateShumaTypes(shumaReasonID);
    }

    if ((cityID > 0) && (!streets)) {
      await updateStreets(cityID);
    }

    const getStreet = (streetID, streets) => {
      if (streetID && streetID !== -1) {
        return streets.length > 0 ? filterOptions(streetID)(streets)[0] : null
      } else return null
    };

    return {
      orderID: orderID || null,
      shumaID: shumaID || null,
      shumaReasonID: shumaReasonID
        ? filterOptions(shumaReasonID)(reasons)[0]?.value?.toString() : null,
      propertyTypeID: propertyTypeID
        ? filterOptions(propertyTypeID)(propertyTypes)[0] : null,
      bankID: bankID ? filterOptions(bankID)(banks)[0] : null,
      bankBranchNumber: bankBranchNumber || '',
      bankBranchName: bankBranchName || '',
      bankClerkFullName: bankClerkFullName || '',
      bankEmail: bankEmail || '',
      bankRequestID: bankRequestID || '',
      bankBuildType: bankBuildType || '',
      shumaTypeID: (shumaTypeID && shumaTypes.length > 0)
        ? filterOptions(shumaTypeID)(shumaTypes)[0] : null,
      loanTypeID: loanTypeID ? filterOptions(loanTypeID)(loanTypes)[0] : null,
      loanText: loanText || '',
      otherPropertyTypeID: otherPropertyTypeID
        ? filterOptions(otherPropertyTypeID)(propertyTypes)[0] : null,
      responsibleUser: responsibleUserID ? filterOptions(responsibleUserID)(users)[0] : null,
      dueDate: dueDate ? new Date(dueDate) : null,
      city: (cityID && cities) ? filterOptions(cityID)(cities)[0] : null,
      gush: gush || '',
      street: getStreet(streetID, streets),
      helka: helka || '',
      houseNumber: houseNumber || '',
      ttHelka: ttHelka || '',
      apartmentNumber: apartmentNumber || '',
      landLotNum: landLotNum || '',
      landLotNumAccordingTaba: landLotNumAccordingTaba || '',
      notes: notes || '',
      loanSubTypeID: (loanSubTypeID && loanSubTypes) ? filterOptions(loanSubTypeID)(loanSubTypes)[0] : null,
      shumaMazminIdentityID: (shumaMazminIdentityID && shumaMazminIdentities) ? filterOptions(shumaMazminIdentityID)(shumaMazminIdentities)[0] : null,
      underConstruction: Boolean(underConstruction)
    };
  };

  const initialValues = {
    orderID: null,
    shumaID: null,
    shumaReasonID: null,
    propertyTypeID: null,
    bankID: null,
    bankBranchNumber: '',
    bankBranchName: '',
    bankClerkFullName: '',
    bankEmail: '',
    bankRequestID: '',
    bankBuildType: '',
    shumaTypeID: null,
    loanTypeID: null,
    loanText: '',
    otherPropertyTypeID: null,
    responsibleUser: null,
    dueDate: null,
    city: null,
    gush: '',
    street: null,
    helka: '',
    houseNumber: '',
    ttHelka: '',
    apartmentNumber: '',
    notes: '',
    landLotNum: '',
    landLotNumAccordingTaba: '',
    loanSubTypeID: null,
    shumaMazminIdentityID: null,
    underConstruction: false
  };

  useEffect(() => {
    setLoadingData(true);
    getInitialValues()
      .then(result => {
        const newValues = {...result};
        if (formik.values.city !== null) {
          newValues.city = formik.values.city;
        }
        formik.setValues(newValues);
      })
      .catch(() => setLoadingData(false))
      .finally(() => {
        const {shumaReasonID, cityID} = formik.values;
        const optionsAreReady = shumaReasonID && shumaTypes && cityID && streets;
        const optionsAreReadyShumaTypesOnlyRequired = shumaReasonID && shumaTypes && !cityID;
        const optionsAreReadyStreetsOnlyRequired = !shumaReasonID && cityID && streets;
        const optionsAreMotRequired = !shumaReasonID && !cityID;
        if (
          optionsAreReady
          || optionsAreReadyShumaTypesOnlyRequired
          || optionsAreReadyStreetsOnlyRequired
          || optionsAreMotRequired
        ) {
          setLoadingData(false);
        }
      });
  }, [
    JSON.stringify(inspectionData),
    JSON.stringify(shumaTypes),
    JSON.stringify(streets)
  ]);

  const validate = values => {
    let errors = {};

    const {
      shumaReasonID,
      propertyTypeID,
      bankID,
      shumaTypeID,
      loanTypeID,
      loanText,
      otherPropertyTypeID,
      responsibleUser,
      shumaMazminIdentityID
    } = values;

    if (shumaReasonID === decisionTree.rightsEstimation) {
      if (!propertyTypeID) {
        errors[fieldNames.propertyTypeID] = 'Required field';
      }
    } else {
      if (!bankID) {
        errors[fieldNames.bankID] = 'Required field';
      }
      if (!shumaTypeID) {
        errors[fieldNames.shumaTypeID] = 'Required field';
      }
      if (!propertyTypeID) {
        errors[fieldNames.propertyTypeID] = 'Required field';
      }
    }
    if (loanTypeID && (`${loanTypeID.value}` === decisionTree.loanTypeID.other)) {
      if (!otherPropertyTypeID) {
        errors[fieldNames.otherPropertyTypeID] = 'Required field';
      }
    }
    if (loanTypeID && (`${loanTypeID.value}` === decisionTree.loanTypeID.another)) {
      if (!loanText) {
        errors[fieldNames.loanText] = 'Required field';
      }
    }
    if (!responsibleUser) {
      errors[fieldNames.responsibleUser] = 'Required field';
    }
    if (!shumaMazminIdentityID) {
      errors[fieldNames.shumaMazminIdentityID] = 'Required field';
    }

    return errors;
  };

  const handleResetDecisionTree = async (value, fieldNames) => {
    switch (value) {
      case decisionTree.bankSecure:
        formik.setFieldValue(fieldNames.bankID, null);
        formik.setFieldValue(fieldNames.shumaTypeID, null);
        formik.setFieldValue(fieldNames.propertyTypeID, null);
        formik.setFieldValue(fieldNames.loanTypeID, null);
        formik.setFieldValue(fieldNames.loanSubTypeID, null);
        formik.setFieldValue(fieldNames.loanText, null);
        formik.setFieldValue(fieldNames.otherPropertyTypeID, null);
      case decisionTree.rightsEstimation:
      default:
        formik.setFieldValue(fieldNames.shumaTypeID, null);
        formik.setFieldValue(fieldNames.propertyTypeID, null);
    }
  };

  const handleResetPreviousSteps = (fieldId, value, fieldNames) => {
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanSubTypeID,
      loanText,
      otherPropertyTypeID,
      bankBranchNumber,
      bankBranchName,
      bankClerkFullName,
      bankEmail,
      bankRequestID,
      bankBuildType,
    } = fieldNames;
    const {setFieldValue} = formik;
    const shumaTypeIDFieldEnabled = !decisionTreeOptions.disabled.includes(shumaTypeID);

    switch (fieldId) {
      case (bankID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(bankBranchNumber, '');
          setFieldValue(bankBranchName, '');
          setFieldValue(bankClerkFullName, '');
          setFieldValue(bankEmail, '');
          setFieldValue(bankRequestID, '');
          setFieldValue(bankBuildType, '');
          setFieldValue(shumaTypeID, null);
        }
      case (shumaTypeID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(propertyTypeID, null);
        }
      case (propertyTypeID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(loanTypeID, null);
        }
      case (loanTypeID):
      default:
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(loanText, '');
          setFieldValue(loanSubTypeID, null);
          setFieldValue(otherPropertyTypeID, null);
        }
    }
  };

  const formik = useFormik({
    initialValues: {...initialValues},
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const decisionTreeOptions = {
    disabled: [],
  };

  const handleEditInspection = async (data) => {
    const {
      orderID,
      bankID,
      city,
      dueDate,
      loanTypeID,
      loanSubTypeID,
      propertyTypeID,
      otherPropertyTypeID,
      responsibleUser,
      shumaTypeID,
      street,
      shumaMazminIdentityID,
      underConstruction,
      ...rest
    } = data;

    const editedData = {
      orderID: orderID,
      bankID: bankID ? bankID.value : null,
      city: city ? city.value : null,
      dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : null,
      loanTypeID: loanTypeID ? loanTypeID.value : null,
      loanSubTypeID: loanSubTypeID ? loanSubTypeID.value : null,
      propertyTypeID: propertyTypeID ? propertyTypeID.value : null,
      otherPropertyTypeID: otherPropertyTypeID ? otherPropertyTypeID.value : null,
      responsibleUser: responsibleUser ? responsibleUser.value : null,
      shumaTypeID: shumaTypeID ? shumaTypeID.value : '',
      street: street ? street.value : null,
      shumaMazminIdentityID: shumaMazminIdentityID ? shumaMazminIdentityID.value : null,
      underConstruction,
      ...rest
    };

    try {
      await dispatch(actions.addEditShumaLinkedToOrder(editedData));
      await dispatch(showGlobalMessage(successMessage));
    } catch (e) {
      console.error(e);
    }
  };

  const value = {
    fieldNames,
    formik,
    users,
    loadingData,
    validate,
    handleEditInspection,
    decisionTreeProps: {
      decisionTree,
      decisionTreeOptions,
      handleResetDecisionTree,
      handleResetPreviousSteps,
      disabledFirstShumaReasonID,
      showBankData,
      setShowBankData,
    },
  };

  return (
    <InspectionDetailsUIContext.Provider value={value}>
      <FormikProvider value={formik}>
        {children}
      </FormikProvider>
    </InspectionDetailsUIContext.Provider>
  );
}

InspectionDetailsUIProvider.propTypes = {
  children: PropTypes.node,
};

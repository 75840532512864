const defaultState = {
  shumaTypes: [],
  loading: false,
  error: false,
  errorMessage: null
};
export const actionTypes = {
  getAllShumaTypes: 'GET_ALL_SHUMA_TYPES',
  catchError: 'CATCH_ERROR',
};
export const allShumaTypesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.getAllShumaTypes: {
      const {shumaTypes} = action.payload;
      return {
        ...state,
        shumaTypes,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.catchError: {
      const error = `GET_ALL_SHUMA_TYPES: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
}
export const actions = {
  getUsers: (data) => ({
    type: actionTypes.getAllShumaTypes,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: error
  }),
};

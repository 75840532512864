import React from 'react';
import GridTaskItem from "./GridTaskItem";
import {useGridTasksContext} from '../../gridTasksContext';
import styles from "./styles.module.scss";

export function GridTasksList() {
  const {rows} = useGridTasksContext()
  const mapTasks = (task, index) => {
    return <GridTaskItem key={index} {...task}/>
  };

  const scrollPaddingStyle = rows.length > 9 ? '13px' : '0px';

  return (
    <div
      className={styles.tasksList}
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '300px',
        paddingLeft: scrollPaddingStyle
      }}
    >
      {rows.map(mapTasks)}
    </div>
  );
}

export default GridTasksList;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

export const Page6TextContentItem1 = React.forwardRef(({className}, ref) => {
  return (
    <div className={classnames(styles.itemTitle, styles.innerPadding, className)} ref={ref}
         data-testid={'Page6TextContentItems1'}>
      בניין ראשי
    </div>
  );
});

Page6TextContentItem1.propTypes = {
  className: PropTypes.string
};

export const Page6TextContentItem2 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingJointHomeValue = (context.data.pdfData.buildingJointHome === 1) ? 'הרשום כבית משותף' : '';

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)} ref={ref}
         data-testid={'Page6TextContentItems2'}>
      על חלקה {context.data.pdfData.helka} בגוש {context.data.pdfData.gush} בנוי בנין מגורים {buildingJointHomeValue}.
      להלן יובא תיאורו:
    </div>
  );
});

Page6TextContentItem2.propTypes = {
  className: PropTypes.string
};

export default [
  Page6TextContentItem1,
  Page6TextContentItem2,
];

const defaultState = {
  cities: null,
  streets: null,
  loading: false,
  error: false,
  errorMessage: null
};

export const actionTypes = {
  getCities: 'GET_CITIES',
  getStreets: 'GET_STREETS',
  catchError: 'CATCH_ERROR',
};

export const citiesAndStreetsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.getCities: {
      const {cities} = action.payload;
      return {
        ...state,
        cities,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.getStreets: {
      const {streets} = action.payload;
      return {
        ...state,
        streets,
        loading: false,
        error: false,
        errorMessage: null
      }
    }
    case actionTypes.catchError: {
      const error = `FILTER: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
};


export const actions = {
  getCities: (data) => ({
    type: actionTypes.getCities,
    payload: data
  }),
  getStreets: (data) => ({
    type: actionTypes.getStreets,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: error
  }),
};

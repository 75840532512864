import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {getSelectedOptionLabel} from '../../../utils/options';
import {
  houseParkingTypeOptions,
  houseRoofAccessOptions,
  houseStorageTypeOptions,
  houseYardTypeOptions,
  yardEffectivityOptions
} from '../../../constants/options';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const HouseAttributesTitle = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseYard,
    houseParking,
    houseStorage,
    houseRoof,
    houseOther
  } = context.data.pdfData;
  const showTitle = _.some([
    houseYard === 1,
    houseParking === 1,
    houseStorage === 1,
    houseRoof === 1,
    houseOther === 1,
  ]);

  return (
    <div ref={ref}>
      {Boolean(showTitle) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          <span className={styles.bold}>שטחים נוספים:</span>
        </div>
      )}
    </div>
  );
});

const HouseYard = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseYardType,
    houseYardAffective,
    houseYard,
    houseYardArea,
  } = context.data.pdfData;
  const houseYardTypeLabel = getSelectedOptionLabel(houseYardType, houseYardTypeOptions);
  const houseYardAffectiveLabel = getSelectedOptionLabel(houseYardAffective, yardEffectivityOptions);
  const houseYardValue = (houseYard === 1)
    ? `חצר בשטח ${houseYardArea} מ"ר ${houseYardTypeLabel}, ${houseYardAffectiveLabel}`
    : null;

  return (
    <div ref={ref}>
      {(houseYard === 1) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          {houseYardValue}.
        </div>
      )}
    </div>
  );
});

const HouseParking = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseParking,
    houseParkingType,
  } = context.data.pdfData;
  const houseParkingTypeLabel = getSelectedOptionLabel(houseParkingType, houseParkingTypeOptions);
  const houseParkingValue = (houseParking === 1)
    ? `חניה ${houseParkingTypeLabel}`
    : null;

  return (
    <div ref={ref}>
      {(houseParking === 1) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          {houseParkingValue}.
        </div>
      )}
    </div>
  );
});

const HouseStorage = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseStorage,
    houseStorageArea,
    houseStorageType,
  } = context.data.pdfData;
  const houseStorageTypeLabel = getSelectedOptionLabel(houseStorageType, houseStorageTypeOptions);
  const houseStorageValue = (houseStorage === 1)
    ? `מחסן בשטח ${houseStorageArea} מ"ר ${houseStorageTypeLabel}`
    : null;

  return (
    <div ref={ref}>
      {(houseStorage === 1) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          {houseStorageValue}.
        </div>
      )}
    </div>
  );
});

const HouseRoof = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseRoofAccess,
    houseRoof,
    houseRoofArea,
  } = context.data.pdfData;
  const houseRoofAccessLabel = getSelectedOptionLabel(houseRoofAccess, houseRoofAccessOptions);
  const houseRoofValue = (houseRoof === 1)
    ? `גג בשטח ${houseRoofArea} מ"ר ${houseRoofAccessLabel}`
    : null;

  return (
    <div ref={ref}>
      {(houseRoof === 1) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          {houseRoofValue}.
        </div>
      )}
    </div>
  );
});

const HouseOther = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseOther,
    houseOtherText,
  } = context.data.pdfData;
  const houseOtherValue = (houseOther === 1) ? houseOtherText : null;

  return (
    <div ref={ref} className={styles.lastSectionLine}>
      {(houseOther === 1) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          {houseOtherValue}.
        </div>
      )}
    </div>
  );
});

export default [
  HouseAttributesTitle,
  HouseYard,
  HouseParking,
  HouseStorage,
  HouseRoof,
  HouseOther
];

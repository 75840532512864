import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Image = ({className, src, alt, onImageLoad}) => {
  const imageRef = useRef(null);
  useEffect(() => {
    if (onImageLoad) {
      onImageLoad(imageRef.current);
    }
  }, []);

  return (
    <div className={classnames(styles.plan, className)}>
      <div>
        <img src={src} alt={alt} className={styles.image} ref={imageRef}/>
      </div>
    </div>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onImageLoad: PropTypes.func,
};

export default Image;

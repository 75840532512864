import axios from "axios";

export const GET_DASHBOARD_DATA_SIMPLE_DATE_RANGE = "/dashboard/getDashboardDataForSimpleDataRange";
export const GET_DASHBOARD_DATA_EXTENDED_DATE_RANGE = "/dashboard/getDashboardDataForExtendedDateRange";
export const GET_DASHBOARD_DATA_EXTENDED_DATE_RANGE_WITH_CITY = "dashboard/getDashboardDataForExtendedDateRangeWithCity";

export const getDashboardDataSimpleDateRange = (data) => {

  return axios.post(GET_DASHBOARD_DATA_SIMPLE_DATE_RANGE, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body
    })
};

export const getDashboardDataExtendedDateRange = (data) => {

  return axios.post(GET_DASHBOARD_DATA_EXTENDED_DATE_RANGE, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body
    })
};

export const getDashboardDataExtendedDateRangeWithCity = (data) => {

  return axios.post(GET_DASHBOARD_DATA_EXTENDED_DATE_RANGE_WITH_CITY, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body
    })
};

import React from 'react';
import classnames from 'classnames';
import {checkIfTextEndsWithDot, getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

const landShitufNoteText = '* לא הוצג בפני הסכם שיתוף ו/או תשריט חלוקה. זיהוי הנכס הוא בהתאם לנכס שהוצג פיזית בפני הח״מ בעת הביקור.';

const landShitufNote = (context) => {
  const {landShituf} = context.data.pdfData;

  return !Boolean(landShituf) && (
    <div className={classnames(styles.itemText, styles.innerPadding)}>
      {landShitufNoteText}
    </div>
  );
};

const warningNote = (context) => {
  const {
    warningNote,
    warningNoteText,
  } = context.data.pdfData;

  let firstLineText;
  let restChunks = [];

  if (Boolean(warningNote)) {
    [firstLineText, ...restChunks] = getTextChunks(warningNoteText);
  }

  return Boolean(warningNote)
    ? [
      <div className={classnames(styles.itemText, styles.innerPadding)}>
        <span className={styles.bold}>הערת אזהרה - </span>
        <span>על הנכס רשומה הערת אזהרה לטובת {firstLineText}</span>
        {checkIfTextEndsWithDot(firstLineText)}
      </div>,
      ...restChunks
        .map(mapTextChunks)
        .map((item, index, array) => (
          <div
            className={classnames(styles.innerPadding, {
              [styles.lastSectionLine]: index === array.length - 1
            })}
          >
            {item}
          </div>
        ))
    ]
    : [];
};

export const getNotes = (context) => {
  return [
    landShitufNote(context),
    ...warningNote(context)
  ];
};

import React, {createContext, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {FormikProvider, useFormik} from "formik";
import {
  addEditPriceQuote,
  uploadPriceQuoteFile
} from "../../_redux/actions/PopupCreateEditPriceQuoteActions";
import {priceQuoteStatus} from "../../../_common/constants/options";

const PriceQuoteCreateUIGlobalContext = createContext('');

export function usePriceQuoteCreateUIGlobalContext() {
  return useContext(PriceQuoteCreateUIGlobalContext);
}

export function PriceQuoteCreateUIGlobalProvider({children, closeModal}) {
  const [uploadingFile, setUploadingFile] = useState(false);
  const dispatch = useDispatch();

  const createPriceQuote = () => {
    Promise.resolve()
      .then(() => {
        return dispatch(addEditPriceQuote({
          priceQuoteID: -1,
          status: formik.values.status,
          price: formik.values.price,
          customers: formik.values.customers,
          createMode: true
        }))
      })
      .then(newID => {
        if (formik.values.fileName && newID) {
          dispatch(uploadPriceQuoteFile(newID, formik.values.fileToSave, formik.values.fileName))
        }
      })
  }

  const initialValues = {
    inputValue: '',
    customers: [],
    priceQuoteID: -1,
    status: priceQuoteStatus[0],
    price: '',
    fileName: '',
    fileToSave: null,
  };

  const validate = (values) => {
    let errors = {};
    const {
      status,
      price,
      customers,
    } = values;

    if (!status) errors[`status`] = 'Required field!';
    if (!price) errors[`price`] = 'Required field!';
    if (customers.length < 1) errors[`customers`] = 'Must be at least one customer!';

    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const uploadFile = (e) => {
    const fileToSave = e.target.files[0];
    const fullFileName = e.target.files[0].name;
    setUploadingFile(true);
    formik.setFieldValue('fileToSave', fileToSave);
    formik.setFieldValue('fileName', fullFileName);

    setTimeout(() => {
      setUploadingFile(false)
      document.body.click()
    }, 1000)
  };

  const handleDeleteFile = (fileName) => {
    if (fileName) {
      formik.setFieldValue('fileToSave', null)
      formik.setFieldValue('fileName', '')
    }
  };

  const value = {
    formik,
    closeModal,
    createPriceQuote,
    uploadFile,
    uploadingFile,
    handleDeleteFile
  };

  return (
    <PriceQuoteCreateUIGlobalContext.Provider value={value}>
      <FormikProvider value={formik}>
        {children}
      </FormikProvider>
    </PriceQuoteCreateUIGlobalContext.Provider>
  );
}

PriceQuoteCreateUIGlobalProvider.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';
import {
  getBalconyAndStorageLabels,
  getBalconyYardRoofRegisteredArea,
  getStorageParkingRegisteredArea,
} from '../../../utils/texts';

const Section6Text76 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseRegisteredArea,
    houseRegisteredAreaIncludeBalcony,
    houseBalconyOpenRegisteredArea,
    houseBalconyClosedRegisteredArea,
    houseRegisteredAreaIncludeStorage,
    houseYardRegisteredArea,
    houseRoofRegisteredArea,
    houseStorageRegisteredArea,
    houseParkingRegisteredArea,
    houseJointHomeRegistered
  } = context.data.pdfData;

  const houseRegisteredAreaIncludeBalconyStorage = getBalconyAndStorageLabels({
    houseAreaIncludeBalcony: houseRegisteredAreaIncludeBalcony,
    houseBalconyOpenArea: houseBalconyOpenRegisteredArea,
    houseBalconyClosedArea: houseBalconyClosedRegisteredArea,
    houseAreaIncludeStorage: houseRegisteredAreaIncludeStorage,
    houseStorageArea: houseStorageRegisteredArea,
  });

  return (
    <div
      className={classnames(styles.itemText, styles.innerPadding)}
      data-testid="houseRegisteredAreaIncludeBalconyValue"
      ref={ref}
    >
      {houseJointHomeRegistered === 1 && (
        <>
          <span className={styles.bold}>שטח רשום: </span>
          {` ${houseRegisteredArea || ''} `}
          <span>מ"ר</span>
          {Boolean(houseRegisteredAreaIncludeBalconyStorage) && (
            <>
              {`, `}
              <span
                data-testid={'houseRegisteredAreaIncludeBalconyStorageStaticText'}>
                בתשריט הבית המשותף מצוין כי
              </span>
              <span data-testid={'houseRegisteredAreaIncludeBalconyStorage'}>
                {` ${houseRegisteredAreaIncludeBalconyStorage}`}
              </span>
            </>
          )}
          <span>.</span>
          <span>
            {` ${getBalconyYardRoofRegisteredArea(
              houseBalconyOpenRegisteredArea,
              houseBalconyClosedRegisteredArea,
              houseYardRegisteredArea,
              houseRoofRegisteredArea,
            )}`}
          </span>
          <span>
        {` ${getStorageParkingRegisteredArea(houseStorageRegisteredArea, houseParkingRegisteredArea)}`}
      </span>
        </>
      )}
    </div>
  );
});

export default Section6Text76;

import React from 'react';
import CommonButton from "../../../_common/components/CommonButton";
import uploadFileIcon from "./upload.svg";

const ButtonUploadFile = ({
                            label,
                            icon,
                            title,
                            onChange,
                            customContainerStyles = '',
                            customLabelStyles = '',
                            multiple = false
                          }) => {

  const containerStyles = `btn btn-sm btn-warning ${customContainerStyles}`;
  const labelStyles = `w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer text-dark font-weight-bolder ${customLabelStyles}`;
  return (
    <CommonButton
      customButtonClassNames={containerStyles}
      title={title}
      label={
        <label
          className={labelStyles}
        >
          {icon && (
            <img
              src={uploadFileIcon}
              alt="upload-file"
              className={label ? 'mr-2' : ''}
            />
          )}
          {label}
          <input
            type="file"
            className="d-none"
            onChange={onChange}
            multiple={multiple}
          />
        </label>
      }
    />
  );
};

export default ButtonUploadFile;

import React from 'react';
import {FormattedMessage} from 'react-intl';
import uploadFileIcon from "../../assets/upload.svg";
import {useCustomerDetailsUIContext} from "../CustomerDetailsUIContext";

const ButtonUploadFile = () => {
  const {handleFileUpload} = useCustomerDetailsUIContext();

  return (
    <>
      <label className={'w-125px mb-0 btn btn-sm btn-warning d-flex align-items-center text-nowrap font-size-lg text-dark'}>
        <div className='d-flex justify-content-between align-items-center'>
          <img
            src={uploadFileIcon}
            alt="upload-file"
            className='mr-2'
          />
          <FormattedMessage
            id="upload-file"
            defaultMessage="Upload file"
          />
          <input
            type="file"
            className="d-none"
            id="uploadedFile"
            onChange={(e) => {
              handleFileUpload(e)
            }}
          />
        </div>
      </label>
    </>
  );
};

export default ButtonUploadFile;

import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button} from 'react-bootstrap';
import {can} from '../../../_common/components/Permissions';
import {permissionTypes as T, subjects as S} from '../../../_common/constants/permissionSubjects';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import config from '../../../../../config/common.json';
import {FormattedMessage} from "react-intl";

const ButtonRedirect = ({data}) => {
  const {adminPermissionsLevel} = useSelector(
    (state) => ({
      adminPermissionsLevel: state.auth.adminPermissionsLevel,
    }),
    shallowEqual
  );

  const handleRedirectToPlatform = () => {
    const platformURL = process.env.NODE_ENV === 'production'
      ? window.location.hostname === 'devadmin.accuraiser.com' ? config.test_main_client_base_URL : config.main_client_base_URL
      : config.dev_main_client_base_URL;
    const adminURL = process.env.NODE_ENV === 'production'
      ? config.admin_base_URL
      : config.dev_main_client_base_URL;
    const url = (data?.originTypeID === 1)
      ? `${platformURL}/inspection/${data.shumaID}/1`
      : `${adminURL}/old-inspection-details/${data.shumaID}`;
    window.open(
      url,
      data?.originTypeID === 1 ? '_blank' : '_self',
      'noreferrer'
    );
  };
  const disabledCondition = !can(T.FULL, S.INSPECTIONS_PAGE) || adminPermissionsLevel !== 2;

  return (
    <Button
      className={`w-100 bg-white border-0 h-40px d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg`}
      disabled={disabledCondition}
      onClick={() => handleRedirectToPlatform()}
    >
      <img
        src={toAbsoluteUrl('media/common/Redirect.svg')}
        alt={'redirect'}
        className={'w-20px mr-4'}
      />
      <FormattedMessage
        id="inspections-column-redirect"
        defaultMessage="Redirect"
      />
    </Button>
  );
};

export default ButtonRedirect;
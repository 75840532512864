import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {can} from '../../../_common/components/Permissions';
import useActiveLinkClass from '../../../_common/components/_custom-hooks/useActiveLinkClass';
import {pathnames as P} from '../../../_common/constants/pathnames';
import {subjects as S, permissionTypes as T} from '../../../_common/constants/permissionSubjects';

const DashboardPageLink = () => {
  const {activeClassName} = useActiveLinkClass({url: P.DASHBOARD_PAGE});

  return can(T.VIEW, S.DASHBOARD_PAGE) && (
    <li
      className={`menu-item ${activeClassName}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={P.DASHBOARD_PAGE}>
        <img
          src={'../../media/common/Layers_BC.svg'}
          alt="dashboard"
          className="mr-3"
        />
        <div className="menu-text">
          <span className="font-size-lg">
            <FormattedMessage
            id="dashboard-page-link"
            defaultMessage="Dashboard"
          />
          </span>
        </div>
      </NavLink>
    </li>
  );
};

DashboardPageLink.propTypes = {};

export default DashboardPageLink;

import {useLocation} from 'react-router';
import {checkIsActive} from '../../../../../_metronic/_helpers';

const useActiveLinkClass = ({
                              url,
                              hasSubmenu
                            }) => {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  return {
    activeClassName: getMenuItemActive(url, hasSubmenu)
  };
};

export default useActiveLinkClass;

import React from 'react';
import classnames from 'classnames';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

const supervisionFileText = 'על פי ההממצאים קיים תיק פיקוח פעיל לנכס.';
export const exceedUsePermitText = 'על פי הממצאים קיים היתר לשימוש חורג.';
export const exceedBuildingText = 'על פי הממצאים קיימות חריגות בנייה.';

const line1 = (context) => {
  const {
    data: {
      pdfData: {
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
      },
    },
  } = context;

  return (supervisionFile === 1) && (
    <div className={classnames(styles.itemText, styles.innerPadding, {
      [styles.lastSectionLine]: !Boolean(housePermitMismatchText)
      && (exceedBuilding !== 1)
      && (exceedUsePermit !== 1)
    })}>
      {supervisionFileText}
    </div>
  );
};

const line2 = (context) => {
  const {
    data: {
      pdfData: {
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
      },
    },
  } = context;

  return (exceedUsePermit === 1) && (
    <div className={classnames(styles.itemText, styles.innerPadding, {
      [styles.lastSectionLine]: !Boolean(housePermitMismatchText)
      && (exceedBuilding !== 1)
    })}>
      {exceedUsePermitText}
    </div>
  );
};

const line3 = (context) => {
  const {
    data: {
      pdfData: {
        exceedBuilding,
        housePermitMismatchText,
      },
    },
  } = context;

  return (exceedBuilding === 1) && (
    <div className={classnames(styles.itemText, styles.innerPadding, {
      [styles.lastSectionLine]: !Boolean(housePermitMismatchText)
    })}>
      {exceedBuildingText}
    </div>
  );
};

const line4 = (context) => {
  const {
    data: {
      pdfData: {
        housePermitMismatchText,
      },
    },
  } = context;
  let housePermitMismatchTextValues = [];
  if (Boolean(housePermitMismatchText)) {
    const [firstLineText, ...restChunks] = getTextChunks(housePermitMismatchText);
    housePermitMismatchTextValues = [
      `פירוט אי התאמה להיתר - ${firstLineText}`,
      ...restChunks
    ];
  }

  return housePermitMismatchTextValues
    .map(mapTextChunks)
    .map((item, index, array) => (
      <div
        className={classnames(styles.itemText, styles.innerPadding, {
          [styles.lastSectionLine]: index === array.length - 1
        })}
      >
        {item}
      </div>
    ));
};

export const getSupervisionSection = context => {
  return [
    line1(context),
    line2(context),
    line3(context),
    line4(context),
  ];
};

import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {formattingDate} from '../../../utils/formattingHelper';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

const licensingTitle = (context) => {
  const {
    buildPermitNumber,
    buildPermitDate,
    buildPermitReason,
    buildPermitDetails,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
    supervisionFile,
    exceedUsePermit,
    exceedBuilding,
  } = context.data.pdfData;

  const showHeaderCondition =
    Boolean(
      buildPermitNumber ||
      buildPermitDate ||
      buildPermitReason ||
      buildPermitDetails ||
      additionalBuildPermitText ||
      form4 ||
      completeCert ||
      dangerousStructure ||
      supervisionFile ||
      (exceedUsePermit && exceedUsePermit === 1) ||
      (exceedBuilding && exceedBuilding === 1)
    );

  return showHeaderCondition && (
    <div
      className={classnames(styles.itemTitle, styles.innerPadding)}
    >
      <span>רישוי</span>
    </div>
  );
};

const line1 = (context) => {
  const {
    buildPermitNumber,
    buildPermitDate,
    buildPermitReason,
    buildPermitDetails,
    additionalBuildPermitText,
  } = context.data.pdfData;

  const showSubHeaderCondition =
    Boolean(
      buildPermitNumber ||
      buildPermitDate ||
      buildPermitReason ||
      buildPermitDetails ||
      additionalBuildPermitText
    );

  return showSubHeaderCondition && (
    <div
      className={classnames(styles.itemText, styles.bold, styles.innerPadding)}
    >
      <span>להלן היתרי בניה המצויים בתיק הבנין:</span>
    </div>
  );
};

const line2 = (context) => {
  const {
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails
  } = context.data.pdfData;
  let buildPermitDetailsValues = [];
  if (Boolean(buildPermitDetails)) {
    const [firstLineText, ...restChunks] = getTextChunks(buildPermitDetails);
    buildPermitDetailsValues = [
      <div className={classnames(styles.innerPadding, styles.itemText)}>
        {` לפיו הותר: ${firstLineText}`}
      </div>,
      ...restChunks.map(mapTextChunks).map((item, index) => {
        return (
          <div key={index} className={styles.innerPadding}>
            {item}
          </div>
        );
      })
    ];
  }

  return _.some([buildPermitNumber, buildPermitDate, buildPermitDetails])
    ? [
      <div className={classnames(styles.itemText, styles.innerPadding)}>
        {Boolean(buildPermitNumber) && (
          `היתר בניה
                מס' ${buildPermitNumber} `
        )}
      </div>,
      <div className={classnames(styles.itemText, styles.innerPadding)}>
        {Boolean(buildPermitDate) && (
          `מיום ${formattingDate(buildPermitDate)}`
        )}
      </div>,
      ...buildPermitDetailsValues
    ]
    : [];
};

const line3 = (context) => {
  const {additionalBuildPermitText} = context.data.pdfData;
  let additionalBuildPermitTextValues = [];
  if (Boolean(additionalBuildPermitText)) {
    additionalBuildPermitTextValues = getTextChunks(additionalBuildPermitText).map(mapTextChunks);
  }

  return additionalBuildPermitTextValues.map((item, index, array) => {
    return (
      <div
        className={classnames(
          styles.itemText,
          styles.innerPadding,
          {[styles.lastSectionLine]: index === array.length - 1}
        )}
      >
        {item}
      </div>
    );
  });
};

export const getLicensingSection = (context) => {
  return [
    licensingTitle(context),
    line1(context),
    ...line2(context),
    line3(context)
  ];
};

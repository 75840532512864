import {persistReducer, PURGE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import common from '../../../../config/common.json';
import {pathnames} from '../../_common/constants/pathnames';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  RegisterToken: '[Register Token] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  CatchError: '[Catch Error] Action',
  UpdatePermissions: '[Update Permissions] Action'
};

const initialAuthState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  idToken: null,
  greenInvoiceApiKey: null,
  greenInvoiceSecret: null,
  greenInvoicePaymentPluginID: null,
  expire: null,
  error: null,
  defaultPage: '',
  pages: null, // {pageName: string, permissionLevel: 'full' | 'view' | 'block'}[] | null
  adminPermissionsLevel: null,
};

const defaultUser = {
  firstName: 'Admin',
  lastName: 'user'
};

export const reducer = persistReducer(
  {
    storage,
    key: 'accuraiser-admin-auth',
    whitelist: [
      'user',
      'accessToken',
      'greenInvoiceApiKey',
      'greenInvoiceSecret',
      'greenInvoicePaymentPluginID',
      'expire',
      'refreshToken',
      'defaultPage',
      'pages',
      'adminPermissionsLevel'
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {
          tokens,
          user,
          greenInvoiceApiKey,
          greenInvoiceSecret,
          greenInvoicePaymentPluginID,
          pages,
          adminPermissionsLevel
        } = action.payload;
        user.email = user.attributes.email;
        if (!user.attributes.name) {
          user.firstName = defaultUser.firstName;
          user.lastName = defaultUser.lastName;
        } else {
          user.firstName = user.attributes.name;
          user.lastName = user.attributes.family_name || '';
        }
        if (!user.lastName) {
          user.initials = `${user.firstName[0].toUpperCase()}`;
        } else {
          user.initials = `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`;
        }
        user.fullName = `${user.firstName} ${user.lastName}`;

        return {
          ...tokens,
          user: user || defaultUser,
          greenInvoiceApiKey,
          greenInvoiceSecret,
          greenInvoicePaymentPluginID,
          pages,
          adminPermissionsLevel,
          defaultPage: findDefaultPage(pages)
        };
      }

      case actionTypes.RegisterToken: {
        const {accessToken, expire} = action.payload;
        return {...state, accessToken, expire};
      }

      case PURGE:
      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const {user} = action.payload;
        return {...state, user};
      }

      case actionTypes.SetUser: {
        const {user} = action.payload;
        return {...state, user};
      }

      case actionTypes.CatchError: {
        const error = `AUTH: ${action.payload.error}`;
        return {...state, error};
      }

      case actionTypes.UpdatePermissions: {
        const {permissions} = action.payload;
        return {
          ...state,
          pages: permissions,
          defaultPage: findDefaultPage(permissions)
        }
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: ({tokens, user, greenInvoiceApiKey, greenInvoiceSecret, pages, adminPermissionsLevel, greenInvoicePaymentPluginID}) => ({
    type: actionTypes.Login,
    payload: {tokens, user, greenInvoiceApiKey, greenInvoiceSecret, pages, adminPermissionsLevel, greenInvoicePaymentPluginID}
  }),
  registerToken: ({accessToken, expire}) => ({
    type: actionTypes.RegisterToken,
    payload: {accessToken, expire},
  }),
  logout: () => ({type: actionTypes.Logout}),
  catchError: (error) => ({type: actionTypes.CatchError, payload: {error}}),
  updatePermissions: (permissions) => ({type: actionTypes.UpdatePermissions, payload: {permissions}}),
};

const findDefaultPage = pages => {
  const [pageMarkedAsDefault] = pages.filter(page => page.default);
  if (Boolean(pageMarkedAsDefault)) {
    const [defaultPage] = Object.values(pathnames).filter(page => page === pageMarkedAsDefault.pageName);
    return defaultPage || common.defaultPage;
  } else {
    return common.defaultPage;
  }
};

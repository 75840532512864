import React from 'react';
import PropTypes from 'prop-types';
import {FormikProvider} from 'formik';
import {useCustomerDetailsUIContext} from '../CustomerDetailsUIContext';
import RowCheque from './RowCheque';
import RowBankTransfer from './RowBankTransfer';
import RowCreditCard from './RowCreditCard';
import RowPaymentApp from './RowPaymentApp';
import RowPayPal from './RowPayPal';
import RowCash from './RowCash';
import RowOther from './RowOther';
import RowWithholdingTax from './RowWithholdingTax';
import RowCommonDocs from './RowCommonDocs';
import {gridTypes as G} from '../../constants/gridTypes';
import styles from './styles.module.scss';

const EditableRow = ({
                       gridType,
                       fieldsSet,
                       values,
                       removeRow,
                       index
                     }) => {
  const {formikCreateDocument} = useCustomerDetailsUIContext();
  const {setFieldValue} = formikCreateDocument;

  const renderRow = (gridType) => {
    switch (gridType) {
      case G.CHEQUE:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowCheque
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.BANK_TRANSFER:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowBankTransfer
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.CREDIT_CARD:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowCreditCard
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              values={values}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.PAYMENT_APP:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowPaymentApp
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              values={values}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.PAY_PAL:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowPayPal
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.CASH:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowCash
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.OTHER:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowOther
              setFieldValue={setFieldValue}
              fieldsSet={fieldsSet}
              values={values}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.WITHHOLDING_TAX:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowWithholdingTax
              fieldsSet={fieldsSet}
              removeRow={removeRow}
            />
          </FormikProvider>
        );
      case G.COMMON_DOCS:
        return (
          <FormikProvider value={formikCreateDocument}>
            <RowCommonDocs
              styles={styles}
              fieldsSet={fieldsSet}
              values={values}
              removeRow={removeRow}
              index={index}
              setFieldValue={setFieldValue}
            />
          </FormikProvider>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.editableRowContainer}>
      {renderRow(gridType)}
    </div>
  );
};

EditableRow.propTypes = {
  gridType: PropTypes.string,
  fieldsSet: PropTypes.object,
  values: PropTypes.object,
  removeRow: PropTypes.func,
  index: PropTypes.number,
};

export default EditableRow;

import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import {useCompleteInspectionContext} from "../../CompleteInspectionContext";
import {propertyTypesGroups as PT} from '../../../constants/propertyTypesGroups';
import styles from './styles.module.scss';

const imagesPerRow = 2;

const Header = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section7}. `}</span>
        <span>תמונות הנכס</span>
      </span>
    </div>
  )
};

const ImagesRow = ({files, className}) => {
  const mapImages = () => {
    return files.map((file, index) => {
      const {body, file_name, free_text, file_extension, file_type_id} = file;
      const src = getFullDataURI({body, file_extension});
      return (
        <div className={classnames(
          styles.imageItem,
          styles.innerPadding,
          {
            [styles.twoInRow]: files.length >= 2,
            [styles.oneInRow]: files.length < 2,
          }
        )} key={index}>
          <div className={styles.imageWrapper}>
            <div className={classnames(styles.freeText, styles.bold, styles.textRegular)}>
              {file_type_id === 10 ? 'חזית הנכס' : free_text}
            </div>
            <img
              src={src}
              alt={file_name}
              key={index}
              className={styles.floorImage}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classnames(styles.floorContainer, className)}>
      <div className={styles.images}>
        {mapImages()}
      </div>
    </div>
  );
};

const getImagesSortedByFloor = (context) => context.files
  .filter(file => file.file_type_id === 11)
  .sort((prev, next) => {
    if (prev.free_id > next.free_id) {
      return 1;
    }
    if (prev.free_id < next.free_id) {
      return -1;
    }
    return 0;
  });

const mapImages = imagesGrouped => (label) => {
  const images = imagesGrouped[label];
  const rows = _.chunk(images, imagesPerRow);

  return rows
    .map((row, index) => (
      <ImagesRow
        files={row}
        className={(index !== rows.length - 1) ? styles.floorRow : ''}
      />
    ));
};

export const getSection7Images = (context) => {
  const propertyTypeID = context.data.pdfData.propertyTypeID;
  const imageBuildingFacade = context.files.filter(file => file.file_type_id === 10);
  const imagesSortedByFloor = getImagesSortedByFloor(context);
  const images = (PT.REGULAR.includes(propertyTypeID))
    ? [...imageBuildingFacade, ...imagesSortedByFloor]
    : [...imagesSortedByFloor];
  const labels = Object.keys({images});

  return labels
    .map(mapImages({images}))
    .flat()
    .map((row, index) => {
      return (index === 0)
        ? (
          <div>
            <Header/>
            <div>{row}</div>
          </div>
        )
        : row;
    });
};

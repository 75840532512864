import axios from 'axios';
import {actions} from '../reducers/TasksTableReduser';
import {showGlobalMessage} from "../../_common/components/GlobalMessage/actions/showGlobalMessage";
import {messages as M} from '../../../../_metronic/i18n/messages';
import moment from "moment/moment";

export const GET_TASKS_LIST_BY_FIRM = '/tasks/getListByFirm';
export const GET_TASK_DETAILS = "/tasks/getTaskDetails";
export const DELETE_TASK = "/tasks/deleteTask";
export const EDIT_VISIT_TASK = "/tasks/addEditVisitTask";
export const EDIT_GENERAL_TASK = "/tasks/addEditGeneralTask";

export const getTasksList = action => ({
                                         taskID = -1,
                                         shumaID = -1,
                                         taskStatus = -1,
                                         taskType = -1,
                                         fromDueDate = "",
                                         toDueDate = "",
                                         cityID = -1,
                                         propertyTypeID = -1,
                                         customerName = "",
                                         responsibleUserID = -1,
                                         requestedPageNumber = 0
                                       }) => dispatch => {

  const data = {
    taskID,
    shumaID,
    taskStatus,
    taskType,
    fromDueDate,
    toDueDate,
    cityID,
    propertyTypeID,
    customerName,
    responsibleUserID,
    requestedPageNumber: parseInt(requestedPageNumber, 10),
  };

  return axios.post(GET_TASKS_LIST_BY_FIRM, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {
        totalPageCount,
        currentPageNumber,
        list,
        totalResultCount
      } = body;

      dispatch(action({
        totalPageCount,
        currentPageNumber,
        tasks: list,
        totalResultCount,
      }));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch(actions.catchError(error));
    });
};

export const getTasks = getTasksList(actions.tasksFetched);
export const getNextTasks = getTasksList(actions.tasksAppended);

export const deleteTask = (taskID) => (dispatch) => {
  const data = {taskID: parseInt(taskID, 10)};
  return axios.post(DELETE_TASK, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) {
        showGlobalMessage(M[errorCode])
      } else {
        dispatch(actions.deleteTask(taskID));
      }
    })
};

export const getTaskDetails = (id) => {
  const data = {taskID: parseInt(id, 10)};

  return axios.post(GET_TASK_DETAILS, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) {
        showGlobalMessage(M[errorCode])
      }

      return body?.task;
    })
};

export const editVisitTask = ({
                                taskID,
                                shumaID,
                                responsibleUserID,
                                username,
                                taskStatusID,
                                taskStatus,
                                dueDate,
                                notes,
                                visitContactName,
                                visitContactPhone
                              }) => (dispatch) => {
  const data = {
    taskID: parseInt(taskID, 10),
    shumaID: parseInt(shumaID, 10),
    responsibleUserID: parseInt(responsibleUserID, 10),
    taskStatusID: parseInt(taskStatusID, 10),
    dueDate: moment(dueDate).format('YYYY-MM-DD'),
    notes,
    visitContactName,
    visitContactPhone
  };

  return axios.post(EDIT_VISIT_TASK, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) {
        showGlobalMessage(M[errorCode])
      } else {
        dispatch(actions.editTask({...data, taskStatus, username}))
      }
    })
    .catch(error => error.clientMessage = "Can't edit task");
};

export const editGeneralTask = ({
                                  taskID,
                                  shumaID,
                                  responsibleUserID,
                                  username,
                                  taskStatusID,
                                  taskStatus,
                                  dueDate,
                                  taskDescription
                                }) => (dispatch) => {
  const data = {
    taskID: parseInt(taskID, 10),
    shumaID: parseInt(shumaID, 10),
    responsibleUserID: parseInt(responsibleUserID, 10),
    taskStatusID: parseInt(taskStatusID, 10),
    dueDate: moment(dueDate).format('YYYY-MM-DD'),
    taskDescription
  };

  return axios.post(EDIT_GENERAL_TASK, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) {
        showGlobalMessage(M[errorCode])
      } else {
        dispatch(actions.editTask({...data, taskStatus, username}))
      }
    })
    .catch(error => error.clientMessage = "Can't edit task");
};
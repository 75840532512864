import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import nothing from './assets/Nothing.svg';
import styles from './styles.module.scss';
import {FormattedMessage} from "react-intl";

const Empty = ({
                 icon = nothing,
                 message = (
                   <FormattedMessage
                     id="no-files"
                     defaultMessage="No files"
                   />
                 ),
                 customContainerStyles
               }) => {

  return (
    <div className={classnames(styles.emptyContainer, customContainerStyles)}>
      <img
        src={icon}
        alt="empty"
        className={styles.emptyIcon}
      />
      <div className={styles.emptyMessage}>
        {message}
      </div>
    </div>
  )
};

Empty.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  customContainerStyles: PropTypes.string,
};

export default Empty;

import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import TextareaAutosize from "react-textarea-autosize";
import styles from './styles.module.scss';


export const StyledFieldTextareaAutosize = ({
                                              fieldId,
                                              label = null,
                                              customContainerStyles = null,
                                              customLabelStyles = null,
                                              customTextareaStyles = null,
                                              ...rest
                                            }) => {
  return (
    <label className={`${styles.styledTextareaContainer} ${customContainerStyles}`}>
      <div className={`${styles.styledLabelContainer} ${customLabelStyles}`}>
        {label}
      </div>
      <TextareaAutosize
        className={`form-control form-control-lg form-control-solid ${styles.styledTextarea} ${customTextareaStyles}`}
        id={fieldId}
        name={fieldId}
        {...rest}
      />
    </label>
  );
};

const FieldTextareaAutosize = ({
                                 fieldId,
                                 label = null,
                                 customContainerStyles = null,
                                 customLabelStyles = null,
                                 customTextareaStyles = null,
                               }) => {

  return (
    <Field
      id={fieldId}
      name={fieldId}
    >
      {({field}) => (
        <label className={`${styles.textareaContainer} ${customContainerStyles}`}>
          <div className={`${styles.styledLabelContainer} ${customLabelStyles}`}>
            {label}
          </div>
          <TextareaAutosize
            className={`form-control form-control-lg form-control-solid ${styles.textarea} ${customTextareaStyles}`}
            {...field}
          />
        </label>
      )}
    </Field>
  )
};

FieldTextareaAutosize.propTypes = {
  fieldId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.node,
  customLabelStyles: PropTypes.string
};

export default FieldTextareaAutosize;

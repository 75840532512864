/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../../_helpers";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import {FormattedMessage} from "react-intl";

export function UserProfileDropdown() {
  const {user} = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span
            className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.fullName}
          </span>
          <span className="symbol symbol-warning symbol-35">
            <span className="symbol-label font-size-h5 text-dark font-weight-bold">
              {user.initials}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        menuAlign="left"
        className="w-auto p-0 m-0 dropdown-menu-left dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
      >
        <div className="p-5">
          <Link
            to="/logout"
            className="nav-link bg-hover-light-o-9 text-hover-dark text-dark font-weight-bold rounded"
          >
            <FormattedMessage
              id="userInfo-btn-logout"
              defaultMessage="Logout"
            />
          </Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

import axios from 'axios';
import {actions} from '../reducers/InspectionsPageTableReducer';

export const APPROVE_SHUMA_REQUEST_SIGNATURE = '/inspections/approveShumaAndRequestSignature';
export const APPROVE_SHUMA = '/inspections/approveShuma';
export const REJECT_SHUMA = '/inspections/rejectShumaApprovalRequest';
export const COPY_SHUMA = '/inspections/copyInspection';
export const DOWNLOAD_SHUMA_FILES = '/inspections/downloadInspectionFiles';

export const approveShumaAndRequestSignature = ({shumaID}) => (dispatch) => {
  return axios.post(APPROVE_SHUMA_REQUEST_SIGNATURE, {shumaID})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaStatusTypeID, shumaStatusTypeName} = body;
      dispatch(actions.approveShuma({
        shumaStatusTypeID,
        shumaStatusTypeName,
        shumaID
      }));
    })
    .catch(error => {
      console.log(error);
    });
};

export const approveShuma = ({shumaID}) => (dispatch) => {
  return axios.post(APPROVE_SHUMA, {shumaID})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaStatusTypeID, shumaStatusTypeName} = body;
      dispatch(actions.approveShuma({
        shumaStatusTypeID,
        shumaStatusTypeName,
        shumaID
      }));
    })
    .catch(error => {
      console.log(error);
    });
};

export const rejectShuma = ({shumaID, rejectText}) => (dispatch) => {
  return axios.post(REJECT_SHUMA, {shumaID, rejectText})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaStatusTypeID, shumaStatusTypeName} = body;
      dispatch(actions.rejectShuma({
        shumaStatusTypeID,
        shumaStatusTypeName,
        shumaID
      }));

    })
    .catch(error => {
      console.log(error);
    });
};

export const copyShuma = (data) => (dispatch) => {

  return axios.post(COPY_SHUMA, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      if (errorCode === 0) {
        dispatch(actions.copyShuma(body.inspection));
      }
      return response.data
    })
    .catch(error => {
      console.log(error);
    });
};

export const downloadShumaFiles = (shumaID) => {

  return axios.post(DOWNLOAD_SHUMA_FILES,{shumaID},{responseType: 'blob'})
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(error);
    });
};

import moment from "moment";

const defaultState = {
  listLoading: false,
  pageCount: 0,
  currentPageNumber: 0,
  entities: null,
  totalResult: 0,
  error: null,
};
export const actionTypes = {
  tasksFetched: 'TASKS_FETCHED',
  tasksAppended: 'TASKS_APPENDED',
  editTask: 'EDIT_TASK',
  deleteTask: 'DELETE_TASK',
  catchError: 'CATCH_ERROR'
};

const getIndex = (array, taskID) => {
  const mappedArray = array.map(item => item.taskID);
  return mappedArray.indexOf(taskID);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.tasksFetched: {
      const {totalPageCount, currentPageNumber, tasks, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: tasks,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.tasksAppended: {
      const {totalPageCount, currentPageNumber, tasks, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...tasks]
          : [...tasks],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.editTask: {
      const {
        taskID,
        responsibleUserID,
        username,
        taskStatus,
        dueDate,
        taskDescription,
        notes,
        visitContactName,
        visitContactPhone,
      } = action.payload;
      const index = getIndex(state.entities, taskID);
      state.entities[index].responsibleUserID = responsibleUserID
      state.entities[index].username = username
      state.entities[index].taskStatus = taskStatus
      state.entities[index].dueDate = dueDate
      state.entities[index].taskDescription = taskDescription
      state.entities[index].notes = notes
      state.entities[index].visitContactName = visitContactName
      state.entities[index].visitContactPhone = visitContactPhone
      return {
        ...state,
        entities: [...state.entities]
      };
    }
    case actionTypes.deleteTask: {
      const {taskID} = action.payload;
      const index = getIndex(state.entities, taskID);
      state.entities.splice(index, 1);
      return {
        ...state,
        entities: [...state.entities]
      };
    }
    case actionTypes.catchError: {
      const error = `TASKS: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
};

export const actions = {
  tasksFetched: (data) => ({
    type: actionTypes.tasksFetched,
    payload: data
  }),
  tasksAppended: (data) => ({
    type: actionTypes.tasksAppended,
    payload: data
  }),
  editTask: (data) => ({
    type: actionTypes.editTask,
    payload: data
  }),
  deleteTask: (data) => ({
    type: actionTypes.deleteTask,
    payload: data
  }),
  catchError: (error) => ({type: actionTypes.catchError, payload: {error}}),
};

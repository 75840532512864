import {FormattedMessage} from "react-intl";
import React from "react";

export const headerCellsArrayCommonDocs = [
  {
    customItemStyles: 'w-160px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-vatType"
        defaultMessage="VAT type"
      />
    )
  },
  {
    customItemStyles: 'w-475px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-description"
        defaultMessage="description"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-quantity"
        defaultMessage="quantity"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-price"
        defaultMessage="price"
      />
    )
  },
  {
    showMarkNecessary: false,
    customItemStyles: '',
    label: ''
  },

];
export const headerCellsArrayReceiptCheque = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cheque-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cheque-bankName"
        defaultMessage="Bank name"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cheque-bankBranch"
        defaultMessage="Bank branch"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cheque-bankAccount"
        defaultMessage="Bank account"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cheque-chequeNum"
        defaultMessage="Cheque number"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cheque-price"
        defaultMessage="Price"
      />
    )
  },
  {
    customItemStyles: '',
    showMarkNecessary: false,
    label: ''
  },
];
export const headerCellsArrayReceiptBankTransfer = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-bankTransfer-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-200px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-bankTransfer-bankName"
        defaultMessage="Bank name"
      />
    )
  },
  {
    customItemStyles: 'w-200px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-bankTransfer-bankBranch"
        defaultMessage="Bank branch"
      />
    )
  },
  {
    customItemStyles: 'w-200px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-bankTransfer-bankAccount"
        defaultMessage="Bank account"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-bankTransfer-price"
        defaultMessage="Price"
      />
    )
  },
  {
    customItemStyles: '',
    showMarkNecessary: false,
    label: ''
  },
];
export const headerCellsArrayReceiptCreditCard = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-creditCard-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-140px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-creditCard-cardNum"
        defaultMessage="Card number"
      />
    )
  },
  {
    customItemStyles: 'w-160px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-creditCard-cardType"
        defaultMessage="Card type"
      />
    )
  },
  {
    customItemStyles: 'w-160px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-creditCard-dealType"
        defaultMessage="Deal type"
      />
    )
  },
  {
    customItemStyles: 'w-140px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-creditCard-numPayments"
        defaultMessage="Number of payments"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-creditCard-price"
        defaultMessage="Price"
      />
    )
  },
  {
    customItemStyles: '',
    showMarkNecessary: false,
    label: null
  },
];
export const headerCellsArrayReceiptPaymentApp = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-paymentApp-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-160px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-paymentApp-appType"
        defaultMessage="App type"
      />
    )
  },
  {
    customItemStyles: 'w-225px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-paymentApp-accountId"
        defaultMessage="Account ID"
      />
    )
  },
  {
    customItemStyles: 'w-225px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-paymentApp-transactionId"
        defaultMessage="Transaction ID"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-paymentApp-price"
        defaultMessage="Price"
      />
    )
  },
  {
    showMarkNecessary: false,
    label: null
  },
];
export const headerCellsArrayReceiptPayPal = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-payPal-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-300px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-payPal-accountId"
        defaultMessage="Account ID"
      />
    )
  },
  {
    customItemStyles: 'w-300px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-payPal-transactionId"
        defaultMessage="Transaction ID"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-payPal-price"
        defaultMessage="Price"
      />
    )
  },
  {
    showMarkNecessary: false,
    label: ''
  },
];
export const headerCellsArrayReceiptCash = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cash-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-500px justify-content-start',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-cash-price"
        defaultMessage="Price"
      />
    )
  },
  {
    customItemStyles: 'w-250px',
    showMarkNecessary: false,
    label: ''
  },
];
export const headerCellsArrayReceiptOther = [
  {
    customItemStyles: 'w-125px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-other-date"
        defaultMessage="Date"
      />
    )
  },
  {
    customItemStyles: 'w-200px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-other-subType"
        defaultMessage="Sub type"
      />
    )
  },
  {
    customItemStyles: 'w-425px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-other-transactionId"
        defaultMessage="transaction ID"
      />
    )
  },
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-other-price"
        defaultMessage="Price"
      />
    )
  },
  {
    customItemStyles: '',
    showMarkNecessary: false,
    label: null
  },
];
export const headerCellsArrayReceiptWithholdingTax = [
  {
    customItemStyles: 'w-150px',
    showMarkNecessary: true,
    label: (
      <FormattedMessage
        id="customer-details-popup-create-document-label-rows-withholdingTax-price"
        defaultMessage="Withholding tax"
      />
    )
  },
  {
    customItemStyles: '',
    showMarkNecessary: false,
    label: ''
  },
];

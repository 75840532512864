import React from 'react';
import {CompleteInspectionContextProvider} from '../CompleteInspectionContext';
import CompleteInspectionPages from '../CompleteInspectionPages';

const CompleteInspectionPage = ({cellContent}) => {
  return (
    <CompleteInspectionContextProvider>
      <CompleteInspectionPages cellContent={cellContent}/>
    </CompleteInspectionContextProvider>
  );
};

export default CompleteInspectionPage;

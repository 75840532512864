import React from 'react';
import moment from 'moment/moment';
import {SectionRef} from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingDate} from '../../../utils/formattingHelper';
import styles from './styles.module.scss';

const Section3 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    visitDate,
    officialDate,
  } = context.data.pdfData;
  const officialDateValue = Boolean(officialDate) ? formattingDate(officialDate) : '';
  const sameDayLabel = (moment(officialDate).isSame(visitDate, 'day'))
    ? ' יום הביקור בנכס'
    : '';
  const title = (
    <>
      <span>{context.sectionNumbers.section3}</span>
      <span>. המועד הקובע לחוות הדעת</span>
    </>
  );

  return (
    <SectionRef title={title} ref={ref}>
      <div className={styles.itemText}>
        המועד הקובע לחוות הדעת הינו{sameDayLabel}, {officialDateValue}.
      </div>
    </SectionRef>
  );
});

export default Section3;

import React from 'react';
import classnames from 'classnames';
import {findFileByTypeId} from '../../../utils/files';
import {convertFileToContent} from '../../FileToContentConverter';
import styles from './styles.module.scss';

const fileTypeID = 12;

const HeaderLine = () => (
  <div className={classnames(styles.bold, styles.marginVert, styles.innerPadding)}>
    זיהוי הנכס בתשריט הבית המשותף:
  </div>
);

export const getPropertyIdentification = (context) => {
  const file = findFileByTypeId(context.files, fileTypeID);
  return Boolean(file)
    ?
    convertFileToContent(context, fileTypeID, {
      firstPageHeader: <HeaderLine/>,
    })
    : [];
};

import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import PriceQuotesPage from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';


export const priceQuotesPageRoute = (
  <ProtectedRoute
    subject={S.PRICE_QUOTES_PAGE}
    path={P.PRICE_QUOTES_PAGE}
    component={PriceQuotesPage}
  />
);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {pdfjs} from 'react-pdf';
import Media from '../Media';
import {findFileByTypeId, getMediaType} from '../../utils/files';
import {getFullDataURI} from '../../utils/files/getFullDataURI';
import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ImagesToPages = ({
                         file,
                         fileSrc,
                         mediaType,
                         firstPageHeader,
                         firstPageFooter,
                         lastPageFooter,
                         ...rest
                       }) => {
  return (
    <div {...rest}>
      {firstPageHeader}
      {file && (
        <Media
          src={fileSrc}
          file={file}
          alt={`${rest['data-testid']} image`}
          type={mediaType}
        />
      )}
      {firstPageFooter}
      {lastPageFooter}
    </div>
  );
};

const PDFAsImageToPage = React.forwardRef(({
                                             firstPageHeader,
                                             firstPageFooter,
                                             lastPageFooter,
                                             file: {
                                               convertedPages,
                                             },
                                             fileSrc,
                                             className,
                                             ...rest
                                           }, ref) => {
  const showFirstPageHeader = index => (index === 0) && Boolean(firstPageHeader);
  const showFirstPageFooter = index => (index === 0) && Boolean(firstPageFooter);
  const showLastPageFooter = index => (index === (convertedPages.length - 1)) && Boolean(lastPageFooter);
  delete (rest.mediaType);

  const mapPages = () => {
    return convertedPages.filter(Boolean).map((fileSrc, index) => {
      return (
        <div key={index}
             className={classnames(styles.flexContainer, className)} {...rest}>
          {showFirstPageHeader(index) && firstPageHeader}
          <div className={classnames(styles.mediaContainer, {
            [styles.bottomMargin]: showFirstPageFooter(index) || showLastPageFooter(index)
          })}>
            <Media
              src={fileSrc}
              alt={`${rest['data-testid']} image`}
              type={'fitted image'}
              page={index + 1}
              className={styles.media}
            />
          </div>
          {showFirstPageFooter(index) && firstPageFooter}
          {showLastPageFooter(index) && lastPageFooter}
        </div>
      );
    });
  };

  return Boolean(convertedPages.length) && mapPages();
});

const FileToContentConverter = ({
                                  data,
                                  fileTypeID,
                                  ...rest
                                }) => {
  const {files} = data;
  const file = findFileByTypeId(files, fileTypeID);
  const fileSrc = file && getFullDataURI(file);
  const mediaType = file && getMediaType(file);
  const props = {
    file,
    fileSrc,
    mediaType,
    ...rest
  };
  const renderPages = () => (mediaType === 'pdf')
    ? <PDFAsImageToPage {...props}/>
    : <ImagesToPages {...props}/>;

  return Boolean(file) && renderPages();
};

const mapPDF = ({
                  file,
                  firstPageHeader,
                  firstPageFooter,
                  lastPageFooter,
                  className,
                }) => {
  const {convertedPages} = file;
  const showFirstPageHeader = index => (index === 0) && Boolean(firstPageHeader);
  const showFirstPageFooter = index => (index === 0) && Boolean(firstPageFooter);
  const showLastPageFooter = index => (index === (convertedPages.length - 1)) && Boolean(lastPageFooter);

  const mapPages = () => {
    return convertedPages.filter(Boolean).map((fileSrc, index) => {
      return (
        <div
          key={index}
          className={classnames(styles.flexContainer, className)}>
          {showFirstPageHeader(index) && (
            <div className={styles.additionalContent}>
              {firstPageHeader}
            </div>
          )}
          <div
            className={classnames(styles.mediaContainer, styles.bottomPadding)}
          >
            <Media
              src={fileSrc}
              alt={`pdf as image`}
              type={'fitted image'}
              page={index + 1}
              className={styles.media}
            />
          </div>
          {showFirstPageFooter(index) && (
            <div className={styles.additionalContent}>
              {firstPageFooter}
            </div>
          )}
          {showLastPageFooter(index) && (
            <div className={styles.additionalContent}>
              {lastPageFooter}
            </div>
          )}
        </div>
      );
    });
  };

  return Boolean(convertedPages.length) && mapPages();
};

const mapImages = ({
                     file,
                     firstPageHeader,
                     firstPageFooter,
                     className,
                   }) => {
  const fileSrc = file && getFullDataURI(file);

  return (fileSrc)
    ? [
      <div className={classnames(styles.flexContainer, className)} key={0}>
        {Boolean(firstPageHeader) && (
          <div className={styles.additionalContent}>
            {firstPageHeader}
          </div>
        )}
        <div
          className={classnames(styles.mediaContainer, styles.bottomPadding)}
        >
          <Media
            src={fileSrc}
            alt={`fitted image`}
            type={'fitted image'}
            className={styles.media}
          />
        </div>
        {Boolean(firstPageFooter) && (
          <div className={styles.additionalContent}>
            {firstPageFooter}
          </div>
        )}
      </div>
    ]
    : [];
};

export const convertFileToContent = (context, fileTypeID, props) => {
  const {files} = context;
  const file = findFileByTypeId(files, fileTypeID);
  const mediaType = file && getMediaType(file);

  return (mediaType === 'pdf')
    ? [...mapPDF({file, ...props})]
    : [...mapImages({file, ...props})];
};

FileToContentConverter.propTypes = {
  data: PropTypes.object.isRequired,
  fileTypeID: PropTypes.number.isRequired,
  firstPageHeader: PropTypes.node,
  firstPageFooter: PropTypes.node,
  lastPageFooter: PropTypes.node
};

export default FileToContentConverter;

import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import DashboardPage from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const dashboardPageRoute = (
  <ProtectedRoute
    subject={S.DASHBOARD_PAGE}
    path={P.DASHBOARD_PAGE}
    component={DashboardPage}
  />
);

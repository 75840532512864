import React from 'react';
import PopupHeader from "./PopupHeader";
import PopupForm from "./PopupForm";
import PopupButtons from "./PopupButtons";

const PopupContent = ({closeModal, data}) => {
  const {firmShumaID, shumaID} = data;

  return (
    <div className={'w-100'}>
      <PopupHeader firmShumaID={firmShumaID}/>
      <PopupForm data={data}/>
      <PopupButtons closeModal={closeModal} shumaID={shumaID}/>
    </div>
  )
};

export default PopupContent;

import React from 'react';

export function AddressFormatter(city, street, houseNumber) {
  const text = (city || street || houseNumber)
    ? [[city, street].filter(Boolean).join(', '), houseNumber].filter(Boolean).join(' ')
    : ''
  ;

  return (
    <div className="font-weight-bold">
      {text}
    </div>
  );
}

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const ShumaType1GrayBox = React.forwardRef(({}, ref) => {
  const {
    data: {
      userData: {
        gender,
      },
    },
  } = useCompleteInspectionContext();
  const genderSuffix1 = (gender === 2) ? 'ה' : '';
  const genderSuffix2 = (gender === 2) ? 'ת' : '';
  const genderPrefix = (gender === 2) ? 'ת' : 'י';

  return (
    <div ref={ref} className={styles.innerPadding}>
      <ul className={styles.grayBgArea}>
        <li className={styles.item}>
          הנני מצהיר{genderSuffix1} כי למיטב ידיעתי העובדות עליהן מתבססת חוו"ד
          נכונות וכי המידע המשפטי המוצג בחוו"ד זו הינו המידע המשפטי עליו מבוססת
          השומה. חוו"ד זו אינה מהווה חוו"ד משפטי ו/או תחליף ליעוץ משפטי.
        </li>
        <li className={styles.item}>
          פרטי רישום הזכויות במקרקעין והמצב התכנוני אומתו ע"י הח"מ במקורות
          המידע הגלויים לציבור במסגרת חופש המידע.
        </li>
        <li className={styles.item}>
          אין בחוו"ד זו משום חוו"ד הנדסית ו/או אדריכלית ואין בה כדי להעיד על
          נתוני איכות הסביבה, לרבות קיומן של אנטנות סלולריות, זיהום קרקע
          וכיוצ"ב.
        </li>
        <li className={styles.item}>
          הנני מצהיר{genderSuffix1} כי חוו"ד זו נערכה עפ"י כללי האתיקה של לשכת
          שמאי המקרקעין וכי אין לי עניין או נגיעה אישית לנכס הנדון . חוו"ד זו
          נעשתה עפ"י מיטב הבנתי,ידיעותיי וניסיוני המקצועי.
        </li>
        <li className={styles.item}>
          כל הנתונים והערכים בחוו"ד נכונים ליום הקובע לחוו"ד. אין הח"מ מתחייבת
          כי אלה יהיו הערכים בעתיד.אם בכלל.
        </li>
        <li className={styles.item}>
          חוו"ד זו נערכה למטרה המנויה לעיל עבור מזמין הדו"ח. אחריותה של הח"מ
          מוגבלת למזמין הדו"ח ולמטרת הדו"ח בלבד. שימוש שלא בהתאם לאמור לעיל
          יעשה לאחר קבלת אישור מראש ובכתב מאת הח"מ בלבד. הח"מ
          לא {genderPrefix}היה אחראי{genderSuffix2} כלפי מאן דהו אשר יעשה
          שימוש בשומה זו ללא אישור כאמור לעיל.
        </li>
        <li className={styles.item}>
          חוו"ד זו אינה שומה לצרכי משכנתא ו/או לצורך מתן אשראי מכל מן וסוג
          שהוא.
        </li>
        <li className={styles.item}>
          חוו"ד זו אינה המלצה או אי המלצה לעשיית עסקה.
        </li>
      </ul>
    </div>
  );
});

export default ShumaType1GrayBox;

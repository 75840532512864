import React from 'react';
import {useLocation} from 'react-router';
import {checkIsActive} from '../../../../_helpers';
import links from '../../../../../app/modules/links';

export function AsideMenuList({layoutProps}) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {links.map((Link, ind) => <Link key={ind}/>)}
    </ul>
  );
}

import React from 'react';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {housePhysicalStateOptions,} from '../../../constants/options';
import styles from './styles.module.scss';

const Section6Text710 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {housePhysicalState} = context.data.pdfData;
  const housePhysicalStateValue = getSelectedOptionLabel(housePhysicalState, housePhysicalStateOptions);

  const {noteNumbers} = context;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>
        מצב פיזי
        <NoteNumber id={'housePhysicalStateValue'}>
          {noteNumbers.housePhysicalStateValue}
        </NoteNumber>
        :
      </span>
      <span data-testid="housePhysicalStateValue">
        {` ${housePhysicalStateValue}`}
      </span>.
    </div>
  );
});

export default Section6Text710;

import React from 'react';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import moment from 'moment/moment';
import classnames from 'classnames';
import {getDocCodeString} from '../../utils/texts';
import styles from './styles.module.scss';

export const DocumentCodeAndTimestamp = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    gush, helka, ttHelka,
    pdfCreateTime,
  } = context.data.pdfData;
  const pdfCreateTimeValue = moment(pdfCreateTime).format('DD/MM/YYYY');

  return (
    <div className={classnames(styles.blockLeftAlign)} ref={ref}>
      <div className={styles.blockLine}>
        תאריך: {pdfCreateTimeValue}
      </div>
      <div className={styles.blockLine}>
        סימננו:{getDocCodeString({gush, helka, ttHelka, pdfCreateTime})}
      </div>
    </div>
  );
});

export default DocumentCodeAndTimestamp;

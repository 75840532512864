import React from 'react';
import Tabs from '../../../_common/components/Tabs';
import TabOrderGeneral from '../TabOrderGeneral';
import TabOrderInspections from '../TabOrderInspections';
import TabOrderDocuments from '../TabOrderDocuments';
import {useOrderDetailsUIOrderContext} from '../OrderDetailsUIOrderContext';

export function TabsContainer() {
  const {nextTabsEnabled} = useOrderDetailsUIOrderContext();

  return (
    <div className={'w-100 m-10 mx-auto px-12'}>
      <Tabs items={[
        {
          key: 'orderGeneral',
          title: 'פרטי הזמנה',
          icon: <i className="flaticon2-list-3"/>,
          content: <TabOrderGeneral/>,
        },
        {
          key: 'orderInspections',
          title: 'דוחות',
          icon: <i className="flaticon-home-1"/>,
          content: <TabOrderInspections/>,
          disabled: !nextTabsEnabled
        },
        {
          key: 'orderDocuments',
          title: 'מסמכים',
          icon: <i className="flaticon2-image-file"/>,
          content: <TabOrderDocuments/>,
          disabled: !nextTabsEnabled
        },
      ]}
      />
    </div>
  );
}

export default TabsContainer;

import React from 'react';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {
  getBalconyAndStorageLabels,
  getStorageParkingPermitArea,
  getTextChunks,
  mapTextChunks
} from '../../../utils/texts';
import styles from './styles.module.scss';

export const getSection6Text77 = (context) => {
  const {
    housePermitArea,
    houseMainArea,
    houseServiceArea,
    housePermitAreaText,
    houseBalconyOpenPermitArea,
    houseBalconyClosedPermitArea,
    houseYardPermitArea,
    houseRoofPermitArea,
    buildPermitNotSigned,
    houseStoragePermitArea,
    houseParkingPermitArea,
    housePermitAreaIncludeBalcony,
    housePermitAreaIncludeStorage
  } = context.data.pdfData;
  const housePermitAreaIncludeBalconyStorage = getBalconyAndStorageLabels({
    houseAreaIncludeBalcony: housePermitAreaIncludeBalcony,
    houseBalconyOpenArea: houseBalconyOpenPermitArea,
    houseBalconyClosedArea: houseBalconyClosedPermitArea,
    houseAreaIncludeStorage: housePermitAreaIncludeStorage,
    houseStorageArea: houseStoragePermitArea,
  });
  const {noteNumbers} = context;
  let firstTextLine;
  let housePermitAreaTextValues = [];

  if (Boolean(housePermitAreaText)) {
    [firstTextLine, ...housePermitAreaTextValues] = getTextChunks(housePermitAreaText);
  }

  return [
    <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
      <span className={styles.bold}>
            שטח ברישוי
        {(buildPermitNotSigned === 1) && (
          <NoteNumber id={'buildPermitNotSigned'}>
            {noteNumbers.buildPermitNotSigned}
          </NoteNumber>
        )}
        :
      </span>
      <span> כ-</span>
      {` ${housePermitArea} `}
      <span>מ"ר במדידה גראפית</span>
      {Boolean(housePermitAreaIncludeBalconyStorage)
        && (
          <span data-testid="housePermitAreaIncludeBalconyStorage">
                {`, ${housePermitAreaIncludeBalconyStorage} `}
              </span>
        )}
      <span>.</span>
      {Boolean(houseMainArea) && (
        <>
          {` ${houseMainArea} `}
          <span>מ"ר שטח עיקרי,</span>
        </>
      )}
      {Boolean(houseServiceArea) && (
        <>
          {` ${houseServiceArea} `}
          <span>מ"ר שטח שירות,</span>
        </>
      )}
      {Boolean(houseBalconyOpenPermitArea) && (
        <>
          {` ${houseBalconyOpenPermitArea} `}
          <span>מ"ר שטח מרפסות פתוחות,</span>
        </>
      )}
      {Boolean(houseBalconyClosedPermitArea) && (
        <>
          {` ${houseBalconyClosedPermitArea} `}
          <span>מ"ר שטח מרפסות סגורות,</span>
        </>
      )}
      {Boolean(houseYardPermitArea) && (
        <>
          {` ${houseYardPermitArea} `}
          <span>מ"ר שטח חצר,</span>
        </>
      )}
      {Boolean(houseRoofPermitArea) && (
        <>
          {` ${houseRoofPermitArea} `}
          <span>מ"ר שטח גג,</span>
        </>
      )}
      <span>{` ${getStorageParkingPermitArea(houseStoragePermitArea, houseParkingPermitArea)}`}</span>
      <span>{Boolean(firstTextLine) ? ` ${firstTextLine}` : ''}</span>
      <span>{Boolean(housePermitAreaTextValues.length === 0) ? `.` : ''}</span>
    </div>,
    ...housePermitAreaTextValues
      .map(mapTextChunks)
      .map((item, index) => (
        <div
          className={classnames(styles.itemText, styles.innerPadding)}
          key={index + 1}
        >
          {item}
        </div>
      ))
  ];
};

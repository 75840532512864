import {actionTypes} from "../reducers/statusListReduser";
import axios from "axios";

export const GET_STATUS_LIST = "/orders/getOrderStatusOptions";

export const getStatusList = () => dispatch => {
  return axios.get(GET_STATUS_LIST)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch({
        type: actionTypes.getStatusList,
        payload: {statusList: body},
      });
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

import React from 'react';
import {useHeader} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import {StreetsProvider} from '../../_common/components/CitiesAndStreets';
import {SimpleDateRangeProvider} from "../SimpleDateRangeContext";
import {SimpleDateRange} from "../components/DashboardPageCharts/SimpleDateRange";
import {ExtendedDateRangeProvider} from "../ExtendedDateRangeContext";
import {ExtendedDateRange} from "../components/DashboardPageCharts/ExtendedDateRange";
import SimpleDateRangeFilters from "../components/DashboardPageFilter/components/SimpleDateRangeFilters";
import ExtendedDateRangeFilters from "../components/DashboardPageFilter/components/ExtendedDateRangeFilters";

const DashboardPage = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'dashboard-page-header',
    defaultMessage: 'Dashboard page'
  }));

  return (
    <StreetsProvider>
      <SimpleDateRangeProvider>
        <SimpleDateRangeFilters/>
        <SimpleDateRange/>
      </SimpleDateRangeProvider>
      <ExtendedDateRangeProvider>
        <ExtendedDateRangeFilters/>
        <ExtendedDateRange/>
      </ExtendedDateRangeProvider>
    </StreetsProvider>
  );
};

export default DashboardPage;

const defaultState = {
  general: {},
  pageCount: 0,
  currentPageNumber: 0,
  entities: null,
  totalResult: 0,
  listLoading: false,
  error: false,
  errorMessage: null
};

export const actionTypes = {
  SET_CUSTOMER_DETAILS: 'SET_CUSTOMER_DETAILS',
  EDIT_CUSTOMER: 'INSPECTION_DETAILS_FETCHED',
  FETCH_INSPECTIONS: 'FETCH_INSPECTIONS',
  APPEND_INSPECTIONS: 'APPEND_INSPECTIONS',
  catchError: 'CATCH_ERROR'
};

export default (state= defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_DETAILS: {
      const {
        firstName,
        lastName,
        phone,
        email,
        customerTypeID,
        customerTypeName,
        idNumber,
        genderID,
        genderName,
        greenInvoiceClientID,
        address,
        customerStatusID,
        customerStatus
      } = action.payload;

      return {
        ...state,
        general: {
          firstName,
          lastName,
          phone,
          email,
          customerTypeID,
          customerTypeName,
          idNumber,
          genderID,
          genderName,
          greenInvoiceClientID,
          address,
          customerStatusID,
          customerStatus
        },
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.FETCH_INSPECTIONS: {
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: inspections,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.APPEND_INSPECTIONS: {
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...inspections]
          : [...inspections],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.catchError: {
      return {
        ...state,
        error: true,
        errorMessage: `EDIT CUSTOMER: ${action.payload.error}`
      };
    }
    default: return state;
  }
};

export const actions = {
  setCustomer: (data) => ({
    type: actionTypes.SET_CUSTOMER_DETAILS,
    payload: data
  }),
  clear: () => ({
    type: actionTypes.SET_CUSTOMER_DETAILS,
    payload: {...defaultState}
  }),
  fetchInspections: data => ({
    type: actionTypes.FETCH_INSPECTIONS,
    payload: data
  }),
  appendInspections: data => ({
    type: actionTypes.APPEND_INSPECTIONS,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: {error}
  }),
};

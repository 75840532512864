const defaultState = {
  listLoading: false,
  pageCount: 0,
  currentPageNumber: 0,
  entities: null,
  totalResult: 0,
  error: null,
};
export const actionTypes = {
  oldInspectionsFetched: 'OLD_INSPECTIONS_FETCHED',
  oldInspectionsAppended: 'OLD_INSPECTIONS_APPENDED',
  catchError: 'CATCH_ERROR'
};

export default (state= defaultState, action) => {
  switch (action.type) {
    case actionTypes.oldInspectionsFetched: {
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: inspections,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.oldInspectionsAppended: {
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...inspections]
          : [...inspections],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.CatchError: {
      const error = `INSPECTIONS: ${action.payload.error}`;
      return {...state, error};
    }
    default: return state;
  }
};

export const actions = {
  oldInspectionsFetched: (data) => ({
    type: actionTypes. oldInspectionsFetched,
    payload: data
  }),
  oldInspectionsAppended: (data) => ({
    type: actionTypes. oldInspectionsAppended,
    payload: data
  }),
  catchError: (error) => ({type: actionTypes.catchError, payload: {error}}),
};

import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

const ShumaType1Section10Subtitle = React.forwardRef(({}, ref) => (
  <div className={classnames(styles.itemText, styles.bold, styles.innerPadding)} ref={ref}>
    הצעת שווי
  </div>
));

export default ShumaType1Section10Subtitle;

export const commonStyles = {
  control: (base, state) => ({
    height: 'calc(1.5em + 1.65rem + 2px)',
    fontWeight: '500',
    color: '#6c6c6c',
    border: 0,
    backgroundColor: state.isFocused ? '#ebedf3' : '#F3F6F9',
    boxShadow: state.isFocused ? null : null,
    opacity: state.isDisabled ? '0.3' : '1',
    borderRadius: '.42rem',
    fontSize: '1.08rem',
    padding: '0 1.42rem 0 3rem',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0'
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  input: (base) => ({
    ...base,
    paddingLeft: '0.5rem'
  }),
  dropdownIndicator: () => ({
    position: 'absolute',
    top: '25%',
    left: '0.5rem',
    color: '#6c6c6c',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  singleValue: () => ({
    color: '#3f4254'
  }),
  menu: (base) => ({
    ...base,
    top: '80%',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '130px',
  }),
};
export const filtersBar = {
  control: (base, state) => ({
    height: '2.7rem',
    fontWeight: '500',
    color: '#cccccc',
    border: 0,
    backgroundColor: state.isFocused ? '#ebedf3' : '#F3F6F9',
    boxShadow: state.isFocused ? null : null,
    opacity: state.isDisabled ? '0.3' : '1',
    borderRadius: '.42rem',
    fontSize: '1.08rem',
    padding: '0 1rem 0 3.7rem',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0',
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  input: (base) => ({
    ...base,
    paddingLeft: '1rem',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#b7b7c3',
    fontWeight: '400',
  }),
  dropdownIndicator: () => ({
    position: 'absolute',
    top: '19%',
    left: '2rem',
    borderLeft: '1px solid #6c6c6c',
    color: '#6c6c6c',
  }),
  menu: (base) => ({
    ...base,
    top: '73%',
    zIndex: 999
  }),
};
export const inspectionDetails = {
  control: (base, state) => ({
    height: 'calc(1.5em + 1.65rem + 2px)',
    fontWeight: '500',
    color: '#6c6c6c',
    border: 0,
    backgroundColor: state.isFocused ? '#ebedf3' : '#F3F6F9',
    boxShadow: state.isFocused ? null : null,
    opacity: state.isDisabled ? '0.3' : '1',
    borderRadius: '.42rem',
    fontSize: '1.08rem',
    padding: '0 1.42rem 0 3rem',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0'
  }),
  container: (base) => ({
    ...base,
    width: '420px',
  }),
  input: (base) => ({
    ...base,
    paddingLeft: '0.5rem'
  }),
  dropdownIndicator: () => ({
    position: 'absolute',
    top: '25%',
    left: '0.5rem',
    color: '#6c6c6c',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    top: '73%',
  }),
};
export const popupCreateDocument = {
  control: (base, state) => ({
    height: 'calc(1.5em + 1.65rem + 2px)',
    fontWeight: '500',
    color: '#6c6c6c',
    border: 0,
    backgroundColor: state.isFocused ? '#ebedf3' : '#F3F6F9',
    boxShadow: state.isFocused ? null : null,
    opacity: state.isDisabled ? '0.3' : '1',
    borderRadius: '.42rem',
    fontSize: '1.08rem',
    padding: '0 1.42rem 0 3rem',
  }),
  placeholder: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0',
    position: 'relative'
  }),
  container: (base) => ({
    ...base,
    width: '120px',
  }),
  input: (base) => ({
    ...base,
    paddingLeft: '0.5rem'
  }),
  dropdownIndicator: () => ({
    position: 'absolute',
    top: '25%',
    left: '0.5rem',
    color: '#6c6c6c',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  singleValue: () => ({
    color: '#3f4254',
    position: 'absolute',
    right: '0px'
  }),
};
export const createDocumentGridSelectStyles = {
  control: (base, state) => ({
    height: 'calc(1.5em + 1.65rem + 2px)',
    fontWeight: '500',
    color: '#6c6c6c',
    border: 0,
    backgroundColor: state.isFocused ? '#ebedf3' : '#F3F6F9',
    boxShadow: state.isFocused ? null : null,
    opacity: state.isDisabled ? '0.3' : '1',
    borderRadius: '.42rem',
    fontSize: '1.08rem',
    padding: '0 1.42rem 0 3rem',
  }),
  placeholder: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0'
  }),
  singleValue: () => ({
    color: '#3f4254',
    position: 'absolute',
    right: '0',
    width: '100%'
  }),
  container: (base) => ({
    ...base,
    width: '160px',
    position: 'static',
    marginLeft: '1rem',
  }),
  dropdownIndicator: () => ({
    position: 'absolute',
    top: '-2.5rem',
    left: '-2.4rem',
    color: '#6c6c6c',
  }),
  menu: (base) => ({
    ...base,
    top: 'initial',
    position: 'absolute',
    width: '210px',
    zIndex: 999,
  }),
  input: () => ({
    marginTop: 0,
    marginBottom: 0
  }),
  indicatorsContainer: (base) => ({
    ...base,
    position: 'relative'
  }),
};
export const createDocumentGridReceiptSelectStyles = {
  singleValue: () => ({
    color: '#3f4254',
    position: 'absolute',
    right: 0
  }),
};
export const createDocumentGridReceiptOtherSelectStyles = {
  container: (base) => ({
    ...base,
    width: '210px',
    position: 'static',
    marginLeft: '1rem',
  }),
  singleValue: () => ({
    color: '#3f4254',
    position: 'absolute',
    right: 0
  }),
};
export const gridSelectStyles = {
  control: (base, state) => ({
    height: 'calc(1.5em + 1.65rem + 2px)',
    fontWeight: '500',
    color: '#6c6c6c',
    border: 0,
    backgroundColor: state.isFocused ? '#ebedf3' : '#F3F6F9',
    boxShadow: state.isFocused ? null : null,
    opacity: state.isDisabled ? '0.3' : '1',
    borderRadius: '.42rem',
    fontSize: '1.08rem',
    padding: '0 1.42rem 0 3rem',
    width: '250px',
  }),
  placeholder: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0'
  }),
  singleValue: () => ({
    color: '#3f4254',
    position: 'absolute',
    right: '0',
    width: '100%'
  }),
  container: (base) => ({
    ...base,
    position: 'static',
  }),
  dropdownIndicator: () => ({
    position: 'absolute',
    top: '-2.5rem',
    left: '-2.4rem',
    color: '#6c6c6c',
  }),
  menu: (base) => ({
    ...base,
    top: 'initial',
    position: 'absolute',
    zIndex: 999,
    width: '250px'
  }),
  input: () => ({
    marginTop: 0,
    marginBottom: 0
  }),
  indicatorsContainer: (base) => ({
    ...base,
    position: 'relative'
  }),
};


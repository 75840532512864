import React, {createContext, useContext, useState} from 'react';

const CacheContext = createContext({});

export function useCacheContext() {
  return useContext(CacheContext);
}

export function CacheProvider({children}) {
  const [cache, setCache] = useState({});

  const getItem = key => cache[key];

  const setItem = (key, value) => setCache(prevState => {
    return {...prevState, [key]: value};
  });

  const ctx = {
    getItem,
    setItem
  };

  return (
    <CacheContext.Provider value={ctx}>
      {children}
    </CacheContext.Provider>
  );
}

import React from 'react';
import {FormattedMessage} from "react-intl";
import {FileItem} from "../FileItem/FileItem";
import Empty from "../../../../../_common/components/Empty";
import classnames from 'classnames';
import styles from "./styles.module.scss";


export function FileList({
                           gotFiles,
                           fileList,
                           orderID,
                           shumaID,
                           showFileTypeColumn = true,
                           allowDownloadFile = true,
                           allowDeleteFile = true,
                           allowSelectFile = false,
                           fileTypeID,
                           closePopup
                         }) {
  const mapFiles = file => {
    const {
      fileID: fileID,
      fileName,
      fileTypeName
    } = file;

    return (
      <FileItem
        fileName={fileName}
        fileID={fileID}
        fileTypeName={fileTypeName}
        fileTypeID={fileTypeID}
        orderID={orderID}
        shumaID={shumaID}
        showFileTypeColumn={showFileTypeColumn}
        allowDownloadFile={allowDownloadFile}
        allowDeleteFile={allowDeleteFile}
        allowSelectFile={allowSelectFile}
        closePopup={closePopup}
      />
    )
  };

  const scrollPaddingStyle = fileList.length > 9 ? '13px' : '0px';
  const scrollPaddingClassName = fileList.length > 9 ? 'pr-11' : '';
  const gridStyles = {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '495px',
    paddingLeft: scrollPaddingStyle
  };

  return (
    <div>
      {gotFiles
        ? (
          <>
            <div className={classnames(styles.filesList, scrollPaddingClassName)}>
              <div className="font-weight-bolder">
                <FormattedMessage
                  id="inspection-details-page-files-column-name"
                  defaultMessage="Name"
                />
              </div>
              {showFileTypeColumn && (
                <div className="font-weight-bolder">
                  <FormattedMessage
                    id="inspection-details-page-files-column-type"
                    defaultMessage="Type"
                  />
                </div>
              )}
              {allowDeleteFile && (
                <div className="font-weight-bolder">
                  <FormattedMessage
                    id="inspection-details-page-files-column-deleteFile"
                    defaultMessage="Delete"
                  />
                </div>
              )}
              {allowDownloadFile && (
                <div className="font-weight-bolder">
                  <FormattedMessage
                    id="inspection-details-page-files-column-downloadFile"
                    defaultMessage="Download"
                  />
                </div>
              )}
              {allowSelectFile && (
                <div className="font-weight-bolder">
                  <FormattedMessage
                    id="inspection-details-page-files-column-selectFile"
                    defaultMessage="Select"
                  />
                </div>
              )}
            </div>
            <div style={gridStyles}>
              {fileList.map(mapFiles)}
            </div>
          </>
        )
        : <Empty customContainerStyles={'pt-12'}/>
      }
    </div>
  );
}

import React, {Suspense} from 'react';
import {Redirect, Switch} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {
  CitiesProvider
} from './modules/_common/components/CitiesAndStreets';
import {
  DecisionTreeOptionsContextProvider
} from './modules/_common/components/DecisionTreeOptions';
import {LayoutSplashScreen} from '../_metronic/layout';
import routes from './modules/routes';
import {pathnames as P} from './modules/_common/constants/pathnames';

export default function BasePage() {
  const defaultPage = useSelector(
    state => state.auth?.defaultPage,
    shallowEqual
  );

  return (
    <CitiesProvider>
      <DecisionTreeOptionsContextProvider>
        <Suspense fallback={<LayoutSplashScreen/>}>
          <link
            href={'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css'}
            rel="stylesheet"
          />
          <Switch>
            <Redirect exact from="/" to={defaultPage || P.DASHBOARD_PAGE}/>
            {routes.map(Route => Route)}
          </Switch>
        </Suspense>
      </DecisionTreeOptionsContextProvider>
    </CitiesProvider>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import {useCustomerDetailsUIContext} from "../../CustomerDetailsUIContext";
import {FormattedMessage} from "react-intl";
import SimpleButtonDropdown from "../../../../_common/components/SimpleButtonDropdown";
import {InteractionsMenuItem} from '../InteractionsMenuItems';
import PopupDeleteFile from "../../PopupDeleteFile";
import interactionIcon from '../../../assets/interaction.svg';
import downloadFileIcon from '../../../assets/download.svg';
import styles from './styles.module.scss';

const InteractionsMenu = ({fileName, fileID}) => {
  const {handleFileDownload} = useCustomerDetailsUIContext();

  return (
    <div className={styles.interaction}>
      <SimpleButtonDropdown
        title={
          <img
            src={interactionIcon}
            alt="interaction"
          />
        }
        items={[
          {
            itemContent: (
              <InteractionsMenuItem
                label={
                  <FormattedMessage
                    id="download-file"
                    defaultMessage="download file"
                  />
                }
                icon={downloadFileIcon}
                onClick={() => handleFileDownload(fileName)}
              />
            )
          },
          {
            itemContent: (
              <PopupDeleteFile
                fileName={fileName}
                fileID={fileID}
              />
            )
          },
        ]}
      />
    </div>
  );
};

InteractionsMenu.propTypes = {
  fileName: PropTypes.string,
  fileID: PropTypes.number,
};

export default InteractionsMenu;

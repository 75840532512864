import React, {
  createContext, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import {decisionTree} from '../../_common/constants/decisionTree';
import {useOrderDetailsUIInspectionsListContext} from './OrderDetailsUIInspectionsListContext';

const OrderDetailsUINewInspectionContext = createContext('');

export function useOrderDetailsUINewInspectionContext() {
  return useContext(OrderDetailsUINewInspectionContext);
}

export function OrderDetailsUINewInspectionProvider({
                                                      index,
                                                      children
                                                    }) {

  const [showBankData, setShowBankData] = useState(false);
  const disabledFirstShumaReasonID = false;

  const {formikOrderInspections} = useOrderDetailsUIInspectionsListContext();

  const decisionTreeOptions = {
    disabled: [],
  };

  const handleResetDecisionTree = (value, fieldNames) => {
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanText,
      otherPropertyTypeID,
      loanSubTypeID,
    } = fieldNames;
    const {setFieldValue, validateForm} = formikOrderInspections;
    switch (value) {
      case decisionTree.bankSecure:
        setFieldValue(bankID, null);
        setFieldValue(shumaTypeID, null);
        setFieldValue(propertyTypeID, null);
        setFieldValue(loanTypeID, null);
        setFieldValue(loanSubTypeID, null);
        setFieldValue(loanText, null);
        setFieldValue(otherPropertyTypeID, null);
      case decisionTree.rightsEstimation:
      default:
        setFieldValue(shumaTypeID, null);
        setFieldValue(propertyTypeID, null);
    }
    validateForm();
  };

  const handleResetPreviousSteps = (fieldId, value, fieldNames, values) => {
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanSubTypeID,
      loanText,
      otherPropertyTypeID,
      bankBranchNumber,
      bankBranchName,
      bankClerkFullName,
      bankEmail,
      bankRequestID,
      bankBuildType,
    } = fieldNames;
    const {setFieldValue} = formikOrderInspections;
    const shumaTypeIDFieldEnabled = !decisionTreeOptions.disabled.includes(shumaTypeID);
    switch (fieldId) {
      case (bankID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(bankBranchNumber, '');
          setFieldValue(bankBranchName, '');
          setFieldValue(bankClerkFullName, '');
          setFieldValue(bankEmail, '');
          setFieldValue(bankRequestID, '');
          setFieldValue(bankBuildType, '');
          setFieldValue(shumaTypeID, null);
        }
      case (shumaTypeID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(propertyTypeID, null);
        }
      case (propertyTypeID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(loanTypeID, null);
        }
      case (loanTypeID):
      default:
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(loanText, '');
          setFieldValue(loanSubTypeID, '');
          setFieldValue(otherPropertyTypeID, null);
        }
    }
    validateUpdatedForm({
      fieldId,
      value,
      fieldNames,
      formValues: values, ...formikOrderInspections
    });
  };

  const validateUpdatedForm = ({
                                 fieldId,
                                 value,
                                 fieldNames,
                                 formValues,
                                 validateForm,
                                 values,
                                 setErrors
                               }) => {
    const updatedValues = {...values, [fieldId]: value};
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanSubTypeID,
      otherPropertyTypeID,
    } = fieldNames;

    switch (fieldId) {
      case (bankID):
        updatedValues[shumaTypeID] = formValues.shumaTypeID;
      case (shumaTypeID):
        updatedValues[propertyTypeID] = formValues.propertyTypeID;
      case (propertyTypeID):
        updatedValues[loanTypeID] = formValues.loanTypeID;
      case (loanTypeID):
      default:
        updatedValues[loanSubTypeID] = formValues.loanSubTypeID;
        updatedValues[otherPropertyTypeID] = formValues.otherPropertyTypeID;
    }
    validateForm(updatedValues).then(setErrors);
  };

  const fieldNames = {
    propertyTypeIDRightsEstimation: `inspections.${index}.propertyTypeID-rightsEstimation`,
    bankID: `inspections.${index}.bankID`,
    shumaTypeID: `inspections.${index}.shumaTypeID`,
    propertyTypeID: `inspections.${index}.propertyTypeID`,
    loanTypeID: `inspections.${index}.loanTypeID`,
    loanText: `inspections.${index}.loanText`,
    otherPropertyTypeID: `inspections.${index}.otherPropertyTypeID`,
    shumaReasonID: `inspections.${index}.shumaReasonID`,
    bankBranchNumber: `inspections.${index}.bankBranchNumber`,
    bankBranchName: `inspections.${index}.bankBranchName`,
    bankClerkFullName: `inspections.${index}.bankClerkFullName`,
    bankEmail: `inspections.${index}.bankEmail`,
    bankRequestID: `inspections.${index}.bankRequestID`,
    bankBuildType: `inspections.${index}.bankBuildType`,
    responsibleUser: `inspections.${index}.responsibleUser`,
    dueDate: `inspections.${index}.dueDate`,
    city: `inspections.${index}.city`,
    gush: `inspections.${index}.gush`,
    street: `inspections.${index}.street`,
    helka: `inspections.${index}.helka`,
    houseNumber: `inspections.${index}.houseNumber`,
    ttHelka: `inspections.${index}.ttHelka`,
    apartmentNumber: `inspections.${index}.apartmentNumber`,
    notes: `inspections.${index}.notes`,
    landLotNum: `inspections.${index}.landLotNum`,
    landLotNumAccordingTaba: `inspections.${index}.landLotNumAccordingTaba`,
    loanSubTypeID: `inspections.${index}.loanSubTypeID`,
    shumaMazminIdentityID: `inspections.${index}.shumaMazminIdentityID`,
    underConstruction: `inspections.${index}.underConstruction`,
  };

  const value = {
    formikOrderInspection: formikOrderInspections,
    decisionTreeProps: {
      decisionTree,
      decisionTreeOptions,
      handleResetDecisionTree,
      handleResetPreviousSteps,
      disabledFirstShumaReasonID,
      showBankData,
      setShowBankData,
    },
    fieldNames,
  };

  return (
    <OrderDetailsUINewInspectionContext.Provider value={value}>
      {children}
    </OrderDetailsUINewInspectionContext.Provider>
  );
}

OrderDetailsUINewInspectionProvider.propTypes = {
  inspection: PropTypes.object,
  children: PropTypes.node,
};

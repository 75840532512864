import React from 'react';
import ChartView from "../../../_common/components/ChartView";


export const Chart = ({
                        header,
                        options,
                        type,
                        width,
                        height
                      }) => {

  return (
    <ChartView
      options={options}
      series={options.series}
      type={type}
      width={width}
      height={height}
      containerClassNames={'bg-white rounded mx-2 h-350px mb-4'}
      header={header}
    />
  )
}

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getPropertyPopulatedLabel} from '../../../utils/texts';
import styles from './styles.module.scss';

const Section6Text713 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {propertyPopulated} = context.data.pdfData;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>אכלוס הנכס: </span>
      <span data-testid="propertyPopulatedLabel">
        {` ${getPropertyPopulatedLabel(propertyPopulated)}`}
      </span>
      <span>.</span>
    </div>
  );
});

export default Section6Text713;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import {getLandSurroundingsLabel} from '../../../utils/texts';
import styles from './styles.module.scss';

const ShumaType1and2Section6Text = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        streetName,
        gush, helka,
        landShape,
        registeredArea,
        landTopography,
        landDirection,
        landSurroundings,
        landSurroundingsText,
        landFront,
        buildingNearbyHazard
      },
    },
    noteNumbers
  } = useCompleteInspectionContext();

  const landShapeValue = (landShape === null) ? '' : getSelectedOptionLabel(landShape, options.landShapeOptions);
  const landTopographyValue = (landSurroundings === null) ? '' : getSelectedOptionLabel(landTopography, options.landTopographyOptions);
  const landDirectionValue = getSelectedOptionLabel(landDirection, options.landDirectionOptions);
  const landSurroundingsValue = getLandSurroundingsLabel(landSurroundings, landSurroundingsText);

  return (
    <div className={styles.innerPadding} data-testid="section6-text" ref={ref}>
      <div className={classnames(styles.itemText, styles.bold)}>
        קרקע
      </div>
      <div className={styles.itemText}>
        חלקה {helka} בגוש {gush} מהווה חלקה {landShapeValue} בשטח רשום
        של {registeredArea} מ"ר
        <NoteNumber id={'landShapeValueText'}>{noteNumbers.landShapeValueText}</NoteNumber>.
      </div>
      {Boolean(landTopography) && (
        <div className={styles.itemText}>
          טופוגרפית החלקה הכללית - {landTopographyValue}.
        </div>
      )}
      {(landFront === 1)
        ? (
          <div className={classnames(styles.itemText, {[styles.lastSectionLine]: !Boolean(buildingNearbyHazard)})}>
            <span>לחלקה חזית</span>
            <span>{` ${landDirectionValue} `}</span>
            <span>לרח' </span>
            <span>{` ${streetName}.`}</span>
            {(Boolean(landSurroundings)) && (
              <>
                <span>החלקה גובלת ב</span>
                <span>{`${landSurroundingsValue}.`}</span>
              </>
            )}
          </div>
        )
        : (
          <div className={classnames(styles.itemText, {[styles.lastSectionLine]: !Boolean(buildingNearbyHazard)})}>
            <span>לחלקה אין גישה ישירה לרחוב</span>
            {` ${streetName}. `}
            {(Boolean(landSurroundings)) && (
              <>
                <span>החלקה גובלת ב</span>
                <span>{`${landSurroundingsValue}.`}</span>
              </>
            )}
          </div>
        )}
    </div>
  );
});

ShumaType1and2Section6Text.propTypes = {
  noteNumber: PropTypes.array,
};

export default ShumaType1and2Section6Text;

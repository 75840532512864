import React from 'react';
import classnames from 'classnames';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

const getCountryStateDataSection = (context) => {
  const {countryStateData} = context.data.pdfData;
  const chunks = Boolean(countryStateData)
    ? getTextChunks(countryStateData)
    : [];

  return chunks.length > 0 ? [
      <div className={classnames(styles.itemTitle, styles.innerPadding)} key={0}>
        מחוזיות/ארציות:
      </div>,
      ...chunks
        .map(mapTextChunks)
        .map((item, index, array) => (
          <div className={classnames(
            styles.innerPadding,
            {[styles.lastSectionLine]: index === array.length - 1}
          )}>
            {item}
          </div>
        ))
    ]
    : [];
};

export default getCountryStateDataSection;

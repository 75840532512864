import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Field} from 'formik';
import styles from './styles.module.scss';

export const RadioDot = ({
                           field,
                           meta = {},
                           value,
                           label,
                           customClassNames = {},
                           ...rest
                         }) => (
  <label
    className={classnames(styles.inputContainer, customClassNames.container)}
  >
    <input
      className={classnames(styles.input, customClassNames.input, {
        [customClassNames.error]: meta.error && meta.touched,
      })}
      type={'radio'}
      {...field}
      {...rest}
      value={value}
      checked={field.value === value}
    />
    <div className={classnames(styles.outerCircle, {
      [customClassNames.error]: meta.error && meta.touched,
    })}>
      <div className={styles.innerCircle}/>
    </div>
    <div
      className={classnames(styles.labelContainer, customClassNames.label)}>
      {label}
    </div>
  </label>
);

export const RadioButton = ({
                              field,
                              meta = {},
                              value,
                              label,
                              customClassNames = {},
                              icon,
                              iconPosition,
                              disabled,
                              ...rest
                            }) => (
  <label
    className={classnames(styles.inputContainer, styles.button, customClassNames.container, {
      [styles.checked]: field.value === value,
      [styles.inRow]: iconPosition === 'right',
      [styles.disabled]: disabled && field.value !== value,
      [customClassNames.error]: meta.error && meta.touched,
    })}
  >
    <input
      className={classnames(styles.input, customClassNames.input, {
        [styles.errorBorder]: meta.error && meta.touched,
        [customClassNames.error]: meta.error && meta.touched,
      })}
      type={'radio'}
      {...field}
      {...rest}
      value={value}
      checked={field.value === value}
      disabled={disabled}
    />
    {icon}
    <div
      className={classnames(styles.labelContainer, customClassNames.label)}>
      {label}
    </div>
  </label>
);

const FieldRadiobutton = ({
                            fieldId,
                            value,
                            variant = 'button',
                            onChange,
                            customContainerStyles,
                            ...rest
                          }) => {
  const handleChange = ({form, field}) => e => {
    const value = e.target.value;
    if (field.value === value) return;

    if (onChange) {
      onChange({fieldId, value});
    }
    form.setFieldValue(field.name, value);
    form.setFieldTouched(fieldId, true);
  };
  const renderRadiobutton = fieldProps => {
    const props = {
      ...fieldProps,
      ...rest,
      value,
      onClick: handleChange(fieldProps)
    };
    switch (variant) {
      case 'dot':
        return (
          <RadioDot {...props}/>
        );
      case 'button':
      default:
        return (
          <RadioButton {...props}/>
        );
    }
  };

  return (
    <div className={customContainerStyles}>
      <Field
        id={fieldId}
        name={fieldId}
        value={value}
      >
        {(fieldProps) => renderRadiobutton(fieldProps)}
      </Field>
    </div>
  );
};

FieldRadiobutton.propTypes = {
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.node,
  customClassNames: PropTypes.shape({
    container: PropTypes.string,
    input: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
  }),
  icon: PropTypes.node,
  variant: PropTypes.oneOf(['dot', 'button']),
  iconPosition: PropTypes.oneOf(['top', 'right']),
  customContainerStyles: PropTypes.string,
};

export default FieldRadiobutton;

import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import {FormattedMessage} from 'react-intl';

const UserInfoPanel = ({user}) => (
  <div className="pl-7 pr-7">
    <h2 className="text-light">{user.username || "Admin"}</h2>
    <NavLink to="/logout" className="nav-link btn text-dark d-inline-block p-1">
      <FormattedMessage
        id="userInfo-btn-logout"
        defaultMessage="Logout"
      />
    </NavLink>
  </div>
);

UserInfoPanel.propTypes = {
  user: PropTypes.object,
};

export default UserInfoPanel;

import React from 'react';
import {FormikProvider} from "formik";
import CommonButton from "../../../_common/components/CommonButton";
import {StyledFieldInput} from "../../../_common/components/FieldInput";
import FieldDatepicker from "../../../_common/components/FieldDatepicker";
import FieldCheckbox, {InputCheckbox} from "../../../_common/components/FieldCheckbox";
import FieldFindCustomers from "../../../_common/components/FieldFindCustomers";
import PopupAddCustomer from "../../../CustomersEntity/components/PopupAddCustomer";
import OrderStatus from "../../../_common/components/OrderStatus";
import {StyledFieldSelect} from "../../../_common/components/FieldSelect";
import {integerValue, integerValueCanStartFromNull} from "../../../_common/utils/changeInputValue";
import {useOrderDetailsUIGlobalContext} from '../OrderDetailsUIGlobalContext';
import {useOrderDetailsUIOrderContext} from '../OrderDetailsUIOrderContext';
import {useOrderDetailsUIInspectionsListContext} from "../OrderDetailsUIInspectionsListContext";
import {filterOptions} from '../../../_common/utils/filterOptions';

const TabOrderGeneral = () => {
  const {
    orderID,
    textContent,
    users,
  } = useOrderDetailsUIGlobalContext();
  const {
    formikOrderGeneral,
    handleCreateOrder,
    oldInspectionsForms,
    toggleOldInspectionForms,
    orderStatus
  } = useOrderDetailsUIOrderContext();
  const {formikOrderInspections} = useOrderDetailsUIInspectionsListContext();
  const oldInspectionsDisabled = (orderID !== -1)
    && (formikOrderInspections?.values.inspections.filter(item => item.shumaID > 0).length > 0);

  const {
    values,
    setFieldValue,
    handleChange,
    isValid,
    dirty
  } = formikOrderGeneral;
  const {
    fieldLabelLawyerName,
    subHeaderContactDetails,
    fieldLabelFullName,
    fieldLabelContactPhone,
    fieldLabelContactJob,
    fieldLabelContactOrganisation,
    fieldLabelContactEmail,
    fieldLabelContactAddress,
    subHeaderGeneral,
    fieldShumaForOrderCount,
    fieldDueDate,
    fieldResponsibleUser,
    placeholderSelect,
    buttonLabelCreateOrder,
    buttonLabelEditOrder,
    noOptionsMessage,
    fieldLabelUsingLawyer,
    fieldLabelOldInspections
  } = textContent;

  const commonFieldProps = {
    customLabelStyles: 'pl-6',
    customContainerStyles: 'py-3 pr-4 w-25',
  };
  const commonFieldInputProps = {
    customLabelStyles: 'pl-6',
    customContainerStyles: 'py-3 pr-4 w-25',
    onChange: handleChange
  }

  return (
    <FormikProvider value={formikOrderGeneral}>
      <div className={'px-4 w-100 position-relative'}>
        <OrderStatus
          variant={'badge'}
          size={'lg'}
          statusID={orderStatus}
          customClassNames={'position-absolute my-2 mx-4 top-0 right-0'}
        />
        <div className={'d-flex'}>
          <FieldFindCustomers
            name={'customers'}
            formik={formikOrderGeneral}
            customContainerStyles={'w-25 r-4'}
            customItemStyles={'d-flex align-items-center'}
          />
          <PopupAddCustomer
            formikProps={formikOrderGeneral}
            customButtonClassNames={'mt-7 ml-4'}
          />
        </div>
        <FieldCheckbox
          fieldId="usingLawyer"
          label={fieldLabelUsingLawyer}
          value={values.usingLawyer}
          customContainerStyles={'py-3 w-150px'}
        />
        {values.usingLawyer &&
        <StyledFieldInput
          fieldId={'lawyerName'}
          label={fieldLabelLawyerName}
          value={values.lawyerName}
          {...commonFieldInputProps}
        />
        }
        <h4 className={'font-weight-bolder d-inline-block mt-3 mb-0 border-warning border-bottom'}>
          {subHeaderContactDetails}
        </h4>
        <div className={'d-flex justify-content-between'}>
          <StyledFieldInput
            fieldId={'contactFullName'}
            label={fieldLabelFullName}
            value={values.contactFullName}
            {...commonFieldInputProps}
          />
          <StyledFieldInput
            fieldId={'contactPhone'}
            label={fieldLabelContactPhone}
            value={values.contactPhone}
            onChange={e => setFieldValue('contactPhone', integerValueCanStartFromNull(e))}
            {...commonFieldProps}
          />
          <StyledFieldInput
            fieldId={'contactJob'}
            label={fieldLabelContactJob}
            value={values.contactJob}
            {...commonFieldInputProps}
          />
          <StyledFieldInput
            fieldId={'contactOrganisation'}
            label={fieldLabelContactOrganisation}
            value={values.contactOrganisation}
            {...commonFieldInputProps}
          />
        </div>
        <div className={'d-flex'}>
          <StyledFieldInput
            fieldId={'contactEmail'}
            type={'email'}
            label={fieldLabelContactEmail}
            value={values.contactEmail}
            {...commonFieldInputProps}
          />
          <StyledFieldInput
            fieldId={'contactAddress'}
            label={fieldLabelContactAddress}
            value={values.contactAddress}
            {...commonFieldInputProps}
          />
        </div>
        <h4 className={'font-weight-bolder d-inline-block mt-3 mb-0 border-warning border-bottom'}>
          {subHeaderGeneral}
        </h4>
        <div className={'d-flex'}>
          <StyledFieldInput
            fieldId={'shumaForOrderCount'}
            label={
              <>
                <span className={'text-danger'}>*</span>
                {fieldShumaForOrderCount}
              </>
            }
            value={values.shumaForOrderCount}
            onChange={e => setFieldValue('shumaForOrderCount', integerValue(e))}
            {...commonFieldProps}
          />
          <FieldDatepicker
            fieldId={'dueDate'}
            label={fieldDueDate}
            onChange={(date) => {
              setFieldValue('dueDate', new Date(date))
            }}
            {...commonFieldProps}
          />
          <StyledFieldSelect
            name={'responsibleUser'}
            options={users}
            value={values.responsibleUser}
            customContainerStyles={'py-3 mr-4 w-25'}
            onChange={({value}) => {
              setFieldValue('responsibleUser', filterOptions(value)(users)[0])
            }}
            placeholder={placeholderSelect}
            isSearchable={true}
            isAsync={true}
            noOptionsMessage={() => noOptionsMessage}
            label={
              <div className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                {fieldResponsibleUser}
              </div>
            }
          />
        </div>
        <InputCheckbox
          label={fieldLabelOldInspections}
          value={oldInspectionsForms}
          onChange={toggleOldInspectionForms}
          customContainerStyles={'py-3 w-250px'}
          disabled={oldInspectionsDisabled}
        />
        <div className={'mt-10 mb-6 d-flex justify-content-end'}>
          <CommonButton
            label={(
              orderID === -1
                ? buttonLabelCreateOrder
                : buttonLabelEditOrder
            )}
            customButtonClassNames={'w-250px btn btn-warning font-weight-bold text-dark text-nowrap font-size-lg'}
            onClick={() => handleCreateOrder({...values, orderID})}
            disabled={!(isValid && dirty)}
          />
        </div>
      </div>
    </FormikProvider>
  );
};

export default TabOrderGeneral;

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {joinAirDirections} from '../../../utils/texts';
import styles from './styles.module.scss';

const Section6Text74 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    houseAirDirectionNorth,
    houseAirDirectionSouth,
    houseAirDirectionEast,
    houseAirDirectionWest,
    houseAirDirectionNorthEast,
    houseAirDirectionNorthWest,
    houseAirDirectionSouthEast,
    houseAirDirectionSouthWest,
  } = context.data.pdfData;
  const houseAirDirectionSelected = (
    houseAirDirectionNorth ||
    houseAirDirectionSouth ||
    houseAirDirectionEast ||
    houseAirDirectionWest ||
    houseAirDirectionNorthEast ||
    houseAirDirectionNorthWest ||
    houseAirDirectionSouthEast ||
    houseAirDirectionSouthWest
  );

  return (
    <div ref={ref}>
      {Boolean(houseAirDirectionSelected) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          <span className={styles.bold}>כיווני אויר: </span>
          {joinAirDirections({
            houseAirDirectionNorth,
            houseAirDirectionSouth,
            houseAirDirectionEast,
            houseAirDirectionWest,
            houseAirDirectionNorthEast,
            houseAirDirectionNorthWest,
            houseAirDirectionSouthEast,
            houseAirDirectionSouthWest,
          })}.
        </div>
      )}
    </div>
  );
});

export default Section6Text74;

import moment from 'moment';

export const formattingPrice = (value, showSign = false) => {
  const sign = (showSign) ? ` ₪` : '';
  return Boolean(value) ? `${value.toLocaleString('he')}${sign}` : '';
};

export const formattingDate = (dateStr, format = 'DD/MM/YYYY') => {
  return moment(dateStr).format(format);
};

export const formattingPercents = value => {
  if (Boolean(value)) {
    const valueStr = value.toFixed(2);
    return `${parseFloat(valueStr)}%`;
  } else {
    return '';
  }
};

export const divideThousands = value => {
  if (value) {
    let parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  }
};

export const divideThousandsInteger = value => {
  if (value) {
    let parts = value.toString().replace(/[^\d]/, '').split('.');
    parts[0] = parts[0].startsWith('0')
      ? ''
      : parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  }
};

export const divideThousandsIntegerValue = value => {
  if (value) {
    value = isNaN(value) ? '' : parseInt(value);
    let parts = value.toString().replace(/[^\d]/, '').split(',');
    parts[0] = parts[0].startsWith('0')
      ? ''
      : parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  }
};

export const formatStringToNumber = e => {
  const stringValue = (e.target.value).replace(/[\s,%]/g, '');

  return stringValue ? stringValue : '';
};

export const removeTrailingZeroes = str => {
  return str.replace(/^0*/, '');
};

import React, {createContext, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import * as _ from 'lodash';

import {versions as V} from '../../constants/versions';
import {Counter} from '../../utils/counter';
import {findFileByTypeId} from '../../utils/files';
import getValidLocalPlansDetailsSection from '../PagesStack/content/ValidLocalPlansDetailsSection';
import getCountryStateDataSection from '../PagesStack/content/CountryStateDataSection';
import getPlansInProgressSection from '../PagesStack/content/PlansInProgressSection';
import {getCompleteCert, getDangerousStructure, getForm4} from '../PagesStack/content/Page19AttachedFiles';
import {landFileTypeIDs} from '../../constants/landLabels';
import {
  fileTypeIdPurchase,
  fileTypeIdRent,
  fileTypeIdReport,
  fileTypeIdTama
} from '../PagesStack/content/ShumaType2and3FilesList';
import {fileTypeId} from '../../constants/fileTypeId';

const defaultValue = {
  data: null,
  setData: () => {
  },
  files: null,
  setFiles: () => {
  },
  accessToken: 'testToken',
  shumaID: 2484,
  version: V.DRAFT,
};

const defaultNoteNumbers = {
  registeredArea: 0, //Boolean(registeredArea)
  surveyArea: 0, //Boolean(surveyArea)
  landShapeValueText: 0, //shuma type 1, 2
  buildingPhysicalStateValue: 0,
  buildPermitNotSigned: 0, //Boolean(buildPermitNotSigned)
  permitAndExistingAreaComparisonText: 0, //Boolean(houseExistingArea)
  housePhysicalStateValue: 0,
  section7: 0,
  estimateText: 0,
  propertyBuildCostEstimate: 0, //shuma type 2 only
  priceDelta: 0, //shuma type 2 only
  bettermentTax: 0, //shuma type 2 only Boolean(bettermentTax)
  ramiCost: 0, //shuma type 2 only
  realizeCost: 0, //shuma type 2 only
};

const orderedNotesKeys = [ //instead of object, an array always keeps his order
  'registeredArea',
  'surveyArea',
  'landShapeValueText',
  'buildingPhysicalStateValue',
  'buildPermitNotSigned',
  'permitAndExistingAreaComparisonText',
  'housePhysicalStateValue',
  'section7',
  'estimateText',
  'propertyBuildCostEstimate',
  'priceDelta',
  'bettermentTax',
  'ramiCost',
  'realizeCost',
];

const calculateNoteNumbers = ({data, shumaTypeID}) => {
  const counter = new Counter(0);
  const fileTypeIDSection7 = 14;
  const {
    surveyArea,
    buildPermitNotSigned,
    houseExistingArea,
    bettermentTax,
    supervisionFile,// section 7
    exceedUsePermit,
    exceedBuilding,
    housePermitMismatchText,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    buildPermitReason,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
  } = data.pdfData;
  const fileSection7 = findFileByTypeId(data.files, fileTypeIDSection7);
  const someSection7TextsExist = _.some([
    supervisionFile,
    exceedUsePermit === 1,
    exceedBuilding === 1,
    housePermitMismatchText,
    fileSection7,
    getValidLocalPlansDetailsSection({data}).length > 0,
    getCountryStateDataSection({data}).length > 0,
    getPlansInProgressSection({data}).length > 0,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    buildPermitReason,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
    getForm4({data}).length > 0,
    getCompleteCert({data}).length > 0,
    getDangerousStructure({data}).length > 0,
  ]);

  return orderedNotesKeys.reduce((numbers, key) => {
    switch (key) {
      case 'surveyArea':
        return Boolean(surveyArea)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'landShapeValueText':
        return (shumaTypeID === 1 || shumaTypeID === 2)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'buildPermitNotSigned':
        return (buildPermitNotSigned === 1)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'permitAndExistingAreaComparisonText':
        return Boolean(houseExistingArea)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'section7':
        return (someSection7TextsExist)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'propertyBuildCostEstimate':
      case 'priceDelta':
        return (shumaTypeID === 2 || shumaTypeID === 3 || shumaTypeID === 4)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'ramiCost':
        return (shumaTypeID === 2)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'bettermentTax':
        return (shumaTypeID === 2) && Boolean(bettermentTax)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      default:
        return {...numbers, [key]: counter.nextNumber()};
    }
  }, {});
};

const orderedSectionKeys = [
  'section1',
  'section2',
  'section3',
  'section4',
  'section5',
  'section6',
  'section7',
  'section8',
  'section9',
  'section10',
];

const orderedSectionKeysShumaType3 = [
  'section1',
  'section2',
  'section3',
  'section4',
  'section5',
  'section6',
  'section7',
  'section8',
  'section9',
  'section10',
  'section11',
  'section12',
  'section13',
  'section14',
  'section15',
];

const defaultSectionNumbers = orderedSectionKeys.reduce((numbers, key, currentIndex) => {
  return {...numbers, [key]: currentIndex + 1};
}, {});

const defaultSectionNumbersShumaType3 = orderedSectionKeysShumaType3.reduce((numbers, key, currentIndex) => {
  return {...numbers, [key]: currentIndex + 1};
}, {});

const calculateSectionNumbers = ({data, shumaTypeID}) => {
  const counter = new Counter(0);
  const fileTypeIDSection7 = 14;
  const {
    supervisionFile,// section 7
    exceedUsePermit,
    exceedBuilding,
    housePermitMismatchText,
    propertyLocation, // section 10
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    buildPermitReason,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
  } = data.pdfData;
  const fileSection7 = findFileByTypeId(data.files, fileTypeIDSection7);
  const someSection7TextsExist = _.some([
    supervisionFile,
    exceedUsePermit === 1,
    exceedBuilding === 1,
    housePermitMismatchText,
    fileSection7,
    getValidLocalPlansDetailsSection({data}).length > 0,
    getCountryStateDataSection({data}).length > 0,
    getPlansInProgressSection({data}).length > 0,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    buildPermitReason,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
    getForm4({data}).length > 0,
    getCompleteCert({data}).length > 0,
    getDangerousStructure({data}).length > 0,
  ]);

  const someSection9ValuesExist = _.some([
    propertyLocation,
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other
  ], Boolean);

  return orderedSectionKeys.reduce((numbers, key) => {
    switch (key) {
      case 'section7':
        return Boolean(fileSection7) || someSection7TextsExist
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'section8':
        return displaySection8(shumaTypeID, data)
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'section9':
        if (shumaTypeID === 3) {
          return someSection9ValuesExist
            ? {...numbers, [key]: counter.nextNumber()}
            : numbers;
        } else {
          return {...numbers, [key]: counter.nextNumber()};
        }
      default:
        return {...numbers, [key]: counter.nextNumber()};
    }
  }, {});
};

const calculateSectionNumbersShumaType3 = ({data}) => {
  const counter = new Counter(0);
  const {
    validLocalPlansDetails,
    countryStateData,
    plansInProgress,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    additionalBuildPermitText,
    supervisionFile,
    exceedUsePermit,
    exceedBuilding,
    housePermitMismatchText,
    form4,
    form4Text,
    completeCert,
    completeCertText,
    dangerousStructure,
    dangerousStructureText,
    taxAndCostDesc,
    propertyLocation,
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other,
    mortgageEntityEmphasis
  } = data.pdfData;
  const someSection10TextsExist = _.some([
    findFileByTypeId(data.files, fileTypeId.PLANS_IN_EFFECT),
    validLocalPlansDetails,
    countryStateData,
    plansInProgress,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    additionalBuildPermitText,
    supervisionFile,
    exceedUsePermit,
    exceedBuilding,
    housePermitMismatchText,
    form4,
    form4Text,
    completeCert,
    completeCertText,
    dangerousStructure,
    dangerousStructureText,
    findFileByTypeId(data.files, fileTypeId.PERMIT_BLUEPRINT)
  ]);
  const someSection12TextsExist = Boolean(taxAndCostDesc);
  const someHeader13TextsExist = _.some([
    propertyLocation,
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other,
    mortgageEntityEmphasis
  ]);

  return orderedSectionKeysShumaType3.reduce((numbers, key) => {
    switch (key) {
      case 'section10':
        return someSection10TextsExist
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'section12':
        return someSection12TextsExist
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      case 'section13':
        return someHeader13TextsExist
          ? {...numbers, [key]: counter.nextNumber()}
          : numbers;
      default:
        return {...numbers, [key]: counter.nextNumber()};
    }
  }, {});
};

const displaySection8 = (shumaTypeID, data) => {
  if (Boolean(shumaTypeID) && Boolean(data)) {
    const {
      propertyRightsDetails,
      displayNoNesachText,
      landShituf,
      warningNote,
    } = data.pdfData;
    const propertyRightsDetailsExists = Boolean(propertyRightsDetails);
    const fileType1Exists = data.files.filter(file => file.file_type_id === 1).length > 0;
    const fileOrTextExist = fileType1Exists || !displayNoNesachText;
    const step5MediaFilesExist = Object.keys(landFileTypeIDs).map(fileId => {
      return findFileByTypeId(data.files, landFileTypeIDs[fileId]);
    }).filter(Boolean).length > 0;
    const notesExist = (shumaTypeID === 1) && (!Boolean(landShituf) || Boolean(warningNote));
    const anyStep12FileExist = _.some([
      Boolean(findFileByTypeId(data.files, fileTypeIdTama)),
      Boolean(findFileByTypeId(data.files, fileTypeIdPurchase)),
      Boolean(findFileByTypeId(data.files, fileTypeIdRent)),
      Boolean(findFileByTypeId(data.files, fileTypeIdReport))
    ]);
    const filesListExist = ((shumaTypeID === 2) || (shumaTypeID === 3))
      && anyStep12FileExist;

    return _.some([
      propertyRightsDetailsExists,
      fileOrTextExist,
      step5MediaFilesExist,
      notesExist,
      filesListExist
    ]);
  } else {
    return false;
  }
};

export const CompleteInspectionContext = createContext(defaultValue);
CompleteInspectionContext.displayName = 'CompleteInspection';

export const useCompleteInspectionContext = () => useContext(CompleteInspectionContext);

export const CompleteInspectionContextProvider = ({children}) => {
  const [data, setData] = useState(null);
  const [files, setFiles] = useState(null);
  const [shumaTypeID, setShumaTypeID] = useState(null);
  const [noteNumbers, setNoteNumbers] = useState(defaultNoteNumbers);
  const [sectionNumbers, setSectionNumbers] = useState(defaultSectionNumbers);
  const [sectionNumbersShumaType3, setSectionNumbersShumaType3] = useState(defaultSectionNumbersShumaType3);

  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const accessToken = qs.get('accessToken');
  const shumaID = qs.get('shumaID');
  const version = qs.get('version');

  useEffect(() => {
    if (Boolean(data) && Boolean(shumaTypeID)) {
      const updatedNoteNumbers = calculateNoteNumbers({data, shumaTypeID});
      setNoteNumbers(updatedNoteNumbers);

      const updatedSectionNumbers = calculateSectionNumbers({
        data,
        shumaTypeID
      });
      setSectionNumbers(updatedSectionNumbers);

      const updatedSectionNumbersShumaType3 = calculateSectionNumbersShumaType3({data});
      setSectionNumbersShumaType3(updatedSectionNumbersShumaType3);
    }
  }, [data, shumaTypeID]);

  const context = {
    data,
    setData,
    files,
    setFiles,
    shumaTypeID,
    setShumaTypeID,
    accessToken,
    shumaID,
    version,
    noteNumbers,
    sectionNumbers,
    sectionNumbersShumaType3,
    display: {
      section8: displaySection8(shumaTypeID, data)
    },
  };

  return (
    <CompleteInspectionContext.Provider value={context}>
      {children}
    </CompleteInspectionContext.Provider>
  );
};

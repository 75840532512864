import React from 'react';
import classnames from 'classnames';
import Section from '../../Section';
import DocProps from '../../DocProps';
import NoteNumber from '../../NoteNumber';
import {formattingDate, formattingPercents} from '../../../utils/formattingHelper';
import styles from './styles.module.scss';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formatPriceOrReturnPlaceholder, getGushHelkaTTHelkaRTLString} from '../../../utils/texts';

const ShumaType2LastPage = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        gush, helka, ttHelka,
        streetName, houseNumber, cityName,
        officialDate,
        bankName,
        finalMarketPrice,
        quickRealizeCost,
        bettermentTax,
        improvementLevy,
        ramiCost,
        realizeCost,
        quickRealizeDeduction,
      },
      pdfDataCustomers
    },
    noteNumbers,
  } = useCompleteInspectionContext();

  const address = `${streetName} ${houseNumber}, ${cityName}`;
  const code = getGushHelkaTTHelkaRTLString({gush, helka, ttHelka});
  const customersNames = (pdfDataCustomers.length > 0)
    ? pdfDataCustomers.map(customer => customer.name).join(' ו - ')
    : '';
  const delta = quickRealizeCost - bettermentTax - improvementLevy - ramiCost - realizeCost;

  return (
    <div ref={ref}>
      <div className={styles.page}>
        <Section>
          <DocProps/>
          <div className={styles.title}>
            נספח בטחונות
          </div>
          <div className={styles.listItem}>
            <div className={classnames(styles.itemText, styles.bold)}>
              <span>הנדון: אומדן שווי זכויות בדירה ברח'</span>
              {` ${address} `}
              <span>גו"ח</span>
              {` ${code}:`}
            </div>
            <div className={styles.itemText}>
              <span>בהמשך להערכתי מיום</span>
              {` ${Boolean(officialDate) ? formattingDate(officialDate) : ''} `}
              <span>של הנכס הנדון, עבור</span>
              <span data-testid="bankName">{` ${bankName} `}</span>
              <span>בע"מ, לטובת </span>
              {` ${customersNames},`}
            </div>
            <div className={styles.itemText}>
              להלן אומדן ההפחתות והוצאות המימוש הצפויות בעת המכירה ופירוט השווי
              לבטחונות:
            </div>
          </div>

          <div className={classnames(styles.listItem)}>
            <table
              className={classnames(styles.simpleTable, styles.w100, styles.longTable)}
            >
              <thead className={classnames(styles.greenBg, styles.bold)}>
              <tr>
                <th className={styles.alignRight}>שווי בש"ח</th>
                <th className={styles.w30}>רכיב</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>שווי שוק הנכס</td>
                <td>{formatPriceOrReturnPlaceholder(finalMarketPrice, true)}</td>
              </tr>
              <tr>
                <td>
                  <span>הפחתה למימוש מהיר</span>
                  {` ${formattingPercents(quickRealizeDeduction)}`}
                </td>
                <td>{formatPriceOrReturnPlaceholder(quickRealizeCost, true)}</td>
              </tr>
              {Boolean(bettermentTax) && (
                <tr>
                  <td>מס שבח
                    {!Boolean(bettermentTax) && (
                      <NoteNumber
                        id={'bettermentTax'}>{noteNumbers.bettermentTax}</NoteNumber>
                    )}
                  </td>
                  <td>{formatPriceOrReturnPlaceholder(bettermentTax, true)}</td>
                </tr>
              )}
              {Boolean(improvementLevy) && (
                <tr>
                  <td>היטל השבחה</td>
                  <td>{formatPriceOrReturnPlaceholder(improvementLevy, true)}</td>
                </tr>
              )}
              {Boolean(ramiCost) && (
                <tr>
                  <td>דמי היתר לרשות מקרקעי ישראל
                    <NoteNumber
                      id={'ramiCost'}>{noteNumbers.ramiCost}</NoteNumber>
                  </td>
                  <td>{formatPriceOrReturnPlaceholder(ramiCost, true)}</td>
                </tr>
              )}
              {Boolean(realizeCost) && (
                <tr>
                  <td>הוצאות שיווק ועו"ד
                    <NoteNumber
                      id={'realizeCost'}>{noteNumbers.realizeCost}</NoteNumber>
                  </td>
                  <td>{formatPriceOrReturnPlaceholder(realizeCost, true)}</td>
                </tr>
              )}
              <tr>
                <td className={styles.bold}>
                  סה"כ יתרה לאחר הפחתות (במעוגל)
                </td>
                <td>{formatPriceOrReturnPlaceholder(delta, true)}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </Section>
      </div>
    </div>
  );
});

export default ShumaType2LastPage;

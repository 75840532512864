const defaultState = {
  listLoading: false,
  pageCount: 0,
  currentPageNumber: 0,
  entities: null,
  totalResult: 0,
  error: null,
};
export const actionTypes = {
  inspectionsFetched: 'INSPECTIONS_FETCHED',
  inspectionsAppended: 'INSPECTIONS_APPENDED',
  approveShuma: 'APPROVE_SHUMA',
  rejectShuma: 'REJECT_SHUMA',
  copyShuma: 'COPY_SHUMA',
  catchError: 'CATCH_ERROR'
};

const getIndex = (array, shumaID) => {
  const mappedArray = array.map(item => item.shumaID);
  return mappedArray.indexOf(shumaID);
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.inspectionsFetched: {
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: inspections,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.inspectionsAppended: {
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...inspections]
          : [...inspections],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.approveShuma: {
      const {shumaStatusTypeID, shumaStatusTypeName, shumaID} = action.payload;
      const index = getIndex(state.entities, shumaID);
      state.entities[index].shumaStatusTypeName = shumaStatusTypeName;
      state.entities[index].shumaStatusTypeID = shumaStatusTypeID;
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.rejectShuma: {
      const {shumaStatusTypeID, shumaStatusTypeName, shumaID} = action.payload;
      const index = getIndex(state.entities, shumaID);
      state.entities[index].shumaStatusTypeName = shumaStatusTypeName;
      state.entities[index].shumaStatusTypeID = shumaStatusTypeID;
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.copyShuma: {
      const {
        cityName,
        createTime,
        customers,
        estimatePriceFinal,
        finalPDFFileID,
        firmShumaID,
        gush,
        helka,
        houseNumber,
        originTypeID,
        originTypeName,
        propertyTypeID,
        propertyTypeName,
        shumaID,
        shumaReasonID,
        shumaReasonName,
        shumaStatusTypeID,
        shumaStatusTypeName,
        shumaTypeID,
        shumaTypeName,
        streetName,
        userFirstName,
        userID,
        userLastName
      } = action.payload;
      state.entities.unshift({});
      state.entities[0] = {
        cityName: cityName || '',
        createTime: createTime || '',
        customers: customers || '',
        estimatePriceFinal: estimatePriceFinal || '',
        finalPDFFileID: finalPDFFileID || '',
        firmShumaID: firmShumaID || '',
        gush: gush || '',
        helka: helka || '',
        houseNumber: houseNumber || '',
        originTypeID: originTypeID || '',
        originTypeName: originTypeName || '',
        propertyTypeID: propertyTypeID || '',
        propertyTypeName: propertyTypeName || '',
        shumaID: shumaID || '',
        shumaReasonID: shumaReasonID || '',
        shumaReasonName: shumaReasonName || '',
        shumaStatusTypeID: shumaStatusTypeID || '',
        shumaStatusTypeName: shumaStatusTypeName || '',
        shumaTypeID: shumaTypeID || '',
        shumaTypeName: shumaTypeName || '',
        streetName: streetName || '',
        userFirstName: userFirstName || '',
        userID: userID || '',
        userLastName: userLastName || ''
      };
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.catchError: {
      const error = `INSPECTIONS: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
};

export const actions = {
  inspectionsFetched: (data) => ({
    type: actionTypes.inspectionsFetched,
    payload: data
  }),
  inspectionsAppended: (data) => ({
    type: actionTypes.inspectionsAppended,
    payload: data
  }),
  approveShuma: (data) => ({
    type: actionTypes.approveShuma,
    payload: data
  }),
  rejectShuma: (data) => ({
    type: actionTypes.rejectShuma,
    payload: data
  }),
  copyShuma: (data) => ({
    type: actionTypes.copyShuma,
    payload: data
  }),
  catchError: (error) => ({type: actionTypes.catchError, payload: {error}}),
};

import React from 'react';

export function TypeAndOriginFormatter(shumaType, shumaOrigin) {
  return (
    <div className="font-weight-bold">
      {shumaType && (
        <div>{shumaType}</div>
      )}
      {shumaOrigin && (
        <div>{shumaOrigin}</div>
      )}
    </div>
  );
}

import React from 'react';
import classNames from 'classnames';
import Tabs from '../../../_common/components/Tabs';
import Spinner from '../../../_common/components/Spinner';
import TabGeneralDetails from '../TabGeneralDetails';
import TabInspectionsByCustomer from '../TabInspectionsByCustomer';
import TabDocuments from '../TabDocuments';
import TabCustomerFiles from "../TabCustomerFiles";
import {useCustomerDetailsUIContext} from '../CustomerDetailsUIContext';
import styles from './styles.module.scss';
import {shallowEqual, useSelector} from 'react-redux';


export function SectionsContainer() {
  const {loading, generalDetails} = useCustomerDetailsUIContext();

  const {
    greenInvoiceApiKey,
    greenInvoiceSecret
  } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const isTabDocumentsDisabled = () => {
    return !(Boolean(generalDetails.greenInvoiceClientID)
      && Boolean(greenInvoiceSecret)
      && Boolean(greenInvoiceApiKey));
  };

  return (loading)
    ? (
      <div
        className="w-100 h-100 d-flex justify-content-center align-items-center"
      >
        <Spinner/>
      </div>
    )
    : (
      <div
        className={classNames('d-flex flex-column', styles.wrapper)}
      >
        <Tabs items={[
          {
            key: 'general',
            title: 'כללי',
            icon: <i className="flaticon2-user"/>,
            content: <TabGeneralDetails/>,
          },
          {
            key: 'inspections',
            title: 'דוחות',
            icon: <i className="flaticon2-grids"/>,
            content: <TabInspectionsByCustomer/>,
          },
          {
            key: 'payments',
            title: 'תשלומים',
            icon: <i className="flaticon2-list"/>,
            content: <TabDocuments/>,
            disabled: isTabDocumentsDisabled()
          },
          {
            key: 'documents',
            title: 'מסמכים כלליים',
            icon: <i className="flaticon2-checking"/>,
            content: <TabCustomerFiles/>,
          },
        ]}
        />
      </div>
    );
}

export default SectionsContainer;

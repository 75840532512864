import React from 'react';
import {useHeader} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import SubheaderBar from '../../_common/components/SubheaderBar';
import PriceQuotesPageFilters from '../components/PriceQuotesPageFilters';
import PriceQuotesPageTable from '../components/PriceQuotesPageTable';
import {PriceQuotesPageUIProvider} from '../components/PriceQuotesPageUIContext';

const PriceQuotesPage = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'price-quotes-page-header',
    defaultMessage: 'Price quotes'
  }));

  return (
    <PriceQuotesPageUIProvider>
      <SubheaderBar>
        <PriceQuotesPageFilters/>
      </SubheaderBar>
      <PriceQuotesPageTable/>
    </PriceQuotesPageUIProvider>
  );
};

export default PriceQuotesPage;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

const GreenBox = React.forwardRef(({
                                     children,
                                     textClassName,
                                     containerClassName
                                   }, ref) => {
  return (
    <div
      className={classnames(styles.greenBlockContainer, containerClassName)}
      ref={ref}
    >
      <div className={styles.whiteBorder}>
        <div className={`${styles.greenBlock} ${textClassName}`}>
          {children}
        </div>
      </div>
    </div>
  );
});

GreenBox.propTypes = {
  children: PropTypes.node,
  textClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default GreenBox;

import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {Button} from "react-bootstrap";
import {useIntl} from 'react-intl';
import CommonButton from "../../../_common/components/CommonButton";
import {tooltips as TTS} from "../../../_common/constants/tooltips";
import {
  subjects as S,
  permissionTypes as T
} from '../../../_common/constants/permissionSubjects';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {can} from '../../../_common/components/Permissions';
import {useDispatch} from "react-redux";
import {deletePriceQuote} from "../../_redux/actions/PriceQuotesPageTableActions";

const PopupDeletePriceQuote = ({data}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const intl = useIntl();
  const messageDelete = intl.formatMessage({
    id: 'price-quotes-page-popup-message-delete',
    defaultMessage: 'Delete price quote?',
  });
  const buttonLabelYes = intl.formatMessage({
    id: 'yes',
    defaultMessage: 'Yes',
  });
  const buttonLabelNo = intl.formatMessage({
    id: 'no',
    defaultMessage: 'No',
  });
  const {priceQuoteID} = data;
  const handleDeletePriceQuote = () => {
    dispatch(deletePriceQuote(priceQuoteID));
    handleCloseModal();
  };
  const popupMessage = `${messageDelete} ${priceQuoteID}?`;

  return (
    <>
      <Button
        className={'btn btn-icon btn-warning border-0 w-38px'}
        title={TTS.DELETE_PRICE_QUOTE}
        disabled={!can(T.FULL, S.PRICE_QUOTES_PAGE)}
        onClick={handleShowModal}
      >
        <img
          src={toAbsoluteUrl('media/common/Delete.svg')}
          alt="delete-price-quote"
        />
      </Button>
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <div className={'py-4'}>
            <div className={'h2 text-center'}>
              {popupMessage}
            </div>
            <div className="w-75 mx-auto d-flex justify-content-between pt-10">
              <CommonButton
                variant={'button'}
                onClick={handleCloseModal}
                label={buttonLabelNo}
              />
              <CommonButton
                variant={'submit'}
                onClick={handleDeletePriceQuote}
                label={buttonLabelYes}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PopupDeletePriceQuote;

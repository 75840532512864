import React from 'react';
import classnames from 'classnames';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

export const getAdjacentStreets = (context) => {
  const {adjacentStreets, streetName} = context.data.pdfData;
  let adjacentStreetsValues = [];
  if (Boolean(adjacentStreets)) {
    adjacentStreetsValues = (adjacentStreets instanceof Array)
      ? adjacentStreets
      : getTextChunks(adjacentStreets)
  }

  return [
    <div
      className={classnames(styles.innerPadding, styles.itemText, styles.textRegular)}
      key={0}
    >
      {Boolean(adjacentStreets) && (
        <div className={classnames(styles.itemText, styles.bold)}>
          במקטע הרלוונטי רח' {streetName} גובל בציונים הבאים:
        </div>
      )}
    </div>,
    ...adjacentStreetsValues
      .map(mapTextChunks)
      .map((item, index, array) => (
        <div
          className={classnames(styles.itemText, styles.innerPadding, styles.textRegular, {
            [styles.listItem]: index === array.length - 1
          })}
          key={index + 1}
        >
          {item}
        </div>
      ))
  ]
};

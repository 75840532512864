export const limitValueLength = (maxLength) => (e) => {
  const value = e.target.value;
  const newValue = `${value}`.slice(0, maxLength);
  return parseInt(newValue)
};

export const limitFloatValueLength = (maxLength) => (e) => {
  const value = `${e.target.value}`.replace(/[^\.\,\d]/, '');
  return value.slice(0, maxLength);
};

export const limitIntegerValueLength = (maxLength) => (e) => {
  const value = `${e.target.value}`.replace(/[^\d]/, '');
  return value.slice(0, maxLength);
};

export const divideThousandsInteger = value => {
  if (value) {
    let parts = value.toString().replace(/[^\d]/, '').split(".");
    parts[0] = parts[0].startsWith('0')
      ? ''
      : parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  }
};

export const formatStringToNumber = e => {
  const stringValue = (e.target.value).replace(/[\s,%]/g, '');

  return stringValue ? stringValue : '';
};

export const formatStringToFloatNumber = e => {
  const value = `${e.target.value}`.replace(/[^\.\,\d]/, '');
  return value ? value : '';
};

export const integerValue = (e) => {
  const value = `${e.target.value}`.replace(/[^\d]/, '');
  return value ? parseInt(value, 10) : '';
};

export const integerValueCanStartFromNull = (e) => {
  return `${e.target.value}`.replace( /[^0-9]/, '');
};

export const limitValueRange = (maxLength, min, max) => (e) => {
  const value = `${e.target.value}`.replace(/[^0-9\.\,]/g, '');
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value.slice(0, maxLength) || '0'
  }
};

export const limitValueRangeInteger = (maxLength, min, max) => (e) => {
  const value = `${e.target.value}`.replace(/[^0-9]/g, '');
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value.slice(0, maxLength) || '0'
  }
};

export const addComaRoundDecimals = (numb) => {
  const string = numb !== null ? String(Math.round(numb)) : '';
  const value = string.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
  return value
};

export const limitDataLength = (maxLength) => (val) => {
  const value = val !== null ? `${val}`.replace(/[^\.\,\d]/, '') : '';
  return value.slice(0, maxLength);
};

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import Media from '../../Media';
import classnames from 'classnames';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import styles from '../styles.module.scss';

const fileTypeID = 10;

const GoogleViewImageWithLabel = React.forwardRef(({}, ref) => {
  const {files} = useCompleteInspectionContext();
  const file = findFileByTypeId(files, fileTypeID);
  const fileSrc = file && getFullDataURI(file);
  const mediaType = file && getMediaType(file);

  return (
    <div ref={ref}>
      {Boolean(file) && (
        <>
          <div
            className={classnames(styles.itemText, styles.bold, styles.innerPadding)}
          >
            חזית המבנה:
          </div>
          <Media
            src={fileSrc}
            file={file}
            alt="googleView"
            className={styles.customPlan}
            type={mediaType}
          />
        </>
      )}
    </div>
  );
});

export default GoogleViewImageWithLabel;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Field} from 'formik';
import styles from './styles.module.scss';

export const RadioDot = ({
                           field,
                           meta = {},
                           selected,
                           value,
                           label,
                           customClassNames = {},
                           ...rest
                         }) => (
  <label
    className={classnames(styles.inputContainer, customClassNames.container)}
  >
    <input
      className={classnames(styles.input, customClassNames.input, {
        [customClassNames.error]: meta.error && meta.touched,
      })}
      type={'radio'}
      {...field}
      {...rest}
      value={value}
      checked={selected === value}
    />
    <div className={classnames(styles.outerCircle, {
      [customClassNames.error]: meta.error && meta.touched,
    })}>
      <div className={styles.innerCircle}/>
    </div>
    <div
      className={classnames(styles.labelContainer, customClassNames.label)}>
      {label}
    </div>
  </label>
);

export const RadioButton = ({
                              field,
                              meta = {},
                              selected,
                              value,
                              label,
                              customClassNames = {},
                              icon,
                              iconPosition,
                              disabled,
                              ...rest
                            }) => (
  <label
    className={classnames(styles.inputContainer, styles.button, customClassNames.container, {
      [styles.checked]: selected === value,
      [styles.inRow]: iconPosition === 'right',
      [styles.disabled]: disabled && selected !== value,
      [customClassNames.error]: meta.error && meta.touched,
    })}
  >
    <input
      className={classnames(styles.input, customClassNames.input, {
        [styles.errorBorder]: meta.error && meta.touched,
        [customClassNames.error]: meta.error && meta.touched,
      })}
      type={'radio'}
      {...rest}
      value={value}
      checked={selected === value}
      disabled={disabled}
    />
    {icon}
    <div
      className={classnames(styles.labelContainer, customClassNames.label)}>
      {label}
    </div>
  </label>
);

const StyledFieldRadiobutton = ({
                                  fieldId,
                                  value,
                                  selected,
                                  variant = 'button',
                                  onChange,
                                  customContainerStyles,
                                  ...rest
                                }) => {
  const handleChange = e => {
    const value = e.target.value;
    if (selected !== value && onChange) {
      onChange(value);
    }
  };

  const renderRadiobutton = () => {
    const props = {
      ...rest,
      selected,
      value,
      onClick: handleChange
    };
    switch (variant) {
      case 'dot':
        return (
          <RadioDot {...props}/>
        );
      case 'button':
      default:
        return (
          <RadioButton {...props}/>
        );
    }
  };

  return (
    <div className={customContainerStyles}>
      {renderRadiobutton()}
    </div>
  );
};

StyledFieldRadiobutton.propTypes = {
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.node,
  customClassNames: PropTypes.shape({
    container: PropTypes.string,
    input: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
  }),
  icon: PropTypes.node,
  variant: PropTypes.oneOf(['dot', 'button']),
  iconPosition: PropTypes.oneOf(['top', 'right']),
  customContainerStyles: PropTypes.string,
};

export {StyledFieldRadiobutton};

import axios from "axios";

const LOGIN_URL = "/auth/login";
const LOGOUT_URL = "/auth/logout";
const REFRESH_URL = "/auth/refreshToken";
const GET_PERMISSIONS_URL = "/auth/getPermissions";

//deprecated
export function getAccessToken(loginToken) {
  return axios.post(LOGIN_URL,
    {
      loginToken
    },
    {
      headers: {'Content-Type': 'application/json'}
    });
}

export function loginWithTokens({userID, accessToken, idToken, refreshToken, expire}) {
  return axios.post(LOGIN_URL, {
    userID,
    accessToken,
    idToken,
    refreshToken,
    expireTime: expire
  })
}

export function logout() {
  return axios.post(LOGOUT_URL, {});
}

export function refreshToken() {
  return axios
    .post(REFRESH_URL, {})
}

export function getUserPermissions() {
  return axios
    .get(GET_PERMISSIONS_URL)
}


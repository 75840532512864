import React from 'react';
import {getUniqueElementsFromArray} from "../utils/getUniqueElementsFromArray";

export function SeveralItemsNoRepeatColumnFormatter(cellContent) {

  const array = getUniqueElementsFromArray(cellContent);

  const mappedCellContent = array.map(item => {
    return (
      <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
        {item}
      </div>
    )
  });

  return (
    <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
      {mappedCellContent}
    </div>
  );
}

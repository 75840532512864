import {actions} from "../reducers/InspectionsPageTableReducer";
import axios from "axios";

export const GET_INSPECTIONS_LIST = "/inspections/getList";

export const getInspectionsList = action => ({
                                               cityID = -1,
                                               streetID = -1,
                                               gush = "",
                                               helka = "",
                                               fromDate = "",
                                               toDate = "",
                                               customerName = "",
                                               shumaID = -1,
                                               shumaTypeID = -1,
                                               propertyTypeID = -1,
                                               responsibleUserID = -1,
                                               statusTypeID = -1,
                                               fromRoomCount = -1,
                                               toRoomCount = -1,
                                               fromRegisteredArea = -1,
                                               toRegisteredArea = -1,
                                               elevator = -1,
                                               fromShumaDueDate = "",
                                               toShumaDueDate = "",
                                               requestedPageNumber = 0,
                                             }) => async dispatch => {
  try {
    const data = {
      cityID: parseInt(cityID, 10),
      streetID: parseInt(streetID, 10),
      gush,
      helka,
      fromDate,
      toDate,
      customerName,
      shumaID: parseInt(shumaID, 10),
      shumaTypeID: parseInt(shumaTypeID, 10),
      responsibleUserID: parseInt(responsibleUserID, 10),
      statusTypeID: parseInt(statusTypeID, 10),
      propertyTypeID: parseInt(propertyTypeID, 10),
      fromRoomCount: parseInt(fromRoomCount, 10),
      toRoomCount: parseInt(toRoomCount, 10),
      fromRegisteredArea: parseInt(fromRegisteredArea, 10),
      toRegisteredArea: parseInt(toRegisteredArea, 10),
      elevator: parseInt(elevator, 10),
      fromShumaDueDate,
      toShumaDueDate,
      requestedPageNumber: parseInt(requestedPageNumber, 10),
    };

    const response = await axios.post(GET_INSPECTIONS_LIST, data);
    const {errorCode, body} = response.data;
    if (errorCode < 0) throw new Error();
    const {totalPageCount, currentPageNumber, inspections, totalResultCount} = body;

    dispatch(action({
      totalPageCount,
      currentPageNumber,
      inspections,
      totalResultCount,
    }));
  } catch (error) {
    dispatch(actions.catchError(error));
  }
};

export const getInspections = getInspectionsList(actions.inspectionsFetched);
export const getNextInspections = getInspectionsList(actions.inspectionsAppended);

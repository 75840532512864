import axios from 'axios';

export const CREATE_SHUMA_LINKED_TO_ORDER = '/inspections/addEditShumaLinkedToOrder';

export const addEditShumaLinkedToOrder = ({
                                            orderID,
                                            shumaID,
                                            shumaReasonID,
                                            shumaTypeID,
                                            propertyTypeID,
                                            bankID,
                                            loanTypeID,
                                            loanText,
                                            otherPropertyTypeID,
                                            bankBranchNumber,
                                            bankBranchName,
                                            bankClerkFullName,
                                            bankEmail,
                                            bankRequestID,
                                            bankBuildType,
                                            dueDate,
                                            city,
                                            street,
                                            houseNumber,
                                            apartmentNumber,
                                            gush,
                                            helka,
                                            ttHelka,
                                            notes,
                                            landLotNum,
                                            landLotNumAccordingTaba,
                                            responsibleUser,
                                            originTypeID,
                                            loanSubTypeID,
                                            shumaMazminIdentityID,
                                            underConstruction,
                                          }) => () => {
  const data = {
    orderID: parseInt(orderID, 10),
    shumaID,
    shumaReasonID: parseInt(shumaReasonID, 10),
    shumaTypeID,
    propertyTypeID: parseInt(propertyTypeID, 10),
    bankID,
    loanTypeID,
    loanText,
    otherPropertyTypeID: parseInt(otherPropertyTypeID, 10),
    bankBranchNumber: parseInt(bankBranchNumber, 10),
    bankBranchName,
    bankClerkFullName,
    bankEmail,
    bankRequestID: parseInt(bankRequestID, 10),
    bankBuildType,
    dueDate,
    city,
    street,
    houseNumber,
    apartmentNumber,
    gush,
    helka,
    ttHelka,
    notes,
    landLotNum,
    landLotNumAccordingTaba,
    responsibleUser: parseInt(responsibleUser, 10),
    originTypeID: originTypeID && parseInt(originTypeID, 10),
    loanSubTypeID: loanSubTypeID && parseInt(loanSubTypeID, 10),
    shumaMazminIdentityID: shumaMazminIdentityID && parseInt(shumaMazminIdentityID, 10),
    underConstruction,
  };

  return axios.post(CREATE_SHUMA_LINKED_TO_ORDER, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body?.shumaID;
    });
};

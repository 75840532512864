import React from 'react';
import PropTypes from 'prop-types';
import can from './can';

const ProtectedComponent = ({action, subject, children}) => {
  return can(action, subject) && children;
};

ProtectedComponent.propTypes = {
  action: PropTypes.string,
  subject: PropTypes.string,
  children: PropTypes.node
};

export default ProtectedComponent;

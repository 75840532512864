import React from 'react';
import {useIntl} from 'react-intl';
import {useHeader} from '../../../../_metronic/layout';
import {useLocation} from "react-router-dom";
import SubheaderBar from '../../_common/components/SubheaderBar';
import {
  StreetsProvider
} from '../../_common/components/CitiesAndStreets';
import {
  OldInspectionDetailsUIProvider
} from '../components/OldInspectionsDetailsUIContext';
import SectionsContainer from '../components/SectionsContainer';

const OldInspectionDetails = () => {
  const header = useHeader();
  const intl = useIntl();
  const location = useLocation();
  const editRowHeader = intl.formatMessage({
    id: 'inspection-details-page-editRow-header',
    defaultMessage: 'Edit row',
  });
  const getInspectionID = () => {
    const pathName = location.pathname;
    return pathName.replace('/old-inspection-details/', '');
  };
  const pageHeader = `${editRowHeader} - ${getInspectionID()}`;
  header.setTitle(pageHeader);

  return (
    <StreetsProvider>
      <OldInspectionDetailsUIProvider>
        <SubheaderBar/>
        <SectionsContainer/>
      </OldInspectionDetailsUIProvider>
    </StreetsProvider>
  );
};

export default OldInspectionDetails;

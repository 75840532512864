import React from 'react';
import styles from './styles.module.scss';

const ShumaType2Title = React.forwardRef(({}, ref) => (
  <div className={styles.title} ref={ref}>
    שומת מקרקעין מלאה - הערכת שווי שוק
  </div>
));

export default ShumaType2Title;

import {actions} from "../reducers/OldInspectionsPageTableReduser";
import axios from "axios";


export const GET_OLD_INSPECTIONS_LIST = "/inspections/getOldInspectionsListByFirm";

export const getOldInspectionsList = action => ({
                                               cityID = -1,
                                               streetID = -1,
                                               gush = "",
                                               helka = "",
                                               fromDate = "",
                                               toDate = "",
                                               selectedUserID = -1,
                                               requestedPageNumber = 0,
                                             }) => dispatch => {

  const data = {
    cityID: parseInt(cityID, 10),
    streetID: parseInt(streetID, 10),
    gush,
    helka,
    fromDate,
    toDate,
    selectedUserID: parseInt(selectedUserID, 10),
    requestedPageNumber: parseInt(requestedPageNumber, 10),
  };

  return axios.post(GET_OLD_INSPECTIONS_LIST, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {totalPageCount, currentPageNumber, inspections, totalResultCount} = body;

      dispatch(action({
        totalPageCount,
        currentPageNumber,
        inspections,
        totalResultCount,
      }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError(error));
    });
};

export const getOldInspections = getOldInspectionsList(actions.oldInspectionsFetched);
export const getOldNextInspections = getOldInspectionsList(actions.oldInspectionsAppended);

import axios from 'axios';

export const GET_INSPECTION_BY_ID = 'inspections/getShumaByID';

export const getInspectionData = (inspectionID) => {
  const shumaID = {
    shumaID: parseInt(inspectionID, 10)
  }

  return axios.post(GET_INSPECTION_BY_ID, shumaID)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body;
    });
};

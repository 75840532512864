import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonRemoveRow from '../ButtonRemoveRow';
import FieldInput from '../../../../_common/components/FieldInput';
import {DatePicker} from '../../../../_common/components/FieldDatepicker';
import {floatValue} from '../../../../../../utils/format';
import styles from '../styles.module.scss';

const RowCheque = ({
                     setFieldValue,
                     fieldsSet,
                     removeRow
                   }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={styles.chequeRowContainer}>
      <div className={styles.rowFieldsContainer}>
        <DatePicker
          className={styles.gridCellDate}
          value={startDate}
          onChange={(date) => {
            setStartDate(date);
            setFieldValue(fieldsSet.date, date);
          }}
          minDate={moment().format('YYYY-MM-DD')}
        />
        <FieldInput
          fieldId={fieldsSet.bankName}
          customContainerStyles={styles.gridCell}
        />
        <FieldInput
          fieldId={fieldsSet.bankBranch}
          customContainerStyles={styles.gridCell}
        />
        <FieldInput
          fieldId={fieldsSet.bankAccount}
          customContainerStyles={styles.gridCell}
        />
        <FieldInput
          fieldId={fieldsSet.chequeNum}
          customContainerStyles={styles.gridCell}
        />
        <FieldInput
          fieldId={fieldsSet.price}
          customContainerStyles={styles.gridCellPrice}
          onChange={floatValue}
        />
      </div>
      <ButtonRemoveRow onClick={removeRow} styles={styles}/>
    </div>
  );
};

RowCheque.propTypes = {
  setFieldValue: PropTypes.func,
  fieldsSet: PropTypes.object,
  removeRow: PropTypes.func,
};

export default RowCheque;


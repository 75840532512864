import React from 'react';
import classnames from 'classnames';
import {SectionRef} from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import NoteNumber from '../../NoteNumber';
import {formattingPercents, formattingPrice} from '../../../utils/formattingHelper';
import styles from './styles.module.scss';

const ShumaType2FinalPrice = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        finalMarketPrice,
        propertyBuildCostEstimate,
        propertyLandRemainingValueEstimate,
        quickRealizeCost,
        quickRealizeDeduction,
        improvementLevy,
      }
    },
    noteNumbers
  } = useCompleteInspectionContext();

  return (
    <SectionRef ref={ref}>
      <table
        className={classnames(styles.simpleTable, styles.w100, styles.longTable)}
      >
        <thead className={styles.greenBg}>
        <tr>
          <th>רכיב</th>
          <th className={styles.w30}>שווי בש"ח</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>שווי שוק הנכס</td>
          <td>{formattingPrice(finalMarketPrice)}</td>
        </tr>
        <tr>
          <td>אומדן עלות בניה של הנכס (ערך כינון)
            <NoteNumber id={'propertyBuildCostEstimate'}>
              {noteNumbers.propertyBuildCostEstimate}
            </NoteNumber>
          </td>
          <td>{formattingPrice(propertyBuildCostEstimate)}</td>
        </tr>
        <tr>
          <td>אומדן יתרת שווי הקרקע של הנכס
            <NoteNumber id={'priceDelta'}>{noteNumbers.priceDelta}</NoteNumber>
          </td>
          <td>{formattingPrice(propertyLandRemainingValueEstimate)}</td>
        </tr>
        <tr>
          <td>
            <span>שווי למימוש מהיר לצורך מכירה באילוץ (בהפחתה </span>
            {` ${formattingPercents(quickRealizeDeduction || 0)} `}
            <span>)</span>
          </td>
          <td>{formattingPrice(quickRealizeCost)}</td>
        </tr>
        {(improvementLevy === 0) && (
          <tr>
            <td colSpan={2}>
              בעת מימוש במכר ע"י הלווים לא צפוי היטל השבחה נכון למועד הקובע
            </td>
          </tr>
        )}
        <tr>
          <td colSpan={2}>
            בעת מימוש במכר ע"י הלווים לאור מועד הרכישה,ככל שאין זכאות לפטור בגין
            דירת מגורים מזכה מהווה שיעור המס 25% מהשטח בכפוף לניכויים עפ"י סעיף
            39 לחוק מסמ"ק,ככל שיהיו.
          </td>
        </tr>
        </tbody>
      </table>
    </SectionRef>
  );
});

export default ShumaType2FinalPrice;

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import * as _ from 'lodash';
import Media from '../../Media';
import {convertFileToContent} from '../../FileToContentConverter';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {fileTypeId} from '../../../constants/fileTypeId';
import {
  propertyLoanLegalRelationOptions,
  rentEssenseOptions,
  rightsRegistryRegulationsOptions,
  rightsTypeOptions,
} from '../../../constants/options';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import {checkIfTextEndsWithDot, getBreakLinesTextShumaType3} from '../../../utils/texts';
import {getSelectedOptionLabel} from '../../../utils/options';
import {divideThousandsIntegerValue, formattingDate} from '../../../utils';
import styles from './styles.module.scss';

const HeadLine1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyLegalDefinition,
        propertyLoanLegalRelation,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      <HeadContent/>
      <Line1Content/>
      {!Boolean(propertyLegalDefinition) && <Line2Content/>}
      {!Boolean(propertyLoanLegalRelation) && (
        <>
          <SubHead1Content/>
          <Line3Contnent/>
        </>
      )}
    </div>
  );
});

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section11}. `}</span>
        <span>מצב משפטי (הזכויות בנכס)</span>
      </span>
    </div>
  );
};

const Head = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <HeadContent/>
    </div>
  );
});

const Line1Content = () => {
  const {
    data: {
      pdfData: {
        propertyLegalDefinition
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(propertyLegalDefinition) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>הגדרה משפטית של הנכס: </span>
      <span>{getBreakLinesTextShumaType3(propertyLegalDefinition)}</span>
    </div>
  );
};

const Line1 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <Line1Content/>
    </div>
  );
});

const Line2Content = () => {
  const {
    data: {
      pdfData: {
        propertyLoanLegalRelation,
        propertyLoanLegalRelationText
      },
    },
  } = useCompleteInspectionContext();
  const propertyLoanLegalRelationValue = getSelectedOptionLabel(propertyLoanLegalRelation, propertyLoanLegalRelationOptions);

  return Boolean(propertyLoanLegalRelation) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>הקשר המשפטי בין הלווה לבין הנכס: </span>
      {propertyLoanLegalRelation === 4
        ? <span>{getBreakLinesTextShumaType3(propertyLoanLegalRelationText)}</span>
        : <span>{propertyLoanLegalRelationValue}.</span>
      }
    </div>
  );
};

const Line2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyLegalDefinition
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(propertyLegalDefinition) && <Line2Content/>}
    </div>
  );
});

const SubHead1Content = () => {
  return (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.innerPadding,
        styles.textUnderline,
        styles.bold
      )}
    >
      פרטי רישום
    </div>
  );
};

const SubHead1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyLegalDefinition
      },
    },
  } = useCompleteInspectionContext();
  return (
    <div ref={ref}>
      {Boolean(propertyLegalDefinition) && (
        <>
          <SubHead1Content/>
          <Line3Contnent/>
        </>
      )}
    </div>
  );
});

const Line3Contnent = () => {
  const {
    data: {
      pdfData: {
        helka,
        gush,
        landLotNumb,
        landLotNumAccordingTaba,
        noKadestritDivideText,
      },
    },
  } = useCompleteInspectionContext();
  const text = [
    'הקרקע שבה הנכס הנדון ידועה כחלקה ',
    Boolean(helka) ? `${helka}` : '',
    ' בגוש ',
    Boolean(gush) ? `${gush}` : '',
    Boolean(landLotNumb) ? ' מגרש ' : '',
    Boolean(landLotNumb) ? `${landLotNumb}` : '',
    Boolean(landLotNumAccordingTaba) ? ' ע"פ תב"ע ' : '',
    Boolean(landLotNumAccordingTaba) ? `${landLotNumAccordingTaba}` : '',
    _.some([
      helka,
      gush,
      landLotNumb,
      landLotNumAccordingTaba,
    ]) ? '.' : '',
    Boolean(noKadestritDivideText) ? ` ${noKadestritDivideText}${checkIfTextEndsWithDot(noKadestritDivideText)}` : '',
  ];

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
    >
      {text}
    </div>
  );
};

const Line3 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <Line3Contnent/>
    </div>
  );
});

const Line4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        registerDetailsNotes
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(registerDetailsNotes) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span>{getBreakLinesTextShumaType3(registerDetailsNotes)}</span>
        </div>
      )}
    </div>
  );
});

const Line6 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        rightsType,
        joinHomeRegistryRightsType,
      },
    },
  } = useCompleteInspectionContext();
  const rightsTypeValue = getSelectedOptionLabel(rightsType, rightsTypeOptions);
  const text = () => {
    switch (identifyMethodType) {
      case 2:
        return getBreakLinesTextShumaType3(joinHomeRegistryRightsType);
      case 1:
        return `${rightsTypeValue}${checkIfTextEndsWithDot(rightsTypeValue)}`;
      default:
        return null;
    }
  };

  return (
    <div ref={ref}>
      {_.some([rightsType, joinHomeRegistryRightsType]) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold, styles.textUnderline)}>הזכויות בנכס:</span>
          <span> {text()}</span>
        </div>
      )}
    </div>
  );
});

const Line7 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        rightsOwnerName,
        joinHomeRegistryOwnerName,
      },
    },
  } = useCompleteInspectionContext();

  const text = () => {
    switch (identifyMethodType) {
      case 2:
        return getBreakLinesTextShumaType3(joinHomeRegistryOwnerName);
      case 1:
        return getBreakLinesTextShumaType3(rightsOwnerName);
      default:
        return null;
    }
  };

  return (
    <div ref={ref}>
      {_.some([rightsOwnerName, joinHomeRegistryOwnerName,]) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>בעלים רשום: </span>
          <span>{text()}</span>
        </div>
      )}
    </div>
  );
});

const SubHeadLine2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
        arnonaVoucher,
        rightsChain,
        companyDocument,
      },
    },
    files,
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);
  const fileLandShituf = findFileByTypeId(files, fileTypeId.LAND_SHITUF);

  return (
    <div ref={ref}>
      <SubHead2Content/>
      <Line8Content/>
      {!Boolean(rightsPermitCompanyMeshakenet) && <Line9Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
      ]) && <Line10Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
      ]) && <Line11Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
      ]) && <Line12ImageContent/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
      ]) && <Line13Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
      ]) && <Line14ImageContent/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
      ]) && <Line15Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
        arnonaVoucher,
      ]) && <Line16Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
        arnonaVoucher,
        rightsChain,
      ]) && <Line17Content/>}
      {!_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
        arnonaVoucher,
        rightsChain,
        companyDocument,
      ]) && <Line18Content/>}
    </div>
  );
});

const SubHead2Content = () => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
        arnonaVoucher,
        rightsChain,
        companyDocument,
        stopConditionsAppendix,
        tamaDate,
        tamaSides,
        tamaLand,
        tamaEssence,
        rightsRegistryDate,
        rightsRegistryDesc,
        floor,
        houseRegisteredArea,
        sharedParts,
        attachedText,
        rightsRegistryRegulations,
        joinHomeRegistryNotes,
        joinHomeRegistryMortgage,
        joinHomeRegistryDate,
        warningNote,
        warningNoteText,
        rightsRegistryMortgage,
        rentAnnualFeeAmount,
        rentDate,
        rentEssense,
        rentSides,
        rentRentee,
        rentPurpose,
        rentPeriod,
        rentExtendOptions,
        rentCertificates,
        rentSpecialConditions,
        purchase,
        purchaseDate,
        purchaseSides,
        purchaseSelling,
        purchaseCompensation,
        purchaseDeliveryDate,
      },
      files
    },
  } = useCompleteInspectionContext();
  const showSubHeaderCondition =
    _.some([
      rightsPermitCompanyMeshakenet,
      rightsPermitRami,
      rightsPermitOther,
      landHahira,
      landHahiraText,
      landShituf,
      landShitufText,
      arnonaVoucher,
      rightsChain,
      companyDocument,
      stopConditionsAppendix,
      tamaDate,
      tamaSides,
      tamaLand,
      tamaEssence,
      rightsRegistryDate,
      rightsRegistryDesc,
      floor,
      houseRegisteredArea,
      sharedParts,
      attachedText,
      rightsRegistryRegulations,
      joinHomeRegistryNotes,
      joinHomeRegistryMortgage,
      joinHomeRegistryDate,
      warningNote,
      warningNoteText,
      rightsRegistryMortgage,
      rentAnnualFeeAmount,
      rentDate,
      rentEssense,
      rentSides,
      rentRentee,
      rentPurpose,
      rentPeriod,
      rentExtendOptions,
      rentCertificates,
      rentSpecialConditions,
      purchase,
      purchaseDate,
      purchaseSides,
      purchaseSelling,
      purchaseCompensation,
      purchaseDeliveryDate,
      findFileByTypeId(files, fileTypeId.LAND_SHITUF),
      findFileByTypeId(files, fileTypeId.TAMA),
      findFileByTypeId(files, fileTypeId.RENT),
      findFileByTypeId(files, fileTypeId.PURCHASE),
      findFileByTypeId(files, fileTypeId.COMMON_HOUSE_PLAN_LOCATING_APARTMENT)
    ]);

  return showSubHeaderCondition && (
    <div className={classnames(
      styles.textRegular,
      styles.sectionContainer,
      styles.innerPadding,
      styles.textUnderline,
      styles.bold
    )
    }>
      זכויות אחרות (חוזיות / זיקות הנאה / וכיוצ"ב):
    </div>
  );
};

const SubHead2 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <SubHead2Content/>
      <Line8Content/>
    </div>
  );
});

const Line8Content = () => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(rightsPermitCompanyMeshakenet) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>אישור זכויות חברה משכנת: </span>
      <span>{getBreakLinesTextShumaType3(rightsPermitCompanyMeshakenet)}</span>
    </div>
  );
};

const Line8 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <Line8Content/>
    </div>
  );
});

const Line9Content = () => {
  const {
    data: {
      pdfData: {
        rightsPermitRami
      },
    },
  } = useCompleteInspectionContext();

  return (
    Boolean(rightsPermitRami) && (
      <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
        <span className={classnames(styles.bold)}>אישור זכויות רמ"י: </span>
        <span>{getBreakLinesTextShumaType3(rightsPermitRami)}</span>
      </div>
    )
  );
};

const Line9 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rightsPermitCompanyMeshakenet) && <Line9Content/>}
    </div>
  );
});

const Line10Content = () => {
  const {
    data: {
      pdfData: {
        rightsPermitOther
      },
    },
  } = useCompleteInspectionContext();

  return (
    Boolean(rightsPermitOther) && (
      <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
        <div className={classnames(styles.bold)}>אישור זכויות אחר:</div>
        <div>{getBreakLinesTextShumaType3(rightsPermitOther)}</div>
      </div>
    )
  );
};

const Line10 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami
      ]) && <Line10Content/>}
    </div>
  );
});

const Line11Content = () => {
  const {
    data: {
      pdfData: {
        landHahira,
        landHahiraText
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div>
      {Boolean(landHahira) && Boolean(landHahiraText) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <div className={classnames(styles.bold)}>חוזה חכירה:</div>
          <div>{getBreakLinesTextShumaType3(landHahiraText)}</div>
        </div>
      )}
    </div>
  );
};

const Line11 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther
      ]) && <Line11Content/>}
    </div>
  );
});

const Line12ImageContent = () => {
  const context = useCompleteInspectionContext();

  const file = findFileByTypeId(context.files, fileTypeId.LAND_HAHIRA);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return Boolean(file) && Boolean(context?.data?.pdfData?.landHahira) && (
    <div className={classnames(styles.sectionContainer)}>
      <div
        className={classnames(
          styles.textRegular,
          styles.innerPadding,
          styles.bold,
        )}
      >
        קובץ:
      </div>
      <div className={styles.mediaContainer}>
        <Media
          src={imageSrc}
          file={file}
          alt="file"
          className={classnames(styles.mb3, styles.noPaddingRight)}
          type={mediaType}
        />
      </div>
    </div>
  );
};

const Line12Image = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
      ]) && <Line12ImageContent/>}
    </div>
  );
});

const HeaderLine12 = () => (
  <>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine12Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.LAND_HAHIRA);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.LAND_HAHIRA, {
      firstPageHeader: <HeaderLine12/>
    })
    : [];
};

const Line13Content = () => {
  const {
    data: {
      pdfData: {
        landShituf,
        landShitufText,
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(landShituf) && Boolean(landShitufText) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>הסכם שיתוף: </span>
      <span>{getBreakLinesTextShumaType3(landShitufText)}</span>
    </div>
  );
};

const Line13 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
      },
    },
    files
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
      ]) && <Line13Content/>}
    </div>
  );
});

const Line14ImageContent = () => {
  const context = useCompleteInspectionContext();
  const file = findFileByTypeId(context.files, fileTypeId.LAND_SHITUF);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return Boolean(file) && Boolean(context?.data?.pdfData?.landShituf) && (
    <div className={classnames(styles.sectionContainer)}>
      <div
        className={classnames(
          styles.textRegular,
          styles.innerPadding,
          styles.bold,
        )}
      >
        קובץ:
      </div>
      <div className={styles.mediaContainer}>
        <Media
          src={imageSrc}
          file={file}
          alt="file"
          className={classnames(styles.mb3, styles.noPaddingRight)}
          type={mediaType}
        />
      </div>
    </div>
  );
};

const Line14Image = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
      },
    },
    files
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
      ]) && <Line14ImageContent/>}
    </div>
  );
});

const HeaderLine14 = () => (
  <>
    <Line13/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine14Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.LAND_SHITUF);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.LAND_SHITUF, {
      firstPageHeader: <HeaderLine14/>,
      className: styles.shortedImageHeight
    })
    : [
      <Line13/>
    ];
};

const Line15Content = () => {
  const {
    data: {
      pdfData: {
        arnonaVoucher
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(arnonaVoucher) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={styles.bold}>שובר ארנונה: </span>
      <span>{getBreakLinesTextShumaType3(arnonaVoucher)}</span>
    </div>
  );
};

const Line15 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
      },
    },
    files
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);
  const fileLandShituf = findFileByTypeId(files, fileTypeId.LAND_SHITUF);

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
      ]) && <Line15Content/>}
    </div>
  );
});

const Line16Content = () => {
  const {
    data: {
      pdfData: {
        rightsChain
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(rightsChain) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={styles.bold}>שרשור זכויות: </span>
      <span>{getBreakLinesTextShumaType3(rightsChain)}</span>
    </div>
  );
};

const Line16 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
        arnonaVoucher,
      },
    },
    files
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);
  const fileLandShituf = findFileByTypeId(files, fileTypeId.LAND_SHITUF);

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
      ]) && <Line16Content/>}
    </div>
  );
});

const Line17Content = () => {
  const {
    data: {
      pdfData: {
        companyDocument
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(companyDocument) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={styles.bold}>מסמך מטעם החברה: </span>
      <span>{getBreakLinesTextShumaType3(companyDocument)}</span>
    </div>
  );
};

const Line17 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
        arnonaVoucher,
        rightsChain,
      },
    },
    files
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);
  const fileLandShituf = findFileByTypeId(files, fileTypeId.LAND_SHITUF);

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
        arnonaVoucher,
        rightsChain,
      ]) && <Line17Content/>}
    </div>
  );
});

const Line18Content = () => {
  const {
    data: {
      pdfData: {
        stopConditionsAppendix
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(stopConditionsAppendix) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={styles.bold}>נספח תנאים מתלים ומפסיקים: </span>
      <span>{getBreakLinesTextShumaType3(stopConditionsAppendix)}</span>
    </div>
  );
};

const Line18 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira,
        landHahiraText,
        landShituf,
        landShitufText,
        arnonaVoucher,
        rightsChain,
        companyDocument,
      },
    },
    files
  } = useCompleteInspectionContext();

  const fileLandHahira = findFileByTypeId(files, fileTypeId.LAND_HAHIRA);
  const fileLandShituf = findFileByTypeId(files, fileTypeId.LAND_SHITUF);

  return (
    <div ref={ref}>
      {_.some([
        rightsPermitCompanyMeshakenet,
        rightsPermitRami,
        rightsPermitOther,
        landHahira && landHahiraText,
        fileLandHahira && landHahira,
        landShituf && landShitufText,
        fileLandShituf && landShituf,
        arnonaVoucher,
        rightsChain,
        companyDocument,
      ]) && <Line18Content/>}
    </div>
  );
});

const SubHead3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        tama,
      }
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(tama) && (
        <div className={classnames(
          styles.textRegular,
          styles.sectionContainer,
          styles.innerPadding,
          styles.textUnderline,
          styles.bold
        )
        }>
          הסכם תמ"א:
        </div>
      )}
    </div>
  );
});

const Line19 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        tama,
        tamaDate
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(tamaDate) && Boolean(tama) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={styles.bold}>תאריך - </span>
          <span>{`${formattingDate(tamaDate)}${checkIfTextEndsWithDot(tamaDate)}`}</span>
        </div>
      )}
    </div>
  );
});

const Line20 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        tama,
        tamaSides
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(tamaSides) && Boolean(tama) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>הצדדים - </span>
          <span>{getBreakLinesTextShumaType3(tamaSides)}</span>
        </div>
      )}
    </div>
  );
});

const Line21 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        tama,
        tamaLand
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(tamaLand) && Boolean(tama) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>המקרקעין - </span>
          <span>{getBreakLinesTextShumaType3(tamaLand)}</span>
        </div>
      )}
    </div>
  );
});

const Line22 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        tama,
        tamaEssence
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(tamaEssence) && Boolean(tama) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>המהות - </span>
          <span>{getBreakLinesTextShumaType3(tamaEssence)}</span>
        </div>
      )}
    </div>
  );
});

const Line23Image = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();

  const file = findFileByTypeId(context.files, fileTypeId.TAMA);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return (
    <div ref={ref}>
      {file && Boolean(context?.data?.pdfData?.tama) && (
        <div className={classnames(styles.sectionContainer)}>
          <div
            className={classnames(
              styles.textRegular,
              styles.innerPadding,
              styles.bold,
            )}
          >
            קובץ:
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrc}
              file={file}
              alt="file"
              className={classnames(styles.mb3, styles.noPaddingRight)}
              type={mediaType}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const HeaderLine23 = () => (
  <>
    <SubHead3/>
    <Line19/>
    <Line20/>
    <Line21/>
    <Line22/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine23Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.TAMA);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.TAMA, {
      firstPageHeader: <HeaderLine23/>,
      className: styles.shortedImageHeight
    })
    : [
      <SubHead3/>,
      <Line19/>,
      <Line20/>,
      <Line21/>,
      <Line22/>,
    ];
};

const SubHead4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseJointHomeRegistered,
        houseRegisteredArea,
        identifyMethodType,
        rightsRegistryDate,
        rightsRegistryDesc,
        floor,
        sharedParts,
        attachedText,
        rightsRegistryRegulations,
        warningNote,
        warningNoteText,
        rightsRegistryMortgage,
      },
      files
    },
  } = useCompleteInspectionContext();

  const showHeaderCondition = (identifyMethodType === 1) &&
    _.some([
      rightsRegistryDate,
      rightsRegistryDesc,
      floor,
      sharedParts,
      attachedText,
      rightsRegistryRegulations,
      warningNote,
      warningNoteText,
      rightsRegistryMortgage,
      findFileByTypeId(files, fileTypeId.COMMON_HOUSE_PLAN_LOCATING_APARTMENT),
      (Boolean(houseRegisteredArea) && houseJointHomeRegistered === 1)
    ]);

  return (
    <div ref={ref}>
      {showHeaderCondition && (
        <div className={classnames(
          styles.textRegular,
          styles.sectionContainer,
          styles.innerPadding,
          styles.textUnderline,
          styles.bold
        )
        }>
          בנכסים הרשומים בפנקס הבתים המשותפים:
        </div>
      )}
    </div>
  );
});

const Line24 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        rightsRegistryDate,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {(identifyMethodType === 1) && Boolean(rightsRegistryDate) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- תאריך: </span>
          <span>{formattingDate(rightsRegistryDate)}{checkIfTextEndsWithDot(rightsRegistryDate)}</span>
        </div>
      )}
    </div>
  );
});

const Line25 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        rightsRegistryDesc,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {(identifyMethodType === 1) && Boolean(rightsRegistryDesc) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- תאור: </span>
          <span>{rightsRegistryDesc}{checkIfTextEndsWithDot(rightsRegistryDesc)}</span>
        </div>
      )}
    </div>
  );
});

const Line26 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        floor,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {(identifyMethodType === 1) && Boolean(floor) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- קומה: </span>
          <span>{floor}{checkIfTextEndsWithDot(floor)}</span>
        </div>
      )}
    </div>
  );
});

const Line27 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseJointHomeRegistered,
        houseRegisteredArea,
        identifyMethodType
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(houseRegisteredArea) && (houseJointHomeRegistered === 1) && (identifyMethodType === 1) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- שטח רשום: </span>
          <span>{houseRegisteredArea}</span>
          <span> מ"ר.</span>
        </div>
      )}
    </div>
  );
});

const Line28 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        sharedParts,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(sharedParts) && (identifyMethodType === 1) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- החלק ברכוש המשותף: </span>
          <span>{sharedParts}{checkIfTextEndsWithDot(sharedParts)}</span>
        </div>
      )}
    </div>
  );
});

const Line29 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        attachedText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(attachedText) && (identifyMethodType === 1) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- הצמדות (סימון, תאור, שטח): </span>
          <span>{getBreakLinesTextShumaType3(attachedText)}</span>
        </div>
      )}
    </div>
  );
});

const Line30 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        rightsRegistryRegulations,
      },
    },
  } = useCompleteInspectionContext();
  const rightsRegistryRegulationsValue = getSelectedOptionLabel(rightsRegistryRegulations, rightsRegistryRegulationsOptions);

  return (
    <div ref={ref}>
      {Boolean(rightsRegistryRegulations) && (identifyMethodType === 1) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- תקנון מצוי/ מוסכם (תנאים מיוחדים): </span>
          <span>{rightsRegistryRegulationsValue}{checkIfTextEndsWithDot(rightsRegistryRegulationsValue)}</span>
        </div>
      )}
    </div>
  );
});

const Line31 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        warningNote,
        warningNoteText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(warningNote) && Boolean(warningNoteText) && (identifyMethodType === 1) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- הערות בנסח הרישום: </span>
          <span>{getBreakLinesTextShumaType3(warningNoteText)}</span>
        </div>
      )}
    </div>
  );
});

const Line32 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        rightsRegistryMortgage,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rightsRegistryMortgage) && (identifyMethodType === 1) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>- משכנתא: </span>
          <span>{getBreakLinesTextShumaType3(rightsRegistryMortgage)}</span>
        </div>
      )}
    </div>
  );
});

const Line33Image = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();

  const file = findFileByTypeId(context.files, fileTypeId.COMMON_HOUSE_PLAN_LOCATING_APARTMENT);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return (
    <div ref={ref}>
      {file && (
        <div className={classnames(styles.sectionContainer)}>
          <div
            className={classnames(
              styles.textRegular,
              styles.innerPadding,
              styles.bold
            )}
          >
            תשריט הדירה מתשריטי רישום הבית המשותף:
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrc}
              file={file}
              alt="file"
              className={classnames(styles.mb3, styles.noPaddingRight)}
              type={mediaType}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const HeaderLine33 = () => (
  <>
    <SubHead4/>
    <Line24/>
    <Line25/>
    <Line26/>
    <Line27/>
    <Line28/>
    <Line29/>
    <Line30/>
    <Line31/>
    <Line32/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine33Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.COMMON_HOUSE_PLAN_LOCATING_APARTMENT);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.COMMON_HOUSE_PLAN_LOCATING_APARTMENT, {
      firstPageHeader: <HeaderLine33/>,
      className: styles.shortedImageHeight
    })
    : [
      <SubHead4/>,
      <Line24/>,
      <Line25/>,
      <Line26/>,
      <Line27/>,
      <Line28/>,
      <Line29/>,
      <Line30/>,
      <Line31/>,
      <Line32/>,
    ];
};

const SubHeadLine5 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        joinHomeRegistryDate,
        joinHomeRegistryNotes,
        identifyMethodType,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      <SubHead5Content/>
      <Line34Content/>
      {!_.some([
        joinHomeRegistryDate,
        identifyMethodType === 2,
      ]) && <Line35Content/>}
      {!_.some([
        joinHomeRegistryDate,
        identifyMethodType === 2,
        joinHomeRegistryNotes,
      ]) && <Line36Content/>}
    </div>
  );
});

const SubHead5Content = () => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        joinHomeRegistryDate,
        joinHomeRegistryNotes,
        joinHomeRegistryMortgage,
      },
    },
  } = useCompleteInspectionContext();
  const showSubHeaderCondition = (identifyMethodType === 2) &&
    _.some([
      joinHomeRegistryDate,
      joinHomeRegistryMortgage,
      joinHomeRegistryNotes
    ]);

  return showSubHeaderCondition && (
    <div className={classnames(
      styles.textRegular,
      styles.sectionContainer,
      styles.innerPadding,
      styles.textUnderline,
      styles.bold
    )
    }>
      נסח רישום מפנקס הזכויות:
    </div>
  );
};

const Line34Content = () => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        joinHomeRegistryDate
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(joinHomeRegistryDate) && (identifyMethodType === 2) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={styles.bold}> - תאריך: </span>
      <span>{`${formattingDate(joinHomeRegistryDate)}${checkIfTextEndsWithDot(joinHomeRegistryDate)}`}</span>
    </div>
  );
};

const Line35Content = () => {
  const {
    data: {
      pdfData: {
        joinHomeRegistryNotes,
        identifyMethodType
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(joinHomeRegistryNotes) && (identifyMethodType === 2) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}> - הערות בנסח הרישום: </span>
      <span>{getBreakLinesTextShumaType3(joinHomeRegistryNotes)}</span>
    </div>
  );
};

const Line35 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        joinHomeRegistryDate
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        joinHomeRegistryDate,
        identifyMethodType === 2,
      ]) && <Line35Content/>}
    </div>
  );
});

const Line36Content = () => {
  const {
    data: {
      pdfData: {
        joinHomeRegistryMortgage,
        identifyMethodType
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(joinHomeRegistryMortgage) && (identifyMethodType === 2) && (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
    >
      <span className={styles.bold}>- משכנתא: </span>
      <span>{getBreakLinesTextShumaType3(joinHomeRegistryMortgage)}</span>
    </div>
  );
};

const Line36 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        identifyMethodType,
        joinHomeRegistryDate,
        joinHomeRegistryNotes,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        joinHomeRegistryDate,
        identifyMethodType === 2,
        joinHomeRegistryNotes,
      ]) && <Line36Content/>}
    </div>
  );
});

const SubHead6 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent
      }
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rent) && (
        <div className={classnames(
          styles.textRegular,
          styles.sectionContainer,
          styles.innerPadding,
          styles.textUnderline,
          styles.bold
        )
        }>
          שכירות:
        </div>
      )}
    </div>
  );
});

const Line37 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentAnnualFeeAmount,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentAnnualFeeAmount) && Boolean(rent) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>דמי שכירות חודשיים: </span>
          <span>{divideThousandsIntegerValue(rentAnnualFeeAmount)}</span>
          <span> ש"ח.</span>
        </div>
      )}
    </div>
  );
});

const Line38 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentDate
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentDate) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={styles.bold}>מתאריך: </span>
          <span>{`${formattingDate(rentDate)}${checkIfTextEndsWithDot(rentDate)}`}</span>
        </div>
      )}
    </div>
  );
});

const Line39 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentEssense,
      },
    },
  } = useCompleteInspectionContext();
  const rentEssenseValue = getSelectedOptionLabel(rentEssense, rentEssenseOptions);

  return (
    <div ref={ref}>
      {Boolean(rentEssense) && Boolean(rent) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={styles.bold}>מהות השכירות: </span>
          <span>{rentEssenseValue}{checkIfTextEndsWithDot(rentEssenseValue)}</span>
        </div>
      )}
    </div>
  );
});

const Line40 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentSides
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentSides) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>הצדדים: </span>
          <span>{getBreakLinesTextShumaType3(rentSides)}</span>
        </div>
      )}
    </div>
  );
});

const Line41 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentRentee
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentRentee) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>המושכר: </span>
          <span>{getBreakLinesTextShumaType3(rentRentee)}</span>
        </div>
      )}
    </div>
  );
});

const Line42 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentPurpose
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentPurpose) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>מטרת השכירות: </span>
          <span>{getBreakLinesTextShumaType3(rentPurpose)}</span>
        </div>
      )}
    </div>
  );
});

const Line43 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentPeriod
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentPeriod) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>תקופת השכירות: </span>
          <span>{getBreakLinesTextShumaType3(rentPeriod)}</span>
        </div>
      )}
    </div>
  );
});

const Line44 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentExtendOptions
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentExtendOptions) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>אופציות להארכה: </span>
          <span>{getBreakLinesTextShumaType3(rentExtendOptions)}</span>
        </div>
      )}
    </div>
  );
});

const Line45 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentCertificates
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentCertificates) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>הערות: </span>
          <span>{getBreakLinesTextShumaType3(rentCertificates)}</span>
        </div>
      )}
    </div>
  );
});

const Line46 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        rent,
        rentSpecialConditions
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(rentSpecialConditions) && Boolean(rent) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>תנאים מיוחדים: </span>
          <span>{getBreakLinesTextShumaType3(rentSpecialConditions)}</span>
        </div>
      )}
    </div>
  );
});

const Line47Image = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const file = findFileByTypeId(context.files, fileTypeId.RENT);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return (
    <div ref={ref}>
      {file && Boolean(context?.data?.pdfData?.rent) && (
        <div className={classnames(styles.sectionContainer)}>
          <div
            className={classnames(
              styles.textRegular,
              styles.innerPadding,
              styles.bold
            )}
          >
            קובץ:
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrc}
              file={file}
              alt="file"
              className={classnames(styles.mb3, styles.noPaddingRight)}
              type={mediaType}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const HeaderLine47 = () => (
  <>
    <SubHead6/>
    <Line37/>
    <Line38/>
    <Line39/>
    <Line40/>
    <Line41/>
    <Line42/>
    <Line43/>
    <Line44/>
    <Line45/>
    <Line46/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine47Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.RENT);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.RENT, {
      firstPageHeader: <HeaderLine47/>,
      className: styles.shortedImageHeight
    })
    : [
      <SubHead6/>,
      <Line37/>,
      <Line38/>,
      <Line39/>,
      <Line40/>,
      <Line41/>,
      <Line42/>,
      <Line43/>,
      <Line44/>,
      <Line45/>,
      <Line46/>,
    ];
};

const SubHead7 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
      }
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchase) && (
        <div className={classnames(
          styles.textRegular,
          styles.sectionContainer,
          styles.innerPadding,
          styles.textUnderline,
          styles.bold
        )}
        >
          הסכם רכישה:
        </div>
      )}
    </div>
  );
});

const Line48 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseSigned
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchase) && (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
        >
          <span className={classnames(styles.bold)}>הסכם חתום: </span>
          <span>{Boolean(purchaseSigned) ? 'כן' : 'לא'}</span>
          <span>.</span>
        </div>
      )}
    </div>
  );
});

const Line49 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseDate
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchaseDate) && Boolean(purchase) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={styles.bold}>מתאריך: </span>
          <span>{`${formattingDate(purchaseDate)}${checkIfTextEndsWithDot(purchaseDate)}`}</span>
        </div>
      )}
    </div>
  );
});

const Line50 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseSides
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchaseSides) && Boolean(purchase) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>הצדדים: </span>
          <span>{getBreakLinesTextShumaType3(purchaseSides)}</span>
        </div>
      )}
    </div>
  );
});

const Line51 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseSelling
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchaseSelling) && Boolean(purchase) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>הממכר: </span>
          <span>{getBreakLinesTextShumaType3(purchaseSelling)}</span>
        </div>
      )}
    </div>
  );
});

const Line52 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseCompensation
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchaseCompensation) && Boolean(purchase) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>תמורת הממכר: </span>
          <span>{getBreakLinesTextShumaType3(purchaseCompensation)}</span>
        </div>
      )}
    </div>
  );
});

const Line53 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseDeliveryDate
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(purchaseDeliveryDate) && Boolean(purchase) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={styles.bold}>מועד מסירת החזקה בהתאם להסכם: </span>
          <span>{`${formattingDate(purchaseDeliveryDate)}${checkIfTextEndsWithDot(purchaseDeliveryDate)}`}</span>
        </div>
      )}
    </div>
  );
});

const Line53a = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        comments
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(comments) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={styles.bold}>הערות: </span>
          <span>{getBreakLinesTextShumaType3(comments)}</span>
        </div>
      )}
    </div>
  );
});

const Line54Image = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const file = findFileByTypeId(context.files, fileTypeId.PURCHASE);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return (
    <div ref={ref}>
      {file && Boolean(context?.data?.pdfData?.purchase) && (
        <div className={classnames(styles.sectionContainer)}>
          <div
            className={classnames(
              styles.textRegular,
              styles.innerPadding,
              styles.bold
            )}
          >
            קובץ:
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrc}
              file={file}
              alt="file"
              className={classnames(styles.mb3, styles.noPaddingRight)}
              type={mediaType}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const HeaderLine54 = () => (
  <>
    <SubHead7/>
    <Line48/>
    <Line49/>
    <Line50/>
    <Line51/>
    <Line52/>
    <Line53/>
    <Line53a/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine54Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.PURCHASE);

  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.PURCHASE, {
      firstPageHeader: <HeaderLine54/>,
      className: styles.shortedImageHeight
    })
    : [
      <SubHead7/>,
      <Line48/>,
      <Line49/>,
      <Line50/>,
      <Line51/>,
      <Line52/>,
      <Line53/>,
      <Line53a/>,
    ];
};

const SubHeadLine8 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <SubHead8Content/>
      <Line55Content/>
    </div>
  );
});

const SubHead8Content = () => {
  const {
    data: {
      pdfData: {
        propertyRightsDetails
      }
    },
  } = useCompleteInspectionContext();

  return Boolean(propertyRightsDetails) && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.innerPadding,
        styles.textUnderline,
        styles.bold
      )}
    >
      סיכום מצב משפטי:
    </div>
  );
};

const SubHead8 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <SubHead8Content/>
    </div>
  );
});

const Line55Content = () => {
  const {
    data: {
      pdfData: {
        propertyRightsDetails
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(propertyRightsDetails) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      {getBreakLinesTextShumaType3(propertyRightsDetails)}
    </div>
  );
};

const Line55 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <Line55Content/>
    </div>
  );
});

export {
  getLine12Files,
  getLine14Files,
  getLine23Files,
  getLine33Files,
  getLine47Files,
  getLine54Files,
};

export default {
  HeadLine1,
  Head,
  Line1,
  Line2,
  SubHead1,
  Line3,
  Line4,
  Line6,
  Line7,
  SubHeadLine2,
  SubHead2,
  Line8,
  Line9,
  Line10,
  Line11,
  Line12Image,
  Line13,
  Line14Image,
  Line15,
  Line16,
  Line17,
  Line18,
  SubHead3,
  Line19,
  Line20,
  Line21,
  Line22,
  Line23Image,
  SubHead4,
  Line24,
  Line25,
  Line26,
  Line27,
  Line28,
  Line29,
  Line30,
  Line31,
  Line32,
  Line33Image,
  SubHeadLine5,
  Line35,
  Line36,
  SubHead6,
  Line37,
  Line38,
  Line39,
  Line40,
  Line41,
  Line42,
  Line43,
  Line44,
  Line45,
  Line46,
  Line47Image,
  SubHead7,
  Line48,
  Line49,
  Line50,
  Line51,
  Line52,
  Line53,
  Line53a,
  Line54Image,
  SubHeadLine8,
  SubHead8,
  Line55,
};

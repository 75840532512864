import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import {can} from '../../../_common/components/Permissions';
import CommonPopup from "../../../_common/components/CommonPopup";
import {
  subjects as S,
  permissionTypes as T
} from '../../../_common/constants/permissionSubjects';
import {tooltips as TTS} from "../../../_common/constants/tooltips";
import PriceQuoteEdit from "./PriceQuoteEdit";
import {PriceQuoteEditUIGlobalProvider} from "./PriceQuoteEditUIContext";


const CellEditPriceQuote = ({cellContent}) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const {priceQuoteID} = cellContent;

  return (
    <>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={TTS.EDIT_PRICE_QUOTE}
        disabled={!can(T.FULL, S.PRICE_QUOTES_PAGE)}
        onClick={() => handleShowModal()}
      >
        <img
          src={toAbsoluteUrl('media/common/Edit.svg')}
          alt="edit-price-quote"
        />
      </Button>
      <CommonPopup
        isOpen={show}
        commonButton={false}
        content={
          <PriceQuoteEditUIGlobalProvider
            id={priceQuoteID}
            closeModal={handleCloseModal}
          >
            <PriceQuoteEdit/>
          </PriceQuoteEditUIGlobalProvider>
        }
        size={'m'}
      />
    </>
  );
};

export default CellEditPriceQuote;

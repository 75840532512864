import {actions} from "../reducers/OrdersPageTableReduser";
import axios from "axios";


export const GET_ORDERS_LIST_FOR_EXCEL = "/orders/getListForExcel";

export const getOrdersListForExcel = ({
                                        orderNumber = -1,
                                        createdFromDate = "",
                                        createdToDate = "",
                                        dueFromDate = "",
                                        dueToDate = "",
                                        customerID = -1,
                                        status = -1,
                                        shumaID = -1,
                                        requestedPageNumber = 0,
                                      }) => dispatch => {

  const data = {
    orderNumber: parseInt(orderNumber, 10),
    createdFromDate,
    createdToDate,
    dueFromDate,
    dueToDate,
    customerID: parseInt(customerID, 10),
    status: parseInt(status, 10),
    shumaID: parseInt(shumaID, 10),
    requestedPageNumber: parseInt(requestedPageNumber, 10),
  };

  return axios.post(GET_ORDERS_LIST_FOR_EXCEL, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body.list
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError(error));
    });
};

import React, {
  createContext, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useFormik, FieldArray, FormikProvider} from 'formik';
import moment from 'moment';
import {useOrderDetailsUIOrderContext} from './OrderDetailsUIOrderContext';
import {useOrderDetailsUIGlobalContext} from './OrderDetailsUIGlobalContext';
import * as actions from '../../_common/actions/orderInspectionsActions';
import {
  showGlobalMessage
} from '../../_common/components/GlobalMessage/actions/showGlobalMessage';
import {getFieldID} from '../utils/getFieldID';
import {decisionTree} from '../../_common/constants/decisionTree';

const OrderDetailsUIInspectionsListContext = createContext('');

export function useOrderDetailsUIInspectionsListContext() {
  return useContext(OrderDetailsUIInspectionsListContext);
}

export function OrderDetailsUIInspectionsListProvider({children}) {
  const {
    textContent,
    orderID
  } = useOrderDetailsUIGlobalContext();
  const {
    shumaForOrderCount,
    inspectionsCount,
    oldInspectionsForms
  } = useOrderDetailsUIOrderContext();

  const dispatch = useDispatch();

  const {orderDetails} = useSelector(
    (state) => ({orderDetails: state.orderDetailsReducer.orderDetails}),
    shallowEqual
  );
  const {inspections} = orderDetails;

  const getInitialTabOrderInspectionsValues = () => {
    const inspectionsTotalCount = Boolean(inspectionsCount) ? inspectionsCount : shumaForOrderCount;
    const newInspectionsQuantity = inspectionsTotalCount - (Boolean(inspections) ? inspections.length : 0);
    let result = [];
    if (Boolean(inspections)) {
      result = [...inspections];
    }
    for (let i = 1; i <= newInspectionsQuantity; i++) {
      result = [...result, {
        shumaID: null,
        shumaReasonID: null,
        propertyTypeID: null,
        bankID: null,
        bankBranchNumber: '',
        bankBranchName: '',
        bankClerkFullName: '',
        bankEmail: '',
        bankRequestID: '',
        bankBuildType: '',
        shumaTypeID: null,
        loanTypeID: null,
        loanText: '',
        otherPropertyTypeID: null,
        responsibleUser: null,
        dueDate: null,
        city: null,
        street: null,
        gush: '',
        helka: '',
        houseNumber: '',
        ttHelka: '',
        apartmentNumber: '',
        notes: '',
        landLotNum: '',
        landLotNumAccordingTaba: '',
        loanSubTypeID: null,
        shumaMazminIdentityID: null,
        underConstruction: false
      }];
    }

    return result;
  };

  useEffect(() => {
    const inspections = getInitialTabOrderInspectionsValues();
    formikOrderInspections.setValues({inspections});
  }, [
    JSON.stringify(inspections)
  ]);

  const validate = values => {
    const {inspections} = values;
    let errors = {};

    inspections.forEach((item, index) => {
      if (!item.shumaID) {
        const {
          shumaReasonID,
          propertyTypeID,
          bankID,
          shumaTypeID,
          loanTypeID,
          loanText,
          otherPropertyTypeID,
          responsibleUser,
          shumaMazminIdentityID
        } = item;

        if (shumaReasonID === decisionTree.rightsEstimation) {
          if (!propertyTypeID) {
            errors[getFieldID(`propertyTypeID`, index)] = 'Required field';
          }
        } else {
          if (!bankID) {
            errors[getFieldID(`bankID`, index)] = 'Required field';
          }
          if (!shumaTypeID) {
            errors[getFieldID(`shumaTypeID`, index)] = 'Required field';
          }
          if (!propertyTypeID) {
            errors[getFieldID(`propertyTypeID`, index)] = 'Required field';
          }
        }
        if (loanTypeID && `${loanTypeID.value}` === decisionTree.loanTypeID.other) {
          if (!otherPropertyTypeID) {
            errors[getFieldID(`otherPropertyTypeID`, index)] = 'Required field';
          }
        }
        if (loanTypeID && `${loanTypeID.value}` === decisionTree.loanTypeID.another) {
          if (!loanText) {
            errors[getFieldID(`loanText`, index)] = 'Required field';
          }
        }
        if (!responsibleUser) {
          errors[getFieldID(`responsibleUser`, index)] = 'Required field';
        }
        if (!shumaMazminIdentityID) {
          errors[getFieldID(`shumaMazminIdentityID`, index)] = 'Required field';
        }
      }
    });

    return errors;
  };

  const formikOrderInspections = useFormik({
    initialValues: {
      inspections: []
    },
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const handleCreateInspectionForLinkedOrder = async () => {
    const calls = formikOrderInspections.values.inspections.map(async (inspection, index) => {
      const {
        bankID,
        city,
        dueDate,
        loanTypeID,
        propertyTypeID,
        otherPropertyTypeID,
        responsibleUser,
        shumaTypeID,
        street,
        landLotNum,
        landLotNumAccordingTaba,
        loanSubTypeID,
        shumaMazminIdentityID,
        underConstruction,
        ...rest
      } = inspection;
      const editedInspection = {
        orderID: orderID,
        bankID: bankID ? bankID.value : null,
        city: city ? city.value : null,
        dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : null,
        loanTypeID: loanTypeID ? loanTypeID.value : null,
        propertyTypeID: propertyTypeID ? propertyTypeID.value : null,
        otherPropertyTypeID: otherPropertyTypeID ? otherPropertyTypeID.value : null,
        responsibleUser: responsibleUser ? responsibleUser.value : null,
        shumaTypeID: shumaTypeID ? shumaTypeID.value : '',
        street: street ? street.value : null,
        loanSubTypeID: loanSubTypeID ? loanSubTypeID.value : null,
        shumaMazminIdentityID: shumaMazminIdentityID ? shumaMazminIdentityID.value : null,
        landLotNum: landLotNum || '',
        landLotNumAccordingTaba: landLotNumAccordingTaba || '',
        underConstruction: Boolean(underConstruction),
        ...rest
      };

      try {
        if (editedInspection.shumaID) {
          return null;
        } else {
          const newInspection = {...editedInspection, shumaID: -1};
          if (oldInspectionsForms) {
            newInspection.originTypeID = 2;
          }
          const result = await dispatch(actions.addEditShumaLinkedToOrder(newInspection));
          formikOrderInspections.setFieldValue(getFieldID(`shumaID`, index), result);
        }
      } catch (e) {
        return null;
      }
    });

    const results = await Promise.all(calls);

    if (results.every(Boolean)) {
      await dispatch(showGlobalMessage(textContent.successMessage));
    }
  };

  const value = {
    formikOrderInspections,
    getInitialTabOrderInspectionsValues,
    handleCreateInspectionForLinkedOrder,
  };

  return (
    <FormikProvider value={value.formikOrderInspections}>
      <FieldArray
        name={'inspections'}
        render={(arrayHelpers) => (
          <OrderDetailsUIInspectionsListContext.Provider value={{...value, arrayHelpers}}>
            {children}
          </OrderDetailsUIInspectionsListContext.Provider>
        )}
      />
    </FormikProvider>
  );
}

OrderDetailsUIInspectionsListProvider.propTypes = {
  children: PropTypes.node,
};

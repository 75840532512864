import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {formattingDate} from '../../utils/formattingHelper';
import {getDocCodeString} from '../../utils/texts';
import styles from './styles.module.scss';

const DocProps = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    gush, helka, ttHelka,
    pdfCreateTime,
    bankName,
    bankBranchNumber,
    bankBranchName,
    bankClerkFullName,
    bankEmail,
    bankRequestID,
    bankBuildType,
  } = context.data.pdfData;

  const pdfCreateTimeValue = formattingDate(pdfCreateTime);
  const docCodeValue = getDocCodeString({gush, helka, ttHelka, pdfCreateTime});
  const bankBranchNameValue = Boolean(bankBranchName) ? bankBranchName : '';
  const bankBranchNumberValue = Boolean(bankBranchNumber) ? bankBranchNumber : '';
  const branchValue = `${bankBranchNameValue} ${bankBranchNumberValue}`;

  return (
    <div ref={ref}>
      <div className={styles.docPropsItem}>
        לכבוד:
      </div>
      <div className={styles.docProps}>
        <div className={styles.rightSideRows}>
          <div className={classnames(styles.bold, styles.bigColumn, styles.docPropsItem)}>
            {bankName}
          </div>
          {(Boolean(bankBranchName) || Boolean(bankBranchNumber)) && (
            <div className={classnames(styles.bigColumn, styles.docPropsItem)}>
              סניף:{branchValue}
            </div>
          )}
          {Boolean(bankClerkFullName) && (
            <div className={classnames(styles.bigColumn, styles.docPropsItem)}>
              לידי:{bankClerkFullName}
            </div>
          )}
          {Boolean(bankEmail) && (
            <div className={classnames(styles.bigColumn, styles.docPropsItem)}>
              מייל: {bankEmail}
            </div>
          )}
        </div>
        <div className={styles.leftSideRows}>
          <div className={classnames(styles.bold, styles.docPropsItem)}>
            תאריך: {pdfCreateTimeValue}
          </div>
          <div className={classnames(styles.bold, styles.docPropsItem)}>
            סימננו:{docCodeValue}
          </div>
          {Boolean(bankRequestID) && (
            <div className={classnames(styles.bold, styles.docPropsItem)}>
              מספר בקשה/הלוואה/בטחון:{bankRequestID}
            </div>
          )}
          {Boolean(bankBuildType) && (
            <div className={classnames(styles.bold, styles.docPropsItem)}>
              סוג בנייה: {bankBuildType}
            </div>
          )}
        </div>
      </div>
      <div className={classnames(styles.bold, styles.lastPageLine)}>
        ג.א.נ. ,
      </div>
    </div>
  );
});

export default DocProps;

import React from 'react';

export function BoldLTRColumnFormatter(cellContent) {
  return (
    <div
      dir={'ltr'}
      className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap"
      data-text={cellContent}
    >
      {cellContent}
    </div>
  );
}

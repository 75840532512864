import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';


const GridTasksContext = createContext('');

export function useGridTasksContext() {
  return useContext(GridTasksContext);
}

export function GridTasksContextProvider({tasksContext, children}) {
  const {
    shumaID,
    formik,
    fieldNames,
    tasksList,
    loadingList,
    getTaskDetails,
    resetTaskDetails,
    addEditTask,
    updateTasksList,
    deleteTask,
    createTask,
  } = tasksContext;

  const rows = tasksList.map(item => {
    return {
      ...item,
      onOpenDetails: async (taskID) => {
        formik.resetForm();
        await getTaskDetails(taskID);
      },
      onEdit: async () => {
        await addEditTask();
        await updateTasksList();
      },
      onDelete: async (taskID) => {
        await deleteTask(taskID);
        await updateTasksList();
      }
    };
  });

  const value = {
    shumaID,
    formik,
    fieldNames,
    tasksList,
    loadingList,
    getTaskDetails,
    resetTaskDetails,
    addEditTask,
    deleteTask,
    createTask,
    updateTasksList,
    rows,
    disabled: !Boolean(shumaID) || (shumaID === -1)
  };

  return (
    <GridTasksContext.Provider value={value}>
      {children}
    </GridTasksContext.Provider>
  );
}

GridTasksContextProvider.propTypes = {
  children: PropTypes.node,
};

const defaultState = {
  previewDocumentLink: '',
  error: false,
  errorMessage: null
};

export const actionTypes = {
  FETCH_DOCUMENT_LINK: 'FETCH_DOCUMENT_LINK',
  catchError: 'CATCH_ERROR'
};

export default (state= defaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_LINK: {
      const {file} = action.payload;

      return {
        previewDocumentLink: file,
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.catchError: {
      return {
        ...state,
        error: true,
        errorMessage: `FETCH_DOCUMENT_LINK: ${action.payload.error}`
      };
    }
    default: return state;
  }
};

export const actionsPreviewDocument = {
  fetchDocumentLink: (data) => ({
    type: actionTypes.FETCH_DOCUMENT_LINK,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: {error}
  }),
};

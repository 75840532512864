import React from 'react';
import {useDispatch} from "react-redux";
import {useLocation} from 'react-router';
import {closeShuma} from "../../../../../_common/actions/oldInspectionDetailsActions";
import {
  getFileList,
  getFile,
  deleteFile,
  copyOrderFileToShuma
} from "../../../../actions/SectionFilesActions";
import CommonButton from "../../../../../_common/components/CommonButton";
import styles from './styles.module.scss';


export function FileItem({
                           fileID,
                           fileName,
                           fileTypeName,
                           fileTypeID,
                           orderID,
                           shumaID,
                           showFileTypeColumn = true,
                           allowDownloadFile,
                           allowDeleteFile,
                           allowSelectFile,
                           closePopup
                         }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const shumaOriginID = Boolean(location.state) ? location.state[0] : null;

  const handleDownloadFile = () => {
    dispatch(getFile({shumaID, fileID, fileName}))
  };
  const handleDeleteFile = () => {
    dispatch(deleteFile({shumaID, fileID}))
      .then(() => dispatch(getFileList(shumaID)))
  }
  const handleSelectFile = () => {
    dispatch(copyOrderFileToShuma({orderID, shumaID, orderFileID: fileID, fileTypeID}))
      .then(() => {
        if (fileTypeID === 21 && shumaOriginID === 3) {
          dispatch(closeShuma(shumaID))
        }
      })
      .then(() => dispatch(getFileList(shumaID)))
      .then(() => closePopup())
      .catch(e => console.log(e))
  };

  return (
    <div
      key={fileID}
      className={`mt-2 ${styles.filesList}`}
    >
      <div className="font-weight-bolder font-size-m">
        {fileName}
      </div>
      {showFileTypeColumn && (
        <div className="font-weight-bolder font-size-m mr-2">
          {fileTypeName}
        </div>
      )}
      {allowDeleteFile && (
        <CommonButton
          type={'button'}
          icon={'../../../media/common/Delete.svg'}
          onClick={() => handleDeleteFile(fileID)}
          customButtonClassNames={'btn btn-warning w-50px d-flex mr-2'}
        />
      )}
      {allowDownloadFile && (
        <CommonButton
          type={'button'}
          icon={'../../../media/common/Download.svg'}
          onClick={() => handleDownloadFile(fileID, fileName)}
          customButtonClassNames={'btn btn-warning w-50px d-flex'}
        />
      )}
      {allowSelectFile && (
        <CommonButton
          type={'button'}
          icon={'../../../media/common/Check.svg'}
          onClick={() => handleSelectFile()}
          customButtonClassNames={'btn btn-warning d-flex'}
        />
      )}
    </div>
  );
}

import React, {useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf';
import Spinner from '../Spinner';
import Empty from '../Empty';
import {getFile} from '../../actions/getFile';
import styles from './styles.module.scss';

const DocumentPreview = ({fileID, shumaID, close}) => {
  const [dataURI, setDataURI] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (fileID) {
      update().catch(console.error);
    }
  }, []);

  const update = async () => {
    try {
      setDataURI('');
      const body = await getFile({shumaId: shumaID, fileID});
      setDataURI(`data:application/pdf;base64,${body}`);
      setLoading(false);
    } catch (e) {
      setDataURI('');
      close();
    } finally {
      setLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({numPages}) => {
    setTotalPages(numPages);
  };

  const renderPages = () => {
    const result = [];
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      result.push(<Page pageNumber={pageNumber} className="mb-1"/>);
    }

    return result;
  };

  return loading
    ? (
      <div className={styles.spinnerBg}>
        <Spinner/>
      </div>
    )
    : (
      <div className={styles.popupPreviewDocumentContainer}>
        <Document
          file={dataURI}
          loading={
            <div className={styles.iconContainer}>
              <Spinner/>
            </div>
          }
          noData={
            <div className={styles.iconContainer}>
              <Empty/>
            </div>
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {renderPages()}
        </Document>
      </div>
    );
};

export default DocumentPreview;
import moment from 'moment';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import * as requestFromServer from './authCrud';
import {actions} from './authRedux';
import {persistor} from '../../../../redux/store';
import {PoolData} from '../../../../config/cognito';
import config from '../../../../config/common';

const userPool = new CognitoUserPool(PoolData);

export const loginWithCognito = code => dispatch => {
  return requestFromServer
    .getAccessToken(code)
    .then(response => {
      const {error, data, status, statusText} = response.data;
      if (error < 0) throw new Error(`${status}: ${statusText}`);

      return dispatch(actions.login(data.token));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find user';
      dispatch(actions.catchError(error));
    });
};

export const login = ({username, password}) => dispatch => {
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (userSession) {
        const tokens = extractUserSessionTokens(userSession);
        cognitoUser.getUserAttributes((err, result) => {
          if (err) return console.error(err.message || JSON.stringify(err));
          const attributes = transformUserAttributes(result);
          resolve(requestFromServer
            .loginWithTokens({
              userID: attributes.email,
              ...tokens
            })
            .then(response => {
              dispatch(actions.login({
                tokens,
                user: {...cognitoUser, attributes},
                greenInvoiceApiKey: response.data.body.greenInvoiceApiKey,
                greenInvoiceSecret: response.data.body.greenInvoiceSecret,
                greenInvoicePaymentPluginID: response.data.body.greenInvoicePaymentPluginID,
                pages: (Boolean(response.data.body.pages))
                  ? response.data.body.pages.map(page => {
                    return {
                      pageName: page.page_name,
                      permissionLevel: page.permission_level,
                      default: Boolean(page.is_default)
                    };
                  })
                  : [],
                adminPermissionsLevel: response.data.body.adminPermissionsLevel
              }));
            })
            .catch(err => console.error(err.message || JSON.stringify(err))));
        });
      },

      onFailure: function (err) {
        const errText = err.message || JSON.stringify(err);
        console.error(errText);
        reject(errText);
      },
    });
  });
};

export const logout = () => dispatch => {
  return requestFromServer.logout()
    .then(() => persistor.purge())
    .catch(err => console.error(err.message || JSON.stringify(err)))
    .finally(() => dispatch(actions.logout()));
};

export const refreshToken = () => dispatch => {
  return requestFromServer
    .refreshToken()
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body.newTokenData.accessToken;
    })
    .then(accessToken => dispatch(actions.registerToken({
      accessToken,
      expire: moment().add(config.expireTimeout).unix()
    })));
};

export const getUserPermissions = () => dispatch => {
  return requestFromServer
    .getUserPermissions()
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body.permissions;
    })
    .then(permissions => dispatch(actions.updatePermissions(permissions)));
};

const extractUserSessionTokens = sessionInfo => {
  const {
    accessToken: {
      jwtToken: accessToken,
      payload: {
        exp: expire,
        sub: uid
      }
    },
    idToken: {
      jwtToken: idToken,
    },
    refreshToken: {
      token: refreshToken,
    }
  } = sessionInfo;

  return {
    uid,
    accessToken,
    idToken,
    refreshToken,
    expire
  };
};

const transformUserAttributes = cognitoAttributes => {
  return cognitoAttributes.reduce((acc, attr) => {
    const {Name, Value} = attr;
    acc[Name] = Value;

    return acc;
  }, {});
};

import React from 'react';
import classnames from 'classnames';
import ItemList from '../../ItemList';
import {convertFileToContent} from '../../FileToContentConverter';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {findFileByTypeId} from '../../../utils/files';
import {formattingDate} from '../../../utils/formattingHelper';
import {getSelectedOptionLabel} from '../../../utils/options';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import {rentEssenseOptions} from '../../../constants/options';
import styles from './styles.module.scss';
import pageStyle from '../styles.module.scss';

export const landShitufNoteText = '* לא הוצג בפני הסכם שיתוף ו/או תשריט חלוקה. זיהוי הנכס הוא בהתאם לנכס שהוצג פיזית בפני הח״מ בעת הביקור.';
export const fileTypeIdTama = 27;
export const fileTypeIdPurchase = 28;
export const fileTypeIdRent = 29;
export const fileTypeIdReport = 30;

const getHeader = ({context, page}) => {
  const tamaFile = findFileByTypeId(context.files, fileTypeIdTama);
  const purchaseFile = findFileByTypeId(context.files, fileTypeIdPurchase);
  const rentFile = findFileByTypeId(context.files, fileTypeIdRent);
  const {
    data: {
      pdfData: {
        tama,
        purchase,
        rent,
      }
    }
  } = context;
  const noTamaOrNoFile = !Boolean(tama && tamaFile);
  const noPurchaseOrNoFile = !Boolean(purchase && purchaseFile);
  const noRentOrNoFile = !Boolean(rent && rentFile);

  switch (page) {
    case 'tama':
      return [<Notes/>, <TamaText/>];
    case 'purchase':
      let purchaseResult = [];
      if (noTamaOrNoFile) {
        purchaseResult = [...purchaseResult, <Notes/>, <TamaText/>];
      }
      purchaseResult = [...purchaseResult, <PurchaseText/>];

      return purchaseResult;
    case 'rent':
      let rentResult = [];
      if (noTamaOrNoFile && noPurchaseOrNoFile) {
        rentResult = [...rentResult, <Notes/>, <TamaText/>];
      }
      if (noPurchaseOrNoFile) {
        rentResult = [...rentResult, <PurchaseText/>];
      }
      rentResult = [...rentResult, <RentText/>];

      return rentResult;
    case 'report':
      let reportResult = [];
      if (noTamaOrNoFile && noPurchaseOrNoFile && noRentOrNoFile) {
        reportResult = [...reportResult, <Notes/>, <TamaText/>];
      }
      if (noPurchaseOrNoFile && noRentOrNoFile) {
        reportResult = [...reportResult, <PurchaseText/>];
      }
      if (noRentOrNoFile) {
        reportResult = [...reportResult, <RentText/>];
      }
      reportResult = [...reportResult, <ReportText/>];

      return reportResult;
    default:
      return [];
  }
};

const getFooter = ({context, page}) => {
  const purchaseFile = findFileByTypeId(context.files, fileTypeIdPurchase);
  const rentFile = findFileByTypeId(context.files, fileTypeIdRent);
  const reportFile = findFileByTypeId(context.files, fileTypeIdReport);
  const {
    data: {
      pdfData: {
        purchase,
        rent,
        report
      }
    }
  } = context;
  const noPurchaseOrNoFile = !Boolean(purchase && purchaseFile);
  const noRentOrNoFile = !Boolean(rent && rentFile);
  const noReportOrNoFile = !Boolean(report && reportFile);

  switch (page) {
    case 'tama':
      return (noPurchaseOrNoFile && noRentOrNoFile && noReportOrNoFile)
        ? [
          <PurchaseText/>,
          <RentText/>,
          <ReportText/>,
        ]
        : [];
    case 'purchase':
      return (noRentOrNoFile && noReportOrNoFile)
        ? [
          <RentText/>,
          <ReportText/>,
        ]
        : [];
    case 'rent':
      return noReportOrNoFile ? [<ReportText/>] : [];
    case 'report':
    default:
      return [];
  }
};

const LandShitufNote = () => {
  const {
    data: {
      pdfData: {
        landShituf,
      },
    },
  } = useCompleteInspectionContext();

  return !Boolean(landShituf) && (
    <div className={classnames(styles.itemText, styles.innerPadding)}>
      {landShitufNoteText}
    </div>
  );
};

const WarningNote = () => {
  const {
    data: {
      pdfData: {
        warningNote,
        warningNoteText,
      },
    },
  } = useCompleteInspectionContext();

  let firstLineText = '';
  let restChunks = [];
  if (Boolean(warningNoteText)) {
    [firstLineText, ...restChunks] = getTextChunks(warningNoteText);
  }

  return Boolean(warningNote) && [
    <div className={classnames(styles.itemText, styles.innerPadding)}>
      <span className={styles.bold}>הערת אזהרה - </span>
      <span>על הנכס רשומה הערת אזהרה לטובת {firstLineText}.</span>
    </div>,
    ...restChunks.map(mapTextChunks).map((item, index) => (
      <div className={styles.innerPadding} key={index}>
        {item}
      </div>
    ))
  ];
};

const Notes = () => {
  const {
    data: {
      pdfData: {
        landShituf,
        warningNote,
      },
    },
  } = useCompleteInspectionContext();

  return (!Boolean(landShituf) || Boolean(warningNote)) && [
    <LandShitufNote/>,
    <WarningNote/>,
    <div className={styles.lastSectionLine}/>
  ];
};

const TamaText = () => {
  const {
    data: {
      pdfData: {
        tama,
        tamaDate,
        tamaSides,
        tamaLand,
        tamaEssence,
      },
    },
  } = useCompleteInspectionContext();

  const items = [
    {name: 'תאריך', value: Boolean(tamaDate) ? formattingDate(tamaDate) : ''},
    {name: 'הצדדים', value: tamaSides},
    {name: 'המקרקעין', value: tamaLand},
    {name: 'המהות', value: tamaEssence},
  ];

  return Boolean(tama) && (
    <ItemList
      fileTypeId={fileTypeIdTama}
      items={items}
      title={'הוצג בפני הסכם תמ"א 38'}
      altText={'file-tama'}
    />
  );
};

const getTama = context => {
  const tamaFile = findFileByTypeId(context.files, fileTypeIdTama);

  return Boolean(tamaFile) ? [
    ...getHeader({context, page: 'tama'}),
    ...convertFileToContent(context, fileTypeIdTama)
      .map((item, index, content) => {
        const cutFirstPage = index === 0
          && getHeader({context, page: 'tama'}).length > 0;
        const cutLastPage = index === content.length - 1
          && getFooter({context, page: 'tama'}).length > 0;
        return (
          <div className={classnames({
            [pageStyle.pageHeight]: cutFirstPage || cutLastPage,
            [pageStyle.smallerHeight720]: cutFirstPage || cutLastPage
          })}>
            {item}
          </div>
        );
      }),
    ...getFooter({context, page: 'tama'}),
  ] : [];
};

const PurchaseText = ({...rest}) => {
  const {
    data: {
      pdfData: {
        purchase,
        purchaseDate,
        purchaseSigned,
        purchaseSides,
        purchaseSelling,
        purchaseCompensation,
        purchaseDeliveryDate,
      },
    },
  } = useCompleteInspectionContext();

  const purchaseSignedValue = (purchaseSigned === 1) ? 'כן' : 'לא';
  const items = [
    {
      name: 'תאריך',
      value: Boolean(purchaseDate) ? formattingDate(purchaseDate) : ''
    },
    {name: 'הסכם חתום', value: purchaseSignedValue},
    {name: 'הצדדים', value: purchaseSides},
    {name: 'הממכר', value: purchaseSelling},
    {name: 'התמורה', value: purchaseCompensation},
    {
      name: 'מועד מסירת החזקה',
      value: Boolean(purchaseDeliveryDate) ? formattingDate(purchaseDeliveryDate) : ''
    },
  ];

  return Boolean(purchase) && (
    <ItemList
      fileTypeId={fileTypeIdPurchase}
      items={items}
      title={'הוצג בפני הסכם רכישה'}
      altText={'file-purchase'}
      {...rest}
    />
  );
};

const getPurchase = context => {
  const purchaseFile = findFileByTypeId(context.files, fileTypeIdPurchase);

  return Boolean(purchaseFile) ? [
    ...getHeader({context, page: 'purchase'}),
    ...convertFileToContent(context, fileTypeIdPurchase)
      .map((item, index, content) => {
        const cutFirstPage = index === 0
          && getHeader({context, page: 'purchase'}).length > 0;
        const cutLastPage = index === content.length - 1
          && getFooter({context, page: 'purchase'}).length > 0;
        return (
          <div className={classnames({
            [pageStyle.pageHeight]: cutFirstPage || cutLastPage,
            [pageStyle.smallerHeight720]: cutFirstPage || cutLastPage
          })}>
            {item}
          </div>
        );
      }),
    ...getFooter({context, page: 'purchase'}),
  ] : [];
};

const RentText = ({...rest}) => {
  const {
    data: {
      pdfData: {
        rent,
        rentEssense,
        rentDate,
        rentSides,
        rentRentee,
        rentPurpose,
        rentPeriod,
        rentExtendOptions,
        rentAnnualFeeAmount,
        rentCertificates,
        rentSpecialConditions,
      },
    },
  } = useCompleteInspectionContext();

  const rentEssenceValue = getSelectedOptionLabel(rentEssense, rentEssenseOptions);
  const items = [
    {name: 'מהות השכירות', value: rentEssenceValue},
    {name: 'תאריך', value: Boolean(rentDate) ? formattingDate(rentDate) : ''},
    {name: 'הצדדים', value: rentSides},
    {name: 'המושכר', value: rentRentee},
    {name: 'מטרת השכירות', value: rentPurpose},
    {name: 'תקופת השכירות', value: rentPeriod},
    {name: 'אופציות להארכה', value: rentExtendOptions},
    {name: 'דמ"ש שנתיים', value: rentAnnualFeeAmount},
    {name: 'האישורים לעניין דמי השכירות', value: rentCertificates},
    {name: 'תנאים מיוחדים', value: rentSpecialConditions},
  ];

  return Boolean(rent) && (
    <ItemList
      fileTypeId={fileTypeIdRent}
      items={items}
      title={'הוצג בפני הסכם שכירות'}
      altText={'file-rent'}
      {...rest}
    />
  );
};

const getRent = context => {
  const rentFile = findFileByTypeId(context.files, fileTypeIdRent);

  return Boolean(rentFile) ? [
    ...getHeader({context, page: 'rent'}),
    ...convertFileToContent(context, fileTypeIdRent)
      .map((item, index, content) => {
        const cutFirstPage = index === 0
          && getHeader({context, page: 'rent'}).length > 0;
        const cutLastPage = index === content.length - 1
          && getFooter({context, page: 'rent'}).length > 0;
        return (
          <div className={classnames({
            [pageStyle.pageHeight]: cutFirstPage || cutLastPage,
            [pageStyle.smallerHeight720]: cutFirstPage || cutLastPage
          })}>
            {item}
          </div>
        );
      }),
    ...getFooter({context, page: 'rent'}),
  ] : [];
};

const ReportText = ({...rest}) => {
  const {
    data: {
      pdfData: {
        report,
        reportDate,
        reportText,
      },
    },
  } = useCompleteInspectionContext();

  const items = [
    {
      name: 'תאריך',
      value: Boolean(reportDate) ? formattingDate(reportDate) : ''
    },
    {name: 'פירוט', value: reportText},
  ];

  return Boolean(report) && (
    <ItemList
      fileTypeId={fileTypeIdReport}
      items={items}
      title={'הוצג בפני דוח מהנדס לליקויי בנייה'}
      altText={'file-report'}
      {...rest}
    />
  );
};

const getReport = context => {
  const reportFile = findFileByTypeId(context.files, fileTypeIdReport);

  return Boolean(reportFile) ? [
    ...getHeader({context, page: 'report'}),
    ...convertFileToContent(context, fileTypeIdReport)
      .map((item, index, content) => {
        const cutFirstPage = index === 0
          && getHeader({context, page: 'report'}).length > 0;
        const cutLastPage = index === content.length - 1
          && getFooter({context, page: 'report'}).length > 0;
        return (
          <div className={classnames({
            [pageStyle.pageHeight]: cutFirstPage || cutLastPage,
            [pageStyle.smallerHeight720]: cutFirstPage || cutLastPage
          })}>
            {item}
          </div>
        );
      }),
    ...getFooter({context, page: 'report'}),
  ] : [];
};

const texts = [
  <Notes/>,
  <TamaText/>,
  <PurchaseText/>,
  <ReportText/>,
  <RentText/>,
];

export const getShumaType2and3FilesList = (context) => {
  const {
    data: {
      pdfData: {
        tama,
        purchase,
        rent,
        report,
      },
    },
    files,
  } = context;

  const tamaFile = findFileByTypeId(files, fileTypeIdTama);
  const purchaseFile = findFileByTypeId(files, fileTypeIdPurchase);
  const rentFile = findFileByTypeId(files, fileTypeIdRent);
  const reportFile = findFileByTypeId(files, fileTypeIdReport);

  const noFiles = !Boolean(tamaFile)
    && !Boolean(purchaseFile)
    && !Boolean(rentFile)
    && !Boolean(reportFile);

  const assetsList = [
    Boolean(tama) && getTama(context),
    Boolean(purchase) && getPurchase(context),
    Boolean(rent) && getRent(context),
    Boolean(report) && getReport(context),
  ].filter(Boolean);

  return (noFiles) ? texts : assetsList.flat();
};

import {rightsTypeOptions} from '../constants/options';

export const getSelectedOptionLabel = (selectedValue, options) => {
  switch (typeof selectedValue) {
    case 'string':
      return getMultipleOptionsLabels(selectedValue, options);
    case 'number':
    default:
      return getSingleOptionLabel(selectedValue, options);
  }
};

const getSingleOptionLabel = (selectedValue, options) => {
  const [selectedLabel] = options
    .filter(item => item.value === selectedValue)
    .map(item => item.label);

  return selectedLabel;
};

const getMultipleOptionsLabels = (selectedValues, options) => {
  const values = prepareValues(selectedValues);
  const labels = values.map(value => {
    const selectedValue = parseInt(value, 10);
    return getSingleOptionLabel(selectedValue, options);
  });

  return labels.join(', ');
};

const prepareValues = selectedValuesStr => {
  return selectedValuesStr
    .split(',')
    .map(value => parseInt(value, 10));
};

export const getSelectedRightsType = rightsType => {
  return getSelectedOptionLabel(rightsType, rightsTypeOptions);
};

import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import classnames from 'classnames';
import CompleteInspectionPage from '../../../CompleteInspectionPage';
import * as actions from '../../_redux/actions/shumaActions';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import FieldTextareaAutosize from '../../../_common/components/FieldTextareaAutosize';
import {can} from '../../../_common/components/Permissions';
import {permissionTypes as T, subjects as S} from '../../../_common/constants/permissionSubjects';
import styles from '../../styles/styles.module.scss';
import {FormattedMessage} from "react-intl";

const PopupFinalPDFData = ({content}) => {
  const dispatch = useDispatch();
  const [rejectReason, setRejectReason] = useState(false);
  const {adminPermissionsLevel} = useSelector(
    (state) => ({
      adminPermissionsLevel: state.auth.adminPermissionsLevel,
    }),
    shallowEqual
  );

  const approveShumaFunc = (shumaID) => {
    if (adminPermissionsLevel === 1) {
      dispatch(actions.approveShumaAndRequestSignature({shumaID}));
    }
    if (adminPermissionsLevel === 2) {
      dispatch(actions.approveShuma({shumaID}));
    }
  };
  const rejectShumaFunc = (shumaID, rejectText) => {
    dispatch(actions.rejectShuma({shumaID, rejectText}));
  };

  const handleApprove = (close) => {
    approveShumaFunc(content.shumaID);
    close();
    setRejectReason(false);
  };
  const handleReject = (close, value) => {
    rejectShumaFunc(content.shumaID, value);
    close();
    setRejectReason(false);
  };

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const disabledCondition = !can(T.FULL, S.INSPECTIONS_PAGE)
    || content?.originTypeID === 3
    || adminPermissionsLevel === 3
    || Boolean(!adminPermissionsLevel);
  const showApproveRejectButtons = !(
    content?.shumaStatusTypeID === 1 ||
    content?.shumaStatusTypeID === 5 ||
    (content?.shumaStatusTypeID === 7 && adminPermissionsLevel === 1)
  );

  return (
    <>
      <Button
        className={'w-100 bg-white border-0 h-40px d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg'}
        onClick={() => handleShowModal()}
        disabled={disabledCondition}
      >
        <img
          src={toAbsoluteUrl('media/common/ZoomPlus.svg')}
          alt={'action'}
          className={'w-20px mr-4'}
        />
        <FormattedMessage
          id="inspections-column-preview"
          defaultMessage="Preview"
        />
      </Button>
      <Modal
        show={show}
        onHide={handleCloseModal}
        centered
        overlayClassName={styles.customOverlay}
      >
        <Modal.Body className={styles.customModalPreview}>
          <div className={styles.modalContentContainer}>
            <div className={styles.documentViewPort}>
              <div className={classnames(styles.iframeContainerWrapper, styles.whiteBg)}>
                <div className={styles.iframeContainer}>
                  <CompleteInspectionPage
                    cellContent={{
                      ...content,
                      closeModal: handleCloseModal
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.rejectMenu}>
              <Formik
                initialValues={{rejectReasonText: ''}}
                onSubmit={() => {}}
                enableReinitialize={true}
              >
                {({values}) => (
                  <form>
                    {!rejectReason && (
                      <div className={classnames(styles.modalButtons, `mt-6 mb-4`)}>
                        <button
                          type="button"
                          className={styles.cancelButton}
                          onClick={handleCloseModal}
                        >
                          <span>סגירה</span>
                        </button>
                        {showApproveRejectButtons && (
                          <>
                            <button
                              type="button"
                              className={styles.confirmButton}
                              onClick={() => handleApprove(handleCloseModal)}
                            >
                              <span>אישור</span>
                            </button>
                            <button
                              type="button"
                              className={styles.confirmButton}
                              onClick={() => {
                                setRejectReason(true);
                              }}
                            >
                              <span>דחייה</span>
                            </button>
                          </>
                        )}
                      </div>
                    )}
                    {rejectReason && (
                      <div>
                        <FieldTextareaAutosize
                          fieldId="rejectReasonText"
                          value={values.rejectReasonText}
                          placeholder="reason"
                        />
                        <div className={classnames(styles.modalButtons, `my-2 w-25`)}>
                          <button
                            type="button"
                            className={styles.cancelButton}
                            onClick={() => {
                              setRejectReason(false);
                            }}
                          >
                            <span>ביטול</span>
                          </button>
                          <button
                            type="button"
                            className={styles.confirmButton}
                            onClick={() => handleReject(handleCloseModal, values.rejectReasonText)}
                          >
                            <span>שליחה</span>
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PopupFinalPDFData.propTypes = {
  content: PropTypes.object
};

export default PopupFinalPDFData;

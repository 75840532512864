import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useFormik, FormikProvider} from 'formik';
import moment from 'moment';
import {
  showGlobalMessage
} from '../../_common/components/GlobalMessage/actions/showGlobalMessage';
import * as actions from '../../_common/actions/oldInspectionDetailsActions';
import {
  useStreetsContext,
} from '../../_common/components/CitiesAndStreets';
import {checkRequiredValues} from '../../../../utils/checkRequiredValues';
import {normalizeFormValues} from '../../_common/utils/normalizeFormValues';

const OldInspectionDetailsUIContext = createContext('');

export function useOldInspectionDetailsUIContext() {
  return useContext(OldInspectionDetailsUIContext);
}

export function OldInspectionDetailsUIProvider({children}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const {streets} = useStreetsContext();
  const successMessage = intl.formatMessage({
    id: 'data-sent-success',
    defaultMessage: 'Success'
  });

  const getInspectionID = () => {
    const pathName = location.pathname;
    return pathName.replace('/old-inspection-details/', '');
  };
  const shumaID = getInspectionID();

  const [loading, setLoading] = useState(false);
  const [inspectionData, setInspectionData] = useState({});

  const updateInspectionData = async (shumaID) => {
    try {
      const data = await dispatch(actions.getOldInspectionDataValues(shumaID));
      setInspectionData(data);
    } catch (error) {
      console.error(`inspectionData: ${error}`);
      setInspectionData({});
      return {};
    }
  };

  const fieldNames = {
    shumaID: 'shumaID',
    gush: 'gush',
    helka: 'helka',
    ttHelka: 'ttHelka',
    cityID: 'cityID',
    streetID: 'streetID',
    houseNumber: 'houseNumber',
    officialDate: 'officialDate',
    averageSqmPrice: 'averageSqmPrice',
    estimatePriceFinal: 'estimatePriceFinal',
    landRegisteredLotArea: 'landRegisteredLotArea',
    houseRegisteredArea: 'houseRegisteredArea',
    houseRoomCount: 'houseRoomCount',
    floor: 'floor',
    floorCount: 'floorCount',
    buildingAge: 'buildingAge',
    parking: 'parking',
    elevator: 'elevator',
    yard: 'yard',
    storage: 'storage',
    roof: 'roof',
    customers: 'customers',
  };

  const initialValues = {
    shumaID: null,
    orderID: null,
    gush: '',
    helka: '',
    ttHelka: '',
    cityID: null,
    streetID: null,
    houseNumber: '',
    officialDate: null,
    averageSqmPrice: '',
    estimatePriceFinal: '',
    landRegisteredLotArea: '',
    houseRegisteredArea: '',
    houseRoomCount: '',
    floor: '',
    floorCount: '',
    buildingAge: '',
    parking: null,
    elevator: null,
    yard: null,
    storage: null,
    roof: null,
    customers: [],
  };

  useEffect(() => {
    if (shumaID) {
      setLoading(true);
      Promise.resolve()
        .then(() => updateInspectionData(shumaID));
    }
  }, []);

  useEffect(() => {
    if (shumaID) {
      setLoading(true);
      Promise.resolve()
        .then(() => updateInspectionData(shumaID));
    }
  }, []);

  const getInitialValues = async () => {
    return {
      shumaID: inspectionData?.shumaID || shumaID || '',
      orderID: inspectionData?.orderID || '',
      gush: inspectionData?.gush || '',
      helka: inspectionData?.helka || '',
      ttHelka: inspectionData?.ttHelka || '',
      cityID: inspectionData?.cityID || null,
      streetID: inspectionData?.streetID || null,
      houseNumber: inspectionData?.houseNumber || '',
      officialDate: (inspectionData?.officialDate)
        ? moment(inspectionData.officialDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      averageSqmPrice: inspectionData?.averageSqmPrice || '',
      estimatePriceFinal: inspectionData?.estimatePriceFinal || '',
      landRegisteredLotArea: inspectionData?.landRegisteredLotArea || '',
      houseRegisteredArea: inspectionData?.houseRegisteredArea || '',
      houseRoomCount: inspectionData?.houseRoomCount || '',
      floor: inspectionData?.floor || '',
      floorCount: inspectionData?.floorCount || '',
      buildingAge: inspectionData?.buildingAge || '',
      parking: Boolean(inspectionData?.parking) || false,
      elevator: Boolean(inspectionData?.elevator) || false,
      yard: Boolean(inspectionData?.yard) || false,
      storage: Boolean(inspectionData?.storage) || false,
      roof: Boolean(inspectionData?.roof) || false,
      customers: inspectionData?.customers || [],
    };
  };

  useEffect(() => {
    setLoading(true);
    getInitialValues()
      .then(formik.setValues)
      .catch(() => setLoading(false))
      .finally(() => {
        const {cityID} = formik.values;
        const optionsAreReady = cityID && streets;
        const optionsAreMotRequired = !cityID;
        if (
          optionsAreReady
          || optionsAreMotRequired
        ) {
          setLoading(false);
        }
      });
  }, [
    JSON.stringify(inspectionData)
  ]);


  const validate = values => {
    const {gush, helka} = values;

    return checkRequiredValues({gush, helka}, {});
  };


  const editOldInspection = async (values) => {
    const {
      shumaID,
      orderID,
      gush,
      helka,
      ttHelka,
      cityID,
      streetID,
      houseNumber,
      officialDate,
      averageSqmPrice,
      estimatePriceFinal,
      landRegisteredLotArea,
      houseRegisteredArea,
      houseRoomCount,
      floor,
      floorCount,
      buildingAge,
      parking,
      elevator,
      yard,
      storage,
      roof,
      customers
    } = normalizeFormValues(values);
    const data = {
      shumaID,
      orderID,
      gush,
      helka,
      ttHelka,
      cityID,
      streetID,
      houseNumber,
      officialDate,
      averageSqmPrice,
      estimatePriceFinal,
      landRegisteredLotArea,
      houseRegisteredArea,
      houseRoomCount,
      floor,
      floorCount,
      buildingAge,
      parking,
      elevator,
      yard,
      storage,
      roof,
      customers: customers && customers.map(item => item.customerID)
    };

    try {
      const newShumaID = await actions.addEditInspectionData(data);
      await updateInspectionData(newShumaID);
      await dispatch(showGlobalMessage(successMessage));
    } catch (e) {
      console.log(e);
    }
  };


  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: () => {
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: true,
  });

  const value = {
    shumaID,
    orderID: formik.values.orderID,
    fieldNames,
    formik,
    loading,
    editOldInspection,
  };

  return (
    <OldInspectionDetailsUIContext.Provider value={value}>
      <FormikProvider value={formik}>
        {children}
      </FormikProvider>
    </OldInspectionDetailsUIContext.Provider>
  );
}

OldInspectionDetailsUIProvider.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import classnames from 'classnames';
import {convertFileToContent} from '../../FileToContentConverter';
import {findFileByTypeId} from '../../../utils/files';
import styles from './styles.module.scss';

const fileTypeID = 13;

const HeaderLine = () => (
  <div className={classnames(styles.itemTitle, styles.innerPadding)}>
    להלן תשריט קומה טיפוסית וזיהוי הנכס, מתוך תכנית חתומה להיתר:
  </div>
);

export const getTypicalFloorPlanFiles = (context) => {
  const file = findFileByTypeId(context.files, fileTypeID);
  return Boolean(file)
    ?
    convertFileToContent(context, fileTypeID, {
      firstPageHeader: <HeaderLine/>
    })
    : [];
};

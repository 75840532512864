import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {SplashScreen} from '../../../../../_metronic/_partials/controls';
import * as actions from '../../../Auth/_redux/authActions';

const PermissionsProvider = ({children}) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth?.accessToken, shallowEqual);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updatePermissions())
      .finally(() => setLoading(false));
  }, []);

  const updatePermissions = async () => {
    if (accessToken) {
      dispatch(actions.getUserPermissions());
    }
  };

  return loading ? <SplashScreen/> : children;
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
};

export default PermissionsProvider;

const defaultState = {
  orderDetailsLoading: false,
  orderDetails: {},
  error: false,
  errorMessage: null
};

export const actionTypes = {
  LOADING_ORDER_DETAILS: 'LOADING_ORDER_DETAILS',
  FETCH_ORDER_DETAILS: 'FETCH_ORDER_DETAILS',
  CATCH_ERROR: 'CATCH_ERROR'
};

export default (state= defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_ORDER_DETAILS: {
      return {
        ...state,
        orderDetailsLoading: true,
        orderDetails: {},
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.FETCH_ORDER_DETAILS: {
      const {orderDetails} = action.payload;
      return {
        ...state,
        orderDetailsLoading: false,
        orderDetails: orderDetails,
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.catchError: {
      return {
        ...state,
        error: true,
        errorMessage: `ORDER DATA: ${action.payload.error}`
      };
    }
    default: return state;
  }
};

export const actions = {
  loadingOrderDetails: data => ({
    type: actionTypes.LOADING_ORDER_DETAILS,
    payload: data
  }),
  fetchOrderDetails: data => ({
    type: actionTypes.FETCH_ORDER_DETAILS,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.CATCH_ERROR,
    payload: {error}
  }),
};

import React from 'react';
import classnames from 'classnames';
import Media from '../../Media';
import Section from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import styles from './styles.module.scss';

const fileTypeIDSatellite = 8;

const SatelliteImageWithText = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const satellite = findFileByTypeId(context.files, fileTypeIDSatellite);
  const satelliteSrc = satellite && getFullDataURI(satellite);
  const satelliteMediaType = satellite && getMediaType(satellite);

  return (
    <div ref={ref}>
      {Boolean(satellite) && (
        <>
          <Section
            customClassNames={{
              container: classnames(styles.mb0, styles.itemText, styles.bold)
            }}
          >
            להלן תצלום אוויר של החלקה וסביבתה (מתוך ממ"ג עירונית):
          </Section>
          <Media
            src={satelliteSrc}
            file={satellite}
            alt="satellite"
            className={classnames(styles.plan, styles.innerPadding)}
            type={satelliteMediaType}
            height={340}
          />
        </>
      )}
    </div>
  );
});

export default SatelliteImageWithText;

export const messages = {
  INVOICES_POPUP_SUCCESSFUL_REQUEST: "פעולה בוצעה בהצלחה",
  INVOICES_POPUP_FAILED_REQUEST: "פעולה נכשלה, נסו שנית או פנו לתמיכה",
  ADD_STREET_NAME_EXISTS: "רחוב בשם זה כבר קיים",
  GREEN_INVOICE_CREATE_CUSTOMER_ERROR: "לקוח נוצר אבל לא סונכרן לחשבונית ירוקה",
  GREEN_INVOICE_CREATE_PAYMENT_LINK_ERROR: "הפעולה נכשלה, הייתה בעיה בחשבונית ירוקה:",
  '0': 'פעולה בוצעה בהצלחה',
  '-1': 'פעולה נכשלה, נסו שנית או פנו לתמיכה',
  '-2': 'קובץ 6101 סגירה לא נוצר, אנא בידקו את תקינות כלל הנתונים ונסו שוב או פנו לתמיכה',
  '-3': 'אין מספיק מידע לבצע את הפעולה, אנא בדקו שנית או פנו לתמיכה',
};

import React from 'react';
import classnames from 'classnames';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

export const getShumaType2and3MortgageEntityEmphasis = (context) => {
  const {mortgageEntityEmphasis} = context.data.pdfData;

  let firstLineText;
  let restChunks = [];

  if (Boolean(mortgageEntityEmphasis)) {
    [firstLineText, ...restChunks] = getTextChunks(mortgageEntityEmphasis);
  }

  return Boolean(mortgageEntityEmphasis)
    ? [
      <div className={classnames(styles.itemText, styles.innerPadding)} key={0}>
        <span className={styles.bold}>הדגשים לגוף המשעבד: </span>
        <span>{Boolean(firstLineText) ? ` ${firstLineText}` : ''}</span>
      </div>,
      ...restChunks
        .map(mapTextChunks)
        .map((item, index, array) => (
          <div
            className={classnames(styles.itemText, styles.innerPadding, {
              [styles.lastSectionLine]: index === array.length - 1
            })}
            key={index + 1}
          >
            {item}
          </div>
        ))
    ]
    : [];
};

import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import OldInspectionDetails from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const oldInspectionDetailsRoute = (
  <ProtectedRoute
    subject={S.OLD_INSPECTION_DETAILS_PAGE}
    path={P.OLD_INSPECTION_DETAILS}
    component={OldInspectionDetails}
  />
);

import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as actions from '../../../actions/SectionFilesActions';
import {FileList} from "./FileList/FileList";
import {DropdownButton} from "./DropdownButton";
import {ItemUploadFile} from './ItemUploadFile';
import PopupSelectOrderFile from "./PopupSelectOrderFile";
import {uploadFileTypes} from "../../../../_common/constants/uploadFileTypes";
import styles from "../../styles.module.scss";


export function SectionFiles({shumaID, orderID}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const header = intl.formatMessage({
    id: 'header-files',
    defaultMessage: 'Files',
  });
  const upload = intl.formatMessage({
    id: 'upload-file',
    defaultMessage: 'Upload file',
  });
  const choose = intl.formatMessage({
    id: "choose-file-from-order",
    defaultMessage: "Choose file from order",
  });

  const fileList = useSelector(
    (state) => state.getFileList.fileList,
    shallowEqual
  );

  useEffect(() => {
    if (shumaID !== '-1') dispatch(actions.getFileList(shumaID))
  }, []);

  const getItemUploadFile = (item) => {

    return (
      <ItemUploadFile
        shumaID={shumaID}
        label={
          <FormattedMessage
            id={`inspection-details-page-dropdown-file-${item.fileTypeID}`}
            defaultMessage="File"
          />
        }
        fileTypeID={item.fileTypeID}
      />
    )
  };
  const getItemPopupOrderFiles = (item) => {

    return (
      <PopupSelectOrderFile
        label={item.fileTypeID}
        orderID={orderID}
        shumaID={shumaID}
        fileTypeID={item.fileTypeID}
      />
    )
  };

  return (
    <div className={styles.sectionFilesContainer}>
      <h4 className={styles.filesHeader}>{header}</h4>
      <div className={styles.buttonsContainer}>
        <DropdownButton
          icon={'../../../media/common/Upload.svg'}
          buttonLabel={upload}
          items={uploadFileTypes.map(getItemUploadFile)}
          disabled={shumaID === '-1'}
        />
        <DropdownButton
          icon={'../../../media/common/Upload.svg'}
          buttonLabel={choose}
          items={uploadFileTypes.map(getItemPopupOrderFiles)}
          disabled={shumaID === '-1' || Boolean(!orderID)}
        />
      </div>
      <FileList
        gotFiles={fileList.length > 0 && shumaID !== '-1'}
        fileList={fileList}
        shumaID={shumaID}
      />
    </div>
  );
}

import {actions as A} from '../../../constants/actions';

const showGlobalMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: A.SHOW_GLOBAL_MESSAGE,
      payload: {message}
    });
  }

};


export {showGlobalMessage}
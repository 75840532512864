const defaultState = {
  listLoading: false,
  pageCount: 0,
  currentPageNumber: 0,
  entities: [],
  totalResult: 0,
  error: null,
};
export const actionTypes = {
  ordersFetched: 'ORDERS_FETCHED',
  ordersAppended: 'ORDERS_APPENDED',
  catchError: 'CATCH_ERROR'
};

export default (state= defaultState, action) => {
  switch (action.type) {
    case actionTypes.ordersFetched: {
      const {totalPageCount, currentPageNumber, orders, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: orders,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.ordersAppended: {
      const {totalPageCount, currentPageNumber, orders, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...orders]
          : [...orders],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.CatchError: {
      const error = `ORDERS: ${action.payload.error}`;
      return {...state, error};
    }
    default: return state;
  }
};

export const actions = {
  ordersFetched: (data) => ({
    type: actionTypes.ordersFetched,
    payload: data
  }),
  ordersAppended: (data) => ({
    type: actionTypes.ordersAppended,
    payload: data
  }),
  catchError: (error) => ({type: actionTypes.catchError, payload: {error}}),
};

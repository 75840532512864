const defaultState = {
  fileList: [],
  loading: false,
  error: false,
  errorMessage: null
};

export const actionTypes = {
  fileListFetched: 'FILE_LIST_FETCHED',
  catchError: 'CATCH_ERROR'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.fileListFetched: {
      const {fileList} = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        fileList
      };
    }

    case actionTypes.catchError: {
      const error = `FILE LIST: ${action.payload.error}`;
      return {...state, error};
    }

    default: return state;
  }
}

export const actions = {
  fileListFetched: (data) => ({
    type: actionTypes.fileListFetched,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: {error}
  }),
};


import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';
import classnames from 'classnames';
import {useCustomerDetailsUIContext} from '../CustomerDetailsUIContext';
import giIcon from '../../assets/greenInvoice.png';
import styles from './styles.module.scss';

const ButtonCreateClient = () => {
  const {
    createClient: {
      createGreenInvoiceClientForCurrentCustomer,
      creatingClient,
    }
  } = useCustomerDetailsUIContext();

  const handleClick = () => {
    if (!creatingClient) {
      createGreenInvoiceClientForCurrentCustomer();
    }
  }

  return (
    <Button
      type="button"
      className={classnames('d-flex justify-content-between align-items-center text-dark-75 mt-4 w-25', styles.button)}
      disabled={creatingClient}
      onClick={handleClick}
    >
      <img src={giIcon} alt="green-invoice-icon" className={styles.icon}/>
      <div className="w-100">
        <FormattedMessage
          id="green-invoice-create-client-button"
          defaultMessage="Create client"
        />
      </div>
    </Button>
  );
};

export default ButtonCreateClient;

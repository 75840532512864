import React from 'react';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import GridTasksList from './GridTasksList';
import Empty from '../../../Empty';
import PopupCreateEditTask from '../PopupCreateEditTask/PopupCreateEditTask';
import Spinner from '../../../Spinner';
import {useGridTasksContext} from '../../gridTasksContext';
import {taskTypeIDs} from '../../../../constants/taskTypes';
import styles from './styles.module.scss';

const GridTasks = () => {
  const {
    loadingList,
    tasksList,
    createTask,
    updateTasksList,
    resetTaskDetails,
    disabled
  } = useGridTasksContext();
  const intl = useIntl();
  const tasksHeader = intl.formatMessage({
    id: 'tab-order-inspections-tasks-header',
    defaultMessage: 'Tasks',
  });
  const gridHeaderLabelCreateDate = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-header-createDate',
    defaultMessage: 'Create date',
  });
  const gridHeaderLabelTaskType = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-header-taskType',
    defaultMessage: 'Task type',
  });
  const gridHeaderLabelStatus = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-header-status',
    defaultMessage: 'Status',
  });
  const gridHeaderLabelDueDate = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-header-dueDate',
    defaultMessage: 'Due date',
  });
  const gridHeaderLabelEditTask = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-header-editTask',
    defaultMessage: 'Edit task',
  });
  const gridHeaderLabelDeleteTask = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-header-deleteTask',
    defaultMessage: 'Delete task',
  });
  const gridLabelNoTasks = intl.formatMessage({
    id: 'tab-order-inspections-tasks-grid-no-tasks',
    defaultMessage: 'No tasks',
  });

  const tasksGridHeaders = [
    '#',
    gridHeaderLabelCreateDate,
    gridHeaderLabelTaskType,
    gridHeaderLabelStatus,
    gridHeaderLabelDueDate,
    gridHeaderLabelEditTask,
    gridHeaderLabelDeleteTask,
  ];

  const mapTasksHeaders = (text, index) => {
    return (
      <div className={styles.headerCell} key={index}>
        {text}
      </div>
    );
  };

  const handleCreateTask = taskTypeID => async () => {
    await createTask(taskTypeID);
    await updateTasksList();
  };

  return (
    <div className={styles.tasksUnit}>
      <h4 className={styles.header}>{tasksHeader}</h4>
      <div className={styles.tasksCreateButtons}>
        <PopupCreateEditTask
          taskType={taskTypeIDs.visit}
          onEdit={handleCreateTask(taskTypeIDs.visit)}
          onOpen={resetTaskDetails}
          disabled={disabled}
        />
        <PopupCreateEditTask
          taskType={taskTypeIDs.general}
          onEdit={handleCreateTask(taskTypeIDs.general)}
          onOpen={resetTaskDetails}
          disabled={disabled}
        />
      </div>
      <div
        className={classnames(styles.tasksHeader, {
          'pr-8': tasksList.length > 5
        })}
      >
        {tasksGridHeaders.map(mapTasksHeaders)}
      </div>
      <div>
        {loadingList && <Spinner/>}
        {(tasksList.length === 0)
          ? (
            <Empty
              message={gridLabelNoTasks}
              customContainerStyles={styles.emptyContainer}
            />
          )
          : <GridTasksList/>
        }
      </div>
    </div>
  );
};

export default GridTasks;

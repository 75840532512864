import axios from 'axios';
import {actions} from '../reducers/OrderDetailsReducer';

export const CREATE_ORDER = '/orders/createEditOrder';
export const GET_ORDER_BY_ID = '/orders/getOrderByID';

export const createOrder = ({
                              orderID,
                              customers,
                              usingLawyer,
                              lawyerName,
                              contactFullName,
                              contactPhone,
                              contactJob,
                              contactOrganisation,
                              contactEmail,
                              contactAddress,
                              shumaForOrderCount,
                              dueDate,
                              responsibleUser,
                              priceQuoteID
                            }) => (dispatch) => {
  const data = {
    orderID,
    customers,
    usingLawyer,
    lawyerName,
    contactFullName,
    contactPhone,
    contactJob,
    contactOrganisation,
    contactEmail,
    contactAddress,
    shumaForOrderCount,
    dueDate,
    responsibleUser,
    priceQuoteID
  };

  return axios.post(CREATE_ORDER, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      const {orderID} = body;

      return orderID
    })
};

export const getOrderByID = (orderID) => (dispatch) => {
  dispatch(actions.loadingOrderDetails({orderDetails: {}}));

  return axios.post(GET_ORDER_BY_ID, {orderID})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.fetchOrderDetails({orderDetails: body}));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t get order data';
      dispatch(actions.catchError({error}));
    });
};

import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import CommonButton from '../../../CommonButton';
import {TaskGeneral} from './TaskGeneral';
import TaskVisit from './TaskVisit';
import {useGridTasksContext} from '../../gridTasksContext';
import {taskTypeIDs} from '../../../../constants/taskTypes';

const PopupContent = ({taskID, taskType, onClose, onSubmit}) => {
  const intl = useIntl();
  const labelButtonClose = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-close',
    defaultMessage: 'Close',
  });
  const labelButtonSubmit = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-submit',
    defaultMessage: 'Submit',
  });
  const headerCreateGeneral = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-label-general',
    defaultMessage: 'Create mission general',
  });
  const headerCreateVisit = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-label-visit',
    defaultMessage: 'Create mission visit',
  });
  const headerEdit = intl.formatMessage({
      id: 'tab-order-inspections-tasks-button-label-editTask',
      defaultMessage: `Edit task ${taskID}`
    },
    {id: taskID}
  );
  const getHeader = () => {
    if (taskID === -1) {
      return taskType === taskTypeIDs.general
        ? headerCreateGeneral
        : headerCreateVisit;
    } else return headerEdit;
  };

  const {formik} = useGridTasksContext();
  const {isValid, dirty, setFieldValue} = formik;
  useEffect(() => {
    setFieldValue('taskTypeID', taskType)
  }, []);

  return (
    <div className="d-flex justify-content-center flex-column py-4 px-14">
      {(taskType === taskTypeIDs.general)
        ? <TaskGeneral header={getHeader()}/>
        : <TaskVisit header={getHeader()}/>
      }
      <div className="d-flex justify-content-between py-10">
        <CommonButton
          variant={'button'}
          label={labelButtonClose}
          onClick={onClose}
        />
        <CommonButton
          variant={'submit'}
          label={labelButtonSubmit}
          onClick={onSubmit}
          disabled={!(isValid && dirty)}
        />
      </div>
    </div>
  );
};

const PopupCreateEditTask = ({
                               taskType,
                               taskID = -1,
                               onOpen,
                               onEdit,
                               disabled
                             }) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = async () => {
    setShow(true);
    if (onOpen) {
      await onOpen(taskID);
    }
  };

  const intl = useIntl();
  const labelVisit = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-label-visit',
    defaultMessage: 'Create mission visit',
  });
  const labelGeneral = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-label-general',
    defaultMessage: 'Create mission general',
  });

  const handleEdit = async () => {
    if (onEdit) {
      await onEdit();
    }
    handleCloseModal();
  };

  const getLabel = () => {
    if (taskID === -1) {
      return (taskType === taskTypeIDs.general) ? labelGeneral : labelVisit;
    } else {
      return (
        <img
          src={'../../../media/common/Edit.svg'}
          alt="edit"
          className="w-20px h-20px"
        />
      );
    }
  };

  return (
    <>
      <CommonButton
        variant={'submit'}
        customButtonClassNames={`${(taskID === -1) ? 'w-200px mr-4' : 'w-40px d-flex justify-content-center'}`}
        label={getLabel()}
        onClick={handleShowModal}
        disabled={disabled}
      />
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <PopupContent
            taskID={taskID}
            taskType={taskType}
            onClose={handleCloseModal}
            onSubmit={handleEdit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PopupCreateEditTask;

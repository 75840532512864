import {FormattedMessage} from "react-intl";
import React from "react";

export const getStatusName = (status) => {
  switch (status) {
    case 0:
      return (
        <FormattedMessage
          id="customer-details-documentInfo-label-status-0"
          defaultMessage="0"
        />
      );
    case 1:
      return (
        <FormattedMessage
          id="customer-details-documentInfo-label-status-1"
          defaultMessage="1"
        />
      );
    case 2:
      return (
        <FormattedMessage
          id="customer-details-documentInfo-label-status-2"
          defaultMessage="2"
        />
      );
    case 3:
      return (
        <FormattedMessage
          id="customer-details-documentInfo-label-status-3"
          defaultMessage="3"
        />
      );
    case 4:
      return (
        <FormattedMessage
          id="customer-details-documentInfo-label-status-4"
          defaultMessage="4"
        />
      );
    default:
      return null
  }
};

import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {
  InspectionDetailsUIProvider
} from '../components/InspectionsDetailsUIContext';
import {
  InspectionDetailsTasksProvider
} from '../components/InspectionsDetailsTasksContext';
import {
  StreetsProvider
} from '../../_common/components/CitiesAndStreets';
import {
  DecisionTreeShumaTypesOptionsContextProvider
} from '../../_common/components/DecisionTreeOptions';
import InspectionDetails from '../components/InspectionDetails';
import {useHeader} from '../../../../_metronic/layout';

const InspectionDetailsPage = () => {
  const header = useHeader();
  const intl = useIntl();
  const location = useLocation();
  const inspectionDetailsHeaderEdit = intl.formatMessage({
    id: 'inspection-details-page-header',
    defaultMessage: 'Edit inspection',
  });
  const pageHeader = [inspectionDetailsHeaderEdit, location.state.firmShumaID].filter(Boolean).join(' - ');
  header.setTitle(pageHeader);

  return (
    <StreetsProvider>
      <DecisionTreeShumaTypesOptionsContextProvider>
        <InspectionDetailsUIProvider>
          <InspectionDetailsTasksProvider>
            <InspectionDetails/>
          </InspectionDetailsTasksProvider>
        </InspectionDetailsUIProvider>
      </DecisionTreeShumaTypesOptionsContextProvider>
    </StreetsProvider>
  );
};

export default InspectionDetailsPage;

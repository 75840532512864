import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import * as _ from 'lodash';
import Media from '../../Media';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {fileTypeId} from '../../../constants/fileTypeId';
import {
  completeCertOptions,
  dangerousStructureOptions,
  exceedBuildingOptions,
  exceedUsePermitOptions,
  form4Options,
  supervisionFileOptions,
} from '../../../constants/options';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import {checkIfTextEndsWithDot, getBreakLinesTextShumaType3} from '../../../utils/texts';
import {formattingDate} from '../../../utils/formatting';
import {getSelectedOptionLabel} from '../../../utils/options';
import styles from './styles.module.scss';
import {convertFileToContent} from '../../FileToContentConverter';

export const exceedUsePermitText = 'על פי הממצאים קיים היתר לשימוש חורג';
export const exceedBuildingText = 'על פי הממצאים קיימות חריגות בנייה';

const HeadLine1 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <HeadContent/>
      <SubHeaderLine2Content/>
    </div>
  );
});

const HeadContent = () => {
  const {
    data: {
      pdfData: {
        validLocalPlansDetails,
        countryStateData,
        plansInProgress,
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
        completeCert,
        completeCertText,
        dangerousStructure,
        dangerousStructureText
      },
      files
    },
    sectionNumbersShumaType3,
  } = useCompleteInspectionContext();
  const showHeader10 = _.some([
    findFileByTypeId(files, fileTypeId.PLANS_IN_EFFECT),
    validLocalPlansDetails,
    countryStateData,
    plansInProgress,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    additionalBuildPermitText,
    supervisionFile,
    exceedUsePermit,
    exceedBuilding,
    housePermitMismatchText,
    form4,
    form4Text,
    completeCert,
    completeCertText,
    dangerousStructure,
    dangerousStructureText,
    findFileByTypeId(files, fileTypeId.PERMIT_BLUEPRINT)
  ]);

  return showHeader10 && (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
          <span className={classnames(styles.textUnderline, styles.bold)}>
            <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section10}. `}</span>
            <span>מצב תכנוני</span>
          </span>
    </div>
  );
};

const Head = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <HeadContent/>
    </div>
  );
});

const Line1Image = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const file = findFileByTypeId(context.files, fileTypeId.PLANS_IN_EFFECT);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return (
    <div ref={ref}>
      {file && (
        <div className={classnames(styles.sectionContainer)}>
          <div
            className={classnames(
              styles.textRegular,
              styles.innerPadding,
              styles.bold
            )}
          >
            קובץ תכניות בתוקף:
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrc}
              file={file}
              alt="file"
              className={styles.mb3}
              type={mediaType}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const HeaderLine1 = () => (
  <>
    <HeadContent/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ תכניות בתוקף:
    </div>
  </>
);

const getLine1Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.PLANS_IN_EFFECT);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.PLANS_IN_EFFECT, {
      firstPageHeader: <HeaderLine1/>
    })
    : [<HeadLine1/>];
};

const SubHeaderLine2Content = () => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
        completeCert,
        completeCertText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <>
      <SubHead1Content/>
      <Line2Content/>
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
      ]) && <Line3Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
      ]) && <Line4Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
      ]) && <Line5Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
      ]) && <Line6Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
      ]) && <Line7Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
      ]) && <Line8Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
      ]) && <Line9Content/>}
      {!_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
        completeCert,
        completeCertText,
      ]) && <Line10Content/>}
    </>
  );
};

const SubHeaderLine2 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const file = findFileByTypeId(context.files, fileTypeId.PLANS_IN_EFFECT);

  return Boolean(file) && (
    <div ref={ref}>
      <SubHeaderLine2Content/>
    </div>
  );
});

const SubHead1Content = () => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
        completeCert,
        completeCertText,
        dangerousStructure,
        dangerousStructureText
      }
    },
  } = useCompleteInspectionContext();
  const showSubHeaderCondition =
    _.some([
      buildPermitNumber,
      buildPermitDate,
      buildPermitDetails,
      additionalBuildPermitText,
      supervisionFile,
      exceedUsePermit,
      exceedBuilding,
      housePermitMismatchText,
      form4,
      form4Text,
      completeCert,
      completeCertText,
      dangerousStructure,
      dangerousStructureText,
    ]);

  return showSubHeaderCondition && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.innerPadding,
        styles.textUnderline,
        styles.bold
      )}
      id={SubHead1Content}
    >
      רישוי
    </div>
  );
};

const Line2Content = () => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
      },
    },
  } = useCompleteInspectionContext();

  const text = [
    Boolean(buildPermitNumber) ? 'מס\' היתר: ' : '',
    Boolean(buildPermitNumber) ? `${buildPermitNumber}, ` : '',
    Boolean(buildPermitDate) ? 'מתאריך: ' : '',
    Boolean(buildPermitDate) ? `${formattingDate(buildPermitDate)}, ` : '',
    Boolean(buildPermitDetails) ? 'מהות היתר: ' : '',
    ...getBreakLinesTextShumaType3(buildPermitDetails)
  ];

  return _.some([buildPermitNumber, buildPermitDate, buildPermitDetails]) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>היתרי בנייה מקורי - </span>
      <span>{text}</span>
    </div>
  );
};

const Line3Content = () => {
  const {
    data: {
      pdfData: {
        additionalBuildPermitText
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(additionalBuildPermitText) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>היתרים נוספים - </span>
      <span>{getBreakLinesTextShumaType3(additionalBuildPermitText)}</span>
    </div>
  );
};

const Line3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
      ]) && <Line3Content/>}
    </div>
  );
});

const Line4Content = () => {
  const {
    data: {
      pdfData: {
        supervisionFile
      },
    },
  } = useCompleteInspectionContext();
  const supervisionFileValue = getSelectedOptionLabel(supervisionFile, supervisionFileOptions);

  return Boolean(supervisionFile) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>תיק פיקוח - </span>
      <span>{supervisionFileValue}{checkIfTextEndsWithDot(supervisionFileValue)}</span>
    </div>
  );
};

const Line4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
      ]) && <Line4Content/>}
    </div>
  );
});

const Line5Content = () => {
  const {
    data: {
      pdfData: {
        exceedUsePermit
      },
    },
  } = useCompleteInspectionContext();
  const exceedUsePermitValue = exceedUsePermit === 1
    ? exceedUsePermitText
    : getSelectedOptionLabel(exceedUsePermit, exceedUsePermitOptions);

  return Boolean(exceedUsePermit) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>היתר לשימוש חורג - </span>
      <span>{exceedUsePermitValue}{checkIfTextEndsWithDot(exceedUsePermitValue)}</span>
    </div>
  );
};

const Line5 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
      ]) && <Line5Content/>}
    </div>
  );
});

const Line6Content = () => {
  const {
    data: {
      pdfData: {
        exceedBuilding
      },
    },
  } = useCompleteInspectionContext();
  const exceedBuildingValue = exceedBuilding === 1
    ? exceedBuildingText
    : getSelectedOptionLabel(exceedBuilding, exceedBuildingOptions);

  return Boolean(exceedBuilding) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>חריגות בנייה - </span>
      <span>{exceedBuildingValue}{checkIfTextEndsWithDot(exceedBuildingValue)}</span>
    </div>
  );
};

const Line6 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
      ]) && <Line6Content/>}
    </div>
  );
});

const Line7Content = () => {
  const {
    data: {
      pdfData: {
        housePermitMismatchText
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(housePermitMismatchText) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>הערות כלליות - </span>
      <span>{getBreakLinesTextShumaType3(housePermitMismatchText)}</span>
    </div>
  );
};

const Line7 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
      ]) && <Line7Content/>}
    </div>
  );
});

const Line8Content = () => {
  const {
    data: {
      pdfData: {
        form4,
        form4Text
      },
    },
  } = useCompleteInspectionContext();
  const form4Value = getSelectedOptionLabel(form4, form4Options);
  const text = [
    Boolean(form4) ? `${form4Value}` : '',
    Boolean(form4Text) ? `, ${form4Text}${checkIfTextEndsWithDot(form4Text)}` : '',
  ];

  return _.some([form4, form4Text]) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>טופס 4: </span>
      <span>{text}</span>
      <span>{Boolean(form4Text) ? '' : '.'}</span>
    </div>
  );
};

const Line8 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
      ]) && <Line8Content/>}
    </div>
  );
});

const Line9Content = () => {
  const {
    data: {
      pdfData: {
        completeCert,
        completeCertText
      },
    },
  } = useCompleteInspectionContext();
  const completeCertValue = getSelectedOptionLabel(completeCert, completeCertOptions);
  const text = [
    Boolean(completeCert) ? `${completeCertValue}` : '',
    Boolean(completeCertText) ? ', ' : '.',
    ...getBreakLinesTextShumaType3(completeCertText),
  ];

  return _.some([completeCert, completeCertText]) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>תעודת גמר: </span>
      <span>{text}</span>
    </div>
  );
};

const Line9 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
      ]) && <Line9Content/>}
    </div>
  );
});

const Line10Content = () => {
  const {
    data: {
      pdfData: {
        dangerousStructure,
        dangerousStructureText
      },
    },
  } = useCompleteInspectionContext();
  const dangerousStructureValue = getSelectedOptionLabel(dangerousStructure, dangerousStructureOptions);
  const text = [
    Boolean(dangerousStructure) ? `${dangerousStructureValue}` : '',
    Boolean(dangerousStructureText) ? `, ${dangerousStructureText}${checkIfTextEndsWithDot(dangerousStructureText)}` : '',
  ];

  return _.some([dangerousStructure, dangerousStructureText]) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>תעודת גמר: </span>
      <span>{text}</span>
      <span>{Boolean(dangerousStructureText) ? '' : '.'}</span>
    </div>
  );
};

const Line10 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
        completeCert,
        completeCertText,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {_.some([
        buildPermitNumber,
        buildPermitDate,
        buildPermitDetails,
        additionalBuildPermitText,
        supervisionFile,
        exceedUsePermit,
        exceedBuilding,
        housePermitMismatchText,
        form4,
        form4Text,
        completeCert,
        completeCertText,
      ]) && <Line10Content/>}
    </div>
  );
});

const Line11ImageContent = () => {
  const context = useCompleteInspectionContext();

  const file = findFileByTypeId(context.files, fileTypeId.PERMIT_BLUEPRINT);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);

  return Boolean(file) && (
    <div className={classnames(styles.sectionContainer)}>
      <div
        className={classnames(
          styles.textRegular,
          styles.innerPadding,
          styles.bold
        )}
      >
        תשריט הדירה מתוך היתר הבניה
      </div>
      <div className={styles.mediaContainer}>
        <Media
          src={imageSrc}
          file={file}
          alt="file"
          className={classnames(styles.mb3, styles.noPaddingRight)}
          type={mediaType}
        />
      </div>
    </div>
  );
};

const Line11Image = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <Line11ImageContent/>
    </div>
  );
});

const HeaderLine11 = () => (
  <>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      תשריט הדירה מתוך היתר הבניה
    </div>
  </>
);

const getLine11Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.PERMIT_BLUEPRINT);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.PERMIT_BLUEPRINT, {
      firstPageHeader: <HeaderLine11/>
    })
    : [];
};

export {
  getLine1Files,
  getLine11Files,
};

export default {
  Head,
  SubHeaderLine2,
  Line1Image,
  Line3,
  Line4,
  Line5,
  Line6,
  Line7,
  Line8,
  Line9,
  Line10,
  Line11Image,
};

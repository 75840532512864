import React from 'react';
import Section from '../../Section';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {findFileByTypeId} from '../../../utils/files';
import {convertFileToContent} from '../../FileToContentConverter';
import getValidLocalPlansDetailsSection from './ValidLocalPlansDetailsSection';
import getCountryStateDataSection from './CountryStateDataSection';
import getPlansInProgressSection from './PlansInProgressSection';
import {getCompleteCert, getDangerousStructure, getForm4} from './Page19AttachedFiles';
import styles from './styles.module.scss';
import * as _ from 'lodash';

const fileTypeID = 14;

export const Section7TitleAndSubtitle = () => {
  const context = useCompleteInspectionContext();
  const {noteNumbers, sectionNumbers} = context;
  const {
    supervisionFile,
    exceedUsePermit,
    exceedBuilding,
    housePermitMismatchText,
    buildPermitNumber,
    buildPermitDate,
    buildPermitReason,
    buildPermitDetails,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
  } = context.data.pdfData;
  const fileSection7 = findFileByTypeId(context.files, fileTypeID);
  const someSection7TextsExist = _.some([
    supervisionFile,
    exceedUsePermit === 1,
    exceedBuilding === 1,
    housePermitMismatchText,
    fileSection7,
    getValidLocalPlansDetailsSection(context).length > 0,
    getCountryStateDataSection(context).length > 0,
    getPlansInProgressSection(context).length > 0,
    buildPermitNumber,
    buildPermitDate,
    buildPermitDetails,
    buildPermitReason,
    additionalBuildPermitText,
    form4,
    completeCert,
    dangerousStructure,
    getForm4(context).length > 0,
    getCompleteCert(context).length > 0,
    getDangerousStructure(context).length > 0,
  ]);

  return (someSection7TextsExist) && (
    <Section title={(
      <>
        <span>{sectionNumbers.section7}</span>
        <span>. הרקע התכנוני</span>
        <NoteNumber id={'section7'}>{noteNumbers.section7}</NoteNumber>
      </>
    )}>
      {Boolean(fileSection7) && (
        <div className={styles.itemText}>
          להלן ריכוז הנתונים המופיעים בדף המידע:
        </div>
      )}
    </Section>
  );
};

export const getSection7 = (context) => {
  const file = findFileByTypeId(context.files, fileTypeID);

  return Boolean(file)
    ? convertFileToContent(context, fileTypeID, {
      firstPageHeader: <Section7TitleAndSubtitle/>,
    })
    : [<Section7TitleAndSubtitle key={0}/>];
};

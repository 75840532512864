export const documentTypes = {
  PRICE_QUOTE: 10,
  WORK_ORDER: 100,
  RETURN_CERTIFICATE: 210,
  TRANSACTION_ACCOUNT: 300,
  INVOICE: 305,
  INVOICE_RECEIPT: 320,
  CREDIT_INVOICE: 330,
  RECEIPT: 400,
  DONATION_RECEIPT: 405,
  PURCHASE_ORDER: 500,
  RECEIVING_DEPOSIT: 600,
  WITHDRAWAL_DEPOSIT: 610,
};

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {houseFinishGradeOptions,} from '../../../constants/options';
import styles from './styles.module.scss';

const Section6Text711 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {houseFinishGrade} = context.data.pdfData;
  const houseFinishGradeValue = getSelectedOptionLabel(houseFinishGrade, houseFinishGradeOptions);

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>רמת גמר: </span>
      <span data-testid="houseFinishGradeValue">
        {houseFinishGradeValue}
      </span>.
    </div>
  );
});

export default Section6Text711;

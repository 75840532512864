import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import classnames from 'classnames';
import styles from '../styles.module.scss';

const ShumaType3BuildingMatchingEnvironment = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  let buildingMatchingEnvironmentValue = getSelectedOptionLabel(context.data.pdfData.buildingMatchingEnvironment, options.buildingMatchingEnvironmentOptions);
  if ((context.data.pdfData.buildingMatchingEnvironment === 1) && context.data.pdfData.buildingMatchingEnvironmentText) {
    buildingMatchingEnvironmentValue = `${buildingMatchingEnvironmentValue}, ${context.data.pdfData.buildingMatchingEnvironmentText}`;
  }

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingMatchingEnvironment) && (
        <div className={classnames(styles.innerPadding, styles.itemText)}>
          <span className={styles.bold}>
            האם הבניין תואם/חורג מסביבתו:
          </span>
          <span>{` ${buildingMatchingEnvironmentValue}.`}</span>
        </div>
      )}
    </div>
  );
});

export default ShumaType3BuildingMatchingEnvironment;

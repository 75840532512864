import React from 'react';
import classnames from 'classnames';
import {checkIfTrimmedTextEndsWithDot, getTextChunks, mapTrimmedTextChunks} from '../../../utils/texts';
import styles from '../styles.module.scss';

export const getPropertyRightsDetails = (context) => {
  const {propertyRightsDetails} = context.data.pdfData;

  let firstTextLine;
  let restLines = [];
  if (Boolean(propertyRightsDetails)) {
    [firstTextLine, ...restLines] = getTextChunks(propertyRightsDetails);
  }

  return [
    <div className={classnames(styles.itemText)} key={0}>
      {Boolean(firstTextLine) && ` ${firstTextLine}${checkIfTrimmedTextEndsWithDot(firstTextLine)}`}
    </div>,
    ...restLines.map(mapTrimmedTextChunks).map((item, index) => (
      <div
        className={classnames(styles.itemText)}
        key={index + 1}
      >
        {item}
      </div>
    ))
  ];
};

export const checkRequiredValues = (values, errors = {}) => {
  const required = {};

  for (let key in values) {
    const input = values[key];
    const emptyInput = !Boolean(input);
    const emptyValue = !emptyInput && (typeof input === 'object') && ('value' in input) && !isValueExist(input.value);
    const invalidField = emptyInput || emptyValue;
    if (values.hasOwnProperty(key) && invalidField) required[key] = 'required field';
  }

  return {
    ...errors,
    ...required
  }
};

const isValueExist = value => {
  return value !== undefined && value !== null;
}

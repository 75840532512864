import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {fileTypeId} from '../../../constants/fileTypeId';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import Media from '../../Media';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import {
  buildingElevatorOptions,
  buildingIntercomOptions,
  buildingLocationOptions,
  buildingMaintenanceOptions,
  buildingMatchingEnvironmentOptions,
  buildingOnPillarsOptions,
  buildingParkingOptions,
  buildingPhysicalStateOptions,
  buildingPopulatedOptions,
  buildingRoofTypeOptions,
  buildingTypeIdOptions,
  buildPeriodOptions,
  buildTypeOptions,
  facingOptions,
  parkingIssuesTimesOptions,
  streetLocationOptions,
  streetTrafficOptions,
  streetTypeOptions
} from '../../../constants/options';
import {
  checkIfTextEndsWithDot,
  getBreakLinesTextShumaType3,
  getBuildingBuildTypeValue,
  getBuildingOuterLayerValue,
  getLandSurroundingsLabel,
} from '../../../utils/texts';
import {propertyTypesGroups as PT} from '../../../constants/propertyTypesGroups';
import {convertFileToContent} from '../../FileToContentConverter';
import styles from './styles.module.scss';

const HeadLine1 = React.forwardRef(({}, ref) => {

  return (
    <div ref={ref}>
      <HeadContent/>
      <SubHead1Content/>
      <Line1Content/>
    </div>
  );
});

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
      <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section8}. `}</span>
      <span>תיאור כללי של הסביבה והבניין</span>
      </span>
    </div>
  );
};

const SubHead1Content = () => {

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}/>
        <span>8.1 </span>
        <span>מאפייני הסביבה</span>
      </span>
    </div>
  );
};

const Line1Content = () => {
  const {
    data: {
      pdfData: {
        propertyLocationDesc,
        areaLimits
      },
    },
  } = useCompleteInspectionContext();
  const gotContent = _.some([propertyLocationDesc, areaLimits]);

  const text = [
    Boolean(propertyLocationDesc) ? `${propertyLocationDesc}` : '',
    (Boolean(propertyLocationDesc) && Boolean(areaLimits)) ? ', ' : '',
    Boolean(areaLimits) ? 'באזור התחום ב' : '',
    Boolean(areaLimits) ? `${areaLimits}` : '', gotContent ? '.' : '',
  ].filter(Boolean).join('');

  return gotContent && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>אופי הסביבה: </span>
      <span>{text}</span>
    </div>
  );
};

const Line2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        streetMainUsage,
        buildPeriod,
        buildType,
        areaUniqueFeatures,
        envDevelopmentLevel
      },
    },
  } = useCompleteInspectionContext();
  const buildPeriodText = getSelectedOptionLabel(buildPeriod, buildPeriodOptions);
  const buildTypeText = getSelectedOptionLabel(buildType, buildTypeOptions);

  const text = [
    Boolean(streetMainUsage) ? `${streetMainUsage}` : '',
    Boolean(buildPeriod) ? `${buildPeriodText}` : '',
    Boolean(buildType) ? ` הבנייה הינה ${buildTypeText}` : '',
    Boolean(areaUniqueFeatures) ? ` לצד` : '',
  ].filter(Boolean).join(', ');

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
      ref={ref}
    >
      <span>הסביבה מאופיינת ברובה ב</span>
      <span>{text}</span>
      {Boolean(areaUniqueFeatures)
        ? <span> {getBreakLinesTextShumaType3(areaUniqueFeatures)}</span>
        : <span>.</span>
      }
      <span> {getBreakLinesTextShumaType3(envDevelopmentLevel)}</span>
    </div>
  );
});

const Line3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingLocation,
        propertyTypeName,
        facing,
        streetName,
        cityName,
        streetLocation,
        streetType,
        streetTraffic,
        parkingIssuesTimes,
        publicTransport
      },
    },
  } = useCompleteInspectionContext();
  const buildingLocationValue = getSelectedOptionLabel(buildingLocation, buildingLocationOptions);
  const facingValue = getSelectedOptionLabel(facing, facingOptions);
  const streetLocationValue = getSelectedOptionLabel(streetLocation, streetLocationOptions);
  const streetTypeValue = getSelectedOptionLabel(streetType, streetTypeOptions);
  const streetTrafficValue = getSelectedOptionLabel(streetTraffic, streetTrafficOptions);
  const parkingIssuesTimesValue = getSelectedOptionLabel(parkingIssuesTimes, parkingIssuesTimesOptions);

  const text = [
    Boolean(buildingLocation) ? `${buildingLocationValue}. ` : '',
    Boolean(propertyTypeName) ? ` הנכס שבנדון מהווה ${propertyTypeName} ` : '',
    (Boolean(propertyTypeName) && PT.REGULAR.includes(propertyTypeID)) ? ' בבניין ' : '',
    Boolean(facing) ? ` הממוקם בפאה ה${facingValue} ` : '',
    Boolean(streetName) ? ` ברח' ` : '',
    Boolean(streetName) ? `${streetName}` : '',
    Boolean(streetName) ? ', ' : '',
    Boolean(cityName) && !Boolean(streetName) ? 'ב' : '',
    Boolean(cityName) ? `${cityName}` : '',
    _.some([
      buildingLocation,
      propertyTypeName,
      facing,
      streetName,
      cityName,
    ]) ? '.' : '',
    _.some([
      streetLocation,
      streetType,
      streetTraffic
    ]) ? [
      Boolean(streetName) ? ` רח' ${streetName}` : ' הרחוב',
      Boolean(streetLocation) ? ` מהווה רחוב ${streetLocationValue} ` : '',
      Boolean(streetType) ? `${streetTypeValue} ` : '',
      Boolean(streetTraffic) ? `המאופיין בתנועה ${streetTrafficValue} של כלי רכב` : '',
      _.some([
        streetName,
        streetLocationValue,
        streetTypeValue,
        streetTrafficValue,
      ]) ? '.' : '',
      Boolean(parkingIssuesTimes) ? ` ניכרת מצוקת חניה האופיינית לסביבה ${parkingIssuesTimesValue}` : '',
      Boolean(parkingIssuesTimes) ? '.' : '',
      Boolean(publicTransport) ? ` ברחוב קיימת תחבורה ציבורית.` : '',
    ].filter(Boolean).join('') : '',
  ].filter(Boolean).join('');

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
      ref={ref}
    >
      <span className={classnames(styles.bold)}>
        מיקום הנכס: 
      </span>
      <span>{text}</span>
    </div>
  );
});

const Line4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        buildingType
      },
    },
  } = useCompleteInspectionContext();
  const buildingTypeValue = getSelectedOptionLabel(buildingType, buildingTypeIdOptions);

  return (
    <div ref={ref}>
      {Boolean(buildingType) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            סוג הבניין: 
          </span>
          <span>{buildingTypeValue}{checkIfTextEndsWithDot(buildingTypeValue)}</span>
        </div>
      )}
    </div>
  );
});

const Line5Image = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();

  const fileEnvironmentLocatorMap = findFileByTypeId(context.files, fileTypeId.ENVIRONMENT_LOCATOR_MAP);
  const mediaEnvironmentLocatorMap = fileEnvironmentLocatorMap && getMediaType(fileEnvironmentLocatorMap);
  const imageSrcEnvironmentLocatorMap = fileEnvironmentLocatorMap && getFullDataURI(fileEnvironmentLocatorMap);

  const fileIdfLocatingPlot = findFileByTypeId(context.files, fileTypeId.IDF_LOCATING_PLOT);
  const mediaIdfLocatingPlot = fileIdfLocatingPlot && getMediaType(fileIdfLocatingPlot);
  const imageSrcIdfLocatingPlot = fileIdfLocatingPlot && getFullDataURI(fileIdfLocatingPlot);

  return (
    <div className={classnames(styles.sectionContainer, styles.flex)} ref={ref}>
      {fileEnvironmentLocatorMap && (
        <div>
          <div
            className={classnames(
              styles.textRegular,
              styles.sectionContainer,
              styles.innerPadding,
              styles.bold,
              styles.textCentered
            )}
          >
            מפת איתור הנכס
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrcEnvironmentLocatorMap}
              file={fileEnvironmentLocatorMap}
              alt="file"
              className={classnames(styles.mb3, styles.noPaddingRight)}
              type={mediaEnvironmentLocatorMap}
            />
          </div>
        </div>
      )}
      {fileIdfLocatingPlot && (
        <div>
          <div
            className={classnames(
              styles.textCentered,
              styles.textRegular,
              styles.bold,
            )}
          >
            תצ"א
          </div>
          <div className={styles.mediaContainer}>
            <Media
              src={imageSrcIdfLocatingPlot}
              file={fileIdfLocatingPlot}
              alt="file"
              className={styles.mb3}
              type={mediaIdfLocatingPlot}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const HeaderLine5Map = () => (
  <div
    className={classnames(
      styles.textRegular,
      styles.innerPadding,
      styles.bold,
    )}
  >
    מפת איתור הנכס
  </div>
);

const getLine5MapFiles = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.ENVIRONMENT_LOCATOR_MAP);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.ENVIRONMENT_LOCATOR_MAP, {
      firstPageHeader: <HeaderLine5Map/>
    })
    : [];
};

const HeaderLine5Plot = () => (
  <>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      תצ"א
    </div>
  </>
);

const getLine5PlotFiles = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.IDF_LOCATING_PLOT);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.IDF_LOCATING_PLOT, {
      firstPageHeader: <HeaderLine5Plot/>
    })
    : [];
};

const SubHead2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        registeredArea,
        landShape,
        landSurroundings,
        landSurroundingsText,
        buildingNearbyHazard,
      },
    },
  } = useCompleteInspectionContext();

  const showLine6 = _.some([
    registeredArea,
    landShape,
    landSurroundings,
    landSurroundingsText
  ]);
  const showLine7 = Boolean(buildingNearbyHazard);

  return (
    <div ref={ref}>
      <SubHead2Content/>
      <Line6Content/>
      {!showLine6 && <Line7Content/>}
      {!_.some([showLine6, showLine7]) && <Line7aContent/>}
    </div>
  );
});

const Line7 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        registeredArea,
        landShape,
        landSurroundings,
        landSurroundingsText,
      },
    },
  } = useCompleteInspectionContext();
  const showLine6 = _.some([
    registeredArea,
    landShape,
    landSurroundings,
    landSurroundingsText
  ]);

  return (
    <div ref={ref}>
      {showLine6 && <Line7Content/>}
    </div>
  );
});

const Line7a = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        registeredArea,
        landShape,
        landSurroundings,
        landSurroundingsText,
        buildingNearbyHazard,
      },
    },
  } = useCompleteInspectionContext();
  const showLine6 = _.some([
    registeredArea,
    landShape,
    landSurroundings,
    landSurroundingsText
  ]);
  const showLine7 = Boolean(buildingNearbyHazard);

  return (
    <div ref={ref}>
      {_.some([showLine6, showLine7]) && <Line7aContent/>}
    </div>
  );
});

const SubHead2Content = () => {
  const {
    data: {
      pdfData: {
        registeredArea,
        landShape,
        landSurroundings,
        buildingNearbyHazard,
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(_.some([
    registeredArea,
    landShape,
    landSurroundings,
    buildingNearbyHazard
  ])) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
     <span className={classnames(styles.textUnderline, styles.bold)}>
     <span className={styles.sectionNumber}/>
     <span>8.2 </span>
     <span>תיאור החלקה</span>
     </span>
    </div>
  );
};

const Line6Content = () => {
  const {
    data: {
      pdfData: {
        registeredArea,
        landShape,
        landSurroundings,
        landSurroundingsText,
      },
    },
  } = useCompleteInspectionContext();
  const landShapeValue = (landShape === null) ? '' : getSelectedOptionLabel(landShape, options.landShapeOptions);
  const landSurroundingsValue = getLandSurroundingsLabel(landSurroundings, landSurroundingsText);

  const text = [
    Boolean(registeredArea) ? `שטח החלקה הרשום: ` : '',
    Boolean(registeredArea) ? `${registeredArea} ` : '',
    Boolean(registeredArea) ? 'מ"ר. ' : '',
    Boolean(landShape) ? `צורת החלקה ` : '',
    Boolean(landShape) ? `${landShapeValue}.` : '',
    Boolean(landSurroundings) ? `החלקה גובלת ב` : '',
    Boolean(landSurroundings) ? `${landSurroundingsValue}.` : '',
  ].filter(Boolean).join('');

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
    >
      {text}
    </div>
  );
};

const Line7Content = () => {
  const {
    data: {
      pdfData: {
        buildingNearbyHazard,
      },
    },
  } = useCompleteInspectionContext();

  const text = [
    Boolean(buildingNearbyHazard) ? `החלקה גובלת במפגעים הבאים: ` : '',
    Boolean(buildingNearbyHazard) ? `${buildingNearbyHazard}.` : '',
  ].filter(Boolean).join('');

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
    >
      {text}
    </div>
  );
};

const Line7aContent = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        landDescription
      },
    },
  } = useCompleteInspectionContext();
  const isPropertyTypeLot = PT.LOT.includes(propertyTypeID);

  return (landDescription && !isPropertyTypeLot) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>תיאור מגרש: </span>
      <span>{landDescription}{checkIfTextEndsWithDot(landDescription)}</span>
    </div>
  );
};

const SubHead3 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <SubHead3Content/>
      <Line8Content/>
    </div>
  );
});

const SubHead3Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        registeredArea,
        landShape,
        landSurroundings,
        buildingNearbyHazard
      },
    },
  } = useCompleteInspectionContext();
  const isPropertyTypeRegular = PT.REGULAR.includes(propertyTypeID);
  const showSecondSubHeader = Boolean(_.some([
    registeredArea,
    landShape,
    landSurroundings,
    buildingNearbyHazard
  ]));

  return isPropertyTypeRegular && (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
        <span className={classnames(styles.textUnderline, styles.bold)}>
          <span className={styles.sectionNumber}/>
          {showSecondSubHeader ? <span>8.3 </span> : <span>8.2 </span>}
          <span>מאפייני הבניין</span>
        </span>
    </div>
  );
};

const Line8Content = () => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingPhysicalState,
      },
    },
  } = useCompleteInspectionContext();
  const buildingPhysicalStateValue = getSelectedOptionLabel(buildingPhysicalState, buildingPhysicalStateOptions);

  const text = [
    Boolean(buildingPhysicalState) ? `${buildingPhysicalStateValue}.` : '',
  ].filter(Boolean).join('');

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <span className={classnames(styles.bold)}>
            מצב פיזי: 
          </span>
          <span>{text}</span>
        </div>
      );
    default:
      return null;
  }
};

const Line9 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingAge,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div ref={ref}>
          {Boolean(buildingAge) && (
            <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
              <span className={classnames(styles.bold)}>
                גיל הבניין (בשנים): 
              </span>
              <span>{`כ- ${buildingAge}`}{checkIfTextEndsWithDot(buildingAge)}</span>
            </div>
          )}
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line10 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingPopulated,
      },
    },
  } = useCompleteInspectionContext();
  const buildingPopulatedText = getSelectedOptionLabel(buildingPopulated, buildingPopulatedOptions);

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
           <span className={classnames(styles.bold)}>
             האם הבניין מאוכלס: 
           </span>
          <span>{buildingPopulatedText}{checkIfTextEndsWithDot(buildingPopulatedText)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line11 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildPercentage,
      },
    },
  } = useCompleteInspectionContext();
  const text = [
    Boolean(buildPercentage) ? `${buildPercentage}%` : '',
    Boolean(buildPercentage) ? ` בניה נוכחי.` : '',
  ].filter(Boolean).join('');

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div ref={ref}>
          {Boolean(buildPercentage) && (
            <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
              <span className={classnames(styles.bold)}>
                 מצבו הפיזי של בניין בבניה: 
              </span>
              <span>{text}</span>
            </div>
          )}

        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line12 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingMaintenance,
      },
    },
  } = useCompleteInspectionContext();
  const buildingMaintenanceText = getSelectedOptionLabel(buildingMaintenance, buildingMaintenanceOptions);

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            תחזוקתו: 
          </span>
          <span>{buildingMaintenanceText}{checkIfTextEndsWithDot(buildingMaintenanceText)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line13 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingBuildType,
        buildingBuildTypeText,
        buildingRoofType,
      },
    },
  } = useCompleteInspectionContext();

  const text = [
    Boolean(buildingBuildType) ? `${getBuildingBuildTypeValue(buildingBuildType, buildingBuildTypeText)}, ` : '',
    Boolean(buildingRoofType) ? ` גג ${getSelectedOptionLabel(buildingRoofType, buildingRoofTypeOptions)}.` : '',
  ].filter(Boolean).join('');

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div ref={ref}>
          {Boolean(buildingBuildType) && (
            <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
              <span className={styles.bold}>סוג בנייה: </span>
              <span>{text}</span>
            </div>
          )}
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line14 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingOuterLayer,
        buildingOuterLayerText,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            חיפוי חיצוני: 
          </span>
          <span>{getBuildingOuterLayerValue(buildingOuterLayer, buildingOuterLayerText)}.</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line15 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingFloorsCount,
        buildingOnPillars,
        buildingOnPillarsOtherText,
      },
    },
  } = useCompleteInspectionContext();
  const buildingOnPillarsValue = buildingOnPillars === 12
    ? buildingOnPillarsOtherText
    : getSelectedOptionLabel(buildingOnPillars, buildingOnPillarsOptions);

  const text = [
    `${buildingFloorsCount}`,
    ` מעל קומת `,
    `${buildingOnPillarsValue}`,
    '.'
  ].filter(Boolean).join('');

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
      ref={ref}
    >
      {PT.REGULAR.includes(propertyTypeID) && (
        <>
          <span className={classnames(styles.bold)}>
            מספר קומות: 
          </span>
          <span>{text}</span>
        </>
      )}
    </div>
  );
});

const Line16 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingApartmentPerFloor,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            מספר יח"ד בקומה: 
          </span>
          <span>{buildingApartmentPerFloor}{checkIfTextEndsWithDot(buildingApartmentPerFloor)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line16a = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingEntranceCount,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            מספר כניסות: 
          </span>
          <span>{buildingEntranceCount}{checkIfTextEndsWithDot(buildingEntranceCount)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line16b = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        apartmentsNumberAtGroundFloor,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div ref={ref}>
          {apartmentsNumberAtGroundFloor && (
            <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
              <span className={classnames(styles.bold)}>
                מספר יח"ד בכניסה: 
              </span>
              <span>{apartmentsNumberAtGroundFloor}{checkIfTextEndsWithDot(apartmentsNumberAtGroundFloor)}</span>
            </div>
          )}
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line16c = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        apartmentsNumberInBuilding,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div ref={ref}>
          {apartmentsNumberInBuilding && (
            <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
              <span className={classnames(styles.bold)}>
                מספר יח"ד בבניין: 
              </span>
              <span>{apartmentsNumberInBuilding}{checkIfTextEndsWithDot(apartmentsNumberInBuilding)}</span>
            </div>
          )}
        </div>
      )
        ;
    default:
      return <div ref={ref}/>;
  }
});

const Line17 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingElevator,
      },
    },
  } = useCompleteInspectionContext();
  const buildingElevatorValue = getSelectedOptionLabel(buildingElevator, buildingElevatorOptions);
  const buildingElevatorValueOrDefault = buildingElevator > 0
    ? buildingElevatorValue
    : 'אין';

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            מעלית: 
          </span>
          <span>{buildingElevatorValueOrDefault}{checkIfTextEndsWithDot(buildingElevatorValueOrDefault)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line18 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingIntercom,
      },
    },
  } = useCompleteInspectionContext();
  const buildingIntercomText = getSelectedOptionLabel(buildingIntercom, buildingIntercomOptions);

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            אינטרקום: 
          </span>
          <span>{buildingIntercomText}{checkIfTextEndsWithDot(buildingIntercomText)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line19 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingParking,
      },
    },
  } = useCompleteInspectionContext();
  const buildingParkingText = getSelectedOptionLabel(buildingParking, buildingParkingOptions);

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div
          className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}
          ref={ref}
        >
          <span className={classnames(styles.bold)}>
            חניה: 
          </span>
          <span>{buildingParkingText}</span>
          <span>.</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line20 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingMatchingEnvironment,
        buildingMatchingEnvironmentText,
      },
    },
  } = useCompleteInspectionContext();
  let buildingMatchingEnvironmentValue = getSelectedOptionLabel(buildingMatchingEnvironment, buildingMatchingEnvironmentOptions);

  const text = [
    Boolean(buildingMatchingEnvironment) ? `${buildingMatchingEnvironmentValue}.` : '',
    Boolean(buildingMatchingEnvironmentText) ? ` ${buildingMatchingEnvironmentText}.` : '',
  ].filter(Boolean).join('');

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
          <span className={classnames(styles.bold)}>
           {Boolean(buildingMatchingEnvironment) ? `האם הבניין תואם/חורג מסביבתו: ` : ''}
          </span>
          <span>{text}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

const Line21 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyTypeID,
        buildingPhysicalStateDesc,
      },
    },
  } = useCompleteInspectionContext();

  switch (propertyTypeID) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
          <span>{getBreakLinesTextShumaType3(buildingPhysicalStateDesc)}</span>
        </div>
      );
    default:
      return <div ref={ref}/>;
  }
});

export {
  getLine5MapFiles,
  getLine5PlotFiles,
};

export default {
  HeadLine1,
  Line2,
  Line3,
  Line4,
  Line5Image,
  SubHead2,
  Line7,
  Line7a,
  SubHead3,
  Line9,
  Line10,
  Line11,
  Line12,
  Line13,
  Line14,
  Line15,
  Line16,
  Line16a,
  Line16b,
  Line16c,
  Line17,
  Line18,
  Line19,
  Line20,
  Line21,
};

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {FormattedMessage} from "react-intl";
import CommonButton from "../../CommonButton";
import styles from './styles.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const ModalApproveDecline = ({
                               isOpen,
                               closeModal,
                               approveFunc,
                               message,
                             }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.modalApproveDeclineContainer}
      overlayClassName={styles.overlayModalApproveDeclineContainer}
    >
      <div className={styles.popupContainer}>
        <p className={styles.message}>
          {message}
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <CommonButton
          variant={'button'}
          label={
            <FormattedMessage
              id="button-decline"
              defaultMessage="Decline"
            />
          }
          onClick={closeModal}
        />
        <CommonButton
          variant={'submit'}
          label={
            <FormattedMessage
              id="button-approve"
              defaultMessage="Approve"
            />
          }
          onClick={() => {
            approveFunc();
            closeModal();
          }}
        />
      </div>
    </Modal>
  );
};

ModalApproveDecline.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  approveFunc: PropTypes.func,
  message: PropTypes.node,
};

export default ModalApproveDecline;

import axios from 'axios';
import {actions} from '../reducers/CustomerDetailsReducer';
import {showGlobalMessage} from '../../_common/components/GlobalMessage/actions/showGlobalMessage';

export const ADD_EDIT_CUSTOMER = '/customers/addEditCustomer';
export const GET_CUSTOMER_DATA = '/customers/getCustomerData';
export const GET_CUSTOMER_INSPECTIONS = '/customers/getCustomerInspections';

export const getCustomerDetails = (customerID) => dispatch => {
  return axios.post(GET_CUSTOMER_DATA, {customerID})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.setCustomer(body.customerData));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
    });
};

export const editCustomer = ({
                               customerID,
                               firstName,
                               lastName,
                               phone,
                               email,
                               customerType,
                               idNumber,
                               address,
                               genderID,
                               status,
                             }, successMessage) => (dispatch) => {
  const data = {
    customerID: parseInt(customerID, 10),
    firstName,
    lastName,
    phone,
    email,
    idNumber,
    address,
    customerType: parseInt(customerType, 10),
    genderID: parseInt(genderID, 10),
    status: parseInt(status, 10),
  };

  return axios.post(ADD_EDIT_CUSTOMER, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.setCustomer(body.customerData));
    })
    .then(() => {
      dispatch(showGlobalMessage(successMessage));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t send data';
    });
};

export const clearCustomerData = () => dispatch => {
  dispatch(actions.clear());
};

const LIMIT = 100;

const getInspectionsList = action => customerID => ({
                                                      requestedPageNumber
                                                    }) => dispatch => {
  const data = {
    customerID: parseInt(customerID, 10),
    limit: LIMIT,
    skip: parseInt(requestedPageNumber, 10) * LIMIT
  };

  return axios.post(GET_CUSTOMER_INSPECTIONS, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {
        totalPageCount,
        currentPageNumber,
        inspections,
        totalResultCount
      } = body;

      dispatch(action({
        totalPageCount,
        currentPageNumber,
        inspections,
        totalResultCount,
      }));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch(actions.catchError(error));
    });
};

export const getInspections = getInspectionsList(actions.fetchInspections);
export const getNextInspections = getInspectionsList(actions.appendInspections);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import {getBuildingBuildTypeValue} from '../../../utils/texts';
import styles from './styles.module.scss';

export const TextItem4 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingEntranceCount) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, className)}
        >
          <span className={styles.bold}>מספר כניסות: </span>
          {` ${context.data.pdfData.buildingEntranceCount}.`}
        </div>
      )}
    </div>
  );
});

TextItem4.propTypes = {
  className: PropTypes.string
};

export const TextItem5 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const currentBuildingOnPillars = getSelectedOptionLabel(context.data.pdfData.buildingOnPillars, options.buildingOnPillarsOptions);
  const buildingOnPillarsValue = ` מעל קומת ${currentBuildingOnPillars}`;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)}
         ref={ref}>
      <span className={styles.bold}>מס' קומות: </span>
      {context.data.pdfData.buildingFloorsCount} קומות
      <span>{context.data.pdfData.buildingOnPillars !== 2 && buildingOnPillarsValue}</span>
      <span>.</span>
    </div>
  );
});

TextItem5.propTypes = {
  className: PropTypes.string
};

export const TextItem6 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingApartmentPerFloor) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, className)}
        >
          <span className={styles.bold}>מספר דירות בקומה: </span>
          {` ${context.data.pdfData.buildingApartmentPerFloor}.`}
        </div>
      )}
    </div>
  );
});

TextItem6.propTypes = {
  className: PropTypes.string
};

export const TextItem7 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingTenantsRegisteredCount) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, className)}
        >
          <span className={styles.bold}>מס' דיירים ברישום: </span>
          {` ${context.data.pdfData.buildingTenantsRegisteredCount}.`}
        </div>
      )}
    </div>
  );
});

TextItem7.propTypes = {
  className: PropTypes.string
};

export const TextItem8 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingTenantsLicenseCount) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, className)}
        >
          <span className={styles.bold}>מס' דיירים ברישוי: </span>
          {` ${context.data.pdfData.buildingTenantsLicenseCount}.`}
        </div>
      )}
    </div>
  );
});

TextItem8.propTypes = {
  className: PropTypes.string
};

export const TextItem9 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingElevatorValue = getSelectedOptionLabel(context.data.pdfData.buildingElevator, options.buildingElevatorOptions);
  const buildingElevatorValueOrDefault = context.data.pdfData.buildingElevator > 0
    ? buildingElevatorValue
    : 'ללא';

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)}
         ref={ref}>
        <span
          className={styles.bold}>מעלית: </span>
      {` ${buildingElevatorValueOrDefault}.`}
    </div>
  );
});

TextItem9.propTypes = {
  className: PropTypes.string
};

export const TextItem10 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingParkingValue = getSelectedOptionLabel(context.data.pdfData.buildingParking, options.buildingParkingOptions);

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)}
         ref={ref}>
      <span className={styles.bold}>חניה: </span>
      <span>{` ${buildingParkingValue}.`}</span>
    </div>
  );
});

TextItem10.propTypes = {
  className: PropTypes.string
};

export const TextItem11 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingIntercomValue = getSelectedOptionLabel(context.data.pdfData.buildingIntercom, options.buildingIntercomOptions);

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)}
         ref={ref}>
      <span className={styles.bold}>אינטרקום: </span>
      <span>{` ${buildingIntercomValue}.`}</span>
    </div>
  );
});

TextItem11.propTypes = {
  className: PropTypes.string
};

export const TextItem12 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingMaintenanceValue = getSelectedOptionLabel(context.data.pdfData.buildingMaintenance, options.buildingMaintenanceOptions);

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)}
         ref={ref}>
      <span className={styles.bold}>מצב תחזוקה: </span>
      <span>{` ${buildingMaintenanceValue}.`}</span>
    </div>
  );
});

TextItem12.propTypes = {
  className: PropTypes.string
};

export const TextItem13 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingPhysicalStateValue = getSelectedOptionLabel(context.data.pdfData.buildingPhysicalState, options.buildingPhysicalStateOptions);
  const {noteNumbers} = context;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding, className)}
         ref={ref}>
      <span className={styles.bold}>
          מצב פיזי
          <NoteNumber
            id={'buildingPhysicalStateValue'}>{noteNumbers.buildingPhysicalStateValue}</NoteNumber> :
        </span>
      <span
        data-testid="buildingPhysicalStateValue">
          {` ${buildingPhysicalStateValue}.`}
        </span>
    </div>
  );
});

TextItem13.propTypes = {
  className: PropTypes.string
};

export const TextItem14 = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildingBuildType) && (
        <div
          className={classnames(styles.itemText, styles.innerPadding, className)}
        >
          <span className={styles.bold}>סוג בנייה: </span>
          {` ${getBuildingBuildTypeValue(context.data.pdfData.buildingBuildType, context.data.pdfData.buildingBuildTypeText)}.`}
        </div>
      )}
    </div>
  );
});

TextItem14.propTypes = {
  className: PropTypes.string
};

export default [
  TextItem4,
  TextItem5,
  TextItem6,
  TextItem7,
  TextItem8,
  TextItem9,
  TextItem10,
  TextItem11,
  TextItem12,
  TextItem13,
  TextItem14,
];


import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route, useHistory, useLocation} from 'react-router-dom';
import {useSelector, shallowEqual} from 'react-redux';
import can from './can';

const ProtectedRoute = ({subject, ...routeProps}) => {
  const {defaultPage} = useSelector(
    ({auth}) => auth,
    shallowEqual
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!can('view', subject)) {
      history.replace(defaultPage);
    }
  }, [location.pathname]);

  return <Route {...routeProps}/>
};

ProtectedRoute.propTypes = {
  subject: PropTypes.string,
  defaultPage: PropTypes.string,
  children: PropTypes.node
};

export default ProtectedRoute;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  getCommonAssetsColumnHeader,
  getSimilarAssetsCrawlerColumnHeader,
  getSurveyAssetsColumnHeader
} from '../../utils/gridColumns';
import {formattingDate, formattingPrice} from '../../utils/formattingHelper';
import {tableTitles} from '../../constants/tableTitles';
import styles from './styles.module.scss';

const mapTable = ({columns, rows}, tableId) => {
  const columnFormats = getColumnFormats(columns);
  return (rows.length > 0) && (
    <table
      className={classnames(styles.simpleTable, styles.w100, styles.longTable)}>
      {mapColumnHeaders(columns, tableId)}
      {mapRows(rows, columnFormats)}
    </table>
  );
};

const mapColumnHeaders = (columns, tableId) => {
  const findColumnName = getColumnNamesFinder(tableId);
  return (
    <thead>
    <tr>
      {columns.map((name, ind) => (
        <th className={styles.greenBg} key={ind}>{findColumnName(name)}</th>
      ))}
    </tr>
    </thead>
  );
};

const mapRows = (rows, columnFormats) => {
  return (
    <tbody>
    {rows.map((row, ind) => (
      <tr key={ind}>
        {mapCells(row, columnFormats)}
      </tr>
    ))}
    </tbody>
  );
};

const mapCells = (row, columnFormats) => {
  return (
    row.map((cell, ind) => (
      <td key={ind}>{renderCell(cell, columnFormats[ind])}</td>
    )));
};

const renderCell = (cell, format) => {
  return format ? format(cell) : cell;
};

const getColumnNamesFinder = columnId => {
  switch (columnId) {
    case 'similarAssets':
      return getSimilarAssetsCrawlerColumnHeader;
    case 'surveyAssets':
      return getSurveyAssetsColumnHeader;
    case 'firmAssets':
    case 'sharedAssets':
    default:
      return getCommonAssetsColumnHeader;
  }
};

const formattingTableDate = date => formattingDate(date, 'DD/MM/YY');
const formattingTablePrice = price => formattingPrice(Math.round(price));
const getColumnFormats = (columns) => {
  const columnFormats = {};
  columns.forEach((columnName, ind) => {
    if (columnName === 'officialDate' || columnName === 'purchaseDate') {
      columnFormats[ind] = formattingTableDate;
    }
    if ((columnName === 'price') || (columnName === 'askingPrice') || (columnName === 'publicPrice') || (columnName === 'salePrice')) {
      columnFormats[ind] = formattingTablePrice;
    }
  });
  return columnFormats;
};

const AssetsTable = ({table}) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.tableTitle}>{tableTitles[table.id]}</div>
      {mapTable(table, table.id)}
    </div>
  );
};

AssetsTable.propTypes = {
  table: PropTypes.object,
};

export default AssetsTable;

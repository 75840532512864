import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';
import {useHistory} from 'react-router-dom';
import {useOrdersPageUIContext} from '../OrdersPageUIContext';
import moment from 'moment';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import FilterTextBox from '../../../_common/components/FilterTextBox';
import CommonButton from '../../../_common/components/CommonButton';
import FilterDropdown from '../../../_common/components/FilterDropdown';
import {FilterRangePicker} from '../../../_common/components/FieldDatepicker';
import FiltersLabel from '../../../_common/components/FiltersLabel';
import FilterInputTypeahead from '../../../_common/components/FilterInputTypeahead';
import CustomerItem from '../../../_common/components/CustomerItem';
import * as actions from '../../../_common/actions/findCustomers';
import {generateExcelFile} from '../../../_common/utils/excel';
import {ordersHeader} from '../../../_common/constants/exportedFilesMainHeaders';
import {columnNamesOrders} from '../../../_common/constants/excelColumnNames';
import {getOrdersListForExcel} from '../../_redux/actions/OrdersPageFilterActions';
import {filterOptions} from '../../../../../utils/filterOptions';
import {clearFilterValue, onChangeDDValue} from '../../../../../utils/changeFilterValues';
import {getUniqueElementsFromArray} from '../../../_common/utils/getUniqueElementsFromArray';
import {applyFilter} from '../../../../../utils/prepareApplyFilter';
import {integerValue} from '../../../_common/utils/changeInputValue';
import styles from './styles.module.scss';


export function OrdersPageFilters({statusList}) {
  const [inputCustomer, setInputCustomer] = useState('');
  const ordersUIContext = useOrdersPageUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams,
    };
  }, [ordersUIContext]);

  const dispatch = useDispatch();
  const history = useHistory();

  const intl = useIntl();
  const searchByOrderNumber = intl.formatMessage({
    id: 'filter-placeholder-order-number',
    defaultMessage: 'Order number',
  });
  const searchByShumaID = intl.formatMessage({
    id: 'filter-placeholder-shumaID',
    defaultMessage: 'Shuma ID',
  });
  const searchByCreatedDate = intl.formatMessage({
    id: 'filter-placeholder-createDate',
    defaultMessage: 'Create date',
  });
  const searchByDueDate = intl.formatMessage({
    id: 'filter-placeholder-dueDate',
    defaultMessage: 'Due date',
  });
  const searchByCustomer = intl.formatMessage({
    id: 'filter-placeholder-customer',
    defaultMessage: 'Customer',
  });
  const searchByStatus = intl.formatMessage({
    id: 'filter-placeholder-status',
    defaultMessage: 'Status',
  });
  const buttonCreateOrderLabel = intl.formatMessage({
    id: 'orders-page-button-create-order',
    defaultMessage: 'Create order',
  });
  const buttonExportToExcelLabel = intl.formatMessage({
    id: 'orders-page-button-export-to-excel',
    defaultMessage: 'Export',
  });

  const qs = new URLSearchParams(window.location.search);
  let orderNumber = qs.get('orderNumber') || '';
  let shumaID = qs.get('shumaID') || '';
  let createdFromDate = qs.get('createdFromDate') || '';
  let createdToDate = qs.get('createdToDate') || '';
  let dueFromDate = qs.get('dueFromDate') || '';
  let dueToDate = qs.get('dueToDate') || '';
  let customerID = qs.get('customerID') || '';
  let status = qs.get('status') || '';

  const onChangeCreatedDate = (fromDate, toDate, setFieldValue, handleSubmit) => {
    setFieldValue('createdFromDate', fromDate || '');
    setFieldValue('createdToDate', toDate || '');
    handleSubmit();
  };
  const onChangeDueDate = (fromDate, toDate, setFieldValue, handleSubmit) => {
    setFieldValue('dueFromDate', fromDate);
    setFieldValue('dueToDate', toDate);
    handleSubmit();
  };
  const onChangeCustomer = (value) => (setFieldValue, handleSubmit) => {
    if (value && value[0]) {
      const {firstName, lastName, customerID} = value[0];
      const inputCustomerValue = `${firstName || ''} ${lastName || ''}`;
      setFieldValue('customerID', customerID);
      setFieldValue('inputCustomer', inputCustomerValue);
      setInputCustomer(inputCustomerValue);
      handleSubmit();
    }
  };
  const onChangeShumaID = (e) => (setFieldValue, handleSubmit) => {
    setFieldValue('shumaID', integerValue(e));
    handleSubmit();
  };
  const onChangeOrderNumber = (e) => (setFieldValue, handleSubmit) => {
    setFieldValue('orderNumber', integerValue(e));
    handleSubmit();
  };

  const clearCreatedDate = (setFieldValue, handleSubmit) => {
    setFieldValue('createdFromDate', '');
    setFieldValue('createdToDate', '');
    handleSubmit();
  };
  const clearDueDate = (setFieldValue, handleSubmit) => {
    setFieldValue('dueFromDate', '');
    setFieldValue('dueToDate', '');
    handleSubmit();
  };
  const clearCustomer = (setFieldValue, handleSubmit) => {
    setFieldValue('customerID', '');
    setFieldValue('inputCustomer', '');
    setInputCustomer('');
    handleSubmit();
  };

  const exportOrdersFileFunc = () => {
    // Here s = start, r = row, c=col, e= end
    const merge = [
      {s: {r: 1, c: 7}, e: {r: 1, c: 12}},
    ];
    // Custom cell
    const customCell = (ws) => {

      return ws['H2'] = {
        t: 's',
        v: 'פרטי איש קשר',
        s: {
          alignment: {
            horizontal: 'center'
          },
          font: {
            bold: true,
          }
        }
      };
    };

    dispatch(getOrdersListForExcel(ordersUIProps.queryParams))
      .then(prepareDataForExcel)
      .then((data) => {
        generateExcelFile(
          data,
          'Orders.xlsx',
          ordersHeader,
          merge,
          customCell
        );
      })
      .catch((error) => {
        console.error(`Error: + ${error}`);
      });
  };
  const prepareDataForExcel = data => {
    return data.map((item, index) => {
      const {
        orderNumber,
        createdDate,
        customers,
        shumaCount,
        dueDate,
        status,
        contactFullName,
        contactPhone,
        contactJob,
        contactOrganisation,
        contactEmail,
        contactAddress,
        lawyerName
      } = item;

      return {
        [columnNamesOrders[0]]: index + 1,
        [columnNamesOrders[1]]: orderNumber,
        [columnNamesOrders[2]]: moment(createdDate).format('DD.MM.YYYY'),
        [columnNamesOrders[3]]: Boolean(customers) ? getUniqueElementsFromArray(customers).toString() : '',
        [columnNamesOrders[4]]: shumaCount,
        [columnNamesOrders[5]]: moment(dueDate).format('DD.MM.YYYY'),
        [columnNamesOrders[6]]: status
          ? statusList.filter(item => item.value === status)[0].label : '',
        [columnNamesOrders[7]]: contactFullName,
        [columnNamesOrders[8]]: contactPhone,
        [columnNamesOrders[9]]: contactJob,
        [columnNamesOrders[10]]: contactOrganisation,
        [columnNamesOrders[11]]: contactEmail,
        [columnNamesOrders[12]]: contactAddress,
        [columnNamesOrders[13]]: lawyerName,
      };
    });
  };

  const handleCreateOrder = () => history.push(`/order-details/-1`);

  const findCustomer = (input) => {
    return dispatch(actions.findCustomer(input));
  };

  useEffect(() => {
    setInputCustomer(window.localStorage.getItem('inputCustomer'));
  }, []);

  useEffect(() => {
    const value = Boolean(inputCustomer) ? inputCustomer : '';
    window.localStorage.setItem('inputCustomer', value);
  }, [inputCustomer]);

  return (
    <Formik
      initialValues={{
        orderNumber,
        shumaID,
        createdFromDate,
        createdToDate,
        dueFromDate,
        dueToDate,
        inputCustomer: '',
        customerID,
        status
      }}
      onSubmit={(values) => {
        applyFilter(values, ordersUIProps);
      }}
      enableReinitialize={true}
    >
      {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit} className={styles.formFilters}>
          <div className="form-group row d-flex mb-0 ml-8">
            <FiltersLabel/>
            <FilterTextBox
              name={'orderNumber'}
              placeholder={searchByOrderNumber}
              value={values.orderNumber}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeOrderNumber(e)(setFieldValue, handleSubmit)}
              onClearValue={() => clearFilterValue('orderNumber')(setFieldValue, handleSubmit)}
              customContainerClassName={'col-lg position-relative'}
            />
            <FilterTextBox
              name={'shumaID'}
              placeholder={searchByShumaID}
              value={values.shumaID}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeShumaID(e)(setFieldValue, handleSubmit)}
              onClearValue={() => clearFilterValue('shumaID')(setFieldValue, handleSubmit)}
              customContainerClassName={'col-lg position-relative'}
            />
            <div className="col-lg position-relative">
              <FilterRangePicker
                className="h-35px pl-4 pr-12 min-w-235px w-235px"
                onChange={(fromDate, toDate) => onChangeCreatedDate(fromDate, toDate, setFieldValue, handleSubmit)}
                placeholder={searchByCreatedDate}
                startDate={createdFromDate}
                endDate={createdToDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearCreatedDate(setFieldValue, handleSubmit)}
              />
            </div>
            <div className="col-lg position-relative">
              <FilterRangePicker
                className="h-35px pl-4 pr-12 min-w-235px w-235px"
                onChange={(fromDate, toDate) => onChangeDueDate(fromDate, toDate, setFieldValue, handleSubmit)}
                placeholder={searchByDueDate}
                startDate={dueFromDate}
                endDate={dueToDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearDueDate(setFieldValue, handleSubmit)}
              />
            </div>
            <FilterInputTypeahead
              fieldId={'inputCustomer'}
              value={values.inputCustomer}
              labelKey={option => `${option.firstName} ${option.lastName}`}
              placeholder={inputCustomer ? inputCustomer : searchByCustomer}
              onChange={(value) => onChangeCustomer(value)(setFieldValue, handleSubmit)}
              setFieldValue={setFieldValue}
              renderMenuItemChildren={option => <CustomerItem {...option}/>}
              dispatch={dispatch}
              handleFindItem={findCustomer}
              onClearValue={() => clearCustomer(setFieldValue, handleSubmit)}
            />
            <FilterDropdown
              name="status"
              value={filterOptions(values.status)(statusList)}
              onChangeValue={({value}) => onChangeDDValue('status', value)(setFieldValue, handleSubmit)}
              options={statusList}
              placeholder={searchByStatus}
              onClearValue={() => clearFilterValue('status')(setFieldValue, handleSubmit)}
              customContainerClassname={'col-md-2 position-relative'}
            />
            <div className="col-lg position-relative d-flex justify-content-center">
              <CommonButton
                label={
                  <>
                    <img
                      src={toAbsoluteUrl('/media/common/Excel.svg')}
                      alt="export"
                      className={'w-15px h-15px mr-2'}
                    />
                    {buttonExportToExcelLabel}
                  </>
                }
                onClick={exportOrdersFileFunc}
                customButtonClassNames={'btn btn-warning h-35px btn-sm font-weight-bolder text-dark text-nowrap mr-4'}
              />
              <CommonButton
                label={
                  <>
                    <img
                      src={toAbsoluteUrl('/media/common/Plus.svg')}
                      alt="create"
                      className={'w-15px h-15px mr-2'}
                    />
                    {buttonCreateOrderLabel}
                  </>
                }
                onClick={handleCreateOrder}
                customButtonClassNames={'btn btn-warning h-35px btn-sm font-weight-bolder text-dark text-nowrap w-auto'}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default OrdersPageFilters;

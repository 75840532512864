import React from 'react';
import {FormattedMessage} from "react-intl";
import {FieldArray, FormikProvider} from 'formik';
import PropTypes from "prop-types";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {useCustomerDetailsUIContext} from "../CustomerDetailsUIContext";
import EditableRow from "../EditableRow";
import CommonButton from "../../../_common/components/CommonButton";
import {gridTypes as G} from "../../constants/gridTypes";
import {
  headerCellsArrayCommonDocs,
  headerCellsArrayReceiptCheque,
  headerCellsArrayReceiptBankTransfer,
  headerCellsArrayReceiptCreditCard,
  headerCellsArrayReceiptPaymentApp,
  headerCellsArrayReceiptPayPal,
  headerCellsArrayReceiptCash,
  headerCellsArrayReceiptOther,
  headerCellsArrayReceiptWithholdingTax,
} from "../../constants/gridColumns";
import {
  rowValuesSetCommonDocs,
  rowValuesSetCheque,
  rowValuesSetBankTransfer,
  rowValuesSetCreditCard,
  rowValuesSetPaymentApp,
  rowValuesSetPayPal,
  rowValuesSetCash,
  rowValuesSetOther,
  rowValuesSetWithholdingTax,
} from "../../constants/valuesSets";
import {
  rowFieldsSetCommonDocs,
  rowFieldsSetCheque,
  rowFieldsSetBankTransfer,
  rowFieldsSetCreditCard,
  rowFieldsSetPaymentApp,
  rowFieldsSetPayPal,
  rowFieldsSetCash,
  rowFieldsSetOther,
  rowFieldsSetWithholdingTax,
} from "../../constants/fieldSets";
import classnames from 'classnames';
import styles from './styles.module.scss';

const GridEditableRows = ({gridType, containerClassnames = ''}) => {
  const {formikCreateDocument} = useCustomerDetailsUIContext();
  const {values} = formikCreateDocument;

  const gridData = (gridType) => {
    switch (gridType) {
      case G.CHEQUE:
        return {
          headerCells: headerCellsArrayReceiptCheque,
          valuesSet: rowValuesSetCheque,
          fieldsSet: rowFieldsSetCheque,
          fieldRowName: 'rowsCheque',
          values: values.rowsCheque,
        };
      case G.BANK_TRANSFER:
        return {
          headerCells: headerCellsArrayReceiptBankTransfer,
          valuesSet: rowValuesSetBankTransfer,
          fieldsSet: rowFieldsSetBankTransfer,
          fieldRowName: 'rowsBankTransfer',
          values: values.rowsBankTransfer,
        };
      case G.CREDIT_CARD:
        return {
          headerCells: headerCellsArrayReceiptCreditCard,
          valuesSet: rowValuesSetCreditCard,
          fieldsSet: rowFieldsSetCreditCard,
          fieldRowName: 'rowsCreditCard',
          values: values.rowsCreditCard,
        };
      case G.PAYMENT_APP:
        return {
          headerCells: headerCellsArrayReceiptPaymentApp,
          valuesSet: rowValuesSetPaymentApp,
          fieldsSet: rowFieldsSetPaymentApp,
          fieldRowName: 'rowsPaymentApp',
          values: values.rowsPaymentApp,
        };
      case G.PAY_PAL:
        return {
          headerCells: headerCellsArrayReceiptPayPal,
          valuesSet: rowValuesSetPayPal,
          fieldsSet: rowFieldsSetPayPal,
          fieldRowName: 'rowsPayPal',
          values: values.rowsPayPal,
        };
      case G.CASH:
        return {
          headerCells: headerCellsArrayReceiptCash,
          valuesSet: rowValuesSetCash,
          fieldsSet: rowFieldsSetCash,
          fieldRowName: 'rowsCash',
          values: values.rowsCash,
        };
      case G.OTHER:
        return {
          headerCells: headerCellsArrayReceiptOther,
          valuesSet: rowValuesSetOther,
          fieldsSet: rowFieldsSetOther,
          fieldRowName: 'rowsOther',
          values: values.rowsOther,
        };
      case G.WITHHOLDING_TAX:
        return {
          headerCells: headerCellsArrayReceiptWithholdingTax,
          valuesSet: rowValuesSetWithholdingTax,
          fieldsSet: rowFieldsSetWithholdingTax,
          fieldRowName: 'rowsWithholdingTax',
          values: values.rowsWithholdingTax,
        };
      case G.COMMON_DOCS:
        return {
          headerCells: headerCellsArrayCommonDocs,
          valuesSet: rowValuesSetCommonDocs,
          fieldsSet: rowFieldsSetCommonDocs,
          fieldRowName: 'rowsCommonDocs',
          values: values.rowsCommonDocs,
        };
    }
  }

  const mapHeaderCells = (item) => {
    return (
      <div className={`${styles.headerCell} ${item.customItemStyles}`}>
        {item.showMarkNecessary && <span className='text-danger'>*</span>}
        {item.label}
      </div>
    )
  };

  return (
    <div className={classnames(styles.gridEditableRows, containerClassnames)}>
      <div className={`${styles.header}  ${gridData(gridType).values.length > 3 ? 'pr-6' : ''}`}>
        {gridData(gridType).headerCells.map(mapHeaderCells)}
      </div>
      <FormikProvider value={formikCreateDocument}>
        <FieldArray name={gridData(gridType).fieldRowName}>
          {({remove, push}) => {
            return (
              <>
                <div className={styles.gridBody}>
                  {(gridData(gridType).values.length > 0) && gridData(gridType).values.map((sets, index) => (
                    <div key={index} className={styles.gridContainer}>
                      <EditableRow
                        gridType={gridType}
                        fieldsSet={(gridData(gridType).fieldsSet)(index)}
                        values={values}
                        removeRow={() => {
                          remove(index)
                        }}
                        index={index}
                      />
                    </div>
                  ))
                  }
                </div>
                <div className={styles.addRowButtonContainer}>
                  <CommonButton
                    onClick={() => {
                      push(gridData(gridType).valuesSet)
                    }}
                    label={
                      <div className={styles.addRowButtonLabel}>
                        <img
                          src={toAbsoluteUrl('/media/common/Plus.svg')}
                          alt="add-row"
                          className={styles.image}
                        />
                        <FormattedMessage
                          id="customer-details-popup-create-document-button-label-addRow"
                          defaultMessage="Add row"
                        />
                      </div>
                    }
                    customButtonClassNames={'w-150px btn btn-m btn-warning d-flex align-items-center'}
                  />
                </div>
              </>
            )
          }}
        </FieldArray>
      </FormikProvider>
    </div>
  );
};

GridEditableRows.propTypes = {
  gridType: PropTypes.string,
  containerClassnames: PropTypes.string,
};

export default GridEditableRows;

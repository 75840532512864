import React from 'react';
import {FormattedMessage} from "react-intl";

const PopupHeader = ({firmShumaID}) => {
  return (
    <h2 className='text-center font-weight-bolder'>
      <FormattedMessage
        id="inspections-popup-copy-header"
        defaultMessage="Copy inspection {id}"
        values={{id: firmShumaID}}
      />
    </h2>
  )
};

export default PopupHeader;

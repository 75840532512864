import axios from 'axios';

const CREATE_CLIENT = '/client/create';

export const createClient = ({
                               customerID,
                               name,
                               active,
                               department,
                               taxId,
                               accountingKey,
                               paymentTerms = 0,
                               bankName,
                               bankBranch,
                               bankAccount,
                               address,
                               city,
                               zip,
                               country = 'IL',
                               category,
                               subCategory,
                               phone,
                               fax,
                               mobile,
                               remarks,
                               contactPerson,
                               emails,
                               labels,
                             }, {
                               greenInvoiceApiKey,
                               greenInvoiceSecret
                             }) => dispatch => {
  const data = {
    customerID: parseInt(customerID, 10),
    name,
    active: true,
    department,
    taxId,
    accountingKey,
    paymentTerms: parseInt(paymentTerms, 10),
    bankName,
    bankBranch,
    bankAccount,
    address,
    city,
    zip,
    country,
    category: parseInt(category, 10),
    subCategory: parseInt(subCategory, 10),
    phone,
    fax,
    mobile,
    remarks,
    contactPerson,
    emails,
    labels,
  };

  return axios.post(CREATE_CLIENT, data, {
    headers: {
      'gi-api-key': greenInvoiceApiKey,
      'gi-api-secret': greenInvoiceSecret
    }
  })
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body;
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create client';
    });
};

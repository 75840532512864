// common
export const genderOptions = [
  {label: 'זכר', value: 1},
  {label: 'נקבה', value: 2}
];

// Step 2
export const rightsTypeOptions = [
  {label: 'בעלות', value: 1},
  {label: 'חכירה', value: 2},
  {label: 'להירשם כבעלים', value: 3},
  {label: 'זכויות חוזיות לבעלות', value: 4},
];
export const affiliationOptions = [
  {label: 'חברה', value: 1},
  {label: 'עירייה', value: 2},
  {label: 'מוסד', value: 3},
  {label: 'פרטי', value: 4}
];

//Step 3
export const presentorGenderOptions = [
  {label: 'זכר', value: 1},
  {label: 'נקבה', value: 2}
];
export const propertyPopulatedOptions = [
  {label: 'בעלים', value: 1},
  {label: 'שוכר', value: 2},
  {label: 'דייר מוגן', value: 3},
  {label: 'הנכס פנוי וריק', value: 4},
  {label: 'מחזיק ללא חוזה שכירות', value: 5},
  {label: 'הנכס בבניה ואינו ראוי למגורים במצבו', value: 6},
  {label: 'מגרש ריק', value: 7},
];
export const attachedOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 2}
];
export const houseSecureRoomOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 2}
];
export const landTopographyOptions = [
  {label: 'נוחה', value: 1},
  {label: 'הפרשי גבהים בחלקה', value: 2},
];
export const buildingElevatorOptions = [
  {label: '0', value: 0},
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
];
export const buildingIntercomOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2}
];
export const buildingLocationOptions = [
  {label: 'חזית', value: 1},
  {label: 'עורף', value: 2},
  {label: 'פינתי', value: 3},
  {label: 'אמצעי', value: 4},
  {label: 'צדדי', value: 5},
  {label: 'חזית ועורף', value: 6},
  {label: 'פנימית', value: 7},
];
export const buildingOnPillarsOptions = [
  {label: 'עמודים מפולשת', value: 1},
  {label: 'קרקע', value: 2},
  {label: 'מסחר', value: 3},
  {label: 'מרתף', value: 4},
  {label: 'מקלט', value: 5},
  {label: 'מסד', value: 6},
  {label: 'כניסה', value: 7},
  {label: 'עמודים ומרתף', value: 8},
  {label: 'עמודים ומסחר', value: 9},
  {label: 'קרקע ומרתף', value: 10},
  {label: 'קרקע למגורים', value: 11},
  {label: 'אחר', value: 12},
];
export const buildingParkingOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 2},
];
export const buildingMaintenanceOptions = [
  {label: 'תקין', value: 1},
  {label: 'לא תקין', value: 2},
  {label: 'הנכס בהליכי בניה', value: 3},
];
export const buildingPhysicalStateOptions = [
  {label: 'תקין', value: 1},
  {label: 'לא תקין', value: 2},
  {label: 'תואם לגילו', value: 3},
  {label: 'הנכס בהליכי בניה', value: 4},
];
export const buildingRoofTypeOptions = [
  {label: 'בטון', value: 1},
  {label: 'רעפים', value: 2},
  {label: 'רעפים ובטון', value: 3},
  {label: 'פנל מבודד', value: 4},
  {label: 'עץ', value: 5},
  {label: 'אסבסט', value: 6},
];
export const buildingPopulatedOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2},
];
export const buildingOuterLayerOptions = [
  {label: 'לוחות אבן', value: 1},
  {label: 'טיח', value: 2},
  {label: 'שפריץ', value: 3},
  {label: 'בטון', value: 4},
  {label: 'פסיפס קרמי', value: 5},
  {label: 'שליכט', value: 7},
  {label: 'גרנוליט', value: 8},
  {label: 'אלומינים', value: 9},
  {label: 'קירות מסך', value: 10},
  {label: 'שליכט אקרילי', value: 11},
  {label: 'אחר', value: 6},
];
export const buildingBuildTypeOptions = [
  {label: 'קונבנציונלית', value: 1},
  {label: 'טרומית', value: 2},
  {label: 'בניה קלה', value: 4},
  {label: 'עץ', value: 5},
  {label: 'מתועש', value: 6},
  {label: 'אחר', value: 3},
];
export const housePhysicalStateOptions = [
  {label: 'תקין', value: 1},
  {label: 'לא תקין', value: 2},
  {label: 'תואם לגילו', value: 3},
  {label: 'הנכס בהליכי בניה', value: 4},
];
export const houseFinishGradeOptions = [
  {label: 'נאה', value: 1},
  {label: 'מודרנית', value: 2},
  {label: 'פשוטה', value: 3},
  {label: 'יוקרתית', value: 4},
  {label: 'נמוכה', value: 5},
  {label: 'ירודה', value: 6},
  {label: 'לא אחידה', value: 7},
  {label: 'רגילה', value: 8},
  {label: 'טובה', value: 9},
  {label: 'לא רלוונטי', value: 10},
  {label: 'הנכס בהליכי בניה', value: 11},
  {label: 'משופרת', value: 12},
];
export const houseMaintenanceStatusOptions = [
  {label: 'תקין', value: 1},
  {label: 'לא תקין', value: 2},
  {label: 'רגיל', value: 3},
  {label: 'טוב', value: 4},
  {label: 'לא רלוונטי', value: 5},
  {label: 'הנכס בהליכי בניה', value: 6},
];
export const houseYardAffectiveOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2},
];
export const houseYardTypeOptions = [
  {label: 'רגולרית', value: 1},
  {label: 'לא רגולרית', value: 2},
];
export const houseParkingTypeOptions = [
  {label: 'טורית', value: 1},
  {label: 'בודדת', value: 2},
  {label: 'מתקן חניה', value: 3},
  {label: 'מקורה', value: 4},
  {label: 'לא מקורה', value: 5},
  {label: 'תת קרקעית', value: 6},
  {label: 'מקבילה', value: 7},
];
export const houseStorageTypeOptions = [
  {label: 'תת קרקעי', value: 1},
  {label: 'על קרקעי', value: 2},
];
export const houseRoofAccessOptions = [
  {label: 'עם יציאה לגג', value: 1},
  {label: 'ללא יציאה לגג', value: 2},
];
export const visitorJobOptions = [
  {label: 'שמאי מקרקעין', value: 1},
  {label: 'שמאית מקרקעין', value: 2},
  {label: 'מתמחה', value: 3},
  {label: 'בוגר התמחות', value: 4},
  {label: 'בוגרת התמחות', value: 5},
];
export const presentorAssociationOptions = [
  {label: 'בעלים', value: 1},
  {label: 'שוכר', value: 2},
  {label: 'מתווך', value: 3},
  {label: 'שוכר שסירב להזדהות', value: 4},
  {label: 'קרוב משפחה', value: 5},
  {label: 'נציג מטעם המזמין', value: 6},
  {label: 'שכן', value: 7},
  {label: 'בעל מגרש שכן', value: 8},
  {label: 'מוכר', value: 9},
  {label: 'קונה', value: 10},
  {label: 'דייר מוגן', value: 11},
  {label: 'חוכר', value: 12},
];
export const buildingMatchingEnvironmentOptions = [
  {label: 'לא תואם', value: 1},
  {label: 'תואם', value: 2},
];
export const houseMatchingBuildingOptions = [
  {label: 'תואמת', value: 1},
  {label: 'לא תואמת', value: 2},
];
export const presentorIdTypeOptions = [
  {label: 'ת.ז.', value: 1},
  {label: 'דרכון', value: 2},
  {label: 'רשיון נהיגה', value: 3},
];
export const buildingTypeIdOptions = [
  {label: 'בניה רוויה', value: 1},
  {label: 'צמוד קרקע', value: 2},
  {label: 'בית מגורים משותף במסגרת תמ”א 38', value: 3},
];
export const rightsRegistryRegulationsOptions = [
  {label: 'מצוי', value: 1},
  {label: 'מוסכם', value: 2},
  {label: 'מתוקן', value: 3},
];

// Step 4
export const buildTypeOptions = [
  {label: 'נמוכה', value: 1},
  {label: 'גבוהה', value: 2},
  {label: 'מעורבת', value: 3}
];
export const buildPeriodOptions = [
  {label: 'חדשה', value: 1},
  {label: 'ותיקה', value: 2},
  {label: 'מתקופות שונות', value: 3}
];
export const facingOptions = [
  {label: 'צפונית', value: 1},
  {label: 'דרומית', value: 2},
  {label: 'מזרחית', value: 3},
  {label: 'מערבית', value: 4},
];
export const streetLocationOptions = [
  {label: 'ראשי', value: 1},
  {label: 'מקומי', value: 2},
  {label: 'מרכזי', value: 3},
  {label: 'פנימי', value: 4},
];
export const streetTypeOptions = [
  {label: 'חד סיטרי', value: 1},
  {label: 'דו סיטרי', value: 2},
  {label: 'כיכר', value: 3},
  {label: 'סמטה', value: 4},
  {label: 'הולנדי', value: 5},
  {label: 'רחוב סגור', value: 6},
  {label: 'רחוב פתוח', value: 7},
  {label: 'רחוב קניות', value: 8},
  {label: 'שדרת פארק', value: 9},
  {label: 'מעגלי', value: 10},
];
export const streetTrafficOptions = [
  {label: 'דלילה', value: 1},
  {label: 'ערה', value: 2},
  {label: 'בינונית', value: 3},
];
export const parkingIssuesTimesOptions = [
  {label: 'בכל שעות היממה', value: 1},
  {label: 'בערב', value: 2},
  {label: 'בבוקר', value: 3},
];
export const landSurroundingsOptions = [
  {label: 'חצרות', value: 1},
  {label: 'בנייני מגורים', value: 2},
  {label: 'בניינים גבוהים', value: 3},
  {label: 'גינה ציבורית', value: 4},
  {label: 'גן ילדים', value: 5},
  {label: 'בית כנסת', value: 6},
  {label: 'בית עלמין', value: 7},
  {label: 'מבני ציבור אחרים', value: 8},
  {label: 'אחר', value: 9},
];

// Step 5
export const buildingJointHomeOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2}
];
export const houseJointHomeRegisteredOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2}
];
export const landShapeOptions = [
  {label: 'רגולרית', value: 1},
  {label: 'לא רגולרית', value: 2},
  {label: 'פינתית אי רגולרית', value: 3},
  {label: 'פינתית רגולרית', value: 4},
];
export const landDirectionOptions = [
  {label: 'צפונית', value: 1},
  {label: 'דרומית', value: 2},
  {label: 'מערבית', value: 3},
  {label: 'מזרחית', value: 4},
];

// Step 6
export const buildPermitReasonOptions = [
  {label: 'תכנית שינויים', value: 1},
  {label: 'בניין חדש', value: 2}
];
export const supervisionFileOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 2},
  {label: 'לא ידוע', value: 3},
];
export const exceedUsePermitOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 2},
  {label: 'לא ידוע', value: 3},
];
export const exceedBuildingOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 2}
];
export const form4Options = [
  {label: 'הוצג', value: 1},
  {label: 'לא הוצג', value: 2}
];
export const completeCertOptions = [
  {label: 'הוצג', value: 1},
  {label: 'לא הוצג', value: 2}
];
export const dangerousStructureOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2},
  {label: 'לא ידוע', value: 3},
];

// Step 9 - TabSurveyAssets
export const ddOptions = [
  {label: 'יש', value: 1},
  {label: 'אין', value: 0},
];

// CompleteInspection
export const assessorGenderOptions = [
  {label: 'שמאי', value: 1},
  {label: 'שמאית', value: 2},
]

export const customerOptions = [
  {label: 'חברה', value: 1},
  {label: 'עירייה', value: 2},
  {label: 'מוסד', value: 3},
  {label: 'פרטי', value: 4},
]

//Step 12
export const rentEssenseOptions = [
  {label: 'חופשית', value: 1},
  {label: 'מוגנת', value: 2},
]
export const loanToPropertyBondOptions = [
  {label: 'בעלים', value: 1},
  {label: 'חוכר', value: 2},
  {label: 'רוכש', value: 3},
  {label: 'אחר', value: 4},
]
export const buildingMatchSurroundingsOptions = [
  {label: 'תואם', value: 1},
  {label: 'לא תואם', value: 2},
]
export const propertyLoanLegalRelationOptions = [
  {label: 'בעלים', value: 1},
  {label: 'חוכר', value: 2},
  {label: 'רוכש', value: 3},
  {label: 'אחר', value: 4},
]
export const propertyIdentifiedByOptions = [
  {label: 'פורטל לאומי/עירוני (מערכת מיפוי ממשלתית)', value: 1},
  {label: 'תשריט היתר בניה', value: 2},
  {label: 'תכנית בניין עיר', value: 3},
  {label: 'טופס ארנונה', value: 4},
  {label: 'הסכם שכירות', value: 5},
  {label: 'תיבת דואר', value: 6},
  {label: 'אישור מצב זכויות', value: 7},
  {label: 'הסכם מכר/רכישה', value: 8},
  {label: 'הסכם שיתוף', value: 9},
  {label: 'חוזה חכירה', value: 10},
  {label: 'תשריט בית משותף', value: 11},
  {label: 'נסח רישום מקרקעין', value: 12},
  {label: 'מפת מודד', value: 13},
  {label: 'ייפוי כוח לנציגי הקבוצה', value: 14},
  {label: 'תעודת גמר', value: 15},
  {label: 'תשריט חכירה', value: 16},
  {label: 'דף מידע תכנוני', value: 17},
  {label: 'תשריטי מכר', value: 18},
]

//Complete inspection
export const presenterGenderFirstLabelOptions = [
  {label: 'המציג, מר', value: 1},
  {label: 'המציגה, גברת', value: 2},
]

export const presenterGenderSecondLabelOptions = [
  {label: 'זוהה', value: 1},
  {label: 'זוהתה', value: 2},
]

export const presenterGenderThirdLabelOptions = [
  {label: 'זיקתו של המציג', value: 1},
  {label: 'זיקתה של המציגה', value: 2},
]

export const yardEffectivityOptions = [
  {label: 'אפקטיבית', value: 1},
  {label: 'לא אפקטיבית', value: 2},
]

export const completeCertYesNoOptions = [
  {label: 'כן', value: 1},
  {label: 'לא', value: 2}
];

export const floorOptions = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: 'גג', value: 4},
  {label: 'מרתף', value: 5},
  {label: 'גלריה', value: 6},
];
export const lotPropertyFloorOptions = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: 'גג', value: 4},
  {label: 'מרתף', value: 5},
  {label: 'גלריה', value: 6},
  {label: 'חזית הנכס', value: 7},
];
export const step9PropertyTypeOptions = [
  {label: 'דירת מגורים', value: 1},
  {label: 'בניין', value: 2},
  {label: 'קרקע חקלאית', value: 3},
  {label: 'קרקע', value: 4},
  {label: 'כללי', value: 5}
];
export const step9TransactionOptions = [
  {label: 'הכל', value: 0},
  {label: 'דירה בבית קומות', value: 1},
  {label: 'דירת גן', value: 2},
  {label: 'דירת גג', value: 3},
  {label: 'קוטג\' חד משפחתי', value: 4},
  {label: 'קוטג\' דו משפחתי', value: 5},
  {label: 'קוטג\' טורי', value: 6},
  {label: 'בית בודד', value: 7},
  {label: 'דירת נופש', value: 8},
  {label: 'דיור מוגן', value: 9},
  {label: 'קומבינציה', value: 10},
  {label: 'משק חקלאי - נחלה', value: 11},
  {label: 'אופציה', value: 12},
  {label: 'פנטהאוז', value: 13},
];
export const step9EmploymentPeriodOptions = [
  {label: 'מותאם אישית', value: 1},
  {label: 'ב 3 חודשים האחרונים', value: 2},
  {label: 'ב 6 חודשים האחרונים', value: 3},
  {label: 'ב 12 חודשים האחרונים', value: 4},
  {label: 'ב 36 חודשים האחרונים', value: 5}
];
export const taskStatuses = [
  {label: 'פתוחה', value: 1},
  {label: 'סגורה', value: 2},
];
export const taskTypes = [
  {label: 'ביקור בנכס', value: 1},
  {label: 'כללי', value: 2},
];
export const elevatorPresence = [
  {label: 'אין מעלית', value: 0},
  {label: 'מעלית קיימת', value: 1},
];
export const shumaStatuses = [
  {label: 'בתהליך', value: 1},
  {label: 'נדחה', value: 3},
  {label: 'אושר', value: 4},
  {label: 'הסתיים', value: 5},
  {label: 'ממתין לבדיקה', value: 6},
  {label: 'ממתין לאישור סופי', value: 7},
];

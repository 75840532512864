import React from 'react';
import classnames from 'classnames';
import {convertFileToContent} from '../../FileToContentConverter';
import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import ShumaType3Line from './ShumaType3Line';
import {getPropertyRightsDetails} from './Section8PropertyRightsDetails';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {findFileByTypeId} from '../../../utils/files';
import styles from './styles.module.scss';

const fileTypeID = 1;

const Section8Title = () => {
  const context = useCompleteInspectionContext();
  const title = (
    <>
      <span>{context.sectionNumbers.section8}</span>
      <span>. הרקע הקנייני</span>
    </>
  );

  return context.display.section8 && <SectionTitle title={title}/>;
};

const getAssets = shumaTypeID => {
  switch (shumaTypeID) {
    case 3:
      return {
        section8Line: <ShumaType3Line/>,
        section8PropertyRightsDetails: context => getPropertyRightsDetails(context),
      };
    case 2:
    case 1:
      return {
        section8Line: null,
        section8PropertyRightsDetails: context => getPropertyRightsDetails(context)
      };
    default:
      return {};
  }
};

const getS8List = (context, title, assets) => {
  const {cityName} = context.data.pdfData;

  return [
    <Section8Title/>,
    <div className={styles.innerPadding}>{assets.section8Line}</div>,
    ...assets.section8PropertyRightsDetails(context).map(item => (
      <div className={styles.innerPadding}>{item}</div>
    )),
    ...convertFileToContent(context, fileTypeID, {
      firstPageHeader: (
        <div className={classnames(styles.itemText, styles.bold)}>
          עפ"י מידע עדכני מאת לשכת רישום המקרקעין {cityName}, להלן יובא
          מידע
          הרישום הרלוונטי:
        </div>
      ),
      lastPageFooter: (
        <Section
          customClassNames={{content: classnames(styles.itemText, styles.bold)}}
        >
          חוו"ד זו אינה חוו"ד משפטית וכל האמור בסעיף זה הינו כרקע לחוו"ד
          בהתאם
          לממצאים המצויים בפני
          הח"מ במועד עריכת חוו"ד.
        </Section>
      ),
    })
  ];
};

const getS8File = (context, title, assets) => {
  const {cityName} = context.data.pdfData;

  return convertFileToContent(context, fileTypeID, {
    firstPageHeader: (
      <Section
        title={title}
        customClassNames={{content: styles.noInnerPadding}}
      >
        {assets.section8Line}
        <div className={classnames(styles.itemText, styles.bold)}>
          עפ"י מידע עדכני מאת לשכת רישום המקרקעין {cityName}, להלן יובא
          מידע
          הרישום הרלוונטי:
        </div>
      </Section>
    ),
    lastPageFooter: (
      <Section
        customClassNames={{content: classnames(styles.itemText, styles.bold)}}
      >
        חוו"ד זו אינה חוו"ד משפטית וכל האמור בסעיף זה הינו כרקע לחוו"ד
        בהתאם
        לממצאים המצויים בפני
        הח"מ במועד עריכת חוו"ד.
      </Section>
    ),
  });
};

const getS8Text = (context, title, assets) => {
  return [
    <Section8Title/>,
    <div className={styles.innerPadding}>{assets.section8Line}</div>,
    ...assets.section8PropertyRightsDetails(context).map(item => (
      <div className={styles.innerPadding}>{item}</div>
    )),
    !context.data.pdfData.displayNoNesachText && (
      <div
        className={classnames(styles.itemText, styles.innerPadding, styles.bold, styles.lastSectionLine)}
      >
        במועד חוות הדעת לא ניתן היה להפיק נסח רישום עדכני והממצאים
        המפורטים בסעיף זה על פי המצוי בפני עורכ/ת
        חוות הדעת. במידה ויוצגו ממצאים שיש בהם לסתור את האמור לעיל, חוות
        הדעת תעודכן בהתאם לרלוונטיות.
      </div>
    )
  ];
};

export const getSection8 = (context) => {
  const file = findFileByTypeId(context.files, fileTypeID);
  const assets = getAssets(context.shumaTypeID);
  const {propertyRightsDetails} = context.data.pdfData;
  const title = (
    <>
      <span>{context.sectionNumbers.section8}</span>
      <span>. הרקע הקנייני</span>
    </>
  );

  if (file) {
    return (Boolean(propertyRightsDetails))
      ? getS8List(context, title, assets)
      : getS8File(context, title, assets);
  } else {
    return getS8Text(context, title, assets);
  }
};

import React, {useState} from "react";
import {useDispatch, connect} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {login} from "../../_redux/authActions";
import "../../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import {useFormik} from "formik";
import * as Yup from "yup";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../../_redux/authRedux";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {images as I} from '../../constants/images';
import styles from './styles.module.scss';

const initialValues = {
  username: "",
  password: ""
};

export function AuthPage(props) {
  const history = useHistory();
  const location = useLocation();
  if (location.pathname !== "/") history.replace("/");

  const {intl} = props;
  const usernamePlaceholder = intl.formatMessage({id: 'auth-username-label'});
  const passwordPlaceholder = intl.formatMessage({id: 'auth-password-label'});

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setStatus(undefined);
      enableLoading();
      setTimeout(() => {
        Promise
          .resolve()
          .then(() => {
            const {username, password} = values;
            return dispatch(login({username, password}));
          })
          .then(() => {
            disableLoading();
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div
      className="login login-5 login-signin-on d-flex flex-row-fluid"
      id="kt_login"
    >
      <div
        className={`d-flex justify-content-center pt-40 bgi-size-cover bgi-no-repeat flex-row-fluid ${styles.background}`}
      >
        <div
          className="login-form text-center text-white p-25 position-relative overflow-hidden">
          <div className="d-flex flex-center mb-15">
            <img
              src={toAbsoluteUrl(I.LOGO)}
              className="w-100"
              alt="logo"
            />
          </div>
          <div className="login-signin">
            <div className="mb-20">
              <h2 className="font-weight-normal text-dark">
                <FormattedMessage id="AUTH.LOGIN.TITLE"/>
              </h2>
              <h4 className="font-weight-normal text-dark">
                <FormattedMessage id="auth-form-title"/>
              </h4>
            </div>
            <form
              className="form"
              id="kt_login_signin_form"
              onSubmit={formik.handleSubmit}
            >
              <div className="form-group">
                <input
                  className="form-control h-auto bg-white rounded-pill shadow py-4 px-8"
                  type="text" placeholder={usernamePlaceholder} name="username"
                  {...formik.getFieldProps("username")}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <input
                  className="form-control h-auto bg-white rounded-pill shadow py-4 px-8"
                  type="password" placeholder={passwordPlaceholder}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
              </div>
              <div className="form-group text-center mt-10 position-relative">
                {loading && <span className={`spinner spinner-dark position-absolute mt-6 ml-16 left-0`}/>}
                <button id="kt_login_signin_submit"
                        className="btn btn-pill btn-warning text-dark font-size-lg font-weight-bolder shadow opacity-90 px-15 py-3">
                  <FormattedMessage
                    id="auth-login-btn"
                    defaultMessage="Sign in"
                  />
                </button>
              </div>
              {formik.status &&
              <div className="alert alert-danger mb-10">{formik.status}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const connectedAuthPage = connect(null, auth.actions)(AuthPage);
export default injectIntl(connectedAuthPage);

import React from 'react';
import {FormattedMessage} from "react-intl";
import {usePopupCopyInspectionContext} from "../PopupCopyInspectionContext";
import CommonButton from "../../../_common/components/CommonButton";

const PopupButtons = ({closeModal, shumaID}) => {
  const {handleSubmit, formik: {isValid}} = usePopupCopyInspectionContext();

  return (
    <div className={'w-50 mx-auto mt-10 d-flex justify-content-around'}>
      <CommonButton
        variant={'button'}
        onClick={closeModal}
        label={
          <FormattedMessage
            id="inspections-popup-copy-cancel"
            defaultMessage="Cancel"
          />
        }
      />
      <CommonButton
        variant={'submit'}
        onClick={() => {
          closeModal();
          handleSubmit(shumaID);
        }}
        disabled={!isValid}
        label={
          <FormattedMessage
            id="inspections-popup-copy-submit"
            defaultMessage="Copy"
          />
        }
      />
    </div>
  )
};

export default PopupButtons;

import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const ShumaType2Title = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.itemTitle, styles.bold)} ref={ref}>
      <span>{context.sectionNumbers.section10}</span>
      <span>. השומה</span>
    </div>
  );
});

export default ShumaType2Title;

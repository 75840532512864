import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import * as _ from 'lodash';
import {checkIfTextEndsWithDot, getBreakLinesTextShumaType3, getTextChunks} from '../../../utils/texts';
import {Counter} from '../../../utils/counter';
import styles from './styles.module.scss';

const mapTextChunks = (chunk, index, chunks) => {
  return (
    <span
      className={classnames(
        {[styles.emptyLine]: chunk.length === 0}
      )}
      key={index + 1}
    >
      {chunk}
      {(index === chunks.length - 1) ? checkIfTextEndsWithDot(chunk) : ''}
    </span>
  );
};

const mapListItem = ({id, counter, sectionIndex, firstLineText = ''}) => (item, index) =>
  (index === 0)
    ? (
      <div
        className={classnames(styles.innerPadding)}
        key={index + 1}
        data-testid={id}
      >
        <span>{sectionIndex}.{counter.nextNumber()}</span>
        <span className={classnames(styles.textRegular, styles.paddingOne)}>
          {(index === 0) && firstLineText}
          {item}
        </span>
      </div>
    )
    : (
      <div
        className={classnames(styles.innerPadding, styles.lineContainer)}
        key={index + 1}
        data-testid={id}
      >
        <div className={classnames(styles.textRegular, styles.itemPadding)}>
          {item}
        </div>
      </div>
    );

const HeadLine1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyLocation,
        propertyProperties,
        planningBackground,
        possessoryBackground,
        areaPropertiesPrices,
        st8Other,
        mortgageEntityEmphasis,
      }
    },
  } = useCompleteInspectionContext();
  const context = useCompleteInspectionContext();
  const showHeader =
    _.some([
      propertyLocation,
      propertyProperties,
      planningBackground,
      possessoryBackground,
      areaPropertiesPrices,
      st8Other,
      mortgageEntityEmphasis
    ]);
  const showSubHeader =
    _.some([
      propertyLocation,
      propertyProperties,
      planningBackground,
      possessoryBackground,
      areaPropertiesPrices,
      st8Other,
    ]);

  return (
    <div ref={ref} data-testid={'Section13Assets.getSection13HeadLine'}>
      <HeadContent/>
      <SubHead1Content/>
      {(showHeader || showSubHeader) && getSection13Content(context)[0]}
    </div>
  );
});

const HeadContent = () => {
  const {
    data: {
      pdfData: {
        propertyLocation,
        propertyProperties,
        planningBackground,
        possessoryBackground,
        areaPropertiesPrices,
        st8Other,
        mortgageEntityEmphasis,
      }
    },
    sectionNumbersShumaType3
  } = useCompleteInspectionContext();
  const showHeader13 =
    _.some([
      propertyLocation,
      propertyProperties,
      planningBackground,
      possessoryBackground,
      areaPropertiesPrices,
      st8Other,
      mortgageEntityEmphasis
    ]);

  return showHeader13 && (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
          <span className={classnames(styles.textUnderline, styles.bold)}>
            <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section13}. `}</span>
            <span>עקרונות גורמים ושיקולים, הערות כלליות, התניות וסייגים - לתשומת לב הבנק</span>
          </span>
    </div>
  );
};

const SubHead1Content = () => {
  const {
    data: {
      pdfData: {
        propertyLocation,
        propertyProperties,
        planningBackground,
        possessoryBackground,
        areaPropertiesPrices,
        st8Other,
      },
    },
  } = useCompleteInspectionContext();
  const showSubHeaderCondition =
    _.some([
      propertyLocation,
      propertyProperties,
      planningBackground,
      possessoryBackground,
      areaPropertiesPrices,
      st8Other,
    ]);

  return showSubHeaderCondition && (
    <div className={classnames(
      styles.textRegular,
      styles.sectionContainer,
      styles.innerPadding,
      styles.bold
    )}>
      עקרונות גורמים ושיקולים:
    </div>
  );
};

const getSection13Content = context => {
  const {
    propertyLocation,
    propertyProperties,
    planningBackground,
    possessoryBackground,
    areaPropertiesPrices,
    st8Other
  } = context.data.pdfData;
  const sectionIndex = context.sectionNumbersShumaType3.section13;

  const counter = new Counter();
  const propertyLocationChunks = Boolean(propertyLocation)
    ? getTextChunks(propertyLocation)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'propertyLocation',
        counter,
        sectionIndex
      }))
    : [];
  const propertyPropertiesChunks = Boolean(propertyProperties)
    ? getTextChunks(propertyProperties)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'propertyProperties',
        counter,
        sectionIndex
      }))
    : [];
  const planningBackgroundChunks = Boolean(planningBackground)
    ? getTextChunks(planningBackground)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'planningBackground',
        counter,
        sectionIndex
      }))
    : [];
  const possessoryBackgroundChunks = Boolean(possessoryBackground)
    ? getTextChunks(possessoryBackground)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'possessoryBackground',
        counter,
        sectionIndex
      }))
    : [];
  const areaPropertiesPricesChunks = Boolean(areaPropertiesPrices)
    ? getTextChunks(areaPropertiesPrices)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'areaPropertiesPrices',
        counter,
        sectionIndex
      }))
    : [];
  const st8OtherChunks = Boolean(st8Other)
    ? getTextChunks(st8Other)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'st8Other',
        counter,
        sectionIndex
      }))
    : [];

  return [
    ...propertyLocationChunks,
    ...propertyPropertiesChunks,
    ...planningBackgroundChunks,
    ...possessoryBackgroundChunks,
    ...areaPropertiesPricesChunks,
    ...st8OtherChunks
  ];
};

export const getSection13 = (context) => {
  const {
    data: {
      pdfData: {
        propertyLocation,
        propertyProperties,
        planningBackground,
        possessoryBackground,
        areaPropertiesPrices,
        st8Other,
        mortgageEntityEmphasis,
      }
    },
  } = context;
  const showHeader =
    _.some([
      propertyLocation,
      propertyProperties,
      planningBackground,
      possessoryBackground,
      areaPropertiesPrices,
      st8Other,
      mortgageEntityEmphasis
    ]);
  const showSubHeader =
    _.some([
      propertyLocation,
      propertyProperties,
      planningBackground,
      possessoryBackground,
      areaPropertiesPrices,
      st8Other,
    ]);

  return (showHeader || showSubHeader)
    ? getSection13Content(context).slice(1)
    : getSection13Content(context);
};

const Line1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        mortgageEntityEmphasis
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(mortgageEntityEmphasis) && (
        <div className={classnames(
          styles.textRegular,
          styles.sectionContainer,
          styles.innerPadding,
        )}>
          <div className={styles.bold}>דגשים לגוף המשעבד:</div>
          <div>{getBreakLinesTextShumaType3(mortgageEntityEmphasis)}</div>
        </div>
      )}
    </div>
  );
});

export default {
  HeadLine1,
  Line1,
};

import React from 'react';
import SectionTitle from '../../SectionTitle';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';

const Section6Title = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const title = (
    <>
      <span>{context.sectionNumbers.section6}</span>
      <span>. תאור הנכס</span>
    </>
  );

  return <SectionTitle title={title} ref={ref}/>;
});

export default Section6Title;

import React from 'react';
import {getSection9} from './Section9';
import SectionTitle from '../../SectionTitle';
import styles from './styles.module.scss';

export const getShumaType1Section9 = (context) => {
  const title = (
    <>
      <span>{context.sectionNumbers.section9}</span>
      <span>. חוות הדעת</span>
    </>
  );

  return [
    <SectionTitle title={title} key={0}/>,
    ...getSection9(context),
    <div className={styles.lastSectionLine}/>
  ];
};

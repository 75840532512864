import React from 'react';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {formattingDate} from '../../utils/formattingHelper';
import styles from './styles.module.scss';

export const DocPropsDateShuma = () => {
  const context = useCompleteInspectionContext();
  const {pdfCreateTime, firmShumaID} = context.data.pdfData;
  const pdfCreateTimeValue = formattingDate(pdfCreateTime);

  return context.shumaTypeID === 3
    ? (
      <div className={styles.logoDocProps}>
        <div className={styles.docPropsItem}>
          תאריך: {pdfCreateTimeValue}
        </div>
        <div className={styles.docPropsItem}>
          מס' חוות הדעת: {firmShumaID}
        </div>
      </div>
    )
    : null;
};

export default DocPropsDateShuma;

import axios from 'axios';
import moment from "moment";

export const GET_LIST = '/tasks/getList';
export const DELETE_TASK = "/tasks/deleteTask";
export const ADD_EDIT_VISIT_TASK = "/tasks/addEditVisitTask";
export const ADD_EDIT_GENERAL_TASK = "/tasks/addEditGeneralTask";
export const GET_TASK_DETAILS = "/tasks/getTaskDetails";

export const getList = (shumaID) => {
  const data = {shumaID};

  return axios.post(GET_LIST, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body?.list;
    });
};

export const deleteTask = (taskID) => {
  const data = {taskID: parseInt(taskID, 10)};

  return axios.post(DELETE_TASK, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
};

export const addEditGeneralTask = ({
                                     taskID = -1,
                                     shumaID,
                                     responsibleUserID,
                                     taskStatusID,
                                     dueDate,
                                     taskDescription
                                   }) => {
  const data = {
    taskID: parseInt(taskID, 10),
    shumaID: parseInt(shumaID, 10),
    responsibleUserID: parseInt(responsibleUserID, 10),
    taskStatusID: parseInt(taskStatusID, 10),
    dueDate: moment(dueDate).format('YYYY-MM-DD'),
    taskDescription
  };

  return axios.post(ADD_EDIT_GENERAL_TASK, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();

    })
    .catch(error => {
      error.clientMessage = "Can't add/edit task";
    });
};

export const addEditVisitTask = ({
                                   taskID = -1,
                                   shumaID,
                                   responsibleUserID,
                                   taskStatusID,
                                   dueDate,
                                   notes,
                                   visitContactName,
                                   visitContactPhone
                                 }) => {
  const data = {
    taskID: parseInt(taskID, 10),
    shumaID: parseInt(shumaID, 10),
    responsibleUserID: parseInt(responsibleUserID, 10),
    taskStatusID: parseInt(taskStatusID, 10),
    dueDate: moment(dueDate).format('YYYY-MM-DD'),
    notes,
    visitContactName,
    visitContactPhone
  };

  return axios.post(ADD_EDIT_VISIT_TASK, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();

    })
    .catch(error => {
      error.clientMessage = "Can't add/edit task";
    });
};

export const getTaskDetails = (taskID) => {
  const data = {taskID: parseInt(taskID, 10)};

  return axios.post(GET_TASK_DETAILS, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body?.task;
    })
};

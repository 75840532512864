import React from 'react';
import {SectionRef} from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingDate} from '../../../utils/formattingHelper';
import {getShumaType2and3Section1LoanTypeSentenceText} from '../../../utils/texts';
import styles from './styles.module.scss';

const ShumaType3Section1 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    pdfData: {
      usingLawyer,
      lawyerName,
      bankName,
      purchaseDate,
      purchase
    },
    pdfDataCustomers
  } = context.data;

  const customersNames = (pdfDataCustomers.length > 0)
    ? pdfDataCustomers.map(customer => customer.name).join(' ו - ')
    : '';
  const lawyerNameValue = (usingLawyer === 1)
    ? `באמצעות עו"ד ${lawyerName},`
    : '';
  const title = (
    <>
      <span>{context.sectionNumbers.section1}</span>
      <span>. מטרת השומה</span>
    </>
  );

  return (
    <SectionRef title={title} ref={ref}>
      <div className={styles.itemText}>
        <span>נתבקשתי ע"י ה"ה</span>
        {
          Boolean(lawyerNameValue)
            ? ` ${customersNames} ${lawyerNameValue} `
            : ` ${customersNames}, ${lawyerNameValue} `
        }
        <span data-testid="shumaType3Section1Text">
          <span>
            להעריך את שווי שוק וערך למטרת ביטחון אשראי ל
          </span>
          {`${bankName}.`}
          <span> שווי שוק יוגדר בחוו"ד זו כשווי הסביר העשוי להתקבל בעסקת מכר שבין מוכר מרצון לקונה מרצון, בתנאי שוק חופשי כאשר הנכס ריק פנוי וחופשי מכל חוב שיעבוד, עיקול, חזקה, ו/או זכויות צד ג' כלשהן. סוג ההלוואה עפ"י ההפנייה: כספי בנק.
השומה הוזמנה לצורך מתן הלוואה. מטרת ההלוואה הנה מימון</span>
          {getShumaType2and3Section1LoanTypeSentenceText(context)}
          <span>
            {Boolean(purchase) && (
              <span>
              <span> בהתאם להסכם רכישה </span>
                {Boolean(purchaseDate) ? ` מיום ${formattingDate(purchaseDate)} ` : ''}
                <span>אשר הוצג בפני הח"מ</span>
              </span>
            )}
            <span>.</span>
        </span>
        </span>
      </div>
    </SectionRef>
  );
});

export default ShumaType3Section1;

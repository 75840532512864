import React, {createContext, useContext, useState, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {isEqual, isFunction} from "lodash";

const PriceQuotesPageUIContext = createContext('');

export function usePriceQuotesPageUIContext() {
  return useContext(PriceQuotesPageUIContext);
}

export const OrdersPageUIConsumer = PriceQuotesPageUIContext.Consumer;

export function PriceQuotesPageUIProvider({children}) {
  const history = useHistory();
  const [queryParams, setQueryParamsBase] = useState({});
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      for (let key in nextQueryParams) {
        if (nextQueryParams.hasOwnProperty(key) && !nextQueryParams[key]) delete nextQueryParams[key]
      }
      const path = history.location.pathname;
      const newQueryParams = new URLSearchParams(nextQueryParams);
      history.replace(`${path}?${newQueryParams.toString()}`);

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <PriceQuotesPageUIContext.Provider value={value}>{children}</PriceQuotesPageUIContext.Provider>;
}

import {DashboardPageLink} from './DashboardPage';
import {PriceQuotesPageLink} from './PriceQuotesPage';
import {OrdersPageLink} from './OrdersPage';
import {OrderDetailsLink} from './OrderDetails';
import {InspectionsPageLink} from './InspectionsPage';
import {CustomersEntityLink} from './CustomersEntity';
import {OldInspectionDetailsLink} from './OldInspectionDetails';
import {InspectionDetailsLink} from './InspectionDetails';
import {CustomerDetailsLink} from './CustomerDetails';
import {TasksLink} from './TasksPage';


export default [
  DashboardPageLink,
  PriceQuotesPageLink,
  OrdersPageLink,
  OrderDetailsLink,
  TasksLink,
  InspectionsPageLink,
  InspectionDetailsLink,
  OldInspectionDetailsLink,
  CustomersEntityLink,
  CustomerDetailsLink,
]

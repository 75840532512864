import {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

const initialRequestedPageNumber = 0;

const useInfiniteScrollControl = ({
                                    currentStateStoreId,
                                    UIContext,
                                    getNewEntities,
                                    getNextEntities,
                                    onEntitiesChange,
                                    ...restProps
                                  }) => {
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(initialRequestedPageNumber);

  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  const createURLSearchParamsInstance = () => new URLSearchParams(window.location.search);
  const currentQueryParams = createURLSearchParamsInstance();

  const {currentState} = useSelector(
    (state) => ({currentState: state[currentStateStoreId]}),
    shallowEqual
  );

  const {pageCount, entities} = currentState;

  const getEntities = (requestedPageNumber) => {
    // select missed query params and clear
    const paramsFromURL = {};
    const queryParamsFromURL = createURLSearchParamsInstance();
    queryParamsFromURL.forEach((value, key) => {
      if (value) paramsFromURL[key] = value;
    });
    Promise.resolve()
      .then(() => {
        UIProps.setQueryParams({...paramsFromURL});
      })
      .then(() => dispatch(getNewEntities({
        ...paramsFromURL,
        requestedPageNumber,
      })));
  };

  const resetPageAndGetEntities = () => {
    setPageNumber(initialRequestedPageNumber);
    getEntities(initialRequestedPageNumber);
  };

  useEffect(() => {
    resetPageAndGetEntities();
  }, [currentQueryParams.toString()]);

  useEffect(() => {
    if (entities !== null) {
      setHasMore(checkHasMore());
      onEntitiesChange(entities);
    }
  }, [entities]);

  const next = () => {
    const requestedPageNumber = parseInt(pageNumber, 10) + 1;
    return Promise.resolve()
      .then(() => dispatch(getNextEntities({
        ...UIProps.queryParams,
        requestedPageNumber
      })))
      .then(() => setPageNumber(requestedPageNumber));
  };

  const checkHasMore = () => {
    return parseInt(pageCount, 10) > parseInt(pageNumber + 1, 10);
  };

  return {
    next,
    hasMore,
    ...restProps
  };
};

export default useInfiniteScrollControl;

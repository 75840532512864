import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import {can} from '../../../_common/components/Permissions';
import CommonPopup from "../../../_common/components/CommonPopup";
import PopupContent from "./PopupContent";
import {PopupCopyInspectionProvider} from "../PopupCopyInspectionContext";
import {
  subjects as S,
  permissionTypes as T
} from '../../../_common/constants/permissionSubjects';
import {DecisionTreeShumaTypesOptionsContextProvider} from "../../../_common/components/DecisionTreeOptions";
import {FormattedMessage} from "react-intl";

const PopupCopyInspection = ({data}) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const isDisabled = data.originTypeID !== 1 || !can(T.FULL, S.INSPECTIONS_PAGE);

  return (
    <>
      <Button
        className={`w-100 bg-white border-0 h-40px d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg`}
        onClick={() => handleShowModal()}
        disabled={isDisabled}
      >
        <img
          src={toAbsoluteUrl('media/common/Copy.svg')}
          alt={'copy'}
          className={'w-20px mr-4'}
        />
        <FormattedMessage
          id="inspections-column-copy"
          defaultMessage="Copy"
        />
      </Button>
      <CommonPopup
        isOpen={show}
        commonButton={false}
        content={
          <DecisionTreeShumaTypesOptionsContextProvider shumaID={data.shumaID}>
            <PopupCopyInspectionProvider shumaReasonID={data.shumaReasonID}>
              <PopupContent
                closeModal={() => handleCloseModal()}
                data={data}
              />
            </PopupCopyInspectionProvider>
          </DecisionTreeShumaTypesOptionsContextProvider>
        }
        size={'lg'}
      />
    </>
  );
};

export default PopupCopyInspection;

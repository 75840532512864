import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const Section6Text71 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {ttHelka} = context.data.pdfData;
  const ttHelkaValue = ttHelka ? `-תת חלקה ${ttHelka}` : '';

  return (
    <div className={classnames(styles.itemTitle, styles.innerPadding)} ref={ref}>
      הדירה הנסקרת {ttHelkaValue}
    </div>
  );
});

export default Section6Text71;

const defaultState = {
  fileList: [],
  error: false,
  errorMessage: null
};

export const actionTypes = {
  FETCH_FILE_LIST: 'FETCH_FILE_LIST',
  catchError: 'CATCH_ERROR'
};

export default (state= defaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FILE_LIST: {
      const {
        fileList
      } = action.payload;

      return {
        ...state,
        fileList,
        error: false,
        errorMessage: null
      };
    }
    case actionTypes.catchError: {
      return {
        ...state,
        error: true,
        errorMessage: `FETCH_FILE_LIST: ${action.payload.error}`
      };
    }
    default: return state;
  }
};

export const actions = {
  fetchFileList: (data) => ({
    type: actionTypes.FETCH_FILE_LIST,
    payload: data
  }),
  catchError: (error) => ({
    type: actionTypes.catchError,
    payload: {error}
  }),
};

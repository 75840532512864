import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {decisionTree} from '../../../constants/decisionTree';
import styles from './styles.module.scss';

const Section1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        loanTypeID,
        loanTypeName,
        loanText
      },
    },
    sectionNumbersShumaType3
  } = useCompleteInspectionContext();
  const text = (`${loanTypeID}` === decisionTree.loanTypeID.another) ? loanText : loanTypeName;

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)} ref={ref}>
      <span className={classnames(styles.textUnderline, styles.bold, styles.sectionHeader)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section1}. `}</span>
        <span>מטרת ההלוואה:</span>
      </span>
      <span>{text}</span>
    </div>
  );
});

export default Section1;

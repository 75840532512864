import React, {useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {useIntl} from 'react-intl';
import {useTable} from "react-table";
import {useHistory} from 'react-router-dom';
import * as actions from '../../_redux/actions/PriceQuotesPageTableActions';
import * as columnFormatters from '../../../_common/column-formatters';
import * as customColumnFormatters from '../column-formatters';
import {usePriceQuotesPageUIContext} from '../PriceQuotesPageUIContext';
import useInfiniteScrollControl
  from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import CommonGrid from "../../../_common/components/CommonGrid";
import styles from './styles.module.scss';


export function PriceQuotesPageTable() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tableRows, setTableRows] = useState([]);
  const columns = useMemo(() => [
    {
      accessor: 'priceQuoteID',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-id', defaultMessage: 'ID'
      })
    },
    {
      accessor: 'createDate',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-createDate', defaultMessage: 'Create date'
      })
    },
    {
      accessor: 'customers',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-customers', defaultMessage: 'Customers'
      })
    },
    {
      accessor: 'priceQuoteStatusName',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-status', defaultMessage: 'Status'
      })
    },
    {
      accessor: 'price',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-price', defaultMessage: 'Price'
      })
    },
    {
      accessor: 'edit',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-edit', defaultMessage: 'Edit'
      })
    },
    {
      accessor: 'delete',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-delete', defaultMessage: 'Delete'
      })
    },
    {
      accessor: 'download',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-download', defaultMessage: 'Download'
      })
    },
    {
      accessor: 'createOrder',
      Header: intl.formatMessage({
        id: 'price-quotes-page-column-createOrder', defaultMessage: 'Create order'
      })
    },
  ], []);

  const data = tableRows.map((item) => {
    return {
      priceQuoteID: columnFormatters.BoldColumnFormatter(item.priceQuoteID),
      createDate: columnFormatters.DateColumnFormatter(item.createDate),
      customers: columnFormatters.CustomersColumnFormatter(item.customers),
      priceQuoteStatusName: columnFormatters.BoldColumnFormatter(item.priceQuoteStatusName),
      price: columnFormatters.BoldColumnFormatter(item.price),
      edit: columnFormatters.EditPriceQuoteColumnFormatter(item),
      delete: columnFormatters.DeletePriceQuoteColumnFormatter(item),
      download: columnFormatters.DownloadPriceQuoteColumnFormatter(null, item, dispatch),
      createOrder: customColumnFormatters.CreateOrderColumnFormatter(null, item, {history}),
    };
  })

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'priceQuotesPageTableReducer',
    UIContext: usePriceQuotesPageUIContext(),
    getNewEntities: actions.getPriceQuotes,
    getNextEntities: actions.getNextPriceQuotes,
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([])
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-100px d-flex justify-content-center'}>
        <span className='spinner spinner-md spinner-warning'/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  return (
    <CommonGrid
      infiniteScrollProps={infiniteScrollProps}
      customHeaderStyles={styles.customHeader}
      customBodyStyles={styles.customBody}
      {...useTable({columns, data})}
    />
  );
}

export default PriceQuotesPageTable;

import React from 'react';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import styles from '../../styles/styles.module.scss';
import {getCustomersDataString} from '../../utils/texts';

const GreenBoxCustomerNamesLine = () => {
  const context = useCompleteInspectionContext();

  return (
    <div className={styles.blockLine}>
      <span>שם הלווים: </span>
      <span>{getCustomersDataString(context.data.pdfDataCustomers)}</span>
    </div>
  );
};

export default GreenBoxCustomerNamesLine;

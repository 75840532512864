import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import Tasks from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const tasksRoute = (
  <ProtectedRoute
    subject={S.TASKS_PAGE}
    path={P.TASKS_PAGE}
    component={Tasks}
  />
);

import React from 'react';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import * as _ from 'lodash';
import {StyledFieldSelect} from '../FieldSelect';
import {StyledFieldRadiobutton} from '../FieldRadiobutton';
import {StyledFieldDatepicker} from '../FieldDatepicker';
import {StyledFieldInput} from '../FieldInput';
import CommonButton from '../CommonButton';
import AddStreetPopup from './AddStreetPopup';
import {integerValue} from '../../../../../utils/format';
import {filterOptions} from '../../utils/filterOptions';
import {useCitiesContext, useStreetsContext} from '../CitiesAndStreets';
import {useDecisionTreeOptionsContext, useDecisionTreeShumaTypesOptionsContext} from '../DecisionTreeOptions';
import styles from './styles.module.scss';
import {InputCheckbox} from '../FieldCheckbox';

const OrderInspectionForm = ({
                               values,
                               fieldNames,
                               setFieldValue,
                               handleChange,
                               decisionTreeProps,
                               selectProps,
                               disabledFormCondition,
                               tasksSection
                             }) => {
  const {users} = selectProps;
  const {cities} = useCitiesContext();
  const {
    streets,
    updateStreets,
  } = useStreetsContext();
  const {
    reasons,
    banks,
    propertyTypes,
    loanTypes,
    loanSubTypes,
    shumaMazminIdentities
  } = useDecisionTreeOptionsContext();
  const {
    shumaTypes,
    updateShumaTypes
  } = useDecisionTreeShumaTypesOptionsContext();

  const {
    decisionTree,
    decisionTreeOptions,
    handleResetDecisionTree,
    handleResetPreviousSteps,
    disabledFirstShumaReasonID,
    showBankData,
    setShowBankData,
  } = decisionTreeProps;

  const intl = useIntl();
  const bankGuarantee = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankGuarantee',
    defaultMessage: 'Bank guarantee'
  });
  const valueOfRights = intl.formatMessage({
    id: 'tab-order-inspections-field-label-valueOfRights',
    defaultMessage: 'Value of rights'
  });
  const fieldLabelBank = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bank',
    defaultMessage: 'Bank'
  });
  const fieldLabelPropertyType = intl.formatMessage({
    id: 'tab-order-inspections-field-label-propertyType',
    defaultMessage: 'Property type'
  });
  const fieldLabelShumaType = intl.formatMessage({
    id: 'tab-order-inspections-field-label-shumaType',
    defaultMessage: 'Shuma type'
  });
  const fieldLabelLoanType = intl.formatMessage({
    id: 'tab-order-inspections-field-label-loanType',
    defaultMessage: 'Loan type'
  });
  const fieldLabelLoanSubType = intl.formatMessage({
    id: 'tab-order-inspections-field-label-loanSubType',
    defaultMessage: 'Loan sub type'
  });
  const fieldLabelResponsibleUser = intl.formatMessage({
    id: 'tab-order-inspections-field-label-responsibleUser',
    defaultMessage: 'Firm user'
  });
  const subHeaderPropertyDetails = intl.formatMessage({
    id: 'tab-order-inspections-header-propertyDetails',
    defaultMessage: 'Property details'
  });
  const fieldLabelCity = intl.formatMessage({
    id: 'tab-order-inspections-field-label-city',
    defaultMessage: 'City'
  });
  const fieldLabelStreet = intl.formatMessage({
    id: 'tab-order-inspections-field-label-street',
    defaultMessage: 'Street'
  });
  const fieldLabelHouseNumber = intl.formatMessage({
    id: 'tab-order-inspections-field-label-houseNumber',
    defaultMessage: 'House number'
  });
  const fieldLabelApartmentNumber = intl.formatMessage({
    id: 'tab-order-inspections-field-label-apartmentNumber',
    defaultMessage: 'Apartment number'
  });
  const fieldLabelGush = intl.formatMessage({
    id: 'tab-order-inspections-field-label-gush',
    defaultMessage: 'Gush'
  });
  const fieldLabelHelka = intl.formatMessage({
    id: 'tab-order-inspections-field-label-helka',
    defaultMessage: 'Helka'
  });
  const fieldLabelTTHelka = intl.formatMessage({
    id: 'tab-order-inspections-field-label-ttHelka',
    defaultMessage: 'ttHelka'
  });
  const fieldLabelLandLotNum = intl.formatMessage({
    id: 'tab-order-inspections-field-label-landLotNum',
    defaultMessage: 'Land lot number'
  });
  const fieldLabelLandLotNumbAccordingTaba = intl.formatMessage({
    id: 'tab-order-inspections-field-label-landLotNumbAccordingTaba',
    defaultMessage: 'Land lot number according taba'
  });
  const fieldLabelNotes = intl.formatMessage({
    id: 'tab-order-inspections-field-label-notes',
    defaultMessage: 'Notes'
  });
  const fieldLabelLoanText = intl.formatMessage({
    id: 'tab-order-inspections-field-label-loanText',
    defaultMessage: 'Loan text'
  });
  const fieldLabelLoanPropertyType = intl.formatMessage({
    id: 'tab-order-inspections-field-label-loanPropertyType',
    defaultMessage: 'Loan property type'
  });
  const fieldLabelBankBranchNumber = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankBranchNumber',
    defaultMessage: 'Bank branch number'
  });
  const fieldLabelBankBranchName = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankBranchName',
    defaultMessage: 'Bank branch name'
  });
  const fieldLabelBankClerkFullName = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankClerkFullName',
    defaultMessage: 'Bank clerk full name'
  });
  const fieldLabelBankEmail = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankEmail',
    defaultMessage: 'Bank email'
  });
  const fieldLabelBankRequestID = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankRequestID',
    defaultMessage: 'Bank request ID'
  });
  const fieldLabelBankBuildType = intl.formatMessage({
    id: 'tab-order-inspections-field-label-bankBuildType',
    defaultMessage: 'Bank build type'
  });
  const showBankPropsLabel = intl.formatMessage({
    id: 'tab-order-inspections-button-label-showBankProps',
    defaultMessage: 'Show bank props'
  });
  const hideBankPropsLabel = intl.formatMessage({
    id: 'tab-order-inspections-button-label-hideBankProps',
    defaultMessage: 'Hide bank props'
  });
  const noOptionsMessage = intl.formatMessage({
    id: 'label-no-options',
    defaultMessage: 'No options',
  });
  const placeholderSelect = intl.formatMessage({
    id: 'select',
    defaultMessage: 'Select',
  });
  const fieldDueDate = intl.formatMessage({
    id: 'tab-order-general-field-label-dueDate',
    defaultMessage: 'Due date',
  });
  const fieldShumaMazminIdentityID = intl.formatMessage({
    id: 'tab-order-inspections-field-label-shumaMazminIdentityID',
    defaultMessage: 'Shuma mazmin identity',
  });
  const underConstructionLabel = intl.formatMessage({
    id: 'tab-order-inspections-field-label-underConstruction',
    defaultMessage: 'Under construction',
  });

  const {
    propertyTypeIDRightsEstimation,
    bankID,
    shumaTypeID,
    propertyTypeID,
    loanTypeID,
    loanText,
    otherPropertyTypeID,
    shumaReasonID,
    bankBranchNumber,
    bankBranchName,
    bankClerkFullName,
    bankEmail,
    bankRequestID,
    bankBuildType,
    responsibleUser,
    dueDate,
    city,
    gush,
    street,
    helka,
    houseNumber,
    ttHelka,
    apartmentNumber,
    landLotNum,
    landLotNumAccordingTaba,
    notes,
    loanSubTypeID,
    shumaMazminIdentityID,
    underConstruction
  } = fieldNames;

  const showSection = (fieldId) => {
    switch (fieldId) {
      case (propertyTypeIDRightsEstimation):
        return _.get(values, shumaReasonID) === decisionTree.rightsEstimation;
      case (bankID):
        return _.get(values, shumaReasonID) === decisionTree.bankSecure;
      case (shumaTypeID):
        return (
          _.get(values, shumaReasonID) === decisionTree.rightsEstimation ||
          _.get(values, shumaReasonID) === decisionTree.bankSecure
        );
      case (propertyTypeID):
        return Boolean(_.get(values, bankID)) && Boolean(_.get(values, shumaTypeID));
      case (loanTypeID):
        return Boolean(_.get(values, bankID))
          && Boolean(_.get(values, shumaTypeID))
          && Boolean(_.get(values, propertyTypeID));
      case (loanSubTypeID):
        return Boolean(_.get(values, loanTypeID));
      case (loanText):
        if (_.get(values, loanTypeID)) {
          return _.get(values, loanTypeID)?.value?.toString() === decisionTree.loanTypeID.another;
        } else return null;
      case (otherPropertyTypeID):
        if (_.get(values, loanTypeID)) {
          return _.get(values, loanTypeID)?.value?.toString() === decisionTree.loanTypeID.other;
        } else return null;
      default:
        return true;
    }
  };

  const commonSelectProps = {
    placeholder: placeholderSelect,
    isAsync: true,
    noOptionsMessage: () => noOptionsMessage,
    menuIsOpen: disabledFormCondition ? false : undefined,
    isSearchable: true,
    customContainerStyles: 'w-25 py-3 pr-4',
  };
  const commonInputProps = {
    customLabelStyles: 'pl-6',
    customContainerStyles: 'w-25 py-3 pr-4',
    readonly: disabledFormCondition
  };

  const handleCityChange = selected => {
    if (!values.city || values.city !== selected) {
      setFieldValue(street, null);
      if (Boolean(selected)) {
        const [selectedOption] = filterOptions(selected)(cities);
        setFieldValue(city, selectedOption);
        updateStreets(selected).catch(console.error);
      }
    }
  };

  const handleShumaReasonChange = selected => {
    if (!values.shumaReasonID || values.shumaReasonID !== selected) {
      setFieldValue(shumaTypeID, null);
      if (Boolean(selected)) {
        updateShumaTypes(parseInt(selected, 10)).catch(console.error);
      }
    }
  };

  const reason_valueOfRights = reasons[0]?.value?.toString() || '1';
  const reason_bankGuarantee = reasons[1]?.value?.toString() || '2';

  return (
    <div className={'px-4'}>
      {/*START DECISION TREE*/}
      <>
        <div className={'w-25 d-flex justify-content-between mb-4 pr-4'}>
          <StyledFieldRadiobutton
            fieldId={shumaReasonID}
            value={reason_bankGuarantee}
            selected={_.get(values, shumaReasonID)}
            label={bankGuarantee}
            icon={<i className={`la la-bank h1 text-dark rounded`}/>}
            customContainerStyles={'mr-8'}
            onChange={value => {
              setFieldValue(shumaReasonID, value);
              handleResetDecisionTree(value, fieldNames);
              handleShumaReasonChange(value);
            }}
            disabled={decisionTreeOptions.disabled.includes(shumaReasonID) || disabledFormCondition}
          />
          <StyledFieldRadiobutton
            fieldId={shumaReasonID}
            value={reason_valueOfRights}
            selected={_.get(values, shumaReasonID)}
            label={valueOfRights}
            icon={<i className={`la la-home h1 text-dark rounded`}/>}
            onChange={value => {
              setFieldValue(shumaReasonID, value);
              handleResetDecisionTree(value, fieldNames);
              handleShumaReasonChange(value);
            }}
            disabled={disabledFirstShumaReasonID
              || decisionTreeOptions.disabled.includes(shumaReasonID)
              || disabledFormCondition}
          />
        </div>
        {showSection(shumaTypeID) && _.get(values, shumaReasonID) === '1' && (
          <StyledFieldSelect
            name={shumaTypeID}
            value={_.get(values, shumaTypeID)}
            options={
              decisionTreeOptions.disabled.includes(shumaTypeID)
                ? [] : shumaTypes
            }
            onChange={({value}) => {
              handleResetPreviousSteps(shumaTypeID, value, fieldNames, values);
              decisionTreeOptions.disabled.includes(shumaTypeID)
                ? setFieldValue(shumaTypeID, null)
                : setFieldValue(shumaTypeID, filterOptions(value)(shumaTypes)[0]);
            }}
            label={
              <div
                className="d-inline-block font-weight-bolder text-dark-75 pl-6"
              >
                <span className={'text-danger'}>*</span>
                {fieldLabelShumaType}
              </div>
            }
            {...commonSelectProps}
          />
        )}
        {showSection(propertyTypeIDRightsEstimation) && (
          <div className={'w-25 py-3 pr-4 position-relative'}>
            <StyledFieldSelect
              name={propertyTypeID}
              value={_.get(values, propertyTypeID)}
              options={propertyTypes}
              onChange={({value}) => {
                setFieldValue(propertyTypeID, filterOptions(value)(propertyTypes)[0]);
              }}
              label={(
                <div
                  className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                  <span className={'text-danger'}>*</span>
                  {fieldLabelPropertyType}
                </div>
              )}
              {...commonSelectProps}
              customContainerStyles={'w-100'}
            />
            <InputCheckbox
              fieldId={underConstruction}
              label={underConstructionLabel}
              customContainerStyles={'position-absolute mt-0'}
              onChange={() => {
                setFieldValue(underConstruction, !_.get(values, underConstruction))
              }}
              value={_.get(values, underConstruction)}
            />
          </div>
        )}
        {showSection(bankID) && (
          <>
            <>
              <div className={classnames(
                'w-100 d-flex justify-content-start align-items-end',
                styles.bankPropsButtonContainer
              )}>
                <StyledFieldSelect
                  name={bankID}
                  value={_.get(values, bankID)}
                  options={banks}
                  onChange={({value}) => {
                    handleResetPreviousSteps(bankID, value, fieldNames, values);
                    setFieldValue(bankID, filterOptions(value)(banks)[0]);
                  }}
                  label={(
                    <div
                      className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                      <span className={'text-danger'}>*</span>
                      {fieldLabelBank}
                    </div>
                  )}
                  {...commonSelectProps}
                />
                {_.get(values, bankID) && (
                  <CommonButton
                    customButtonClassNames={classnames(
                      'd-block w-150px mb-3 btn btn-m btn-warning text-dark font-weight-bolder text-nowrap',
                      styles.bankPropsButton
                    )}
                    label={showBankData ? hideBankPropsLabel : showBankPropsLabel}
                    onClick={() => setShowBankData(!showBankData)}
                  />
                )}
              </div>
              {showBankData && (
                <div>
                  <div className={'d-flex'}>
                    <StyledFieldInput
                      fieldId={bankBranchNumber}
                      label={fieldLabelBankBranchNumber}
                      value={_.get(values, bankBranchNumber)}
                      onChange={e => setFieldValue(bankBranchNumber, integerValue(e))}
                      {...commonInputProps}
                    />
                    <StyledFieldInput
                      fieldId={bankBranchName}
                      label={fieldLabelBankBranchName}
                      value={_.get(values, bankBranchName)}
                      onChange={handleChange}
                      {...commonInputProps}
                    />
                    <StyledFieldInput
                      fieldId={bankClerkFullName}
                      label={fieldLabelBankClerkFullName}
                      value={_.get(values, bankClerkFullName)}
                      onChange={handleChange}
                      {...commonInputProps}
                    />
                  </div>
                  <div className={'d-flex'}>
                    <StyledFieldInput
                      fieldId={bankEmail}
                      type={'email'}
                      label={fieldLabelBankEmail}
                      value={_.get(values, bankEmail)}
                      onChange={handleChange}
                      {...commonInputProps}
                    />
                    <StyledFieldInput
                      fieldId={bankRequestID}
                      label={fieldLabelBankRequestID}
                      value={_.get(values, bankRequestID)}
                      onChange={handleChange}
                      {...commonInputProps}
                    />
                    <StyledFieldInput
                      fieldId={bankBuildType}
                      label={fieldLabelBankBuildType}
                      value={_.get(values, bankBuildType)}
                      onChange={handleChange}
                      {...commonInputProps}
                    />
                  </div>
                </div>
              )}
            </>
            {showSection(shumaTypeID) && _.get(values, shumaReasonID) === '2' && (
              <StyledFieldSelect
                name={shumaTypeID}
                value={_.get(values, shumaTypeID)}
                options={
                  decisionTreeOptions.disabled.includes(shumaTypeID)
                    ? [] : shumaTypes
                }
                onChange={({value}) => {
                  handleResetPreviousSteps(shumaTypeID, value, fieldNames, values);
                  decisionTreeOptions.disabled.includes(shumaTypeID)
                    ? setFieldValue(shumaTypeID, null)
                    : setFieldValue(shumaTypeID, filterOptions(value)(shumaTypes)[0]);
                }}
                label={
                  <div
                    className="d-inline-block font-weight-bolder text-dark-75 pl-6"
                  >
                    <span className={'text-danger'}>*</span>
                    {fieldLabelShumaType}
                  </div>
                }
                {...commonSelectProps}
              />
            )}
            <div className={'d-flex'}>
              {showSection(propertyTypeID) && (
                <div className={'w-25 py-3 pr-4 position-relative'}>
                  <StyledFieldSelect
                    name={propertyTypeID}
                    value={_.get(values, propertyTypeID)}
                    options={decisionTreeOptions.disabled.includes(propertyTypeID)
                      ? []
                      : propertyTypes
                    }
                    onChange={({value}) => {
                      handleResetPreviousSteps(propertyTypeID, value, fieldNames, values);
                      decisionTreeOptions.disabled.includes(propertyTypeID)
                        ? setFieldValue(propertyTypeID, null)
                        : setFieldValue(propertyTypeID, filterOptions(value)(propertyTypes)[0]);
                    }}
                    label={
                      <div
                        className="d-inline-block font-weight-bolder text-dark-75 pl-6"
                      >
                        <span className={'text-danger'}>*</span>
                        {fieldLabelPropertyType}
                      </div>
                    }
                    {...commonSelectProps}
                    customContainerStyles={'w-100'}
                  />
                  <InputCheckbox
                    fieldId={underConstruction}
                    label={underConstructionLabel}
                    customContainerStyles={'position-absolute mt-0'}
                    onChange={() => {
                      setFieldValue(underConstruction, !_.get(values, underConstruction))
                    }}
                    value={_.get(values, underConstruction)}
                  />
                </div>
              )}
              {showSection(loanTypeID) && (
                <StyledFieldSelect
                  name={loanTypeID}
                  value={_.get(values, loanTypeID)}
                  options={loanTypes}
                  onChange={({value}) => {
                    handleResetPreviousSteps(loanTypeID, value, fieldNames, values);
                    setFieldValue(loanTypeID, filterOptions(value)(loanTypes)[0]);
                  }}
                  label={
                    <div
                      className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                      {fieldLabelLoanType}
                    </div>
                  }
                  {...commonSelectProps}
                />
              )}
              {showSection(loanSubTypeID) && (
                <StyledFieldSelect
                  name={loanSubTypeID}
                  value={_.get(values, loanSubTypeID)}
                  options={loanSubTypes}
                  onChange={({value}) => {
                    setFieldValue(loanSubTypeID, filterOptions(value)(loanSubTypes)[0]);
                  }}
                  label={
                    <div
                      className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                      {fieldLabelLoanSubType}
                    </div>
                  }
                  {...commonSelectProps}
                />
              )}
              {showSection(otherPropertyTypeID) && (
                <StyledFieldSelect
                  name={otherPropertyTypeID}
                  value={_.get(values, otherPropertyTypeID)}
                  options={propertyTypes}
                  onChange={({value}) => {
                    setFieldValue(otherPropertyTypeID, filterOptions(value)(propertyTypes)[0]);
                  }}
                  label={(
                    <div
                      className="d-inline-block font-weight-bolder text-dark-75 pl-6">
                      <span className={'text-danger'}>*</span>
                      {fieldLabelLoanPropertyType}
                    </div>
                  )}
                  {...commonSelectProps}
                />
              )}
            </div>
            {showSection(loanText) && (
              <StyledFieldInput
                fieldId={loanText}
                value={_.get(values, loanText)}
                label={(
                  <div
                    className="d-inline-block font-weight-bolder text-dark-75">
                    <span className={'text-danger'}>*</span>
                    {fieldLabelLoanText}
                  </div>
                )}
                customLabelStyles={'pl-6'}
                customContainerStyles={'py-3 w-100 pr-4'}
                onChange={handleChange}
                disabled={disabledFormCondition}
              />
            )}
          </>
        )}
      </>
      {/*/!*END DECISION TREE*!/*/}
      <div className={'d-flex'}>
        <StyledFieldSelect
          name={responsibleUser}
          value={_.get(values, responsibleUser)}
          options={users || []}
          onChange={({value}) => {
            setFieldValue(responsibleUser, filterOptions(value)(users)[0]);
          }}
          label={
            <div
              className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <span className={'text-danger'}>*</span>
              {fieldLabelResponsibleUser}
            </div>
          }
          {...commonSelectProps}
        />
        <StyledFieldDatepicker
          label={fieldDueDate}
          value={_.get(values, dueDate)}
          onChange={(date) => {
            setFieldValue(dueDate, date);
          }}
          disabled={disabledFormCondition}
          customLabelStyles={'pl-6'}
          customContainerStyles={'w-25 py-3 pr-4'}
        />
        <StyledFieldSelect
          name={shumaMazminIdentityID}
          value={_.get(values, shumaMazminIdentityID)}
          options={shumaMazminIdentities}
          onChange={({value}) => {
            setFieldValue(shumaMazminIdentityID, filterOptions(value)(shumaMazminIdentities)[0]);
          }}
          label={
            <div
              className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              <span className={'text-danger'}>*</span>
              {fieldShumaMazminIdentityID}
            </div>
          }
          {...commonSelectProps}
        />
      </div>
      <h4
        className={'font-weight-bolder d-inline-block mt-3 mb-0 border-warning border-bottom'}>
        {subHeaderPropertyDetails}
      </h4>
      <div className={'d-flex'}>
        <StyledFieldSelect
          name={city}
          value={_.get(values, city)}
          options={cities}
          onChange={({value}) => {
            handleCityChange(value);
          }}
          label={
            <div
              className="d-inline-block font-weight-bolder text-dark-75 pl-6">
              {fieldLabelCity}
            </div>
          }
          {...commonSelectProps}
        />
        <StyledFieldSelect
          name={street}
          value={_.get(values, street)}
          options={streets}
          onChange={({value}) => {
            setFieldValue(street, filterOptions(value)(streets)[0]);
          }}
          label={
            <div className="d-inline-flex font-weight-bolder text-dark-75 pl-6 align-item-end">
              <div className="d-inline-block pr-3">
                {fieldLabelStreet}
              </div>
              <div className="d-inline-block">
                <AddStreetPopup
                  cityID={_.get(values, city)?.value}
                  onSubmit={async selected => {
                    await setFieldValue(street, selected);
                  }}
                  buttonDisabled={!_.get(values, city)?.value}
                />
              </div>
            </div>
          }
          disabled={!_.get(values, city)}
          {...commonSelectProps}
        />
        <StyledFieldInput
          fieldId={houseNumber}
          value={_.get(values, houseNumber)}
          label={fieldLabelHouseNumber}
          onChange={e => setFieldValue(houseNumber, integerValue(e))}
          {...commonInputProps}
        />
        <StyledFieldInput
          fieldId={apartmentNumber}
          value={_.get(values, apartmentNumber)}
          label={fieldLabelApartmentNumber}
          onChange={e => setFieldValue(apartmentNumber, integerValue(e))}
          {...commonInputProps}
        />
      </div>
      <div className={'d-flex'}>
        <StyledFieldInput
          fieldId={gush}
          value={_.get(values, gush)}
          label={fieldLabelGush}
          onChange={e => setFieldValue(gush, integerValue(e))}
          {...commonInputProps}
        />
        <StyledFieldInput
          fieldId={helka}
          value={_.get(values, helka)}
          label={fieldLabelHelka}
          onChange={e => setFieldValue(helka, integerValue(e))}
          {...commonInputProps}
        />
        <StyledFieldInput
          fieldId={ttHelka}
          value={_.get(values, ttHelka)}
          label={fieldLabelTTHelka}
          onChange={e => setFieldValue(ttHelka, integerValue(e))}
          {...commonInputProps}
        />
      </div>
      <div className={'d-flex'}>
        <StyledFieldInput
          fieldId={landLotNum}
          value={_.get(values, landLotNum)}
          label={fieldLabelLandLotNum}
          onChange={handleChange}
          {...commonInputProps}
        />
        <StyledFieldInput
          fieldId={landLotNumAccordingTaba}
          value={_.get(values, landLotNumAccordingTaba)}
          label={fieldLabelLandLotNumbAccordingTaba}
          onChange={handleChange}
          {...commonInputProps}
        />
      </div>
      <StyledFieldInput
        fieldId={notes}
        value={_.get(values, notes)}
        label={fieldLabelNotes}
        onChange={handleChange}
        customLabelStyles={'pl-6'}
        customContainerStyles={'py-3 pr-4'}
        readonly={disabledFormCondition}
      />
      {tasksSection}
    </div>
  );
};

export default OrderInspectionForm;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import CommonButton from "../CommonButton";
import styles from './styles.module.scss';


const CommonPopupWithTrigger = ({
                                  triggerContent,
                                  content,
                                  customContainerStyles,
                                  size = 'xl',
                                  onApprove,
                                  isOnApproveDisabled = false,
                                  buttonLabelApprove,
                                  buttonLabelCancel
                                }) => {
  const [open, setOpen] = useState(false);
  const handleOpenPopup = () => setOpen(true);
  const handleClosePopup = () => setOpen(false);

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
      onMouseOver={e => e.stopPropagation()}
    >
      <div onClick={handleOpenPopup}>
        {triggerContent}
      </div>
      <Modal show={open} onHide={handleClosePopup} size={size} backdrop={'static'}>
        <Modal.Body>
          <div className={`${styles.modalContainer} ${customContainerStyles}`}>
            <div className={styles.content}>
              {content}
            </div>
            <div className={styles.buttonsContainer}>
              <CommonButton
                variant={'button'}
                label={buttonLabelCancel}
                onClick={handleClosePopup}
              />
              {Boolean(onApprove) && (
                <CommonButton
                  variant={'submit'}
                  label={buttonLabelApprove}
                  onClick={() => {
                    onApprove();
                    handleClosePopup();
                  }}
                  disabled={isOnApproveDisabled}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

CommonPopupWithTrigger.propTypes = {
  triggerContent: PropTypes.node,
  content: PropTypes.node,
  customContainerStyles: PropTypes.string,
  size: PropTypes.string,
  onApprove: PropTypes.func,
  isOnApproveDisabled: PropTypes.bool,
  buttonLabelApprove: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  buttonLabelCancel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
};

export default CommonPopupWithTrigger;

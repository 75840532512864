import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useTable} from 'react-table';
import * as actions from '../../actions/TasksTableActions';
import * as columnFormatters from '../../../_common/column-formatters';
import {useTasksUIContext} from '../TasksUIContext';
import useInfiniteScrollControl
  from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import CommonGrid from '../../../_common/components/CommonGrid';
import styles from './styles.module.scss';

export default function TasksTable() {
  const intl = useIntl();
  const [tableRows, setTableRows] = useState([]);

  const columns = useMemo(() => [
    {
      accessor: 'taskID',
      Header: intl.formatMessage({
        id: 'tasks-page-column-taskID', defaultMessage: 'Task ID'
      })
    },
    {
      accessor: 'username',
      Header: intl.formatMessage({
        id: 'tasks-page-column-responsibleUser', defaultMessage: 'Responsible user'
      })
    },
    {
      accessor: 'firmShumaIDAndPropertyType',
      Header: intl.formatMessage({
        id: 'tasks-page-column-firmShumaIDAndPropertyType', defaultMessage: 'Firm shuma ID + property type'
      })
    },
    {
      accessor: 'taskTypeAndTaskStatus',
      Header: intl.formatMessage({
        id: 'tasks-page-column-taskTypeAndTaskStatus', defaultMessage: 'Task type + task status'
      })
    },
    {
      accessor: 'customers',
      Header: intl.formatMessage({
        id: 'tasks-page-column-customers', defaultMessage: 'Customers'
      })
    },
    {
      accessor: 'fullAddress',
      Header: intl.formatMessage({
        id: 'tasks-page-column-fullAddress', defaultMessage: 'Full address'
      })
    },
    {
      accessor: 'dueDate',
      Header: intl.formatMessage({
        id: 'tasks-page-column-dueDate', defaultMessage: 'Due date'
      })
    },
    {
      accessor: 'view',
      Header: intl.formatMessage({
        id: 'tasks-page-column-view', defaultMessage: 'View'
      })
    },
    {
      accessor: 'delete',
      Header: intl.formatMessage({
        id: 'tasks-page-column-delete', defaultMessage: 'Delete'
      })
    },
  ], []);
  const data = useMemo(() => [
    ...tableRows.map((item) => {
      return {
        taskID: columnFormatters.BoldColumnFormatter(item.taskID),
        username: columnFormatters.BoldColumnFormatter(item.username),
        firmShumaIDAndPropertyType: columnFormatters.SeveralItemsColumnFormatter([item.firmShumaID, item.propertyTypeName]),
        taskTypeAndTaskStatus: columnFormatters.SeveralItemsColumnFormatter([item.taskType, item.taskStatus]),
        customers: columnFormatters.SeveralItemsColumnFormatter(item.customers),
        fullAddress: columnFormatters.AddressColumnFormatter(item.cityName, item.streetName, item.houseNumber),
        dueDate: columnFormatters.DateColumnFormatter(item.dueDate),
        view: columnFormatters.EditTaskColumnFormatter(item.taskID, item.taskTypeID),
        delete: columnFormatters.DeleteTaskColumnFormatter(item.taskID),
      };
    })
  ], [tableRows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'tasksTableReducer',
    UIContext: useTasksUIContext(),
    getNewEntities: actions.getTasks,
    getNextEntities: actions.getNextTasks,
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([]);
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-50px d-flex justify-content-center'}>
        <span className="spinner spinner-md spinner-warning"/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  const gridProps = {
    infiniteScrollProps,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows
  };

  return (
    <CommonGrid
      customHeaderStyles={styles.customHeader}
      customBodyStyles={styles.customBody}
      {...gridProps}
    />
  );
}

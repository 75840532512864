import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import IconInfo from "./info.svg";
import styles from "./styles.module.scss";

const MultipleTooltip = ({
                           label,
                           id,
                           place,
                           effect,
                           customIconClassName,
                           customTooltipClassName,
                           icon = IconInfo
                         }) => {

  return (
    <>
      <div data-for={id} data-tip={label}>
        <img
          src={icon}
          alt="tooltipIcon"
          className={`${styles.icon} ${customIconClassName}`}
        />
      </div>
      <ReactTooltip
        id={id}
        place={place}
        effect={effect}
        className={`${styles.tooltip} ${customTooltipClassName}`}
      />
    </>
  )
};

MultipleTooltip.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  customIconClassName: PropTypes.string,
  customTooltipClassName: PropTypes.string,
};

export default MultipleTooltip;

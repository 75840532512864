import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getBuildingLocationValue} from '../../../utils/texts';
import styles from './styles.module.scss';

const Section6Text73 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {buildingLocation, streetName} = context.data.pdfData;

  return (
    <div ref={ref}>
      {Boolean(buildingLocation) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          <span className={styles.bold}>
            חזית:
          </span>
          <span>דירה</span>
          <span>{` ${getBuildingLocationValue(buildingLocation)} `}</span>
          <span>לרח'</span>
          <span>{` ${streetName}.`}</span>
        </div>
      )}
    </div>
  );
});

export default Section6Text73;

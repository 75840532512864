import React from 'react';
import {useIntl} from "react-intl";
import {shallowEqual, useSelector} from "react-redux";
import {StyledFieldDatepicker}  from "../../../_common/components/FieldDatepicker";
import {StyledFieldSelect} from "../../../_common/components/FieldSelect";
import {filterOptions} from "../../../_common/utils/options";
import {StyledFieldTextareaAutosize} from "../../../_common/components/FieldTextareaAutosize";
import {usePopupEditTasksContext} from "./PopupEditTasksContext";
import {taskStatuses} from "../../../_common/constants/options";

export function TaskGeneral({header}) {
  const intl = useIntl();
  const labelTaskDescription = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-taskDescription',
    defaultMessage: 'Task description',
  });
  const labelResponsibleUser = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-responsibleUser',
    defaultMessage: 'Responsible user',
  });
  const labelDueDate = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-dueDate',
    defaultMessage: 'Due date',
  });
  const labelStatus = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-status',
    defaultMessage: 'Status',
  });

  const {formik} = usePopupEditTasksContext();
  const {values, setFieldValue} = formik;
  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );

  const getFieldLabel = (text, mandatory = true) => {
    return (
      <div className={'font-weight-bolder pl-6'}>
        {mandatory && <span className={'text-danger'}>*</span>}
        <span>{text}</span>
      </div>
    )
  };

  return (
    <>
      <h2 className="font-weight-normal text-dark w-100 d-flex justify-content-center mb-6">
        {header}
      </h2>
      <div className={'py-4'}>
        <StyledFieldTextareaAutosize
          fieldId={'taskDescription'}
          onChange={(e) => setFieldValue('taskDescription', e.target.value)}
          value={values.taskDescription}
          label={getFieldLabel(labelTaskDescription)}
          customContainerStyles={'py-3'}
          customLabelStyles={'fs-6'}
          customTextareaStyles={'overflow-hidden'}
        />
        <StyledFieldSelect
          fieldId={'responsibleUserID'}
          onChange={(option) => setFieldValue('responsibleUserID', option.value)}
          value={filterOptions(values.responsibleUserID)(users)}
          options={users}
          label={getFieldLabel(labelResponsibleUser)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldDatepicker
          value={values.taskDueDate}
          onChange={(date) => {
            setFieldValue('taskDueDate', new Date(date));
          }}
          label={getFieldLabel(labelDueDate)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldSelect
          fieldId={'taskStatusID'}
          onChange={(option) => setFieldValue('taskStatusID', option.value)}
          value={filterOptions(values.taskStatusID)(taskStatuses)}
          options={taskStatuses}
          label={getFieldLabel(labelStatus)}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
}

export default TaskGeneral;

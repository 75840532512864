import React from 'react';
import moment from "moment";
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const Section4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        officialDate,
        visitDate
      },
    },
    sectionNumbersShumaType3
  } = useCompleteInspectionContext();
  const text = [
    moment(officialDate).format('DD/MM/YYYY'),
    visitDate === officialDate ? ' (מועד הביקור בנכס)' : ''
  ].filter(Boolean).join('');

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer)}
      ref={ref}
    >
      <span className={classnames(styles.textUnderline, styles.bold, styles.sectionHeader)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section4}. `}</span>
        <span>המועד הקובע לשומה:</span>
      </span>
      <span>{text}</span>
    </div>
  );
});

export default Section4;

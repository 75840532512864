import React from 'react';
import PropTypes from 'prop-types';
import CommonButton from "../../../../_common/components/CommonButton";
import removeIcon from './Minus-square.svg';

const ButtonRemoveRow = ({onClick, styles}) => {
  return (
    <div className={styles.removeRowButton}>
      <CommonButton
        onClick={onClick}
        label={
          <img
            src={removeIcon}
            alt="remove-row"
            className='w-20px h-20px'
          />
        }
        customButtonClassNames={'btn btn-sm btn-warning d-flex align-items-center w-50px'}
      />
    </div>
  );
}

ButtonRemoveRow.propTypes = {
  onClick: PropTypes.func,
  styles: PropTypes.object,
}

export default ButtonRemoveRow;


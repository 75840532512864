import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import OldInspectionsPage from '../pages/OldInspectionsPage';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const oldInspectionsPageRoute = (
  <ProtectedRoute
    subject={S.OLD_INSPECTIONS_PAGE}
    path={P.OLD_INSPECTIONS_PAGE}
    component={OldInspectionsPage}
  />
);

import React from 'react';
import {useHeader} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import SubheaderBar from '../../_common/components/SubheaderBar';
import OrdersPageFilters from '../components/OrdersPageFilter';
import OrdersPageTable from '../components/OrdersPageTable';
import {OrdersPageUIProvider} from '../components/OrdersPageUIContext';

const OrdersPage = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'orders-page-header',
    defaultMessage: 'Orders page'
  }));

  return (
    <OrdersPageUIProvider>
      <SubheaderBar>
        <OrdersPageFilters/>
      </SubheaderBar>
      <OrdersPageTable/>
    </OrdersPageUIProvider>
  );
};

export default OrdersPage;

import React from "react";
import {CircularProgress} from "@material-ui/core";
import {toAbsoluteUrl} from "../../_helpers";

export function SplashScreen() {
  return (
    <>
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div>
          <div className="w-150px">
            <img
              src={toAbsoluteUrl("/media/common/House_illustration_Red-Yellow.svg")}
              alt="Metronic logo"
            />
          </div>
          <div className="w-100 mt-13 d-flex align-items-center justify-content-center">
            <CircularProgress />
          </div>
        </div>
      </div>
    </>
  );
}

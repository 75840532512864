import React from 'react';
import classnames from 'classnames';
import Section from '../../Section';
import NoteNumber from '../../NoteNumber';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingPrice} from '../../../utils/formatting';
import {getBreakLinesTextShumaType3, getFinalPriceOnlyText} from '../../../utils/texts';
import styles from './styles.module.scss';

const TableWithHeader = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <HeadContent/>
      <TableContnent/>
    </div>
  );
});

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section14}. `}</span>
        <span>השומה (בשקלים חדשים) - לתאריך הדו"ח</span>
      </span>
    </div>
  );
};

const TableContnent = () => {
  const {
    data: {
      pdfData: {
        finalMarketPrice,
        deductionAmount,
        propertyBuildCostEstimate,
        quickRealizeDeduction,
        propertyLandRemainingValueEstimate
      }
    }
  } = useCompleteInspectionContext();
  const parsedFinalMarketPrice = Boolean(finalMarketPrice) ? parseFloat(finalMarketPrice) : 0;
  const parsedDeductionAmount = Boolean(deductionAmount) ? parseFloat(deductionAmount) : 0;
  const parsedQuickRealizeDeduction = Boolean(quickRealizeDeduction) ? parseFloat(quickRealizeDeduction) : 0;
  const deltaPriceDeduction = parsedFinalMarketPrice - parsedDeductionAmount;
  const showSecondThirdRowsCondition = Boolean(deductionAmount) && deductionAmount !== '0';
  const quickRealizeDeductionSum = (parsedFinalMarketPrice - parsedDeductionAmount) * (parsedQuickRealizeDeduction) / 100;
  const diffPriceQuickRealizeDeductionSum = parsedFinalMarketPrice - quickRealizeDeductionSum - parsedDeductionAmount;

  const getLastItemLetter = () => {
    if (propertyBuildCostEstimate) {
      return showSecondThirdRowsCondition ? 'ו.' : 'ד.';
    } else {
      return showSecondThirdRowsCondition ? 'ה.' : 'ג.';
    }
  };

  return (
    <Section>
      <table
        className={classnames(styles.simpleTable, styles.w100, styles.longTable)}
      >
        <thead>
        <tr>
          <th className={styles.w5}/>
          <th className={styles.w40}>סעיף הערכה</th>
          <th>ש"ח</th>
          <th className={styles.w30}>ובמילים</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className={styles.textCentered}>א.</td>
          <td>שווי הנכס במצב הנוכחי</td>
          <td className={styles.textCentered}>{formattingPrice(finalMarketPrice)}</td>
          <td>{getFinalPriceOnlyText(finalMarketPrice)}</td>
        </tr>
        {showSecondThirdRowsCondition && (
          <>
            <tr>
              <td className={styles.textCentered}>ב.</td>
              <td>הפחתות</td>
              <td className={styles.textCentered}>{formattingPrice(deductionAmount)}</td>
              <td>{getFinalPriceOnlyText(deductionAmount)}</td>
            </tr>
            <tr>
              <td className={styles.textCentered}>ג.</td>
              <td>שווי הנכס בניכוי הפחתה</td>
              <td className={styles.textCentered}>{formattingPrice(deltaPriceDeduction)}</td>
              <td>{getFinalPriceOnlyText(deltaPriceDeduction)}</td>
            </tr>
          </>
        )}
        <tr>
          <td className={styles.textCentered}>{showSecondThirdRowsCondition ? 'ד.' : 'ב.'}</td>
          <td>{`שווי למימוש מהיר/כפוי, הפחתה ${quickRealizeDeduction}% -`}</td>
          <td className={styles.textCentered}>{formattingPrice(diffPriceQuickRealizeDeductionSum)}</td>
          <td>{getFinalPriceOnlyText(diffPriceQuickRealizeDeductionSum)}</td>
        </tr>
        {Boolean(propertyBuildCostEstimate) && (
          <tr>
            <td className={styles.textCentered}>{showSecondThirdRowsCondition ? 'ה.' : 'ג.'}</td>
            <td>ערך כינון<NoteNumber id={'replacementValue'}>{1}</NoteNumber> של המחוברים</td>
            <td className={styles.textCentered}>{formattingPrice(propertyBuildCostEstimate)}</td>
            <td>{getFinalPriceOnlyText(propertyBuildCostEstimate)}</td>
          </tr>
        )}
        {Boolean(propertyLandRemainingValueEstimate) && (
          <tr>
            <td className={styles.textCentered}>{getLastItemLetter()}</td>
            <td>אומדן גס של שווי הקרקע של הנכס המוערך</td>
            <td className={styles.textCentered}>{formattingPrice(propertyLandRemainingValueEstimate)}</td>
            <td>{getFinalPriceOnlyText(propertyLandRemainingValueEstimate)}</td>
          </tr>
        )}
        </tbody>
      </table>
    </Section>
  );
};

const Line1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        calculationNotes
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(calculationNotes) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          <div className={classnames(styles.bold)}>הערות לתחשיב:</div>
          <div>{getBreakLinesTextShumaType3(calculationNotes)}</div>
        </div>
      )}
    </div>
  );
});

export default {
  TableWithHeader,
  Line1
};

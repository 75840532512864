import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import Select from 'react-select';
import {
  useCitiesContext,
  useStreetsContext
} from '../../../_common/components/CitiesAndStreets';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import FiltersLabel from '../../../_common/components/FiltersLabel';
import FilterDropdown from "../../../_common/components/FilterDropdown";
import FilterTextBox from "../../../_common/components/FilterTextBox";
import {DatePicker} from '../../../_common/components/FieldDatepicker';
import {useOldInspectionsPageUIContext} from '../OldInspectionsPageUIContext';
import {filtersBar} from '../../../_common/selectStyles/customSelectStyles';
import {filterOptions} from "../../../../../utils/filterOptions";
import {
  onChangeTextValue,
  clearFilterValue,
  onChangeDDValue
} from "../../../../../utils/changeFilterValues";
import {applyFilter} from "../../../../../utils/prepareApplyFilter";
import styles from './styles.module.scss';


export function OldInspectionsPageFilters() {
  const oldInspectionsUIContext = useOldInspectionsPageUIContext();
  const oldInspectionsUIProps = useMemo(() => {
    return {
      queryParams: oldInspectionsUIContext.queryParams,
      setQueryParams: oldInspectionsUIContext.setQueryParams,
    };
  }, [oldInspectionsUIContext]);
  const {cities} = useCitiesContext();
  const {streets, updateStreets} = useStreetsContext();

  const intl = useIntl();
  const searchByCity = intl.formatMessage({
    id: 'filter-placeholder-city',
    defaultMessage: 'City',
  });
  const searchByStreet = intl.formatMessage({
    id: 'filter-placeholder-street',
    defaultMessage: 'Street',
  });
  const searchByGush = intl.formatMessage({
    id: 'filter-placeholder-gush',
    defaultMessage: 'Gush',
  });
  const searchByHelka = intl.formatMessage({
    id: 'filter-placeholder-helka',
    defaultMessage: 'Helka',
  });
  const searchByFromDate = intl.formatMessage({
    id: 'filter-placeholder-fromDate',
    defaultMessage: 'From date',
  });
  const searchByToDate = intl.formatMessage({
    id: 'filter-placeholder-toDate',
    defaultMessage: 'To date',
  });

  const qs = new URLSearchParams(window.location.search);
  let cityID = qs.get('cityID') || '';
  let streetID = qs.get('streetID') || '';
  let gush = qs.get('gush') || '';
  let helka = qs.get('helka') || '';
  let toDate = qs.get('toDate') || '';
  let fromDate = qs.get('fromDate') || '';

  useEffect(() => {
    if (cityID) {
      updateStreets(cityID);
    }
  }, []);

  const handleCityChange = values => selected => {
    const {cityID} = values;
    if (!cityID || cityID !== selected.value) {
      updateStreets(selected.value);
    }
  };
  const onChangeCityID = (values) => (value) => (setFieldValue, handleSubmit, handleCityChange) => {
    setFieldValue('cityID', value);
    setFieldValue('streetID', '');
    handleSubmit();
    handleCityChange(values)({value});
  };
  const onChangeFromDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('fromDate', date);
    handleSubmit();
  };
  const onChangeToDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('toDate', date);
    handleSubmit();
  };

  const clearCityID = (setFieldValue, handleSubmit) => {
    setFieldValue('cityID', '');
    setFieldValue('streetID', '');
    handleSubmit();
  };
  const clearFromDate = (setFieldValue, handleSubmit) => {
    setFieldValue('fromDate', '');
    handleSubmit();
  };
  const clearToDate = (setFieldValue, handleSubmit) => {
    setFieldValue('toDate', '');
    handleSubmit();
  };

  return (
    <Formik
      initialValues={{
        cityID,
        streetID,
        gush,
        helka,
        fromDate,
        toDate,
      }}
      onSubmit={(values) => {
        applyFilter(values, oldInspectionsUIProps);
      }}
      enableReinitialize={true}
    >
      {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit} className={styles.formFilters}>
          <div className="form-group row d-flex mb-0 ml-8">
            <FiltersLabel/>
            <FilterDropdown
              name="cityID"
              value={filterOptions(values.cityID)(cities)}
              onChangeValue={({value}) => onChangeCityID(values)(value)(setFieldValue, handleSubmit, handleCityChange)}
              options={cities}
              placeholder={searchByCity}
              onClearValue={() => clearCityID(setFieldValue, handleSubmit)}
              customContainerClassname={"col-lg position-relative"}
            />
            <div className="col-lg position-relative">
              <Select
                name="streetID"
                value={streets ? filterOptions(values.streetID)(streets) : null}
                onChange={({value}) => onChangeDDValue('streetID', value)(setFieldValue, handleSubmit)}
                options={streets}
                styles={filtersBar}
                placeholder={searchByStreet}
                isSearchable={true}
                isAsync={true}
                defaultOptions={true}
                isDisabled={!values.cityID}
              />
              {values.cityID
                ?
                (<img
                  src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                  alt="clear-filter"
                  className={styles.clearFilterIcon}
                  onClick={() => clearFilterValue('streetID')(setFieldValue, handleSubmit)}
                />)
                :
                (<img
                  src={toAbsoluteUrl('/media/common/Cross_disabled.svg')}
                  alt="clear-filter"
                  className={styles.clearFilterIcon}
                />)
              }
            </div>
            <FilterTextBox
              name={"gush"}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue('gush', e)(setFieldValue, handleSubmit)}
              placeholder={searchByGush}
              value={values.gush}
              onClearValue={() => clearFilterValue('gush')(setFieldValue, handleSubmit)}
              customContainerClassName={"col-lg position-relative"}
            />
            <FilterTextBox
              name={"helka"}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue( 'helka', e)(setFieldValue, handleSubmit)}
              placeholder={searchByHelka}
              value={values.helka}
              onClearValue={() => clearFilterValue('helka')(setFieldValue, handleSubmit)}
              customContainerClassName={"col-lg position-relative"}
            />
            <div className="col-lg position-relative">
              <DatePicker
                className="h-35px pl-4"
                placeholder={searchByFromDate}
                value={fromDate}
                onChange={date => onChangeFromDate(date, setFieldValue, handleSubmit)}
                maxDate={toDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearFromDate(setFieldValue, handleSubmit)}
              />
            </div>
            <div className="col-lg position-relative">
              <DatePicker
                className="h-35px pl-4"
                placeholder={searchByToDate}
                value={toDate}
                onChange={date => onChangeToDate(date, setFieldValue, handleSubmit)}
                minDate={fromDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearToDate(setFieldValue, handleSubmit)}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default OldInspectionsPageFilters;

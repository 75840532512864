export const fileTypeId = {
  NESACH_TABU: 1,
  ENVIRONMENT_LOCATOR_MAP: 2,
  LAND_SHITUF: 3,
  LAND_HAHIRA: 4,
  LAND_HAFKAA: 5,
  LAND_HAZMADA: 6,
  LAND_ZIKAT_HANAA: 7,
  IDF_LOCATING_PLOT: 8,
  DEDICATED_AREA_MAP: 9,
  BUILDING_FACADE: 10,
  PHOTOS_VISIT_DATE: 11,
  COMMON_HOUSE_PLAN_LOCATING_APARTMENT: 12,
  PERMIT_BLUEPRINT: 13,
  PLANS_IN_EFFECT: 14,
  LICENSING_DOCUMENTS: 15,
  HOUSE_SHARED_DOCUMENTS: 16,
  ADDITIONAL_FILE: 17,
  FORM_4: 22,
  COMPLETE_CERT: 23,
  DANGEROUS_STRUCTURE: 24,
  HOUSE_SHARED_ORDER: 25,
  LAND_OTHER: 26,
  TAMA: 27,
  PURCHASE: 28,
  RENT: 29,
  REPORT: 30,
  SURVEY_ASSETS_EXCEL_FILE: 31,
  PROPERTY_LEGAL_DEFINITION: 32,
  PROPERTY_DRAWING: 33,
  BUILD_COST_ESTIMATE: 34,
  RENOVATION_COST_ESTIMATE: 35,
};

import React from 'react';
import classnames from 'classnames';
import {useField} from 'formik';
import {DatePicker as AntdDatepicker} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import locale from 'antd/es/date-picker/locale/he_IL';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import styles from './styles.module.scss';

const SimpleSeparator = () => {
  const separator = ' - ';
  return <div>{separator}</div>;
};

export const DatePicker = ({
                                   className,
                                   label,
                                   value,
                                   minDate,
                                   maxDate,
                                   onChange,
                                   onClear,
                                   placeholder,
                                   dateFormat = 'DD-MM-YYYY',
                                   clearable = false,
                                   disabled,
                                   ...rest
                                 }) => {
  return (
    <AntdDatepicker
      value={value && dayjs(value)}
      className={classnames(
        'form-control form-control-lg form-control-solid d-flex align-items-center justify-content-center',
        styles.datepicker,
        className,
      )}
      placeholder={placeholder}
      onChange={date => onChange(date.$d)}
      locale={locale}
      format={dateFormat}
      allowClear={clearable && (
        <img
          src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
          alt="clear-filter"
          className={styles.clearFilterIcon}
          onClick={() => onClear()}
        />
      )}
      suffixIcon
      showToday={false}
      disabled={disabled}
      minDate={minDate && dayjs(minDate)}
      maxDate={maxDate && dayjs(maxDate)}
      {...rest}
    />
  );
};

export const FilterRangePicker = ({
                                    className,
                                    onChange,
                                    dateFormat = 'DD-MM-YYYY',
                                    placeholder,
                                    startDate,
                                    endDate,
                                    clearable = false,
                                    onClear,
                                    ...rest
                                  }) => {
  return (
    <AntdDatepicker.RangePicker
      value={[startDate && dayjs(startDate), endDate && dayjs(endDate)]}
      className={classnames(
        'form-control form-control-lg form-control-solid d-flex align-items-center justify-content-between',
        styles.rangePicker,
        className,
      )}
      placeholder={placeholder}
      onChange={([startDate, endDate]) => onChange(startDate.$d, endDate.$d)}
      locale={locale}
      format={dateFormat}
      allowClear={clearable && (
        <img
          src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
          alt="clear-filter"
          className={styles.clearFilterIcon}
          onClick={() => onClear()}
        />
      )}
      separator={<SimpleSeparator/>}
      suffixIcon
      showToday={false}
      {...rest}
    />
  );
};

export const StyledFieldDatepicker = ({
                                        customContainerStyles,
                                        customLabelStyles,
                                        customInputStyles = 'form-control form-control-lg form-control-solid',
                                        label,
                                        value,
                                        onChange,
                                        onClear,
                                        placeholder,
                                        dateFormat = 'DD-MM-YYYY',
                                        clearable = false,
                                        disabled,
                                      }) => {
  return (
    <div className={customContainerStyles}>
      {label &&
        <div className={classnames(styles.labelContainer, customLabelStyles)}>
          {label}
        </div>
      }
      <div className={classnames({'opacity-30': disabled})}>
        <AntdDatepicker
          value={value && dayjs(value)}
          className={customInputStyles}
          placeholder={placeholder}
          onChange={date => onChange(date.$d)}
          locale={locale}
          format={dateFormat}
          allowClear={clearable && (
            <img
              src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
              alt="clear-filter"
              className={styles.clearFilterIcon}
              onClick={() => onClear()}
            />
          )}
          suffixIcon
          showToday={false}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const FieldDatepicker = ({
                           customContainerStyles,
                           customLabelStyles,
                           customInputStyles = 'form-control form-control-lg form-control-solid',
                           label,
                           fieldId,
                           onChange,
                           onClear,
                           placeholder,
                           dateFormat = 'DD-MM-YYYY',
                           clearable = false,
                           disabled,
                         }) => {
  const [field] = useField(fieldId);

  return (
    <div className={customContainerStyles}>
      {label &&
        <div className={classnames(styles.labelContainer, customLabelStyles)}>
          {label}
        </div>
      }
      <div className={classnames({'opacity-30': disabled})}>
        <AntdDatepicker
          value={field.value && dayjs(field.value)}
          className={customInputStyles}
          placeholder={placeholder}
          onChange={date => onChange(date.$d)}
          locale={locale}
          format={dateFormat}
          allowClear={clearable && (
            <img
              src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
              alt="clear-filter"
              className={styles.clearFilterIcon}
              onClick={() => onClear()}
            />
          )}
          suffixIcon
          showToday={false}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default FieldDatepicker;

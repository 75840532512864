import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {formattingDate} from '../../../utils/formattingHelper';
import styles from './styles.module.scss';

const ShumaType1and2PlansCheckDate = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    plansCheckDate,
    committeeName,
  } = context.data.pdfData;

  const plansCheckDateValue = moment(plansCheckDate).isValid() ? formattingDate(plansCheckDate) : '';

  return (
    <div ref={ref} className={styles.lastSectionLine}>
      {Boolean(plansCheckDate) && (
        <div className={classnames(styles.itemText, styles.innerPadding)}>
          2. בתאריך {plansCheckDateValue} ובמועדים קודמים נערכה בדיקה בועדה
          המקומית לתכנון ובניה "{committeeName}".
        </div>
      )}
    </div>
  );
});

export default ShumaType1and2PlansCheckDate;

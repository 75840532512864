import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const CustomerItem = ({
                        customerID,
                        firstName,
                        lastName,
                        phone,
                        email,
                        idNumber,
                      }) => {
 return (
    <div key={customerID} className={styles.container}>
      <div className={styles.name}>{`${firstName} ${lastName}`}</div>
      <div className={styles.details}>
        <span className={styles.phone}>{phone}</span>
        <span className={styles.email}>{email}</span>
        <span className={styles.idNumber}>{idNumber}</span>
      </div>
    </div>
  )
};

CustomerItem.propTypes = {
  customerID: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  idNumber: PropTypes.number,
};

export default CustomerItem;

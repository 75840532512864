import React, {
  createContext, useContext, useEffect
} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {shallowEqual, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {
  useCitiesContext,
  useStreetsContext
} from '../../_common/components/CitiesAndStreets/components/CitiesAndStreetsContext';

const OrderDetailsUIGlobalContext = createContext('');

export function useOrderDetailsUIGlobalContext() {
  return useContext(OrderDetailsUIGlobalContext);
}

export function OrderDetailsUIGlobalProvider({children}) {
  const intl = useIntl();
  const location = useLocation();

  const errorMessage = intl.formatMessage({
    id: 'request-failed',
    defaultMessage: 'Request failed',
  });
  const noOptionsMessage = intl.formatMessage({
    id: 'label-no-options',
    defaultMessage: 'No options',
  });
  const fieldLabelOldInspections = intl.formatMessage({
    id: 'tab-order-general-field-label-oldInspections',
    defaultMessage: 'Old inspections'
  });
  const fieldLabelUsingLawyer = intl.formatMessage({
    id: 'tab-order-general-field-label-usingLawyer',
    defaultMessage: 'Using lawyer'
  });
  const fieldLabelLawyerName = intl.formatMessage({
    id: 'tab-order-general-field-label-lawyerName',
    defaultMessage: 'Lawyer name',
  });
  const subHeaderContactDetails = intl.formatMessage({
    id: 'tab-order-general-sub-header-contact-details',
    defaultMessage: 'Contact details',
  });
  const fieldLabelFullName = intl.formatMessage({
    id: 'tab-order-general-field-label-fullName',
    defaultMessage: 'Full name',
  });
  const fieldLabelContactPhone = intl.formatMessage({
    id: 'tab-order-general-field-label-contactPhone',
    defaultMessage: 'Contact phone',
  });
  const fieldLabelContactJob = intl.formatMessage({
    id: 'tab-order-general-field-label-contactJob',
    defaultMessage: 'Contact job',
  });
  const fieldLabelContactOrganisation = intl.formatMessage({
    id: 'tab-order-general-field-label-contactOrganisation',
    defaultMessage: 'Contact organisation',
  });
  const fieldLabelContactEmail = intl.formatMessage({
    id: 'tab-order-general-field-label-contactEmail',
    defaultMessage: 'Contact email',
  });
  const fieldLabelContactAddress = intl.formatMessage({
    id: 'tab-order-general-field-label-contactAddress',
    defaultMessage: 'Contact address',
  });
  const subHeaderGeneral = intl.formatMessage({
    id: 'tab-order-general-sub-header-general',
    defaultMessage: 'General',
  });
  const fieldShumaForOrderCount = intl.formatMessage({
    id: 'tab-order-general-field-label-shumaForOrderCount',
    defaultMessage: 'Shuma for order count',
  });
  const noInspectionsAdded = intl.formatMessage({
    id: 'tab-order-inspections-no-inspections-added',
    defaultMessage: 'No inspections added',
  });
  const fieldDueDate = intl.formatMessage({
    id: 'tab-order-general-field-label-dueDate',
    defaultMessage: 'Due date',
  });
  const fieldResponsibleUser = intl.formatMessage({
    id: 'tab-order-general-field-label-responsibleUser',
    defaultMessage: 'Firm users',
  });
  const placeholderSelect = intl.formatMessage({
    id: 'select',
    defaultMessage: 'Select',
  });
  const buttonLabelCreateOrder = intl.formatMessage({
    id: 'tab-order-general-button-label-create-order',
    defaultMessage: 'Create an order',
  });
  const buttonLabelEditOrder = intl.formatMessage({
    id: 'tab-order-general-button-label-edit-order',
    defaultMessage: 'Edit an order',
  });
  const noFiles = intl.formatMessage({
    id: 'no-files',
    defaultMessage: 'No files'
  });
  const successMessage = intl.formatMessage({
    id: 'data-sent-success',
    defaultMessage: 'Success'
  });
  const labelUploadFiles = intl.formatMessage({
    id: 'tab-order-documents-label-upload',
    defaultMessage: 'Label upload files'
  });
  const buttonLabelUploadGeneral = intl.formatMessage({
    id: 'tab-order-documents-button-label-upload-general',
    defaultMessage: 'Upload general file'
  });
  const buttonLabelUploadFinancial = intl.formatMessage({
    id: 'tab-order-documents-button-label-upload-financial',
    defaultMessage: 'Upload financial file'
  });
  const tabOrderDocumentsGridHeader = intl.formatMessage({
    id: 'tab-order-documents-grid-header',
    defaultMessage: 'Files'
  });
  const tabOrderDocumentsGridColumnUploadDate = intl.formatMessage({
    id: 'tab-order-documents-column-uploadDate',
    defaultMessage: 'Upload date'
  });
  const tabOrderDocumentsGridColumnName = intl.formatMessage({
    id: 'tab-order-documents-column-fileName',
    defaultMessage: 'Name'
  });
  const tabOrderDocumentsGridColumnType = intl.formatMessage({
    id: 'tab-order-documents-column-fileType',
    defaultMessage: 'Type'
  });
  const tabOrderDocumentsGridColumnDocType = intl.formatMessage({
    id: 'tab-order-documents-column-docType',
    defaultMessage: 'Document type'
  });
  const tabOrderDocumentsGridColumnDelete = intl.formatMessage({
    id: 'tab-order-documents-column-delete',
    defaultMessage: 'Delete'
  });
  const tabOrderDocumentsGridColumnDownload = intl.formatMessage({
    id: 'tab-order-documents-column-download',
    defaultMessage: 'Download'
  });
  const tabOrderDocumentsItemFileTypeIDGeneral = intl.formatMessage({
    id: 'tab-order-documents-column-file-type-general',
    defaultMessage: 'General'
  });
  const tabOrderDocumentsItemFileTypeIDFinancial = intl.formatMessage({
    id: 'tab-order-documents-column-file-type-financial',
    defaultMessage: 'Financial'
  });
  const tabOrderDocumentsButtonLabelComplete = intl.formatMessage({
    id: 'tab-order-documents-button-label-complete',
    defaultMessage: 'Complete'
  });
  const buttonCreateInspectionsLabel = intl.formatMessage({
    id: 'tab-order-inspections-button-create-inspections',
    defaultMessage: 'Create inspections'
  });

  const textContent = {
    fieldLabelLawyerName,
    subHeaderContactDetails,
    fieldLabelFullName,
    fieldLabelContactPhone,
    fieldLabelContactJob,
    fieldLabelContactOrganisation,
    fieldLabelContactEmail,
    fieldLabelContactAddress,
    subHeaderGeneral,
    fieldShumaForOrderCount,
    fieldDueDate,
    fieldResponsibleUser,
    placeholderSelect,
    buttonLabelCreateOrder,
    buttonLabelEditOrder,
    noInspectionsAdded,
    successMessage,
    noOptionsMessage,
    noFiles,
    labelUploadFiles,
    buttonLabelUploadGeneral,
    buttonLabelUploadFinancial,
    tabOrderDocumentsGridHeader,
    tabOrderDocumentsGridColumnUploadDate,
    tabOrderDocumentsGridColumnName,
    tabOrderDocumentsGridColumnType,
    tabOrderDocumentsGridColumnDocType,
    tabOrderDocumentsGridColumnDownload,
    tabOrderDocumentsGridColumnDelete,
    tabOrderDocumentsItemFileTypeIDGeneral,
    tabOrderDocumentsItemFileTypeIDFinancial,
    tabOrderDocumentsButtonLabelComplete,
    buttonCreateInspectionsLabel,
    errorMessage,
    fieldLabelUsingLawyer,
    fieldLabelOldInspections,
  };

  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );
  const {cities} = useCitiesContext();
  const {streets} = useStreetsContext();

  const getOrderID = () => {
    const pathName = location.pathname;
    const orderNumber = pathName.replace('/order-details/', '');

    return orderNumber === '-1' ? -1 : parseInt(orderNumber, 10);
  };
  const orderID = getOrderID();

  const value = {
    orderID,
    textContent,
    users,
    cities,
    streets,
  };

  return (
    <OrderDetailsUIGlobalContext.Provider value={value}>
      {children}
    </OrderDetailsUIGlobalContext.Provider>
  );
}

OrderDetailsUIGlobalProvider.propTypes = {
  children: PropTypes.node,
};

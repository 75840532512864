import React from 'react';
import PagesStack from '../PagesStack';
import Logo from '../Logo';
import DocPropsDateShuma from '../DocPropsDateShuma/DocPropsDateShuma';
import Footer from '../Footer';
import styles from './styles.module.scss';


const CompleteInspectionPagesView = () => {
  return (
    <div className={styles.container}>
      <Logo/>
      <DocPropsDateShuma/>
      <PagesStack/>
      <Footer/>
    </div>
  );
};

export default CompleteInspectionPagesView;


import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {
  presentorAssociationOptions,
  presentorIdTypeOptions,
  propertyPopulatedOptions,
  visitorJobOptions
} from '../../../constants/options';
import {getSelectedOptionLabel} from '../../../utils/options';
import {getBreakLinesTextShumaType3} from "../../../utils/texts";
import styles from './styles.module.scss';

const HeadLine1 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <HeadContent/>
      <Line1Content/>
    </div>
  )
})

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section5}. `}</span>
        <span>ביקור בנכס:</span>
      </span>
    </div>
  );
}

const Line1Content = () => {
  const {
    data: {
      pdfData: {
        visitDate,
        visitorName,
        visitorJob
      },
    },
  } = useCompleteInspectionContext();
  const visitorJobName = getSelectedOptionLabel(visitorJob, visitorJobOptions);

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>עורך הביקור: נערך ביקור פיזי בנכס בתאריך: </span>
      <span>{`${moment(visitDate).format('DD/MM/YYYY')} `}</span>
      <span className={styles.paddingOne}/>
      <span className={classnames(styles.bold)}>ע"י: </span>
      <span>{`${visitorName} `}</span>
      <span className={styles.paddingOne}/>
      <span className={classnames(styles.bold)}>תפקיד: </span>
      <span>{`${visitorJobName}`}</span>
    </div>
  );
}

const Line2 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        presentorGender,
        presentorName,
        presentorIDType,
        presentorIDNumber
      },
    },
  } = useCompleteInspectionContext();

  const getPresentorGenderText = (value) => {
    switch (value) {
      case 1:
        return 'מר';
      case 2:
        return 'הגב\'';
      default:
        return '';
    }
  };
  const presentorGenderText = getPresentorGenderText(presentorGender);
  const presentorIDTypeText = getSelectedOptionLabel(presentorIDType, presentorIdTypeOptions);

  const text1 = [
    presentorGenderText,
    ' ',
    presentorName,
  ].filter(Boolean).join(' ');
  const text2 = [
    presentorIDTypeText,
    ' ',
    presentorIDNumber
  ].filter(Boolean).join(' ');

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
      {Boolean(presentorName)
        ? (
          <>
            <span className={classnames(styles.bold)}>מציג הנכס - שם:</span>
            {` ${text1} `}
            <span className={styles.paddingOne}/>
            <span className={classnames(styles.bold)}>זיהוי המציג ע"י:</span>
            {` ${text2}`}
          </>
        )
        : <span className={classnames(styles.bold)}>מציג הנכס -ביקור עצמי</span>
      }
    </div>
  );
});

const Line3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        presentorAssociation,
        propertyPopulated,
        presentorName
      },
    },
  } = useCompleteInspectionContext();

  const presentorAssociationText = getSelectedOptionLabel(presentorAssociation, presentorAssociationOptions);
  const propertyPopulatedText = getSelectedOptionLabel(propertyPopulated, propertyPopulatedOptions);

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)} ref={ref}>
      {Boolean(presentorName)
        ? (
          <>
            <span className={classnames(styles.bold)}>זיקת המציג לנכס:</span>
            {` ${presentorAssociationText} `}
            <span className={styles.paddingOne}/>
          </>
        )
        : null
      }
      <span className={classnames(styles.bold)}>המחזיק בנכס ומעמדו:</span>
      {` ${propertyPopulatedText}`}
    </div>
  );
});

const Line4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        propertyPopulatedNotes,
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(propertyPopulatedNotes) && (
        <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
          {getBreakLinesTextShumaType3(propertyPopulatedNotes)}
        </div>
      )}
    </div>
  );
});


export default {HeadLine1, Line2, Line3, Line4};

import axios from "axios";
import {showGlobalMessage} from "../components/GlobalMessage/actions/showGlobalMessage";
import {messages as M} from "../../../../_metronic/i18n/messages";

export const FIND_CUSTOMER = "/customers/findCustomer";

export const findCustomer = (customerName) => dispatch => {
  const data = {customerName};

  return axios.post(FIND_CUSTOMER, data)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) {
        dispatch(showGlobalMessage(M[errorCode]))
      }

      return {options: body.customers};
    })
    .catch((err) => {
      console.log(err)
      dispatch(showGlobalMessage(M['-1']))
    });
};

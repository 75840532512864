export const decisionTree = {
  rightsEstimation: '1',
  propertyTypeID: {
    construction: '1',
    floor: '2',
    areas: '3',
  },
  bankSecure: '2',
  bankID: {
    national: '1',
    hapoalim: '2',
    mizrahi: '3',
    discount: '4',
    international: '5',
  },
  shumaTypeID: {
    standard: '2',
    abbreviated: '3',
    extended: '4',
  },
  loanTypeID: {
    inQuestion: '1',
    other: '2',
    bankFinancing: '3',
    existingProperty: '4',
    renovations: '5',
    selfConstruction: '6',
    another: '7',
  },
  otherPropertyTypeID: {
    construction: '1',
    floor: '2',
    parkingLot: '3',
    office: '4',
    shop: '5',
    land: '6',
  }
};

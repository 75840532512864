import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import classnames from "classnames";
import styles from "./styles.module.scss";

const CACHE = {};

const FilterInputTypeahead = ({
                                fieldId,
                                value,
                                labelKey,
                                placeholder,
                                onChange,
                                setFieldValue,
                                renderMenuItemChildren,
                                dispatch,
                                typeaheadLabel = '',
                                customContainerStyles = "col-lg position-relative",
                                inputCustomStyles = 'form-control form-control-lg form-control-solid h-35px pl-4',
                                labelCustomStyles = '',
                                handleFindItem,
                                onClearValue,
                                ...rest
                              }) => {
  const intl = useIntl();
  const typeaheadEmptyLabel = intl.formatMessage({
    id: 'typeahead-search-customers-empty-label',
    defaultMessage: 'Empty',
  });
  const typeaheadSearch = intl.formatMessage({
    id: 'typeahead-search-customers-text',
    defaultMessage: 'Search',
  });
  const typeaheadPrompt = intl.formatMessage({
    id: 'typeahead-selected-customer-prompt',
    defaultMessage: 'Type',
  });
  const searchByCustomer = intl.formatMessage({
    id: 'filter-placeholder-customer',
    defaultMessage: 'Customer',
  });

  const [options, setOptions] = useState([]);
  const handleSearch = useCallback((query) => {
    if (CACHE[query]) {
      setOptions(CACHE[query].options);
      return;
    }
    handleFindItem(query).then((resp) => {
      setOptions(resp.options);
    });
  }, []);

  return (
    <div className={customContainerStyles}>
      <AsyncTypeahead
        id={fieldId}
        onSearch={handleSearch}
        onChange={onChange}
        options={options}
        paginate={false}
        labelKey={labelKey}
        emptyLabel={typeaheadEmptyLabel}
        searchText={typeaheadSearch}
        promptText={typeaheadPrompt}
        renderInput={({inputRef, referenceElementRef, className, ...inputProps}) => (
          <label className={classnames(styles.inputContainer)}>
            <input
              id={fieldId}
              name={fieldId}
              value={value}
              placeholder={placeholder}
              className={classnames(
                `form-control form-control-lg form-control-solid`,
                inputCustomStyles,
                {[styles.customInput]: placeholder !== searchByCustomer})}
              type={'text'}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
              onChange={e => {
                setFieldValue(fieldId, e.target.value);
                inputProps.onChange(e);
              }}
              {...rest}
            />
          </label>
        )}
        renderMenuItemChildren={renderMenuItemChildren}
        useCache={false}
      />
      <img
        src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
        alt="clear-filter"
        className={styles.clearFilterIcon}
        onClick={onClearValue}
      />
    </div>
  )
};

FilterInputTypeahead.propTypes = {
  fieldId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  labelKey: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  renderMenuItemChildren: PropTypes.func,
  typeaheadLabel: PropTypes.node,
  customContainerStyles: PropTypes.string,
  handleFindItem: PropTypes.func,
  onClearValue: PropTypes.func,
};

export default FilterInputTypeahead;

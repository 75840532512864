import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormikProvider} from 'formik';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import {checkEmailIsValid} from '../../_common/utils/checkEmailIsValid';
import {showGlobalMessage} from "../../_common/components/GlobalMessage/actions/showGlobalMessage";
import {messages as M} from "../../../../_metronic/i18n/messages";
import {useCustomerDetailsUIContext} from "./CustomerDetailsUIContext";
import {createPaymentLink} from "../actions/CustomerGreenInvoiceActions";

const PopupCreatePaymentLinkContext = createContext('');

export function usePopupCreatePaymentLinkContext() {
  return useContext(PopupCreatePaymentLinkContext);
}

export function PopupCreatePaymentLinkProvider({children}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {generalDetails} = useCustomerDetailsUIContext();
  const {greenInvoiceApiKey, greenInvoiceSecret, greenInvoicePaymentPluginID} = useSelector(
    (state) => ({
      greenInvoiceApiKey: state.auth.greenInvoiceApiKey,
      greenInvoiceSecret: state.auth.greenInvoiceSecret,
      greenInvoicePaymentPluginID: state.auth.greenInvoicePaymentPluginID,
    }),
    shallowEqual
  );

  const validate = (values) => {
    const {email} = values;
    let errors = {};

    ['description', 'amount', 'maxPayments', 'type', 'email']
      .forEach(function (item) {
      if (!values[item]) errors[item] = 'Required field!';
    });

    if (email && !checkEmailIsValid(email)) {
      errors['email'] = 'Invalid email address!';
    }

    return errors;
  };

  const successMessage = intl.formatMessage({
    id: 'customer-details-popup-payment-link-success',
    defaultMessage: 'Link for payment was successfully created!'
  });

  const handleSubmit = async (values) => {
    const {amount, ...rest} = values;
    const data = {
      client: {
        id: generalDetails.greenInvoiceClientID
      },
      pluginId: greenInvoicePaymentPluginID,
      amount: Boolean(amount) ? parseFloat(amount) : 0,
      fullCustomerName: [generalDetails.firstName, generalDetails.lastName].filter(Boolean).join(' '),
      ...rest
    };
    try {
      const {errorCode, body: {errorMessage}} = await dispatch(createPaymentLink(data, greenInvoiceApiKey, greenInvoiceSecret));

      const message = errorCode < 0
        ? [M.GREEN_INVOICE_CREATE_PAYMENT_LINK_ERROR, errorMessage].filter(Boolean).join(' ')
        : successMessage;
      await dispatch(showGlobalMessage(message));
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      description: '',
      amount: '',
      remarks: '',
      maxPayments: 1,
      type: 320,
      email: generalDetails.email || '',
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
    },
    enableReinitialize: true,
  });

  const value = {
    formik,
    handleSubmit,
    hasPluginID: Boolean(greenInvoicePaymentPluginID),
  };

  return (
    <PopupCreatePaymentLinkContext.Provider value={value}>
      <FormikProvider value={formik}>
        {children}
      </FormikProvider>
    </PopupCreatePaymentLinkContext.Provider>
  );
}

PopupCreatePaymentLinkProvider.propTypes = {
  children: PropTypes.node
};

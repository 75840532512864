import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import FilterTextBox from '../../../_common/components/FilterTextBox';
import CommonButton from '../../../_common/components/CommonButton';
import FilterDropdown from '../../../_common/components/FilterDropdown';
import {FilterRangePicker} from '../../../_common/components/FieldDatepicker';
import FiltersLabel from '../../../_common/components/FiltersLabel';
import CommonPopup from '../../../_common/components/CommonPopup';
import PriceQuoteCreate from '../PopupCreateEditPriceQuote/PriceQuoteCreate';
import {usePriceQuotesPageUIContext} from '../PriceQuotesPageUIContext';
import {PriceQuoteCreateUIGlobalProvider} from '../PopupCreateEditPriceQuote/PriceQuoteCreateUIContext';
import {filterOptionsForFilters} from '../../../_common/utils/filterOptions';
import {formatStringToFloatNumber, integerValue} from '../../../_common/utils/changeInputValue';
import {clearFilterValue} from '../../../../../utils/changeFilterValues';
import {applyFilter} from '../../../../../utils/prepareApplyFilter';
import {priceQuoteStatus} from '../../../_common/constants/options';
import styles from './styles.module.scss';


export function PriceQuotesPageFilters() {
  const priceQuotesPageUIContext = usePriceQuotesPageUIContext();
  const priceQuotesPageUIProps = useMemo(() => {
    return {
      queryParams: priceQuotesPageUIContext.queryParams,
      setQueryParams: priceQuotesPageUIContext.setQueryParams,
    };
  }, [priceQuotesPageUIContext]);

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const intl = useIntl();
  const searchByPriceQuoteID = intl.formatMessage({
    id: 'filter-placeholder-price-quote-id',
    defaultMessage: 'Price quote number',
  });
  const searchByCreatedDate = intl.formatMessage({
    id: 'filter-placeholder-create-date-from-to',
    defaultMessage: 'Create date',
  });
  const searchByStatus = intl.formatMessage({
    id: 'filter-placeholder-status',
    defaultMessage: 'Status',
  });
  const searchByPriceFrom = intl.formatMessage({
    id: 'filter-placeholder-price-from',
    defaultMessage: 'Price from',
  });
  const searchByPriceTo = intl.formatMessage({
    id: 'filter-placeholder-price-to',
    defaultMessage: 'Price to',
  });
  const buttonCreatePriceQuoteLabel = intl.formatMessage({
    id: 'price-quotes-page-button-create-new',
    defaultMessage: 'Create price quote',
  });

  const qs = new URLSearchParams(window.location.search);
  let priceQuoteID = qs.get('priceQuoteID') || '';
  let fromCreateDate = qs.get('fromCreateDate') || '';
  let toCreateDate = qs.get('toCreateDate') || '';
  let status = qs.get('status') || '';
  let fromPrice = qs.get('fromPrice') || '';
  let toPrice = qs.get('toPrice') || '';

  const onChangePriceQuoteNumber = (e) => (setFieldValue, handleSubmit) => {
    setFieldValue('priceQuoteID', integerValue(e));
    handleSubmit();
  };
  const onChangeCreatedDate = (fromDate, toDate, setFieldValue, handleSubmit) => {
    setFieldValue('fromCreateDate', fromDate || '');
    setFieldValue('toCreateDate', toDate || '');
    handleSubmit();
  };
  const onChangeStatus = (value) => (setFieldValue, handleSubmit) => {
    setFieldValue('status', value);
    handleSubmit();
  };
  const onChangePriceFrom = (e) => (setFieldValue, handleSubmit) => {
    setFieldValue('fromPrice', formatStringToFloatNumber(e));
    handleSubmit();
  };
  const onChangePriceTo = (e) => (setFieldValue, handleSubmit) => {
    setFieldValue('toPrice', formatStringToFloatNumber(e));
    handleSubmit();
  };

  const clearCreatedDate = (setFieldValue, handleSubmit) => {
    setFieldValue('fromCreateDate', '');
    setFieldValue('toCreateDate', '');
    handleSubmit();
  };

  return (
    <Formik
      initialValues={{
        priceQuoteID,
        fromCreateDate,
        toCreateDate,
        status,
        fromPrice,
        toPrice
      }}
      onSubmit={(values) => {
        applyFilter(values, priceQuotesPageUIProps);
      }}
      enableReinitialize={true}
    >
      {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit} className={styles.formFilters}>
          <div className="form-group row d-flex mb-0 ml-8">
            <FiltersLabel/>
            <FilterTextBox
              name={'priceQuoteID'}
              placeholder={searchByPriceQuoteID}
              value={values.priceQuoteID}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangePriceQuoteNumber(e)(setFieldValue, handleSubmit)}
              onClearValue={() => clearFilterValue('priceQuoteID')(setFieldValue, handleSubmit)}
              customContainerClassName={'col-lg position-relative'}
            />
            <div className="col-lg position-relative">
              <FilterRangePicker
                className="h-35px pl-4 pr-12"
                onChange={(fromDate, toDate) => onChangeCreatedDate(fromDate, toDate, setFieldValue, handleSubmit)}
                placeholder={searchByCreatedDate}
                startDate={fromCreateDate}
                endDate={toCreateDate}
              />
              <img
                src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
                alt="clear-filter"
                className={styles.clearFilterIcon}
                onClick={() => clearCreatedDate(setFieldValue, handleSubmit)}
              />
            </div>
            <FilterDropdown
              name="status"
              value={filterOptionsForFilters(values.status)(priceQuoteStatus)}
              onChangeValue={({value}) => onChangeStatus(value)(setFieldValue, handleSubmit)}
              options={priceQuoteStatus}
              placeholder={searchByStatus}
              onClearValue={() => clearFilterValue('status')(setFieldValue, handleSubmit)}
              customContainerClassname={'col-md-2 position-relative'}
            />
            <FilterTextBox
              name={'fromPrice'}
              placeholder={searchByPriceFrom}
              value={values.fromPrice}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangePriceFrom(e)(setFieldValue, handleSubmit)}
              onClearValue={() => clearFilterValue('fromPrice')(setFieldValue, handleSubmit)}
              customContainerClassName={'col-lg position-relative'}
            />
            <FilterTextBox
              name={'toPrice'}
              placeholder={searchByPriceTo}
              value={values.toPrice}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangePriceTo(e)(setFieldValue, handleSubmit)}
              onClearValue={() => clearFilterValue('toPrice')(setFieldValue, handleSubmit)}
              customContainerClassName={'col-lg position-relative'}
            />
            <div className="col-lg position-relative d-flex justify-content-center">
              <CommonButton
                label={
                  <>
                    <img
                      src={toAbsoluteUrl('/media/common/Plus.svg')}
                      alt="create"
                      className={'w-15px h-15px mr-2'}
                    />
                    {buttonCreatePriceQuoteLabel}
                  </>
                }
                onClick={() => handleShowModal()}
                customButtonClassNames={'btn btn-warning h-35px w-150px btn-sm font-weight-bolder text-dark text-nowrap'}
              />
              <CommonPopup
                isOpen={show}
                commonButton={false}
                content={
                  <PriceQuoteCreateUIGlobalProvider
                    closeModal={handleCloseModal}
                  >
                    <PriceQuoteCreate/>
                  </PriceQuoteCreateUIGlobalProvider>
                }
                size={'m'}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default PriceQuotesPageFilters;

import {actionTypes} from "../reducers/usersReduser";
import axios from "axios";

export const GET_USERS_LIST = "/inspections/getUsersList";

export const getUsers = () => dispatch => {
  return axios.post(GET_USERS_LIST)
    .then(response => {
      const {body, errorCode} = response.data;
      if (errorCode < 0) throw new Error();
      const {users} = body;

      dispatch({
        type: actionTypes.getUsers,
        payload: {users: transformUsers(users)},
      });
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

const transformUsers = users => users.map(user => {
  const {userID, userName} = user;
  return {label: userName, value: userID};
});
import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import {useInspectionsPageUIContext} from '../InspectionsPageUIContext';
import {useCitiesContext, useStreetsContext} from '../../../_common/components/CitiesAndStreets';
import FiltersLabel from '../../../_common/components/FiltersLabel';
import FilterDropdown from '../../../_common/components/FilterDropdown';
import FilterTextBox from '../../../_common/components/FilterTextBox';
import SimpleButtonDropdown from "../../../_common/components/SimpleButtonDropdown";
import ButtonClearFilter from "../../../_common/components/ButtonClearFilter";
import {DatePicker} from '../../../_common/components/FieldDatepicker';
import {clearFilterValue, onChangeDDValue, onChangeTextValue} from '../../../../../utils/changeFilterValues';
import {filterOptions} from '../../../../../utils/filterOptions';
import {applyFilter} from '../../../../../utils/prepareApplyFilter';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {useDecisionTreeOptionsContext} from "../../../_common/components/DecisionTreeOptions";
import styles from './styles.module.scss';

export function InspectionsPageFilters({users, shumaTypes}) {
  const inspectionsUIContext = useInspectionsPageUIContext();
  const inspectionsUIProps = useMemo(() => {
    return {
      queryParams: inspectionsUIContext.queryParams,
      setQueryParams: inspectionsUIContext.setQueryParams,
    };
  }, [inspectionsUIContext]);
  const {cities} = useCitiesContext();
  const {streets, updateStreets} = useStreetsContext();
  const {propertyTypes} = useDecisionTreeOptionsContext();

  useEffect(() => {
    if (cityID) {
      updateStreets(cityID)
    }
  }, []);

  const intl = useIntl();
  // main
  const searchByCity = intl.formatMessage({
    id: 'filter-placeholder-city',
    defaultMessage: 'City',
  });
  const searchByGush = intl.formatMessage({
    id: 'filter-placeholder-gush',
    defaultMessage: 'Gush',
  });
  const searchByHelka = intl.formatMessage({
    id: 'filter-placeholder-helka',
    defaultMessage: 'Helka',
  });
  const searchByShuma = intl.formatMessage({
    id: 'filter-placeholder-shumaID',
    defaultMessage: 'Shuma',
  });
  const searchByShumaType = intl.formatMessage({
    id: 'filter-placeholder-shumaType',
    defaultMessage: 'Shuma type',
  });
  const searchByPropertyType = intl.formatMessage({
    id: 'filter-placeholder-propertyType',
    defaultMessage: 'Property type',
  });
  const searchByUser = intl.formatMessage({
    id: 'filter-placeholder-user',
    defaultMessage: 'User',
  });
  // advanced
  const searchByStreet = intl.formatMessage({
    id: 'filter-placeholder-street',
    defaultMessage: 'Street',
  });
  const searchByFromDate = intl.formatMessage({
    id: 'filter-placeholder-fromDate',
    defaultMessage: 'From date',
  });
  const searchByToDate = intl.formatMessage({
    id: 'filter-placeholder-toDate',
    defaultMessage: 'To date',
  });
  const searchByStatus = intl.formatMessage({
    id: 'filter-placeholder-status',
    defaultMessage: 'Status',
  });
  const searchByElevator = intl.formatMessage({
    id: 'filter-placeholder-elevator',
    defaultMessage: 'Elevator',
  });
  const searchByFromRoomCount = intl.formatMessage({
    id: 'filter-placeholder-fromRoomCount',
    defaultMessage: 'From room count',
  });
  const searchByToRoomCount = intl.formatMessage({
    id: 'filter-placeholder-toRoomCount',
    defaultMessage: 'To room count',
  });
  const searchByFromRegisteredArea = intl.formatMessage({
    id: 'filter-placeholder-fromRegisteredArea',
    defaultMessage: 'From reg area',
  });
  const searchByToRegisteredArea = intl.formatMessage({
    id: 'filter-placeholder-toRegisteredArea',
    defaultMessage: 'To reg area',
  });
  const searchByCustomer = intl.formatMessage({
    id: 'filter-placeholder-customerName',
    defaultMessage: 'Customer',
  });
  const searchByFromDueDate = intl.formatMessage({
    id: 'filter-placeholder-fromDueDate',
    defaultMessage: 'From due date',
  });
  const searchByToDueDate = intl.formatMessage({
    id: 'filter-placeholder-toDueDate',
    defaultMessage: 'To due date',
  });

  const statuses = [
    {
      value: '1',
      label: intl.formatMessage({
        id: 'inspections-page-filter-status-inProgress',
        defaultMessage: 'In progress',
      })
    },
    {
      value: '3',
      label: intl.formatMessage({
        id: 'inspections-page-filter-status-rejected',
        defaultMessage: 'Rejected',
      })
    },
    {
      value: '4',
      label: intl.formatMessage({
        id: 'inspections-page-filter-status-approved',
        defaultMessage: 'approved',
      })
    },
    {
      value: '5',
      label: intl.formatMessage({
        id: 'inspections-page-filter-status-completed',
        defaultMessage: 'Completed',
      })
    },
    {
      value: '6',
      label: intl.formatMessage({
        id: 'inspections-page-filter-status-pendingReview',
        defaultMessage: 'Pending review',
      })
    },
    {
      value: '7',
      label: intl.formatMessage({
        id: 'inspections-page-filter-status-pendingSignature',
        defaultMessage: 'Pending signature',
      })
    },
  ];
  const elevatorPresence = [
    {
      value: '0',
      label: intl.formatMessage({
        id: 'inspections-page-filter-elevator-absent',
        defaultMessage: 'Absent',
      })
    },
    {
      value: '1',
      label: intl.formatMessage({
        id: 'inspections-page-filter-elevator-got',
        defaultMessage: 'Elevator',
      }),
    },
  ];

  const qs = new URLSearchParams(window.location.search);
  // main
  let cityID = qs.get('cityID') || '';
  let gush = qs.get('gush') || '';
  let helka = qs.get('helka') || '';
  let shumaID = qs.get('shumaID') || '';
  let shumaTypeID = qs.get('shumaTypeID') || '';
  let propertyTypeID = qs.get('propertyTypeID') || '';
  let responsibleUserID = qs.get('responsibleUserID') || '';
  // advanced
  let streetID = qs.get('streetID') || '';
  let toDate = qs.get('toDate') || '';
  let fromDate = qs.get('fromDate') || '';
  let statusTypeID = qs.get('statusTypeID') || '';
  let elevator = qs.get('elevator') || '';
  let fromRoomCount = qs.get('fromRoomCount') || '';
  let toRoomCount = qs.get('toRoomCount') || '';
  let fromRegisteredArea = qs.get('fromRegisteredArea') || '';
  let toRegisteredArea = qs.get('toRegisteredArea') || '';
  let customerName = qs.get('customerName') || '';
  let fromShumaDueDate = qs.get('fromShumaDueDate') || '';
  let toShumaDueDate = qs.get('toShumaDueDate') || '';

  const handleCityChange = values => selected => {
    const {cityID} = values;
    if (!cityID || cityID !== selected.value) {
      updateStreets(selected.value);
    }
  };
  const onChangeCityID = (values) => (value) => (setFieldValue, handleSubmit, handleCityChange) => {
    setFieldValue('cityID', value);
    setFieldValue('streetID', '');
    handleSubmit();
    handleCityChange(values)({value});
  };
  const onChangeFromDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('fromDate', date);
    handleSubmit();
  };
  const onChangeToDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('toDate', date);
    handleSubmit();
  };
  const onChangeFromDueDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('fromShumaDueDate', date);
    handleSubmit();
  };
  const onChangeToDueDate = (date, setFieldValue, handleSubmit) => {
    setFieldValue('toShumaDueDate', date);
    handleSubmit();
  };

  const clearCityID = (setFieldValue, handleSubmit) => {
    setFieldValue('cityID', '');
    setFieldValue('streetID', '');
    handleSubmit();
  };
  const clearFromDate = (setFieldValue, handleSubmit) => {
    setFieldValue('fromDate', '');
    handleSubmit();
  };
  const clearToDate = (setFieldValue, handleSubmit) => {
    setFieldValue('toDate', '');
    handleSubmit();
  };
  const clearFromDueDate = (setFieldValue, handleSubmit) => {
    setFieldValue('fromShumaDueDate', '');
    handleSubmit();
  };
  const clearToDueDate = (setFieldValue, handleSubmit) => {
    setFieldValue('toShumaDueDate', '');
    handleSubmit();
  };

  const filterClassnames = 'w-200px col-sm position-relative';
  const getFixForTypingSpace = () => {
    return {
      onClick: (e) => e.stopPropagation(),
      onKeyDown: (e) => e.keyCode === 32 ? e.stopPropagation() : null,
    }
  };

  return (
    <Formik
      initialValues={{
        cityID,
        gush,
        helka,
        shumaID,
        shumaTypeID,
        propertyTypeID,
        responsibleUserID,
        streetID,
        toDate,
        fromDate,
        statusTypeID,
        elevator,
        fromRoomCount,
        toRoomCount,
        fromRegisteredArea,
        toRegisteredArea,
        customerName,
        fromShumaDueDate,
        toShumaDueDate
      }}
      onSubmit={(values) => applyFilter(values, inspectionsUIProps)}
      enableReinitialize={true}
    >
      {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit} className={styles.formFilters}>
          <div className={'form-group row d-flex flex-nowrap mb-0 ml-8'}>
            <FiltersLabel/>
            <SimpleButtonDropdown
              type={'closeOnButton'}
              customToggleStyles={{
                margin: '0 1rem',
                height: '100%',
              }}
              customMenuStyles={{width: 'fit-content'}}
              title={<img className={'p-2 w-24px'} src={toAbsoluteUrl('/media/common/Dots.svg')} alt="dots"/>}
              items={[
                {
                  itemContent:
                    (
                      <div className={styles.filtersItem}>
                        <FilterDropdown
                          name="streetID"
                          value={(streets) ? filterOptions(values.streetID)(streets) : null}
                          onChangeValue={({value}) => onChangeDDValue('streetID', value)(setFieldValue, handleSubmit)}
                          options={streets}
                          placeholder={searchByStreet}
                          onClearValue={() => clearFilterValue('streetID')(setFieldValue, handleSubmit)}
                          customContainerClassname={filterClassnames}
                          disabled={!values.cityID}
                        />
                        <div className={filterClassnames}>
                          <DatePicker
                            className="h-35px px-4"
                            placeholder={searchByFromDate}
                            value={fromDate}
                            onChange={date => onChangeFromDate(date, setFieldValue, handleSubmit)}
                            maxDate={toDate}
                          />
                          <ButtonClearFilter
                            onClick={() => clearFromDate(setFieldValue, handleSubmit)}
                            className={styles.clearIcon}
                          />
                        </div>
                        <div className={filterClassnames}>
                          <DatePicker
                            className="h-35px px-4"
                            placeholder={searchByToDate}
                            value={toDate}
                            onChange={date => onChangeToDate(date, setFieldValue, handleSubmit)}
                            minDate={fromDate}
                          />
                          <ButtonClearFilter
                            onClick={() => clearToDate(setFieldValue, handleSubmit)}
                            className={styles.clearIcon}
                          />
                        </div>
                        <FilterDropdown
                          name="statusTypeID"
                          value={filterOptions(values.statusTypeID)(statuses)}
                          onChangeValue={({value}) => onChangeDDValue('statusTypeID', value)(setFieldValue, handleSubmit)}
                          options={statuses}
                          placeholder={searchByStatus}
                          onClearValue={() => clearFilterValue('statusTypeID')(setFieldValue, handleSubmit)}
                          customContainerClassname={filterClassnames}
                        />
                        <FilterDropdown
                          name="elevator"
                          value={filterOptions(values.elevator)(elevatorPresence)}
                          onChangeValue={({value}) => onChangeDDValue('elevator', value)(setFieldValue, handleSubmit)}
                          options={elevatorPresence}
                          placeholder={searchByElevator}
                          onClearValue={() => clearFilterValue('elevator')(setFieldValue, handleSubmit)}
                          customContainerClassname={filterClassnames}
                        />
                      </div>
                    ),
                  customStyles: styles.filtersItemContainer
                },
                {
                  itemContent:
                    (
                      <div className={styles.filtersItem}>
                        <FilterTextBox
                          name={'fromRoomCount'}
                          handleBlur={handleBlur}
                          onChangeValue={(e) => onChangeTextValue('fromRoomCount', e)(setFieldValue, handleSubmit)}
                          placeholder={searchByFromRoomCount}
                          value={values.fromRoomCount}
                          onClearValue={() => clearFilterValue('fromRoomCount')(setFieldValue, handleSubmit)}
                          customContainerClassName={filterClassnames}
                          {...getFixForTypingSpace()}
                        />
                        <FilterTextBox
                          name={'toRoomCount'}
                          handleBlur={handleBlur}
                          onChangeValue={(e) => onChangeTextValue('toRoomCount', e)(setFieldValue, handleSubmit)}
                          placeholder={searchByToRoomCount}
                          value={values.toRoomCount}
                          onClearValue={() => clearFilterValue('toRoomCount')(setFieldValue, handleSubmit)}
                          customContainerClassName={filterClassnames}
                          {...getFixForTypingSpace()}
                        />
                        <FilterTextBox
                          name={'fromRegisteredArea'}
                          handleBlur={handleBlur}
                          onChangeValue={(e) => onChangeTextValue('fromRegisteredArea', e)(setFieldValue, handleSubmit)}
                          placeholder={searchByFromRegisteredArea}
                          value={values.fromRegisteredArea}
                          onClearValue={() => clearFilterValue('fromRegisteredArea')(setFieldValue, handleSubmit)}
                          customContainerClassName={filterClassnames}
                          {...getFixForTypingSpace()}
                        />
                        <FilterTextBox
                          name={'toRegisteredArea'}
                          handleBlur={handleBlur}
                          onChangeValue={(e) => onChangeTextValue('toRegisteredArea', e)(setFieldValue, handleSubmit)}
                          placeholder={searchByToRegisteredArea}
                          value={values.toRegisteredArea}
                          onClearValue={() => clearFilterValue('toRegisteredArea')(setFieldValue, handleSubmit)}
                          customContainerClassName={filterClassnames}
                          {...getFixForTypingSpace()}
                        />
                        <FilterTextBox
                          name={'customerName'}
                          handleBlur={handleBlur}
                          onChangeValue={(e) => onChangeTextValue('customerName', e)(setFieldValue, handleSubmit)}
                          placeholder={searchByCustomer}
                          value={values.customerName}
                          onClearValue={() => clearFilterValue('customerName')(setFieldValue, handleSubmit)}
                          customContainerClassName={filterClassnames}
                          {...getFixForTypingSpace()}
                        />
                      </div>
                    ),
                  customStyles: styles.filtersItemContainer
                },
                {
                  itemContent:
                    (
                      <div className={styles.filtersItem}>
                        <div className={filterClassnames}>
                          <DatePicker
                            className="h-35px px-4"
                            placeholder={searchByFromDueDate}
                            value={fromShumaDueDate}
                            onChange={date => onChangeFromDueDate(date, setFieldValue, handleSubmit)}
                            maxDate={toShumaDueDate}
                          />
                          <ButtonClearFilter
                            onClick={() => clearFromDueDate(setFieldValue, handleSubmit)}
                            className={styles.clearIcon}
                          />
                        </div>
                        <div className={filterClassnames}>
                          <DatePicker
                            className="h-35px px-4"
                            placeholder={searchByToDueDate}
                            value={toShumaDueDate}
                            onChange={date => onChangeToDueDate(date, setFieldValue, handleSubmit)}
                            minDate={fromShumaDueDate}
                          />
                          <ButtonClearFilter
                            onClick={() => clearToDueDate(setFieldValue, handleSubmit)}
                            className={styles.clearIcon}
                          />
                        </div>
                      </div>
                    ),
                  customStyles: styles.filtersItemContainer
                },
              ]}
            />
            <FilterDropdown
              name="cityID"
              value={filterOptions(values.cityID)(cities)}
              onChangeValue={({value}) => onChangeCityID(values)(value)(setFieldValue, handleSubmit, handleCityChange)}
              options={cities}
              placeholder={searchByCity}
              onClearValue={() => clearCityID(setFieldValue, handleSubmit)}
              customContainerClassname={filterClassnames}
            />
            <FilterTextBox
              name={'gush'}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue('gush', e)(setFieldValue, handleSubmit)}
              placeholder={searchByGush}
              value={values.gush}
              onClearValue={() => clearFilterValue('gush')(setFieldValue, handleSubmit)}
              customContainerClassName={filterClassnames}
            />
            <FilterTextBox
              name={'helka'}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue('helka', e)(setFieldValue, handleSubmit)}
              placeholder={searchByHelka}
              value={values.helka}
              onClearValue={() => clearFilterValue('helka')(setFieldValue, handleSubmit)}
              customContainerClassName={filterClassnames}
            />
            <FilterTextBox
              name={'shumaID'}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue('shumaID', e)(setFieldValue, handleSubmit)}
              placeholder={searchByShuma}
              value={values.shumaID}
              onClearValue={() => clearFilterValue('shumaID')(setFieldValue, handleSubmit)}
              customContainerClassName={filterClassnames}
            />
            <FilterDropdown
              name="shumaTypeID"
              value={filterOptions(values.shumaTypeID)(shumaTypes)}
              onChangeValue={({value}) => onChangeDDValue('shumaTypeID', value)(setFieldValue, handleSubmit)}
              options={shumaTypes}
              placeholder={searchByShumaType}
              onClearValue={() => clearFilterValue('shumaTypeID')(setFieldValue, handleSubmit)}
              customContainerClassname={filterClassnames}
            />
            <FilterDropdown
              name="propertyTypeID"
              value={filterOptions(values.propertyTypeID)(propertyTypes)}
              onChangeValue={({value}) => onChangeDDValue('propertyTypeID', value)(setFieldValue, handleSubmit)}
              options={propertyTypes}
              placeholder={searchByPropertyType}
              onClearValue={() => clearFilterValue('propertyTypeID')(setFieldValue, handleSubmit)}
              customContainerClassname={filterClassnames}
            />
            <FilterDropdown
              name="responsibleUserID"
              value={filterOptions(values.responsibleUserID)(users)}
              onChangeValue={({value}) => onChangeDDValue('responsibleUserID', value)(setFieldValue, handleSubmit)}
              options={users}
              placeholder={searchByUser}
              onClearValue={() => clearFilterValue('responsibleUserID')(setFieldValue, handleSubmit)}
              customContainerClassname={filterClassnames}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default InspectionsPageFilters;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Section = ({
                   title,
                   children,
                   customClassNames = {},
                   containerRef,
                   ...rest
                 }) => {
  return (
    <div className={classnames(styles.listItem, customClassNames.container)} ref={containerRef} {...rest}>
      {Boolean(title) && (
        <div className={classnames(styles.itemTitle, customClassNames.title)}>
          {title}
        </div>
      )}
      <div
        className={classnames(styles.sectionContent, customClassNames.content)}>
        {children}
      </div>
    </div>
  );
};

export const SectionRef = React.forwardRef(({
                                              title,
                                              children,
                                              customClassNames = {},
                                              ...rest
                                            }, ref) => {
  return (
    <div className={classnames(styles.listItem, customClassNames.container)} ref={ref} {...rest}>
      {Boolean(title) && (
        <div className={classnames(styles.itemTitle, customClassNames.title)}>
          {title}
        </div>
      )}
      <div
        className={classnames(styles.sectionContent, customClassNames.content)}>
        {children}
      </div>
    </div>
  );
});

Section.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  customClassNames: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any})
  ]),
};

export default Section;

import React from 'react';
import PriceQuoteForm from "./PriceQuoteForm";
import {usePriceQuoteCreateUIGlobalContext} from "./PriceQuoteCreateUIContext";


const PriceQuoteCreate = () => {
  const {
    formik,
    closeModal,
    createPriceQuote,
    uploadingFile,
    uploadFile,
    handleDeleteFile
  } = usePriceQuoteCreateUIGlobalContext();

  return (
    <PriceQuoteForm
      id={-1}
      formik={formik}
      closeModal={closeModal}
      handleApprove={() => {
        createPriceQuote();
        closeModal();
      }}
      uploadingFile={uploadingFile}
      uploadFile={uploadFile}
      handleDeleteFile={handleDeleteFile}
    />
  );
};

export default PriceQuoteCreate;

import React from 'react';
import {ProtectedRoute} from '../../_common/components/Permissions';
import OrdersPage from '../pages';
import {pathnames as P} from '../../_common/constants/pathnames';
import {subjects as S} from '../../_common/constants/permissionSubjects';

export const ordersPageRoute = (
  <ProtectedRoute
    subject={S.ORDERS_PAGE}
    path={P.ORDERS_PAGE}
    component={OrdersPage}
  />
);

import React from 'react';
import {SectionRef} from '../../Section';
import TablePrices from '../../TablePrices';
import TableCalculations from '../../TableCalculations';
import styles from './styles.module.scss';

const Shuma1and2CalculationTables = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <div className={styles.page}>
        <SectionRef>
          <div className={styles.tableTitle}>
            להלן ריכוז נתוני שווי ותחשיב
          </div>
          <TablePrices
            customClassNames={{container: styles.tablePrices}}
          />
          <TableCalculations
            customClassNames={{container: styles.tableCalculations}}
          />
        </SectionRef>
      </div>
    </div>
  );
});

export default Shuma1and2CalculationTables;

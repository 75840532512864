import React from 'react';
import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {useHistory} from "react-router-dom";
import {can} from "../../../_common/components/Permissions";
import {permissionTypes as T, subjects as S} from "../../../_common/constants/permissionSubjects";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {pathnames as P} from "../../../_common/constants/pathnames";

const ButtonDetails = ({shumaID, firmShumaID}) => {
  const history = useHistory();
  const handleEditInspection = () => {
    history.push(P.NEW_INSPECTION_DETAILS_LINK(shumaID), {firmShumaID})
  };

  return (
    <Button
      className={'w-100 bg-white border-0 h-40px d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg'}
      onClick={() => handleEditInspection()}
      disabled={!can(T.FULL, S.INSPECTIONS_PAGE)}
    >
      <img
        src={toAbsoluteUrl('media/common/Edit.svg')}
        alt={'edit-inspection'}
        className={'w-20px mr-4'}
      />
      <FormattedMessage
        id="inspections-column-details"
        defaultMessage="Details"
      />
    </Button>
  );
};

export default ButtonDetails;
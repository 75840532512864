import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import styles from './styles.module.scss';

export const ShumaType3HouseMatchingBuilding = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        houseMatchingBuilding,
        houseMatchingBuildingText
      },
    },
  } = useCompleteInspectionContext();

  let houseMatchingBuildingValue = getSelectedOptionLabel(houseMatchingBuilding, options.houseMatchingBuildingOptions);
  if ((houseMatchingBuilding === 2) && Boolean(houseMatchingBuildingText)) {
    houseMatchingBuildingValue = `${houseMatchingBuildingValue}, ${houseMatchingBuildingText}`;
  }

  return (
    <div ref={ref} className={styles.innerPadding}>
      {Boolean(houseMatchingBuilding) && (
        <div className={styles.itemText}>
      <span className={styles.bold}>
        האם הדירה חורגת/תואמת את הבנוי בבניין:
      </span>
          <span>
        {` ${houseMatchingBuildingValue}.`}
      </span>
        </div>
      )}
    </div>
  );
});

export default ShumaType3HouseMatchingBuilding;

export const excelHeaderCellsRange = [
  'A3',
  'B3',
  'C3',
  'D3',
  'E3',
  'F3',
  'G3',
  'H3',
  'I3',
  'J3',
  'K3',
  'L3',
  'M3',
  'N3',
  'O3',
  'P3',
  'Q3',
  'R3',
  'S3',
  'T3',
  'U3',
  'V3',
  'W3',
  'X3',
  'Y3',
  'Z3',
  'AA3',
  'AB3',
  'AC3',
  'AD3',
  'AE3',
  'AF3',
  'AG3',
  'AH3',
  'AI3',
  'AJ3',
  'AK3',
  'AL3',
  'AM3',
  'AN3',
  'AO3',
  'AP3',
  'AQ3',
  'AR3',
  'AS3',
  'AT3',
  'AU3',
  'AV3',
  'AW3',
  'AX3',
  'AY3',
  'AZ3',
];

import axios from "axios";
import {actions} from "../reducers/PriceQuotesPageTableReducer";
import {downloadFile} from "../../../../../utils/downloadFile";

export const GET_PRICE_QUOTE_DETAILS = "/priceQuotes/getPriceQuoteDetails";
export const ADD_EDIT_PRICE_QUOTE = "/priceQuotes/addEditPriceQuote";
export const UPLOAD_FILE_PRICE_QUOTE = "/priceQuotes/uploadFile";
export const DELETE_FILE_PRICE_QUOTE = "/priceQuotes/deleteFile";
export const DOWNLOAD_FILE_PRICE_QUOTE = "/priceQuotes/getFile";

export const getPriceQuoteDetails = priceQuoteID => {
  const data = {
    priceQuoteID: parseInt(priceQuoteID, 10),
  };

  return axios.post(GET_PRICE_QUOTE_DETAILS, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
    });
};

export const addEditPriceQuote = ({priceQuoteID, status, price, customers, createMode}) => (dispatch) => {
  const data = {
    priceQuoteID: parseInt(priceQuoteID, 10),
    status: parseInt(status.value, 10),
    price: parseInt(price, 10),
    customers: customers.map(item => item.customerID)
  };

  return axios.post(ADD_EDIT_PRICE_QUOTE, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {priceQuoteID} = body;
      const data = {
        priceQuoteID,
        status: status.label,
        price,
        customers
      };

      if (createMode) {
        dispatch(actions.addPriceQuote(data));
      } else {
        dispatch(actions.editPriceQuote(data));
      }

      return priceQuoteID
    })
    .catch(error => {
      error.clientMessage = "Can't create/edit document";
    });
};

export const uploadPriceQuoteFile = (priceQuoteID, fileToSave, fileName, payload = {}) => {

  return (dispatch) => {
    const formData = new FormData();
    formData.append('fileToSave', fileToSave);
    formData.append('priceQuoteID', priceQuoteID);
    formData.append('fileName', fileName);
    Object.keys(payload).forEach(key => {
      formData.append(`${key}`, payload[key]);
    });

    return axios.post(UPLOAD_FILE_PRICE_QUOTE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        dispatch(actions.editPriceQuoteFileName({
          priceQuoteID,
          fileName
        }));
      })
      .catch((err) => {
        console.error(`uploadFile returns error: ${err}`);
      });
  };
};

export const deletePriceQuoteFile = (priceQuoteID, fileName) => dispatch => {
  return axios.post(DELETE_FILE_PRICE_QUOTE, {priceQuoteID, fileName})
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.editPriceQuoteFileName({
        priceQuoteID,
        fileName: null
      }));

    })
    .catch(error => {
      error.clientMessage = 'Can\'t delete file';
    });
};

export const downloadPriceQuoteFile = (priceQuoteID, fileName) => dispatch => {
  return axios.post(DOWNLOAD_FILE_PRICE_QUOTE, {priceQuoteID, fileName})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {file} = body;

      try {
        downloadFile(`data:;base64,${file}`, fileName);
      } catch (exc) {
        console.log("Save Blob method failed with the following exception.");
        console.log(exc);
      }

      return file
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find file';
    });
};



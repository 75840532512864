import {permissionsByOrder} from '../constants/permissions';

export const extendPermissionDownward = basicPermission => {
  const basicPermissionIndex = permissionsByOrder.indexOf(basicPermission);
  if (basicPermissionIndex < 0) {
    throw new Error('UNKNOWN PERMISSION TYPE');
  }

  return permissionsByOrder.slice(basicPermissionIndex, permissionsByOrder.length)
};

import React from 'react';
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Button} from "react-bootstrap";
import {tooltips as TTS} from '../constants/tooltips';
import {can} from '../components/Permissions';
import {subjects as S, permissionTypes as T} from '../constants/permissionSubjects';
import {pathnames as P} from '../constants/pathnames';

export function EditRowColumnFormatter(cellContent, row, {history}) {
  const handleClick = () => history.push(P.OLD_INSPECTION_DETAILS_LINK(row.shumaID), [row.shumaOriginTypeID]);

  return (
    <Button
      className="btn btn-icon btn-warning border-1"
      title={TTS.EDIT_ROW}
      onClick={handleClick}
      disabled={!can(T.FULL, S.OLD_INSPECTIONS_PAGE)}
    >
      <img
        src={toAbsoluteUrl('media/common/Edit.svg')}
        alt="edit-row"
      />
    </Button>
  );
}

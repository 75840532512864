import React from 'react';
import {SectionRef} from '../../Section';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import styles from './styles.module.scss';

const ShumaType1Section1 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    pdfData: {
      usingLawyer,
      lawyerName,
    },
    pdfDataCustomers
  } = context.data;

  const customersNames = (pdfDataCustomers.length > 0)
    ? pdfDataCustomers.map(customer => customer.name).join(' ו - ')
    : '';
  const lawyerNameValue = (usingLawyer === 1)
    ? `באמצעות עו"ד ${lawyerName}`
    : '';
  const title = (
    <>
      <span>{context.sectionNumbers.section1}</span>
      <span>. מטרת חוות הדעת</span>
    </>
  );

  return (
    <SectionRef title={title} ref={ref}>
      <div className={styles.itemText}>
        <span>נתבקשתי ע"י ה"ה</span>
        {` ${customersNames} ${lawyerNameValue} `}
        <span>
          לערוך חוו"ד לנכס המקרקעין שבנדון (להלן: "הנכס") לעניין אומדן שווי שוק. שווי שוק יוגדר בחוו"ד זו כתמורה הסבירה העשויה להתקבל בעסקת מכר שבין מוכר מרצון לקונה מרצון, בתנאי שוק חופשי כאשר הנכס ריק פנוי וחופשי מכל זכויות צד ג' כלשהן.
        </span>
      </div>
    </SectionRef>
  );
});

export default ShumaType1Section1;

import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import {useCustomersEntityUIContext} from '../CustomersEntityUIContext';
import FilterTextBox from '../../../_common/components/FilterTextBox';
import FilterDropdown from '../../../_common/components/FilterDropdown';
import PopupAddCustomer from '../PopupAddCustomer/PopupAddCustomer';
import FiltersLabel from '../../../_common/components/FiltersLabel';
import {customerStatus, customerTypes} from '../../../_common/constants/options';
import {filterOptions} from '../../../../../utils/filterOptions';
import {clearFilterValue, onChangeDDValue, onChangeTextValue} from '../../../../../utils/changeFilterValues';
import {applyFilter} from '../../../../../utils/prepareApplyFilter';
import styles from './styles.module.scss';
import {InputCheckbox} from "../../../_common/components/FieldCheckbox";

export function CustomersEntityFilters() {
  const customersEntityUIContext = useCustomersEntityUIContext();
  const customersEntityUIProps = useMemo(() => {
    return {
      queryParams: customersEntityUIContext.queryParams,
      setQueryParams: customersEntityUIContext.setQueryParams,
    };
  }, [customersEntityUIContext]);

  const intl = useIntl();
  const searchByCustomerName = intl.formatMessage({
    id: 'customers-entity-filter-customer-name',
    defaultMessage: 'Name',
  });
  const searchByCustomerType = intl.formatMessage({
    id: 'customers-entity-filter-customer-type',
    defaultMessage: 'Type',
  });
  const searchByStatus = intl.formatMessage({
    id: 'customers-entity-popup-edit-customer-status',
    defaultMessage: 'Status',
  });
  const searchByWithDeleted = intl.formatMessage({
    id: 'customers-entity-filter-deleted',
    defaultMessage: 'With deleted customers',
  });

  const qs = new URLSearchParams(window.location.search);
  let customerName = qs.get('customerName') || '';
  let customerType = qs.get('customerType') || '';
  let status = qs.get('status') || '';
  let deleted = qs.get('deleted') || '';

  return (
    <Formik
      initialValues={{
        customerName,
        customerType,
        status,
        deleted,
      }}
      onSubmit={(values) => {
        applyFilter(values, customersEntityUIProps);
      }}
      enableReinitialize={true}
    >
      {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
        <form onSubmit={handleSubmit} className={styles.formFilters}>
          <div className="form-group row d-flex justify-content-between align-items-center mb-0 ml-8">
            <FiltersLabel/>
            <FilterTextBox
              name={'customerName'}
              handleBlur={handleBlur}
              onChangeValue={(e) => onChangeTextValue('customerName', e)(setFieldValue, handleSubmit)}
              placeholder={searchByCustomerName}
              value={values.customerName}
              onClearValue={() => clearFilterValue('customerName')(setFieldValue, handleSubmit)}
              customContainerClassName={'col-md-2 position-relative'}
            />
            <FilterDropdown
              name="customerType"
              value={filterOptions(values.customerType)(customerTypes)}
              onChangeValue={({value}) => onChangeDDValue('customerType', value)(setFieldValue, handleSubmit)}
              options={customerTypes}
              placeholder={searchByCustomerType}
              onClearValue={() => clearFilterValue('customerType')(setFieldValue, handleSubmit)}
              customContainerClassname={'col-md-2 position-relative'}
            />
            <FilterDropdown
              name="status"
              value={filterOptions(values.status)(customerStatus)}
              onChangeValue={({value}) => onChangeDDValue('status', value)(setFieldValue, handleSubmit)}
              options={customerStatus}
              placeholder={searchByStatus}
              onClearValue={() => clearFilterValue('status')(setFieldValue, handleSubmit)}
              customContainerClassname={'col-md-2 position-relative'}
            />
            <InputCheckbox
              fieldId={'deleted'}
              value={values.deleted}
              label={searchByWithDeleted}
              onChange={() => {
                setFieldValue('deleted', !values.deleted);
                handleSubmit();
              }}
            />
            <PopupAddCustomer
              customButtonClassNames={'col-lg mr-40 d-flex justify-content-end position-relative'}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CustomersEntityFilters;

export const optionsGenders = [
  {label: 'זכר', value: 1},
  {label: 'נקבה', value: 2},
];
export const optionsCustomerTypes = [
  {label: 'חברה', value: 1},
  {label: 'עירייה', value: 2},
  {label: 'מוסד', value: 3},
  {label: 'פרטי', value: 4},
  {label: 'קבוצה', value: 5},
];
export const optionsCustomerStatus = [
  {label: 'פעיל', value: 1},
  {label: 'מוקפא', value: 2},
];
export const optionsDiscountType = [
  {label: '%', value: 'percentage'},
  {label: 'סכום', value: 'sum'}
];
export const optionsVatType = [
  {label: 'לפני מע"מ', value: 0},
  {label: 'כולל מע"מ', value: 1}
];
export const optionsCardType = [
  {label: 'לא ידוע', value: 1},
  {label: 'ישראכרט', value: 2},
  {label: 'ויזה', value: 3},
  {label: 'מאסטרקארד', value: 4},
  {label: 'אמריקן אקספרט', value: 5},
  {label: 'דיינרס', value: 6},
];
export const optionsDealType = [
  {label: 'גיל', value: 1},
  {label: 'תשלומים', value: 2},
  {label: 'קרדיט', value: 3},
  {label: 'חיוב נדחה', value: 4},
  {label: 'אחר', value: 5},
];
export const optionsAppType = [
  {label: 'bit', value: 1},
  {label: 'Colu', value: 2},
  {label: 'PayBox', value: 3},
  {label: 'Pay', value: 4},
];
export const optionsSubType = [
  {label: 'ביטקוין', value: 1},
  {label: 'אתריום', value: 2},
  {label: 'שווה כסף', value: 3},
  {label: 'V-CHECK', value: 4},
  {label: 'שובר מתנה', value: 5},
  {label: 'ניכוי חלק עובד ביטוח לאומי', value: 6},
];
export const optionsTaskStatus = [
  {label: 'פתוחה', value: 1},
  {label: 'סגורה', value: 2},
];










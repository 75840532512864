import {PURGE} from 'redux-persist';
import {actions as A} from '../../../constants/actions';

const defaultState = {
  showGlobalMessage: false,
  message: '',
};

export default (state = defaultState, {type, payload}) => {
  switch(type) {
    case A.SHOW_GLOBAL_MESSAGE:
      const {message} = payload;
      return {...state, showGlobalMessage: true, message};

    case A.HIDE_GLOBAL_MESSAGE:
    case PURGE:
      return {...state, showGlobalMessage: false, message: ''};

    default:
      return state;
  }
}

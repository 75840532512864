import React from 'react';
import styles from './styles.module.scss';

const SubheaderBar = ({children}) => {

  return (
    <div className={styles.subheaderBarContainer}>
      {children}
    </div>
  );
};

export default SubheaderBar;

import React from 'react';
import {useIntl} from "react-intl";
import {shallowEqual, useSelector} from 'react-redux';
import {usePopupEditTasksContext} from "./PopupEditTasksContext";
import {StyledFieldInput} from "../../../_common/components/FieldInput";
import {StyledFieldDatepicker}  from "../../../_common/components/FieldDatepicker";
import {StyledFieldSelect} from "../../../_common/components/FieldSelect";
import {filterOptions} from "../../../_common/utils/options";
import {integerValueCanStartFromNull} from "../../../_common/utils/changeInputValue";
import {taskStatuses} from "../../../_common/constants/options";

const TaskVisit = ({header}) => {
  const intl = useIntl();
  const labelResponsibleUser = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-responsibleUser',
    defaultMessage: 'Responsible user',
  });
  const labelStatus = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-status',
    defaultMessage: 'Status',
  });
  const labelDueDate = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-dueDate',
    defaultMessage: 'Due date',
  });
  const labelNotes = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-notes',
    defaultMessage: 'Notes',
  });
  const labelContactName = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-contactName',
    defaultMessage: 'Contact name',
  });
  const labelContactPhone = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-contactPhone',
    defaultMessage: 'Contact phone',
  });

  const {formik} = usePopupEditTasksContext();
  const {values, setFieldValue} = formik;
  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );

  const getFieldLabel = (text, mandatory = true) => {
    return (
      <div className={'font-weight-bolder pl-6'}>
        {mandatory && <span className={'text-danger'}>*</span>}
        <span>{text}</span>
      </div>
    )
  };

  return (
    <>
      <h2 className="font-weight-normal text-dark w-100 d-flex justify-content-center mb-6">
        {header}
      </h2>
      <div className={'py-4'}>
        <StyledFieldSelect
          fieldId={'responsibleUserID'}
          onChange={(option) => setFieldValue('responsibleUserID', option.value)}
          value={filterOptions(values.responsibleUserID)(users)}
          options={users}
          label={getFieldLabel(labelResponsibleUser)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldSelect
          fieldId={'taskStatusID'}
          onChange={(option) => setFieldValue('taskStatusID', option.value)}
          value={filterOptions(values.taskStatusID)(taskStatuses)}
          options={taskStatuses}
          label={getFieldLabel(labelStatus)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldDatepicker
          value={values.taskDueDate}
          onChange={(date) => {
            setFieldValue('taskDueDate', new Date(date));
          }}
          label={getFieldLabel(labelDueDate)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldInput
          fieldId={'notes'}
          value={values.notes}
          onChange={(e) => setFieldValue('notes', e.target.value)}
          label={getFieldLabel(labelNotes, false)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldInput
          fieldId={'visitContactName'}
          value={values.visitContactName}
          onChange={(e) => setFieldValue('visitContactName', e.target.value)}
          label={getFieldLabel(labelContactName, false)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldInput
          fieldId={'visitContactPhone'}
          value={values.visitContactPhone}
          onChange={(e) => {
            setFieldValue('visitContactPhone', integerValueCanStartFromNull(e))
          }}
          label={getFieldLabel(labelContactPhone, false)}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
}

export default TaskVisit;

import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useTable} from 'react-table';
import useInfiniteScrollControl
  from '../../../_common/components/_custom-hooks/useInfiniteScrollControl';
import * as actions from '../../actions/CustomerDetailsActions';
import * as columnFormatters from '../../../_common/column-formatters';
import {useCustomerDetailsUIContext} from '../CustomerDetailsUIContext';
import CommonGrid from "../../../_common/components/CommonGrid";

const TabInspectionsByCustomer = () => {
  const {customerID} = useCustomerDetailsUIContext();
  const intl = useIntl();
  const [tableRows, setTableRows] = useState([]);
  const columns = useMemo(() => [
    {
      accessor: 'firmShumaID',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-firmShumaID', defaultMessage: '#'
      })
    },
    {
      accessor: 'fullName',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-fullName', defaultMessage: 'Full name'
      })
    },
    {
      accessor: 'address',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-address', defaultMessage: 'Address'
      })
    },
    {
      accessor: 'gushAndHelka',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-gushAndHelka', defaultMessage: 'Gush and helka'
      })
    },
    {
      accessor: 'officialDate',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-officialDate', defaultMessage: 'Official date'
      })
    },
    {
      accessor: 'shumaStatusTypeName',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-shumaStatusTypeName', defaultMessage: 'Shuma status'
      })
    },
    {
      accessor: 'shumaReasonName',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-shumaReasonName', defaultMessage: 'Reason'
      })
    },
    {
      accessor: 'shumaTypeName',
      Header: intl.formatMessage({
        id: 'customers-inspections-column-shumaTypeName', defaultMessage: 'Shuma type'
      })
    },
  ], []);
  const data = useMemo(() => [
    ...tableRows.map((item) => {
      return {
        firmShumaID: columnFormatters.BoldColumnFormatter(item.firmShumaID),
        userID: columnFormatters.BoldColumnFormatter(item.userID),
        fullName: columnFormatters.BoldColumnFormatter(item.fullName),
        address: columnFormatters.BoldColumnFormatter(item.address),
        gushAndHelka: columnFormatters.BoldColumnFormatter(item.gushAndHelka),
        officialDate: columnFormatters.DateColumnFormatter(item.officialDate),
        estimatePriceFinal: columnFormatters.BoldColumnFormatter(item.estimatePriceFinal),
        shumaStatusTypeName: columnFormatters.BoldColumnFormatter(item.shumaStatusTypeName),
        originTypeName: columnFormatters.BoldColumnFormatter(item.originTypeName),
        shumaReasonName: columnFormatters.BoldColumnFormatter(item.shumaReasonName),
        shumaTypeName: columnFormatters.BoldColumnFormatter(item.shumaTypeName),
      };
    })
  ], [tableRows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const infiniteScrollProps = useInfiniteScrollControl({
    currentStateStoreId: 'customerDetailsReducer',
    UIContext: useCustomerDetailsUIContext(),
    getNewEntities: actions.getInspections(customerID),
    getNextEntities: actions.getNextInspections(customerID),
    onEntitiesChange: entities => {
      entities
        ? setTableRows(entities)
        : setTableRows([])
    },
    startRequestedPageNumber: 0,
    dataLength: tableRows.length,
    scrollableTarget: 'scrollContainer',
    scrollThreshold: 0.9,
    loader: (
      <div className={'h-50px d-flex justify-content-center'}>
        <span className='spinner spinner-md spinner-warning'/>
      </div>
    ),
    style: {
      overflow: 'inherit'
    },
  });

  const gridProps = {
    infiniteScrollProps,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows
  }

  return (
    <CommonGrid {...gridProps} customScrollContainerStyles={'w-100'}/>
  );
};

TabInspectionsByCustomer.propTypes = {

};

export default TabInspectionsByCustomer;

export const filterOptions = (specifiedValue) => (options) => {
  if (options) {
    return options.filter(({value}) => value === specifiedValue);
  }
};

export const filterOptionsByLabel = options => label => {
  if (options) {
    return options.filter(option => option.label === label);
  } else {
    return [];
  }
};

export const filterOptionsForFilters = (values) => (options) => {
  return options.filter(({value}) => value == values)
};

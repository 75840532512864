import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ProgressBar = ({progress}) => {
    return (
        <div className={styles.progressContainer}>
            <div
                className={styles.progressCondition}
                style={{width: `${progress}%`}}
            />
        </div>
    )
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;

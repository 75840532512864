export const onChangeTextValue = (fieldID, e) => (setFieldValue, handleSubmit) => {
  setFieldValue(fieldID, e.target.value);
  handleSubmit();
};
export const onChangeDDValue = (fieldID, value) => (setFieldValue, handleSubmit) => {
  setFieldValue(fieldID, value);
  handleSubmit();
};
export const clearFilterValue = (fieldID, value = '') => (setFieldValue, handleSubmit) => {
  setFieldValue(fieldID, value);
  handleSubmit();
};

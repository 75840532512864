import React from 'react';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import {houseSecureRoomOptions,} from '../../../constants/options';
import styles from './styles.module.scss';

const Section6Text712 = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {houseSecureRoom} = context.data.pdfData;
  const houseSecureRoomValue = getSelectedOptionLabel(houseSecureRoom, houseSecureRoomOptions);

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>ממ"ד: </span>
      <span data-testid="houseSecureRoomValue">
        {houseSecureRoomValue}
      </span>.
    </div>
  );
});

export default Section6Text712;

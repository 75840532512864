import React from 'react';
import classnames from 'classnames';
import GreenBox from '../../GreenBox';
import {getGushHelkaTTHelkaRTLString} from '../../../utils/texts';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel, getSelectedRightsType} from '../../../utils/options';
import {assessorGenderOptions} from '../../../constants/options';
import styles from './styles.module.scss';

const ShumaType1DocTitle = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const {
    pdfData: {
      streetName,
      houseNumber,
      cityName,
      gush, helka, ttHelka,
      rightsType,
      rightsOwnerName,
    },
    userData: {
      userFirstName,
      userLastName,
      gender,
    }
  } = context.data;

  const rightsTypeValue = getSelectedRightsType(rightsType);
  const genderText = getSelectedOptionLabel(gender, assessorGenderOptions);

  return (
    <div ref={ref}>
      <div className={classnames(styles.page, styles.paddingAfterLogo)}>
        <GreenBox
          textClassName={styles.bigPaddings}
          containerClassName={classnames(styles.marginAfterGreenBox)}
        >
          <div className={styles.titleFirstPage}>
            חוות דעת במקרקעין
          </div>
          <div className={styles.blockLine}>
            הנדון: דירת מגורים ברח' {streetName} {houseNumber}, {cityName || ''}
          </div>
          <div className={styles.blockLine}>
            גו"ח {getGushHelkaTTHelkaRTLString({gush, helka, ttHelka})}
          </div>
          <div className={styles.blockLine}>
            זכויות {rightsTypeValue} רשומות ע"ש {rightsOwnerName}
          </div>
        </GreenBox>

        <div className={styles.nameAndGenderContainer}>
          <div className={styles.nameAndGender}>
            מאת: {`${userFirstName} ${userLastName}`} ,{genderText} מקרקעין
          </div>
        </div>
      </div>
    </div>
  );
});

export default ShumaType1DocTitle;

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import classnames from 'classnames';
import styles from '../styles.module.scss';

const ShumaType2and3BuildingRoofTypeText = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingRoofTypeValue = getSelectedOptionLabel(context.data.pdfData.buildingRoofType, options.buildingRoofTypeOptions);

  return (
    <div className={classnames(styles.innerPadding, styles.itemText)} ref={ref}>
      <span className={styles.bold}>סוג גג: </span>
      {` ${buildingRoofTypeValue}.`}
    </div>
  );
});

export default ShumaType2and3BuildingRoofTypeText;

import axios from 'axios';
import {actions} from '../reducers/CustomersEntityTableReduser';

export const CUSTOMERS_LIST = 'customers/getCustomers';
export const GET_CUSTOMER_DATA = 'customers/getCustomerData';
export const ADD_EDIT_CUSTOMER = '/customers/addEditCustomer';
export const DELETE_CUSTOMER = 'customers/deleteCustomer';
export const RESTORE_CUSTOMER = 'customers/undeleteCustomer';

export const getCustomersList = action => ({
                                             customerName = '',
                                             customerType = -1,
                                             status = -1,
                                             deleted = 0,
                                             requestedPageNumber = 0,
                                           }) => dispatch => {

  const data = {
    customerName,
    customerType: parseInt(customerType, 10),
    status: parseInt(status, 10),
    deleted: deleted ? 1 : 0,
    requestedPageNumber: parseInt(requestedPageNumber, 10),
  };

  return axios.post(CUSTOMERS_LIST, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {
        totalPageCount,
        currentPageNumber,
        customers,
        totalResultCount
      } = body;

      dispatch(action({
        totalPageCount,
        currentPageNumber,
        customers,
        totalResultCount,
      }));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
      dispatch(actions.catchError(error));
    });
};

export const addCustomer = ({
                              customerID,
                              firstName,
                              lastName,
                              phone,
                              email,
                              address,
                              customerType,
                              idNumber,
                              genderID,
                              status
                            }, forbidGridChanges) => (dispatch) => {

  const data = {
    customerID: parseInt(customerID, 10),
    firstName,
    lastName,
    phone,
    email,
    address,
    customerType: parseInt(customerType, 10),
    idNumber,
    genderID: parseInt(genderID, 10),
    status: parseInt(status, 10),
  };

  return axios.post(ADD_EDIT_CUSTOMER, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      const {
        customerID,
        firstName,
        lastName,
        phone,
        email,
        customerTypeID,
        customerTypeName,
        idNumber,
        genderID,
        genderName,
        address,
        customerStatus,
      } = body.customerData;

      if (!forbidGridChanges) {
        dispatch(actions.addCustomer({
          customerID,
          firstName,
          lastName,
          phone,
          email,
          customerTypeID,
          customerTypeName,
          idNumber,
          genderID,
          genderName,
          address,
          customerStatus,
        }));
      }

      return customerID;
    });
};

export const getCustomerData = (customerID) => dispatch => {

  return axios.post(GET_CUSTOMER_DATA, customerID)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {customerData} = body;

      return customerData;
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find data';
    });
};

export const deleteCustomer = (customerID) => dispatch => {

  return axios.post(DELETE_CUSTOMER, {customerID})
    .then(response => {
      const {errorCode} = response.data;

      if (errorCode === 0) {
        dispatch(actions.deleteCustomer({customerID}))
      } else {
        throw new Error();
      }

      return errorCode;
    })
    .catch(error => {
      error.clientMessage = 'Can\'t delete customer';
    });
};

export const restoreCustomer = (customerID) => dispatch => {

  return axios.post(RESTORE_CUSTOMER, {customerID})
    .then(response => {
      const {errorCode} = response.data;

      if (errorCode === 0) {
        dispatch(actions.restoreCustomer({customerID}))
      } else {
        throw new Error();
      }

      return errorCode;
    })
    .catch(error => {
      error.clientMessage = 'Can\'t restore customer';
    });
};

export const getCustomers = getCustomersList(actions.customersFetched);
export const getNextCustomers = getCustomersList(actions.customersAppended);

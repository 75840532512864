import React from 'react';
import PropTypes from 'prop-types';
import mime from 'mime-types';
import pdf from '../../../assets/pdf.svg';
import jpg from '../../../assets/jpg.svg';
import gif from '../../../assets/gif.svg';
import png from '../../../assets/png.svg';
import defaultIcon from '../../../assets/defFile.svg';
import styles from './styles.module.scss';

const FileIcon = ({fileExtension}) => {
  const getIcon = () => {
    switch (mime.lookup(fileExtension)) {
      case 'application/pdf':
        return pdf;
      case 'image/gif':
        return gif;
      case 'image/jpeg':
      case 'image/jpg':
        return jpg;
      case 'image/tiff':
      case 'image/png':
        return png;
      default:
        return defaultIcon;
    }
  };

  return (
    <div className={styles.imageContainer}>
      <img src={getIcon()} alt="icon" className={styles.image}/>
    </div>
  );
};

FileIcon.propTypes = {
  fileExtension: PropTypes.string,
};

export default FileIcon;

import React from "react";
import {FormattedMessage} from "react-intl";
import price from "../assets/price.svg";
import order from "../assets/order.svg";
import transactionAccount from "../assets/transactionAccount.svg";
import invoice from "../assets/invoice.svg";
import receipt from "../assets/receipt.svg";
import createDocFrom from "../assets/create-doc-from.svg";
import defaultDocument from "../assets/defaultDocument.svg";
import {documentTypes as T} from '../constants/documentTypes';
import PopupCreateDocument from "../components/PopupCreateDocument";

const customDropdownItemStyles = 'w-100 p-0';

export const getDocumentTypeData = (type, id) => {
  switch (type) {
    case T.PRICE_QUOTE:
      return {
        icon: <img className='h-40px' src={price} alt="icon"/>,
        badgeColor: 'success',
        backgroundColor: 'bg-success',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-10"
            defaultMessage="Price"
          />,
        createFromDocumentItemsList: [T.WORK_ORDER, T.INVOICE, T.INVOICE_RECEIPT].map(item => {
          return {
            itemContent: (
              <div className='dropdown-item d-flex align-items-center py-0 px-5'>
                <img
                  src={createDocFrom}
                  alt="download"
                  className='w-20px h-20px'
                />
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id={`customer-details-documentInfo-label-button-create-${item}`}
                      defaultMessage="Create document"
                    />
                  }
                  documentType={item}
                  documentId={id}
                  withCopiedData={true}
                />
              </div>
            ),
            customStyles: customDropdownItemStyles,
          }
        })
      };
    case T.WORK_ORDER:
      return {
        icon: <img className='h-40px' src={order} alt="icon"/>,
        badgeColor: 'primary',
        backgroundColor: 'bg-primary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-100"
            defaultMessage="Work order"
          />,
        createFromDocumentItemsList: [T.INVOICE, T.INVOICE_RECEIPT].map(item => {
          return {
            itemContent: (
              <div className='dropdown-item d-flex align-items-center py-0 px-5'>
                <img
                  src={createDocFrom}
                  alt="download"
                  className='w-20px h-20px'
                />
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id={`customer-details-documentInfo-label-button-create-${item}`}
                      defaultMessage="Create document"
                    />
                  }
                  documentType={item}
                  documentId={id}
                  withCopiedData={true}
                />
              </div>
            ),
            customStyles: customDropdownItemStyles,
          }
        })
      };
    case T.RETURN_CERTIFICATE:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-210"
            defaultMessage="Return certificate"
          />,
        createFromDocumentItemsList: []
      };
    case T.TRANSACTION_ACCOUNT:
      return {
        icon: <img className='h-40px' src={transactionAccount} alt="icon"/>,
        badgeColor: 'info',
        backgroundColor: 'bg-info',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-300"
            defaultMessage="Transaction account"
          />,
        createFromDocumentItemsList: []
      };
    case T.INVOICE:
      return {
        icon: <img className='h-40px' src={invoice} alt="icon"/>,
        badgeColor: 'warning',
        backgroundColor: 'bg-warning',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-305"
            defaultMessage="Invoice"
          />,
        createFromDocumentItemsList: [T.RECEIPT].map(item => {
          return {
            itemContent: (
              <div className='dropdown-item d-flex align-items-center py-0 px-5'>
                <img
                  src={createDocFrom}
                  alt="download"
                  className='w-20px h-20px'
                />
                <PopupCreateDocument
                  label={
                    <FormattedMessage
                      id={`customer-details-documentInfo-label-button-create-${item}`}
                      defaultMessage="Create document"
                    />
                  }
                  documentType={item}
                  documentId={id}
                  withCopiedData={true}
                />
              </div>
            ),
            customStyles: customDropdownItemStyles,
          }
        })
      };
    case T.INVOICE_RECEIPT:
      return {
        icon: <div className='h-40px'><i className="fas fa-file-invoice-dollar text-warning display-4"/></div>,
        badgeColor: 'dark',
        backgroundColor: 'bg-dark',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-320"
            defaultMessage="Tax"
          />,
        createFromDocumentItemsList: []
      };
    case T.CREDIT_INVOICE:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-330"
            defaultMessage="Credit invoice"
          />,
        createFromDocumentItemsList: []
      };
    case T.RECEIPT:
      return {
        icon: <img className='h-40px' src={receipt} alt="icon"/>,
        badgeColor: 'danger',
        backgroundColor: 'bg-danger',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-400"
            defaultMessage="Receipt"
          />,
        createFromDocumentItemsList: []
      };
    case T.DONATION_RECEIPT:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-405"
            defaultMessage="Donation receipt"
          />,
        createFromDocumentItemsList: []
      };
    case T.PURCHASE_ORDER:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-500"
            defaultMessage="Purchase order"
          />,
        createFromDocumentItemsList: []
      };
    case T.RECEIVING_DEPOSIT:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-600"
            defaultMessage="Receiving a deposit"
          />,
        createFromDocumentItemsList: []
      };
    case T.WITHDRAWAL_DEPOSIT:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName:
          <FormattedMessage
            id="customer-details-documentInfo-value-type-610"
            defaultMessage="Deposit withdrawal"
          />,
        createFromDocumentItemsList: []
      };
    default:
      return {
        icon: <img className='h-40px' src={defaultDocument} alt="icon"/>,
        badgeColor: 'secondary',
        backgroundColor: 'bg-secondary',
        typeName: '',
        createFromDocumentItemsList: []
      };
  }
}

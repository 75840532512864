import {Ability, AbilityBuilder} from '@casl/ability';
import {extendPermissionDownward} from '../../utils/permissions';

const ability = new Ability();

/** for some reason when I use usual imports can.js loaded before store.js,
 *  so I use dynamic import to wait until store will be created */
import('../../../../../redux/store').then(({default: store}) => {
  store.subscribe(() => {
    const {pages} = store.getState().auth;
    ability.update(defineRulesFor(pages));
  });
})

const defineRulesFor = (pages) => {
  const {can, rules} = new AbilityBuilder();
  if (Boolean(pages)) {
    pages.forEach(page => {
      const {pageName, permissionLevel} = page;
      can(extendPermissionDownward(permissionLevel), pageName);
    })
  }

  return rules;
};

export default (action, subject) => {
  return ability.can(action, subject);
};

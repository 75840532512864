import {actions} from "../reducers/PriceQuotesPageTableReducer";
import axios from "axios";


export const GET_PRICE_QUOTES_LIST = "/priceQuotes/getList";
export const DELETE_PRICE_QUOTE = "/priceQuotes/deletePriceQuote";

export const getPriceQuotesList = action => ({
                                           priceQuoteID = -1,
                                           fromCreateDate = "",
                                           toCreateDate = "",
                                           status = -1,
                                           fromPrice = -1,
                                           toPrice = -1,
                                           requestedPageNumber = 0,
                                         }) => dispatch => {

  const data = {
    priceQuoteID: parseInt(priceQuoteID, 10),
    fromCreateDate,
    toCreateDate,
    status: parseInt(status, 10),
    fromPrice: parseInt(fromPrice, 10),
    toPrice: parseInt(toPrice, 10),
    requestedPageNumber: parseInt(requestedPageNumber, 10),
  };

  return axios.post(GET_PRICE_QUOTES_LIST, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {totalPageCount, currentPageNumber, list, totalResultCount} = body;

      dispatch(action({
        totalPageCount,
        currentPageNumber,
        priceQuotes: list,
        totalResultCount,
      }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError(error));
    });
};

export const deletePriceQuote = priceQuoteID => dispatch => {
  const data = {
    priceQuoteID: parseInt(priceQuoteID, 10),
  };

  return axios.post(DELETE_PRICE_QUOTE, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.deletePriceQuote({priceQuoteID}));
    })
    .catch(error => {
      error.clientMessage = "Can't delete data";
    });
};

export const getPriceQuotes = getPriceQuotesList(actions.priceQuotesFetched);
export const getNextPriceQuotes = getPriceQuotesList(actions.priceQuotesAppended);

import React from 'react';
import {useHeader} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import SubheaderBar from '../../_common/components/SubheaderBar';
import InspectionsPageFilters from '../components/InspectionsPageFilter';
import InspectionsPageTable from '../components/InspectionsPageTable';
import {
  InspectionsPageUIProvider
} from '../components/InspectionsPageUIContext';
import {
  StreetsProvider
} from '../../_common/components/CitiesAndStreets';

const InspectionsPage = () => {
  const header = useHeader();
  header.setTitle(useIntl().formatMessage({
    id: 'inspections-page-header',
    defaultMessage: 'Inspections page'
  }));

  return (
    <StreetsProvider>
      <InspectionsPageUIProvider>
        <SubheaderBar>
          <InspectionsPageFilters/>
        </SubheaderBar>
        <InspectionsPageTable/>
      </InspectionsPageUIProvider>
    </StreetsProvider>
  );
};

export default InspectionsPage;

import axios from 'axios';
import {actionTypes} from "../reducers/allShumaTypesReduсer";

export const GET_ALL_SHUMA_TYPES = '/generalData/getAllShumaTypes';

export const getAllShumaTypes = () => dispatch =>{

  return axios.post(GET_ALL_SHUMA_TYPES, {})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaTypes} = body;

      dispatch({
        type: actionTypes.getAllShumaTypes,
        payload: {shumaTypes},
      })
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

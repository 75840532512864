import React from 'react';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import styles from './styles.module.scss';

const Logo = () => {
  const context = useCompleteInspectionContext();
  const {
    firmData: {
      logoURL
    },
  } = context.data;

  return (
    <div
      className={`${styles.flexCenter} ${styles.containerLogo} ${context.shumaTypeID === 3 || context.shumaTypeID === 4 ? styles.bottomLine : ''}`}>
      <img src={logoURL} alt="firm logo" className={styles.logo}/>
    </div>
  );
};

export default Logo;

import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

const ShumaType3Line2 = React.forwardRef(({}, ref) => {
  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      <span className={styles.bold}>סוג הנכס: </span>
      <span>
         דירה בבית קומות.
      </span>
    </div>
  );
});

ShumaType3Line2.propTypes = {};

export default ShumaType3Line2;

import {actionTypes} from "../../OldInspectionDetails/reducers/oldInspectionDetailsReduser";
import axios from "axios";

export const ADD_EDIT_INSPECTION_DATA = "/inspections/AddEditOldInspection";
export const GET_OLD_INSPECTION_DATA = "/inspections/GetOldInspectionData";
export const CLOSE_SHUMA = "/inspections/closeShuma";

export const addEditInspectionData = (data) => {
  return axios.post(ADD_EDIT_INSPECTION_DATA, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaID} = body
      return shumaID
    });
};

export const getOldInspectionData = shumaID => dispatch => {
  const data = {shumaID: parseInt(shumaID, 10)};
  return axios.post(GET_OLD_INSPECTION_DATA, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {shumaData} = body;

      dispatch({
        type: actionTypes.inspectionDetailsFetched,
        payload: {shumaData: shumaData},
      });
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch({
        type: actionTypes.catchError,
        payload: error,
      });
    });
};

export const getOldInspectionDataValues = shumaID => dispatch => {
  const data = {shumaID: parseInt(shumaID, 10)};
  return axios.post(GET_OLD_INSPECTION_DATA, data)
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      return body.shumaData;
    });
};

export const closeShuma = (shumaID) => () => {
  const data = {
    shumaID: parseInt(shumaID, 10)
  };

  return axios.post(CLOSE_SHUMA, data)
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();
    })
    .catch((err) => {
      console.error(err);
    });
};

import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  getShumaReasons,
  getBanks,
  getShumaPropertyTypes,
  getLoanTypes,
  getLoanSubTypes,
  getShumaMazminIdentities,
  getShumaTypesList
} from './actions/decisionTreeOptionsActions';
import {useCacheContext} from '../AppCahce/AppCache';
import {SplashScreen} from '../../../../../_metronic/_partials/controls';

const DecisionTreeOptionsContext = createContext('');

export function useDecisionTreeOptionsContext() {
  return useContext(DecisionTreeOptionsContext);
}

export function DecisionTreeOptionsContextProvider({children}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {reasons, banks, propertyTypes, loanTypes, loanSubTypes, shumaMazminIdentities} = useSelector(
    (state) => ({
      reasons: state.decisionTreeOptionsReducer.reasons,
      banks: state.decisionTreeOptionsReducer.banks,
      propertyTypes: state.decisionTreeOptionsReducer.propertyTypes,
      loanTypes: state.decisionTreeOptionsReducer.loanTypes,
      loanSubTypes: state.decisionTreeOptionsReducer.loanSubTypes,
      shumaMazminIdentities: state.decisionTreeOptionsReducer.shumaMazminIdentities,
    }),
    shallowEqual
  );

  const getOptions = async () => {
    if (reasons?.length === 0) {
      await dispatch(getShumaReasons());
    }
    if (banks?.length === 0) {
      await dispatch(getBanks());
    }
    if (propertyTypes?.length === 0) {
      await dispatch(getShumaPropertyTypes());
    }
    if (loanTypes?.length === 0) {
      await dispatch(getLoanTypes());
    }
    if (loanSubTypes?.length === 0) {
      await dispatch(getLoanSubTypes());
    }
    if (shumaMazminIdentities?.length === 0) {
      await dispatch(getShumaMazminIdentities());
    }
  };

  useEffect(() => {
    setLoading(true);
    getOptions()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const value = {
    getOptions,
    reasons,
    banks,
    propertyTypes,
    loanTypes,
    loanSubTypes,
    shumaMazminIdentities,
  };

  return loading ? <SplashScreen/> : (
    <DecisionTreeOptionsContext.Provider value={value}>
      {children}
    </DecisionTreeOptionsContext.Provider>
  );
}

const DecisionTreeShumaTypesOptionsContext = createContext('');

export function useDecisionTreeShumaTypesOptionsContext() {
  return useContext(DecisionTreeShumaTypesOptionsContext);
}

export function DecisionTreeShumaTypesOptionsContextProvider({children}) {
  const [shumaTypes, setShumaTypes] = useState(null);
  const {getItem, setItem} = useCacheContext();

  const updateShumaTypes = async (shumaReasonID) => {
    const cachedList = getItem(`shumaTypes-${shumaReasonID}`);
    if (cachedList) {
      setShumaTypes(cachedList);
    } else {
      const list = await getShumaTypesList(shumaReasonID);
      setShumaTypes([...list]);
      setItem(`shumaTypes-${shumaReasonID}`, list);
    }
  };

  const value = {
    shumaTypes,
    updateShumaTypes,
  };

  return (
    <DecisionTreeShumaTypesOptionsContext.Provider value={value}>
      {children}
    </DecisionTreeShumaTypesOptionsContext.Provider>
  );
}

DecisionTreeOptionsContextProvider.propTypes = {
  children: PropTypes.node,
};

import {dashboardPageRoute} from './DashboardPage';
import {priceQuotesPageRoute} from './PriceQuotesPage';
import {ordersPageRoute} from './OrdersPage';
import {orderDetailsRoute} from './OrderDetails';
import {inspectionsPageRoute} from './InspectionsPage';
import {inspectionDetailsPageRoute} from './InspectionDetails';
import {customersEntityRoute} from './CustomersEntity';
import {customerDetailsRoute} from './CustomerDetails';
import {oldInspectionsPageRoute} from './OldInspectionsPage';
import {oldInspectionDetailsRoute} from './OldInspectionDetails';
import {tasksRoute} from './TasksPage';

export default [
  dashboardPageRoute,
  priceQuotesPageRoute,
  ordersPageRoute,
  orderDetailsRoute,
  inspectionsPageRoute,
  inspectionDetailsPageRoute,
  customersEntityRoute,
  customerDetailsRoute,
  oldInspectionsPageRoute,
  oldInspectionDetailsRoute,
  tasksRoute,
]

import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {useFormik, FormikProvider} from 'formik';
import {useOrderDetailsUIGlobalContext} from './OrderDetailsUIGlobalContext';
import {decisionTree} from '../../_common/constants/decisionTree';
import {filterOptions} from '../../_common/utils/filterOptions';
import {
  useDecisionTreeOptionsContext,
  useDecisionTreeShumaTypesOptionsContext
} from '../../_common/components/DecisionTreeOptions';
import {
  useStreetsContext,
  useCitiesContext
} from '../../_common/components/CitiesAndStreets';
import Spinner from '../../_common/components/Spinner';

const OrderDetailsUIInspectionContext = createContext('');

export function useOrderDetailsUIInspectionContext() {
  return useContext(OrderDetailsUIInspectionContext);
}

export function OrderDetailsUIInspectionProvider({
                                                   inspectionDetails,
                                                   children
                                                 }) {
  const [loading, setLoading] = useState(false);
  const {
    users,
  } = useOrderDetailsUIGlobalContext();
  const {
    banks,
    reasons,
    propertyTypes,
    loanTypes,
    loanSubTypes,
    shumaMazminIdentities
  } = useDecisionTreeOptionsContext();
  const {
    shumaTypes,
    updateShumaTypes
  } = useDecisionTreeShumaTypesOptionsContext();
  const {cities} = useCitiesContext();
  const {streets, updateStreets} = useStreetsContext();

  const fieldNames = {
    propertyTypeIDRightsEstimation: `propertyTypeID-rightsEstimation`,
    bankID: `bankID`,
    shumaTypeID: `shumaTypeID`,
    propertyTypeID: `propertyTypeID`,
    loanTypeID: `loanTypeID`,
    loanText: `loanText`,
    otherPropertyTypeID: `otherPropertyTypeID`,
    shumaReasonID: `shumaReasonID`,
    bankBranchNumber: `bankBranchNumber`,
    bankBranchName: `bankBranchName`,
    bankClerkFullName: `bankClerkFullName`,
    bankEmail: `bankEmail`,
    bankRequestID: `bankRequestID`,
    bankBuildType: `bankBuildType`,
    responsibleUser: `responsibleUser`,
    dueDate: `dueDate`,
    city: `city`,
    gush: `gush`,
    street: `street`,
    helka: `helka`,
    houseNumber: `houseNumber`,
    ttHelka: `ttHelka`,
    apartmentNumber: `apartmentNumber`,
    notes: `notes`,
    landLotNum: 'landLotNum',
    landLotNumAccordingTaba: 'landLotNumAccordingTaba',
    loanSubTypeID: 'loanSubTypeID',
    shumaMazminIdentityID: `shumaMazminIdentityID`,
    underConstruction: `underConstruction`,
  };

  const [showBankData, setShowBankData] = useState(false);
  const disabledFirstShumaReasonID = false;

  const getInspectionInitialValues = async (item = {}) => {
    const {
      shumaID,
      shumaReasonID,
      propertyTypeID,
      bankID,
      bankBranchNumber,
      bankBranchName,
      bankClerkFullName,
      bankEmail,
      bankRequestID,
      bankBuildType,
      shumaTypeID,
      loanTypeID,
      loanText,
      otherPropertyTypeID,
      responsibleUserID,
      dueDate,
      cityID,
      gush,
      streetID,
      helka,
      houseNumber,
      ttHelka,
      apartmentNumber,
      landLotNum,
      landLotNumAccordingTaba,
      notes,
      loanSubTypeID,
      shumaMazminIdentityID,
      underConstruction
    } = item;

    if (shumaReasonID && (!shumaTypes)) {
      await updateShumaTypes(shumaReasonID);
    }

    if ((cityID > 0) && (!streets)) {
      await updateStreets(cityID);
    }

    return {
      shumaID: shumaID || null,
      shumaReasonID: shumaReasonID
        ? filterOptions(shumaReasonID)(reasons)[0]?.value?.toString() : null,
      propertyTypeID: propertyTypeID
        ? filterOptions(propertyTypeID)(propertyTypes)[0] : null,
      bankID: bankID ? filterOptions(bankID)(banks)[0] : null,
      bankBranchNumber: bankBranchNumber || '',
      bankBranchName: bankBranchName || '',
      bankClerkFullName: bankClerkFullName || '',
      bankEmail: bankEmail || '',
      bankRequestID: bankRequestID || '',
      bankBuildType: bankBuildType || '',
      shumaTypeID: (shumaTypeID && shumaTypes.length > 0)
        ? filterOptions(shumaTypeID)(shumaTypes)[0] : null,
      loanTypeID: loanTypeID ? filterOptions(loanTypeID)(loanTypes)[0] : null,
      loanText: loanText || '',
      otherPropertyTypeID: otherPropertyTypeID
        ? filterOptions(otherPropertyTypeID)(propertyTypes)[0] : null,
      responsibleUser: responsibleUserID ? filterOptions(responsibleUserID)(users)[0] : null,
      dueDate: dueDate ? new Date(dueDate) : null,
      city: cityID ? filterOptions(cityID)(cities)[0] : null,
      street: (streetID && streets.length > 0) ? filterOptions(streetID)(streets)[0] : null,
      gush: gush || '',
      helka: helka || '',
      houseNumber: houseNumber || '',
      ttHelka: ttHelka || '',
      apartmentNumber: apartmentNumber || '',
      landLotNum: landLotNum || '',
      landLotNumAccordingTaba: landLotNumAccordingTaba || '',
      notes: notes || '',
      loanSubTypeID: (loanSubTypeID && loanSubTypes) ? filterOptions(loanSubTypeID)(loanSubTypes)[0] : null,
      shumaMazminIdentityID: (shumaMazminIdentityID && shumaMazminIdentities) ? filterOptions(shumaMazminIdentityID)(shumaMazminIdentities)[0] : null,
      underConstruction: Boolean(underConstruction)
    };
  };

  useEffect(() => {
    setLoading(true);
    getInspectionInitialValues(inspectionDetails)
      .then(formikOrderInspection.setValues)
      .catch(() => setLoading(false))
      .finally(() => {
        const {shumaReasonID, cityID} = formikOrderInspection.values;
        const optionsAreReady = shumaReasonID && shumaTypes && cityID && streets;
        const optionsAreReadyShumaTypesOnlyRequired = shumaReasonID && shumaTypes && !cityID;
        const optionsAreReadyStreetsOnlyRequired = !shumaReasonID && cityID && streets;
        const optionsAreMotRequired = !shumaReasonID && !cityID;
        if (
          optionsAreReady
          || optionsAreReadyShumaTypesOnlyRequired
          || optionsAreReadyStreetsOnlyRequired
          || optionsAreMotRequired
        ) {
          setLoading(false);
        }
      });
  }, [
    JSON.stringify(inspectionDetails),
    JSON.stringify(shumaTypes),
    JSON.stringify(streets),
  ]);

  const validate = values => {
    let errors = {};
    const {
      shumaReasonID,
      propertyTypeID,
      bankID,
      shumaTypeID,
      loanTypeID,
      loanText,
      otherPropertyTypeID,
      responsibleUser,
      shumaMazminIdentityID
    } = values;

    if (shumaReasonID === decisionTree.rightsEstimation) {
      if (!propertyTypeID) {
        errors[`propertyTypeID`] = 'Required field';
      }
    } else {
      if (!bankID) {
        errors[`bankID`] = 'Required field';
      }
      if (!shumaTypeID) {
        errors[`shumaTypeID`] = 'Required field';
      }
      if (!propertyTypeID) {
        errors[`propertyTypeID`] = 'Required field';
      }
    }
    if (loanTypeID && `${loanTypeID.value}` === decisionTree.loanTypeID.other) {
      if (!otherPropertyTypeID) {
        errors[`otherPropertyTypeID`] = 'Required field';
      }
    }
    if (loanTypeID && `${loanTypeID.value}` === decisionTree.loanTypeID.another) {
      if (!loanText) {
        errors[`loanText`] = 'Required field';
      }
    }
    if (!responsibleUser) {
      errors[`responsibleUser`] = 'Required field';
    }
    if (!shumaMazminIdentityID) {
      errors[`shumaMazminIdentityID`] = 'Required field';
    }

    return errors;
  };

  const formikOrderInspection = useFormik({
    initialValues: {
      shumaID: null,
      shumaReasonID: null,
      propertyTypeID: null,
      bankID: null,
      bankBranchNumber: '',
      bankBranchName: '',
      bankClerkFullName: '',
      bankEmail: '',
      bankRequestID: '',
      bankBuildType: '',
      shumaTypeID: null,
      loanTypeID: null,
      loanText: '',
      otherPropertyTypeID: null,
      responsibleUser: null,
      dueDate: null,
      city: null,
      street: null,
      gush: '',
      helka: '',
      houseNumber: '',
      ttHelka: '',
      apartmentNumber: '',
      notes: '',
      landLotNum: '',
      landLotNumAccordingTaba: '',
      loanSubTypeID: null,
      shumaMazminIdentityID: null,
      underConstruction: false
    },
    onSubmit: () => {
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true
  });

  const decisionTreeOptions = {
    disabled: [],
  };

  const handleResetDecisionTree = (value, fieldNames) => {
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanText,
      otherPropertyTypeID,
      loanSubTypeID
    } = fieldNames;
    const {setFieldValue, validateForm} = formikOrderInspection;
    switch (value) {
      case decisionTree.bankSecure:
        setFieldValue(bankID, null);
        setFieldValue(shumaTypeID, null);
        setFieldValue(propertyTypeID, null);
        setFieldValue(loanTypeID, null);
        setFieldValue(loanSubTypeID, null);
        setFieldValue(loanText, null);
        setFieldValue(otherPropertyTypeID, null);
      case decisionTree.rightsEstimation:
      default:
        setFieldValue(shumaTypeID, null);
        setFieldValue(propertyTypeID, null);
    }
    validateForm();
  };

  const handleResetPreviousSteps = (fieldId, value, fieldNames, values) => {
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanSubTypeID,
      loanText,
      otherPropertyTypeID,
      bankBranchNumber,
      bankBranchName,
      bankClerkFullName,
      bankEmail,
      bankRequestID,
      bankBuildType,
    } = fieldNames;
    const {setFieldValue} = formikOrderInspection;
    const shumaTypeIDFieldEnabled = !decisionTreeOptions.disabled.includes(shumaTypeID);
    switch (fieldId) {
      case (bankID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(bankBranchNumber, '');
          setFieldValue(bankBranchName, '');
          setFieldValue(bankClerkFullName, '');
          setFieldValue(bankEmail, '');
          setFieldValue(bankRequestID, '');
          setFieldValue(bankBuildType, '');
          setFieldValue(shumaTypeID, null);
        }
      case (shumaTypeID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(propertyTypeID, null);
        }
      case (propertyTypeID):
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(loanTypeID, null);
        }
      case (loanTypeID):
      default:
        if (shumaTypeIDFieldEnabled) {
          setFieldValue(loanText, '');
          setFieldValue(loanSubTypeID, null);
          setFieldValue(otherPropertyTypeID, null);
        }
    }
    validateUpdatedForm({
      fieldId,
      value,
      fieldNames,
      formValues: values, ...formikOrderInspection
    });
  };

  const validateUpdatedForm = ({
                                 fieldId,
                                 value,
                                 fieldNames,
                                 formValues,
                                 validateForm,
                                 values,
                                 setErrors
                               }) => {
    const updatedValues = {...values, [fieldId]: value};
    const {
      bankID,
      shumaTypeID,
      propertyTypeID,
      loanTypeID,
      loanSubTypeID,
      otherPropertyTypeID,
    } = fieldNames;

    switch (fieldId) {
      case (bankID):
        updatedValues[shumaTypeID] = formValues.shumaTypeID;
      case (shumaTypeID):
        updatedValues[propertyTypeID] = formValues.propertyTypeID;
      case (propertyTypeID):
        updatedValues[loanTypeID] = formValues.loanTypeID;
      case (loanTypeID):
      default:
        updatedValues[loanSubTypeID] = formValues.loanSubTypeID;
        updatedValues[otherPropertyTypeID] = formValues.otherPropertyTypeID;
    }
    validateForm(updatedValues).then(setErrors);
  };

  const value = {
    formikOrderInspection,
    decisionTreeProps: {
      decisionTree,
      decisionTreeOptions,
      handleResetDecisionTree,
      handleResetPreviousSteps,
      disabledFirstShumaReasonID,
      showBankData,
      setShowBankData,
    },
    fieldNames,
  };

  return loading ? <Spinner/> : (
    <OrderDetailsUIInspectionContext.Provider value={value}>
      <FormikProvider value={value.formikOrderInspections}>
        {children}
      </FormikProvider>
    </OrderDetailsUIInspectionContext.Provider>
  );
}

OrderDetailsUIInspectionProvider.propTypes = {
  inspection: PropTypes.object,
  children: PropTypes.node,
};

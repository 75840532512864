import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getGushHelkaTTHelkaRTLString} from '../../../utils/texts';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Section6Text1 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        streetName,
        houseNumber,
        cityName,
        gush, helka, ttHelka,
      },
    },
  } = useCompleteInspectionContext();
  const address = `${streetName} ${houseNumber}, ${cityName}`;

  return (
    <div className={classnames(styles.itemText, styles.innerPadding)} ref={ref}>
      הנכס שבנדון מהווה דירת מגורים בבנין הממוקם ברח' {address},
      בגו"ח {getGushHelkaTTHelkaRTLString({gush, helka, ttHelka})}.
    </div>
  );
});

export default Section6Text1;

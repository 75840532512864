import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getSelectedOptionLabel} from '../../../utils/options';
import * as options from '../../../constants/options';
import classnames from 'classnames';
import styles from '../styles.module.scss';

const ShumaType1BuildingRoofTypeText = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const buildingRoofTypeValue = getSelectedOptionLabel(context.data.pdfData.buildingRoofType, options.buildingRoofTypeOptions);

  return (
    <div ref={ref}>
      {Boolean(buildingRoofTypeValue) && (
        <div
          className={classnames(styles.innerPadding, styles.itemText)}
        >
          <span className={styles.bold}>סוג גג: </span>
          {` ${buildingRoofTypeValue}.`}
        </div>
      )}
    </div>
  );
});

export default ShumaType1BuildingRoofTypeText;

import axios from 'axios';
import {actions} from '../reducers/CustomerFileListReducer';
import {downloadFile} from "../../../../utils/downloadFile";

export const GET_FILE_LIST = '/customerFiles/getFileList';
export const UPLOAD_FILE = '/customerFiles/uploadFile';
export const GET_FILE = '/customerFiles/getFile';
export const DELETE_FILE = '/customerFiles/deleteFile';

export const getFileList = (customerID) => dispatch => {
  return axios.post(GET_FILE_LIST, {customerID})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();

      dispatch(actions.fetchFileList(body));
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find files';
    });
};

export const uploadFile = (customerID, fileToSave, fileName, payload = {}) => {

  return (dispatch) => {
    const formData = new FormData();
    formData.append('fileToSave', fileToSave);
    formData.append('customerID', customerID);
    formData.append('fileName', fileName);
    Object.keys(payload).forEach(key => {
      formData.append(`${key}`, payload[key]);
    });

    return axios.post(UPLOAD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .catch((err) => {
        console.error(`uploadFile returns error: ${err}`);
      });
  };
};

export const getFile = (customerID, fileName) => dispatch => {
  return axios.post(GET_FILE, {customerID, fileName})
    .then(response => {
      const {errorCode, body} = response.data;
      if (errorCode < 0) throw new Error();
      const {file} = body;

      try {
        downloadFile(`data:;base64,${file}`, fileName);
      } catch (exc) {
        console.log("Save Blob method failed with the following exception.");
        console.log(exc);
      }

      return file
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find file';
    });
};

export const deleteFile = (customerID, fileName, fileID) => dispatch => {
  return axios.post(DELETE_FILE, {customerID, fileName, fileID})
    .then(response => {
      const {errorCode} = response.data;
      if (errorCode < 0) throw new Error();

    })
    .catch(error => {
      error.clientMessage = 'Can\'t delete file';
    });
};

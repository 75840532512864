import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {useHeader} from '../../../../_metronic/layout';
import SubheaderBar from '../../_common/components/SubheaderBar';
import {
  OrderDetailsUIGlobalProvider
} from '../components/OrderDetailsUIGlobalContext';
import {
  OrderDetailsUIOrderProvider
} from '../components/OrderDetailsUIOrderContext';
import {
  OrderDetailsUIInspectionsListProvider
} from '../components/OrderDetailsUIInspectionsListContext';
import {
  OrderDetailsUIFilesProvider
} from '../components/OrderDetailsUIFilesContext';
import {
  StreetsProvider
} from '../../_common/components/CitiesAndStreets';
import TabsContainer from '../components/TabsContainer';

const OrderDetails = () => {
  const header = useHeader();
  const intl = useIntl();
  const location = useLocation();

  const orderDetailsHeaderCreate = intl.formatMessage({
    id: 'order-details-page-header-create',
    defaultMessage: 'New order/case',
  });
  const orderDetailsHeaderEdit = intl.formatMessage({
    id: 'order-details-page-header-edit',
    defaultMessage: 'EDIT order/case',
  });

  const getOrderID = () => {
    const pathName = location.pathname;
    const orderNumber = pathName.replace('/order-details/', '');

    return orderNumber === '-1' ? -1 : parseInt(orderNumber, 10);
  };

  const headerOrderDetails = getOrderID() === -1
    ? orderDetailsHeaderCreate
    : `${orderDetailsHeaderEdit} ${getOrderID()}`;

  header.setTitle(headerOrderDetails);

  return (
    <StreetsProvider>
      <OrderDetailsUIGlobalProvider>
        <OrderDetailsUIOrderProvider>
          <OrderDetailsUIInspectionsListProvider>
            <OrderDetailsUIFilesProvider>
              <SubheaderBar/>
              <TabsContainer/>
            </OrderDetailsUIFilesProvider>
          </OrderDetailsUIInspectionsListProvider>
        </OrderDetailsUIOrderProvider>
      </OrderDetailsUIGlobalProvider>
    </StreetsProvider>
  );
};

export default OrderDetails;

import React, {useState} from 'react';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import * as _ from 'lodash';
import {useStreetsContext} from '../CitiesAndStreets';
import CommonButton from '../CommonButton';
import {StyledFieldInput} from '../FieldInput';
import {showGlobalMessage} from '../GlobalMessage/actions/showGlobalMessage';
import {filterOptionsByLabel} from '../../utils/filterOptions';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import styles from './styles.module.scss';

const PopupContent = ({cityID, onSubmit, close}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {addStreet, streets} = useStreetsContext();

  const handleSubmit = async values => {
    const {errorMessage, list} = await addStreet(cityID, values.streetName);

    if (errorMessage) {
      await dispatch(showGlobalMessage(errorMessage));
    } else if (onSubmit) {
      const [selected] = filterOptionsByLabel(list)(values.streetName);
      await onSubmit(selected);
    }

    close();
  };

  const formik = useFormik({
    initialValues: {streetName: ''},
    validate: values => (values.streetName) ? {} : {streetName: 'required'},
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const buttonLabelSubmit = intl.formatMessage({
    id: 'inspection-details-addStreet-label-popup-submit',
    defaultMessage: 'Submit',
  });
  const buttonLabelCancel = intl.formatMessage({
    id: 'inspection-details-addStreet-label-popup-cancel',
    defaultMessage: 'Close',
  });
  const fieldLabelStreet = intl.formatMessage({
    id: 'inspection-details-addStreet-label-popup-input',
    defaultMessage: 'Street'
  });
  const headerText = intl.formatMessage({
    id: 'inspection-details-addStreet-label-popup-header',
    defaultMessage: 'Input a new street name'
  });

  return (
    <>
      <div className={styles.formContainer}>
        <h2 className={'text-center py-4'}>{headerText}</h2>
        <StyledFieldInput
          fieldId={'streetName'}
          value={_.get(formik.values, 'streetName')}
          label={fieldLabelStreet}
          onChange={formik.handleChange}
          customLabelStyles={'pl-6'}
        />
      </div>

      <div className={'w-50 mx-auto mt-10 d-flex justify-content-between'}>
        <CommonButton
          label={buttonLabelCancel}
          onClick={close}
        />
        <CommonButton
          customButtonClassNames={'btn btn-warning font-weight-bolder text-dark text-nowrap font-size-lg'}
          label={buttonLabelSubmit}
          onClick={formik.submitForm}
          disabled={!(formik.isValid && cityID)}
        />
      </div>
    </>
  );
};

const AddStreetPopup = ({buttonDisabled, ...props}) => {
  const intl = useIntl();

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = async () => {
    setShow(true);
  };

  const buttonLabel = intl.formatMessage({
    id: 'inspection-details-addStreet-label-button',
    defaultMessage: 'Add street'
  });

  return (
    <>
      <div className={'w-25 pr-4 d-flex align-item-end'}>
        <CommonButton
          customButtonClassNames={classnames(`font-weight-bolder text-dark text-nowrap bg`, styles.addButton)}
          title={buttonLabel}
          icon={toAbsoluteUrl('/media/common/Plus.svg')}
          onClick={handleShowModal}
          disabled={buttonDisabled}
        />
      </div>
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <PopupContent
            close={handleCloseModal}
            {...props}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddStreetPopup;

import React from 'react';

export function CustomersColumnFormatter(cellContent) {

  const mappedCellContent = cellContent.map(item => {
    return (
      <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
        {item.firstName} {item.lastName}
      </div>
    )
  });

  return (
    <div className="text-dark-75 font-weight-bolder d-block font-size-lg text-nowrap">
      {mappedCellContent}
    </div>
  );
}

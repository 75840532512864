import React from 'react';
import classnames from 'classnames';
import {toAbsoluteUrl} from '../../../../../_metronic/_helpers';
import styles from './styles.module.scss';

const ButtonClearFilter = ({onClick = () => {}, className}) => {

  return (
    <img
      src={toAbsoluteUrl('/media/common/Cross_BC.svg')}
      alt="clear-filter"
      className={classnames(styles.icon, className)}
      onClick={onClick}
    />
  );
};

export default ButtonClearFilter;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {FormattedMessage} from "react-intl";
import {Document, Page} from 'react-pdf';
import Modal from "react-bootstrap/Modal";
import CommonButton from "../../../_common/components/CommonButton";
import Spinner from "../../../_common/components/Spinner";
import Empty from "../../../_common/components/Empty";
import styles from './styles.module.scss';


const PopupPreviewDocument = ({
                                documentPath,
                                customButtonClassnames = 'btn btn-default btn-sm font-weight-bold text-dark text-nowrap font-size-lg',
                                handlePreviewDocument,
                                allowHandlePreviewDocument,
                                disableButton = false
                              }) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  return (
    <>
      <button
        onClick={() => {
          handleShowModal();
          if (allowHandlePreviewDocument) {
            handlePreviewDocument();
          }
        }}
        className={customButtonClassnames}
        disabled={disableButton}
      >
        <img
          src={toAbsoluteUrl('/media/common/Visible.svg')}
          alt="preview"
          className='w-20px mr-2'
        />
        <span className='text-truncate'>
          <FormattedMessage
            id="customer-details-documentInfo-label-button-view"
            defaultMessage="Preview"
          />
        </span>
      </button>
      <Modal
        show={show}
        onHide={handleCloseModal}
        size={'lg'}
      >
        <Modal.Body>
          <div className={styles.popupPreviewDocumentContainer}>
            <Document
              file={documentPath}
              loading={
                <div className={styles.iconContainer}>
                  <Spinner/>
                </div>
              }
              noData={
                <div className={styles.iconContainer}>
                  <Empty/>
                </div>
              }
            >
              <Page pageNumber={1}/>
            </Document>
          </div>
          <div className={styles.buttonContainer}>
            <CommonButton
              variant={'button'}
              label={
                <FormattedMessage
                  id="button-close"
                  defaultMessage="Close"
                />
              }
              onClick={handleCloseModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PopupPreviewDocument.propTypes = {
  documentPath: PropTypes.string,
  customButtonClassnames: PropTypes.string,
  allowHandlePreviewDocument: PropTypes.bool,
  handlePreviewDocument: PropTypes.func,
  disableButton: PropTypes.bool,
}

export default PopupPreviewDocument;

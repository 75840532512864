import React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import {getBreakLinesTextShumaType3, getBreakLinesTextShumaType3WithoutDot} from '../../../utils/texts';
import {findFileByTypeId} from '../../../utils/files';
import {fileTypeId} from '../../../constants/fileTypeId';
import {versions as V} from '../../../constants/versions';
import styles from './styles.module.scss';
import {convertFileToContent} from '../../FileToContentConverter';

const HeadLine1 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <HeadContent/>
      <SubHead1Content/>
      <Line1Content/>
    </div>
  );
});

const HeadContent = () => {
  const {sectionNumbersShumaType3} = useCompleteInspectionContext();

  return (
    <div className={classnames(styles.textRegular, styles.sectionContainer)}>
      <span className={classnames(styles.textUnderline, styles.bold)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section15}. `}</span>
        <span>הצהרה</span>
      </span>
    </div>
  );
};

const SubHead1Content = () => (
  <div
    className={classnames(
      styles.textRegular,
      styles.sectionContainer,
      styles.innerPadding,
      styles.bold
    )}
  >
    הריני מצהיר בזה כי:
  </div>
);

const Line1Content = () => (
  <div className={styles.paddingOne}>
    <ul
      className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding, styles.noMarginAfter)}
    >
      <li>אין לי עניין בנכס הנדון ושומה זו נעשתה על פי מיטב ידיעתי והכשרתי המקצועית.</li>
    </ul>
  </div>
);

const Line2 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref} className={styles.paddingOne}>
      <ul
        className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding, styles.noMarginAfter)}
      >
        <li>מסמך זה נעשה בהתאם למבנה ולהנחיות הבנק.</li>
      </ul>
    </div>
  );
});

const Line3 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref} className={styles.paddingOne}>
      <ul
        className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding, styles.noMarginAfter)}
      >
        <li>יש להביא בחשבון כי בעת מו"מ/מימוש זכויות יתכנו סטיות מהשווי הנקוב בשיעור של כ-10% +/- .</li>
      </ul>
    </div>
  );
});

const Line4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        additionalStatements
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref} className={styles.paddingOne}>
      {Boolean(additionalStatements) && (
        <ul className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding, styles.noMarginAfter)}>
          <li>{getBreakLinesTextShumaType3(additionalStatements)}</li>
        </ul>
      )}
    </div>
  );
});

const Line5 = React.forwardRef(({}, ref) => {
  const {
    data: {
      userData: {
        userFirstName,
        userLastName,
        gender,
        signatureUrl,
      },
    },
    version
  } = useCompleteInspectionContext();

  return (
    <div
      ref={ref}
      className={classnames(
        styles.textRegular,
        styles.bold,
        styles.fullNameUserFinalizeReport,
      )}
    >
      <div className={classnames(styles.textCentered, styles.userFullNameContainer)}>
        <div>ולראיה באתי על החתום,</div>
        <div>{`${userFirstName} ${userLastName}`}</div>
        <div>{gender === 1 ? 'שמאי מקרקעין' : 'שמאית מקרקעין'}</div>
        {(version === V.OFFICIAL) && (
          <div className={classnames(styles.listItem, styles.signatureContainer)}>
            <img
              src={signatureUrl}
              alt="signature"
              className={styles.signature}
            />
          </div>
        )}
      </div>
    </div>
  );
});

const SubHead23Line6 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <SubHead2Content/>
      <SubHead3Content/>
      <Line6Content/>
    </div>
  );
});

const SubHead2Content = () => {
  const {
    data: {
      pdfData: {
        selfBuildDesc
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(selfBuildDesc) && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.bold
      )}
    >
      <span>נספח א</span>
    </div>
  );
};

const SubHead3Content = () => {
  const {
    data: {
      pdfData: {
        selfBuildDesc
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(selfBuildDesc) && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.paddingOne,
        styles.bold,
      )}
    >
      <span>1.  </span>
      <span>בבניה עצמית</span>
    </div>
  );
};

const Line6Content = () => {
  const {
    data: {
      pdfData: {
        selfBuildDesc
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(selfBuildDesc) && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.innerPadding
      )}
    >
      <div className={styles.bold}>אומדן שווי בבניה עצמית:</div>
      <div>{getBreakLinesTextShumaType3WithoutDot(selfBuildDesc)}</div>
    </div>
  );
};

const Line7Content = () => {
  const {
    data: {
      pdfData: {
        selfBuildDesc
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(selfBuildDesc) && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.bold,
        styles.innerPadding
      )}
    >
          <span>
            שווי הנכס בסיום הבניה הינו אומדן המותנה בבניית הנכס בסטנדט המפורט, בשטח המפורט, בהתאמה להיתר ולאחר הוצאת
            טופס 4.
          </span>
    </div>
  );
};

const Line7 = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <Line7Content/>
    </div>
  );
});

const HeaderLine8 = () => (
  <>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      להלן אומדן ההשקעה הצפויה בעלויות הבניה בכל שלב:
    </div>
  </>
);

const getLine8Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.BUILD_COST_ESTIMATE);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.BUILD_COST_ESTIMATE, {
      firstPageHeader: <HeaderLine8/>
    })
    : [];
};

const SubHeadLine4 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      <SubHead4Content/>
      <Line9Content/>
      {!Boolean(renovationDetailsDesc) && <Line10Content/>}
    </div>
  );
});

const SubHead4Content = () => {
  const {
    data: {
      pdfData: {
        renovationDetailsDesc,
        renovationCostDesc,
        selfBuildDesc
      },
      files
    },
  } = useCompleteInspectionContext();
  const showHeaderCondition =
    _.some([
      renovationDetailsDesc,
      renovationCostDesc,
      findFileByTypeId(files, fileTypeId.RENOVATION_COST_ESTIMATE)
    ]);

  return showHeaderCondition && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.paddingOne,
        styles.bold,
      )}
    >
      <span>{Boolean(selfBuildDesc) ? '2. ' : '1. '}</span>
      <span>בשיפוצים</span>
    </div>
  );
};

const Line9Content = () => {
  const {
    data: {
      pdfData: {
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();

  return Boolean(renovationDetailsDesc) && (
    <div className={classnames(styles.textRegular, styles.sectionContainer, styles.innerPadding)}>
      <span className={classnames(styles.bold)}>פרוט מהות השיפוצים הצפויים: </span>
      <span>{getBreakLinesTextShumaType3(renovationDetailsDesc)}</span>
    </div>
  );
};

const Line10Content = () => {
  const {
    data: {
      pdfData: {
        renovationCostDesc
      },
      files
    },
  } = useCompleteInspectionContext();

  return _.some([renovationCostDesc, findFileByTypeId(files, fileTypeId.RENOVATION_COST_ESTIMATE)]) && (
    <div
      className={classnames(
        styles.textRegular,
        styles.sectionContainer,
        styles.innerPadding
      )}
    >
      <div className={styles.bold}>אומדן סך עלות הכוללת של השיפוצים בש"ח:</div>
      <div>{getBreakLinesTextShumaType3(renovationCostDesc)}</div>
    </div>
  );
};

const Line10 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        renovationDetailsDesc
      },
    },
  } = useCompleteInspectionContext();

  return (
    <div ref={ref}>
      {Boolean(renovationDetailsDesc) && <Line10Content/>}
    </div>
  );
});

const HeaderLine11 = () => (
  <>
    <SubHeadLine4/>,
    <Line10/>
    <div
      className={classnames(
        styles.textRegular,
        styles.innerPadding,
        styles.bold,
      )}
    >
      קובץ:
    </div>
  </>
);

const getLine11Files = (context) => {
  const file = findFileByTypeId(context.files, fileTypeId.RENOVATION_COST_ESTIMATE);
  return Boolean(file)
    ? convertFileToContent(context, fileTypeId.RENOVATION_COST_ESTIMATE, {
      firstPageHeader: <HeaderLine11/>
    })
    : [
      <SubHeadLine4/>,
      <Line10/>,
    ];
};

export {
  getLine8Files,
  getLine11Files
};

export default {
  HeadLine1,
  Line2,
  Line3,
  Line4,
  Line5,
  SubHead23Line6,
  Line7,
};

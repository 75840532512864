import React from 'react';

const RestPageHeightElement = React.forwardRef(({}, ref) => {
  return (
    <div ref={ref}>
      <div data-label="restPageHeightElement"/>
    </div>
  );
});

export default RestPageHeightElement;

import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {isEqual, isFunction} from "lodash";
import {useDispatch} from "react-redux";
import {
  getDashboardDataExtendedDateRange,
  getDashboardDataExtendedDateRangeWithCity
} from "./actions/DashboardPageChartsActions";
import {showGlobalMessage} from "../_common/components/GlobalMessage/actions/showGlobalMessage";
import {messages as M} from "../../../_metronic/i18n/messages";

const ExtendedDateRangeContext = createContext('');

export function useExtendedDateRangeContext() {
  return useContext(ExtendedDateRangeContext);
}

export function ExtendedDateRangeProvider({children}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataBar, setDataBar] = useState([]);
  const [dataLine, setDataLine] = useState([]);
  const [extendedQueryParams, setExtendedQueryParamsBase] = useState({
    extendedDateRange: 'last 6 periods',
    scale: 'one week',
    cityID: -1,
    streetID: -1,
  });
  const setExtendedQueryParams = useCallback(nextExtendedQueryParams => {
    setExtendedQueryParamsBase(prevExtendedQueryParams => {
      if (isFunction(nextExtendedQueryParams)) {
        nextExtendedQueryParams = nextExtendedQueryParams(prevExtendedQueryParams);
      }

      if (isEqual(prevExtendedQueryParams, nextExtendedQueryParams)) {
        return prevExtendedQueryParams;
      }
      for (let key in nextExtendedQueryParams) {
        if (nextExtendedQueryParams.hasOwnProperty(key) && !nextExtendedQueryParams[key]) delete nextExtendedQueryParams[key]
      }
      const path = history.location.pathname;
      const newExtendedQueryParams = new URLSearchParams(nextExtendedQueryParams);
      history.replace(`${path}?${newExtendedQueryParams.toString()}`);

      return nextExtendedQueryParams;
    });
  }, []);
  const {extendedDateRange, scale, cityID, streetID} = extendedQueryParams;
  useEffect(() => {
    getData('bar')
  }, [extendedDateRange, scale]);
  useEffect(() => {
    getData('line')
  }, [extendedDateRange, scale, cityID, streetID]);

  const getData = async (type) => {
    try {
      if (type === 'bar') {
        let resultBar = await getDashboardDataExtendedDateRange({
          dateRange: extendedQueryParams.extendedDateRange,
          scale: extendedQueryParams.scale
        })
        setDataBar(resultBar)
      } else {
        let resultLine = await getDashboardDataExtendedDateRangeWithCity({
          dateRange: extendedQueryParams.extendedDateRange,
          scale: extendedQueryParams.scale,
          cityID: extendedQueryParams.cityID,
          streetID: extendedQueryParams.streetID,
        })
        setDataLine(resultLine)
      }
    } catch (e) {
      console.log(e);
      dispatch(showGlobalMessage(M["-1"]))
    }
  };

  const value = {
    extendedQueryParams,
    setExtendedQueryParamsBase,
    setExtendedQueryParams,
    dataBar,
    dataLine,
  };

  return (
    <ExtendedDateRangeContext.Provider value={value}>
      {children}
    </ExtendedDateRangeContext.Provider>
  );
}

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Modal from "react-bootstrap/Modal";
import CommonButton from "../../../../../_common/components/CommonButton";
import {OrderFilesList} from "../OrderFilesList";
import styles from './styles.module.scss';


const PopupSelectOrderFile = ({
                                label,
                                orderID,
                                fileTypeID,
                                shumaID
                              }) => {
  const [open, setOpen] = useState(false);
  const handleOpenPopup = () => setOpen(true);
  const handleClosePopup = () => setOpen(false);

  return (
    <>
      <div className={'w-100'} onClick={handleOpenPopup}>
        <img
          src={'../../../media/common/File-plus_BC.svg'}
          alt="file"
          className={'d-inline-block'}
        />
        <div className='text-dark mr-4 ml-1 d-inline-block'>
          <FormattedMessage
            id={`inspection-details-page-dropdown-file-${label}`}
            defaultMessage="File"
          />
        </div>
      </div>
      <Modal show={open} onHide={handleClosePopup} size={'lg'} backdrop={'static'}>
        <Modal.Body>
          <div className={styles.modalContainer}>
            <div className={styles.content}>
              <OrderFilesList
                header={
                  <FormattedMessage
                    id={`inspection-details-page-dropdown-file-${label}`}
                    defaultMessage="File"
                  />
                }
                fileTypeID={fileTypeID}
                orderID={orderID}
                shumaID={shumaID}
                closePopup={handleClosePopup}
              />
            </div>
            <div className={styles.buttonsContainer}>
              <CommonButton
                label={'ביטול'}
                onClick={handleClosePopup}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PopupSelectOrderFile.propTypes = {
  label: PropTypes.string,
  orderID: PropTypes.number,
  fileTypeID: PropTypes.number,
};

export default PopupSelectOrderFile;

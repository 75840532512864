import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {Button} from "react-bootstrap";
import {useIntl} from 'react-intl';
import {usePopupEditTasksContext} from "./PopupEditTasksContext";
import CommonButton from '../../../_common/components/CommonButton';
import TaskGeneral from './TaskGeneral';
import TaskVisit from './TaskVisit';
import {can} from "../../../_common/components/Permissions";
import {taskTypeIDs} from '../../../_common/constants/taskTypes';
import {tooltips as TTS} from "../../../_common/constants/tooltips";
import {permissionTypes as T, subjects as S} from "../../../_common/constants/permissionSubjects";

const PopupEditTask = ({id, type}) => {
  const {getData, edit, formik} = usePopupEditTasksContext();
  const {isValid, dirty} = formik;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
    await getData(id);
  };
  const handleEdit = async () => {
    await edit();
    handleClose();
  };

  const intl = useIntl();
  const labelButtonClose = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-close',
    defaultMessage: 'Close',
  });
  const labelButtonSubmit = intl.formatMessage({
    id: 'tab-order-inspections-tasks-button-submit',
    defaultMessage: 'Submit',
  });
  const header = intl.formatMessage({
      id: 'tab-order-inspections-tasks-button-label-editTask',
      defaultMessage: `Edit task ${id}`
    },
    {id}
  );

  return (
    <>
      <Button
        className={'btn btn-icon btn-warning border-0'}
        title={TTS.EDIT_TASK}
        onClick={handleShow}
        disabled={!can(T.FULL, S.TASKS_PAGE)}
      >
        <img src={'../../../media/common/Edit.svg'} alt="edit"/>
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="d-flex justify-content-center flex-column py-4 px-14">
            {(type === taskTypeIDs.general)
              ? <TaskGeneral header={header}/>
              : <TaskVisit header={header}/>
            }
            <div className="d-flex justify-content-between my-10">
              <CommonButton
                variant={'button'}
                label={labelButtonClose}
                onClick={handleClose}
              />
              <CommonButton
                variant={'submit'}
                label={labelButtonSubmit}
                onClick={() => handleEdit()}
                disabled={!(isValid && dirty)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PopupEditTask;

import {vat} from '../../_common/constants/financial';

const arraySum = (array) => {
  return array.reduce((acc, item) => {
    return Boolean(item) ? acc + parseFloat(item) : acc
  }, 0);
};

const calculateTotalInRows = rows => rows.map(item => {
  const vatType = item.vatType
  if (vatType || vatType === 0) {
    if (vatType.value === 0 || vatType === 0) {
      return item.price * item.quantity;
    }
    if (vatType.value === 1 || vatType === 1) {
      return (item.price * item.quantity) / (1 + vat);
    } else {
      return 0;
    }
  }
})

export const getTotalCommonDocsAmounts = (values, copiedDataCondition) => {
  if (copiedDataCondition) {
    const {rowsCommonDocs, type, amount: amountStr} = values;
    const amount = parseFloat(amountStr);
    const totalInRows = calculateTotalInRows(rowsCommonDocs);

    const totalWithoutVAT = arraySum(totalInRows) || 0;

    const totalDiscount = (type === 'percentage' || (Boolean(type) && type.value === 'percentage'))
        ? (totalWithoutVAT * amount / 100) || 0
        : amount || 0;

    const totalVAT = (totalWithoutVAT !== 0)
      ? (totalWithoutVAT - totalDiscount) * vat
      : 0;
    const total = totalWithoutVAT + totalVAT - totalDiscount;

    return {
      totalWithoutVAT: totalWithoutVAT.toFixed(2),
      totalDiscount: totalDiscount.toFixed(2),
      totalVAT: totalVAT.toFixed(2),
      total: total.toFixed(2)
    };
  } else {
    const {rowsCommonDocs, type, amount: amountStr} = values;
    const amount = parseFloat(amountStr);
    const totalInRows = calculateTotalInRows(rowsCommonDocs);
    const typeValue = Boolean(type) ? type.value : null;
    const totalWithoutVAT = arraySum(totalInRows) || 0;
    const totalDiscount = Boolean(typeValue) ? (
      (typeValue === 'percentage')
        ? (totalWithoutVAT * amount / 100) || 0
        : amount || 0
    ) : 0;
    const totalVAT = (totalWithoutVAT !== 0)
      ? (totalWithoutVAT - totalDiscount) * vat
      : 0;
    const total = totalWithoutVAT + totalVAT - totalDiscount;

    return {
      totalWithoutVAT: totalWithoutVAT.toFixed(2),
      totalDiscount: totalDiscount.toFixed(2),
      totalVAT: totalVAT.toFixed(2),
      total: total.toFixed(2)
    };
  }
};

export const getTotalPaid = (values) => {
  const {
    rowsCheque,
    rowsBankTransfer,
    rowsCreditCard,
    rowsPaymentApp,
    rowsPayPal,
    rowsCash,
    rowsOther,
    rowsWithholdingTax
  } = values;
  const arraysAmounts = [
    arraySum(rowsCheque.map(item => item.price)),
    arraySum(rowsBankTransfer.map(item => item.price)),
    arraySum(rowsCreditCard.map(item => item.price)),
    arraySum(rowsPaymentApp.map(item => item.price)),
    arraySum(rowsPayPal.map(item => item.price)),
    arraySum(rowsCash.map(item => item.price)),
    arraySum(rowsOther.map(item => item.price)),
    arraySum(rowsWithholdingTax.map(item => item.price)),
  ];

  return arraySum(arraysAmounts);
};

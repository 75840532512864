import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import classnames from 'classnames';
import moment from 'moment';
import styles from '../styles.module.scss';

const ShumaType3BuildingPermit = React.forwardRef(({}, ref) => {
  const context = useCompleteInspectionContext();
  const buildPermitDateValue = Boolean(context.data.pdfData.buildPermitDate) ? moment(context.data.pdfData.buildPermitDate).format('DD/MM/YYYY') : '';

  return (
    <div ref={ref}>
      {Boolean(context.data.pdfData.buildPermitNumber || buildPermitDateValue) && (
        <div className={classnames(styles.innerPadding, styles.itemText)}>
          <span className={styles.bold}>רישוי: </span>
          <>
            הבניין הוקם במקורו עפ"י היתר בניה
            מס' {context.data.pdfData.buildPermitNumber} מתאריך {buildPermitDateValue}
            {Boolean(context.data.pdfData.buildPermitReason === 1) &&
              <span> ובוצעו בו שינויים עפ"י היתרים המפורטים בפרק רישוי</span>
            }
            <span>.</span>
          </>
        </div>
      )}
    </div>
  );
});

export default ShumaType3BuildingPermit;

import React from 'react';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Section3 = React.forwardRef(({}, ref) => {
  const {
    data: {
      pdfData: {
        shumaTypeName
      },
    },
    sectionNumbersShumaType3
  } = useCompleteInspectionContext();

  return (
    <div
      className={classnames(styles.textRegular, styles.sectionContainer)}
      ref={ref}
    >
      <span>
      <span className={classnames(styles.textUnderline, styles.bold, styles.sectionHeader)}>
        <span className={styles.sectionNumber}>{`${sectionNumbersShumaType3.section3}. `}</span>
        <span>מטרת השומה:</span>
      </span>
        <span>ביטחון אשראי   </span>
      </span>
      <span>
        <span className={classnames(styles.textUnderline, styles.bold)}>
          סוג השומה:
        </span>
        <span>{` ${shumaTypeName}`}</span>
      </span>
    </div>
  );
});

export default Section3;

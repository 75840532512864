import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../CompleteInspectionContext';
import {versions as V} from '../../constants/versions';
import watermark from '../../assets/watermark.png';
import styles from './styles.module.scss';

const PageContainer = ({children, className, ...rest}) => {
  const {version} = useCompleteInspectionContext();

  const allowPageRender = () => {
    if (children instanceof Array) {
      return children.filter(Boolean).length > 0;
    } else {
      return Boolean(children);
    }
  };

  return allowPageRender() && (
    <div
      className={classnames(styles.pageContainer, styles.pageContainerContext, className)}
      {...rest}
    >
      {(version === V.DRAFT) && (
        <img
          src={watermark}
          alt="watermark"
          className={styles.watermark}
          data-testid="watermark-foreground-image"
        />
      )}
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
};

export default PageContainer;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useCompleteInspectionContext} from '../../CompleteInspectionContext';
import Media from '../../Media';
import {findFileByTypeId, getMediaType} from '../../../utils/files';
import {getFullDataURI} from '../../../utils/files/getFullDataURI';
import styles from './styles.module.scss';

const fileTypeID = 2;

const ShumaType1Text = () => (
  <div className={classnames(
    styles.listItem,
    styles.itemText,
    styles.innerPadding,
    styles.bold,
    styles.mb0)}
  >
    מפת איתור סביבה:
  </div>
);

const ShumaType2and3Text = () => (
  <div className={classnames(
    styles.listItem,
    styles.itemText,
    styles.innerPadding,
    styles.bold,
    styles.mb0)}
  >
    מערכת מיפוי ממשלתית:
  </div>
);

const MapImageWithText = React.forwardRef(({className}, ref) => {
  const context = useCompleteInspectionContext();
  const file = findFileByTypeId(context.files, fileTypeID);
  const mediaType = file && getMediaType(file);
  const imageSrc = file && getFullDataURI(file);
  const getAssetsByShumaTypeID = (shumaTypeID) => {
    switch (shumaTypeID) {
      case 3:
        return {
          text: <ShumaType2and3Text/>,
        };
      case 2:
        return {
          text: <ShumaType2and3Text/>,
        };
      case 1:
      default:
        return {
          text: <ShumaType1Text/>,
        };
    }
  };
  const assets = getAssetsByShumaTypeID(context.shumaTypeID);

  return (
    <div ref={ref}>
      {Boolean(file) && (
        <>
          {assets.text}
          <Media
            src={imageSrc}
            file={file}
            alt="file"
            className={classnames(styles.planMapImageWithText, styles.mb3)}
            type={mediaType}
          />
        </>
      )}
    </div>
  );
});

MapImageWithText.propTypes = {
  className: PropTypes.string,
  fileTypeID: PropTypes.number,
};

export default MapImageWithText;

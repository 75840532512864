import React, {createRef} from 'react';
import classnames from 'classnames';
import DocumentTitle from './contentShumaType3/DocumentTitle';
import DocProps from './contentShumaType3/DocProps';
import CustomerProps from './contentShumaType3/CustomerProps';
import Section1 from './contentShumaType3/Section1';
import Section2 from './contentShumaType3/Section2';
import Section3 from './contentShumaType3/Section3';
import Section4 from './contentShumaType3/Section4';
import Section5 from './contentShumaType3/Section5';
import Section6 from './contentShumaType3/Section6';
import * as Section7Assets from './contentShumaType3/Section7Images';
import Section8, * as Section8Assets from './contentShumaType3/Section8';
import Section9, * as Section9Assets from './contentShumaType3/Section9';
import Section10, * as Section10Assets from './contentShumaType3/Section10';
import Section11, * as Section11Assets from './contentShumaType3/Section11';
import Section12 from './contentShumaType3/Section12';
import Section13, * as Section13Assets from './contentShumaType3/Section13';
import Section14 from './contentShumaType3/Section14';
import Section15, * as Section15Assets from './contentShumaType3/Section15';
import Comments from './contentShumaType3/Comments';
import RestPageHeightElement from './contentShumaType3/RestPageHeightElement';
import {getSection11Image} from './contentShumaType3/Section11Image';
import {getFloors} from './contentShumaType3/Floors';
import {getAdjacentStreets} from './contentShumaType3/SectionAdjacentStreets';
import getValidLocalPlansDetailsSection from './contentShumaType3/ValidLocalPlansDetailsSection';
import getCountryStateDataSection from './contentShumaType3/CountryStateDataSection';
import getPlansInProgressSection from './contentShumaType3/PlansInProgressSection';
import styles from './styles.module.scss';
import contentStyles from './contentShumaType3/styles.module.scss';

let i = 0;

const mapItem = ({refs, id, getClassName}) => (item, index, array) => {
  const Component = React.forwardRef(({}, ref) => (
    <div
      ref={ref}
      data-testid={id}
      className={Boolean(getClassName) ? getClassName({index, array}) : ''}
    >
      {item}
    </div>
  ));

  return <Component ref={addRef(refs)} key={i++}/>;
};

export const getContentAndRefs = (context) => {
  let refs = [];
  let commentRefs = {};
  const content = [
    <DocProps ref={addRef(refs)} key={i++}/>,
    <DocumentTitle ref={addRef(refs)} key={i++}/>,
    <CustomerProps ref={addRef(refs)} key={i++}/>,
    <Section1 ref={addRef(refs)} key={i++}/>,
    <Section2 ref={addRef(refs)} key={i++}/>,
    <Section3 ref={addRef(refs)} key={i++}/>,
    <Section4 ref={addRef(refs)} key={i++}/>,
    <Section5.HeadLine1 ref={addRef(refs)} key={i++}/>,
    <Section5.Line2 ref={addRef(refs)} key={i++}/>,
    <Section5.Line3 ref={addRef(refs)} key={i++}/>,
    <Section5.Line4 ref={addRef(refs)} key={i++}/>,
    <Section6.HeadLine1 ref={addRef(refs)} key={i++}/>,
    ...getFloors(context, 32, 'זיהוי הנכס לפי תשריט')
      .map(mapItem({
        refs,
        id: 'getFloors',
        getClassName: ({index, array}) => classnames({
          [styles.mb5]: index === array.length - 1
        })
      })),
    <Section6.Line3 ref={addRef(refs)} key={i++}/>,
    <Section6.Line4 ref={addRef(refs)} key={i++}/>,
    <Section6.Line5 ref={addRef(refs)} key={i++}/>,
    <Section6.Line6 ref={addRef(refs)} key={i++}/>,
    <Section6.Line7 ref={addRef(refs)} key={i++}/>,
    <Section6.Line8 ref={addRef(refs)} key={i++}/>,
    <Section6.Line9 ref={addRef(refs)} key={i++}/>,
    <Section6.Line10 ref={addRef(refs)} key={i++}/>,
    <Section6.Line11 ref={addRef(refs)} key={i++}/>,
    <Section6.Line12 ref={addRef(refs)} key={i++}/>,
    <Section6.Line13 ref={addRef(refs)} key={i++}/>,
    <Section6.Line14 ref={addRef(refs)} key={i++}/>,
    <Section6.Line15 ref={addRef(refs)} key={i++}/>,
    <Section6.Line16 ref={addRef(refs)} key={i++}/>,
    <Section6.Line17 ref={addRef(refs)} key={i++}/>,
    ...Section7Assets.getSection7Images(context).map(mapItem({
      refs,
      id: 'Section7Assets.getSection7Images',
    })),
    <Section8.HeadLine1 ref={addRef(refs)} key={i++}/>,
    <Section8.Line2 ref={addRef(refs)} key={i++}/>,
    <Section8.Line3 ref={addRef(refs)} key={i++}/>,
    ...getAdjacentStreets(context).map(mapItem({
      refs,
      id: 'adjacentStreets'
    })),
    <Section8.Line4 ref={addRef(refs)} key={i++}/>,
    ...Section8Assets.getLine5MapFiles(context).map(mapItem({
      refs,
      id: 'Section8Assets.getLine5MapFiles'
    })),
    ...Section8Assets.getLine5PlotFiles(context).map(mapItem({
      refs,
      id: 'Section8Assets.getLine5PlotFiles'
    })),
    <Section8.SubHead2 ref={addRef(refs)} key={i++}/>,
    <Section8.Line7 ref={addRef(refs)} key={i++}/>,
    <Section8.Line7a ref={addRef(refs)} key={i++}/>,
    <Section8.SubHead3 ref={addRef(refs)} key={i++}/>,
    <Section8.Line9 ref={addRef(refs)} key={i++}/>,
    <Section8.Line10 ref={addRef(refs)} key={i++}/>,
    <Section8.Line11 ref={addRef(refs)} key={i++}/>,
    <Section8.Line12 ref={addRef(refs)} key={i++}/>,
    <Section8.Line13 ref={addRef(refs)} key={i++}/>,
    <Section8.Line14 ref={addRef(refs)} key={i++}/>,
    <Section8.Line15 ref={addRef(refs)} key={i++}/>,
    <Section8.Line16a ref={addRef(refs)} key={i++}/>,
    <Section8.Line16c ref={addRef(refs)} key={i++}/>,
    <Section8.Line16 ref={addRef(refs)} key={i++}/>,
    <Section8.Line16b ref={addRef(refs)} key={i++}/>,
    <Section8.Line17 ref={addRef(refs)} key={i++}/>,
    <Section8.Line18 ref={addRef(refs)} key={i++}/>,
    <Section8.Line19 ref={addRef(refs)} key={i++}/>,
    <Section8.Line20 ref={addRef(refs)} key={i++}/>,
    <Section8.Line21 ref={addRef(refs)} key={i++}/>,
    <Section9.HeadLine1 ref={addRef(refs)} key={i++}/>,
    <Section9.Line01 ref={addRef(refs)} key={i++}/>,
    <Section9.Line02 ref={addRef(refs)} key={i++}/>,
    <Section9.Line03 ref={addRef(refs)} key={i++}/>,
    <Section9.Line04 ref={addRef(refs)} key={i++}/>,
    <Section9.Line05 ref={addRef(refs)} key={i++}/>,
    <Section9.Line06 ref={addRef(refs)} key={i++}/>,
    <Section9.Line1 ref={addRef(refs)} key={i++}/>,
    <Section9.Line2 ref={addRef(refs)} key={i++}/>,
    <Section9.Line3 ref={addRef(refs)} key={i++}/>,
    ...Section9Assets.getLine4Content(context).map(mapItem({
      refs,
      id: 'Section9Assets.getLine4Content',
      getClassName: () => classnames(
        contentStyles.textRegular,
        contentStyles.sectionContainer,
        contentStyles.innerPadding
      )
    })),
    <Section9.Line5 ref={addRef(refs)} key={i++}/>,
    <Section9.Line6 ref={addRef(refs)} key={i++}/>,
    <Section9.Line7 ref={addRef(refs)} key={i++}/>,
    <Section9.Line8 ref={addRef(refs)} key={i++}/>,
    <Section9.Line9 ref={addRef(refs)} key={i++}/>,
    <Section9.Line10 ref={addRef(refs)} key={i++}/>,
    <Section9.Line11 ref={addRef(refs)} key={i++}/>,
    <Section9.Line12 ref={addRef(refs)} key={i++}/>,
    <Section9.Line13 ref={addRef(refs)} key={i++}/>,
    <Section9.Line14 ref={addRef(refs)} key={i++}/>,
    <Section9.Line15 ref={addRef(refs)} key={i++}/>,
    <Section9.Line16 ref={addRef(refs)} key={i++}/>,
    <Section9.Line17 ref={addRef(refs)} key={i++}/>,
    <Section9.Line18 ref={addRef(refs)} key={i++}/>,
    <Section9.Line19 ref={addRef(refs)} key={i++}/>,
    <Section9.Line20 ref={addRef(refs)} key={i++}/>,
    <Section9.Line21 ref={addRef(refs)} key={i++}/>,
    <Section9.Line22 ref={addRef(refs)} key={i++}/>,
    ...getFloors(context, 33, 'שרטוט הנכס:')
      .map(mapItem({
        refs,
        id: 'getFloors',
        getClassName: ({index, array}) => classnames({
          [styles.mb5]: index === array.length - 1
        })
      })),
    ...Section10Assets.getLine1Files(context).map(mapItem({
      refs,
      id: 'Section10Assets.getLine1Files'
    })),
    ...getValidLocalPlansDetailsSection(context).map(mapItem({
      refs,
      id: 'getValidLocalPlansDetailsSection'
    })),
    ...getCountryStateDataSection(context).map(mapItem({
      refs,
      id: 'getCountryStateDataSection'
    })),
    ...getPlansInProgressSection(context).map(mapItem({
      refs,
      id: 'getPlansInProgressSection'
    })),
    <Section10.SubHeaderLine2 ref={addRef(refs)} key={i++}/>,
    <Section10.Line3 ref={addRef(refs)} key={i++}/>,
    <Section10.Line4 ref={addRef(refs)} key={i++}/>,
    <Section10.Line5 ref={addRef(refs)} key={i++}/>,
    <Section10.Line6 ref={addRef(refs)} key={i++}/>,
    <Section10.Line7 ref={addRef(refs)} key={i++}/>,
    <Section10.Line8 ref={addRef(refs)} key={i++}/>,
    <Section10.Line9 ref={addRef(refs)} key={i++}/>,
    <Section10.Line10 ref={addRef(refs)} key={i++}/>,
    ...Section10Assets.getLine11Files(context).map(mapItem({
      refs,
      id: 'getLine11Files'
    })),
    <Section11.HeadLine1 ref={addRef(refs)} key={i++}/>,
    <Section11.Line2 ref={addRef(refs)} key={i++}/>,
    <Section11.SubHead1 ref={addRef(refs)} key={i++}/>,
    <Section11.Line4 ref={addRef(refs)} key={i++}/>,
    ...getSection11Image(context).map((page) => {
      const Component = React.forwardRef(({}, ref) => (
        <div ref={ref}>
          <div>{page}</div>
        </div>
      ));

      return <Component ref={addRef(refs)} key={i++}/>;
    }),
    <Section11.Line6 ref={addRef(refs)} key={i++}/>,
    <Section11.Line7 ref={addRef(refs)} key={i++}/>,
    <Section11.SubHeadLine2 ref={addRef(refs)} key={i++}/>,
    <Section11.Line9 ref={addRef(refs)} key={i++}/>,
    <Section11.Line10 ref={addRef(refs)} key={i++}/>,
    <Section11.Line11 ref={addRef(refs)} key={i++}/>,
    ...Section11Assets.getLine12Files(context).map(mapItem({
      refs,
      id: 'Section11Assets.getLine12Files'
    })),
    ...Section11Assets.getLine14Files(context).map(mapItem({
      refs,
      id: 'Section11Assets.getLine14Files'
    })),
    <Section11.Line15 ref={addRef(refs)} key={i++}/>,
    <Section11.Line16 ref={addRef(refs)} key={i++}/>,
    <Section11.Line17 ref={addRef(refs)} key={i++}/>,
    <Section11.Line18 ref={addRef(refs)} key={i++}/>,
    ...Section11Assets.getLine23Files(context).map(mapItem({
      refs,
      id: 'Section11Assets.getLine23Files'
    })),
    ...Section11Assets.getLine33Files(context).map(mapItem({
      refs,
      id: 'Section11Assets.getLine33Files'
    })),
    <Section11.SubHeadLine5 ref={addRef(refs)} key={i++}/>,
    <Section11.Line35 ref={addRef(refs)} key={i++}/>,
    <Section11.Line36 ref={addRef(refs)} key={i++}/>,
    ...Section11Assets.getLine47Files(context).map(mapItem({
      refs,
      id: 'Section11Assets.getLine47Files'
    })),
    ...Section11Assets.getLine54Files(context).map(mapItem({
      refs,
      id: 'Section11Assets.getLine54Files'
    })),
    <Section11.SubHeadLine8 ref={addRef(refs)} key={i++}/>,
    <Section12.Head ref={addRef(refs)} key={i++}/>,
    <Section13.HeadLine1 ref={addRef(refs)} key={i++}/>,
    ...Section13Assets.getSection13(context).map(mapItem({
      refs,
      id: 'Section13Assets.getSection13',
    })),
    <Section13.Line1 ref={addRef(refs)} key={i++}/>,
    <RestPageHeightElement ref={addRef(refs)} key={i++}/>,
    <Section14.TableWithHeader ref={addRef(refs)} key={i++}/>,
    <Section14.Line1 ref={addRef(refs)} key={i++}/>,
    <Section15.HeadLine1 ref={addRef(refs)} key={i++}/>,
    <Section15.Line2 ref={addRef(refs)} key={i++}/>,
    <Section15.Line3 ref={addRef(refs)} key={i++}/>,
    <Section15.Line4 ref={addRef(refs)} key={i++}/>,
    <Section15.Line5 ref={addRef(refs)} key={i++}/>,
    <Section15.SubHead23Line6 ref={addRef(refs)} key={i++}/>,
    <Section15.Line7 ref={addRef(refs)} key={i++}/>,
    ...Section15Assets.getLine8Files(context).map(mapItem({
      refs,
      id: 'Section15Assets.getLine8Files'
    })),
    ...Section15Assets.getLine11Files(context).map(mapItem({
      refs,
      id: 'Section15Assets.getLine11Files'
    })),
  ];

  const commentsContent = {
    replacementValue: (
      <Comments.CommentReplacementValueText
        ref={addNoteRef(commentRefs, 'replacementValue')}
        key={i++}
      />
    ),
  };

  return {content, refs, commentsContent, commentRefs};
};

const addRef = refs => element => {
  refs.push(element);
};

const addNoteRef = (refs, id) => {
  const ref = createRef();
  refs[id] = ref;
  return ref;
};

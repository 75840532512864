import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import InfoItem from "../InfoItem";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {downloadFile} from '../../../../../utils/downloadFile';
import PopupPreviewDocument from "../PopupPreviewDocument";
import Badge from 'react-bootstrap/Badge';
import {getDocumentTypeData} from '../../utils/getDocumentTypeData';
import {getStatusName} from '../../utils/getStatusName';
import SimpleButtonDropdown from "../../../_common/components/SimpleButtonDropdown";

const DocumentItem = ({data}) => {
  const {
    id,
    number,
    documentDate,
    type,
    description,
    amount,
    status,
    url
  } = data;

  const getInteractionButtonsArray = (type, id) => {
    let items = []
    const commonItems = [
      {
        itemContent: (
          <PopupPreviewDocument
            customButtonClassnames={'dropdown-item w-100 p-0 font-size-lg'}
            allowHandlePreviewDocument={false}
            documentPath={url.origin}
          />
        )
      },
      {
        itemContent: (
          <div
            onClick={() => {
              downloadFile(url.origin)
            }}
            className={`dropdown-item w-100 p-0 font-size-lg`}
          >
            <img
              src={toAbsoluteUrl('/media/common/Download.svg')}
              alt="download"
              className='w-20px mr-2'
            />
            <span className='text-truncate'>
               <FormattedMessage
                 id="customer-details-documentInfo-label-button-download"
                 defaultMessage="Download"
               />
            </span>
          </div>
        )
      }
    ];
    const createDocFromItems = getDocumentTypeData(type, id).createFromDocumentItemsList;

    items.push(commonItems);
    items.push(createDocFromItems);

    return items.flat()
  }

  return (
    <div key={id} className="w-25 mt-4">
      <div className='border border-warning shadow-sm rounded-lg mx-6 p-4 px-8'>
        <div className='w-100 text-center mb-5 position-relative'>
          {getDocumentTypeData(type, id).icon}
        </div>
        <div className='mb-2'>
          <InfoItem
            label={
              <FormattedMessage
                id="customer-details-documentInfo-label-number"
                defaultMessage="Number"
              />
            }
            value={number}
          />
          <InfoItem
            label={
              <FormattedMessage
                id="customer-details-documentInfo-label-documentDate"
                defaultMessage="Document date"
              />
            }
            value={documentDate ? moment(documentDate).format('DD-MM-YYYY') : ''}
          />
          <InfoItem
            label={
              <FormattedMessage
                id="customer-details-documentInfo-label-type"
                defaultMessage="Type"
              />
            }
            value={
              <Badge variant={getDocumentTypeData(type, id).badgeColor} className='font-size-lg'>
                {getDocumentTypeData(type, id).typeName}
              </Badge>
            }
          />
          <InfoItem
            label={
              <FormattedMessage
                id="customer-details-documentInfo-label-amount"
                defaultMessage="Amount"
              />
            }
            value={amount}
          />
          <InfoItem
            label={
              <FormattedMessage
                id="customer-details-documentInfo-label-status"
                defaultMessage="Status"
              />
            }
            value={getStatusName(status)}
          />
          <InfoItem
            label={
              <FormattedMessage
                id="customer-details-documentInfo-label-description"
                defaultMessage="Status"
              />
            }
            value={description}
            customValueClassName={'h-100px overflow-auto'}
          />
        </div>
        <div className='d-flex justify-content-center my-4'>
          <SimpleButtonDropdown
            title={
              <div className='btn btn-warning font-weight-bold text-dark text-nowrap font-size-lg p-2 w-200px'>
                <span>
                  <FormattedMessage
                    id="customer-details-button-documentInteractions-label"
                    defaultMessage="Document interactions"
                  />
                </span>
              </div>
            }
            items={getInteractionButtonsArray(type, id)}
            customMenuStyles={{width: '100%'}}
          />
        </div>
      </div>
    </div>
  );
};

DocumentItem.propTypes = {
  data: PropTypes.object,
};

export default DocumentItem;

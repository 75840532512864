import React from 'react';
import {useIntl} from "react-intl";
import {shallowEqual, useSelector} from 'react-redux';
import {StyledFieldInput} from "../../../FieldInput";
import {StyledFieldDatepicker} from "../../../FieldDatepicker";
import {StyledFieldSelect} from "../../../FieldSelect";
import {integerValueCanStartFromNull} from "../../../../utils/changeInputValue";
import {optionsTaskStatus} from "../../../../../CustomerDetails/constants/options";
import {filterOptions} from "../../../../utils/options";
import {useGridTasksContext} from '../../gridTasksContext';

const TaskVisit = ({header}) => {
  const intl = useIntl();
  const labelResponsibleUser = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-responsibleUser',
    defaultMessage: 'Responsible user',
  });
  const labelStatus = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-status',
    defaultMessage: 'Status',
  });
  const labelDueDate = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-dueDate',
    defaultMessage: 'Due date',
  });
  const labelNotes = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-notes',
    defaultMessage: 'Notes',
  });
  const labelContactName = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-contactName',
    defaultMessage: 'Contact name',
  });
  const labelContactPhone = intl.formatMessage({
    id: 'tab-order-inspections-tasks-popup-contactPhone',
    defaultMessage: 'Contact phone',
  });

  const {formik, fieldNames} = useGridTasksContext();
  const {users} = useSelector(
    (state) => ({users: state.usersReducer.users}),
    shallowEqual
  );

  const getFieldLabel = (text, mandatory = true) => {
    return (
      <div className={'font-weight-bolder pl-6'}>
        {mandatory && <span className={'text-danger'}>*</span>}
        <span>{text}</span>
      </div>
    )
  };

  return (
    <>
      <h2
        className="font-weight-normal text-dark w-100 d-flex justify-content-center mb-6"
      >
        {header}
      </h2>
      <div className={'py-4'}>
        <StyledFieldSelect
          fieldId={fieldNames.responsibleUserID}
          onChange={(option) => formik.setFieldValue(fieldNames.responsibleUserID, option.value)}
          value={filterOptions(formik.values.responsibleUserID)(users)}
          options={users}
          label={getFieldLabel(labelResponsibleUser)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldSelect
          fieldId={fieldNames.taskStatusID}
          onChange={(option) => formik.setFieldValue(fieldNames.taskStatusID, option.value)}
          value={filterOptions(formik.values.taskStatusID)(optionsTaskStatus)}
          options={optionsTaskStatus}
          label={getFieldLabel(labelStatus)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldDatepicker
          value={formik.values[fieldNames.taskDueDate]}
          onChange={(date) => {
            formik.setFieldValue(fieldNames.taskDueDate, new Date(date));
          }}
          label={getFieldLabel(labelDueDate)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldInput
          fieldId={fieldNames.notes}
          value={formik.values[fieldNames.notes]}
          onChange={(e) => formik.setFieldValue(fieldNames.notes, e.target.value)}
          label={getFieldLabel(labelNotes, false)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldInput
          fieldId={fieldNames.visitContactName}
          value={formik.values[fieldNames.visitContactName]}
          onChange={(e) => formik.setFieldValue(fieldNames.visitContactName, e.target.value)}
          label={getFieldLabel(labelContactName, false)}
          customContainerStyles={'py-3'}
        />
        <StyledFieldInput
          fieldId={fieldNames.visitContactPhone}
          value={formik.values[fieldNames.visitContactPhone]}
          onChange={(e) => {
            formik.setFieldValue(fieldNames.visitContactPhone, integerValueCanStartFromNull(e))
          }}
          label={getFieldLabel(labelContactPhone, false)}
          customContainerStyles={'py-3'}
        />
      </div>
    </>
  );
}

export default TaskVisit;

import React, {useState} from 'react';
import moment from 'moment';
import {useIntl} from "react-intl";
import CommonButton from '../../../CommonButton';
import PopupCreateEditTask from '../PopupCreateEditTask/PopupCreateEditTask';
import styles from './styles.module.scss';
import {Modal} from "react-bootstrap";

const GridTaskItem = ({
                        taskID,
                        createDate,
                        taskType,
                        taskTypeID,
                        taskStatus,
                        dueDate,
                        onOpenDetails,
                        onEdit,
                        onDelete
                      }) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const intl = useIntl();
  const messageDelete = intl.formatMessage({
    id: 'tasks-message-delete-task',
    defaultMessage: 'Delete task',
  });
  const buttonLabelYes = intl.formatMessage({
    id: 'yes',
    defaultMessage: 'Yes',
  });
  const buttonLabelNo = intl.formatMessage({
    id: 'no',
    defaultMessage: 'No',
  });
  const popupMessage = `${messageDelete} ${taskID}?`;

  const cellsArray = [
    {value: taskID},
    {value: moment(createDate).format('DD-MM-YYYY')},
    {value: taskType},
    {value: taskStatus},
    {value: moment(dueDate).format('DD-MM-YYYY')},
  ];

  const mapTasks = (task, index) => {
    return (
      <div
        key={index}
        className={'font-weight-bold d-flex align-items-center justify-content-center'}>
        {task.value}
      </div>
    );
  };

  const handleDelete = async () => {
    if (onDelete) {
      await onDelete(taskID);
    }
  };

  return (
    <div className={styles.taskRow}>
      {cellsArray.map(mapTasks)}
      <div className={styles.taskCell}>
        <PopupCreateEditTask
          taskType={taskTypeID}
          taskID={taskID}
          onOpen={onOpenDetails}
          onEdit={onEdit}
        />
      </div>
      <div className={styles.taskCell}>
        <>
          <CommonButton
          variant={'submit'}
          customButtonClassNames={'w-40px d-flex justify-content-center'}
          onClick={handleShowModal}
          label={
            <img
              src={'../../../media/common/Delete.svg'}
              alt="delete"
              className="w-20px h-20px"
            />
          }
        />
          <Modal
            show={show}
            onHide={handleCloseModal}
          >
            <Modal.Body>
              <div className={'py-4'}>
                <div className={'h2 text-center'}>
                  {popupMessage}
                </div>
                <div className="w-75 mx-auto d-flex justify-content-between pt-10">
                  <CommonButton
                    variant={'button'}
                    onClick={handleCloseModal}
                    label={buttonLabelNo}
                  />
                  <CommonButton
                    variant={'submit'}
                    onClick={handleDelete}
                    label={buttonLabelYes}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
};

export default GridTaskItem;

import React from 'react';
import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {saveAs} from 'file-saver';
import {downloadShumaFiles} from "../../_redux/actions/shumaActions";
import {can} from "../../../_common/components/Permissions";
import {permissionTypes as T, subjects as S} from "../../../_common/constants/permissionSubjects";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

const ButtonDownloadFiles = ({shumaID, firmShumaID}) => {
  const handleDownload = async () => {
    try {
      const result = await downloadShumaFiles(shumaID);
      saveAs(result, `Inspection_${firmShumaID}_files`);
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <Button
      className={'w-100 bg-white border-0 h-40px d-flex align-items-center font-weight-bold text-dark text-nowrap font-size-lg'}
      onClick={() => handleDownload()}
      disabled={!can(T.FULL, S.INSPECTIONS_PAGE)}
    >
      <img
        src={toAbsoluteUrl('media/common/Download.svg')}
        alt={'download'}
        className={'w-20px mr-4'}
      />
      <FormattedMessage
        id="inspections-column-download"
        defaultMessage="Download"
      />
    </Button>
  );
};

export default ButtonDownloadFiles;
export const landFileTypeIDs = {
  landShituf: 3,
  landHahira: 4,
  landHafkaa: 5,
  landHazmada: 6,
  landZikatHanaa: 7,
  landOther: 26,
};

export const landLabels = {
  landShituf: 'הסכם שיתוף',
  landHahira: 'חכירה',
  landHafkaa: 'הפקעה',
  landHazmada: 'הצמדה של הקרקע',
  landZikatHanaa: 'זיקת הנאה',
  landOther: 'אחר',
};

import React from 'react';
import classnames from 'classnames';
import {formattingDate} from '../../../utils/formattingHelper';
import {Counter} from '../../../utils/counter';
import {checkIfTextEndsWithDot, getTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

const mapTextChunks = (chunk, index, chunks) => {
  return (
    <span
      className={classnames(
        {[styles.emptyLine]: chunk.length === 0}
      )}
      key={index + 1}
    >
      {chunk}
      {(index === chunks.length - 1) ? checkIfTextEndsWithDot(chunk) : ''}
    </span>
  );
};

const mapListItem = ({id, counter, firstLineText = ''}) => (item, index) =>
  (index === 0)
    ? (
      <ol
        start={counter.nextNumber()}
        className={classnames(styles.innerPadding, styles.lineContainer)}
        key={index + 1}
        data-testid={id}
      >
        <li className={classnames(styles.itemText, styles.paddingOne)}>
          {(index === 0) && firstLineText}
          {item}
        </li>
      </ol>
    )
    : (
      <div
        className={classnames(styles.innerPadding, styles.lineContainer)}
        key={index + 1}
        data-testid={id}
      >
        <div className={classnames(styles.itemText, styles.paddingOne)}>
          {item}
        </div>
      </div>
    );

export const getSection9 = (context) => {
  const {
    propertyLocation,
    propertyProperties,
    possessoryBackground,
    planningBackground,
    areaPropertiesPrices,
    officialDate,
    st8Other,
  } = context.data.pdfData;

  const counter = new Counter();
  const propertyLocationChunks = Boolean(propertyLocation)
    ? getTextChunks(propertyLocation)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'propertyLocation',
        counter,
      }))
    : [];
  const propertyPropertiesChunks = Boolean(propertyProperties)
    ? getTextChunks(propertyProperties)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'propertyProperties',
        counter,
      }))
    : [];
  const possessoryBackgroundChunks = Boolean(possessoryBackground)
    ? getTextChunks(possessoryBackground)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'possessoryBackground',
        counter,
        firstLineText: <span>הרקע התכנוני - </span>
      }))
    : [];
  const planningBackgroundChunks = Boolean(planningBackground)
    ? getTextChunks(planningBackground)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'planningBackground',
        counter,
        firstLineText: <span>הרקע התכנוני - </span>
      }))
    : [];
  const areaPropertiesPricesChunks = Boolean(areaPropertiesPrices)
    ? getTextChunks(areaPropertiesPrices)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'areaPropertiesPrices',
        counter,
      }))
    : [];
  const st8OtherChunks = Boolean(st8Other)
    ? getTextChunks(st8Other)
      .map(mapTextChunks)
      .map(mapListItem({
        id: 'st8Other',
        counter,
      }))
    : [];

  return [
    <div
      className={classnames(styles.itemText, styles.bold, styles.innerPadding)}
      key={0}
    >
      בבואי להעריך את שווי השוק של הנכס שבנדון הבאתי בחשבון את העקרונות,
      הגורמים והשיקולים הבאים:
    </div>,
    ...propertyLocationChunks,
    ...propertyPropertiesChunks,
    ...possessoryBackgroundChunks,
    ...planningBackgroundChunks,
    ...areaPropertiesPricesChunks,
    Boolean(officialDate) && (
      <ol
        start={counter.nextNumber()}
        className={classnames(styles.innerPadding, styles.lineContainer)}
      >
        <li className={classnames(styles.itemText, styles.paddingOne)}>
          {`המועד הקובע לחוות הדעת ${formattingDate(officialDate)}.`}
        </li>
      </ol>
    ),
    ...st8OtherChunks
  ]
    ;
};

import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getFileList} from "../../../../OrderDetails/_redux/actions/OrderFilesActions";
import {FileList} from "./FileList/FileList";


export const OrderFilesList = ({
                                 header,
                                 fileTypeID,
                                 orderID,
                                 shumaID,
                                 closePopup
                               }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFileList(orderID))
  }, []);
  const {orderFileList} = useSelector(
    (state) => ({
      orderFileList: state.orderFilesReducer.orderFileList,
    }),
    shallowEqual
  );

  return (
    <>
      <h4 className={'mb-6 text-center'}>{header}</h4>
      <FileList
        gotFiles={orderFileList.length > 0}
        fileList={orderFileList}
        orderID={orderID}
        shumaID={shumaID}
        fileTypeID={fileTypeID}
        showFileTypeColumn={false}
        allowDownloadFile={false}
        allowDeleteFile={false}
        allowSelectFile={true}
        closePopup={closePopup}
      />
    </>
  )
}

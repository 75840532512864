import React from 'react';
import classnames from 'classnames';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import styles from './styles.module.scss';

const getValidLocalPlansDetailsSection = (context) => {
  const {validLocalPlansDetails} = context.data.pdfData;
  const chunks = Boolean(validLocalPlansDetails)
    ? getTextChunks(validLocalPlansDetails)
    : [];

  return chunks.length > 0 ? [
      <div
        className={classnames(styles.textRegular, styles.bold, styles.innerPadding, styles.pt05)}
        key={0}
      >
       תוכניות בניין עיר בתוקף:
      </div>,
      ...chunks
        .map(mapTextChunks)
        .map((item, index, array) => (
          <div className={classnames(
            styles.textRegular,
            styles.innerPadding,
            {[styles.lastSectionLine]: index === array.length - 1}
          )}>
            {item}
          </div>
        ))
    ]
    : [];
};

export default getValidLocalPlansDetailsSection;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {FieldArray} from 'formik';
import {InputTypeahead} from '../FieldInputTypeahead';
import CustomerItem from '../CustomerItem';
import FieldInput from '../FieldInput';
import {getCustomerDetails} from '../../utils/getCustomerDetails';
import CommonButton from '../CommonButton';
import Empty from '../Empty';
import * as actions from '../../actions/findCustomers';
import styles from './styles.module.scss';

const FieldFindCustomers = ({
                              formik,
                              name,
                              customContainerStyles = 'w-100',
                              customItemStyles = 'w-100',
                              customListStyles = '',
                              readOnlyMode = false,
                              customLabel
                            }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const buttonLabelRemove = intl.formatMessage({
    id: 'button-label-remove',
    defaultMessage: 'Remove',
  });
  const noData = intl.formatMessage({
    id: 'inspection-details-page-search-customers-nothing-old-shuma',
    defaultMessage: 'No data',
  });
  const typeaheadLabel = intl.formatMessage({
    id: 'typeahead-customer-label',
    defaultMessage: 'Search customers',
  });

  const {values} = formik;
  const [inputValue, setInputValue] = useState('');

  const findCustomer = (input) => {
    return dispatch(actions.findCustomer(input));
  };

  const customerNotInList = (customer, values) => {
    const ids = values.customers.map(item => item.customerID);
    return ids.indexOf(customer.customerID) === -1;
  };

  const handleChange = (add) => ([value]) => {
    if (value && customerNotInList(value, values)) {
      add(value);
      formik.setTouched({customers: true});
    }
  };

  return (
    <div className={customContainerStyles}>
      <FieldArray name={name} validateOnChange={false}>
        {({unshift, remove}) => {
          return (
            <>
              {!readOnlyMode && (
                <InputTypeahead
                  id={`inputValue`}
                  value={inputValue}
                  isLoading={false}
                  labelKey={option => `${option.firstName} ${option.lastName}`}
                  makeAndHandleRequest={() => {
                  }}
                  onChange={handleChange(unshift)}
                  onInputChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  renderMenuItemChildren={option => <CustomerItem {...option}/>}
                  handleFindItem={findCustomer}
                  label={customLabel || typeaheadLabel}
                />
              )}
              <div className={`${styles.addedCustomersContainer} ${customListStyles}`}>
                {values.customers.length > 0
                  ? (values.customers.map((customer, index) => (
                    <div className={`pt-4 ${customItemStyles}`}>
                      <div key={index} className={'w-100'}>
                        <FieldInput
                          fieldId={`customerDetails-${index}`}
                          customInputStyles={`form-control form-control-lg form-control-flush ${customer.deleted ? 'text-danger' : ''}`}
                          readonly="readonly"
                          disabled
                          value={getCustomerDetails(customer)}
                        />
                      </div>
                      {!readOnlyMode && (
                        <CommonButton
                          customButtonClassNames={'d-flex btn btn-m btn-warning font-weight-bolder w-80px text-dark mb-2 ml-2'}
                          onClick={() => remove(index)}
                          label={
                            <div className={'d-flex'}>
                              <img
                                src={'../../../media/common/Delete.svg'}
                                alt="remove"
                                className={'w-20px h-20px mr-1'}
                              />
                              <div className={'text-nowrap d-flex'}>
                                {buttonLabelRemove}
                              </div>
                            </div>
                          }
                        />
                      )}
                    </div>
                  )))
                  : <Empty message={noData} customContainerStyles={'pt-8'}/>
                }
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

FieldFindCustomers.propTypes = {
  formik: PropTypes.object,
  customContainerStyles: PropTypes.string,
  customItemStyles: PropTypes.string,
  readOnlyMode: PropTypes.bool,
};

export default FieldFindCustomers;

import React from 'react';
import Section from '../Section';
import styles from './styles.module.scss';

const AssetsTitle = () => {
  return (
    <Section>
      <div className={styles.bold}>
        בוצע סקר מחירים לדירות בסביבת הנכס (מקור הנתונים : רשות המיסים)
      </div>
    </Section>
  );
};

export default AssetsTitle;

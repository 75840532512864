const defaultState = {
  listLoading: false,
  pageCount: 0,
  currentPageNumber: 0,
  entities: null,
  totalResult: 0,
  error: null,
};
export const actionTypes = {
  customersFetched: 'CUSTOMERS_ENTITY_FETCHED',
  customersAppended: 'CUSTOMERS_ENTITY_APPENDED',
  editCustomer: 'EDIT_CUSTOMER',
  addCustomer: 'ADD_CUSTOMER',
  deleteCustomer: 'DELETE_CUSTOMER',
  restoreCustomer: 'RESTORE_CUSTOMER',
  CatchError: 'CATCH_ERROR'
};

const getIndex = (array, customerID) => {
  const mappedArray = array.map(item => item.customerID);
  return mappedArray.indexOf(customerID);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.customersFetched: {
      const {totalPageCount, currentPageNumber, customers, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: customers,
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.customersAppended: {
      const {totalPageCount, currentPageNumber, customers, totalResultCount} = action.payload;
      return {
        ...state,
        listLoading: false,
        pageCount: totalPageCount,
        currentPageNumber: currentPageNumber,
        entities: state.entities
          ? [...state.entities, ...customers]
          : [...customers],
        totalResult: totalResultCount,
        error: null
      };
    }
    case actionTypes.editCustomer: {
      const {customerID, customerTypeID, ...rest} = action.payload;
      const index = getIndex(state.entities, customerID);
      state.entities[index] = {customerType: customerTypeID, customerID, ...rest};
      return {
        ...state,
        entities: [...state.entities]
      };
    }
    case actionTypes.addCustomer: {
      const {customerTypeID, ...rest} = action.payload;
      state.entities.unshift({});
      const index = 0;
      state.entities[index] = {customerType: customerTypeID, ...rest};
      return {
        ...state,
        entities: [...state.entities]
      };
    }
    case actionTypes.deleteCustomer: {
      const {customerID} = action.payload;
      const index = getIndex(state.entities, customerID);
      state.entities.splice(index, 1);
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.restoreCustomer: {
      const {customerID} = action.payload;
      const index = getIndex(state.entities, customerID);
      state.entities.splice(index, 1);
      return {
        ...state,
        entities: [...state.entities]
      }
    }
    case actionTypes.CatchError: {
      const error = `CUSTOMERS_ENTITY: ${action.payload.error}`;
      return {...state, error};
    }
    default:
      return state;
  }
};

export const actions = {
  customersFetched: (data) => ({
    type: actionTypes.customersFetched,
    payload: data
  }),
  customersAppended: (data) => ({
    type: actionTypes.customersAppended,
    payload: data
  }),
  editCustomer: (data) => ({
    type: actionTypes.editCustomer,
    payload: data
  }),
  addCustomer: (data) => ({
    type: actionTypes.addCustomer,
    payload: data
  }),
  deleteCustomer: (data) => ({
    type: actionTypes.deleteCustomer,
    payload: data
  }),
  restoreCustomer: (data) => ({
    type: actionTypes.restoreCustomer,
    payload: data
  }),
  catchError: (error) => ({type: actionTypes.CatchError, payload: {error}}),
};

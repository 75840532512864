import React from 'react';
import OrderInspectionForm
  from '../../../../_common/components/OrderInspectionForm';
import {
  useOrderDetailsUIGlobalContext
} from '../../OrderDetailsUIGlobalContext';
import {useOrderDetailsTaskContext} from '../../OrderDetailsTaskContext';
import GridTasks from "../../../../_common/components/GridTasks";
import {GridTasksContextProvider} from "../../../../_common/components/GridTasks/gridTasksContext";
import {useOrderDetailsUIOrderContext} from "../../OrderDetailsUIOrderContext";

const OrderInspection = ({shumaID, firmShumaID, useContext}) => {
  const {
    formikOrderInspection,
    decisionTreeProps,
    fieldNames
  } = useContext();

  const {values, setFieldValue, handleChange} = formikOrderInspection;
  const {users} = useOrderDetailsUIGlobalContext();
  const {oldInspectionsForms} = useOrderDetailsUIOrderContext();
  const tasksContext = useOrderDetailsTaskContext();

  return (
    <OrderInspectionForm
      firmShumaID={firmShumaID}
      values={values}
      fieldNames={fieldNames}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      decisionTreeProps={decisionTreeProps}
      selectProps={{users}}
      disabledFormCondition={Boolean(shumaID)}
      tasksSection={!oldInspectionsForms && (
        <GridTasksContextProvider tasksContext={tasksContext}>
          <GridTasks/>
        </GridTasksContextProvider>
      )}
    />
  );
};

export default OrderInspection;

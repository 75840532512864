import React from 'react';
import styles from './styles.module.scss';
import {getTextChunks, mapTextChunks} from '../../../utils/texts';
import classnames from 'classnames';

export const getCalculationNotesText = (context) => {
  const {calculationNotes} = context.data.pdfData;
  let firstLineText;
  let restChunks = [];

  if (Boolean(calculationNotes)) {
    [firstLineText, ...restChunks] = getTextChunks(calculationNotes);
  }

  return Boolean(calculationNotes)
    ? [
      <div className={classnames(styles.itemText, styles.innerPadding)}>
        <div className={styles.itemTitle}>
          הערות:
        </div>
      </div>,
      <div className={classnames(styles.itemText, styles.innerPadding)}>
        {Boolean(firstLineText) ? ` ${firstLineText}` : ''}
      </div>,
      ...restChunks
        .map(mapTextChunks)
        .map((item, index, array) => (
          <div
            className={classnames(styles.innerPadding, {
              [styles.lastSectionLine]: index === array.length - 1
            })}
          >
            {item}
          </div>
        ))
    ]
    : [];
};

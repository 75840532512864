export class Counter {
  constructor(defaultCount = 0) {
    this.count = defaultCount;
  }

  nextNumber = () => {
    this.count = this.count + 1;
    return this.count;
  };
}

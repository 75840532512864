import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import FieldInput from '../../../../_common/components/FieldInput';
import ButtonRemoveRow from '../ButtonRemoveRow';
import {floatValue, integerValue} from '../../../../../../utils/format';
import {optionsVatType} from '../../../constants/options';
import {createDocumentGridSelectStyles} from '../../../../_common/selectStyles/customSelectStyles';
import styles from '../styles.module.scss';

const RowCommonDocs = ({
                         fieldsSet,
                         values,
                         removeRow,
                         index,
                         setFieldValue
                       }) => {
  return (
    <div className={styles.commonDocsRowContainer}>
      <div className={styles.rowFieldsContainer}>
        <Select
          name={fieldsSet.vatType}
          value={values.rowsCommonDocs[index].vatType}
          options={optionsVatType}
          styles={createDocumentGridSelectStyles}
          onChange={value => setFieldValue(`rowsCommonDocs[${index}].vatType`, value)}
        />
        <FieldInput
          fieldId={fieldsSet.description}
          customContainerStyles={styles.gridCell}
        />
        <FieldInput
          fieldId={fieldsSet.quantity}
          customContainerStyles={styles.gridCell}
          onChange={integerValue}
        />
        <FieldInput
          fieldId={fieldsSet.price}
          customContainerStyles={styles.gridCellPrice}
          onChange={floatValue}
        />
      </div>
      {index !== 0 && <ButtonRemoveRow onClick={removeRow} styles={styles}/>}
    </div>
  );
};

RowCommonDocs.propTypes = {
  fieldsSet: PropTypes.object,
  values: PropTypes.object,
  removeRow: PropTypes.func,
  index: PropTypes.number,
  setFieldValue: PropTypes.func,
};

export default RowCommonDocs;


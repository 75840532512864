import {connect} from 'react-redux';
import UserInfoPanel from './UserInfoPanel';

const mapStateToProps = ({
                           auth: {user}
                         }) => ({
  user
});

export default connect(
  mapStateToProps,
  null
)(UserInfoPanel);

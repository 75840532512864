export const floatValue = (e) => {
  const value = `${e.target.value}`.replace(/[^\.\,\d]/, '');
  return value;
};

export const integerValue = (e) => {
  const value = `${e.target.value}`.replace(/[^\d]/, '');
  return value ? parseInt(value) : '';
};

export const divideThousands = value => {
  if (value) {
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  }
};

export const divideThousandsInteger = value => {
  if (value) {
    let parts = value.toString().replace(/[^\d]/, '').split(".");
    parts[0] = parts[0].startsWith('0')
      ? ''
      : parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  }
};

export const formatStringToNumber = e => {
  const value = (e.target.value).replace(/[\s,%]/g, '');

  return value ? value : '';
};
